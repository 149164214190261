import { Col, Form } from "react-bootstrap";
import { AuthorizationWrapper } from "./AuthorizationRenderer";
import * as PropTypes from "prop-types";
import React from "react";
import { CheckboxFormComponent } from "../../CheckboxFormComponent";

export const CheckboxRenderer = (field, formColumnCount) => {
    const checkboxControl = (<Form.Group key={field.name} as={Col} md={formColumnCount}>        
        <CheckboxFormComponent name={field.id} isRequired={field.isRequired} validationArray={field.validations} isDisabled={field.isDisabled} onChange={field.onChange} />
        <Form.Label>{field.name}{field.isRequired && ' *'}</Form.Label>
    </Form.Group>);

    if (field.authorizationWrapper) {
        return AuthorizationWrapper(checkboxControl, field.id, field.authorizationWrapper);
    }
    return checkboxControl;
};

CheckboxRenderer.propTypes = {
    item: PropTypes.object.isRequired
};
