import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, Col, Card } from 'react-bootstrap';
import { ButtonStack, Button } from '@dfds-ui/react-components';
import { capitalCase } from '../../core/utility';

const ReduxFormControl = ({ input, meta: { touched, error, warning }, ...props }) => {
    return <><Form.Control {...props} {...input} />
        {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span className="text-warning">{warning}</span>))}
    </>
};
const checkName = value => value && value === 'new' ? `Code cannot be "new".` : undefined;
const required = value => value ? undefined : 'Required';

let CompanyForm = ({ isNewCompany, handleSubmit, pristine, submitting, countries, companyTypes }) => {
    return (
        <Form onSubmit={handleSubmit}>
            <Form.Row>
                <Form.Group as={Col} md="6">
                    <Form.Label>Company code</Form.Label>
                    <Field
                        name="code"
                        component={ReduxFormControl}
                        type="text"
                        placeholder="Company code"
                        disabled={!isNewCompany}
                        validate={[required, checkName]}
                        required
                    />
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <Form.Label>Name</Form.Label>
                    <Field
                        name="name"
                        component={ReduxFormControl}
                        type="text"
                        placeholder="Name"
                        required
                    />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} md="6">
                    <Form.Label>Company type</Form.Label>
                    <Field
                        name="companyType"
                        component={ReduxFormControl}
                        as="select"
                        validate={[required]}
                        required>
                        <option value="">Select Company Type</option>
                        {companyTypes.map((item) => (
                            <option value={item.value} key={item.key}>
                                {capitalCase(item.type)}
                            </option>
                        ))}
                    </Field>
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <Form.Label>Is client</Form.Label>
                    <Field
                        name="companyIsClient"
                        component={ReduxFormControl}
                        type="checkbox"
                        placeholder="Is Client"
                        label="Is Client"

                    // value={option.name}
                    // checked={input.value.indexOf(option.name) !== -1}
                    // onChange={(event) => {
                    //     const newValue = [...input.value];
                    //     if (event.target.checked) {
                    //         newValue.push(option.name);
                    //     } else {
                    //         newValue.splice(newValue.indexOf(option.name), 1);
                    //     }
                    //     return input.onChange(newValue);
                    // }}

                    />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} md="6">
                    <Form.Label>Country</Form.Label>
                    <Field
                        name="companyAddressCountry"
                        component={ReduxFormControl}
                        as="select"
                    >
                         <option value="">Select Country</option>
                         {countries && countries.map((item) => (
                            <option value={item.value} key={item.key}>
                                {item.type}
                            </option>
                        ))}
                    </Field>
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <Form.Label>City</Form.Label>
                    <Field
                        name="companyAddressCity"
                        component={ReduxFormControl}
                        type="text"
                        placeholder="City"
                    />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} md="6">
                    <Form.Label>Postal code</Form.Label>
                    <Field
                        name="companyAddressPostalCode"
                        component={ReduxFormControl}
                        type="text"
                        placeholder="Postal Code"
                    />
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <Form.Label>Address</Form.Label>
                    <Field
                        name="companyAddressAddressLine"
                        component={ReduxFormControl}
                        type="text"
                        placeholder="Address"
                    />
                </Form.Group>
            </Form.Row>
            <div>
                <Button type="submit" disabled={pristine || submitting} className='float-right'>Save</Button>
            </div>
        </Form>
    );
};


CompanyForm = reduxForm({
    form: 'insertOrUpdateCompanyForm', // a unique identifier for this form
})(CompanyForm);
CompanyForm = connect(
    state => ({
        initialValues: state.company.company // pull initial values from company reducer
    }),
)(CompanyForm);

export default CompanyForm;
