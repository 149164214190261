import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    getOperationById,
    clearState,
    getShipmentList
} from '../store/action/operationActions';

import { STATUS, TYPES } from '../constants/operation/operation';

import PageHeader from '../component/PageHeader/PageHeader';
import OperationShipmentList from '../component/Operation/OperationShipmentList';
import FileController from '../component/FileController/FileController';
import ImageController from '../component/ImageController';
import Summary from '../component/Operation/Summary';
import Report from '../component/Report/Report';
import { getValue } from '../core/utility';

const Operation = (props) => {

    //Inputs from url
    const {
        operationId
    } = props.match.params;

    //Inputs from redux
    const {
        operation,
        shipmentIdList,
        shipmentList,
        clearState,
        getOperationById,
        getShipmentList,
    } = props;

    const CONSTANTS = {
        SUMMARYLOADER: 'loader-operation-summary',
        SHIPMENTLISTLOADER: 'loader-shipmentlist',
        FILELOADER: 'loader-operation-file'
    }

    useEffect(() => {
        getOperationById(operationId, CONSTANTS.SUMMARYLOADER);

        return () => {
            clearState();
        }
    }, [])

    useEffect(() => {
        if (shipmentIdList && shipmentIdList.length > 0) {
            getShipmentList(shipmentIdList, CONSTANTS.SHIPMENTLISTLOADER);
        }
    }, [shipmentIdList])

    const renderPageHeader = () => {
        return <PageHeader text={operation ? operation.id : ''} />
    }

    const renderSummary = () => {
        return (<Summary operation={operation} show={true} showSealNumber={true} showUnitLeft={true} loader={CONSTANTS.SUMMARYLOADER} />);
    }

    const renderShipments = () => {
        return (<OperationShipmentList operation={operation} shipmentList={shipmentList} loader={CONSTANTS.SHIPMENTLISTLOADER} />);
    }

    const renderFileController = () => {

        const relativePath = `Unit/${operationId}`;

        const uploadOptions = {
            uploadFileRelativePath: relativePath,
            uploadFileRelativePathAsArray: [relativePath],
            uploadData: operation,
            externalId: operationId,
            externalType: 'Operation',
            loader: CONSTANTS.FILELOADER
        };

        const deleteOptions = { deleteFileRelativePath: [relativePath] };

        var shipmentRelativePathList = [];
        for (let i = 0; i < shipmentIdList.length; i++) {
            const shipmentRelativePath = `Shipment/${shipmentIdList[i]}`;
            shipmentRelativePathList.push(shipmentRelativePath);
        }

        const downloadOptions = {
            downloadAllFilePathList: [relativePath, ...shipmentRelativePathList],
            downloadFileRelativePath: [relativePath]
        };


        const splitOptions = { splitUrl: `/operation/${operationId}/split` }
        const shareOptions = {
            actionKey: operationId,
            actionType: 'download-unit-documents'
        }

        const buttonsProperties = { addFile: true, split: true, download: true, upload: true, shareDocuments: true };

        return (
            <FileController
                operationButtonsToShow={buttonsProperties}
                uploadOptions={uploadOptions}
                deleteOptions={deleteOptions}
                downloadOptions={downloadOptions}
                splitOptions={splitOptions}
                shareOptions={shareOptions}
            />
        );
    }

    const renderReports = () => {

        let reportTypes = [
            { operationType: TYPES.LOADING, operationStatus: STATUS.COMPLATED, reportType: 'Cmr', title: 'CMR', id: getValue(operation, 'id') },
            { operationType: TYPES.LOADING, operationStatus: STATUS.COMPLATED, reportType: 'CsgNote', title: 'Consignment Note', id: getValue(operation, 'id') },
            { operationType: TYPES.LOADING, reportType: 'LoadingList', title: 'Loading List', id: getValue(operation, 'id') },
            { operationType: TYPES.LOADING, reportType: 'LoadingSummary', title: 'Loading Summary', id: getValue(operation, 'id') },

            { operationType: TYPES.UNLOADING, reportType: 'ArrivalReceipt', title: 'Arrival Receipt', id: getValue(operation, 'id') },
            { operationType: TYPES.UNLOADING, reportType: 'FrontPage', title: 'Front Page', id: getValue(operation, 'id') },
            { operationType: TYPES.UNLOADING, reportType: 'LoadingSummary', title: 'Loading Summary', id: getValue(operation, 'id') },
            { operationType: TYPES.UNLOADING, reportType: 'ShipmentLabel', title: 'Shipment Label', id: getValue(operation, 'id') },
            { operationType: TYPES.UNLOADING, reportType: 'GoodsReceipt', title: 'Goods Receipt', id: getValue(operation, 'id') },
        ];

        var data = [];
        for (let i = 0; i < reportTypes.length; i++) {
            var show = getValue(operation, 'type') === reportTypes[i].operationType && (reportTypes[i].operationStatus ? getValue(operation, 'status') === reportTypes[i].operationStatus : true)
            const report = { show: show, item: { title: reportTypes[i].title, request: { type: reportTypes[i].reportType, identifier: reportTypes[i].id } } }
            data.push(report);
        }

        return (<Report data={data} />);
    }

    const renderImageController = () => {

        const imageControllerOptions = {
            relativePath: [`image/operation/${operationId}`],
            relativePathForFetch: `image/operation/${operationId}`,
            redirectUrl: `/operation/${operationId}`,
            externalType: 'Operation',
            externalId: operationId,
            showButtons: true
        };

        return (<ImageController imageControllerOptions={imageControllerOptions} />)
    }

    return (
        <div>
            {renderPageHeader()}
            {renderSummary()}
            <div id="accordion">
                {renderFileController()}
                {renderImageController()}
                {renderReports()}
                {renderShipments()}
            </div>
        </div>
    )
}


const mapStateToProps = ({ operationReducer }) => {
    return {
        operation: operationReducer.operation,
        shipmentIdList: operationReducer.shipmentIdList,
        shipmentList: operationReducer.shipmentList
    }
}

const mapActionsToProps = {
    clearState,
    getOperationById,
    getShipmentList
}

export default connect(mapStateToProps, mapActionsToProps)(Operation);