/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SplitActionButton from '../SplitActionButton/SplitActionButton';
import SplitRow from '../SplitRow/SplitRow';
import { removeUnSavedSplit, deleteSplitFile, clearState, approve, doSplit, checkAndProcessUnfinishedSplit } from '../../store/action/splitActions';
import { connect } from 'react-redux';
import classes from './Split.module.css';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { ButtonStack } from '@dfds-ui/react-components/';

// Şu anda datanın sahibi olmadığımız için split barkod işleminde kullanılan TBR number,
// Dokümanları upload ederken tekillik sağlamadığı için 'shipments' diye bir
// parametre geçmek zorunda kalıyoruz. Bir gün owner'lığı aldığımızda buranın refactor edilip
// shipments'ların bu componentden kaldırılması gerekiyor.

const Split = ({
    splitFilePath,
    splitters,
    shipments,
    splits,
    splitFileUid,
    doSplit,
    removeUnSavedSplit,
    checkAndProcessUnfinishedSplit,
    deleteSplitFile,
    clearState,
    approve,
}) => {
    useEffect(() => {
        return () => {
            clearState();
        }
    }, []);

    useEffect(() => {
        if (splitters && splitters.length > 0) {
            checkAndProcessUnfinishedSplit(splitFilePath, splitters);
        }
    }, [splitters])

    var inputs = document.querySelectorAll('.inputfile');
    Array.prototype.forEach.call(inputs, function (input) {
        var label = input.nextElementSibling,
            labelVal = label.innerHTML;

        input.addEventListener('change', function (e) {
            var fileName = '';
            if (this.files && this.files.length > 1)
                fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
            else
                fileName = e.target.value.split('\\').pop();

            if (fileName)
                label.querySelector('span').innerHTML = fileName;
            else
                label.innerHTML = labelVal;
        });
    });

    const approveClickedHandler = () => {
        // Şu anda datanın sahibi olmadığımız için split barkod işleminde kullanılan TBR number,
        // Dokümanları upload ederken tekillik sağlamadığı için Target Path tarafında 'shipment' 
        // keyword'ünü geçmek zorunda kalıyoruz. Bir gün owner'lığı aldığımızda buranın refactor edilip
        // Shipment kelimesi buradan kaldırılmalıdır.
        // Keza aşağıda yapılan tempSplitPdfs conversation'ını da gereksiz duruma gelecektir.
        const tempSplitPdfs = [];
        for (const currentSplit of splits) {
            var shipment = shipments.find(s => s.shipmentNo === currentSplit.key);
            tempSplitPdfs.push({ ...currentSplit, key: currentSplit.key, externalId: shipment && shipment.id, externalType: 'Shipment' });
        }
        approve(tempSplitPdfs, splitFilePath, 'Shipment', splitFileUid);
    }

    const cancelClickedHandler = () => {
        if (confirm('All changes will be lost. Do you want to cancel split?')) {//eslint-disable-line
            deleteSplitFile(splitFilePath, splitFileUid);
        }
    }

    const selectFileChangedHandler = (e) => {
        const files = Array.from(e.target.files);
        doSplit(files, null, splitFilePath, splitters);
    }

    const deleteUnfoundKeysClickedHandler = () => {
        splits.forEach(s => !s.isFound ? removeUnSavedSplit(s.key) : null)
    }

    const deleteSplitHandler = (key) => {
        removeUnSavedSplit(key);
    }

    const renderSelectFileButton = () => {
        return (
            <div className={[classes.inputFileWrapper, 'btn-space'].join(' ')}>
                <label htmlFor="file" style={{ cursor: 'pointer', marginBottom: '0px' }}>
                    <FontAwesomeIcon icon="copy" style={{ marginRight: '10px' }} />
                Select scanned file
                </label>
                <input type='file' id='file' name="file" onChange={selectFileChangedHandler} multiple className={classes.inputFile} />
            </div>
        )
    }

    const renderCommands = () => {
        const hasUnfoundKey = splits.some(s => !s.isFound)
        const canDeleteUnfoundKeys = hasUnfoundKey;
        const canApprove = !hasUnfoundKey && splits && splits.length > 0;
        const canCancel = splitFileUid !== '';
        const canSelectFile = splitFileUid === '';

        return <>
            <div className="row">
                <div className="col-md-3"> <h2>{'Split'}</h2></div>
                <div className="col-md-9">
                    <ButtonStack align="right" >
                        {canSelectFile ? renderSelectFileButton() : null}
                        <SplitActionButton disabled={!canApprove} icon="check" text="Approve" clicked={approveClickedHandler} />
                        <SplitActionButton disabled={!canCancel} icon="times" text="Cancel" clicked={cancelClickedHandler} />
                        <SplitActionButton disabled={!canDeleteUnfoundKeys} icon="minus-square" text="Delete unfound shipments" clicked={deleteUnfoundKeysClickedHandler} />
                    </ButtonStack>
                </div>
            </div>
            <hr />
        </>
    }
    return (<>
        <LoadingIndicator id="splitOverAll" />
        <Row className="m-2 d-flex">
            <Col>
                {renderCommands()}
            </Col>
        </Row>
        <Row className="m-2 d-flex">
            <Col>
                {splits.some(s => s.existingDocumentUid !== null) ? <Alert variant={'success'}>
                    Shipments with green background already have splitted document. They will be overwritten.
                    </Alert> : null}
                {splits.some(s => !s.isFound) ? <Alert variant={'warning'}>
                    Unmatched shipments are found!
                    </Alert> : null}
                {splits.some(s => s.isFound) ? <div style={{ backgroundColor: '#fff' }}>
                    <Alert className="card-header">
                        Shipments with white background are found and matched documents. They will be sent to related shipments.
                        </Alert>
                </div> : null
                }
            </Col>
        </Row>
        {splitFileUid === '' ? <Row>
            <Col>
                <Alert variant={'primary'}>
                    Select scanned file to start split process.
                    </Alert>
            </Col>
        </Row> : null}
        <Row className="m-2 d-flex">
            <Col>
                <div id="splitAccordion">
                    {splits.map(split => {
                        return <SplitRow
                            splitFilePath={splitFilePath}
                            deleteSplitClicked={() => deleteSplitHandler(split.key)}
                            key={split.key}
                            split={split} />
                    })}
                </div>
            </Col>
        </Row>
    </>
    );
};

const mapStateToProps = (state) => {
    return {
        splitFileUid: state.split.splitFileUid,
        splits: state.split.splits,
    }
}

export default connect(mapStateToProps, { removeUnSavedSplit, doSplit, deleteSplitFile, clearState, approve, checkAndProcessUnfinishedSplit })(Split);