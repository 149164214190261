import shipmentTypes from '../type/shipment';
import post, { get } from '../../core/httpClientSync';
import modalActionTypes from '../type/modal';
import { closeModals } from '../action/modalActions';

const apiUrl = 'xms-shipment-api';
// const apiUrl = 'http://localhost:5000';

export const clearState = () => async dispatch => {
    dispatch({
        type: shipmentTypes.CLEAR_STATE,
    })
}

export const search = (keyword, spinnerElementId = null) => async dispatch => {

    const url = `${apiUrl}/api/shipments?keyword=${keyword}`;
    const response = await get(url, spinnerElementId);

    if (response.isSuccess && response.response && response.response.result) {
        dispatch({
            type: shipmentTypes.SHIPMENTLIST_RECEIVED,
            payload: response.response.result.shipments
        })
    }
}

export const getShipment = (id, spinnerElementId) => async dispatch => {

    dispatch({
        type: shipmentTypes.INITALIZE_SHIPMENT_DATA
    });

    const url = `${apiUrl}/api/shipments/${id}`;

    const response = await get(url, spinnerElementId);

    if (response.isSuccess) {
        dispatch({
            type: shipmentTypes.UPDATE_SEARCHED_SHIPMENT,
            payload: response.shipment
        })
    }
}

export const getFileList = (RelativePaths, spinnerId) => async dispatch => {

    dispatch({
        type: shipmentTypes.INITALIZE_SHIPMENT_DATA
    });

    const getFileListResult = await retrieveFileList(RelativePaths, spinnerId);

    if (getFileListResult.isSuccess) {
        const responseFileList = getFileListResult.response.map(shipmentFileInfo => {
            return {
                relativePath: shipmentFileInfo.relativePath.replace('Shipment/', ''),
                files: shipmentFileInfo.files
            }
        })

        dispatch({
            type: shipmentTypes.SAVE_FILE_LIST,
            payload: responseFileList
        })
    }
}

export const changeMissingDocsState = (shipmentNo, docsMissing, user, spinnerId) => async dispatch => {

    const url = `${apiUrl}/api/shipment/setdocsmissing`;

    const request = { shipmentNo, docsMissing };
    const response = await post(url, request, spinnerId);

    if (response.isSuccess) {
        const changeMissingDocumentStateResponse = {
            orderItemId: shipmentNo,
            isMissingDocumentStateFlagSet: docsMissing
        };
        dispatch({
            type: shipmentTypes.SAVE_DOCS_MISSING_STATE,
            payload: changeMissingDocumentStateResponse
        })
    }
}

export const clearError = () => {
    return {
        type: shipmentTypes.INITALIZE_SHIPMENT_DATA
    };
}

export const initializeShipmentData = () => {
    return {
        type: shipmentTypes.INITALIZE_SHIPMENT_DATA
    };
}

export const retrieveFileList = async (RelativePaths, spinnerId) => {
    const getFileListRequest = { RelativePaths };
    const url = 'xms-document-api/api/document/getFileList';
    const getFileListResult = await post(url, getFileListRequest, spinnerId);
    return getFileListResult;
}

export const concatShipment = relativePath => relativePath ? `Shipment/${relativePath}` : 'Shipment';

export const showAddShipmentModal = () => async dispatch => {
    dispatch({
        type: modalActionTypes.SHOW_MODAL,
        payload: {
            modalType: 'add-shipment',
            modalData: {}
        }
    })
}

export const createShipment = (shipment, spinnerElementId = null) => async dispatch => {
    const request = { ...shipment };

    const url = `${apiUrl}/api/shipments/new`;

    const response = await post(url, request, spinnerElementId);

    if (response.isSuccess) {
        dispatch({
            type: shipmentTypes.SHIPMENT_CREATED,
            payload: response.response.shipment
        })
        dispatch(closeModals())
    }
}

export const createShipmentNo = (spinnerElementId = null) => async dispatch => {

    const url = `${apiUrl}/api/shipments/generateShipmentNo`;

    const response = await get(url, spinnerElementId);
    if (response.isSuccess) {
        dispatch({
            type: shipmentTypes.SHIPMENT_NO_CREATED,
            payload: response.response.result.generatedShipmentNo
        })
    }
}