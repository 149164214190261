/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  getRules,
  deleteRule,
  getBusinessAreas,
  getTimeSlotSimulation,
  getEffectingRulesFor,
  setFilteredBusinessAreaId
} from '../store/action/calendarActions';
import history from '../core/history';
import DefaultQuota from '../component/DefaultQuota/DefaultQuota';
import XmsTable from '../component/XmsTable/XmsTable';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import moment from 'moment';
import { Row, Col, FormLabel } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import TimeSlotTable from '../component/TimeSlotTable/TimeSlotTable';
import Page from '../component/Page/Page';

const CalendarRuleList = (props) => {
  //Redux actions
  const {
    getRules,
    getTimeSlotSimulation,
    getBusinessAreas,
    deleteRule,
    getEffectingRulesFor,
    setFilteredBusinessAreaId
  } = props;

  //Redux store
  let { rules, businessAreas, simulatedTimeSlots, defaultQuota, effectingRules, filteredBusinessAreaId } = props;

  const [calendarRuleListState, setCalendarRuleListState] = useState({
    businessAreaId: null,
    dateEpoch: null
  });

  const [filteredRules, setFilteredRules] = useState([]);

  useEffect(() => {
    if (!businessAreas) {
      getBusinessAreas();
    }
  }, [businessAreas]);

  useEffect(() => {
    getRules();
}, []);

  useEffect(() => {
    if (!rules) {
      getRules();
    }
    else if (filteredBusinessAreaId) {
      businessAreaFilterChanged(filteredBusinessAreaId);
    }
    else {
      setFilteredRules(rules);
    }
  }, [rules]);

  const newConfigurationHandler = () => {
    history.push('/booking/configuration');
  };

  const newRuleClickedHandler = () => {
    history.push('/booking/calendar-rule/new');
  };

  const openAdditionalGateClickedHandler = () => {
    history.push('/booking/calendar-rule/open-additional-gate');
  };

  const deleteRuleClickedHandler = (rule) => {
    deleteRule(rule.id);
  };

  const reservedSlotsClickedHandler = () => {
    history.push('/booking/reserved-slot-rule');
  };

  const businessAreaChangedHandler = (value) => {
    setCalendarRuleListState((previousState, props) => ({
      ...previousState,
      businessAreaId: value
    }));

    if (calendarRuleListState.dateEpoch && value) {
      getTimeSlotSimulation(calendarRuleListState.dateEpoch, value);
      getEffectingRulesFor(calendarRuleListState.dateEpoch, value);
    }
  };

  const businessAreaFilterChanged = (value) => {

    if (value == "") {
      setFilteredRules(rules);
    } else {
      var filteredRulesList = rules && rules.filter(
        a => a.businessAreaId === value
      );
      setFilteredRules(filteredRulesList);
    }
    setFilteredBusinessAreaId(value);
  }

  const renderValidTimeColumn = (rule) => {
    return (
      moment.utc().startOf('day').add(rule.startMinutesSinceMidnight, 'minutes').format('HH:mm') +
      ' - ' +
      moment.utc().startOf('day').add(rule.endMinutesSinceMidnight, 'minutes').format('HH:mm')
    );
  };

  const renderBusinessAreaColumn = (rule) => {
    if (!rule || !businessAreas || !rule.businessAreaId) return '';
    const businessArea = businessAreas.find((x) => x.id === rule.businessAreaId);
    return businessArea ? businessArea.name : '';
  };

  const dateChangedHandler = (value) => {
    setCalendarRuleListState((previousState, props) => ({
      ...previousState,
      dateEpoch: value
    }));

    if (value && calendarRuleListState.businessAreaId) {
      getTimeSlotSimulation(value, calendarRuleListState.businessAreaId);
      getEffectingRulesFor(value, calendarRuleListState.businessAreaId);
    }
  };

  const renderSimulationHeader = () => {
    return businessAreas ? (
      <>
        <Row md="6">
          <Col md="2">
            <FormLabel>Business Area: </FormLabel>
          </Col>
          <Col md="4">
            <Form.Control
              onChange={(e) => businessAreaChangedHandler(e.target.value)}
              md="3"
              as="select"
            >
              <option>Choose...</option>
              {businessAreas.map((businessArea) => (
                <option key={businessArea.id} value={businessArea.id}>
                  {businessArea.name}
                </option>
              ))}
            </Form.Control>
          </Col>
          <Col md="2">
            <FormLabel>Date: </FormLabel>
          </Col>
          <Col md="4">
            <DatePicker
              className="form-control"
              selected={calendarRuleListState.dateEpoch}
              onChange={dateChangedHandler}
            />
          </Col>
        </Row>
        <br />
      </>
    ) : null;
  };

  const renderSimulatedTimeSlots = () => {
    if (!simulatedTimeSlots) return null;
    return (
      <Row className="mt-3">
        <TimeSlotTable readOnly timeSlots={simulatedTimeSlots} />
      </Row>
    );
  };

  const renderRowClasses = (rule) => {
    if (
      rule &&
      simulatedTimeSlots &&
      effectingRules &&
      effectingRules.some((x) => x.id === rule.id)
    ) {
      return 'bg-success text-white';
    }
  };

  const renderFilters = () => {
    return (<>
      <Row md="6">
        <Col md="12">
          <select id="businessAreaCombobox" value={filteredBusinessAreaId} onChange={(e) => businessAreaFilterChanged(e.target.value)} style={{ padding: 6 }}>
            <option key={""} value={""}>Select Business Area...</option>
            { businessAreas && businessAreas.map((businessArea) => (
              <option key={businessArea.id} value={businessArea.id}>
                {businessArea.name}
              </option>
            ))}
          </select>
        </Col>
      </Row>
    </>);
  };

  const tableConfigurations = {
    title: 'Calendar Rules',
    noRowText: 'There are not any rules to show.',
    hasFilter: true,
    showFiltersOnInit: true,
    entities: filteredRules,
    columns: [
      {
        alias: 'Business Area',
        name: 'businessAreaId',
        type: 'custom',
        customRender: renderBusinessAreaColumn
      },
      { alias: 'Type', name: 'ruleType', type: 'string' },
      {
        alias: 'Number of Gates',
        name: 'gateQuota',
        type: 'number',
        defaultValue: defaultQuota ? defaultQuota.quota : null
      },
      {
        alias: 'Valid Time',
        name: null,
        type: 'custom',
        customRender: renderValidTimeColumn
      },
      {
        alias: 'Specific Date',
        name: 'dateEpoch',
        type: 'epoch',
        format: 'shortDate',
        style: { whiteSpace: 'nowrap' }
      },
      { alias: 'Weekday', name: 'weekday', type: 'string' },
      { alias: 'State', name: 'ruleState', type: 'string' },
      { alias: 'Slot Type', name: 'slotType', type: 'string' }
    ],
    tableActions: [
      {
        clickEvent: newConfigurationHandler,
        buttonText: 'Configurations',
        icon: 'cog'
      },
      {
        clickEvent: newRuleClickedHandler,
        buttonText: 'Create rule',
        icon: 'plus'
      },
      {
        clickEvent: openAdditionalGateClickedHandler,
        buttonText: 'Open additional gate',
        icon: 'angle-double-up'
      },
      {
        clickEvent: reservedSlotsClickedHandler,
        buttonText: 'Reserved Slots',
        icon: 'calendar-check'
      }
    ],
    rowActions: [
      {
        title: 'Delete rule',
        clickEvent: deleteRuleClickedHandler,
        icon: 'trash',
        hasConfirm: true,
        confirmText: 'Confirm to delete'
      }
    ],
    renderRowClasses: renderRowClasses,
    filterRenderer: renderFilters
  };

  return (
    <Page>
      <DefaultQuota />
      <XmsTable config={tableConfigurations} />
      <br />
      {/*TODO: GateScheduler dashboard link will come here (?)*/}
      {/*  <h4>Booking System Simulation</h4>
      <hr />
      {renderSimulationHeader()}
      <LoadingIndicator id="getTimeSlotSimulation" />
      {renderSimulatedTimeSlots()}
      <LoadingIndicator id="getAllRules" /> */}
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    defaultQuota: state.gateQuota.defaultConfig,
    businessAreas: state.calendar.businessAreas,
    rules: state.calendar.rules,
    simulatedTimeSlots: state.calendar.simulatedTimeSlots,
    effectingRules: state.calendar.effectingRules,
    filteredBusinessAreaId: state.calendar.filteredBusinessAreaId
  };
};
export default connect(mapStateToProps, {
  getRules,
  getBusinessAreas,
  deleteRule,
  getTimeSlotSimulation,
  getEffectingRulesFor,
  setFilteredBusinessAreaId
})(CalendarRuleList);
