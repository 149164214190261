import React from 'react';
import { connect } from 'react-redux';
import { showReport } from '../../store/action/reportActions';
import styles from '../Report/Report.module.css';
import { Col } from 'react-bootstrap';
import { Button } from '@dfds-ui/react-components'
import { Invoice } from '@dfds-ui/icons'
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';

const Report = (props) => {

    const {
        data,
        showReport,
    } = props

    const onReportItemClicked = (reportItem) => {
        console.log("reportItem: ", reportItem)
        if (reportItem.request.type === 'CsgNote') {
            showReport(reportItem.request, reportItem.title, 'getReports', false);
        } else {
            showReport(reportItem.request, reportItem.title, 'getReports');
        }
    }

    return (
        <>
            <div className='card'>
                <div className='card-header collapsed' href='#collapseReports' data-toggle="collapse" >
                    <h5 className='mb-0'>
                        <img src='/image/icon/down.svg' className='color-action-blue' width='20px' alt='' /> {'Reports'}
                    </h5>
                </div>
                <div id='collapseReports' className='collapse'>
                    <div className='card-body' mt={0}>
                        <div className='col px-0'>
                            <div className='row'>
                                <div className='col-12'>
                                    {data.filter(s => s.show).map((reportType, i) => {
                                        return (
                                            <div key={i} className='card mb-2'>
                                                <ul className="list-group list-group-flush">
                                                    <li className={`list-group-item ${styles['report-item']}`}>
                                                        <div className='row'>
                                                            <Col sm={8}>
                                                                <label className='mr-5'>{reportType.item.title} </label>
                                                            </Col>

                                                            <Col sm={4} className='ml-auto text-right'>
                                                                <Button size='small' variation='secondary' icon={<Invoice />} onClick={() => onReportItemClicked(reportType.item)}>Generate Report</Button>
                                                            </Col>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        )
                                    })}
                                    <LoadingIndicator id='getReports' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = ({ report }) => {
    if (!report) { return {} }
    return {
        reportResponse: report.reportResponse
    }
}

const mapActionToProps = {
    showReport,
}

export default connect(mapStateToProps, mapActionToProps)(Report);