import { combineReducers } from "redux";
import actionTypes from "./actionTypes";


const initialState = {
    shipmentSettings: {
        isRequesting: false,
        columns: undefined,
        errors: [],
    },
    articleSettings: {
        isRequesting: false,
        columns: undefined,
        errors: [],
    },
    containerBookingSettings: {
        isRequesting: false,
        columns: { All: [], Default: [] },
        errors: [],
    },
    settings: {
        isRequesting: false,
        value: null,
        errors: [],
    },
};


/** SHIPMENT SETTINGS */
function reduceShipmentSettings(state = initialState.shipmentSettings, action) {
    switch (action.type) {
        // READ
        case actionTypes.READ_SHIPMENT_SETTINGS_REQUESTING:
            return { ...state, isRequesting: true };
        case actionTypes.READ_SHIPMENT_SETTINGS_SUCCESS:
            return { ...state, isRequesting: false, columns: action.payload, errors: [] };
        case actionTypes.READ_SHIPMENT_SETTINGS_FAILURE:
            return { ...state, isRequesting: false, errors: action.payload };
        // end READ
        default: return state;
    }
}
/** end SHIPMENT SETTINGS */

/** ARTICLE SETTINGS */
function reduceArticleSettings(state = initialState.articleSettings, action) {
    switch (action.type) {
        // READ
        case actionTypes.READ_ARTICLE_SETTINGS_REQUESTING:
            return { ...state, isRequesting: true };
        case actionTypes.READ_ARTICLE_SETTINGS_SUCCESS:
            return { ...state, isRequesting: false, columns: action.payload, errors: [] };
        case actionTypes.READ_ARTICLE_SETTINGS_FAILURE:
            return { ...state, isRequesting: false, errors: action.payload };
        // end READ
        default: return state;
    }
}
/** end ARTICLE SETTINGS */

/** CONTAINER BOOKING SETTINGS */
function reduceContainerBookingSettings(state = initialState.articleSettings, action) {
    switch (action.type) {
        // READ
        case actionTypes.READ_CONTAINER_BOOKING_SETTINGS_REQUESTING:
            return { ...state, isRequesting: true };
        case actionTypes.READ_CONTAINER_BOOKING_SETTINGS_SUCCESS:
            return { ...state, isRequesting: false, columns: action.payload, errors: [] };
        case actionTypes.READ_CONTAINER_BOOKING_SETTINGS_FAILURE:
            return { ...state, isRequesting: false, errors: action.payload };
        // end READ
        default: return state;
    }
}
/** end CONTAINER BOOKING SETTINGS */

/** SETTINGS */
function reduceSettings(state = initialState.settings, action) {
    switch (action.type) {
        // READ
        case actionTypes.READ_SETTINGS_REQUESTING:
            return { ...state, isRequesting: true };
        case actionTypes.READ_SETTINGS_SUCCESS:
            return { ...state, isRequesting: false, value: action.payload, errors: [] };
        case actionTypes.READ_SETTINGS_FAILURE:
            return { ...state, isRequesting: false, errors: action.payload };
        // end READ
        default: return state;
    }
}
/** end SETTINGS */


const reducer = combineReducers({
    shipmentSettings: reduceShipmentSettings,
    articleSettings: reduceArticleSettings,
    containerBookingSettings: reduceContainerBookingSettings,
    settings: reduceSettings
});


export default reducer;