import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    createCompany,
    readCompany,
    updateCompany,
    deleteCompany
} from '../store/ducks/definitions/actionCreators-company';
import styles from './Company.module.css';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { ArrowBack, Delete } from '@dfds-ui/icons/system';
import { Spinner, SelectField } from '@dfds-ui/react-components';
import postSync from "../core/httpClientSync";
import { get } from "../core/httpClientSync";
import { toast } from 'react-toastify';
import { capitalCase } from '../core/utility';
import { justFirstLetterCapitalize } from '../core/utility';
import ActiveContract from '../component/Company/ActiveContract';
import DraftContract from '../component/Company/DraftContract';
import { getActiveContractByCompanyCode, getDraftContract } from '../store/action/contractActions';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import { clearContractState } from '../store/action/contractActions';

function Company({
    createCompany,
    readCompany,
    updateCompany,
    deleteCompany,
    company,
    companies,
    errors,
    createdCompany,
    activeContract,
    draftContract,
    getActiveContractByCompanyCode,
    getDraftContract,
    clearContractState,
    refresh
}) {

    const lookupURL = "xms-definition-api/api";
    const countryUrl = "xms-definition-api/api";

    // HISTORY, LOCATION, PARAMS
    let history = useHistory();
    let { companyId } = useParams();
    // end HISTORY, LOCATION, PARAMS

  // UI LOGIC HELPERS, on requesting initial data, show/hides
  const [isCreateMode, setIsCreateMode] = useState();

  useEffect(() => {
    return () => {
      clearContractState();
    };
  }, []);

  useEffect(() => {
    setIsCreateMode(companyId === 'create');
    if (companyId !== 'create') {
      readCompany(companyId);
    }
    if (refresh) {
      contractFetch(companyId);
    }

    fetchTypes();
    fetchCountries();
  }, [companyId, refresh]);
  // end UI LOGIC HELPERS

    // GET STATE AFTER COMPONENT RENDERS
   
    // end GET STATE AFTER COMPONENT RENDERS

    // STATE
    const [companyName, setCompanyName] = useState('');
    const [companyType, setCompanyType] = useState('');
    const [companyCode, setCompanyCode] = useState('');
    const [companyTypesList, setCompanyTypesList] = useState([]);
    const [companyIsClient, setCompanyIsClient] = useState(false);

    const [companyAddressCountry, setCompanyAddressCountry] = useState("");
    const [companyAddressCity, setCompanyAddressCity] = useState("");
    const [companyAddressPostalCode, setCompanyAddressPostalCode] = useState("");
    const [companyAddressAddressLine, setCompanyAddressAddressLine] = useState("");
    const [companyAddressCountryDataList, setCompanyAddressCountryDataList] = useState([]);
    const [validationErrors, setvalidationErrors] = useState({});

    // end STATE

    // SET STATE FROM PROPS
    useEffect(() => {
        if (company) {
            setCompanyName(company.name);
            setCompanyType(company.type.toString());
            setCompanyCode(company.code);
            setCompanyIsClient(company.isClient);
            if (company.address) {
                setCompanyAddressAddressLine(company.address.addressLine);
                setCompanyAddressCity(company.address.city);
                setCompanyAddressPostalCode(company.address.postalCode);
                if (company.address.country) {
                    setCompanyAddressCountry(company.address.country.code);
                }
            }
        }
    }, [company]); // company from selector
    // end SET STATE FROM PROPS

    useEffect(() => {
        if (errors && errors.response) {
            // var a = errors.response.data.errors[0].description;
            // toast.error(a);
        }
    }, [errors]);

    useEffect(() => {
        if (createdCompany && createdCompany.id) {
            history.replace(`/companies/${createdCompany.id}`);
            toast.success(`Company is created successfully. Code:${createdCompany.code}, Name: ${createdCompany.name}`);
        }
    }, [createdCompany]);

    useEffect(() => {
        if (!companyName) {
            setvalidationErrors(state => ({ ...state, companyName: 'Company name must be provided.' }));
        }
        else {
            setvalidationErrors(state => ({ ...state, companyName: '' }));
        }

        if (parseInt(companyType) <= -1 || !companyType) {
            setvalidationErrors(state => ({ ...state, companyType: 'Company type must be provided.' }));
        }
        else {
            setvalidationErrors(state => ({ ...state, companyType: '' }));
        }

        if (!companyCode) {
            setvalidationErrors(state => ({ ...state, companyCode: 'Company code must be provided.' }));
        }
        else {
            setvalidationErrors(state => ({ ...state, companyCode: '' }));
        }

    }, [companyName, companyType, companyCode])

    const contractFetch = (code) => {
      if (code) {
        getActiveContractByCompanyCode(code);
        getDraftContract(code);
      }
    };

    const isValidToSubmit = () => {

        if (validationErrors) {
            var isValid = Object.values(validationErrors).every(item => item === '');
            return isValid;
        }

        return true;
    }

    // SUBMIT
    const onSubmit = async (e) => {
        e.preventDefault();

        if (!isValidToSubmit()) {
            return;
        }

        const payload = {
            Id: isCreateMode ? null : companyId,
            Code: companyCode,
            Name: companyName,
            Type: parseInt(companyType),
            IsClient: companyIsClient,
            Address: null
        };

        if (companyAddressAddressLine || companyAddressCity || companyAddressPostalCode) {
            payload.Address = {
                AddressLine: companyAddressAddressLine,
                PostalCode: companyAddressPostalCode,
                City: companyAddressCity,
                Country: null
            }
        }

        if (companyAddressCountry) {
            if (!payload.Address) {
                payload.Address = {
                    AddressLine: null,
                    PostalCode: null,
                    City: null,
                    Country: null
                }
            }

            payload.Address.Country = {
                Code: companyAddressCountry,
                Name: getCountryName(companyAddressCountry)
            };
        }

        if (isCreateMode) {
            await createCompany(payload);

        } else {
            await updateCompany(payload);
        }
    }
    // end SUBMIT

    // DELETE
    const onClickDelete = async () => {
        if (window.confirm('Are you sure you want to delete this item?')) {
            await deleteCompany(companyId);
            history.goBack();
        }
    }
    // end DELETE

    // BACK
    const onClickBack = () => history.push('/companies');
    // end BACK

    async function fetchTypes() {
        const result = await get(`${lookupURL}/lookups?type=CompanyType`, null);
        if (result.isSuccess) {

          let lookup = result.response.result.lookup.values;
            var dataList = lookup.map((each) => {
                return Object.assign({
                    key: each.name,
                    value: each.value,
                    type: each.name
                });
            });
            setCompanyTypesList(dataList);
        } else {
            setCompanyTypesList([]);
        }
    }

    async function fetchCountries() {
        const response = await get(`${countryUrl}/country/list`, null);
        if (response.isSuccess) {
            let countries = response.response.result;
            var dataList = countries.map((each) => {
                return Object.assign({
                    key: each.id,
                    value: each.id,
                    type: each.name
                })
            }).sort(compare);
            setCompanyAddressCountryDataList(dataList);
        } else {
            setCompanyAddressCountryDataList([]);
        }
    }

    function compare(country1, country2) {
        const str1 = country1.type.toUpperCase();
        const str2 = country2.type.toUpperCase();

        let comparison = 0;
        if (str1 > str2) {
            comparison = 1;
        } else if (str1 < str2) {
            comparison = -1;
        }
        return comparison;
    }

    const renderSelectBox = (label, value, list, onChange) => {
        return (
            <div className='form-group'>
                <SelectField selected={value} onChange={(e) => onChange(e.target.value)} size='small' className='selectbox-labelless'>
                    <option value="">{label}</option>
                    {list.map((item) => (
                        <option value={item.value} key={item.key}>
                            {capitalCase(item.type)}
                        </option>
                    ))}
                </SelectField>
            </div>
        )
    }

    const typeOnChange = (val) => {
        setCompanyType(val);
    }

    const isClientOnChange = (e) => {
        e.persist();
        setCompanyIsClient(e.target.checked);
    }

    const countryOnChange = (val) => {
        setCompanyAddressCountry(val);
    }

    const getCountryName = (countryCode) => {
        var countries = companyAddressCountryDataList.filter(eachCountry => eachCountry.value == countryCode);
        if (countries && countries.length > 0) {
            return countries[0].type;
        }
        return null;
    }

    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                <Button style={{ fontSize: '14px', marginBottom: '17px' }} className='color-action-blue' variant="transparent" onClick={() => history.push(`/companies`)}>
                                    <FontAwesomeIcon icon="chevron-left" size='lg' />
                                    <span style={{ paddingLeft: '10px' }}>
                                        {`Back to Companies`}
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <main>
                            <section>
                                {
                                    !companies.isRequesting &&

                                    <form onSubmit={onSubmit}>
                                        <Row>
                                            <Col xs="12" sm="6" md="6" lg="6">
                                                <label htmlFor="code" className={styles.label}>Code *</label>
                                                <input id="code" value={companyCode} onChange={e => setCompanyCode(e.target.value)} /><br></br>
                                                <small style={{ visibility: validationErrors.companyCode ? 'visible' : 'hidden' }} className="form-text text-danger">{validationErrors.companyCode || ' '}</small>
                                            </Col>
                                            <Col xs="12" sm="6" md="6" lg="6">
                                                <label htmlFor="name" className={styles.label}>Company Name *</label>
                                                <input id="name" value={companyName} onChange={e => setCompanyName(e.target.value)} /><br></br>
                                                <small style={{ visibility: validationErrors.companyName ? 'visible' : 'hidden' }} className="form-text text-danger">{validationErrors.companyName || ' '}</small>
                                            </Col>
                                            <Col xs="12" sm="6" md="6" lg="6">
                                                <label htmlFor="type" className={styles.label}>Type *</label>
                                                {renderSelectBox("Select Company Type", companyType, companyTypesList, typeOnChange)}
                                                <small style={{ visibility: validationErrors.companyType ? 'visible' : 'hidden' }} className="form-text text-danger">{validationErrors.companyType || ' '}</small>
                                            </Col>
                                            <Col xs="12" sm="6" md="6" lg="6">
                                                <label htmlFor="isclient" className={styles.label}>Is Client</label><br></br>
                                                <input type="checkbox" id="isclient" checked={companyIsClient} onChange={isClientOnChange} />
                                            </Col>
                                            <Col xs="12" sm="6" md="6" lg="6">
                                                <label htmlFor="country" className={styles.label}>Country</label>
                                                {renderSelectBox("Select Country", companyAddressCountry, companyAddressCountryDataList, countryOnChange)}
                                            </Col>
                                            <Col xs="12" sm="6" md="6" lg="6">
                                                <label htmlFor="city" className={styles.label}>City</label>
                                                <input id="city" value={companyAddressCity} onChange={e => setCompanyAddressCity(e.target.value)} /><br></br>
                                                <small style={{ visibility: 'hidden' }} className="form-text text-danger">a</small>
                                            </Col>
                                            <Col xs="12" sm="6" md="6" lg="6">
                                                <label htmlFor="postalCode" className={styles.label}>Postal Colde</label>
                                                <input id="postalCode" value={companyAddressPostalCode} onChange={e => setCompanyAddressPostalCode(e.target.value)} /><br></br>
                                                <small style={{ visibility: 'hidden' }} className="form-text text-danger">a</small>
                                            </Col>
                                            <Col xs="12" sm="6" md="6" lg="6">
                                                <label htmlFor="address" className={styles.label}>Address</label>
                                                <input id="address" value={companyAddressAddressLine} onChange={e => setCompanyAddressAddressLine(e.target.value)} /><br></br>
                                                <small style={{ visibility: 'hidden' }} className="form-text text-danger">a</small>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col></Col>

                                            <Col xs="12" sm="6" md="6" lg="4" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                {/*<input type="reset" value="Reset" />*/}
                                                <input type="submit" value={isCreateMode ? 'Create' : 'Update'} />
                                            </Col>
                                        </Row>
                                    </form>
                                }

                            </section>
                        </main>
                    </Col>
                </Row>
                <Row>
                  <Col>
                    <Card>
                      <Card.Body>
                        <ActiveContract
                          hidden={isCreateMode || (draftContract && draftContract.id)}
                          activeContract={activeContract}
                          companyCode={company ? company.id : null}
                          company={company}
                        ></ActiveContract>
                        <DraftContract
                          hidden={isCreateMode}
                          draftContract={draftContract}
                          companyCode={company ? company.id : null}
                          company={company}
                        ></DraftContract>
                        <LoadingIndicator id="contractDetail" />
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
            </Card.Body>
        </Card>
        
    );
}

const mapStateToProps = ({ definitions, contract }, ownProps) => {
  return {
    companies: definitions.companies,
    company: definitions.companies.items.find(
      (item) => item.id === ownProps.match.params.companyId
    ),
    errors: definitions.companies.errors,
    createdCompany: definitions.companies.createdCompany,
    activeContract: contract.activeContract,
    draftContract: contract.draftContract,
    refresh: contract.refresh
  };
};

const mapDispatchToProps = {
  createCompany,
  readCompany,
  updateCompany,
  deleteCompany,
  getActiveContractByCompanyCode,
  getDraftContract,
  clearContractState
};

export default connect(mapStateToProps, mapDispatchToProps)(Company);