import post, { get } from "../../core/httpClientSync";
import shipmentsTypes from "../type/shipments";
import { toast } from "react-toastify";
import { addQueryString } from '../../core/utility'

const apiUrl = "xms-shipment-api";
// const apiUrl = 'http://localhost:5000';

export const getSuffixList = () => async (dispatch) => {
  const url = "xms-definition-api/api/suffix/get-suffix-list";
  const suffixResult = await post(url, null, null);

  if (suffixResult.isSuccess) {
    var suffixList = suffixResult.response.suffixList.map((item) => {
      return {
        value: item.suffixName,
        text: item.suffixName,
      };
    });

    dispatch({
      type: shipmentsTypes.GET_SUFFIX_LIST,
      payload: suffixList,
    });
  }
};

export const getConnectionPointsList = () => async (dispatch) => {
  const url = "xms-definition-api/api/connectionpoint/list-connection-point";
  const connectionGroupResult = await post(url, null, null);

  if (connectionGroupResult.isSuccess) {
    var ConnectionPointsList = connectionGroupResult.response.connectionPoints.map(
      (item) => {
        return {
          value: item.name,
          text: item.name,
        };
      }
    );

    dispatch({
      type: shipmentsTypes.GET_SUFFIX_LIST,
      payload: ConnectionPointsList,
    });
  }
};

export const getStatusList = () => async (dispatch) => {
  let statusList = [
    { value: "WaitingPick", text: "Unpicked" },
    { value: "Picked", text: "Picked" },
  ];

  dispatch({
    type: shipmentsTypes.GET_STATUS_LIST,
    payload: statusList,
  });
};

export const getOperation = (id, spinnerElementId = null) => async dispatch => {

  const url = `${apiUrl}/api/operations/${id}`;
  const response = await get(url, spinnerElementId);

  if (response.isSuccess && response.response) {
    dispatch({
      type: shipmentsTypes.OPERATION_RECEIVED,
      payload: response.response.result.operation
    })
  }
}

export const getShipmentList = (criteria, spinnerElementId = null) => async (dispatch) => {

  let url = '';
  if (!criteria.pageTypeEnum.inStock)
    url = `${apiUrl}/api/deliveries/picked-list?operationId=${criteria.operationId}&status=${criteria.status}`;
  else
    url = `${apiUrl}/api/deliveries/instock-list?type=${criteria.shipmentType}&${addQueryString('destinationIdList', criteria.suffixList)}`;

  const shipmentsResult = await get(url, spinnerElementId);

  if (shipmentsResult.isSuccess) {
    var shipmentList = shipmentsResult.response.result.shipments;

    if (
      criteria.filteredThuNumbers != undefined &&
      criteria.filteredThuNumbers.length > 0
    ) {
      shipmentList.forEach((shipment) => {
        shipment.articleList = shipment.articleList.filter(
          (thu) => criteria.filteredThuNumbers.indexOf(thu.externalId) > -1
        );
      });

      shipmentList = shipmentList.filter((s) => s.articleList.length > 0);
    }

    dispatch({
      type: shipmentsTypes.GET_SHIPMENT_LIST,
      payload: shipmentList,
    });
  }
};

export const saveShipmentsToUnit = (params, spinnerElementId = null) => async (dispatch) => {

  let url = `${apiUrl}/api/deliveries/add-shipments`;

  if (params.pageTypeEnum.waitingPick)
    url = `${apiUrl}/api/deliveries/picked`;
  else if (params.pageTypeEnum.picked)
    url = `${apiUrl}/api/deliveries/unpicked`;

  const request = {
    operationId: params.operationId,
    groupId: params.groupId,
    articleList: params.articleList,
    shipmentType: params.shipmentType
  };

  const result = await post(url, request, spinnerElementId);

  if (result.isSuccess) {
    toast.success("Thu's Updated");
    dispatch(getShipmentList(params, spinnerElementId));
  }
};

export const clearShipmentsState = () => async (dispatch) => {
  dispatch({
    type: shipmentsTypes.CLEAR_ADD_SHIPMENTS_STATE,
  });
};

export const getGroupList = (ids) => async (dispatch) => {
  if (ids && ids.length > 0) {
    const queryString = addQueryString('idList', ids);
    const url = `${apiUrl}/api/groups/list?${queryString}`;

    const result = await get(url);

    if (result.isSuccess) {
      dispatch({
        type: shipmentsTypes.GET_OPERATIONTRANSPORT_LIST,
        payload: result.response.result.groups,
      });
    }
  }
};