/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { getActionUrlInfo } from '../store/action/actionUrlActions';
import { spinnerService } from '@simply007org/react-spinners';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import { findActionUrl } from '../store/action/operationActions';
import { downloadAllDocuments, clearDownloadStatus } from '../store/action/fileControllerAction';

function ActionUrl({ match, downloadAllDocuments, clearDownloadStatus, isFileDownloaded, fileDownloadErrorMessage }) {
    const guid = match.params.guid;
    const [errorMessage, setErrorMessage] = useState();
    const [currentStatus, setCurrentStatus] = useState('Processing');

    useEffect(() => {
        clearDownloadStatus();
        downloadDocument();
    }, [])

    useEffect(() => {
        if (isFileDownloaded) {
            setCurrentStatus('Process completed');
        }
    }, [isFileDownloaded])

    useEffect(() => {
        setCurrentStatus(fileDownloadErrorMessage);
    }, [fileDownloadErrorMessage])

    const downloadDocument = async () => {
        const getActionUrlInfoResponse = await getActionUrlInfo(guid, 'process');

        if (!getActionUrlInfoResponse.isSuccess) {
            const errorMessage = extractErrorMessage(getActionUrlInfoResponse);
            setErrorMessage(errorMessage);
            spinnerService.hide('process');
            return;
        }

        const actionUrlInfo = getActionUrlInfoResponse.response.actionUrl;

        if (actionUrlInfo.actionType === 'download-unit-documents') {
            const unitInfo = await findActionUrl(actionUrlInfo.actionKey, 'process');
            const relativePathList = [unitInfo.relativePath, ...unitInfo.shipmentRelativePathList];
            downloadAllDocuments(relativePathList, unitInfo.relativePath, 'process');
        } else if (actionUrlInfo.actionType === 'download-shipment-documents') {
            const relativePathList = [concatShipment(actionUrlInfo.actionKey)];
            downloadAllDocuments(relativePathList, actionUrlInfo.actionKey, 'process');
        }
    }

    const renderCusrrentStatus = () => {
        if (errorMessage) { return; }
        return (
            <h5 className="text-center">
                <label>{currentStatus}</label>
            </h5>
        )
    }

    const renderErrorMessage = () => {

        if (!errorMessage) { return; }

        return (
            <h5 className="text-center text-danger">
                <label>{errorMessage}</label>
            </h5>
        )
    }

    return (<>
        <div style={{ position: 'relative' }}>
            {renderCusrrentStatus()}
            {renderErrorMessage()}
            <LoadingIndicator id="process" />
        </div>
    </>)
}

const concatShipment = relativePath => relativePath ? `Shipment/${relativePath}` : 'Shipment';

const extractErrorMessage = errorResponse => {

    if (
        errorResponse &&
        errorResponse.error &&
        errorResponse.error.response &&
        errorResponse.error.response.data &&
        errorResponse.error.response.data.errors &&
        errorResponse.error.response.data.errors.length &&
        errorResponse.error.response.data.errors[0].description
    ) {
        return errorResponse.error.response.data.errors[0].description;
    }

    return;

}

const mapStateToProps = ({ fileController }) => {
    return {
        isFileDownloaded: fileController.isFileDownloaded,
        fileDownloadErrorMessage: fileController.errorMessage
    }
}

export default connect(mapStateToProps, { downloadAllDocuments, clearDownloadStatus })(ActionUrl);
