/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getBusinessAreaList, deleteBusinessArea } from '../store/action/businessAreaActions';
import history from '../core/history';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import XmsTable from '../component/XmsTable/XmsTable';
import Page from '../component/Page/Page';

const BusinessAreaList = ({ businessAreaList, getBusinessAreaList, deleteBusinessArea }) => {
  useEffect(() => {
    if (!businessAreaList) {
      getBusinessAreaList();
    }
  }, [businessAreaList]);

  const businessAreaClickedHandler = (businessArea) => {
    history.push('/businessarea/' + businessArea.id);
  };
  const newBusinessAreaClickedHandler = () => {
    history.push('/businessarea/new');
  };

  const deleteBusinessAreaClickedHandler = (businessArea) => {
    //TODO: Change with confirm modal when anatolia push it to master
    console.log('Deleting business area', businessArea);
    // eslint-disable-next-line no-restricted-globals
    deleteBusinessArea(businessArea.id);
  };

  const tableConfigurations = {
    title: 'Business Areas',
    noRowText: '',
    hasFilter: false,
    entities: businessAreaList,
    columns: [
      { alias: 'Name', name: 'name', type: 'string' },
      { alias: 'Associated Company Count', name: 'companyCount', type: 'string' }
    ],
    tableActions: [
      { clickEvent: newBusinessAreaClickedHandler, buttonText: 'Create', icon: 'plus' }
    ],
    rowActions: [
      { title: 'Edit business area', clickEvent: businessAreaClickedHandler, icon: 'edit' },
      { title: 'Delete business area', clickEvent: deleteBusinessAreaClickedHandler, icon: 'trash' }
    ]
  };

  return (
    <Page>
      <XmsTable config={tableConfigurations} />
      <LoadingIndicator id="getBusinessAreaList" />
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    businessAreaList: state.businessArea.businessAreaList
  };
};
export default connect(mapStateToProps, { getBusinessAreaList, deleteBusinessArea })(
  BusinessAreaList
);
