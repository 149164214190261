
export default Object.freeze({
    CREATE_VOYAGE_REQUESTING: 'astrid/voyages/CREATE_VOYAGE_REQUESTING',
    CREATE_VOYAGE_SUCCESS: 'astrid/voyages/CREATE_VOYAGE_SUCCESS',
    CREATE_VOYAGE_FAILURE: 'astrid/voyages/CREATE_VOYAGE_FAILURE',

    READ_VOYAGE_REQUESTING: 'astrid/voyages/READ_VOYAGE_REQUESTING',
    READ_VOYAGE_SUCCESS: 'astrid/voyages/READ_VOYAGE_SUCCESS',
    READ_VOYAGE_FAILURE: 'astrid/voyages/READ_VOYAGE_FAILURE',

    READ_VOYAGES_REQUESTING: 'astrid/voyages/READ_VOYAGES_REQUESTING',
    READ_VOYAGES_SUCCESS: 'astrid/voyages/READ_VOYAGES_SUCCESS',
    READ_VOYAGES_FAILURE: 'astrid/voyages/READ_VOYAGES_FAILURE',

    UPDATE_VOYAGE_REQUESTING: 'astrid/voyages/UPDATE_VOYAGE_REQUESTING',
    UPDATE_VOYAGE_SUCCESS: 'astrid/voyages/UPDATE_VOYAGE_SUCCESS',
    UPDATE_VOYAGE_FAILURE: 'astrid/voyages/UPDATE_VOYAGE_FAILURE',

    DELETE_VOYAGES_REQUESTING: 'astrid/voyages/DELETE_VOYAGES_REQUESTING',
    DELETE_VOYAGES_SUCCESS: 'astrid/voyages/DELETE_VOYAGES_SUCCESS',
    DELETE_VOYAGES_FAILURE: 'astrid/voyages/DELETE_VOYAGES_FAILURE',

    FILTER_VOYAGES_REQUESTING: 'astrid/voyages/FILTER_VOYAGES_REQUESTING',
    FILTER_VOYAGES_SUCCESS: 'astrid/voyages/FILTER_VOYAGES_SUCCESS',
    FILTER_VOYAGES_FAILURE: 'astrid/voyages/FILTER_VOYAGES_FAILURE',
});