
export default Object.freeze({
    CREATE_CONTAINER_BOOKING_REQUESTING: 'astrid/container-bookings/CREATE_CONTAINER_BOOKING_REQUESTING',
    CREATE_CONTAINER_BOOKING_SUCCESS: 'astrid/container-bookings/CREATE_CONTAINER_BOOKING_SUCCESS',
    CREATE_CONTAINER_BOOKING_FAILURE: 'astrid/container-bookings/CREATE_CONTAINER_BOOKING_FAILURE',

    READ_CONTAINER_BOOKING_REQUESTING: 'astrid/container-bookings/READ_CONTAINER_BOOKING_REQUESTING',
    READ_CONTAINER_BOOKING_SUCCESS: 'astrid/container-bookings/READ_CONTAINER_BOOKING_SUCCESS',
    READ_CONTAINER_BOOKING_FAILURE: 'astrid/container-bookings/READ_CONTAINER_BOOKING_FAILURE',

    READ_CONTAINER_BOOKINGS_REQUESTING: 'astrid/container-bookings/READ_CONTAINER_BOOKINGS_REQUESTING',
    READ_CONTAINER_BOOKINGS_SUCCESS: 'astrid/container-bookings/READ_CONTAINER_BOOKINGS_SUCCESS',
    READ_CONTAINER_BOOKINGS_FAILURE: 'astrid/container-bookings/READ_CONTAINER_BOOKINGS_FAILURE',

    UPDATE_CONTAINER_BOOKING_REQUESTING: 'astrid/container-bookings/UPDATE_CONTAINER_BOOKING_REQUESTING',
    UPDATE_CONTAINER_BOOKING_SUCCESS: 'astrid/container-bookings/UPDATE_CONTAINER_BOOKING_SUCCESS',
    UPDATE_CONTAINER_BOOKING_FAILURE: 'astrid/container-bookings/UPDATE_CONTAINER_BOOKING_FAILURE',

    DELETE_CONTAINER_BOOKINGS_REQUESTING: 'astrid/container-bookings/DELETE_CONTAINER_BOOKINGS_REQUESTING',
    DELETE_CONTAINER_BOOKINGS_SUCCESS: 'astrid/container-bookings/DELETE_CONTAINER_BOOKINGS_SUCCESS',
    DELETE_CONTAINER_BOOKINGS_FAILURE: 'astrid/container-bookings/DELETE_CONTAINER_BOOKINGS_FAILURE',

    SET_FILTER: 'astrid/container-bookings/SET_FILTER',
    SET_FILTERS: 'astrid/container-bookings/SET_FILTERS',
    SET_FILTERS_AUX: 'astrid/container-bookings/SET_FILTERS_AUX',
    RESET_ALL_FILTERS: 'astrid/container-bookings/RESET_ALL_FILTERS',

    SORT_CONTAINER_BOOKINGS: 'astrid/container-bookings/SORT_CONTAINER_BOOKINGS',
});