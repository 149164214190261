import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import PageHeader from '../component/PageHeader/PageHeader';
import { connect } from 'react-redux';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import { clearState, getOperation, getList, linkOperation, unlinkOperation, saveItem, editItem, deleteItem } from '../store/action/transportAction';
import { confirmationModal } from '../store/action/modalActions';
import TransportList from '../component/Transport/TransportList';
import Form from '../component/Transport/Form';
import { TYPES as OPERATIONTYPES } from '../constants/operation/operation';

const Transport = (props) => {

    //redux
    const {
        clearState,
        getOperation,
        getList,
        linkOperation,
        unlinkOperation,
        operation,
        availableList,
        operationList,
        confirmationModal,
        saveItem,
        editItem,
        deleteItem,
        transportItem,
        saveCount
    } = props;

    //parent
    const {
        operationId
    } = props.match.params;

    const [isCheckLinkAll, setIsCheckLinkAll] = useState(false);
    const [isCheckUnlinkAll, setIsCheckUnlinkAll] = useState(false);

    const [toOperationList, setToOperationList] = useState([]);
    const [fromOperationList, setFromOperationList] = useState([]);

    const [transport, setTransport] = useState(transportItem ? transportItem : {})
    const [isEdit, setIsEdit] = useState(transportItem)

    const CONSTANT = {
        FORMLOADER: 'loader-transport-form',
        LISTLOADER: 'loader-transport-list',
    }

    useEffect(() => {
        clearState();
        getOperation(operationId, CONSTANT.FORMLOADER);
    }, [])

    useEffect(() => {
        if (operation) {
            getList(operation.type === OPERATIONTYPES.LOADING ? 0 : 1, operation.groupIdList, null, 1, CONSTANT.LISTLOADER);
        }
    }, [operation])

    useEffect(() => {
        setToOperationList([]);
        setFromOperationList([]);
        setIsCheckLinkAll(false);
        setIsCheckUnlinkAll(false);
    }, [availableList, operationList]);

    useEffect(() => {
        setTransport(transportItem ? transportItem : {})
        setIsEdit(transportItem)
    }, [transportItem])

    useEffect(() => {
        if (operationId) {
            onClearClick();
        }
    }, [saveCount])

    //#region availableList

    const onCheckLinkAll = (isChecked) => {
        setToOperationList((prevState) => {
            let list = [...prevState];
            if (isChecked) {
                list = availableList.map(s => s.id);
            } else {
                list = [];
            }
            return list;
        });
        setIsCheckLinkAll(isChecked)
    }

    const onCheckLinkTransport = (id, isChecked) => {
        setToOperationList((prevState) => {
            let list = [...prevState];
            if (isChecked && list.indexOf(id) < 0) {
                list.push(id)
            }
            if (!isChecked) {
                list = list.filter(x => x != id);
            }

            setIsCheckLinkAll(availableList.length === list.length)
            return list;
        });
    }

    const isCheckLinkTransport = (id) => {
        return toOperationList.indexOf(id) > -1;
    }

    const onClickLink = () => {
        confirmationModal('Transport To Unit', 'Transport(s) will be related with this unit. Do you approve?', () => linkOperation(toOperationList, operationId, CONSTANT.LISTLOADER));
    }

    //#endregion availableList

    //#region operationList

    const onCheckUnlinkAll = (isChecked) => {
        setFromOperationList((prevState) => {
            let list = [...prevState];
            if (isChecked) {
                list = operationList.map(s => s.id);
            } else {
                list = [];
            }
            return list;
        });
        setIsCheckUnlinkAll(isChecked)
    }

    const onCheckUnlinkTransport = (id, isChecked) => {
        setFromOperationList((prevState) => {
            let list = [...prevState];
            if (isChecked && list.indexOf(id) < 0) {
                list.push(id)
            }
            if (!isChecked) {
                list = list.filter(x => x != id);
            }

            setIsCheckUnlinkAll(operationList.length === list.length)
            return list;
        });
    }

    const isCheckUnlinkTransport = (id) => {
        return fromOperationList.indexOf(id) > -1;
    }

    const onClickUnlink = () => {
        confirmationModal('Transport From Operation', 'Transport(s) will be removed from this unit. Do you approve?', () => unlinkOperation(fromOperationList, operationId, CONSTANT.LISTLOADER));
    }

    //#endregion operationList

    const onSaveClick = (transport) => {
        confirmationModal('Transport Save', 'Transport(s) will save. Do you approve?', () => saveItem(transport, CONSTANT.FORMLOADER));
    }

    const onEditClick = (transport) => {
        editItem(transport, CONSTANT.FORMLOADER);
    }

    const onDeleteClick = (transport) => {
        confirmationModal('Transport Delete', 'Transport(s) will delete. Do you approve?', () => deleteItem(transport, CONSTANT.LISTLOADER));
    }

    const onSearch = (text, status) => {
        if (text)
            getList(operation.type === OPERATIONTYPES.LOADING ? 0 : 1, null, text, status, CONSTANT.LISTLOADER);
    }

    const onClearClick = () => {
        setTransport({});
        setIsEdit(false);
    }

    const onItemChange = (event) => {
        setTransport({
            ...transport,
            [event.target.name]: event.target.value
        });
    }

    return (<>
        <Row>
            <Col>
                <PageHeader text={`Transport Management ${operation ? ' - Unit : ' + operation.unitCode : ''}`} />
            </Col>
        </Row>
        <Row>
            <Col>
                <Form transport={transport} isEdit={isEdit} loader={CONSTANT.FORMLOADER} onItemChange={onItemChange} onSaveClick={onSaveClick} onClearClick={onClearClick} />
            </Col>
        </Row>
        <Row className='mt-3'>
            <LoadingIndicator id={CONSTANT.LISTLOADER} hide />
            <Col xs={6}>
                <TransportList
                    isShow={operation != null}
                    transportList={availableList}
                    onCheckAll={onCheckLinkAll}
                    isCheckAll={isCheckLinkAll}
                    onCheckTransport={onCheckLinkTransport}
                    isCheckTransport={isCheckLinkTransport}
                    onEditClick={onEditClick}
                    onDeleteClick={onDeleteClick}
                    onSearch={onSearch}
                    status={0}
                    headerOptions={{ text: `${operation ? operation.type : ''} Available List`, buttonText: 'Link', onClick: onClickLink, count: toOperationList.length }} />
            </Col>
            <Col xs={6}>
                <TransportList
                    isShow={operation != null}
                    transportList={operationList}
                    onCheckAll={onCheckUnlinkAll}
                    isCheckAll={isCheckUnlinkAll}
                    onCheckTransport={onCheckUnlinkTransport}
                    isCheckTransport={isCheckUnlinkTransport}
                    status={1}
                    headerOptions={{ text: ` ${operation ? operation.unitCode : ''} Operation List`, buttonText: 'Unlink', onClick: onClickUnlink, count: fromOperationList.length }} />
            </Col>
        </Row>
    </>)
}

const mapActionToProps = {
    clearState,
    getOperation,
    getList,
    linkOperation,
    unlinkOperation,
    confirmationModal,
    saveItem,
    editItem,
    deleteItem
}

const mapStateToProps = ({ transport }) => {
    return {
        operation: transport.operation,
        availableList: transport.availableList,
        operationList: transport.operationList,
        transportItem: transport.transportItem,
        saveCount: transport.saveCount
    }
}

export default connect(mapStateToProps, mapActionToProps)(Transport);