import shipmentDetailsTypes from '../type/shipmentDetailsTypes';
import thuDetailTypes from '../type/thuDetailTypes';
import post, { get } from '../../core/httpClientSync';
import modalActionTypes from '../type/modal';
import { mapObject, addQueryString } from '../../core/utility';
import history from '../../core/history';
import { toast } from 'react-toastify';

const apiUrl = 'xms-shipment-api';
// const apiUrl = 'http://localhost:5000';

export const getShipment = (id, spinnerElementId = null) => async dispatch => {
    dispatch(filterShipmentRequesting());

    const url = `${apiUrl}/api/shipments/${id}`;
    const response = await get(url, spinnerElementId);

    if (response.isSuccess) {

        const shipment = mapObject(response.response.result.shipment || {}, (key, value) => (value === null || value === undefined) ? { [key]: "" } : { [key]: value });

        dispatch({
            type: shipmentDetailsTypes.SHIPMENT_RECEIVED,
            payload: shipment
        })

        dispatch({
            type: thuDetailTypes.THU_LISTED,
            payload: shipment.articleList
        })
    }
}

export const getRelatedShipments = (id) => async dispatch => {
    const url = `${apiUrl}/api/shipments?relatedShipmentId=${id}`;
    const response = await get(url, null, true);

    if (response.isSuccess) {

        const relatedShipmentList = response.response.result.shipments;

        dispatch({
            type: shipmentDetailsTypes.RELATEDSHIPMENTS_RECEIVED,
            payload: relatedShipmentList
        })

    }
}

const filterShipmentRequesting = () => {
    return {
        type: shipmentDetailsTypes.FILTER_SHIPMENT_REQUESTING
    };
}

export const changeMissingDocsState = (id, docsMissing, spinnerId = null) => async dispatch => {

    const url = `${apiUrl}/api/shipments/set-docs-missing`;

    const request = { id, docsMissing };
    const response = await post(url, request, spinnerId);

    if (response.isSuccess) {
        dispatch({
            type: shipmentDetailsTypes.SET_SHIPMENT_DOCS_MISSING_STATE,
            payload: docsMissing
        })
    }
}

export const changeMissingDocsHoldState = (id, state, spinnerId = null) => async dispatch => {

    const url = `${apiUrl}/api/shipments/hold-docs-missing/${id}`;
    const response = await post(url, null, spinnerId);

    if (response.isSuccess) {
        dispatch({
            type: shipmentDetailsTypes.SET_SHIPMENT_DOCS_MISSING_HOLD_STATE,
            payload: !state
        })
    }
}

export const clearShipmentDetailData = () => async dispatch => {
    dispatch({
        type: shipmentDetailsTypes.CLEAR_SHIPMENT_DETAIL_DATA
    });
}

export const showHazardousModal = (shipmentId, item) => async dispatch => {
    dispatch({
        type: modalActionTypes.SHOW_MODAL,
        payload: {
            modalType: 'addorupdate-hazardous',
            modalData: { shipmentId: shipmentId, item: item }
        }
    })
}

export const addOrUpdateHazardous = (shipmentId, item, spinnerElementId = null) => async dispatch => {

    const url = `${apiUrl}/api/shipments/add-or-update-hazardous-item`;
    const request = { shipmentId: shipmentId, hazardousItem: item };
    const response = await post(url, request, spinnerElementId);

    if (response.isSuccess) {
        dispatch({
            type: shipmentDetailsTypes.HAZARDOUS_UPDATED,
            payload: response.response ? response.response.hazardousItem : item
        })
    }
}

export const deleteHazardousItem = (shipmentId, id, spinnerElementId = null) => async dispatch => {
    const request = { shipmentId: shipmentId, hazardousItemId: id };
    const url = `${apiUrl}/api/shipments/delete-hazardous-item`;
    const response = await post(url, request, spinnerElementId);

    if (response.isSuccess) {
        dispatch({
            type: shipmentDetailsTypes.HAZARDOUS_DELETED,
            payload: id
        })
    }
}

/** TOGGLE EDITING GRANT */
export function toggleEditingGranted(payload, spinnerElementId = null) {
    return async dispatch => {
        const url = `${apiUrl}/api/shipments/toggle-edit`;
        const response = await post(url, payload, spinnerElementId);

        if (response.isSuccess) {
            dispatch({
                type: shipmentDetailsTypes.TOGGLE_EDITING_GRANTED,
                payload: response.response.edit
            });
        }
    }
}

export function toggleEditingGrant(payload, spinnerElementId = null) {
    return async function (dispatch) {
        dispatch(toggleEditingGrantRequesting());

        const url = `${apiUrl}/api/shipments/toggle-edit`;
        const result = await post(url, payload, spinnerElementId);

        if (result.isSuccess) {
            dispatch(toggleEditingGrantSuccess(result.response.edit));
        } else {
            dispatch(toggleEditingGrantFailure(result.error));
        }
    }
};

export function toggleEditingGrantRequesting() {
    return {
        type: shipmentDetailsTypes.TOGGLE_EDITING_GRANT_REQUESTING
    }
}

export function toggleEditingGrantSuccess(payload) {
    return {
        type: shipmentDetailsTypes.TOGGLE_EDITING_GRANT_SUCCESS,
        payload
    }
}

export function toggleEditingGrantFailure(payload) {
    return {
        type: shipmentDetailsTypes.TOGGLE_EDITING_GRANT_FAILURE,
        payload
    }
}
/** end TOGGLE EDITING GRANT */

export function changeFieldValue(payload) {
    return {
        type: shipmentDetailsTypes.CHANGE_FIELD_VALUE,
        payload
    };
}

export function changeExpectedVolumeFieldValue(payload) {
    return {
        type: shipmentDetailsTypes.CHANGE_EXPECTED_VOLUME_FIELD_VALUE,
        payload
    };
}

export function changeExpectedItemQuantityFieldValue(payload) {
    return {
        type: shipmentDetailsTypes.CHANGE_EXPECTED_ITEM_QUANTITY_FIELD_VALUE,
        payload
    };
}

export function changeExpectedGrossWeightFieldValue(payload) {
    return {
        type: shipmentDetailsTypes.CHANGE_EXPECTED_GROSS_WEIGHT_FIELD_VALUE,
        payload
    };
}


/** UPDATE SHIPMENT */
export function updateShipment(shipment, spinnerElementId = null) {
    return async dispatch => {
        dispatch(updateShipmentRequesting());

        const url = `${apiUrl}/api/shipments/update`;
        const response = await post(url, shipment, spinnerElementId);

        if (response.isSuccess) {

            const result = mapObject(response.response.shipment || {}, (key, value) => (value === null || value === undefined) ? { [key]: "" } : { [key]: value });

            dispatch(updateShipmentSuccess(result));

            dispatch(toggleEditingGrant({
                id: shipment.id,
                edit: false
            }));
        } else {
            dispatch(updateShipmentFailure(response.errors));
        }
    }
}


export function deleteShipment(shipment, spinnerElementId = null) {
    return async dispatch => {
        dispatch(deleteShipmentRequesting());

        const url = `${apiUrl}/api/shipments/delete`;
        const body = { Id: shipment.id };
        const response = await post(url, body, spinnerElementId);

        if (response.isSuccess) {

            const result = mapObject(response.response.shipment || {}, (key, value) => (value === null || value === undefined) ? { [key]: "" } : { [key]: value });

            dispatch(deleteShipmentSuccess(result));

            dispatch(toggleEditingGrant({
                id: shipment.id,
                edit: false
            }));
        } else {
            dispatch(deleteShipmentFailure(response.errors));
        }
    }
}

export function updateShipmentRequesting() {
    return {
        type: shipmentDetailsTypes.UPDATE_SHIPMENT_REQUESTING,
    };
}


export function updateShipmentSuccess(payload) {
    return {
        type: shipmentDetailsTypes.UPDATE_SHIPMENT_SUCCESS,
        payload
    };
}

export function updateShipmentFailure(payload) {
    return {
        type: shipmentDetailsTypes.UPDATE_SHIPMENT_FAILURE,
        payload
    };
}
/** end UPDATE SHIPMENT */


export function deleteShipmentRequesting() {
    return {
        type: shipmentDetailsTypes.DELETE_SHIPMENT_REQUESTING,
    };
}
export function deleteShipmentSuccess(payload) {
    return {
        type: shipmentDetailsTypes.DELETE_SHIPMENT_SUCCESS,
        payload
    };
}

export function deleteShipmentFailure(payload) {
    return {
        type: shipmentDetailsTypes.DELETE_SHIPMENT_FAILURE,
        payload
    };
}
/***/
export function deleteTHU(payload) {
    return {
        type: shipmentDetailsTypes.DELETE_THU,
        payload
    }
}
/***/

/** OPERATIONS **/
export const getOperations = (shipmentIdList) => async dispatch => {
    const url = `${apiUrl}/api/operations?${addQueryString('shipmentIdList', shipmentIdList)}`;
    const response = await get(url, null, true);

    if (response.isSuccess) {
        dispatch({
            type: shipmentDetailsTypes.OPERATION_RECEIVED,
            payload: response.response.result.operations
        })
    }
}

export const removeShipmentFromOperation = (shipmentId, operationId, goBack, loaderId) => async dispatch => {

    var request = {
        shipmentId,
        operationId
    }

    const url = `${apiUrl}/api/deliveries/remove-shipment`;
    const result = await post(url, request, loaderId);

    if (result.isSuccess) {
        dispatch({
            type: shipmentDetailsTypes.OPERATION_REMOVED,
            payload: operationId
        })

        if (goBack) {
            toast.info('Shipment removed from operation and you redirected to previous page')
            history.go(-1);
        }
    }
}
/** end OPERATIONS **/
