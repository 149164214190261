import React from 'react';
import { Select } from '@dfds-ui/react-components';

const GroupFilter = ({ list, selectedGroup, show, onChange }) => {
    if (!show) {
        return null;
    }

    return (
        <div className="form-group">
            <label className="col-form-label">Transport</label>
            <Select
                value={selectedGroup}
                onChange={onChange}
                name="select"
                size="small"
            >
                <option value="">Select</option>
                {
                    list &&
                    list.map((item, i) => <option key={i} value={item.id}>{item.code}</option>)
                }
            </Select>
        </div>
    )
}

export default GroupFilter;