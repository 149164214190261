export default Object.freeze({
    READ_SHIPMENT_SETTINGS_REQUESTING: 'astrid/settings/READ_SHIPMENT_SETTINGS_REQUESTING',
    READ_SHIPMENT_SETTINGS_SUCCESS: 'astrid/settings/READ_SHIPMENT_SETTINGS_SUCCESS',
    READ_SHIPMENT_SETTINGS_FAILURE: 'astrid/settings/READ_SHIPMENT_SETTINGS_FAILURE',

    READ_ARTICLE_SETTINGS_REQUESTING: 'astrid/settings/READ_ARTICLE_SETTINGS_REQUESTING',
    READ_ARTICLE_SETTINGS_SUCCESS: 'astrid/settings/READ_ARTICLE_SETTINGS_SUCCESS',
    READ_ARTICLE_SETTINGS_FAILURE: 'astrid/settings/READ_ARTICLE_SETTINGS_FAILURE',

    READ_CONTAINER_BOOKING_SETTINGS_REQUESTING: 'astrid/settings/READ_CONTAINER_BOOKING_SETTINGS_REQUESTING',
    READ_CONTAINER_BOOKING_SETTINGS_SUCCESS: 'astrid/settings/READ_CONTAINER_BOOKING_SETTINGS_SUCCESS',
    READ_CONTAINER_BOOKING_SETTINGS_FAILURE: 'astrid/settings/READ_CONTAINER_BOOKING_SETTINGS_FAILURE',

    READ_SETTINGS_REQUESTING: 'astrid/settings/READ_SETTINGS_REQUESTING',
    READ_SETTINGS_SUCCESS: 'astrid/settings/READ_SETTINGS_SUCCESS',
    READ_SETTINGS_FAILURE: 'astrid/settings/READ_SETTINGS_FAILURE',
});