import actionTypes from "./actionTypes";
import { getValue } from "../../../core/utility";

// If any filter has Value property empty string, "", that means it is not defined, that is better than passing base values null or undefined when using Controlled React Components. We filter wrt those in related action: readArticlesOverview(filters)
const initialFilters = [
    { "Name": "externalId", "Value": "", "Operator": "=" },
    { "Name": "arrivalShipment.shipmentNo", "Value": "", "Operator": "=" },
    { "Name": "status", "Value": "", "Operator": "=" },
    { "Name": "type", "Value": "", "Operator": "=" },
    { "Name": "destinationId", "Value": "", "Operator": "=" },
    { "Name": "deliveryNote", "Value": "", "Operator": "=" },
    { "Name": "externalCode", "Value": "", "Operator": "=" },
    { "Name": "supplierId", "Value": "", "Operator": "=" },
    { "Name": "invoiceNumber", "Value": "", "Operator": "=" },
    { "Name": "invoiceDateStart", "Value": "", "Operator": ">=" },
    { "Name": "invoiceDateEnd", "Value": "", "Operator": "<=" },
    { "Name": "pONumber", "Value": "", "Operator": "=" },
    { "Name": "batchNumber", "Value": "", "Operator": "=" },
    { "Name": "isRush", "Value": "", "Operator": "=" },
    { "Name": "isHeld", "Value": "", "Operator": "=" },
    { "Name": "arrivalShipment.unit.unitCode", "Value": "", "Operator": "=" },
    { "Name": "deliveryShipment.unit.unitCode", "Value": "", "Operator": "=" },
    { "Name": "createdDate", "Value": "", "Operator": ">=" },
    { "Name": "createdDate", "Value": "", "Operator": "<=" },
    { "Name": "actualMeasurement.volume.value", "Value": "", "Operator": ">=" },
    { "Name": "actualMeasurement.volume.value", "Value": "", "Operator": "<=" },
    { "Name": "actualMeasurement.weight.value", "Value": "", "Operator": ">=" },
    { "Name": "actualMeasurement.weight.value", "Value": "", "Operator": "<=" },
    { "Name": "actualMeasurement.grossWeight.value", "Value": "", "Operator": ">=" },
    { "Name": "actualMeasurement.grossWeight.value", "Value": "", "Operator": "<=" },
    { "Name": "inStockDate", "Value": "", "Operator": ">=" },
    { "Name": "inStockDate", "Value": "", "Operator": "<=" },
    { "Name": "waitingPickDate", "Value": "", "Operator": ">=" },
    { "Name": "waitingPickDate", "Value": "", "Operator": "<=" },
    { "Name": "pickedDate", "Value": "", "Operator": ">=" },
    { "Name": "pickedDate", "Value": "", "Operator": "<=" },
    { "Name": "shippedDate", "Value": "", "Operator": ">=" },
    { "Name": "shippedDate", "Value": "", "Operator": "<=" },
];

const initialState = {
    isRequesting: false,
    items: [],
    errors: [],
    filters: initialFilters,
    // saveLastUsedFilters: [], // might not need
    propertiesReflection: {},
};

/** ARTICLES */
function reduceArticles(state = initialState, action) {
    switch (action.type) {
        // READ
        case actionTypes.READ_ARTICLES_REQUESTING:
            return { ...state, isRequesting: true };
        case actionTypes.READ_ARTICLES_SUCCESS:
            return { ...state, isRequesting: false, items: action.payload, errors: [] };
        case actionTypes.READ_ARTICLES_FAILURE:
            return { ...state, isRequesting: false, errors: action.payload };
        // end READ
        case actionTypes.READ_ARTICLES_BY_IDS_REQUESTING:
            return { ...state, isRequesting: true };
        case actionTypes.READ_ARTICLES_BY_IDS_SUCCESS:
            return { ...state, isRequesting: false, items: action.payload, errors: [] };
        case actionTypes.READ_ARTICLES_BY_IDS_FAILURE:
            return { ...state, isRequesting: false, errors: action.payload };
        // READ BY IDs

        // end READ BY IDs

        // FILTER
        case actionTypes.SET_FILTER:
            const { Name, Value, Operator } = action.payload;
            return {
                ...state, filters: state.filters.map(filter => (filter.Name === Name && filter.Operator === Operator) === true
                    ? { ...filter, Value: Value }
                    : filter
                )
            };
        case actionTypes.SET_FILTERS:
            return {
                ...state, filters: action.payload
            };
        case actionTypes.RESET_ALL_FILTERS:
            return { ...state, filters: state.filters.map(filter => ({ ...filter, Value: "" })) };
        // end FILTER
        // SORT
        case actionTypes.SORT_ARTICLES:
            return {
                ...state,
                items: [...state.items].sort((a, b) => {
                    if (action.payload.dataType === 'string') {
                        const comparison = getValue(a, action.payload.sortBy) > getValue(b, action.payload.sortBy);
                        const flag = comparison ? 1 : -1;
                        return action.payload.sortDirection === 'ASC' ? (-flag) : flag;
                    } else if (action.payload.dataType === 'number') {
                        const difference = getValue(a, action.payload.sortBy) - getValue(b, action.payload.sortBy);
                        const flag = difference > 0 ? 1 : -1;
                        return action.payload.sortDirection === 'ASC' ? (-flag) : flag;
                    } else if (action.payload.dataType === 'boolean') {
                        const comparison = getValue(a, action.payload.sortBy) > getValue(b, action.payload.sortBy);
                        const flag = comparison ? 1 : -1;
                        return action.payload.sortDirection === 'ASC' ? (-flag) : flag;
                    }
                })
            };
        // end SORT
        default: return state;
    }
}
/** end ARTICLES */


const reducer = reduceArticles;

export default reducer;