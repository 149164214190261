/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getRule, getRules, saveRule, finalizeAddOrUpdate, getUnitTypes, getClients, getBusinessAreasByClient, getServicesByClientAndType, getBusinessAreaDetails } from '../store/action/reservedSlotActions';
import 'rc-time-picker/assets/index.css';
import './AddOrEditCalendarRule.css';
import history from '../core/history';
import moment from 'moment';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import { weekDaysWithEveryday } from '../core/utility';
import Page from '../component/Page/Page';
import 'react-datepicker/dist/react-datepicker.css';
import SchemaInterpreter from '../component/FormComponents/Schema/SchemaInterpreter';
import { CreateOrUpdateReservedSlotRuleSchema } from '../component/FormComponents/Schema/createOrUpdateReservedSlotRule';
import { utcToEpoch } from '../core/utcToEpochConverter';
import { toast } from 'react-toastify';

const AddOrEditReservedSlotRule = (props) => {
    //Redux actions
    const { getRule, getRules, saveRule, finalizeAddOrUpdate, getUnitTypes, getClients, getBusinessAreasByClient, getServicesByClientAndType, getBusinessAreaDetails } = props;

    //Redux store
    let { ruleItem, rules, businessAreas, unitTypes, isSaveRuleSuccess, clients, services, businessAreaDetails } = props;

    //Other props
    const { match } = props;

    const [rule, setRule] = useState({});

    const ruleId = match.params.ruleid;

    useEffect(() => {
        if (!clients) {
            getClients();
        }
    }, [clients, getClients]);

    useEffect(() => {
        if (rule && rule.clientId) {
            getBusinessAreasByClient(rule.clientId);
        }
    }, [rule.clientId, getBusinessAreasByClient]);

    useEffect(() => {
        if (!rules) {
            getRules();
        }
    }, [rules, getRules]);

    useEffect(() => {
        if (rules && ruleId) {
            getRule(rules, ruleId);
        }

        return () => {
            //Clear rule state
            getRule(null);
        }
    }, [rules, ruleId, getRule]);

    useEffect(() => {
        if (ruleItem) {
            setRule(ruleItem);
        }
    }, [ruleItem]);

    useEffect(() => {
        if (businessAreas && businessAreas.length === 1 && !rule.businessAreaId) {
            setRule(prevState => { return { ...prevState, businessAreaId: businessAreas[0].value } })
        }
    }, [businessAreas, rule, setRule]);

    useEffect(() => {
        if (rule && rule.clientId && rule.operationType && !services) {
            getServicesByClientAndType(rule.clientId, rule.operationType);
        }
    }, [rule, getServicesByClientAndType]);

    useEffect(() => {
        if (!unitTypes) {
            getUnitTypes();
        }

    }, [unitTypes, getUnitTypes]);

    useEffect(() => {
        if (isSaveRuleSuccess) {
            finalizeAddOrUpdate();
            history.push('/booking/reserved-slot-rule');
        }
    }, [isSaveRuleSuccess, finalizeAddOrUpdate]);

    useEffect(() => {
        if (rule && rule.businessAreaId) {
            getBusinessAreaDetails(rule.businessAreaId);
        }
    }, [rule.businessAreaId])

    useEffect(() => {
        if (ruleItem) {
            const newRule = {
                ...ruleItem,
                startDate: moment.unix(ruleItem.startDate).utc().format('DD/MM/YYYY'),
                endDate: moment.unix(ruleItem.endDate).utc().format('DD/MM/YYYY'),
                startTime: moment.utc().startOf('day').add({ minutes: ruleItem.startMinutesSinceMidnight }),
                endTime: moment.utc().startOf('day').add({ minutes: ruleItem.endMinutesSinceMidnight }),
            };
            setRule(newRule);
        }
    }, [ruleItem])

    const getOperationTypeList = () => {
        return [
            { value: "Loading", text: "Loading" },
            { value: "Unloading", text: "Unloading" }
        ];
    };

    const onFieldChangeHandler = (event, newValue, previousValue, fieldId) => {
        if (fieldId === 'clientId') {
            setRule(prevState => {
                return {
                    ...prevState,
                    clientId: newValue,
                    businessAreaId: null,
                    operationType: null,
                    serviceId: null
                }
            });
        }
        else if (fieldId === 'operationType') {
            setRule(prevState => {
                return {
                    ...prevState,
                    serviceId: null,
                    operationType: newValue,
                }
            });
        }
        else if (fieldId === 'businessAreaId') {
            setRule(prevState => {
                return {
                    ...prevState,
                    businessAreaId: newValue,
                    endTime: null,
                }
            });
        }
        else {
            setRule(prevState => {
                return {
                    ...prevState,
                    [fieldId]: newValue,
                }
            })
        }
    }

    const formattedDateOf = date => moment(date, "DD/MM/YYYY HH:mm")

    const fieldRules = {
        'endTime': {
            isDisabled: !businessAreaDetails || !businessAreaDetails.isDynamicTimeSlot ? true : false,
            isRequired: businessAreaDetails && businessAreaDetails.isDynamicTimeSlot ? false : false
        }
    }

    const submit = (ruleModel) => {
        const ruleRequest = {
            ...ruleModel,
            clientName: clients.find(x => x.value === ruleModel.clientId).text,
            serviceName: services.find(x => x.value === ruleModel.serviceId).text,
            businessAreaName: businessAreas.find(x => x.value === ruleModel.businessAreaId).text,
            startDate: utcToEpoch(formattedDateOf(ruleModel.startDate).toDate()),
            endDate: utcToEpoch(formattedDateOf(ruleModel.endDate).toDate()),
            startMinutesSinceMidnight: (ruleModel.startTime.hours() * 60) + ruleModel.startTime.minutes(),
            endMinutesSinceMidnight: ruleModel.endTime ? (ruleModel.endTime.hours() * 60) + ruleModel.endTime.minutes() : 0,
            company: ruleModel.carrier ? ruleModel.carrier : '',
        };

        if (businessAreaDetails && businessAreaDetails.isDynamicTimeSlot) {
            if (!ruleRequest.endMinutesSinceMidnight || ruleRequest.endMinutesSinceMidnight === 0) {
                toast.error('End time is required when the business area has dynamic slots.');
                return;
            }
            if (ruleRequest.startMinutesSinceMidnight >= ruleRequest.endMinutesSinceMidnight) {
                toast.error('Start time must be smaller than end time.');
                return;
            }
        }
        if (ruleRequest.startDate > ruleRequest.endDate) {
            toast.error('Start date must be smaller than end date.');
            return;
        }
        
        saveRule(ruleRequest);
    };


    return <Page backLink='/booking/reserved-slot-rule' backToWhere='Reserved Slot Rules' >
        {clients && <SchemaInterpreter
            title={ruleId && ruleId === 'new' ? 'Create Rule' : 'Edit Rule'}
            onFieldChange={onFieldChangeHandler}
            onSubmit={submit}
            formItem={rule}
            formSchema={CreateOrUpdateReservedSlotRuleSchema}
            operationTypes={getOperationTypeList()}
            clients={clients}
            services={services}
            businessAreas={businessAreas}
            daysOfWeek={weekDaysWithEveryday}
            unitTypes={unitTypes}
            fieldRules={fieldRules}
        />}
        <LoadingIndicator id='addOrEditReservedSlotRule' />
    </Page>
}

const mapStateToProps = state => {
    return {
        ruleItem: state.reservedSlot.rule,
        rules: state.reservedSlot.rules,
        isSaveRuleSuccess: state.reservedSlot.isSaveRuleSuccess,
        unitTypes: state.reservedSlot.unitTypes,
        businessAreas: state.reservedSlot.clientBusinessAreas,
        clients: state.reservedSlot.clients,
        services: state.reservedSlot.services,
        businessAreaDetails: state.reservedSlot.businessAreaDetails
    }
}

const mapDispatchToProps = {
    getRule,
    saveRule,
    getRules,
    finalizeAddOrUpdate,
    getUnitTypes,
    getClients,
    getBusinessAreasByClient,
    getServicesByClientAndType,
    getBusinessAreaDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOrEditReservedSlotRule);