import bookingsQueryStringTypes from '../type/bookingsQueryStringTypes';

export const businessAreasChanged = (businessAreas) => {
    return {
        type: bookingsQueryStringTypes.BUSINESSAREA_CHANGED,
        payload: businessAreas
    };
};

export const dateChanged = (date) => {
    return {
        type: bookingsQueryStringTypes.DATE_CHANGED,
        payload: date
    };
};

export const bookingIdChanged = (bookingCode) => {
    return {
        type: bookingsQueryStringTypes.BOOKINGCODE_CHANGED,
        payload: bookingCode
    };
};

export const textSearchChanged = (textSearch) => {
    return {
        type: bookingsQueryStringTypes.SEARCHTERM_CHANGED,
        payload: textSearch
    }
};

export const SetQueryString = (businesArea, date, textSearch, bookingId) => {
    const _payload =  {
        code: bookingId,
        businessAreas: businesArea,
        searchTerm: textSearch,
        date: date
    };
    return {
        type: bookingsQueryStringTypes.SETQUERYSTRING,
        payload: _payload
    };
}

export const ReadQueryString = () => {
    return {
        type: bookingsQueryStringTypes.READQUERYSTRING
    };
}

export const ResetFilters = () => {
    return {
        type: bookingsQueryStringTypes.RESET_ALL_QUERY
    }
};