import React from 'react';
import history from '../../../core/history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@dfds-ui/react-components';
import { STATUS, TYPES } from '../../../constants/operation/operation';
import PolicyGroupAccessHoc from "../../../RoutedComponents/Routes/PolicyGroupAccessHoc";

const PickShipmentsToOperation = (operation) => {

    if (!operation || operation.type === TYPES.UNLOADING) {
        return;
    }

    return (
        <PolicyGroupAccessHoc componentName="PickShipmentsToOperationHOC">
            <Button className="dolly-flatbed-button" disabled={operation.status === STATUS.COMPLATED || operation.status === STATUS.CANCELLED} title="Waiting Pick / Picked Shipments" size="small" variation="primary" onClick={() => pickShipmentsClickedEvent(operation.id)}>
                <FontAwesomeIcon icon={['fas', 'dolly-flatbed']} className="mr-2" /> Pick THUs
        </Button>
        </PolicyGroupAccessHoc>
    );
}

const pickShipmentsClickedEvent = (id) => {
    var path = `/shipmentoutboundoperation/2/${id}`;
    history.push(path);
}

export default PickShipmentsToOperation;