import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonStack } from '@dfds-ui/react-components';
import {
    createBooking,
    clearBookingState,
    updateBooking,
    getBusinessAreas,
    getUnitTypesForBooking,
    clearTimeSlots
} from '../store/action/bookingActions';
import history from '../core/history';
import { convertEpochToUtcTime } from '../core/convertEpochToUtcTime';
import './BookingCommon.css';
import moment from 'moment';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import { isOnlyExternalUser } from "../core/authentication";
import SchemaInterpreter from "../component/FormComponents/Schema/SchemaInterpreter";
import { ConfirmBookingSchema } from "../component/FormComponents/Schema/confirmBooking";
import Page from '../component/Page/Page';
import { useIfNotNavigatedTo } from '../core/hooks';
import { ResetFilters } from '../store/action/bookingsQueryStringActions';

const ConfirmBooking = (props) => {

    // Redux actions
    const { createBooking, updateBooking, clearBookingState, clearTimeSlots } = props;

    // Redux store
    let { formData, bookingResult, selectedDate, selectedTime, unitTypesForBooking, services, clients, clientBusinessAreas, timeSlots, ResetFilters } = props;

    // Others
    const { match } = props

    useIfNotNavigatedTo(["/booking/list", "/booking/carrier-booking-list"], ResetFilters);

    const isInternalUser = () => {
        return !isOnlyExternalUser();
    }

    const getPageHeader = () => {
        if (!formData || !clientBusinessAreas || !clients || !services) {
            return 'Retrieving data...';
        }
        else if (bookingResult) {
            return 'Confirmed';
        }
        else {
            return 'Confirm your booking';
        }
    }

    const ref = useRef(null);
    const bookingId = match.params.bookingid;


    useEffect(() => {
        if (bookingResult) {
            ref.current.scrollIntoView();
        }
    }, [bookingResult]);

    useEffect(() => {
        return () => {
            clearBookingState();
        }
    }, [clearBookingState]);

    // useEffect(() => {
    //     if (formData && clientBusinessAreas && clients && services && !timeSlots) {

    //         history.push('/booking/new');
    //     }
    // }, [timeSlots]);

    const createBookingHandler = () => {
        createBooking(formData);
    }

    const updateBookingHandler = () => {
        updateBooking(bookingId, formData, isInternalUser());
    }

    const returnClickedHandler = () => {
        clearBookingState();
        if (!isOnlyExternalUser()) {
            history.push('/booking/list');
        } else {
            history.push('/booking/carrier-booking-list');
        }
    }

    const createNewBookingClickedHandler = () => {
        clearBookingState();
        history.push('/booking/new');
    }

    const getSubmitHandler = () => {
        if (bookingResult)
            return returnClickedHandler;
        if (bookingId === 'new')
            return createBookingHandler;
        return updateBookingHandler;
    }

    const renderReservationDateTime = () => {
        return <h6>Reservation Date Time: <strong>{moment.unix(formData.dateTime).format('L') + ' ' + convertEpochToUtcTime(formData.dateTime)} </strong> </h6>
    }

    const renderBookingResult = () => {
        return bookingResult ?
            <div ref={ref}>
                <div className="booking-result clearfix alert">
                    <h6><strong>Booking has been confirmed. Please note your booking no</strong></h6>
                    <h6>Booking No: <strong>{bookingResult} </strong> </h6>
                    <h6>Reservation Date Time: <strong>{moment.unix(formData.dateTime).format('L') + ' ' + convertEpochToUtcTime(formData.dateTime)} </strong> </h6>
                </div>
                <div>
                    <ButtonStack align='right'>
                        <Button type="button" variant="primary" onClick={returnClickedHandler} className="btn-lg float-right">Return to main page</Button>
                        <Button type="button" variant="primary" onClick={createNewBookingClickedHandler} className="btn-lg float-right">Create Another Booking</Button>
                    </ButtonStack>
                </div>
            </div>
            : null
    }

    const getOperationTypeList = () => {
        return [
            { value: "Loading", text: "Loading" },
            { value: "Unloading", text: "Unloading" }
        ];
    };

    const renderForm = () => {
        return <>{formData &&
            renderReservationDateTime()}
            < SchemaInterpreter
                onSubmit={getSubmitHandler()}
                formItem={formData}
                formSchema={ConfirmBookingSchema}
                operationTypes={getOperationTypeList()}
                businessAreas={clientBusinessAreas}
                clients={clients}
                services={services}
                unitTypes={unitTypesForBooking}

            />

        </>
    }

    const backClickedHandler = async () => {
        clearBookingState();
        history.push('/booking/new');
    }

    return <Page onBackClick={backClickedHandler} backToWhere='Create Booking'>
        <h4>{getPageHeader()}</h4><hr className='mt-0' />
        {renderForm()}
        {renderBookingResult && renderBookingResult()}
        <LoadingIndicator id="createOrUpdateBooking" />
    </Page>
}

const mapStateToProps = (state) => {
    return {
        clients: state.booking.clients,
        services: state.booking.services,
        formData: state.booking.formData,
        selectedDate: state.booking.selectedDate,
        selectedTime: state.booking.selectedTime,
        bookingResult: state.booking.bookingResult,
        timeSlots: state.booking.timeSlots,
        clientBusinessAreas: state.booking.clientBusinessAreas,
        unitTypesForBooking: state.booking.unitTypesForBooking,
        authenticatedUser: state.auth.authenticatedUser,

    }
}

const mapDispatchToProps = {
    createBooking,
    updateBooking,
    getBusinessAreas,
    getUnitTypesForBooking,
    clearBookingState,
    clearTimeSlots,
    ResetFilters
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmBooking);
