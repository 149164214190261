import React, { useState, useEffect } from 'react';


export function InputDatalist({
  value,
  onChange,
  data = [],
  fetchDataFunction,
  minCharsToFetchData = 2,
  labelClassName,
  labelText,
  datalistId,
  inputId,
  placeholder = `Min ${minCharsToFetchData} characters`,
  name,
  className,
  renderDatalistOptions,
  readOnly = false,
  autoComplete = "off",
  constantFetchParam, //for example: connectionPoint for suffix list
  queryParameterName = "query",
  timeoutMs = 400
}) {
  /** FETCH DATA */
  const [query, setQuery] = useState('');
  const [extraParam, setextraParam] = useState(constantFetchParam);

  // GET STATE FROM PROPS
  // if prop is ready at first render use useState(prop), if not, as here, useEffect(..., [prop]) as below
  useEffect(() => {
    if (value || value === "") {
      setQuery(value);
    }
  }, [value]);
  // end GET STATE FROM PROPS

  useEffect(() => {

    if (extraParam || extraParam === "") {
      setextraParam(constantFetchParam);
    }
  }, [constantFetchParam]);


  const [myTimeOut, setMyTimeOut] = useState();

  useEffect(() => {
    if (fetchDataFunction !== undefined && query.length >= minCharsToFetchData) {
      // TODO: need to deformat query here since the value prop sets it and value prop given here was formatted data.
      // write some deformat that returns same if it is not given formatted string

      if (myTimeOut) {
        clearTimeout(myTimeOut)
      };

      const newTimeOut = setTimeout(() => {
        fetchDataFunction({ [queryParameterName]: query, extraParam });
      }, timeoutMs);
      setMyTimeOut(newTimeOut);

    }
  }, [query, fetchDataFunction, minCharsToFetchData, extraParam]);
  /** end FETCH DATA */

  const [didSetInitialStateFromProps, setDidSetInitialStateFromProps] = useState(false);
  useEffect(() => {
    if (value && !didSetInitialStateFromProps) {
      setQuery(value);
      setDidSetInitialStateFromProps(true);
    }
  }, []);
  /** UNIQUE IDs: across html document for datalist, input and label, input matching */
  const [_datalistId] = useState(Math.random().toString());
  const [_inputId] = useState(Math.random().toString());
  /** end UNIQUE IDs */

  return (
    <>
      {labelText && <label htmlFor={inputId || _inputId} className={labelClassName}>{labelText}</label>}
      <input
        list={datalistId || _datalistId}
        id={inputId || _inputId}
        name={name}
        placeholder={!readOnly ? placeholder : ""}
        value={query} /** from state or external state */
        onChange={e => {
          /** from inside and/or from outside */
          setQuery(e.target.value); // to set value in state
          onChange(e); // to set value on an external state
        }
        }
        readOnly={readOnly}
        className={className}
        autoComplete={autoComplete}
      />
      <datalist id={datalistId || _datalistId}>
        {
          data.map(renderDatalistOptions)
          // data.map((item, i) => <option value={item[datalistTextContentProp]} key={i} />)
        }
      </datalist>
    </>
  );
}

// TODO: style with emotion pkg

// LATER:
// TODO: PARAMETRIZE: everything to give most ontrol to component user. some attributes can be passed like rest or and object paramete even with default values

// TODO: use displayProperty to enhab-nce for the case the data from fetch is an array of objects, you will have to access its props from option tags to show

// TODO: option may need to concat two strings in two props. So better we have a renderOptionFunction prop, or optionTextFunction, or better a mapDataToOptions function: (item, i, array) => <option value={item.someThing} key={i} />

// TODO: find a way to generate document wide unique ids for input (for label matching) and for datalist (for input matching)

// for input datalist cancel request when typed more. abort controller

// have a better way to pass custom fetch function with arbitrary no of parameters