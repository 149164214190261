import { spinnerService } from "@simply007org/react-spinners";
import httpClient from "../../core/httpclient";
import gateQuotaActions from '../type/gatequota';

const apiUrl = 'astrid-slotbooking-api/api/gatequota';

export const getAllConfigurations = () => async dispatch => {
    const endPoint = `${apiUrl}/getall`;
    spinnerService.show('getAllConfigs');
    httpClient.get(endPoint)
        .then(response => {
            dispatch({
                type: gateQuotaActions.GET_ALL_CONFIGURATIONS,
                payload: response.data.result
            })
        })
        .catch(error => {
            console.log('Error occured: ', error);
        })
        .finally(() => { spinnerService.hide('getAllConfigs'); });
}

export const createConfiguration = (configModel) => async dispatch => {
    const endPoint = `${apiUrl}/save`;
    spinnerService.show('createConfig');
    let post = httpClient.post(endPoint, configModel)
    post.then(response => {
        dispatch({
            type: gateQuotaActions.CREATE_CONFIGURATION,
            payload: { ...configModel, date: configModel.dateEpoch }
        })
    })
        .catch(error => {
            console.log('Error occured: ', error);
        }).finally(() => {
            spinnerService.hide('createConfig');
        });

    return post;
}

export const deleteConfiguration = (configModel) => async dispatch => {
    const endPoint = `${apiUrl}/deleteconfiguration`;
    const request = {
        ...configModel,
        dateEpoch: configModel.date
    }
    httpClient.delete(endPoint, { data: request })
        .then(response => {
            dispatch({
                type: gateQuotaActions.DELETE_CONFIGURATION,
                payload: configModel
            })
        })
        .catch(error => {
            console.log('Error occured: ', error);
        })
}