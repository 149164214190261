import React from 'react';
import { Button, ButtonStack } from '@dfds-ui/react-components';
import { connect } from 'react-redux';
import { setTaskStateTo, showUsersForAssignment, assign, reopenTask, completeTask } from '../../../store/action/taskActions';
import taskStates from '../../../store/type/task';
import history from '../../../core/history';
import PolicyGroupAccessHoc from '../../../RoutedComponents/Routes/PolicyGroupAccessHoc';
import { confirmationModal } from '../../../store/action/modalActions';

const TaskActions = (props) => {

    //Redux actions
    const { setTaskStateTo, showUsersForAssignment, completeTask, assign, reopenTask, confirmationModal } = props;

    //Redux store
    let { task, usersForAssignment, authenticatedUser } = props

    //Others
    let { customActions: CustomActions } = props

    const setTaskStateHandler = (e, state) => {
        e.stopPropagation();
        setTaskStateTo(state, task.id);
    }

    const assignClickedHandler = (e) => {
        e.stopPropagation();
        showUsersForAssignment(usersForAssignment, userSelectedForAssignmentHandler);
    }

    const completeClickedHandler = (e) => {
        e.stopPropagation();
        completeTask(task.id);
    }

    const reOpenClickedHandler = (e) => {
        e.stopPropagation();
        confirmationModal('Operation', 'Are you sure you want to reopen this task?', () => reopenTask(task.id, authenticatedUser.id, authenticatedUser.name));
    }

    const updateClickedHandler = (e) => {
        e.stopPropagation();
        history.push(`task/${task.id}`);
    }

    const userSelectedForAssignmentHandler = (userId, userNameSurname) => assign(task.id, userId, userNameSurname);

    const actions = [
        { id: 1, text: 'Complete', name: taskStates.TASK_COMPLETED, hidden: !task.canBeCompleted, onClickHander: completeClickedHandler },
        { id: 2, text: 'Claim', name: taskStates.TASK_CLAIMED, hidden: !task.canBeClaimable },
        { id: 3, text: 'Accept', name: taskStates.TASK_ACCEPTED, hidden: !task.canBeAcceptable, },
        { id: 4, text: 'Reject', name: taskStates.TASK_REJECTED, hidden: !task.canBeRejectable },
        { id: 5, text: 'Assign', name: taskStates.TASK_ASSIGNED, hidden: !task.canBeAssignable, onClickHander: assignClickedHandler },
        { id: 6, text: 'Edit', name: taskStates.TASK_UPDATED, hidden: !task.canBeUpdatable, onClickHander: updateClickedHandler, classes: task.canBeUpdatable ? 'd-none d-md-block' : '', authorizationWrapper: 'EditTask' },
        { id: 8, text: 'Re-Assign', name: taskStates.TASK_ASSIGNED, hidden: !task.canBeReAssignable, onClickHander: assignClickedHandler },
        { id: 9, text: 'Re-Open', name: taskStates.TASK_REOPENED, hidden: !task.canBeReOpen, onClickHander: reOpenClickedHandler },
    ];

    const possiblePrimaryActions = actions.filter(a => !a.hidden).sort((a, b) => a - b);

    const primaryAction = possiblePrimaryActions ? possiblePrimaryActions[0] : null;

    const renderAction = (action) => {
        const renderedButton = <Button
            key={action.id}
            className={action.classes}
            size='small'
            onClick={action.onClickHander ? action.onClickHander : (e) => setTaskStateHandler(e, action.name)}
            variation={primaryAction && primaryAction.id === action.id ? 'primary' : 'outlined'}>{action.text}</Button>

        if (action.authorizationWrapper) {
            return <PolicyGroupAccessHoc componentName={action.authorizationWrapper} key={action.id}>
                {renderedButton}
            </PolicyGroupAccessHoc>
        }
        return renderedButton;
    }
    return <ButtonStack align='center' orientation='vertical'>
        {actions.filter(a => !a.hidden).map(action => renderAction(action))
        }
        <CustomActions task={task} />
    </ButtonStack>
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.auth.authenticatedUser,
        usersForAssignment: state.task.usersForAssignment
    }
}

const mapDispatchToProps = {
    setTaskStateTo,
    showUsersForAssignment,
    assign,
    reopenTask,
    completeTask,
    confirmationModal
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskActions);