import shipmentDetailsTypes from '../type/shipmentDetailsTypes';
import { setValue } from '../../core/utility';

const initialState = {
    isRequesting: false,
    shipment: null,
    relatedShipmentList: null,
    operations: [],
    errors: [],
    hazardousItemList: [],
    docsMissingState: null,
    isEditingGranted: false,
    isRequestingEditingGrant: false,
    docsMissingHoldState: null
}

export default (state = initialState, action) => {
    let hazardousItemList = [];
    switch (action.type) {
        case shipmentDetailsTypes.SHIPMENT_RECEIVED:
            return {
                ...state,
                isRequesting: false,
                shipment: action.payload,
                docsMissingState: action.payload.docsMissingByUser,
                docsMissingHoldState: action.payload.docsMissingForHoldArticle,
                hazardousItemList: action.payload.hazardousList ? action.payload.hazardousList : []
            }
        case shipmentDetailsTypes.FILTER_SHIPMENT_REQUESTING:
            return {
                ...state,
                isRequesting: true,
            };
        case shipmentDetailsTypes.FILTER_SHIPMENT_FAILURE:
            return {
                ...state,
                isRequesting: false,
                errors: action.payload
            };
        case shipmentDetailsTypes.RELATEDSHIPMENTS_RECEIVED:
            return {
                ...state,
                relatedShipmentList: action.payload
            }
        case shipmentDetailsTypes.DELETE_THU:
            return {
                ...state,
                shipment: {
                    ...state.shipment,
                    items: state.shipment.articleList.filter(item => item.id !== action.payload.id),
                    actualItemQuantity: state.shipment.articleList.length - 1
                }
            };
        case shipmentDetailsTypes.SET_SHIPMENT_DOCS_MISSING_STATE:
            return {
                ...state,
                docsMissingState: action.payload
            }
        case shipmentDetailsTypes.CLEAR_SHIPMENT_DETAIL_DATA:
            return {
                ...state,
                ...initialState
            }
        case shipmentDetailsTypes.HAZARDOUS_UPDATED:
            hazardousItemList = [...state.hazardousItemList];
            hazardousItemList = hazardousItemList.filter(s => s.id !== action.payload.id);
            hazardousItemList.push(action.payload)

            return {
                ...state,
                hazardousItemList: hazardousItemList
            }
        // RECOMMENDATION: react would not re-render with above mutation if hazardousItemList not copied during Array.filter in the second line and no need to copy at first line:
        // return {...state, hazardousItemList: state.hazardousItemList.map(item => item.id == action.payload.id ? action.payload : item)};
        case shipmentDetailsTypes.HAZARDOUS_DELETED:
            hazardousItemList = [...state.hazardousItemList];
            hazardousItemList = hazardousItemList.filter(s => s.id !== action.payload);
            return {
                ...state,
                hazardousItemList: hazardousItemList
            }
        // RECOMMENDATION: no need to copy at first line:
        // return { ...state, hazardousItemList: state.hazardousItemList.filter(item => item.id != action.payload.id) };
        case shipmentDetailsTypes.TOGGLE_EDITING_GRANTED:
            return {
                ...state,
                isEditingGranted: action.payload
            };
        case shipmentDetailsTypes.TOGGLE_EDITING_GRANT_REQUESTING:
            return {
                ...state,
                isRequestingEditingGrant: true
            };
        case shipmentDetailsTypes.TOGGLE_EDITING_GRANT_SUCCESS:
            return {
                ...state,
                isRequestingEditingGrant: false,
                isEditingGranted: action.payload
            }
        case shipmentDetailsTypes.TOGGLE_EDITING_GRANT_FAILURE:
            return {
                ...state,
                isRequestingEditingGrant: false,
                errors: action.payload
            };
        case shipmentDetailsTypes.CHANGE_FIELD_VALUE:
            var key = Object.keys(action.payload)[0];
            var value = Object.values(action.payload)[0];
            var x = { ...state.shipment };
            setValue(x, key, value);
            return {
                ...state,
                shipment: x
            };
        case shipmentDetailsTypes.CHANGE_EXPECTED_VOLUME_FIELD_VALUE:
            return {
                ...state, shipment: {
                    ...state.shipment,
                    expectedMeasurement: {
                        ...state.shipment.expectedMeasurement,
                        volume: {
                            ...state.shipment.expectedMeasurement.volume,
                            value: action.payload
                        }
                    }
                }
            };
        case shipmentDetailsTypes.CHANGE_EXPECTED_ITEM_QUANTITY_FIELD_VALUE:
            return {
                ...state, shipment: {
                    ...state.shipment,
                    expectedMeasurement: {
                        ...state.shipment.expectedMeasurement,
                        itemQuantity: {
                            ...state.shipment.expectedMeasurement.itemQuantity,
                            value: action.payload
                        }
                    }
                }
            };
        case shipmentDetailsTypes.CHANGE_EXPECTED_GROSS_WEIGHT_FIELD_VALUE:
            return {
                ...state, shipment: {
                    ...state.shipment,
                    expectedMeasurement: {
                        ...state.shipment.expectedMeasurement,
                        grossWeight: {
                            ...state.shipment.expectedMeasurement.grossWeight,
                            value: action.payload
                        }
                    }
                }
            };
        case shipmentDetailsTypes.UPDATE_SHIPMENT_REQUESTING,
            shipmentDetailsTypes.DELETE_SHIPMENT_REQUESTING:
            return {
                ...state,
                isRequesting: true,
            };
        case shipmentDetailsTypes.UPDATE_SHIPMENT_SUCCESS,
            shipmentDetailsTypes.DELETE_SHIPMENT_SUCCESS:
            return {
                ...state,
                isRequesting: false,
                shipment: { ...action.payload },
                errors: action.payload.errors,
                docsMissingState: action.payload.docsMissingByUser,
                hazardousItemList: action.payload.hazardousItemList ? action.payload.hazardousItemList : []
            };
        case shipmentDetailsTypes.UPDATE_SHIPMENT_FAILURE,
            shipmentDetailsTypes.DELETE_SHIPMENT_FAILURE:
            return {
                ...state,
                isRequesting: false,
                errors: action.payload,
            };
        case shipmentDetailsTypes.OPERATION_RECEIVED:
            return {
                ...state,
                operations: action.payload ? action.payload : []
            }
        case shipmentDetailsTypes.OPERATION_REMOVED:
            var operations = [...state.operations].filter(s => s.id !== action.payload);
            return {
                ...state,
                operations: operations
            }
        case shipmentDetailsTypes.SET_SHIPMENT_DOCS_MISSING_HOLD_STATE:
            return {
                ...state,
                docsMissingHoldState: action.payload
            }
        default:
            return state;
    }

}