import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, Table, Col, Row } from 'react-bootstrap';
import { ButtonStack } from '@dfds-ui/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@dfds-ui/react-components/button';
import {
    readContainerBookings,
    deleteContainerBookings,
    setFilters,
    setFiltersAux,
    resetAllFilters,
    sortContainerBookings,
} from '../store/ducks/containerBookings/actionCreators';
import { fetchTypes } from '../store/ducks/definitions/actionCreators';
import { readContainerBookingSettings } from '../store/ducks/settings/actionCreators';
import styles from './ContainerBookings.module.css';
import { replaceWithIndex, getValue, generateGUID, member } from '../core/utility';
import ModalContainerBookingsMoreFilters from '../component/Modal/ModalContainerBookingsMoreFilters';
import LoadingIndicator from "../component/LoadingIndicator/LoadingIndicator";
import { localDateTimeToEpochSecond, epochSecondToLocalDateTime } from '../core/utcToEpochConverter.js';
import DatePicker from "react-datepicker";
import { useIfNotNavigatedTo } from '../core/hooks';
import PolicyGroupAccessHoc from "../RoutedComponents/Routes/PolicyGroupAccessHoc";

function ContainerBookings({
    readContainerBookings,
    deleteContainerBookings,
    containerBookings,
    types,
    fetchTypes,
    setFilters,
    setFiltersAux,
    resetAllFilters,
    containerBookingSettings,
    readContainerBookingSettings,
    sortContainerBookings,
}) {
    /** HISTORY, LOCATION, SEARCH PARAMS, URL PARAMS */
    let history = useHistory();
    useIfNotNavigatedTo(["/container-bookings/"], resetAllFilters);
    /** end HISTORY, LOCATION, SEARCH PARAMS, URL PARAMS */

    /** CREATE */
    const onClickCreate = () => history.push(`/container-bookings/container-booking`);
    /** end CREATE */

    /** READ, read state after component renders */
    useEffect(() => {
        readContainerBookings(containerBookings.filters.filter(({ Value }) => Value !== ''), 'readContainerBookings');
        fetchTypes();
    }, [containerBookings.filters, readContainerBookings, fetchTypes]);
    /** end READ */

    /** UPDATE */
    const onClickEdit = () => history.push(`/container-bookings/container-booking?ids=${checkedItemIds}`);
    /** end UPDATE */

    /** DELETE */
    const onClickDelete = async () => {
        if (window.confirm(`Are you sure you want to delete ${checkedItemIds.length > 1 ? `these ${checkedItemIds.length} items` : 'this item'}?`)) {
            await deleteContainerBookings({ bookingIds: checkedItemIds }, "readContainerBookings");
            setCheckedItemIds([]);// uncheck all.
        }
    }
    /** end DELETE */

    /** FUNCTION CONTROLS SECTION */
    const [visibleFunctionControls, setVisibleFunctionControls] = useState('filters');

    const onClickFunctionButton = (e) => {
        if (visibleFunctionControls === e.currentTarget.id) {
            setVisibleFunctionControls(undefined);
        } else {
            setVisibleFunctionControls(e.currentTarget.id);
        }
    }
    /** end FUNCTION CONTROLS SECTION */

    /* FILTER */
    const [queryLocal, setQueryLocal] = useState([]);
    const [queryLocalAux, setQueryLocalAux] = useState([]);

    // SET FILTERS STATE AFTER MOUNT, from store
    useEffect(() => {
        setQueryLocal(containerBookings.filters); // array of objects
        setQueryLocalAux(containerBookings.filtersAux); // object of objects
    }, [containerBookings.filters]);
    // end SET FILTERS STATE AFTER MOUNT, from store

    // VALUE SELECTORS
    const valueSelectorForFilters = (name, operator = '=', arrayOfValues = queryLocal) => {
        const valueFound = arrayOfValues.find(({ Name, Operator }) => Name === name && Operator === operator);
        return (valueFound && valueFound.Value) ? valueFound.Value : ''; // TODO: include 0, '' too
    }

    const valueSelectorForFiltersAux = (name, objectOfValues = queryLocalAux) => objectOfValues[name] || ''; // returns object or empty string
    // end VALUE SELECTORS

    // ON CHANGES
    const onChangeQuery = e => {
        const { id, value, valueAsNumber, checked, type } = e.target;
        const { operator = '=' } = e.target.dataset; // retrieve data-* attribute values
        setQueryLocal(state => {
            return state.map(query => {
                return (query.Name === id && query.Operator === operator) ?
                    {
                        ...query,
                        Value: type && type === 'number' ? valueAsNumber
                            : type && type === 'checkbox' ? checked
                                : value
                    }
                    : query
            });
        }); // similar to the corresponding store reducer
    }

    const onChangeDateQuery = (key, operator, epochSeconds) => {
        setQueryLocal(state => {
            return state.map(query => (query.Name === key && query.Operator === operator) === true ?
                { ...query, Value: epochSeconds } :
                query
            )
        }); // similar to the corresponding store reducer
    }
    // end ON CHANGES

    // MORE FILTER
    const [isModalMoreFiltersModalVisible, setIsMoreFiltersModalVisible] = useState(false);

    const onClickMoreFilters = (e) => setIsMoreFiltersModalVisible(true);

    // Badge for Filters & More filters button
    const [numberOfFilters, setNumberOfFilters] = useState(0);
    const [numberOfMoreFilters, setNumberOfMoreFilters] = useState(0);
    const primaryFilters = ['carrierBookingReference', 'containerClosingDate', 'operationNumber', 'voyageExternalNumber'];
    const BASE_VALUES = ['', 0, false, NaN, undefined, null];

    useEffect(() => {
        if (containerBookings.filters) {
            const allFilters = containerBookings.filters
                .filter(item => BASE_VALUES.includes(item.Value) === false);
            const number = allFilters.length;
            setNumberOfFilters(number > 0 ? number : '');
            const moreFilters = containerBookings.filters
                .filter(item => !primaryFilters.includes(item.Name))
                .filter(item => BASE_VALUES.includes(item.Value) === false);
            const numberOfMoreFilters = moreFilters.length;
            setNumberOfMoreFilters(numberOfMoreFilters > 0 ? numberOfMoreFilters : '');
        }
    }, [containerBookings.filters]);
    // END Badge for Filters & More filters button
    // end MORE FILTER

    const onSubmitQuery = (e) => {
        e.preventDefault();
        // just commit filters to redux store, and pass it as dependency array in read state from props effect; it will get state itself
        setFilters(queryLocal);
        setFiltersAux(queryLocalAux);
    }

    const onResetQuery = (e) => {
        // resets the form fields by default. if you want to reset the local state directly use e.preventDefault(); and call setQueryLocal() and setQueryLocalAux(), not recommended since it will make the values you use in fields elements TypeError since they will be all undefined you will be trying to reach properties of undefined if you have not handled that in a value selector like function.
        resetAllFilters(); // also reset filters at store then it will reset the filters at local state in turn
    }
    /* end FILTER */

    /** SORT */
    // Client side sort
    const [sortDirection, setSortDirection] = useState('ASC');
    const [sortBy, setSortBy] = useState();

    const onClickSort = (e) => {
        const { sortBy, sortDirection, dataType } = e.target.dataset;
        sortContainerBookings({ sortBy, sortDirection, dataType });
        setSortDirection(state => state === 'ASC' ? 'DESC' : 'ASC');
        setSortBy(sortBy);
    }
    // END Client side sort

    // Server side sort
    // END Server side sort
    /** end SORT */


    /** VIEW SETTINGS */
    const revision = 7;
    const [defaultViewSettings, setDefaultViewSettings] = useState({ columnOptions: { items: [] }, fontSize: 16, wrap: 'nowrap', persistSettings: true, revision: revision });

    const [viewSettings, setViewSettings] = useState(defaultViewSettings);

    // Force Update View Settings: force update the localStorage by flushing it. Good for making sure your data in users' localStorage is in good shape, can crash app if you made changes to the data structure and your code operating on it. 
    useEffect(() => {
        if (defaultViewSettings.revision !== revision) {
            window.localStorage.removeItem('containerBookingViewSettings');
        }
    }, [defaultViewSettings]);

    // Set view settings state column options from persistence or props
    useEffect(() => {
        // if persistSettings true start with the persisted state, else start with default state, the initial state to useState()
        const viewSettingsFromLocalStorage = JSON.parse(window.localStorage.getItem('containerBookingViewSettings'));

        if (viewSettingsFromLocalStorage && viewSettingsFromLocalStorage.columnOptions.items.length) {
            // if persisted and persistSettings is true read settings from persistence
            setViewSettings(state => ({ ...state, ...viewSettingsFromLocalStorage, persistSettings: viewSettingsFromLocalStorage.persistSettings }));

        } else if (containerBookingSettings.columns) {
            const columnOptionsItems = containerBookingSettings.columns.All
                .map(columnOption => ({
                    ...columnOption,
                    isVisible: containerBookingSettings.columns.Default.find(defaultColumnOption => defaultColumnOption.Key === columnOption.Key) ? true : false
                }));

            setViewSettings(state => ({ ...state, columnOptions: { ...state.columnOptions, items: columnOptionsItems } }));
        }
    }, [containerBookingSettings.columns]);

    // Set default view settings state column options from props
    useEffect(() => {
        if (containerBookingSettings.columns) {
            const columnOptionsItems = containerBookingSettings.columns.All
                .map(columnOption => ({
                    ...columnOption,
                    isVisible: containerBookingSettings.columns.Default.find(defaultColumnOption => defaultColumnOption.Key === columnOption.Key) ? true : false
                }));

            setDefaultViewSettings(state => ({ ...state, columnOptions: { ...state.columnOptions, items: columnOptionsItems } }));
        }
    }, [containerBookingSettings.columns]);

    const onChangeViewSetting = (e) => {
        const { id, value, checked } = e.target;
        const { operator, index, increment } = e.target.dataset; // retrieve data-* attribute values

        switch (id) {
            case 'columnVisibility':
                setViewSettings({
                    ...viewSettings,
                    columnOptions: {
                        items: viewSettings.columnOptions.items.map((item, i) => {
                            return i === parseInt(index) ? ({ ...item, isVisible: checked }) : item;
                        })
                    },
                });
                break;
            case 'fontSize':
                setViewSettings({ ...viewSettings, fontSize: viewSettings.fontSize + parseInt(operator) });
                break;
            case 'wrap':
                setViewSettings({ ...viewSettings, [id]: value });
                break;
            case 'resize':
                // setViewSettings({ ...viewSettings, [id]: viewSettings.resize + (parseInt(increment) % 100) });
                break;
            case 'persistSettings':
                setViewSettings({ ...viewSettings, [id]: checked });
                break;
            default: return viewSettings;
        }
    }

    // Persist View Settings onchange
    useEffect(() => {
        if (viewSettings.persistSettings === true) {
            window.localStorage.setItem('containerBookingViewSettings', JSON.stringify(viewSettings));
        }
    }, [viewSettings]);

    // always persist the persistSettings setting to persistence
    useEffect(() => {
        window.localStorage.setItem('containerBookingViewSettings', JSON.stringify(viewSettings));
    }, [viewSettings.persistSettings]);
    // END Persist View Settings on change

    // Column Options
    const [isColumnOptionsPopoverOpen, setIsColumnOptionsPopoverOpen] = useState(false);

    const toggleColumnOptionsPopover = () => {
        setIsColumnOptionsPopoverOpen(!isColumnOptionsPopoverOpen);
    }

    // Read default shipment column settings from server
    useEffect(() => {
        readContainerBookingSettings();
    }, [readContainerBookingSettings]);
    // END Column Options

    const onClickViewSettingsResetToDefaults = (e) => {
        setViewSettings(defaultViewSettings);
    }
    /** end VIEW SETTINGS */


    /* ON CLICK LIST ITEM, navigate to item view */
    const onClickTableBody = (e) => {
        e.persist();
        const { id } = e.target.dataset;
        if (e.target.type === 'checkbox') {
            onChangeCheckbox(e);
        } else if (id) {
            history.push(`/container-bookings/container-booking?ids=${id}`);
        }
    }
    /* end ON CLICK LIST ITEM */

    /* CHECK LIST ITEM/S, with checkboxes */
    const [checkedItemIds, setCheckedItemIds] = useState([]);

    const onChangeCheckbox = (e) => {
        e.persist();
        const { id } = e.target.dataset;
        if (e.target.checked) {
            setCheckedItemIds(state => state.includes(id) ? state : state.concat(id)); // check one
        } else {
            setCheckedItemIds(state => state.filter(item => item !== id)); // uncheck one
        }
    }

    const onChangeMasterCheckbox = (e) => {
        e.persist();
        if (e.target.checked) {
            setCheckedItemIds(containerBookings.items.map(item => item.id)); // check all
        } else {
            setCheckedItemIds([]); // uncheck all
        }
    }
    /* end CHECK LIST ITEM/S */

    /* VALIDATION */
    /* end VALIDATION */

    /* PAGINATION */
    /* end PAGINATION */

    // TODO: consider showing the selected N items note and its reset selection cross icon in the info section where spinner or showing n items stays
    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                <span style={{ fontSize: '1.75rem', paddingLeft: '10px' }}>Container Bookings</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                <ButtonStack align='right'>
                                    <PolicyGroupAccessHoc componentName="AddNewContainerBookingButtonHOC">
                                        <Button
                                            variation='primary'
                                            key={generateGUID()}
                                            onClick={onClickCreate}>
                                            Create <FontAwesomeIcon icon="plus" />
                                        </Button>
                                    </PolicyGroupAccessHoc>
                                    <Button
                                        variation='secondary'
                                        key='view-settings'
                                        id='view-settings'
                                        onClick={onClickFunctionButton}>
                                        Settings <FontAwesomeIcon icon="cog" />
                                    </Button>
                                    {
                                        checkedItemIds.length === 1 &&
                                        <Button
                                            variation='secondary'
                                            key={generateGUID()}
                                            onClick={onClickEdit}>
                                            Edit <FontAwesomeIcon icon="edit" />
                                        </Button>
                                    }
                                    {
                                        checkedItemIds.length > 0 &&
                                        <Button
                                            variation='secondary'
                                            key={generateGUID()}
                                            onClick={onClickDelete}>
                                            Delete <FontAwesomeIcon icon="trash" />
                                        </Button>
                                    }
                                    {
                                        checkedItemIds.length === 0 &&
                                        <Button
                                            variation='secondary'
                                            key='filters'
                                            id='filters'
                                            style={{ marginTop: '5px', marginLeft: '10px' }}
                                            onClick={onClickFunctionButton}>
                                            {numberOfFilters} Filters <FontAwesomeIcon icon="filter" />
                                        </Button>
                                    }
                                </ButtonStack>
                            </div>
                        </div>
                        <hr />
                        {
                            visibleFunctionControls === 'filters' &&
                            <Card className={styles.functionControls}>
                                <Row className={styles.functionSection}>
                                    <Col style={{ width: '100%', padding: '0px' }}>
                                        <form onSubmit={onSubmitQuery} onReset={onResetQuery} style={{ margin: '0px' }}>
                                            <Row>
                                                <Col xs="12" sm="6" md="3" lg="2">
                                                    <label htmlFor="voyageExternalNumber" className={styles.label}>Voyage External Number</label><br></br>
                                                    <input id="voyageExternalNumber" data-operator="=" value={valueSelectorForFilters('voyageExternalNumber')} onChange={onChangeQuery} autoFocus />
                                                </Col>

                                                <Col xs="12" sm="6" md="3" lg="2">
                                                    <label htmlFor="carrierBookingReference" className={styles.label}>Carrier Booking Reference</label><br></br>
                                                    <input id="carrierBookingReference" data-operator="=" value={valueSelectorForFilters('carrierBookingReference')} onChange={onChangeQuery} />
                                                </Col>

                                                <Col xs="12" sm="6" md="3" lg="2">
                                                    <label htmlFor="operationInfos.id" className={styles.label}>Operation Number</label><br></br>
                                                    <input id="operationInfos.id" data-operator="=" value={valueSelectorForFilters('operationInfos.id')} onChange={onChangeQuery} />
                                                </Col>

                                                <Col xs="12" sm="6" md="3" lg="2">
                                                    <label htmlFor="operationInfos.unitCode" className={styles.label}>Unit Number</label><br></br>
                                                    <input id="operationInfos.unitCode" data-operator="=" value={valueSelectorForFilters('operationInfos.unitCode')} onChange={onChangeQuery} />
                                                </Col>

                                                <Col xs="12" sm="6" md="3" lg="2">
                                                    <label htmlFor="containerClosingDateStart" className={styles.label}>Container Closing Date Start</label><br></br>
                                                    <DatePicker
                                                        id="containerClosingDateStart"
                                                        selected={valueSelectorForFilters('containerClosingDate', '>=') ? epochSecondToLocalDateTime(valueSelectorForFilters('containerClosingDate', '>=')) : ''}
                                                        onChange={(dateTime) => onChangeDateQuery('containerClosingDate', ">=", dateTime ? localDateTimeToEpochSecond(dateTime) : '')}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        todayButton="Today"
                                                        type='text'
                                                        dropdownMode="select"
                                                    />
                                                </Col>

                                                <Col xs="12" sm="6" md="3" lg="2">
                                                    <label htmlFor="containerClosingDateEnd" className={styles.label}>Container Closing Date End</label><br></br>
                                                    <DatePicker
                                                        id="containerClosingDateEnd"
                                                        selected={valueSelectorForFilters('containerClosingDate', '<=') ? epochSecondToLocalDateTime(valueSelectorForFilters('containerClosingDate', '<=')) : ''}
                                                        onChange={(dateTime) => onChangeDateQuery('containerClosingDate', "<=", dateTime ? localDateTimeToEpochSecond(dateTime) : '')}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        todayButton="Today"
                                                        type='text'
                                                        dropdownMode="select"
                                                    />
                                                </Col>
                                            </Row>

                                            <Row style={{ justifyContent: 'flex-end' }}>
                                                <Col xs="12" sm="6" md="3" lg="2" style={{ display: 'flex', alignItems: 'flex-end', minWidth: '90px', paddingTop: '10px' }}>
                                                    <input type="button" className={styles.outlined} value={`${numberOfMoreFilters} More`} onClick={onClickMoreFilters} title="More filters" style={{ flexGrow: 1 }} />
                                                </Col>

                                                <Col xs="12" sm="6" md="3" lg="2" style={{ display: 'flex', alignItems: 'flex-end', minWidth: '90px', paddingTop: '10px' }}>
                                                    <input type="reset" className={styles.outlined} value='Reset All' title="Reset all filters" style={{ flexGrow: 1 }} />
                                                </Col>

                                                <Col xs="12" sm="6" md="3" lg="2" style={{ display: 'flex', alignItems: 'flex-end', minWidth: '90px', paddingTop: '10px' }}>
                                                    <input type="submit" className={styles.secondary} value='Search' title="Search" style={{ flexGrow: 1 }} />
                                                </Col>
                                            </Row>

                                        </form>
                                    </Col>
                                </Row>
                            </Card>
                        }
                        {
                            visibleFunctionControls === 'view-settings' &&
                            <Card className={styles.functionControls}>
                                <Row className={styles.functionSection}>
                                    <Col style={{ width: '100%', padding: '0px' }}>
                                        <form style={{ margin: '0px' }}>
                                            <Row>
                                                <Col xs="12" sm="6" md="3" lg="3">
                                                    <label htmlFor="columnOptions" className={styles.label}>Column Options</label><br></br>
                                                    <input id="columnOptions" type="button" className={styles.outlined} value={"Column Options"} onClick={toggleColumnOptionsPopover} />
                                                </Col>

                                                <Col xs="12" sm="6" md="3" lg="3">
                                                    <label className={styles.label}>Font Size</label><br></br>
                                                    <Col xs="3" sm="4" md="6" lg="6" style={{ display: 'inline-block', paddingLeft: '0px' }}>
                                                        <input type="button" id="fontSize" className={styles.textual} data-operator={-1} value={"A -"} onClick={onChangeViewSetting} title="Decrease font size" />
                                                    </Col>
                                                    <Col xs="3" sm="4" md="6" lg="6" style={{ display: 'inline-block', paddingLeft: '0px' }}>
                                                        <input type="button" id="fontSize" className={styles.textual} data-operator={+1} value={"A +"} onClick={onChangeViewSetting} title="Increase font size" />
                                                    </Col>
                                                </Col>

                                                <Col xs="12" sm="6" md="3" lg="3">
                                                    <label htmlFor="persistSettings" className={styles.label}>Autosave Settings</label><br></br>
                                                    <input id="persistSettings" type="checkbox" checked={viewSettings.persistSettings} onChange={onChangeViewSetting} />
                                                </Col>

                                                <Col xs="12" sm="6" md="3" lg="3" style={{ display: 'flex', alignItems: 'flex-end' }} >
                                                    <input id="resetToDefaults" type="button" className={styles.primary} value="Reset" onClick={onClickViewSettingsResetToDefaults} title="Reset to defaults" />
                                                </Col>
                                            </Row>
                                        </form>
                                    </Col>
                                </Row>
                            </Card>
                        }
                        {
                            isColumnOptionsPopoverOpen && visibleFunctionControls === 'view-settings' &&
                            <Card className={styles.popover}>
                                <div
                                    onDragOver={e => {
                                        e.preventDefault();
                                        const dt = e.dataTransfer;
                                        dt.dropEffect = "move";
                                        // TODO: add a feedback in place dragged over
                                    }}
                                    onDrop={(e) => {
                                        const dt = e.dataTransfer;
                                        const oldIndex = dt.getData('text/plain');
                                        const newIndex = e.target.dataset.index;
                                        // change the array that has the column names ordered, that will cause a re-render, you do not have to and better not use DOM methods to appendChild to target or remove from source element
                                        setViewSettings({ ...viewSettings, columnOptions: { items: replaceWithIndex(parseInt(oldIndex), parseInt(newIndex), viewSettings.columnOptions.items) } });
                                        e.preventDefault();
                                    }}
                                >
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <FontAwesomeIcon icon="times" onClick={toggleColumnOptionsPopover} style={{ cursor: 'pointer' }} />
                                    </div>
                                    {
                                        viewSettings.columnOptions.items.map((item, i) => (
                                            <div
                                                key={i}
                                                data-index={i}
                                                draggable={true}
                                                onDragStart={e => {
                                                    const dt = e.dataTransfer;
                                                    dt.setData('text/plain', i);
                                                    dt.dropEffect = "move";
                                                    // dt.effectAllowed = "copy";
                                                    // dt.setDragImage(image, xOffset, yOffset);
                                                }}
                                                onDragEnd={e => {
                                                }}
                                            >
                                                <input id="columnVisibility" data-index={i} type="checkbox" checked={item.isVisible} onChange={onChangeViewSetting} />
                                                <p>{item.Value}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </Card>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>

                        <main>

                            <Table className="xms-table" bordered responsive>
                                <thead>
                                    <tr>
                                        <th>
                                            <input
                                                type="checkbox"
                                                checked={containerBookings.items.length && checkedItemIds.length === containerBookings.items.length}
                                                onChange={onChangeMasterCheckbox}
                                            />
                                        </th>
                                        {
                                            viewSettings.columnOptions.items
                                                .filter(columnOption => columnOption.isVisible === true)
                                                .map((columnOption, i) => (
                                                    <th
                                                        key={i}
                                                        data-sort-by={columnOption.Key}
                                                        data-sort-direction={sortDirection}
                                                        data-data-type={containerBookings.items[0] && typeof getValue(containerBookings.items[0], columnOption.Key)}
                                                        onClick={onClickSort}
                                                    >
                                                        {columnOption.Value}
                                                        <span style={{ display: (columnOption.Key === sortBy && sortDirection) ? 'initial' : 'none' }}>
                                                            {sortDirection === 'ASC' ? " 🔼" : " 🔽"}
                                                        </span>
                                                    </th>
                                                ))
                                        }
                                    </tr>
                                </thead>
                                <tbody onClick={onClickTableBody}>
                                    {
                                        containerBookings.items.map((item) => {
                                            return (
                                                <tr
                                                    key={item.id}
                                                    data-id={item.id}
                                                >
                                                    <td
                                                        data-id={item.id}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            data-id={item.id}
                                                            checked={checkedItemIds.includes(item.id)}
                                                            onChange={onChangeCheckbox}
                                                        />
                                                    </td>
                                                    {
                                                        viewSettings.columnOptions.items
                                                            .filter(columnOption => columnOption.isVisible === true)
                                                            .map((columnOption, i) => (
                                                                <td
                                                                    key={i}
                                                                    data-id={item.id}
                                                                    data-shipment-no={item.shipmentNo}
                                                                    style={{ fontSize: viewSettings.fontSize, color: item.logicalDeleteKey ? "#f2dede" : null }}
                                                                >
                                                                    {
                                                                        columnOption.Key.search('Date') !== -1 ?
                                                                            (getValue(item, columnOption.Key) > 0 ? epochSecondToLocalDateTime(getValue(item, columnOption.Key)).toLocaleDateString() : '') :
                                                                            getValue(item, columnOption.Key).toString()
                                                                    }
                                                                </td>
                                                            ))
                                                    }
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={containerBookings.items.length && Object.keys(containerBookings.items[0]).length + 3}>{containerBookings.isRequesting ? 'Requesting' : `Showing ${containerBookings.items.length} items`}</td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </main>

                        <ModalContainerBookingsMoreFilters
                            showModal={isModalMoreFiltersModalVisible}
                            onHide={() => setIsMoreFiltersModalVisible(false)}
                            modalData={{ valueSelectorForFilters, valueSelectorForFiltersAux, onChangeQuery, onChangeDateQuery, types, onSubmitQuery }}
                        >
                        </ModalContainerBookingsMoreFilters>
                    </Col>
                </Row>
            </Card.Body>
            <LoadingIndicator id="readContainerBookings" show={containerBookings.isRequesting} />
        </Card >
    );
}

const mapStateToProps = ({ containerBookings, definitions, settings }) => {
    return {
        containerBookings,
        containerBookingSettings: settings.containerBookingSettings,
        types: definitions.types,
    };
};

const mapDispatchToProps = {
    readContainerBookings,
    deleteContainerBookings,
    fetchTypes,
    setFilters,
    setFiltersAux,
    resetAllFilters,
    readContainerBookingSettings,
    sortContainerBookings,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContainerBookings);
