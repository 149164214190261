import React from 'react';
import { Card } from 'react-bootstrap';
import { Button } from '@dfds-ui/react-components';
import history from '../../core/history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let ActiveContract = ({ activeContract, companyCode, hidden, company }) => {
    return (
        !hidden &&
            <Card style={{ display: 'block', marginTop: '50px' }} >
                <Card.Header>Active Contract</Card.Header>
                <Card.Body>
                    {activeContract == null ? <div><Card.Text>There is no active contract.</Card.Text><Button variation='primary'
                        title='Create Contract'
                        onClick={() => { history.push(`/companies/${companyCode}/contract/new`) }}
                        icon={<FontAwesomeIcon icon='plus' />}
                        className='d-none d-md-block'
                    >
                        Create Contract
                </Button></div>
                        : <div><Card.Text>
                            Contract Reference : {activeContract ? activeContract.contractReference : ''}
                        </Card.Text>
                            <Card.Text>
                                Contact Person : {activeContract ? activeContract.clientContactPerson : ''}
                            </Card.Text> <Button title='Contract Details' variant="primary" onClick={() => { history.push(`/companies/${companyCode}/contract/${activeContract.id}`) }}>Details</Button></div>}
                </Card.Body>
            </Card>
    );
};

export default ActiveContract;
