import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Button, Checkbox } from '@dfds-ui/react-components';
import ShipmentRow from './ShipmentRow';
import classes from './ShipmentInboundOperation.module.css';

const ShipmentList = ({ shipmentList, onCheckAll, isCheckAll, onCheckShipment, isCheckShipment, headerOptions }) => {

    const renderList = () => {
        if (shipmentList && shipmentList.length > 0) {
            return shipmentList.map((shipment, i) => {
                return (
                    <ShipmentRow key={i} shipment={shipment} onChange={onCheckShipment} isChecked={isCheckShipment} />
                )
            })
        }

        return (
            <Card>
                <Card.Body>
                    <p>No shipment found</p>
                </Card.Body>
            </Card>
        );
    }

    return (<>
        <Card>
            <Card.Header>
                <Row className="align-items-center">
                    <Col xs={2}>
                        <Checkbox className={`${classes["shipments-checkbox"]}`} onChange={e => onCheckAll(e.target.checked)} checked={isCheckAll} disabled={!shipmentList}></Checkbox>
                    </Col>
                    <Col xs={1}></Col>
                    <Col xs={6} className="text-center">
                        <h5 className="font-weight-bold">{headerOptions.text}</h5>
                    </Col>
                    <Col xs={3} className="text-right">
                        <Button size="small" variation="primary" onClick={headerOptions.onClick} disabled={!shipmentList}>
                            {headerOptions.buttonText}
                            <span className={`badge badge-light ${classes["shipments-count"]}`}>{headerOptions.count}</span>
                        </Button>
                    </Col>
                </Row>
            </Card.Header>
        </Card>
        {renderList()}
    </>)
}

export default ShipmentList;