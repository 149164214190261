/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import history from '../core/history';
import BookingsTable from '../component/BookingTable/BookingsTable';
import { Button } from '@dfds-ui/react-components';
import { showReport } from '../store/action/reportActions';
import Page from '../component/Page/Page';
import { TabCollection } from '../component/BaseComponents/TabCollection';
import { utcToEpoch } from '../core/utcToEpochConverter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import { confirmationModal } from '../store/action/modalActions';
import moment from 'moment';
import {
  getBookings,
  setBookings,
  markAsArrived,
  cancelBooking,
  markAsUnArrived,
  clearBookingFormData,
  setUpSignalRConnectionForBookingsList,
  showAdminNotesInModal,
  arrangeUrlForBookingList,
  getArrivalReceiptDetails
} from '../store/action/bookingActions';
import {
  HubConnectionState
} from '@microsoft/signalr';


import { useIfNotNavigatedTo } from '../core/hooks';
import { dateChanged, ResetFilters } from '../store/action/bookingsQueryStringActions';

const dateTabs = [
  utcToEpoch(moment().toDate()),
  utcToEpoch(moment().add(1, 'day').toDate()),
  utcToEpoch(moment().add(2, 'day').toDate()),
  utcToEpoch(moment().add(3, 'day').toDate()),
  utcToEpoch(moment().add(4, 'day').toDate()),
  utcToEpoch(moment().add(5, 'day').toDate())
];

const ListBookings = (props) => {
  //Redux actions
  const { getBookings, setBookings, markAsArrived, cancelBooking, markAsUnArrived, clearBookingFormData, setUpSignalRConnectionForBookingsList, confirmationModal, showAdminNotesInModal, dateChanged, getArrivalReceiptDetails, showReport, ResetFilters } = props;

  //Redux store
  let { bookings, isRefreshData, bookingsQuery, signalRConnection } = props;

  const [selectedDate, setSelectedDate] = useState();

  useIfNotNavigatedTo(["/booking/", "/booking/new", "/booking/confirm"], ResetFilters);

  useEffect(() => {
    if (!signalRConnection) {
      setUpSignalRConnectionForBookingsList().then(() =>
      {
        initializeData();
      });
    }else
    {
      initializeData();
    }
  }, [signalRConnection]);

  useEffect(() => {
    if (selectedDate) {
      getBookings(selectedDate).then(() => {
        if (signalRConnection.state === HubConnectionState.Connected) {
          signalRConnection.invoke('SubscribeToBookingList', selectedDate).catch((err) => {
            return console.error(err.toString());
          });
        }
      });
    }

  }, [selectedDate, getBookings]);

  useEffect(() => {
    if (isRefreshData) {
      getBookings(selectedDate);
    }
  }, [isRefreshData, getBookings]);

  //TODO: Bu işlemin bu componentde olmaması gerekiyor. BookingForm state'ini Create ve Update componentleri kullanıyor. O componentlere erişim Bookings ve CarrierBookings üzerinden
  // sağlandığı için burada form state'in clear edilmesi sistemin kararlı çalışmasını sağlıyor. Ancak Create ve Update component'ler başka bir yerden çağrılacak şekilde bir ister gelirse
  // buradaki dependent useEffect işlemi olmadığı için form'lar back işlemi sırasında dolu gelme sorunu ortaya çıkacaktır.
  // Buradaki useEffect işleminin doğru kullanımı Create ve Update component'lerinde component unmount hook'unda çağrılması olurdu. Ancak Create ve Update component'leri tasarlanırken
  // booking akışı route değişicek şekilde tasarlandığı için state'in componentler arası redux üzerinden taşınması gerekiyor. Haliyle create ve update component'leri state üzerindeki formData
  // yı diğer componentlerin kullanabilmesi için unmount anında clear etmemektedir.
  // O yüzden burada teknik borç oluşmuştur.

  const dateChangedHandler = newDate => {
    dateChanged(newDate);
    setSelectedDate(newDate);
  }

  const initializeData = () => {
    //if "date" exists in filters get it from filters otherwise initialize it from today value
    let date = bookingsQuery && bookingsQuery.booking && bookingsQuery.booking.date ? utcToEpoch(new Date(bookingsQuery.booking.date * 1000)) : utcToEpoch(moment().toDate());
    setSelectedDate(date);
    clearBookingFormData();
  }

  const markAsArrivedClickedHandler = (bookingId) => markAsArrived(bookingId);

  const markAsUnArrivedClickedHandler = (bookingId) =>
    confirmationModal('Booking', 'Are you sure you want to mark as unarrived this booking?', () =>
      markAsUnArrived(bookingId)
    );

  const cancelBookingClickedHandler = (bookingId) =>
    confirmationModal('Booking', `Are you sure you want to cancel ${bookingId}?`, () =>
      cancelBooking(bookingId)
    );

  const editBookingClickedHandler = (bookingId, selectedBusinessAreas, searchTerm) => {
    const querystring = arrangeUrlForBookingList(bookingId, selectedBusinessAreas, searchTerm, selectedDate);
    history.push(`/booking/${bookingId}?query=${querystring}`);
  };

  const updateAdminNotesClickHandler = (bookingId) => {
    showAdminNotesInModal(bookingId);
  };

  const arrivalReceiptClickedEvent = (bookingId) => {

    getArrivalReceiptDetails(bookingId).then((taskid) => {
      if (taskid) {
        const request = {
          type: "ArrivalReceipt",
          identifier: taskid
        };
        showReport(request, "Arrival Receipt", "bookingGetAll", true);
      }
    });

  };

  return (
    <Page>
      <Row>
        <Col>
          <div className="d-flex" style={{ alignItems: 'center' }}>
            <div style={{ flex: '1' }}>
              <h4 className="mb-0">Bookings</h4>
            </div>
            <div>
              <Button
                title="Create"
                icon={<FontAwesomeIcon icon="plus" />}
                onClick={() => {
                  history.push('/booking/new');
                }}
              >
                Create
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div style={{ marginBottom: '40px' }}> </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <TabCollection
            tabItemStyle={{ height: '2rem', fontSize: 'medium' }}
            selectedTab={selectedDate}
            tabs={dateTabs}
            customTabRenderer={(tab) =>
              moment
                .utc(tab * 1000)
                .calendar(null, {
                  lastDay: '[YESTERDAY] - MMM Do',
                  sameDay: '[TODAY] - MMM Do',
                  nextDay: '[TOMORROW] - MMM Do',
                  nextWeek: 'dddd - MMM Do',
                  sameElse: 'dddd - MMM Do'
                })
                .toLocaleUpperCase()
            }
            onTabClickedEvent={dateChangedHandler}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <BookingsTable
            data={bookings}
            canMarkAsArrived={true}
            canEditBooking={true}
            canSeeAdminNotes={true}
            markAsUnArrivedClickedEvent={markAsUnArrivedClickedHandler}
            markAsArrivedClickedEvent={markAsArrivedClickedHandler}
            editBookingClickedEvent={editBookingClickedHandler}
            cancelBookingClickedHandler={cancelBookingClickedHandler}
            updateAdminNotesClickHandler={updateAdminNotesClickHandler}
            arrivalReceiptClickedEvent={arrivalReceiptClickedEvent}
          />
        </Col>
      </Row>
      <LoadingIndicator id="bookingGetAll" show />
    </Page>
  );
};
const mapStateToProps = (state) => {
  return {
    bookings: state.booking.bookings,
    isRefreshData: state.booking.isRefreshData,
    bookingsQuery: state.bookingsQuery.query,
    signalRConnection: state.booking.signalRConnection
  };
};
const mapDispatchToProps = {
  getBookings,
  setBookings,
  markAsArrived,
  clearBookingFormData,
  setUpSignalRConnectionForBookingsList,
  cancelBooking,
  markAsUnArrived,
  confirmationModal,
  showAdminNotesInModal,
  dateChanged,
  getArrivalReceiptDetails,
  showReport,
  ResetFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(ListBookings);
