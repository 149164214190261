import reducer from './reducers';

import * as actionTypes from './actionTypes';
// import * as articleActions from './actions'; // I have favored exporting actions from inside  actions.js, can have operations.js and import actions there and export operations in here

export {
    actionTypes,
    // articleActions,
};

export default reducer;