import actionTypes from "./actionTypes";
import { post as postSync, deleteSync, get as getSync } from "../../../core/httpClientSync";

const definitionsURL = "xms-definition-api/api";
//const definitionsURL = 'http://localhost:5000/api';
//const companyUrl = 'http://localhost:5000/api';
const companyUrl = "xms-company-api/api";

/** SEARCH ASSOCIATES  */
function searchAssociates(payload, config = null) {
    return async function (dispatch) {
        dispatch(searchAssociatesRequesting());

        const result = await postSync(
            `${companyUrl}/company/search`, payload, null, null, config
        );
        if (result.isSuccess) {
            dispatch(searchAssociatesSuccess(result.response));
        } else {
            dispatch(searchAssociatesFailure(result.error));
        }
        return result;
    };
}

function searchAssociatesRequesting() {
    return {
        type: actionTypes.SEARCH_ASSOCIATES_REQUESTING,
    };
}

function searchAssociatesSuccess(payload) {
    return {
        type: actionTypes.SEARCH_ASSOCIATES_SUCCESS,
        payload,
    };
}

function searchAssociatesFailure(payload) {
    return {
        type: actionTypes.SEARCH_ASSOCIATES_FAILURE,
        payload,
    };
}
/** end SEARCH ASSOCIATES  */



/** CREATE CONNECTION POINT */
function createConnectionPoint(payload, processIndicatorId = null) {
    return async function (dispatch) {
        dispatch(createConnectionPointRequesting());

        const result = await postSync(`${companyUrl}/destinationgroup/create`, payload, processIndicatorId);
        if (result.isSuccess) {
            dispatch(createConnectionPointSuccess(result.response));
        } else {
            dispatch(createConnectionPointFailure(result.error));
        }
        return result;
    }
};

function createConnectionPointRequesting() {
    return {
        type: actionTypes.CREATE_CONNECTION_POINT_REQUESTING
    }
}

function createConnectionPointSuccess(payload) {
    return {
        type: actionTypes.CREATE_CONNECTION_POINT_SUCCESS,
        payload
    }
}

function createConnectionPointFailure(payload) {
    return {
        type: actionTypes.CREATE_CONNECTION_POINT_FAILURE,
        payload
    }
}
/** end CREATE CONNECTION POINT */


/** READ CONNECTION POINT, singular*/
function readConnectionPoint(payload, processIndicatorId = null) {
    return async function (dispatch) {
        dispatch(readConnectionPointRequesting());

        const result = await getSync(`${companyUrl}/destinationgroup/get-by-id/?id=${payload.id}`, processIndicatorId);
        if (result.isSuccess) {
            dispatch(readConnectionPointSuccess(result.response));
        } else {
            dispatch(readConnectionPointFailure(result.error));
        }
        return result;
    }
};

function readConnectionPointRequesting() {
    return {
        type: actionTypes.READ_CONNECTION_POINT_REQUESTING
    }
}

function readConnectionPointSuccess(payload) {
    return {
        type: actionTypes.READ_CONNECTION_POINT_SUCCESS,
        payload
    }
}

function readConnectionPointFailure(payload) {
    return {
        type: actionTypes.READ_CONNECTION_POINT_FAILURE,
        payload
    }
}
/** end READ CONNECTION POINT */


/** READ CONNECTION POINTS, plural */
function readConnectionPoints(processIndicatorId) {
    return async function (dispatch) {
        dispatch(readConnectionPointsRequesting());

        const result = await getSync(`${companyUrl}/destinationgroup/list`, processIndicatorId);
        if (result.isSuccess) {
            dispatch(readConnectionPointsSuccess(result.response));
        } else {
            dispatch(readConnectionPointsFailure(result.error));
        }
        return result;
    }
};

function readConnectionPointsRequesting() {
    return {
        type: actionTypes.READ_CONNECTION_POINTS_REQUESTING
    }
}

function readConnectionPointsSuccess(payload) {
    return {
        type: actionTypes.READ_CONNECTION_POINTS_SUCCESS,
        payload
    }
}

function readConnectionPointsFailure(payload) {
    return {
        type: actionTypes.READ_CONNECTION_POINTS_FAILURE,
        payload
    }
}
/** end READ CONNECTION POINTS */


/** UPDATE CONNECTION POINT */
function updateConnectionPoint(payload, processIndicatorId = null) {
    return async function (dispatch) {
        dispatch(updateConnectionPointRequesting());

        const result = await postSync(`${companyUrl}/destinationgroup/update`, payload, processIndicatorId);
        if (result.isSuccess) {
            dispatch(updateConnectionPointSuccess(result.response));
        } else {
            dispatch(updateConnectionPointFailure(result.error));
        }
        return result;
    }
};

function updateConnectionPointRequesting() {
    return {
        type: actionTypes.UPDATE_CONNECTION_POINT_REQUESTING
    }
}

function updateConnectionPointSuccess(payload) {
    return {
        type: actionTypes.UPDATE_CONNECTION_POINT_SUCCESS,
        payload
    }
}

function updateConnectionPointFailure(payload) {
    return {
        type: actionTypes.UPDATE_CONNECTION_POINT_FAILURE,
        payload
    }
}
/** end UPDATE CONNECTION POINT */


/** DELETE CONNECTION POINT */

/** end DELETE CONNECTION POINT */


/** DELETE CONNECTION POINTS, plural */

/** end DELETE CONNECTION POINTS, plural */


/** SEARCH CONNECTION POINTS */
function searchConnectionPoints(payload, processIndicatorId) {
    return async function (dispatch) {
        dispatch(searchConnectionPointsRequesting());

        const result = await getSync(`${companyUrl}/destinationgroup/search?query=${payload.query}`, processIndicatorId);
        if (result.isSuccess) {
            dispatch(searchConnectionPointsSuccess(result.response.result));
            if (result.response.result.length === 1) {
                dispatch(setSuffixesOfConnectionPointSuccess(result.response.result[0].destinations));
            }
        } else {
            dispatch(searchConnectionFailure(result.error));
        }
        return result;
    }
};

function searchConnectionPointsRequesting() {
    return {
        type: actionTypes.SEARCH_CONNECTION_POINTS_REQUESTING
    }
}

function searchConnectionPointsSuccess(payload) {
    return {
        type: actionTypes.SEARCH_CONNECTION_POINTS_SUCCESS,
        payload
    }
}

function searchConnectionFailure(payload) {
    return {
        type: actionTypes.SEARCH_CONNECTION_POINTS_FAILURE,
        payload
    }
}
/** end SEARCH CONNECTION POINTS */



/** CREATE SUFFIX */
function createSuffix(payload) {
    return async function (dispatch) {
        dispatch(createSuffixRequesting());

        const result = await postSync(
            `${companyUrl}/destination/create`,
            payload
        );
        if (result.isSuccess) {
            dispatch(createSuffixSuccess(result.response));
        } else {
            dispatch(createSuffixFailure(result.error));
        }
        return result;
    };
}

function createSuffixRequesting() {
    return {
        type: actionTypes.CREATE_SUFFIX_REQUESTING,
    };
}

function createSuffixSuccess(payload) {
    return {
        type: actionTypes.CREATE_SUFFIX_SUCCESS,
        payload,
    };
}

function createSuffixFailure(payload) {
    return {
        type: actionTypes.CREATE_SUFFIX_FAILURE,
        payload,
    };
}
/** end CREATE SUFFIX */

/** FETCH SUFFIXES  */
function fetchSuffixes() {
    return async function (dispatch) {
        dispatch(fetchSuffixesRequesting());
        const result = await postSync(`${companyUrl}/destination/list`);
        if (result.isSuccess) {
            dispatch(fetchSuffixesSuccess(result.response));
        } else {
            dispatch(fetchSuffixesFailure(result.error));
        }
        return result;
    };
}

function fetchSuffixesRequesting() {
    return {
        type: actionTypes.FETCH_SUFFIXES_REQUESTING,
    };
}

function fetchSuffixesSuccess(payload) {
    return {
        type: actionTypes.FETCH_SUFFIXES_SUCCESS,
        payload,
    };
}

function fetchSuffixesFailure(payload) {
    return {
        type: actionTypes.FETCH_SUFFIXES_FAILURE,
        payload,
    };
}
/** end FETCH SUFFIXES  */

/** UPDATE SUFFIX */
function updateSuffix(payload) {
    return async function (dispatch) {
        dispatch(updateSuffixRequesting());

        const result = await postSync(
            `${companyUrl}/destination/update`,
            payload
        );
        if (result.isSuccess) {
            dispatch(updateSuffixSuccess(result.response));
        } else {
            dispatch(updateSuffixFailure(result.error));
        }
        return result;
    };
}

function updateSuffixRequesting() {
    return {
        type: actionTypes.UPDATE_SUFFIX_REQUESTING,
    };
}

function updateSuffixSuccess(payload) {
    return {
        type: actionTypes.UPDATE_SUFFIX_SUCCESS,
        payload,
    };
}

function updateSuffixFailure(payload) {
    return {
        type: actionTypes.UPDATE_SUFFIX_FAILURE,
        payload,
    };
}
/** end UPDATE SUFFIX */

/** DELETE SUFFIX */
function deleteSuffix(payload) {
    return async function (dispatch) {
        dispatch(deleteSuffixRequesting());
        const result = await deleteSync(
            `${companyUrl}/destination/delete-by-id/${payload}`,
            payload
        );
        if (result.isSuccess) {
            dispatch(deleteSuffixSuccess(result.response));
        } else {
            dispatch(deleteSuffixFailure(result.error));
        }
        return result;
    };
}

function deleteSuffixRequesting() {
    return {
        type: actionTypes.DELETE_SUFFIX_REQUESTING,
    };
}

function deleteSuffixSuccess(payload) {
    return {
        type: actionTypes.DELETE_SUFFIX_SUCCESS,
        payload,
    };
}

function deleteSuffixFailure(payload) {
    return {
        type: actionTypes.DELETE_SUFFIX_FAILURE,
        payload,
    };
}
/** end DELETE SUFFIX */

/** SEARCH SUFFIXES  */
function searchSuffixes(payload) {
    return async function (dispatch) {
        dispatch(searchSuffixesRequesting());
        const query = payload.query == undefined ? "query=" : `query=${payload.query}`;
        let point = "";
        if (payload.extraParam) {
            point = payload.extraParam.id == undefined ? "" : `&destinationGroup=${payload.extraParam.id}`;
        }
        const urlQueriesString = query + point;
        const result = await getSync(`${companyUrl}/destination/search?${urlQueriesString}`);

        if (result.isSuccess) {
            dispatch(searchSuffixesSuccess(result.response.result));
        } else {
            dispatch(searchSuffixesFailure(result.error));
        }
        return result;
    };
}

function searchSuffixesRequesting() {
    return {
        type: actionTypes.FETCH_SUFFIXES_REQUESTING,
    };
}

function searchSuffixesSuccess(payload) {
    return {
        type: actionTypes.FETCH_SUFFIXES_SUCCESS,
        payload,
    };
}

function setSuffixesOfConnectionPointSuccess(payload) {
    return {
        type: actionTypes.FETCH_SUFFIXES_OF_CONNECTION_POINT_SUCCESS,
        payload,
    };
}



function searchSuffixesFailure(payload) {
    return {
        type: actionTypes.FETCH_SUFFIXES_FAILURE,
        payload,
    };
}
/** end SEARCH SUFFIXES  */

/** FETCH TYPES  */
function fetchTypes(payload = { isActive: true }) {
    return async function (dispatch) {
        dispatch(fetchTypesRequesting());
        const result = await getSync(
            `${definitionsURL}/lookups?isactive=${payload.isActive}`
        );
        if (result.isSuccess) {
            dispatch(fetchTypesSuccess(result.response.result.lookupList));
        } else {
            dispatch(fetchTypesFailure(result.error));
        }
        return result;
    };
}
function fetchTypesRequesting() {
    return {
        type: actionTypes.FETCH_TYPES_REQUESTING,
    };
}

function fetchTypesSuccess(payload) {
    return {
        type: actionTypes.FETCH_TYPES_SUCCESS,
        payload,
    };
}

function fetchTypesFailure(payload) {
    return {
        type: actionTypes.FETCH_TYPES_FAILURE,
        payload,
    };
}
/** end FETCH TYPES  */


/** FILTER CURRENCIES */
function filterCurrencies(payload) {
    return async function (dispatch) {
        dispatch(filterCurrenciesRequesting());

        const result = await postSync(
            `${definitionsURL}/currency/search?query=${payload.query}`
        );
        if (result.isSuccess) {
            dispatch(filterCurrenciesSuccess(result.response));
        } else {
            dispatch(filterCurrenciesFailure(result.error));
        }
        return result;
    };
}

function filterCurrenciesRequesting() {
    return {
        type: actionTypes.FILTER_CURRENCIES_REQUESTING,
    };
}

function filterCurrenciesSuccess(payload) {
    return {
        type: actionTypes.FILTER_CURRENCIES_SUCCESS,
        payload,
    };
}

function filterCurrenciesFailure(payload) {
    return {
        type: actionTypes.FILTER_CURRENCIES_FAILURE,
        payload,
    };
}
/** end FILTER CURRENCIES */


/** FILTER COUNTRIES */
function filterCountries(payload) {
    return async function (dispatch) {
        dispatch(filterCountriesRequesting());

        const result = await postSync(
            `${definitionsURL}/country/search?query=${payload.query}`
        );
        if (result.isSuccess) {
            dispatch(filterCountriesSuccess(result.response));
        } else {
            dispatch(filterCountriesFailure(result.error));
        }
        return result;
    };
}

function filterCountriesRequesting() {
    return {
        type: actionTypes.FILTER_COUNTRIES_REQUESTING,
    };
}

function filterCountriesSuccess(payload) {
    return {
        type: actionTypes.FILTER_COUNTRIES_SUCCESS,
        payload,
    };
}

function filterCountriesFailure(payload) {
    return {
        type: actionTypes.FILTER_COUNTRIES_FAILURE,
        payload,
    };
}
/** end FILTER COUNTRIES */


/** SETTINGS */
function getSettings(payload) {
    return async function (dispatch) {
        dispatch(filterSettingsRequesting());

        const result = await getSync(`${definitionsURL}/settings/${payload}`);

        if (result.isSuccess) {
            dispatch(filterSettingsSuccess(payload, result.response.result));
        } else {
            dispatch(filterSettingsFailure(result.error));
        }
        return result;
    };
}

function filterSettingsRequesting() {
    return {
        type: actionTypes.FILTER_SETTINGS_REQUESTING,
    };
}

function filterSettingsSuccess(key, value) {
    return {
        type: actionTypes.FILTER_SETTINGS_SUCCESS,
        payload: { key, value }
    };
}

function filterSettingsFailure(payload) {
    return {
        type: actionTypes.FILTER_SETTINGS_FAILURE,
        payload,
    };
}
/** end FILTER COUNTRIES */

export {
    searchAssociates,
    searchAssociatesSuccess,
    createConnectionPoint,
    readConnectionPoint,
    readConnectionPoints,
    updateConnectionPoint,
    searchConnectionPoints,
    createSuffix,
    fetchSuffixes,
    updateSuffix,
    deleteSuffix,
    searchSuffixes,
    fetchTypes,
    filterCurrencies,
    filterCountries,
    getSettings
};
