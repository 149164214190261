import { Field } from "redux-form";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import { TimeComponent } from "./Pure/TimeComponent";
import { addValidationToArray } from "./Validation/FormValidation";

export const TimePickerFormComponent = ({ name, id, isRequired, validationArray, isDisabled, onChange, label, minuteStep }) => {
    const [selectedTime, setSelectedTime] = useState(null);

    const timeChangedHandler = (event, newValue, previousValue, fieldId) => {
        setSelectedTime(newValue);
        onChange(event, newValue, previousValue, fieldId);
    };

    const prepareValidation = (isRequired, validationArray) => {
        let validation = addValidationToArray(isRequired, validationArray);
        return validation;
    };

    const validation = prepareValidation(isRequired, validationArray);
    return (<Field
        isRequired={isRequired}
        name={name}
        component={TimeComponent}
        className="form-control"
        label={label}
        minuteStep={minuteStep}
        defaultValue={selectedTime}
        onChange={timeChangedHandler}
        validate={validation}
        disabled={isDisabled}
    />);
};

TimePickerFormComponent.propTypes = {
    name: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    validationArray: PropTypes.arrayOf(PropTypes.func)
};