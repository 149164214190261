import { Col, Form } from "react-bootstrap";
import { TextAreaInputFormComponent } from "../../TextAreaInputFormComponent";
import { AuthorizationWrapper } from "./AuthorizationRenderer";
import React from "react";
import * as PropTypes from "prop-types";

export const TextAreaRenderer = (field, formColumnCount) => {
    const textAreaControl = (<Form.Group key={field.authorizationWrapper ? undefined : field.name} as={Col} md={formColumnCount}>
        <Form.Label>{field.name}{field.isRequired && ' *'}</Form.Label>
        <TextAreaInputFormComponent name={field.id} isRequired={field.isRequired} rowCount={field.rowCount} validationArray={field.validations} isDisabled={field.isDisabled} onChange={field.onChange} />
    </Form.Group>);

    if (field.authorizationWrapper) {
        return AuthorizationWrapper(textAreaControl, field.id, field.authorizationWrapper);
    }
    return textAreaControl;
};

TextAreaRenderer.propTypes = {
    item: PropTypes.object.isRequired
};