import React from 'react';
import { connect } from 'react-redux';
import { resetAllFilters } from '../../store/ducks/articles/actionCreators';
import styles from '../../RoutedComponents/Shipments.module.css';
import { Modal, Row, Col } from 'react-bootstrap';
import { localDateTimeToEpochSecond, epochSecondToLocalDateTime } from '../../core/utcToEpochConverter.js';
import DatePicker from "react-datepicker";
import { InputDatalist } from '../InputDatalist/InputDatalist';
import { searchAssociates } from '../../store/ducks/definitions/actionCreators';

function ModalArticlesMoreFilters({
    showModal = true,
    onHide,
    modalData,
    resetAllFilters,
    searchAssociates
}) {
    const onSubmit = (e) => {
        modalData.onSubmitQuery(e);
        onHide();
    }

    const onReset = (e) => {
        // more filters are inside a form, so use reset event handler. reset more filters in redux store
    }

    const onResetAll = (e) => {
        // reset both more filters form and the primary filters form and redux store
        resetAllFilters();
    }

    const onClickHide = (e) => {
        onHide();
    }

    const delimiter = (data) => data && data.code && data.name ? ',  ' : '';

    const renderInputDatalist = ({
        value,
        onChange,
        fetchDataFunction,
        data,
        renderDatalistOptions,
        label,
        name,
        placeholder,
        autoComplete,
        error,
        md = 6,
        queryParameterName = "query"
    }) => {
        return (
            <div>
                <InputDatalist
                    data={data}
                    value={value}
                    onChange={onChange}
                    fetchDataFunction={fetchDataFunction}
                    renderDatalistOptions={renderDatalistOptions}
                    labelText={label}
                    name={name}
                    placeholder={placeholder}
                    autoComplete={autoComplete}
                    queryParameterName={queryParameterName}
                    labelClassName={styles.label}
                />
                <small className="form-text text-danger">{error ? error : ''}</small>
                {/*<small className="form-text text-danger">{validationErrors[name[0]]}</small>*/}
            </div>
        );
    }

    const renderBooleanSelect = (label, name, value, onChange, error) => {
        return (
            <>
                <label htmlFor={name} className={styles.label}>{label}</label><br></br>
                <select
                    value={value}
                    onChange={onChange}
                    name={name}
                    id={name}
                >
                    <option value={''}>Select one</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </select>
                <small className="form-text text-danger">{error ? error : ''}</small>
            </>
        );
    }

    const renderDatePicker = (id, label, selector, operator) => {
        return (
            <>
                <label htmlFor={id} className={styles.label}>{label}</label><br></br>
                <DatePicker
                    id={id}
                    selected={modalData.valueSelectorForFilters(selector, operator) ? epochSecondToLocalDateTime(modalData.valueSelectorForFilters(selector, operator)) : ''}
                    onChange={(dateTime) => modalData.onChangeDateQuery(selector, operator, dateTime ? localDateTimeToEpochSecond(dateTime) : '')}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    todayButton="Today"
                    type='text'
                    dropdownMode="select"
                />
            </>
        )
    }

    return (
        <Modal
            show={showModal}
            onHide={onClickHide}
            size="xl"
            centered
        >
            <form onSubmit={onSubmit} onReset={onReset}>
                <Modal.Header closeButton>
                    <Col>
                        <Row>
                            <Col>
                                <Modal.Title>More filters</Modal.Title>
                            </Col>
                        </Row>
                    </Col>
                </Modal.Header>

                <Modal.Body>
                    <Col>
                        <Row>


                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="deliveryNote" className={styles.label}>Delivery Note</label><br></br>
                                <input id='deliveryNote' value={modalData.valueSelectorForFilters('deliveryNote')} onChange={modalData.onChangeQuery} /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="externalCode" className={styles.label}>Part Number</label><br></br>
                                <input id='externalCode' value={modalData.valueSelectorForFilters('externalCode')} onChange={modalData.onChangeQuery} /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderInputDatalist({
                                    label: 'Supplier',
                                    name: 'supplierId',
                                    value: `${modalData.supplier ? modalData.supplier.code : ''}${delimiter(modalData.supplier)}${modalData.supplier ? modalData.supplier.name : ''}`,
                                    onChange: modalData.onChangeInputDatalist,
                                    data: modalData.associates.items,
                                    fetchDataFunction: searchAssociates,
                                    queryParameterName: "queryStringValue",
                                    renderDatalistOptions: (item, i) => <option value={`(${item['code']})${delimiter(item)}${item['name']}`} key={i} />,
                                    placeholder: "Enter Id, Code or Name"
                                })
                                }
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderDatePicker('invoiceDateStart', 'Invoice Date Start', 'invoiceDate', '>=')}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderDatePicker('invoiceDateEnd', 'Invoice Date End', 'invoiceDate', '<=')}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="invoiceNumber" className={styles.label}>Invoice Number</label><br></br>
                                <input id='invoiceNumber' value={modalData.valueSelectorForFilters('invoiceNumber')} onChange={modalData.onChangeQuery} /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderDatePicker('inStockDateStart', 'InStock Date Start', 'inStockDate', '>=')}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderDatePicker('inStockDateEnd', 'InStock Date End', 'inStockDate', '<=')}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="pONumber" className={styles.label}>Po Number</label><br></br>
                                <input id='pONumber' value={modalData.valueSelectorForFilters('pONumber')} onChange={modalData.onChangeQuery} /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderDatePicker('waitingPickDateStart', 'Waiting Pick Date Start', 'waitingPickDate', '>=')}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderDatePicker('waitingPickDateEnd', 'Waiting Pick Date End', 'waitingPickDate', '<=')}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="batchNumber" className={styles.label}>Batch Number</label><br></br>
                                <input id='batchNumber' value={modalData.valueSelectorForFilters('batchNumber')} onChange={modalData.onChangeQuery} /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderDatePicker('pickedDateStart', 'Picked Date Start', 'pickedDate', '>=')}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderDatePicker('pickedDateEnd', 'Picked Date End', 'pickedDate', '<=')}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="arrivalShipment.unit.unitCode" className={styles.label}>Arrival Unit Code</label><br></br>
                                <input id='arrivalShipment.unit.unitCode' value={modalData.valueSelectorForFilters('arrivalShipment.unit.unitCode')} onChange={modalData.onChangeQuery} /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderDatePicker('shippedDateStart', 'Shipped Date Start', 'shippedDate', '>=')}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderDatePicker('shippedDateEnd', 'Shipped Date End', 'shippedDate', '<=')}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="deliveryShipment.unit.unitCode" className={styles.label}>Delivery Unit Code</label><br></br>
                                <input id='deliveryShipment.unit.unitCode' value={modalData.valueSelectorForFilters('deliveryShipment.unit.unitCode')} onChange={modalData.onChangeQuery} /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderDatePicker('createdDateStart', 'Created Date Start', 'createdDate', '>=')}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderDatePicker('createdDateEnd', 'Created Date End', 'createdDate', '<=')}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderBooleanSelect('Is Rush?', 'isRush', modalData.valueSelectorForFilters('isRush'), modalData.onChangeQuery)}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="actualMeasurement.itemQuantity.value" className={styles.label}>Item Quantity Min.</label><br></br>
                                <input type="number" id='actualMeasurement.itemQuantity.value' data-operator=">=" min={0} steps="any" value={modalData.valueSelectorForFilters('actualMeasurement.itemQuantity.value', '>=')} onChange={modalData.onChangeQuery} /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="actualMeasurement.itemQuantity.value" className={styles.label}>Item Quantity Max.</label><br></br>
                                <input type="number" id='actualMeasurement.itemQuantity.value' data-operator="<=" min={0} steps="any" value={modalData.valueSelectorForFilters('actualMeasurement.itemQuantity.value', '<=')} onChange={modalData.onChangeQuery} /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderBooleanSelect('Is Held?', 'isHeld', modalData.valueSelectorForFilters('isHeld'), modalData.onChangeQuery)}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="actualMeasurement.volume.value" className={styles.label}>Volume Min.</label><br></br>
                                <input type="number" id='actualMeasurement.volume.value' data-operator=">=" min={0} steps="any" value={modalData.valueSelectorForFilters('actualMeasurement.volume.value', '>=')} onChange={modalData.onChangeQuery} /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="actualMeasurement.volume.value" className={styles.label}>Volume Max.</label><br></br>
                                <input type="number" id='actualMeasurement.volume.value' data-operator="<=" min={0} steps="any" value={modalData.valueSelectorForFilters('actualMeasurement.volume.value', '<=')} onChange={modalData.onChangeQuery} /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4"></Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="actualMeasurement.weight.value" className={styles.label}>Net Weight Min.</label><br></br>
                                <input type="number" id='actualMeasurement.weight.value' data-operator=">=" min={0} steps="any" value={modalData.valueSelectorForFilters('actualMeasurement.grossWeight.value', '>=')} onChange={modalData.onChangeQuery} /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="actualMeasurement.weight.value" className={styles.label}>Net Weight Max.</label><br></br>
                                <input type="number" id='actualMeasurement.weight.value' data-operator="<=" min={0} steps="any" value={modalData.valueSelectorForFilters('actualMeasurement.grossWeight.value', '<=')} onChange={modalData.onChangeQuery} /><br></br>
                            </Col>
                            
                            <Col xs="12" sm="6" md="6" lg="4"></Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="actualMeasurement.grossWeight.value" className={styles.label}>Gross Weight Min.</label><br></br>
                                <input type="number" id='actualMeasurement.grossWeight.value' data-operator=">=" min={0} steps="any" value={modalData.valueSelectorForFilters('actualMeasurement.grossWeight.value', '>=')} onChange={modalData.onChangeQuery} /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="actualMeasurement.grossWeight.value" className={styles.label}>Gross Weight Max.</label><br></br>
                                <input type="number" id='actualMeasurement.grossWeight.value' data-operator="<=" min={0} steps="any" value={modalData.valueSelectorForFilters('actualMeasurement.grossWeight.value', '<=')} onChange={modalData.onChangeQuery} /><br></br>
                            </Col>
                        </Row>
                    </Col>
                </Modal.Body>

                <Modal.Footer>
                    <Col>
                        <Row>

                            {/**
                            <Col xs="12" sm="6" md="6" lg="2" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <input type="reset" value='Reset All' className={styles.reset} onClick={onResetAll} />
                            </Col>

                            <Col></Col>

                            <Col xs="12" sm="6" md="6" lg="2" style={{ display: 'flex' }}>
                                <input type="reset" value='Reset' />
                            </Col>
                            */}

                            <Col></Col>
                            <Col></Col>

                            <Col xs="12" sm="6" md="6" lg="3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <input type="submit" value='Filter' />
                            </Col>

                        </Row>
                    </Col>
                </Modal.Footer>
            </form>
        </Modal >
    );
}

// get the state of the shipment overview table pass it to value of filters, pass it to the table
const mapStateToProps = (state) => {
    return {
        // filters: state.shipmentsOverview.filters,
        // lastUsedFilters: state.shipmentsOverview.lastUsedFilters,
        // types: state.definitions.types
    }
}

const mapDispatchToProps = {
    resetAllFilters,
    searchAssociates,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalArticlesMoreFilters);

// TODO: NICE TO HAVE: keep last used filters, only if they have been searched with