/* eslint-disable jsx-a11y/anchor-is-valid */
import { Down /* BurgerMenu, More */ } from '@dfds-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Label } from '@dfds-ui/react-components';
import { ListItem, ListText } from '@dfds-ui/react-components/lists';
import { NavBar, NavBarIcon, NavBarItem } from '@dfds-ui/react-components/nav-bar';
import axios from 'axios';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink /* useLocation */ } from 'react-router-dom';
import '../../core/utility';
import { buildNumber } from '../../core/utility';
import PolicyGroupAccessHoc from '../../RoutedComponents/Routes/PolicyGroupAccessHoc';
import classes from '../../RoutedComponents/Routes/routes.module.css';
import './Header.css';

const LinkTo = (path) => ({ ...props }) => {
  return (
    <NavLink
      activeClassName={classes.active}
      to={path}
      isActive={(match) => match && match.url === path}
      {...props}
    />
  );
};
// carrier-booking-list
// booking/list

const Header = ({ authenticatedUser }) => {
  // let location = useLocation();

  const [updateNoticeMessage, setUpdateNoticeMessage] = useState(false);
  //**New release feature. */
  const fetchAndCheckBuildNumber = (url) => {
    if (window.location.href.includes('localhost')) {
      return;
    }

    const client = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL
    });

    client
      .get('/utility.json') // JSON File Path
      .then((response) => {
        if (buildNumber !== response.data.buildNumber) {
          setUpdateNoticeMessage(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // useEffect(() => {
  //     setInterval(() => {
  //         fetchAndCheckBuildNumber(process.env.REACT_APP_BASE_URL + '/utility.json');
  //     }, 300000);
  // }, []);

  //**New release feature. */

  return (
    <>
      <NavBar showLogo={false}>
        <a href="/">
          <img src="/image/logo.png" className="logo_new" alt="logo" />
        </a>
        {authenticatedUser && (
          <NavBarIcon
            className="navbar-more-menu"
            alignment="left"
            menu={navBarMenu.map(({ componentName, link, label }) => {
              return (
                <PolicyGroupAccessHoc componentName={componentName} key={link}>
                  <ListItem clickable as={LinkTo(link)}>
                    <ListText>{label}</ListText>
                  </ListItem>
                </PolicyGroupAccessHoc>
              );
            })}
            title="Menu"
          >
            <FontAwesomeIcon icon="ellipsis-v" id="navbar-more-menu-icon" />
          </NavBarIcon>
        )}
        {renderLinks(authenticatedUser)}
        {renderUserInfo(authenticatedUser)}
      </NavBar>
      <div
        className={classes.parentUpdateNotice}
        style={{ display: updateNoticeMessage ? '' : 'none' }}
      >
        <div className={classes.updateNotice}>
          New version of application has been published. Please refresh your page or{' '}
          <a href="#" className={classes.linkColor} onClick={() => window.location.reload()}>
            click here.
          </a>
        </div>
      </div>
    </>
  );
};

const navBarMenu = [
  { componentName: 'ShipmentDomainLink', link: '/shipments', label: 'Shipment' },
  { componentName: 'ArticlesDomainLink', link: '/articles', label: 'Inventory' },
  { componentName: 'BookingInternalLinkSection', link: '/booking/list', label: 'Booking' },
  {
    componentName: 'BookingExternalLinkSection',
    link: '/booking/carrier-booking-list',
    label: 'My Bookings'
  },
  { componentName: 'TaskLinkSection', link: '/task', label: 'Task' },
  {
    componentName: 'ContainerBookingsSection',
    link: '/container-bookings',
    label: 'Container Bookings'
  },
  { componentName: 'ReceivingPageLink', link: '/thu/receiving', label: 'Receiving' }
];

const renderLinks = (authenticatedUser) => {
  if (!authenticatedUser) {
    return null;
  }
  return navBarMenu.map(({ componentName, link, label }) => {
    return (
      <PolicyGroupAccessHoc componentName={componentName} key={link}>
        <NavBarItem as={LinkTo(link)}>{label}</NavBarItem>
      </PolicyGroupAccessHoc>
    );
  });
};

const userMenu = [
  { componentName: 'UserAdminSection', link: '/user', label: 'Users' },
  { componentName: 'CompanyAdminSection', link: '/companies', label: 'Companies' },
  { componentName: 'BusinessAreaAdminSection', link: '/businessarea', label: 'Business Areas' },
  {
    componentName: 'BookingAdminSection',
    link: '/booking/calendar-rule',
    label: 'Booking Configurations'
  },
  { componentName: 'RoleAdminSection', link: '/role', label: 'Role Administration' },
  { componentName: 'HistorySection', link: '/history', label: 'History Tracking' },
  {
    componentName: 'HistorySection',
    link: '/edit-history-json-definition',
    label: 'Edit History Tags'
  },
  { componentName: 'CompanyAdminSection', link: '/service', label: 'Services' },
  { componentName: 'VoyagesSection', link: '/voyages', label: 'Voyages' },
  { componentName: 'SuffixSection', link: '/connection-points', label: 'Connection Points' },
  { componentName: 'SuffixSection', link: '/suffixes', label: 'Suffixes' },
  { componentName: 'MessagesPageLink', link: '/messages', label: 'Messages' },
  {
    componentName: 'BackOffice',
    link: '/backoffice',
    label: 'Back Office'
  }
];
const renderUserInfo = (authenticatedUser) => {
  if (!authenticatedUser) {
    return;
  }
  return (
    <>
      <NavBarIcon
        href="#"
        alignment="right"
        menu={
          <div style={{ minWidth: '205px' }}>
            {userMenu.map((um, i) => (
              <PolicyGroupAccessHoc key={i} componentName={um.componentName}>
                <ListItem clickable as={LinkTo(um.link)}>
                  <ListText>{um.label}</ListText>
                </ListItem>
              </PolicyGroupAccessHoc>
            ))}

            <ListItem clickable as={LinkTo('/logout')}>
              <ListText bold>Logout</ListText>
            </ListItem>
          </div>
        }
      >
        {/* TODO: Buralarda geçici olarak inline style verildi. Component shared-ui da henüz hazır değil. */}
        <Label style={{ fontSize: '16px' }}>{authenticatedUser.name}</Label>
        <Down style={{ fontSize: '16px', marginTop: '-8px', marginLeft: '5px' }} />
      </NavBarIcon>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authenticatedUser: state.auth.authenticatedUser
  };
};

export default connect(mapStateToProps)(Header);
