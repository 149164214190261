import { Field } from "redux-form";
import React from "react";
import { InputComponent } from "./Pure/InputComponent";
import * as PropTypes from "prop-types";
import {
    addValidationToArray
} from "./Validation/FormValidation";
import { memoize } from "lodash";

const numberRequired = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined;
const minValueValidation = min => value => value && value < min ? `Must be at least ${min}` : undefined;
const maxValueValidation = max => value => value && value > max ? `Must be at least ${max}` : undefined;




const memoizedPrepareValidation = memoize((isNumberRequired, minValue, maxValue, validationArray) => {
    let validation = addValidationToArray(isNumberRequired, validationArray);
    if (isNumberRequired)
        validation.push(numberRequired);
    if (minValue)
        validation.push(minValueValidation(minValue));
    if (maxValue)
        validation.push(maxValueValidation(maxValue));
    return validation;
});

export const NumberInputFormComponent = ({ name, step, minValue, maxValue, isRequired, isDisabled, validationArray, onChange }) => {
    const validation = memoizedPrepareValidation(isRequired, minValue, maxValue, validationArray);
    return (<Field
        onChange={onChange}
        name={name}
        component={InputComponent}
        type="number"
        disabled={isDisabled}
        step={step}
        min={minValue}
        max={maxValue}
        validate={validation}
    />)
};

NumberInputFormComponent.propTypes = {
    name: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    validationArray: PropTypes.arrayOf(PropTypes.func)
};