import type from '../type/holdTerminalItem';

const initialState = {
    itemList: [],
    holdTypeList: [],
    reasonTypeList: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case type.STATE_CLEARED:
            return {
                ...initialState,
                itemList: [],
                holdTypeList: [],
                reasonTypeList: []
            }
        case type.DATA_RETRIEVED:
            return {
                itemList: action.payload.itemList,
                holdTypeList: action.payload.holdTypeList,
                reasonTypeList: action.payload.reasonTypeList
            }
        case type.DATA_UPDATED:
            return {
                ...state,
                itemList: action.payload.itemList
            }
        default:
            return state
    }
}