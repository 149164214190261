import httpClient from "../../core/httpclient";
import post, { get } from '../../core/httpClientSync';
import { spinnerService } from "@simply007org/react-spinners";
import businessAreaActions from "../type/businessArea";

const apiUrl = 'astrid-slotbooking-api/api/businessarea';
const companyApiUrl = 'xms-company-api/api/company';


export const getBusinessAreaList = () => async dispatch => {
    const endPoint = `${apiUrl}/getall`;
    spinnerService.show('getBusinessAreaList')
    httpClient.get(endPoint)
        .then(response => {
            dispatch({
                type: businessAreaActions.BUSINESS_AREA_LIST_RECEIVED,
                payload: response.data.result
            });
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            spinnerService.hide('getBusinessAreaList')
        });
}

export const getBusinessArea = (businessAreaList, id) => async dispatch => {
    dispatch({
        type: businessAreaActions.BUSINESS_AREA_RECEIVED,
        payload: businessAreaList && businessAreaList.length > 0 ? businessAreaList.find(x => x.id === id) : null
    });
}

export const insertOrUpdateBusinessArea = (id, name, companies) => async dispatch => {
    const endPoint = `${apiUrl}/save`;
    spinnerService.show('insertOrUpdateBusinessArea')
    const insertOrUpdateBusinessAreaRequest = {
        id: id,
        name: name.trim(),
        companies: companies
    }

    httpClient.post(endPoint, insertOrUpdateBusinessAreaRequest)
        .then(response => {
            dispatch({
                type: businessAreaActions.BUSINESS_AREA_CLEAR_STATE
            });
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            spinnerService.hide('insertOrUpdateBusinessArea');
        });
}

export const deleteBusinessArea = (id) => async dispatch => {
    const endPoint = `${apiUrl}/delete?id=${id}`;

    spinnerService.show('deleteBusinessArea')

    const deleteBusinessAreaRequest = {

    }

    httpClient.post(endPoint, deleteBusinessAreaRequest)
        .then(response => {
            dispatch({
                type: businessAreaActions.BUSINESS_AREA_CLEAR_STATE
            });
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            spinnerService.hide('deleteBusinessArea');
        });

}

export const clearBusinessAreaState = () => async dispatch => {
    dispatch({
        type: businessAreaActions.BUSINESS_AREA_CLEAR_STATE
    });
}

export const getClients = () => async dispatch => {
    const response = await post(`${companyApiUrl}/search`, { isClient: true, pageSize: 1000 }, 'createOrUpdateBusinessArea');
    response.isSuccess && dispatch({
        type: businessAreaActions.COMPANY_LIST_RECEIVED,
        payload: response.response.map(item => ({ value: item.id, text: item.name }))
    })
}
