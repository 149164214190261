import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, SelectField } from '@dfds-ui/react-components';
import { Row, Col, /* Button */ } from 'react-bootstrap';
import { clearThuData, createThu, setThuList, setPrintThuList } from '../store/action/thuDetailAction';
import PageHeader from '../component/PageHeader/PageHeader';
import ThuList from '../component/Thu/ThuList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import { getBarcodeResult } from '../core/utility';
import { getSettings, searchSuffixes } from '../store/ducks/definitions/actionCreators';
import { getShipmentsByShipmentNo } from '../store/ducks/shipments/actionCreators';
import styles from './ThuReceiving.module.css';
import { toast } from 'react-toastify';
import PrintThu from './PrintThu';
import _ from 'lodash';
import Scanner from '../component/Scanner/Scanner';

const ThuReceiving = (props) => {

    //redux elements
    const { shipments, clearThuData, thuList, createThu, packageTypeList, getSettings, barcodeRules, setThuList, getShipmentsByShipmentNo, setPrintThuList, searchSuffixes, suffixes } = props;

    //parent inputs

    const [weightTimeout, setWeightTimeout] = useState();
    const [inputValue, setInputValue] = useState('');
    const [externalId, setExternalId] = useState('');
    const [supplierCode, setSupplierCode] = useState('');
    const [deliveryNote, setDeliveryNote] = useState('');
    const [externalCode, setExternalCode] = useState('');
    const [quantity, setQuantity] = useState('');
    const [packageType, setPackageType] = useState('');
    const [weight, setWeight] = useState('');
    const [tempWeight, setTempWeight] = useState('');
    const [volume, setVolume] = useState('');
    const [isGLabel, setIsGLabel] = useState(false);
    const [message, setMessage] = useState('');
    const [shipmentNo, setShipmentNo] = useState('');
    const [shipmentId, setShipmentId] = useState('');
    const [isShipmentDisabled, setIsShipmentDisabled] = useState(false);
    const [isShipmentAutoFocus, setIsShipmentAutoFocus] = useState(true);
    const [shipment, setShipment] = useState('');
    const [currentQuantityColor, setCurrentQuantityColor] = useState('black');
    const [groupedArticleCount, setGroupedArticleCount] = useState(0);
    const [articleFields, setArticleFields] = useState([]);

    const serialInput = useRef(null);
    const shipmentNoInput = useRef(null);

    useEffect(() => {
        clearThuData();
        reset(true);
        getSettings(CONSTANTS.BARCODERULES);
    }, [])

    const [shipmentNoWarning, setShipmentNoWarning] = useState();
    useEffect(() => {
        if (shipmentNo != "") {
            if (shipments && shipments.items.length === 1) {
                setShipment(shipments.items[0]);
                setShipmentNoWarning();
                setThuBarcodesDetectedAcc([]);
            } else if (shipments && shipments.items.length === 0) {
                // alert("Could not find Shipment");
                setShipmentNoWarning("Could not find Shipment");
            } else if (shipments && shipments.items.length > 1) {
                setShipmentNoWarning();
                alert("There are multiple shipments with same Shipment No, Please proceed on Shipment Overview page");
            }
        }
    }, [shipments.items])

    useEffect(() => {
        if (shipment) {
            setShipmentId(shipment.id);
            setIsShipmentDisabled(true);
            setIsShipmentAutoFocus(false);
            searchSuffixes({ query: shipment.destinationId });

            //it is commented because of child component also set it (<PrintThu/>)
            // setThuList(shipment.articleList);
            //it is commented because of child component also set it (<PrintThu/>)
        }
    }, [shipment])

    useEffect(() => {
        if (!isShipmentAutoFocus) {
            serialInput.current.focus();
        } else {
            shipmentNoInput.current.focus();
        }
    }, [isShipmentAutoFocus])

    useEffect(() => {
        if (thuList) {

            var groupedCount = _.uniq(thuList.map(s => s.externalId)).length;
            setGroupedArticleCount(groupedCount);

            if (shipment && shipment.expectedMeasurement.itemQuantity.value == groupedCount) {
                setCurrentQuantityColor('#4caf50');
            }
            else if (shipment && shipment.expectedMeasurement.itemQuantity.value < groupedCount) {
                setCurrentQuantityColor('red');
                toast.error("Expected Quantity Exceeded!");
            } else {
                setCurrentQuantityColor('black');
            }
        }
    }, [thuList])

    useEffect(() => {
        if (weightTimeout) { clearTimeout(weightTimeout) };
        const newTimeOut = setTimeout(() => {
            if (tempWeight) {
                setWeight(tempWeight);
            }
        }, 1000);
        setWeightTimeout(newTimeOut);
    }, [tempWeight])

    useEffect(() => {
        var list = []

        var suffix = suffixes.items[0];
        if (suffix && suffix.articleFields && suffix.articleFields.length > 0) {
            list = suffix.articleFields;
        }

        setArticleFields(list);

    }, [suffixes])

    useEffect(() => {
        checkThuIsReady();
    }, [externalId, supplierCode, deliveryNote, externalCode, quantity, packageType, weight]);

    const onEnter = (event, callback) => event.key === 'Enter' && callback(event);

    const evaluateInput = () => {
        evaluateNewValue(
            inputValue,
            setInputValue,
            setExternalId,
            setSupplierCode,
            setDeliveryNote,
            setExternalCode,
            setQuantity
        );
    }

    const resetShipment = () => {
        setThuList([]);
        setIsShipmentDisabled(false);
        setIsShipmentAutoFocus(true);
        setShipmentId('');
        setShipmentNo('');
        setShipment('');
        setCurrentQuantityColor('black');
        reset(true);
        shipmentNoInput.current.focus();
        setPrintThuList([]);
        setThuBarcodesDetectedAcc([]);
        setIsValidShipmentNoFound(false);
        setShipmentNoWarning();
    }

    const reset = (force) => {
        if (force || !isGLabel) {
            setExternalId('');
            setDeliveryNote('');
            setIsGLabel(false);
        }
        setInputValue('');
        setSupplierCode('');
        setExternalCode('');
        setQuantity('');
        setPackageType('');
        setVolume('');
        setWeight('');
        setTempWeight('');
        setMessage('');
        setThuBarcodesDetectedAcc([]);
        serialInput.current.focus();
        setIsValidShipmentNoFound(false);
        setShipmentNoWarning();
    }

    const unlock = () => {
        setExternalId('');
        setDeliveryNote('');
        setIsGLabel(false);
        setMessage('');
    }

    const checkThuIsReady = () => {

        let isOk = externalId != '' &&
            (articleFields.includes('supplierCode') ? supplierCode != '' : true) &&
            (articleFields.includes('deliveryNote') ? deliveryNote != '' : true) &&
            (articleFields.includes('externalCode') ? externalCode != '' : true) &&
            (articleFields.includes('quantity') ? quantity != '' : true);

        if (articleFields.includes('packageType')) {
            isOk = isOk && packageType != '' && weight != '';
        }

        if (isOk) {
            createThu({ shipmentId, externalId, externalCode, quantity, supplierCode, deliveryNote, packageType, weight, volume }, `${CONSTANTS.FORMLOADER}`);
            reset(false);
        }
    }

    const onPackageTypeChange = (type) => {
        var packageType = packageTypeList.find(s => s.type === type);
        setVolume(packageType.volume);
        setPackageType(type);
    }

    const onSearch = (e) => {
        e.preventDefault();
        var result = getBarcodeResult(shipmentNo, barcodeRules);
        setShipmentNo(result)
        getShipmentsByShipmentNo(result, CONSTANTS.FORMLOADER);
    }

    // set shipment no to results from the scanner
    const [rfs, setRfs] = useState([]);
    const [isValidShipmentNoFound, setIsValidShipmentNoFound] = useState();
    // check if the shipment no from scanner is valid 
    useEffect(() => {
        if (rfs.length && !isValidShipmentNoFound) {
            getShipmentsByShipmentNo(rfs[0].rawValue)
                .then(res => {
                    if (res && res.payload.length === 0) {
                        setShipmentNoWarning(`Could not find shipment ${rfs[0].rawValue}`);
                    } else if (res && res.payload.length === 1) {
                        setIsValidShipmentNoFound(true);
                        setShipment(res.payload[0]);
                        setShipmentNo(res.payload[0].shipmentNo)
                        setShipmentNoWarning();
                    }
                });
        } else if (rfs.length && isValidShipmentNoFound) {
            const newDetecteds = rfs.map(item => item.rawValue);
            setThuBarcodesDetectedAcc(state => state.reduce(
                (acc, item) =>
                    acc.find((newItem) => newItem === item || newItem[0] === item[0])
                        ? [...acc]
                        : [...acc, item],
                newDetecteds
            )) // insert if not exist
        }
    }, [rfs, getShipmentsByShipmentNo]);

    const thuFieldPrefixesCompulsory = ['N', 'V', 'P', 'Q'];
    const thuFieldPrefixesSelectOne = ['S', 'M', 'G'];
    const [thuBarcodesDetectedAcc, setThuBarcodesDetectedAcc] = useState([]);
    const [isTurnedOn, setIsTurnedOn] = useState(false);
    useEffect(() => {
        setIsShipmentAutoFocus(!isTurnedOn)
    }, [isTurnedOn]);

    useEffect(() => {
        if (isTurnedOn) {
            serialInput.current.blur();
            shipmentNoInput.current.blur();
        }
    }, [isTurnedOn]);

    const [isScanning, setIsScanning] = useState(true);

    useEffect(() => {
        // if all fields scanned stop scanner
        const allFieldsCompulsoryDetected = thuFieldPrefixesCompulsory.every(item => thuBarcodesDetectedAcc.map(item => item[0]).includes(item));
        const allFieldsSelectOneDetected = thuFieldPrefixesSelectOne.some(item => thuBarcodesDetectedAcc.map(item => item[0]).includes(item))
        if (allFieldsCompulsoryDetected && allFieldsSelectOneDetected) {
            evaluateInput(inputValue);
        }
    }, [thuBarcodesDetectedAcc]);

    function formatBarcodeValues(barcodeValues) {
        return barcodeValues.map(item => `"${item}"`).join(",");
    }
    // format & place detected barcode values into field
    useEffect(() => {
        const formattedBarcodeValues = formatBarcodeValues(thuBarcodesDetectedAcc); // encode accumulated values
        setInputValue(formattedBarcodeValues);
    }, [thuBarcodesDetectedAcc]);

    const [isGetUserMediaSupported, setIsGetUserMediaSupported] = useState();
    useEffect(() => {
        const p = (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) || navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia ? true : false;
        setIsGetUserMediaSupported(p);
    }, []);

    const renderValueFields = () => {
        return (
            <>
                {renderInputField("Shipment No", shipmentNo, setShipmentNo, shipmentNoInput, onSearch, isShipmentDisabled, isShipmentAutoFocus)}
                <Row>
                    <Col>
                        <div className='row'>
                            <div className="col col-md-6 mx-auto">
                                <div className='form-group'>
                                    <div className={styles.quantity} style={{ color: currentQuantityColor }}>
                                        Scanned Items: {groupedArticleCount} / {shipment ? shipment.expectedMeasurement.itemQuantity.value : '0'}
                                    </div>
                                    {
                                        shipmentNoWarning &&
                                        <div className={styles.warning}>
                                            {shipmentNoWarning}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='row'>
                            <div className="col col-md-6 mx-auto">
                                <div className='form-group' style={{
                                    textAlign: "center"
                                }} >
                                    <Button variation="outlined" onClick={() => resetShipment()}>Next Shipment <FontAwesomeIcon icon='angle-double-right'></FontAwesomeIcon></Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className='row'>
                    <div className="col col-md-6 mx-auto">
                        <div className='form-group'>
                            <label>Input Value</label>
                            <input
                                ref={serialInput}
                                type='text'
                                className='form-control'
                                onKeyUp={e => setInputValue(e.target.value)}
                                onChange={e => setInputValue(e.target.value)}
                                onKeyPress={e => onEnter(e, evaluateInput)}
                                value={inputValue}
                                disabled={!isShipmentDisabled}
                            ></input>
                            <small>Scan Always <b>Serial Number</b> First!</small>
                        </div>
                    </div>
                </div>
                {renderMessage()}
                <Row>
                    <Col>
                        <div className='row'>
                            <div className="col col-md-6 mx-auto">
                                <div className='form-group' style={{
                                    textAlign: "center"
                                }} >
                                    <Button style={{
                                        width: "170px"
                                    }}
                                        variation="secondary" onClick={() => reset(true)}>Reset Scan</Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className={isGLabel ? "background-light" : ""}>
                    {renderSelectedField('(S),(M),(G) Serial Number (*)', externalId)}
                    {articleFields.includes('deliveryNote') ? renderSelectedField('(N) Delivery Note (*)', deliveryNote) : null}
                </Row>
                <Row>
                    {articleFields.includes('supplierCode') ? renderSelectedField('(V) Supplier No (*)', supplierCode) : null}
                    {articleFields.includes('externalCode') ? renderSelectedField('(P) Part Number (*)', externalCode) : null}
                    {articleFields.includes('quantity') ? renderSelectedField('(Q) Quantity (*)', quantity) : null}
                    {articleFields.includes('packageType') ? renderSelectBox('Package Type (*)', packageType, packageTypeList, onPackageTypeChange) : null}
                    {articleFields.includes('packageType') ? renderField('Gross Weight (*)', tempWeight, setTempWeight) : null}
                </Row>
            </>
        )
    }

    const renderInputField = (label, inputValue, setInputValue, itemRef, callback, isDisabled, isAutoFocus) => {
        return (
            <div className='row'>
                <div className="col col-md-6 mx-auto">
                    <div className='form-group'>
                        <label>{label}</label>
                        <input
                            ref={itemRef}
                            type='text'
                            className='form-control'
                            onKeyUp={e => setInputValue(e.target.value)}
                            onChange={e => setInputValue(e.target.value)}
                            onKeyPress={e => onEnter(e, callback)}
                            value={inputValue}
                            autoFocus={isAutoFocus}
                            disabled={isDisabled}
                        ></input>
                    </div>
                </div>
            </div>
        )
    }

    const renderSelectedField = (label, value) => {
        return (
            <Col md={6}>
                <div className='form-group'>
                    <label>{label}</label>
                    <div className="input-group">
                        <input type='text' className='form-control' disabled value={value}></input>
                        <div className="input-group-append">
                            <span className="input-group-text">
                                <FontAwesomeIcon icon={value !== '' ? 'check' : 'clock'} className={value !== '' ? 'color-yes-green' : ''} />
                            </span>
                        </div>
                    </div>
                </div>
            </Col>
        )
    }

    const renderField = (label, value, setValue) => {
        return (
            <Col md={6}>
                <div className='form-group'>
                    <label>{label}</label>
                    <div className="input-group">
                        <input type='number' className='form-control' value={value} onChange={e => setValue(e.target.value)} onKeyUp={e => setValue(e.target.value)}></input>
                        <div className="input-group-append">
                            <span className="input-group-text">
                                <FontAwesomeIcon icon={value !== '' ? 'check' : 'weight-hanging'} className={value !== '' ? 'color-yes-green' : ''} />
                            </span>
                        </div>
                    </div>
                </div>
            </Col>
        )
    }

    const renderSelectBox = (label, value, list, onChange) => {
        return (
            <Col md={6}>
                <div className='form-group'>
                    <label>{label}</label>
                    <SelectField selected={value} onChange={(e) => onChange(e.target.value)} size='small' className='selectbox-labelless'>
                        <option value="">Select</option>
                        {list.map((item) => (
                            <option value={item.type} key={item.type}>
                                {item.type}
                            </option>
                        ))}
                    </SelectField>
                </div>
            </Col>
        )
    }

    const renderMessage = () => {
        if (!message) {
            return;
        }

        return (
            <Row>
                <Col>
                    <div className="alert alert-secondary" role="alert">
                        {message}
                        <Button style={{ float: 'right', marginTop: '-3px' }} onClick={() => unlock()} title="Unlock" size="small" variation="secondary"><FontAwesomeIcon icon={'unlock-alt'} className="mr-2" />Unlock</Button>
                    </div>
                </Col>
            </Row>
        )
    }

    const evaluateNewValue = (
        newValue,
        setInputValue,
        setExternalId,
        setSupplierCode,
        setDeliveryNote,
        setExternalCode,
        setQuantity
    ) => {
        if (!newValue) { return; }

        var barcodeValues = getBarcodeResult(newValue, barcodeRules);

        for (let i = 0; i < barcodeValues.length; i++) {

            const upperCaseValue = barcodeValues[i].toUpperCase();

            const startString = upperCaseValue.slice(0, 1);

            const value = upperCaseValue.slice(1, upperCaseValue.length);

            switch (startString) {
                case 'S':
                case 'M':
                case 'G':
                    if (!isGLabel) {
                        if (externalId !== upperCaseValue) {
                            reset(barcodeValues.length == 1);
                        }
                        setExternalId(upperCaseValue);
                        setIsGLabel(startString === 'G');
                    } else {
                        setMessage('G Label scanned before so you do NOT need to scan Serial Number. If you want to create NEW THU, please click UNLOCK!');
                    }
                    setInputValue('');
                    break;
                case 'N':
                    if (!isGLabel || !deliveryNote) {
                        setDeliveryNote(value);
                    } else {
                        setMessage('G Label scanned before so you do NOT need to scan Delivery Note. If you want to create NEW THU, please click UNLOCK!');
                    }
                    setInputValue('');
                    break;
                case 'V':
                    setSupplierCode(value);
                    setInputValue('');
                    break;
                case 'P':
                    setExternalCode(value);
                    setInputValue('');
                    break;
                case 'Q':
                    setQuantity(value);
                    setInputValue('');
                    break;
                default:
                    return;
            }
        }
    }

    const renderCreateThuDetail = () => {
        return (
            <div className='card card-body'>
                {/*
                */}
                {isTurnedOn && <Scanner isTurnedOn={isTurnedOn} isScanning={isScanning} setRfs={setRfs}></Scanner>}
                {renderValueFields()}
            </div>
        )
    }

    const renderThuList = () => {
        return (
            <ThuList
                shipmentId={shipmentId}
                shipmentNo={shipmentNo}
                thuList={thuList}
                headerText='Added THU List'
                accordionId='collapseThuDetail' />
        )
    }

    const renderPrintThu = () => {
        return (

            <div className='card'>
                <div className='card-header collapsed' href='#collapsePrintThu' data-toggle='collapse' data-parent='#accordion'>
                    <Row>
                        <Col xs={8}>
                            <h5 className='mb-0'>
                                <img src='/image/icon/down.svg' className='color-action-blue' width='20px' alt='' /> Print THU
                         </h5>
                        </Col>
                    </Row>
                </div>

                <div id='collapsePrintThu' className='collapse'>
                    <div className='card card-body'>
                        <LoadingIndicator id='loader-thu-actions' />
                        <PrintThu shipmentId={shipmentId} shipmentNo={shipmentNo} hideHeader={true} hideList={true} />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <PageHeader
                text={`Receiving`}
                actions={[
                    <FontAwesomeIcon
                        icon="qrcode"
                        onClick={e => isGetUserMediaSupported ? setIsTurnedOn(state => !state) : setIsTurnedOn(false)}
                        title={isGetUserMediaSupported ? "Barcode Scanner" : "Barcode Scanner not supported"}
                        style={{ opacity: isGetUserMediaSupported ? 1 : 0.5 }}
                        size={"2x"}
                        key={1}
                    />
                ]}
            />
            {/*
                <Button key={0} variation='secondary' size='small'>
                </Button>
            */}
            <div id="accordion">
                {renderCreateThuDetail()}
                {renderPrintThu()}
                {renderThuList()}
            </div>
            <LoadingIndicator id={CONSTANTS.FORMLOADER} />
        </div>
    )
}

const CONSTANTS = {
    FORMLOADER: 'loader-article-form',
    BARCODERULES: 'barcode.rules',
}

const mapStateToProps = ({ shipmentsOverview, thuDetailReducer, definitions }) => {
    return {
        shipments: shipmentsOverview,
        thuList: thuDetailReducer.thuList,
        packageTypeList: thuDetailReducer.packageTypeList,
        barcodeRules: definitions.settings.items.filter(s => s.key === CONSTANTS.BARCODERULES).flatMap(s => s.value),
        suffixes: definitions.suffixes,
    }
}

const mapActionToProps = {
    clearThuData,
    createThu,
    getSettings,
    setThuList,
    getShipmentsByShipmentNo,
    setPrintThuList,
    searchSuffixes
}

export default connect(mapStateToProps, mapActionToProps)(ThuReceiving);