const bookingActions = Object.freeze({
    BOOKING_GETREQUIREDATTR_SUCCESS: Symbol('BOOKING_GETREQUIREDATTR_SUCCESS'),
    BOOKING_CREATEBOOKING_SUCCESS: Symbol('BOOKING_CREATEBOOKING_SUCCESS'),
    UPDATE_BOOKING: Symbol('UPDATE_BOOKING'),
    BOOKING_FILL_FORM_DATA_SUCCESS: Symbol('BOOKING_FILL_FORM_DATA_SUCCESS'),
    FILL_FORM_DATA_BY_ID: Symbol('FILL_FORM_DATA_BY_ID'),
    BOOKING_GETTIMESLOTS_SUCCESS: Symbol('BOOKING_GETTIMESLOTS_SUCCESS'),
    BOOKING_SELECTTIMESLOT_SUCCESS: Symbol('BOOKING_SELECTTIMESLOT_SUCCESS'),
    BOOKING_GETBOOKINGS_SUCCESS: Symbol('BOOKING_GETBOOKINGS_SUCCESS'),
    BOOKING_SIGNALR_CONNECTION_SUCCESS: Symbol('BOOKING_SIGNALR_CONNECTION_SUCCESS'),
    BOOKING_GETBOOKINGSFORCARRIER_SUCCESS: Symbol('BOOKING_GETBOOKINGSFORCARRIER_SUCCESS'),
    BOOKING_MARK_AS_ARRIVED_SUCCESS: Symbol('BOOKING_MARK_AS_ARRIVED_SUCCESS'),
    BOOKING_CLEAR_STATE: Symbol('BOOKING_CLEAR_STATE'),
    BOOKING_CANCEL_BOOKING: Symbol('BOOKING_CANCEL_BOOKING'),
    UNIT_TYPE_RECEIVED: Symbol('UNIT_TYPE_RECEIVED'),
    TIME_SLOT_NOT_AVAILABLE: Symbol('TIME_SLOT_NOT_AVAILABLE'),
    BOOKING_MARKED_AS_UNARRIVED: Symbol('BOOKING_MARKED_AS_UNARRIVED'),
    TASK_DETAIL_RECEIVED: Symbol('TASK_DETAIL_RECEIVED'),
    AVAILABLE_DATES_RECEIVED: Symbol('AVAILABLE_DATES_RECEIVED'),
    TIME_SLOTS_CLEARED: Symbol('TIME_SLOTS_CLEARED'),
    BUSINESS_AREA_RECEIVED: Symbol('BUSINESS_AREA_RECEIVED'),
    CLIENT_BUSINESS_AREAS_RECEIVED: Symbol('CLIENT_BUSINESS_AREAS_RECEIVED'),
    UNIT_TYPES_FOR_BOOKING: Symbol('UNIT_TYPES_FOR_BOOKING'),
    BOOKING_RETRIEVING: Symbol('BOOKING_RETRIEVING'),
    CLIENTS_RETRIEVED: Symbol('CLIENTS_RETRIEVED'),
    SERVICES_RETRIEVED: Symbol('SERVICES_RETRIEVED'),
    FORM_DATA_CLEARED: Symbol('FORM_DATA_CLEARED'),
    BUSINESS_AREA_DETAILS_RECEIVED: Symbol('BUSINESS_AREA_DETAILS_RECEIVED'),
    ADMIN_NOTE_UPDATED: Symbol('ADMIN_NOTE_UPDATED')
});

export default bookingActions;
