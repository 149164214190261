export default Object.freeze({
    SEARCH_ASSOCIATES_REQUESTING: 'astrid/definitions/SEARCH_ASSOCIATES_REQUESTING',
    SEARCH_ASSOCIATES_SUCCESS: 'astrid/definitions/SEARCH_ASSOCIATES_SUCCESS',
    SEARCH_ASSOCIATES_FAILURE: 'astrid/definitions/SEARCH_ASSOCIATES_FAILURE',

    CREATE_CONNECTION_POINT_REQUESTING: 'astrid/definitions/CREATE_CONNECTION_POINT_REQUESTING',
    CREATE_CONNECTION_POINT_SUCCESS: 'astrid/definitions/CREATE_CONNECTION_POINT_SUCCESS',
    CREATE_CONNECTION_POINT_FAILURE: 'astrid/definitions/CREATE_CONNECTION_POINT_FAILURE',

    READ_CONNECTION_POINT_REQUESTING: 'astrid/definitions/READ_CONNECTION_POINT_REQUESTING',
    READ_CONNECTION_POINT_SUCCESS: 'astrid/definitions/READ_CONNECTION_POINT_SUCCESS',
    READ_CONNECTION_POINT_FAILURE: 'astrid/definitions/READ_CONNECTION_POINT_FAILURE',

    READ_CONNECTION_POINTS_REQUESTING: 'astrid/definitions/READ_CONNECTION_POINTS_REQUESTING',
    READ_CONNECTION_POINTS_SUCCESS: 'astrid/definitions/READ_CONNECTION_POINTS_SUCCESS',
    READ_CONNECTION_POINTS_FAILURE: 'astrid/definitions/READ_CONNECTION_POINTS_FAILURE',

    UPDATE_CONNECTION_POINT_REQUESTING: 'astrid/definitions/UPDATE_CONNECTION_POINT_REQUESTING',
    UPDATE_CONNECTION_POINT_SUCCESS: 'astrid/definitions/UPDATE_CONNECTION_POINT_SUCCESS',
    UPDATE_CONNECTION_POINT_FAILURE: 'astrid/definitions/UPDATE_CONNECTION_POINT_FAILURE',

    DELETE_CONNECTION_POINT_REQUESTING: 'astrid/definitions/DELETE_CONNECTION_POINT_REQUESTING',
    DELETE_CONNECTION_POINT_SUCCESS: 'astrid/definitions/DELETE_CONNECTION_POINT_SUCCESS',
    DELETE_CONNECTION_POINT_FAILURE: 'astrid/definitions/DELETE_CONNECTION_POINT_FAILURE',

    DELETE_CONNECTION_POINTS_REQUESTING: 'astrid/definitions/DELETE_CONNECTION_POINTS_REQUESTING',
    DELETE_CONNECTION_POINTS_SUCCESS: 'astrid/definitions/DELETE_CONNECTION_POINTS_SUCCESS',
    DELETE_CONNECTION_POINTS_FAILURE: 'astrid/definitions/DELETE_CONNECTION_POINTS_FAILURE',

    SEARCH_CONNECTION_POINTS_REQUESTING: 'astrid/definitions/SEARCH_CONNECTION_POINTS_REQUESTING',
    SEARCH_CONNECTION_POINTS_SUCCESS: 'astrid/definitions/SEARCH_CONNECTION_POINTS_SUCCESS',
    SEARCH_CONNECTION_POINTS_FAILURE: 'astrid/definitions/SEARCH_CONNECTION_POINTS_FAILURE',
    FETCH_SUFFIXES_OF_CONNECTION_POINT_SUCCESS: 'astrid/definitions/FETCH_SUFFIXES_OF_CONNECTION_POINT_SUCCESS',

    CREATE_SUFFIX_REQUESTING: 'astrid/definitions/CREATE_SUFFIX_REQUESTING',
    CREATE_SUFFIX_SUCCESS: 'astrid/definitions/CREATE_SUFFIX_SUCCESS',
    CREATE_SUFFIX_FAILURE: 'astrid/definitions/CREATE_SUFFIX_FAILURE',

    FETCH_SUFFIXES_REQUESTING: 'astrid/definitions/FETCH_SUFFIXES_REQUESTING',
    FETCH_SUFFIXES_SUCCESS: 'astrid/definitions/FETCH_SUFFIXES_SUCCESS',
    FETCH_SUFFIXES_FAILURE: 'astrid/definitions/FETCH_SUFFIXES_FAILURE',

    FETCH_CONECTIONPOINTS_REQUESTING: 'astrid/definitions/FETCH_CONECTIONPOINTS_REQUESTING',
    FETCH_CONECTIONPOINTS_SUCCESS: 'astrid/definitions/FETCH_CONECTIONPOINTS_SUCCESS',
    FETCH_CONECTIONPOINTS_FAILURE: 'astrid/definitions/FETCH_CONECTIONPOINTS_FAILURE',

    UPDATE_SUFFIX_REQUESTING: 'astrid/definitions/UPDATE_SUFFIX_REQUESTING',
    UPDATE_SUFFIX_SUCCESS: 'astrid/definitions/UPDATE_SUFFIX_SUCCESS',
    UPDATE_SUFFIX_FAILURE: 'astrid/definitions/UPDATE_SUFFIX_FAILURE',

    DELETE_SUFFIX_REQUESTING: 'astrid/definitions/DELETE_SUFFIX_REQUESTING',
    DELETE_SUFFIX_SUCCESS: 'astrid/definitions/DELETE_SUFFIX_SUCCESS',
    DELETE_SUFFIX_FAILURE: 'astrid/definitions/DELETE_SUFFIX_FAILURE',

    SEARCH_SUFFIXES_REQUESTING: 'astrid/definitions/SEARCH_SUFFIXES_REQUESTING',
    SEARCH_SUFFIXES_SUCCESS: 'astrid/definitions/SEARCH_SUFFIXES_SUCCESS',
    SEARCH_SUFFIXES_FAILURE: 'astrid/definitions/SEARCH_SUFFIXES_FAILURE',

    FETCH_TYPES_REQUESTING: 'astrid/definitions/FETCH_TYPES_REQUESTING',
    FETCH_TYPES_SUCCESS: 'astrid/definitions/FETCH_TYPES_SUCCESS',
    FETCH_TYPES_FAILURE: 'astrid/definitions/FETCH_TYPES_FAILURE',

    SEARCH_CONNECTIONPOINTS_REQUESTING: 'astrid/definitions/SEARCH_CONNECTIONPOINTS_REQUESTING',
    SEARCH_CONNECTIONPOINTS_SUCCESS: 'astrid/definitions/SEARCH_CONNECTIONPOINTS_SUCCESS',
    SEARCH_CONNECTIONPOINTS_FAILURE: 'astrid/definitions/SEARCH_CONNECTIONPOINTS_FAILURE',

    FILTER_CURRENCIES_REQUESTING: 'astrid/definitions/FILTER_CURRENCIES_REQUESTING',
    FILTER_CURRENCIES_SUCCESS: 'astrid/definitions/FILTER_CURRENCIES_SUCCESS',
    FILTER_CURRENCIES_FAILURE: 'astrid/definitions/FILTER_CURRENCIES_FAILURE',

    FILTER_COUNTRIES_REQUESTING: 'astrid/definitions/FILTER_COUNTRIES_REQUESTING',
    FILTER_COUNTRIES_SUCCESS: 'astrid/definitions/FILTER_COUNTRIES_SUCCESS',
    FILTER_COUNTRIES_FAILURE: 'astrid/definitions/FILTER_COUNTRIES_FAILURE',

    FILTER_SETTINGS_REQUESTING: 'astrid/definitions/FILTER_SETTINGS_REQUESTING',
    FILTER_SETTINGS_SUCCESS: 'astrid/definitions/FILTER_SETTINGS_SUCCESS',
    FILTER_SETTINGS_FAILURE: 'astrid/definitions/FILTER_SETTINGS_FAILURE',
    
    CREATE_COMPANY_REQUESTING: 'astrid/companies/CREATE_COMPANY_REQUESTING',
    CREATE_COMPANY_SUCCESS: 'astrid/companies/CREATE_COMPANY_SUCCESS',
    CREATE_COMPANY_FAILURE: 'astrid/companies/CREATE_COMPANY_FAILURE',
    READ_COMPANY_REQUESTING: 'astrid/companies/READ_COMPANY_REQUESTING',
    READ_COMPANY_SUCCESS: 'astrid/companies/READ_COMPANY_SUCCESS',
    READ_COMPANY_FAILURE: 'astrid/companies/READ_COMPANY_FAILURE',
    READ_COMPANIES_REQUESTING: 'astrid/companies/READ_COMPANIES_REQUESTING',
    READ_COMPANIES_SUCCESS: 'astrid/companies/READ_COMPANIES_SUCCESS',
    READ_COMPANIES_FAILURE: 'astrid/companies/READ_COMPANIES_FAILURE',
    UPDATE_COMPANY_REQUESTING: 'astrid/companies/UPDATE_COMPANY_REQUESTING',
    UPDATE_COMPANY_SUCCESS: 'astrid/companies/UPDATE_COMPANY_SUCCESS',
    UPDATE_COMPANY_FAILURE: 'astrid/companies/UPDATE_COMPANY_FAILURE',
    DELETE_COMPANY_REQUESTING: 'astrid/companies/DELETE_COMPANY_REQUESTING',
    DELETE_COMPANY_SUCCESS: 'astrid/companies/DELETE_COMPANY_SUCCESS',
    DELETE_COMPANY_FAILURE: 'astrid/companies/DELETE_COMPANY_FAILURE',
    DELETE_COMPANIES_REQUESTING: 'astrid/companies/DELETE_COMPANIES_REQUESTING',
    DELETE_COMPANIES_SUCCESS: 'astrid/companies/DELETE_COMPANIES_SUCCESS',
    DELETE_COMPANIES_FAILURE: 'astrid/companies/DELETE_COMPANIES_FAILURE',
    SEARCH_COMPANIES_REQUESTING: 'astrid/companies/SEARCH_COMPANIES_REQUESTING',
    SEARCH_COMPANIES_SUCCESS: 'astrid/companies/SEARCH_COMPANIES_SUCCESS',
    SEARCH_COMPANIES_FAILURE: 'astrid/companies/SEARCH_COMPANIES_FAILURE',
    SET_FILTER: 'astrid/companies/SET_FILTER',
    SET_FILTERS: 'astrid/companies/SET_FILTERS',
    RESET_ALL_FILTERS: 'astrid/companies/RESET_ALL_FILTERS',
});