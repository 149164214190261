import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Label } from '@dfds-ui/react-components';

const ThuFilter = ({ value, show, onChange, partNumberValue, showPartNumber, onChangePartNumber }) => {

    const [thuInputValueElement, setThuInputValueElement] = useState('');
    const [partNumberInputValueElement, setPartNumberInputValueElement] = useState('');

    useEffect(() => {

        if (thuInputValueElement && (!showPartNumber)) {
            partNumberInputValueElement.focus();
        }
        else if (thuInputValueElement) {
            thuInputValueElement.focus();
        }

    }, [partNumberInputValueElement, showPartNumber, thuInputValueElement])

    if (!show) {
        return null;
    }

    return (
        <>

            <label className="col-sm-3 d-none d-sm-block col-form-label">Scan THU</label>
            <Col>
                <input id="thuInput" ref={input => setThuInputValueElement(input)} autoFocus disabled={!showPartNumber} type="text" className="form-control" onChange={onChange} value={value} placeholder="THU Number" />
                <Label hidden={showPartNumber} size="small" className="">duplicate record found please scan part number of current thu</Label>
            </Col>
            <label className="col-sm-3 d-none d-sm-block col-form-label">Scan Part Number</label>
            <Col>
                <input id="partInput" autoFocus ref={input => setPartNumberInputValueElement(input)} type="text" disabled={showPartNumber} className="form-control" value={partNumberValue} onChange={onChangePartNumber} placeholder="Part Number" />
            </Col>


        </>
    )
}

export default ThuFilter;