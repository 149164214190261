import Operation from '../Operation';
import HomePage from '../../component/HomePage/HomePage';
import Logout from '../../component/Logout/Logout';
import NoAuth from '../../component/NoAuth/NoAuth';
// import ShipmentSearch from '../ShipmentSearch';
import Shipments from '../Shipments';
import ShipmentDetail from '../ShipmentDetail';
import ShipmentOutboundOperation from '../ShipmentOutboundOperation';
import OperationSplitContainer from '../OperationSplitContainer';
import ConnectionPoints from '../ConnectionPoints';
import ConnectionPoint from '../ConnectionPoint';
import Suffixes from '../Suffixes';
import NotificationDetail from '../NotificationDetail';
import UserList from '../UserList';
import AddOrEditUser from '../AddOrEditUser';
import ActionUrl from '../ActionUrl';
import CarrierBookings from '../CarrierBookings';
import CompanyList from '../CompanyList';
import AddOrEditCompany from '../AddOrEditCompany';
import ChangePassword from '../ChangePassword';
import CalendarRuleList from '../CalendarRuleList';
import AddOrEditCalendarRule from '../AddOrEditCalendarRule';
import RoleList from '../RoleList';
import Booking from '../Booking';
import Bookings from '../Bookings';
import SelectTimeSlot from '../SelectTimeSlot';
import CreateBookingContainer from '../CreateBookingContainer';
import ConfirmBooking from '../ConfirmBooking';
import UpdateBookingContainer from '../UpdateBookingContainer';
import EditRole from '../EditRole';
import CreateRole from '../CreateRole';
import CreateThu from '../CreateThu';
import ThuReceiving from '../ThuReceiving';
import PrintThu from '../PrintThu';
import HoldTerminalItem from '../HoldTerminalItem';
import AddOrEditSuffix from '../AddOrEditSuffix';
import OpenAdditionalGate from '../OpenAdditionalGate';
import ReservedSlotRuleList from '../ReservedSlotRuleList';
import AddOrEditReservedSlotRule from '../AddOrEditReservedSlotRule';
import AddOrEditSlotBookingConfiguration from '../AddOrEditSlotBookingConfiguration';
import Tasks from '../Tasks';
import CreateOrUpdateTask from '../CreateOrUpdateTask';
import ShipmentInboundOperation from '../ShipmentInboundOperation';
import Transport from '../Transport';
import History from '../History';
import EditHistoryJsonDefinition from '../EditHistoryJsonDefinition';
import ThuRemeasure from '../ThuRemeasure';
import Thu from '../Thu';
import Voyages from '../Voyages';
import Voyage from '../Voyage';
import ContainerBookings from '../ContainerBookings';
import ContainerBooking from '../ContainerBooking';
import ServiceList from '../ServiceList';
import AddOrEditService from '../AddOrEditService';
import Companies from '../Companies';
import Company from '../Company';
import AddOrEditContract from '../AddOrEditContract';
import BusinessAreaList from '../BusinessAreaList';
import AddOrEditBusinessArea from '../AddOrEditBusinessArea';
import SlotBookingConfiguration from '../SlotBookingConfiguration';
import Articles from '../Articles';
import Messages from '../Messages';
import BackOffice from '../BackOffice';

export const routes = [
  {
    path: '/',
    name: '',
    component: HomePage
  },
  {
    path: '/operation/:operationId',
    name: 'Operation',
    component: Operation
  },
  {
    path: '/operation/:operationId/split',
    name: 'OperationSplitContainer',
    component: OperationSplitContainer
  },
  {
    path: '/shipments',
    name: 'Shipments',
    component: Shipments
  },
  {
    path: '/shipment/:shipmentId',
    name: 'ShipmentDetail',
    component: ShipmentDetail
  },
  {
    path: '/action-url/:guid',
    name: 'ActionUrl',
    component: ActionUrl
  },
  {
    path: '/booking/calendar-rule',
    name: 'CalendarRuleList',
    component: CalendarRuleList
  },
  {
    path: '/booking/calendar-rule/open-additional-gate',
    name: 'OpenAdditionalGate',
    component: OpenAdditionalGate
  },
  {
    path: '/booking/calendar-rule/:ruleid',
    name: 'AddOrEditCalendarRule',
    component: AddOrEditCalendarRule
  },
  {
    path: '/booking/reserved-slot-rule',
    name: 'ReservedSlotRuleList',
    component: ReservedSlotRuleList
  },
  {
    path: '/booking/reserved-slot-rule/:ruleid',
    name: 'AddOrEditReservedSlotRule',
    component: AddOrEditReservedSlotRule
  },
  {
    path: '/booking/configuration',
    name: 'SlotBookingConfiguration',
    component: SlotBookingConfiguration
  },
  {
    path: '/booking/configuration/:id',
    name: 'AddOrEditSlotBookingConfiguration',
    component: AddOrEditSlotBookingConfiguration
  },
  {
    path: '/booking',
    name: 'Booking',
    component: Booking
  },
  {
    path: '/booking/list',
    name: 'Bookings',
    component: Bookings
  },
  {
    path: '/booking/carrier-booking-list',
    name: 'CarrierBookings',
    component: CarrierBookings
  },
  {
    path: '/booking/new',
    name: 'CreateBookingContainer',
    component: CreateBookingContainer
  },
  {
    path: '/booking/new/select-time-slot',
    name: 'SelectTimeSlot',
    component: SelectTimeSlot
  },
  {
    path: '/booking/:bookingid/confirm',
    name: 'ConfirmBooking',
    component: ConfirmBooking
  },
  {
    path: '/booking/:bookingid',
    name: 'UpdateBookingContainer',
    component: UpdateBookingContainer
  },
  {
    path: '/connection-points',
    name: 'ConnectionPoints',
    component: ConnectionPoints
  },
  {
    path: '/connection-points/:connectionPointId',
    name: 'ConnectionPoint',
    component: ConnectionPoint
  },
  {
    path: '/suffixes',
    name: 'Suffixes',
    component: Suffixes
  },
  {
    path: '/suffixes/:suffix',
    name: 'AddOrEditSuffix',
    component: AddOrEditSuffix
  },
  {
    path: '/suffixes/email/:notificationId',
    name: 'NotificationDetail',
    component: NotificationDetail
  },
  {
    path: '/company',
    name: 'CompanyList',
    component: CompanyList
  },
  {
    path: '/company/:companycode',
    name: 'AddOrEditCompany',
    component: AddOrEditCompany
  },
  {
    path: '/companies/:companycode/contract/:contractId',
    name: 'AddOrEditContract',
    component: AddOrEditContract
  },
  {
    path: '/user',
    name: 'UserList',
    component: UserList
  },
  {
    path: '/user/:username/change-password',
    name: 'ChangePassword',
    component: ChangePassword
  },
  {
    path: '/user/:username',
    name: 'AddOrEditUser',
    component: AddOrEditUser
  },
  {
    path: '/noAuthorization',
    name: 'NoAuth',
    component: NoAuth
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/role',
    name: 'RoleList',
    component: RoleList
  },
  {
    path: '/role/new',
    name: 'CreateRole',
    component: CreateRole
  },
  {
    path: '/role/:roleName',
    name: 'EditRole',
    component: EditRole
  },
  {
    path: '/shipmentoutboundoperation/:type/:operationId',
    name: 'ShipmentOutboundOperation',
    component: ShipmentOutboundOperation
  },
  {
    path: '/thu/new/:shipmentId/:shipmentNo',
    name: 'CreateThu',
    component: CreateThu
  },
  {
    path: '/thu/receiving',
    name: 'ThuReceiving',
    component: ThuReceiving
  },
  {
    path: '/thu/print/:shipmentId/:shipmentNo',
    name: 'PrintThu',
    component: PrintThu
  },
  {
    path: '/thu/hold/:shipmentId',
    name: 'HoldTerminalItem',
    component: HoldTerminalItem
  },
  {
    path: '/task',
    name: 'Tasks',
    component: Tasks
  },
  {
    path: '/task/:taskid',
    name: 'CreateOrUpdateTask',
    component: CreateOrUpdateTask
  },
  {
    path: '/shipmentinboundoperation/:operationId',
    name: 'ShipmentInboundOperation',
    component: ShipmentInboundOperation
  },
  {
    path: '/transport/:operationId',
    name: 'Transport',
    component: Transport
  },
  {
    path: '/history',
    name: 'History',
    component: History
  },
  {
    path: '/edit-history-json-definition',
    name: 'EditHistoryJsonDefinition',
    component: EditHistoryJsonDefinition
  },
  {
    path: '/history/:objecttype/:entityid',
    name: 'History',
    component: History
  },
  {
    path: '/thu/remeasure/:id',
    name: 'Thu Remeasure',
    component: ThuRemeasure
  },
  {
    path: '/thu',
    name: 'Thu',
    component: Thu
  },
  {
    path: '/voyages',
    name: 'Voyages',
    component: Voyages
  },
  {
    path: '/voyages/:mode', // one of: create, update-many, voyage (for read and update) with ?ids=... will be joined string of the voyage ids array with comma
    name: 'Voyage',
    component: Voyage
  },
  {
    path: '/container-bookings',
    name: 'ContainerBookings',
    component: ContainerBookings
  },
  {
    path: '/container-bookings/:mode', // one of: create, update-many, container booking (for read and update) with ?ids=... will be joined string of the container booking ids array with comma
    name: 'ContainerBookings',
    component: ContainerBooking
  },
  {
    path: '/service',
    name: 'ServiceList',
    component: ServiceList
  },
  {
    path: '/service/:serviceId',
    name: 'AddOrEditService',
    component: AddOrEditService
  },
  {
    path: '/companies',
    name: 'Companies',
    component: Companies
  },
  {
    path: '/companies/:companyId',
    name: 'Company',
    component: Company
  },
  {
    path: '/businessArea',
    name: 'BusinessAreaList',
    component: BusinessAreaList
  },
  {
    path: '/businessArea/:businessAreaId',
    name: 'AddOrEditBusinessArea',
    component: AddOrEditBusinessArea
  },
  {
    path: '/articles',
    name: 'Articles',
    component: Articles
  },
  {
    path: '/messages',
    name: 'Messages',
    component: Messages
  },
  {
    path: '/backoffice',
    name: 'BackOffice',
    component: BackOffice
  }
];
