import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Form, Col, Card } from 'react-bootstrap';
import { Button } from '@dfds-ui/react-components';
import XmsTable from '../XmsTable/XmsTable';
import { toast } from 'react-toastify';

const ReduxFormControl = ({ input, meta: { touched, error, warning }, ...props }) => {
    return <><Form.Control {...props} {...input} />
        {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span className="text-warning">{warning}</span>))}
    </>
};
const checkName = value => value && value === 'new' ? `Code cannot be "new".` : undefined;
const required = value => value ? undefined : 'Required';

//REFACTOR: Burada service table aslında redux form field'ın dışardan aldığı bir component olarak yazılmalıydı.

let ContractForm = (props) => {
    const { pristine, submitting, currencies, services, contract, serviceValue, change } = props;
    const isNewOrDraft = !contract || contract.isDraft;
    const [contractServices, setContractServices] = useState(contract ? contract.services : []);

    useEffect(() => {
        if (contract) {
            setContractServices(contract.services);
        }
    }, [contract])
    const addServiceClickedHandler = (e) => {
        e.preventDefault();
        if (!serviceValue.serviceId) {
            toast.error('Service is required.');
            return;
        }

        if (!serviceValue.price) {
            toast.error('Price is required.');
            return;
        }
        if (!serviceValue.clientRef) {
            toast.error('Client reference is required.');
            return;
        }
        if (!serviceValue.rateSheet) {
            toast.error('Rate sheet reference is required.');
            return;
        }

        setContractServices((prevState, props) => {
            const contractServicesClone = [...prevState];
            contractServicesClone.push(serviceValue);
            return contractServicesClone;
        });

        change('service', '');
        change('clientReference', '');
        change('rateSheetReference', '');
        change('price', '');
    }

    const deleteServiceClickedHandler = (service, e) => {
        e.preventDefault();

        setContractServices((prevState, props) => {
            const contractServicesClone = [...prevState];
            const indexToRemove = contractServicesClone.findIndex(x => x.id === service.id);
            contractServicesClone.splice(indexToRemove, 1);
            return contractServicesClone;
        });
    }

    const tableConfigurations = {
        noRowText: 'There are not any services to show.',
        hasFilter: false,
        entities: contractServices,
        columns: [
            { alias: 'Name', name: 'serviceName', type: 'string' },
            { alias: 'Rate sheet reference', name: 'rateSheet', type: 'string' },
            { alias: 'Client reference', name: 'clientRef', type: 'string' },
            { alias: 'Price', name: 'price', type: 'number' },
        ]

    }
    if (isNewOrDraft) {
        tableConfigurations.rowActions = [
            { title: 'Delete service', clickEvent: deleteServiceClickedHandler, icon: 'trash' }
        ];
    }

    const handleBeforeSubmit = (form) => {

        console.log('FORM:', form);
        const formWithServices = {
            ...form,
            services: contractServices
        }
        props.onSubmit(formWithServices);
    }
    const endContractButtonHandler = (id) => {
        alert(id);
    }
    return (<>
        <Form onSubmit={props.handleSubmit(handleBeforeSubmit.bind(this))} autoComplete="off">
            <fieldset disabled={!isNewOrDraft}>
                <Form.Row>
                    <Form.Group as={Col} md="4">
                        <Form.Label>Contact Person</Form.Label>
                        <Field
                            name="clientContactPerson"
                            component={ReduxFormControl}
                            type="text"
                            placeholder="Contact Person"
                            required
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="4">
                        <Form.Label>Contract Reference</Form.Label>
                        <Field
                            name="contractReference"
                            component={ReduxFormControl}
                            type="text"
                            placeholder="Contract Reference"
                            validate={[required, checkName]}
                            required
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="4">
                        <Form.Label>Currency</Form.Label>
                        <Field as="select" name="currency" component={ReduxFormControl} required>
                            <option>Select currency</option>
                            {currencies && currencies.map((currency) => (
                                <option value={currency.code} key={currency.code}>
                                    {currency.code + ' - ' + currency.name}
                                </option>
                            ))}
                        </Field>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="5">
                        <br />
                        <Form.Label>Services</Form.Label>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="12">
                        <XmsTable config={tableConfigurations} />
                    </Form.Group>
                    {isNewOrDraft && <>
                        <Form.Group as={Col} md="5">
                            <Field as="select" name="service" placeholder='Select service' component={ReduxFormControl}>
                                <option value=''>Select service</option>
                                {services && services.map((service) => (
                                    <option value={JSON.stringify(service)} key={service.id}>
                                        {service.name}
                                    </option>
                                ))}
                            </Field>
                        </Form.Group>
                        <Form.Group as={Col} md="2">
                            <Field
                                name="price"
                                component={ReduxFormControl}
                                type="number"
                                placeholder="Price"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="2">
                            <Field
                                name="clientReference"
                                component={ReduxFormControl}
                                type="text"
                                placeholder="Client Reference"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="2">
                            <Field
                                name="rateSheetReference"
                                component={ReduxFormControl}
                                type="text"
                                placeholder="Rate sheet reference"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="1">
                            <Button size='medium' style={{ height: '38px', width: '100%' }} onClick={addServiceClickedHandler}>Add</Button>
                        </Form.Group>
                    </>}
                </Form.Row>

                {isNewOrDraft && <Form.Row >
                    <Form.Group as={Col} md="6">
                        <Form.Label >Save As Draft</Form.Label>
                        <Field name='isDraft' component='input' type='checkbox' />
                    </Form.Group>
                </Form.Row>
                }


                <Button type='submit' hidden={!isNewOrDraft} disabled={submitting} className='float-right'>Save</Button>
                <Button type='button' hidden={isNewOrDraft} className='float-right' onClick={() => props.onEndContract(contract.id)}  >End Contract</Button>

            </fieldset>
        </Form >

    </>
    );
};

ContractForm = reduxForm({
    form: 'insertOrUpdateContractForm', // a unique identifier for this form
})(ContractForm);
const selector = formValueSelector('insertOrUpdateContractForm')
ContractForm = connect(
    state => {
        const serviceObjectStr = selector(state, 'service');
        let serviceObject = {};
        if (serviceObjectStr) {
            serviceObject = JSON.parse(serviceObjectStr);
        }

        return {
            serviceValue: {
                serviceId: serviceObject.id,
                serviceName: serviceObject.name,
                clientRef: selector(state, 'clientReference'),
                rateSheet: selector(state, 'rateSheetReference'),
                price: selector(state, 'price'),
                operationType: serviceObject.operationType
            },
            initialValues: state.contract.contract // pull initial values from company reducer
        }
    },
)(ContractForm);

export default ContractForm;
