import types from "../type/reservedSlot";

const initialState = {
    rule: null,
    rules: null,
    refreshRules: false,
    isSaveRuleSuccess: null,
    clientBusinessAreas: null,
    unitTypes: null,
    clients: null,
    services: null,
    businessAreaDetails: null,
    ruleFilter: {},
    reservationWarnings: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.RULES_RETRIEVED:
            return {
                ...state,
                refreshRules: false,
                rules: action.payload
            }
        case types.RULE_RETRIEVED:
            return {
                ...state,
                rule: action.payload
            }
        case types.RULE_FILTER_SET:
            return {
                ...state,
                ruleFilter: action.payload
            }
        case types.RULE_SAVED:
            return {
                ...state,
                rule: null,
                refreshRules: true,
                isSaveRuleSuccess: true
            }
        case types.RULE_DELETED:
            return {
                ...state,
                rule: null,
                refreshRules: true
            }
        case types.FINALIZE_ADD_OR_UPDATE: {
            return {
                ...state,
                isSaveRuleSuccess: null
            }
        }
        case types.CLIENT_BUSINESS_AREAS_RECEIVED: {
            return {
                ...state,
                clientBusinessAreas: action.payload
            }
        }
        case types.UNIT_TYPES_RETRIEVED: {
            return {
                ...state,
                unitTypes: action.payload
            }
        }
        case types.CLIENTS_RETRIEVED: {
            return {
                ...state,
                clients: action.payload
            }
        }
        case types.SERVICES_RETRIEVED: {
            return {
                ...state,
                services: action.payload
            }
        }
        case types.BUSINESS_AREA_DETAILS_RECEIVED: {
            return {
                ...state,
                businessAreaDetails: action.payload
            }
        }
        case types.WARNINGS_RECEIVED: {
            return {
                ...state,
                reservationWarnings: action.payload
            }
        }
        default:
            return state;
    }
}