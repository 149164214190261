const actionTypes = Object.freeze({
    CLEAR_SHIPMENT_DETAIL_DATA: 'astrid/shipmentDetail/CLEAR_SHIPMENT_DETAIL_DATA',
    SHIPMENT_RECEIVED: 'astrid/shipmentDetail/SHIPMENT_RECEIVED',
    RELATEDSHIPMENTS_RECEIVED: 'astrid/shipmentDetail/RELATEDSHIPMENTS_RECEIVED',


    FILTER_SHIPMENT_REQUESTING: 'astrid/shipmentDetail/FILTER_SHIPMENT_REQUESTING',
    ADD_SHIPMENT_INFO: 'astrid/shipmentDetail/ADD_SHIPMENT_INFO',
    FILTER_SHIPMENT_FAILURE: 'astrid/shipmentDetail/FILTER_SHIPMENT_FAILURE',
    SET_SHIPMENT_DOCS_MISSING_STATE: 'astrid/shipmentDetail/SET_SHIPMENT_DOCS_MISSING_STATE',
    HAZARDOUS_UPDATED: 'astrid/shipmentDetail/HAZARDOUS_UPDATED',
    HAZARDOUS_DELETED: 'astrid/shipmentDetail/HAZARDOUS_DELETED',
    TOGGLE_EDITING_GRANTED: 'astrid/shipmentDetail/TOGGLE_EDITING_GRANTED',
    TOGGLE_EDITING_GRANT: 'astrid/shipmentDetail/TOGGLE_EDITING_GRANT',
    TOGGLE_EDITING_GRANT_REQUESTING: 'astrid/shipmentDetail/TOGGLE_EDITING_GRANT_REQUESTING',
    TOGGLE_EDITING_GRANT_SUCCESS: 'astrid/shipmentDetail/TOGGLE_EDITING_GRANT_SUCCESS',
    TOGGLE_EDITING_GRANT_FAILURE: 'astrid/shipmentDetail/TOGGLE_EDITING_GRANT_FAILURE',
    CHANGE_FIELD_VALUE: 'astrid/shipmentDetail/CHANGE_FIELD_VALUE',
    CHANGE_EXPECTED_VOLUME_FIELD_VALUE: 'astrid/shipmentDetail/CHANGE_EXPECTED_VOLUME_FIELD_VALUE',
    CHANGE_EXPECTED_ITEM_QUANTITY_FIELD_VALUE: 'astrid/shipmentDetail/CHANGE_EXPECTED_ITEM_QUANTITY_FIELD_VALUE',
    CHANGE_EXPECTED_GROSS_WEIGHT_FIELD_VALUE: 'astrid/shipmentDetail/CHANGE_EXPECTED_GROSS_WEIGHT_FIELD_VALUE',
    UPDATE_SHIPMENT: 'astrid/shipmentDetail/UPDATE_SHIPMENT',
    UPDATE_SHIPMENT_REQUESTING: 'astrid/shipmentDetail/UPDATE_SHIPMENT_REQUESTING',
    UPDATE_SHIPMENT_SUCCESS: 'astrid/shipmentDetail/UPDATE_SHIPMENT_SUCCESS',
    UPDATE_SHIPMENT_FAILURE: 'astrid/shipmentDetail/UPDATE_SHIPMENT_FAILURE',
    OPERATION_RECEIVED: 'astrid/shipmentDetail/OPERATION_RECEIVED',
    OPERATION_REMOVED: 'astrid/shipmentDetail/OPERATION_REMOVED',
    SET_SHIPMENT_DOCS_MISSING_HOLD_STATE: 'astrid/shipmentDetail/SET_SHIPMENT_DOCS_MISSING_HOLD_STATE',
    DELETE_THU: 'astrid/shipmentDetail/DELETE_THU',
    DELETE_SHIPMENT_REQUESTING: 'astrid/shipmentDetail/DELETE_SHIPMENT_REQUESTING',
    DELETE_SHIPMENT_SUCCESS: 'astrid/shipmentDetail/DELETE_SHIPMENT_SUCCESS',
    DELETE_SHIPMENT_FAILURE: 'astrid/shipmentDetail/DELETE_SHIPMENT_FAILURE',

});

export default actionTypes;