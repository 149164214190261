import React from 'react';
import history from '../../core/history';
import { ButtonStack, Button } from '@dfds-ui/react-components'
import { RightLink } from '@dfds-ui/icons'
import { Col, Row } from 'react-bootstrap'
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import PolicyGroupAccessHoc from "../../RoutedComponents/Routes/PolicyGroupAccessHoc";

const ShipmentUnitsRows = ({ options }) => {

    const renderCol = (text, value) => {
        return (
            <Col md={6} className="mb-1">
                <Row className="d-none d-lg-block">
                    <Col>
                        <span className="text-title">{text}</span>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-truncate">
                        <span className="color-text-grey">{value}</span>
                    </Col>
                </Row>
            </Col>
        )
    }

    const renderRemoveButton = (shipmentId, operation, loaderId) => {
        if (operation.type === "Unloading") {
            return null;
        }

        return (
            <PolicyGroupAccessHoc componentName="RemoveShipmentFromOperationHOC">
                <Button className="input-block" variation="primary" size="small" onClick={() => options.onRemoveFromOperationClick(shipmentId, operation.id, loaderId)}>Remove From Operation</Button>
            </PolicyGroupAccessHoc>
        )
    }

    const renderRedirectButton = (operation) => {
        return (
            <Button className="input-block" variation="outlined" icon={<RightLink />} iconAlign="right" size="small" onMouseDown={(e) => mouseDownHandler(e, `/operation/${operation.id}`)} onClick={() => routeToUnitDetail(operation)}>Go Operation</Button>
        )
    }

    const renderRedirectShipmentButton = (shipmentId) => {
        if (options.shipmentId === shipmentId) {
            return null;
        }
        return (
            <Button className="input-block" variation="outlined" icon={<RightLink />} iconAlign="right" size="small" onMouseDown={(e) => mouseDownHandler(e, `/shipment/${shipmentId}`)} onClick={() => routeToShipmentDetail(shipmentId)}>Go Shipment</Button>
        )
    }

    const renderOperations = (operations) => {
        if (operations && operations.length > 0) {
            return operations.map((operation, i) => {
                const loaderId = `loader-operation-${operation.id}`;
                var shipmentId = '';

                for (let i = 0; i < options.shipmentIdList.length; i++) {
                    if (operation.shipmentIdList.indexOf(options.shipmentIdList[i]) > -1) {
                        shipmentId = options.shipmentIdList[i];
                    }
                }

                return (
                    <div className={`mb-1 card`} key={i}>
                        <LoadingIndicator id={loaderId} />
                        <div className={`card-body`} >
                            <Row className={`align-items-center`}>
                                <Col md={8} xs={6}>
                                    <Row>
                                        {renderCol('Type', operation.typeName)}
                                        {renderCol('Id', operation.id)}
                                    </Row>
                                    <Row>
                                        {renderCol('Unit', operation.unitCode)}
                                        {renderCol('Status', operation.statusName)}
                                    </Row>
                                </Col>
                                <Col md={4} xs={6} >
                                    <ButtonStack align="right">
                                        {renderRemoveButton(shipmentId, operation, loaderId)}
                                        {renderRedirectButton(operation)}
                                        {renderRedirectShipmentButton(shipmentId)}
                                    </ButtonStack>
                                </Col>
                            </Row>
                        </div>
                    </div>
                )
            })
        } else {
            return (<div className="row"><div className="col"><h6>No unit registered for this shipment</h6></div></div>)
        }

    }

    return (
        <>
            <div className='card'>
                <div className='card-header collapsed' href={`#${options.id}`} data-toggle="collapse" data-parent="#accordion">
                    <h5 className='mb-0'>
                        <img src="/image/icon/down.svg" className="color-action-blue" width="20px" alt='' /> {options.name}
                    </h5>
                </div>
                <div id={options.id} className="collapse">
                    <div className='card-body' mt={0}>
                        <div className='col px-0'>
                            <div className='row'>
                                <div className='col-12'>
                                    {renderOperations(options.operations)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mouseDownHandler = (event, path) => {
    if (event.button === 1) {
        window.open(path, '_blank');
    }
}

const routeToUnitDetail = (operation) => {
    history.push(`/operation/${operation.id}`);
}

const routeToShipmentDetail = (shipmentId) => {
    history.push(`/shipment/${shipmentId}`);
}


export default ShipmentUnitsRows;