import { get as getSync, deleteSync } from '../../../core/httpClientSync';
import postSync from '../../../core/httpClientSync';

import actionTypes from './actionTypes';

const companyURL = 'xms-company-api/api';

/** CREATE COMPANY */
function createCompany(payload) {
  return async function (dispatch) {
    dispatch(createCompanyRequesting());

    const result = await postSync(`${companyURL}/company/create`, payload);
    if (result.isSuccess) {
      dispatch(createCompanySuccess(result.response));
    } else {
      dispatch(createCompanyFailure(result.error));
    }
  };
}

function createCompanyRequesting() {
  return {
    type: actionTypes.CREATE_COMPANY_REQUESTING
  };
}

function createCompanySuccess(payload) {
  return {
    type: actionTypes.CREATE_COMPANY_SUCCESS,
    payload
  };
}

function createCompanyFailure(payload) {
  return {
    type: actionTypes.CREATE_COMPANY_FAILURE,
    payload
  };
}
/** end CREATE COMPANY */

/** READ COMPANY, singular*/
function readCompany(id) {
  return async function (dispatch) {
    dispatch(readCompanyRequesting());
    const result = await getSync(`${companyURL}/company/get-by-id?id=${id}`);
    if (result.isSuccess) {
      dispatch(readCompanySuccess(result.response));
    } else {
      dispatch(readCompanyFailure(result.error));
    }
  };
}

function readCompanyRequesting() {
  return {
    type: actionTypes.READ_COMPANY_REQUESTING
  };
}

function readCompanySuccess(payload) {
  return {
    type: actionTypes.READ_COMPANY_SUCCESS,
    payload
  };
}

function readCompanyFailure(payload) {
  return {
    type: actionTypes.READ_COMPANY_FAILURE,
    payload
  };
}
/** end READ COMPANY */

/** READ COMPANYS, plural */
function readCompanies() {
  return async function (dispatch) {
    dispatch(readCompaniesRequesting());
    const result = await postSync(`${companyURL}/company/get-by-id-list`);
    if (result.isSuccess) {
      dispatch(readCompaniesSuccess(result.response));
    } else {
      dispatch(readCompaniesFailure(result.error));
    }
  };
}

function readCompaniesRequesting() {
  return {
    type: actionTypes.READ_COMPANIES_REQUESTING
  };
}

function readCompaniesSuccess(payload) {
  return {
    type: actionTypes.READ_COMPANIES_SUCCESS,
    payload
  };
}

function readCompaniesFailure(payload) {
  return {
    type: actionTypes.READ_COMPANIES_FAILURE,
    payload
  };
}
/** end READ COMPANYS */

/** UPDATE COMPANY */
function updateCompany(payload) {
  return async function (dispatch) {
    dispatch(updateCompanyRequesting());

    const result = await postSync(`${companyURL}/company/update`, payload);
    if (result.isSuccess) {
      dispatch(updateCompanySuccess(result.response));
    } else {
      dispatch(updateCompanyFailure(result.error));
    }
  };
}

function updateCompanyRequesting() {
  return {
    type: actionTypes.UPDATE_COMPANY_REQUESTING
  };
}

function updateCompanySuccess(payload) {
  return {
    type: actionTypes.UPDATE_COMPANY_SUCCESS,
    payload
  };
}

function updateCompanyFailure(payload) {
  return {
    type: actionTypes.UPDATE_COMPANY_FAILURE,
    payload
  };
}
/** end UPDATE COMPANY */

/** DELETE COMPANY */
function deleteCompany(id) {
  return async function (dispatch) {
    dispatch(deleteCompanyRequesting());

    const result = await deleteSync(`${companyURL}/company/delete-by-id?id=${id}`);
    if (result.isSuccess) {
      dispatch(deleteCompanySuccess(id));
    } else {
      dispatch(deleteCompanyFailure(result.error));
    }
  };
}

function deleteCompanyRequesting() {
  return {
    type: actionTypes.DELETE_COMPANY_REQUESTING
  };
}

function deleteCompanySuccess(payload) {
  return {
    type: actionTypes.DELETE_COMPANY_SUCCESS,
    payload
  };
}

function deleteCompanyFailure(payload) {
  return {
    type: actionTypes.DELETE_COMPANY_FAILURE,
    payload
  };
}
/** end DELETE COMPANY */

/** DELETE COMPANYS, plural */
function deleteCompanies(payload) {
  return async function (dispatch) {
    dispatch(deleteCompaniesRequesting());
    const result = await postSync(`${companyURL}/company/delete-by-id-list`, payload);
    if (result.isSuccess) {
      dispatch(deleteCompaniesSuccess(payload));
    } else {
      dispatch(deleteCompaniesFailure(result.error));
    }
  };
}

function deleteCompaniesRequesting() {
  return {
    type: actionTypes.DELETE_COMPANIES_REQUESTING
  };
}

function deleteCompaniesSuccess(payload) {
  return {
    type: actionTypes.DELETE_COMPANIES_SUCCESS,
    payload
  };
}

function deleteCompaniesFailure(payload) {
  return {
    type: actionTypes.DELETE_COMPANIES_FAILURE,
    payload
  };
}
/** end DELETE COMPANYS, plural */

/** SEARCH COMPANYS */
function searchCompanies(payload, loadingIndicatorId) {
  return async function (dispatch) {
    dispatch(searchCompaniesRequesting());

    const result = await postSync(`${companyURL}/company/search`, payload, loadingIndicatorId);
    if (result.isSuccess) {
      dispatch(searchCompaniesSuccess(result.response));
    } else {
      dispatch(searchConnectionFailure(result.error));
    }
    return result;
  };
}

function searchCompaniesRequesting() {
  return {
    type: actionTypes.SEARCH_COMPANIES_REQUESTING
  };
}

function searchCompaniesSuccess(payload) {
  return {
    type: actionTypes.SEARCH_COMPANIES_SUCCESS,
    payload
  };
}

function searchConnectionFailure(payload) {
  return {
    type: actionTypes.SEARCH_COMPANIES_FAILURE,
    payload
  };
}

function setFilter(payload) {
  return {
    type: actionTypes.SET_FILTER,
    payload
  };
}

function setFilters(payload) {
  return {
    type: actionTypes.SET_FILTERS,
    payload
  };
}

function resetAllFilters(payload) {
  return {
    type: actionTypes.RESET_ALL_FILTERS,
    payload
  };
}

/** end SEARCH COMPANYS */

export {
  createCompany,
  readCompany,
  readCompanies,
  updateCompany,
  deleteCompany,
  deleteCompanies,
  searchCompanies,
  setFilter,
  setFilters,
  resetAllFilters
};
