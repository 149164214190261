import React from "react";
import { AuthorizationWrapper } from "./AuthorizationRenderer";
import { Button } from '@dfds-ui/react-components'
import store from '../../../../store/store';

export const CommandListRenderer = (schema, props) => {
    if (!schema || !schema.Commands) {
        return;
    }

    const orderedCommands = schema.Commands.slice(0).sort((first, second) => (first.ordering > second.ordering) ? 1 : -1);

    return orderedCommands.map(command => {

        if (command.authorizationWrapper) {
            return AuthorizationWrapper((
                <Button type='button' className='float-right' onClick={() => executeHandler(command, props)}>{command.name}</Button>
            ), command.ordering + 'commandButton', command.authorizationWrapper);
        }

        return (
            <Button type='button' className='float-right' key={command.ordering + 'commandButton'} onClick={() => executeHandler(command, props)}>{command.name}</Button>
        )
    });
};

const executeHandler = (command, props) => {
    command.isConfirmationNeeded && store.dispatch({
        type: 'astrid/modal/SHOW_MODAL',
        payload: {
            modalType: 'confirmation',
            modalData: { title: command.name, message: command.confirmationText, onConfirm: () => confirmationHandler(command, props) }
        }
    })
};

const confirmationHandler = (command, props) => {
    let modelParameter = {};

    command.params.forEach(item => {
        modelParameter[item] = props.initialValues[item];
    });

    props[command.callback](modelParameter);
}