/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import PageHeader from '../component/PageHeader/PageHeader';
import { connect } from 'react-redux';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import { clearShipmentsState, getOperation, getLinkedShipmentList, getUnlinkedShipmentList, linkShipmentsToOperation, unlinkShipmentsFromOperation } from '../store/action/shipmentInboundOperationAction';
import { confirmationModal } from '../store/action/modalActions';
import Filter from '../component/ShipmentInbound/Filter';
import ShipmentList from '../component/ShipmentInbound/ShipmentList';

const ShipmentInboundOperation = (props) => {

    //redux
    const { clearShipmentsState, getOperation, getLinkedShipmentList, getUnlinkedShipmentList, linkShipmentsToOperation, unlinkShipmentsFromOperation, operation, shipmentIdList, availableShipmentList, linkedShipmentList, confirmationModal } = props;

    const operationId = props.match.params.operationId.replace('_', '#');

    const [isCheckLinkAll, setIsCheckLinkAll] = useState(false);
    const [isCheckUnlinkAll, setIsCheckUnlinkAll] = useState(false);

    const [toOperationList, setToOperationList] = useState([]);
    const [fromOperationList, setFromOperationList] = useState([]);

    const CONSTANTS = {
        ALL: 'loader-link-shipment',
        FILTERLOADER: 'loader-link-shipment',
        LINKEDSHIPMENTLISTLOADER: 'loader-link-shipment',
        UNLINKEDSHIPMENTLISTLOADER: 'loader-link-shipment'
    }

    useEffect(() => {
        getOperation(operationId, CONSTANTS.ALL);

        return () => {
            clearShipmentsState();
        }
    }, [])

    useEffect(() => {
        if (shipmentIdList && shipmentIdList.length > 0) {
            getLinkedShipmentList(shipmentIdList, CONSTANTS.ALL)
        }
    }, [shipmentIdList])

    useEffect(() => {
        setToOperationList([]);
        setFromOperationList([]);
        setIsCheckLinkAll(false);
        setIsCheckUnlinkAll(false);
    }, [availableShipmentList, linkedShipmentList]);

    const onSearch = (filter) => {
        getUnlinkedShipmentList(filter, CONSTANTS.ALL)
    }

    //#region AvailableShipmentList

    const onCheckLinkAll = (isChecked) => {
        setToOperationList((prevState) => {
            let list = [...prevState];
            if (isChecked) {
                list = availableShipmentList.map(s => s.id);
            } else {
                list = [];
            }
            return list;
        });
        setIsCheckLinkAll(isChecked)
    }

    const onCheckLinkShipment = (id, isChecked, recursive = false) => {
        setToOperationList((prevState) => {
            let list = [...prevState];
            if (isChecked && list.indexOf(id) < 0) {
                list.push(id)
            }
            if (!isChecked) {
                list = list.filter(x => x != id);
            }

            setIsCheckLinkAll(availableShipmentList.length === list.length)
            return list;
        });

        checkLinkedShipmentTransport(id, isChecked, recursive);
    }

    const checkLinkedShipmentTransport = (id, isChecked, recursive = false) => {
        if (!recursive) {
            var shipment = availableShipmentList.find(s => s.id === id);
            if (shipment && shipment.groupId) {
                var shipments = availableShipmentList.filter(s => s.groupId === shipment.groupId)
                for (var i = 0; i < shipments.length; i++) {
                    onCheckLinkShipment(shipments[i].id, isChecked, true)
                }
            }
        }
    }

    const isCheckLinkShipment = (id) => {
        return toOperationList.indexOf(id) > -1;
    }

    const onClickLink = () => {
        confirmationModal('Shipment To Unloading Unit', 'Shipment(s) will be related with this unit. Do you approve?', () => linkShipmentsToOperation(toOperationList, operationId, CONSTANTS.ALL));
    }

    //#endregion AvailableShipmentList

    //#region linkedShipmentList

    const onCheckUnlinkAll = (isChecked) => {
        setFromOperationList((prevState) => {
            let list = [...prevState];
            if (isChecked) {
                list = linkedShipmentList.map(s => s.id);
            } else {
                list = [];
            }
            return list;
        });
        setIsCheckUnlinkAll(isChecked)
    }

    const onCheckUnlinkShipment = (id, isChecked, recursive = false) => {
        setFromOperationList((prevState) => {
            let list = [...prevState];
            if (isChecked && list.indexOf(id) < 0) {
                list.push(id)
            }
            if (!isChecked) {
                list = list.filter(x => x != id);
            }

            setIsCheckUnlinkAll(linkedShipmentList.length === list.length)
            return list;
        });

        checkUnlinkedShipmentTransport(id, isChecked, recursive);
    }

    const checkUnlinkedShipmentTransport = (id, isChecked, recursive = false) => {
        if (!recursive) {
            var shipment = linkedShipmentList.find(s => s.id === id);
            if (shipment && shipment.groupId) {
                var shipments = linkedShipmentList.filter(s => s.groupId === shipment.groupId)
                for (var i = 0; i < shipments.length; i++) {
                    onCheckUnlinkShipment(shipments[i].id, isChecked, true)
                }
            }
        }
    }

    const isCheckUnlinkShipment = (id) => {
        return fromOperationList.indexOf(id) > -1;
    }

    const onClickUnlink = () => {
        confirmationModal('Shipment From Unloading Unit', 'Shipment(s) will be removed from this unit. Do you approve?', () => unlinkShipmentsFromOperation(fromOperationList, operationId, CONSTANTS.ALL));
    }

    //#endregion linkedShipmentList

    return (<>
        <Row>
            <Col>
                <PageHeader text={`Shipment Inbound Operation - Unit : ${operation && operation.unitCode ? operation.unitCode : operation.id}`} />
            </Col>
        </Row>
        <Row>
            <Col>
                <Filter onClick={onSearch} />
            </Col>
        </Row>
        <Row className='mt-3'>
            <LoadingIndicator id={CONSTANTS.ALL} hide />
            <Col xs={6}>
                <ShipmentList
                    shipmentList={availableShipmentList}
                    onCheckAll={onCheckLinkAll}
                    isCheckAll={isCheckLinkAll}
                    onCheckShipment={onCheckLinkShipment}
                    isCheckShipment={isCheckLinkShipment}
                    headerOptions={{ text: 'Available List', buttonText: 'Link', onClick: onClickLink, count: toOperationList.length }} />
            </Col>
            <Col xs={6}>
                <ShipmentList
                    shipmentList={linkedShipmentList}
                    onCheckAll={onCheckUnlinkAll}
                    isCheckAll={isCheckUnlinkAll}
                    onCheckShipment={onCheckUnlinkShipment}
                    isCheckShipment={isCheckUnlinkShipment}
                    headerOptions={{ text: ` ${operation ? operation.unitCode : ''} Operation List`, buttonText: 'Unlink', onClick: onClickUnlink, count: fromOperationList.length }} />
            </Col>
        </Row>
    </>)
}

const mapActionToProps = {
    clearShipmentsState,
    getOperation,
    getLinkedShipmentList,
    getUnlinkedShipmentList,
    linkShipmentsToOperation,
    unlinkShipmentsFromOperation,
    confirmationModal
}

const mapStateToProps = ({ shipmentInboundOperation }) => {
    return {
        operation: shipmentInboundOperation.operation,
        shipmentIdList: shipmentInboundOperation.shipmentIdList,
        availableShipmentList: shipmentInboundOperation.availableShipmentList,
        linkedShipmentList: shipmentInboundOperation.linkedShipmentList
    }
}

export default connect(mapStateToProps, mapActionToProps)(ShipmentInboundOperation);