import postSync, { get, post } from '../../core/httpClientSync';
import actionTypes from '../type/slotBookingConfiguration';
import { toast } from 'react-toastify';
import history from '../../core/history';

const apiUrl = 'astrid-slotbooking-api/api/configuration';
//const apiUrl = 'http://localhost:5000/api/configuration';

export const getConfigurations = () => async (dispatch) => {
  const endPoint = `${apiUrl}/getall`;
  const getConfigurationsResponse = await get(
    endPoint,
    'addOrEditSlotBookingConfiguration'
  );
  const businessAreaEndPoint = 'astrid-slotbooking-api/api/businessarea/getall';
  const getBusinessAreasResponse = await get(
    `${businessAreaEndPoint}`,
    'addOrEditSlotBookingConfiguration'
  );
  if (getConfigurationsResponse.isSuccess) {
    const configurations = getConfigurationsResponse.response.result.map(
      (x) => {
        //get business area name
        x.businessAreaName = getBusinessAreasResponse.response.result.find(
          (e) => e.id === x.businessAreaId
        ).name;
        return {
          ...x,
        };
      }
    );
    dispatch({
      type: actionTypes.CONFIGURATIONS_RETRIEVED,
      payload: configurations,
    });
  }
};

export const getConfiguration = (id) => async (dispatch) => {
  const endPoint = `${apiUrl}/get/${id}`;
  const getConfigurationResponse = await get(
    endPoint,
    'addOrEditSlotBookingConfiguration'
  );
  
  dispatch({
    type: actionTypes.CONFIGURATION_RETRIEVED,
    payload: getConfigurationResponse.response.result || {},
  });
};

export const saveConfiguration = (model) => async (dispatch) => {
  const endPoint = `${apiUrl}/save`;
  const response = await postSync(
    endPoint,
    model,
    'addOrEditSlotBookingConfiguration'
  );

  if (response.isSuccess) {
    dispatch({
      type: actionTypes.CONFIGURATION_SAVED,
    });

    toast.success(
      `Configuration has been ${
        !model.id ? 'created' : 'updated'
      } successfully.`
    );
    history.goBack();
  }
};

export const getBusinessAreas = () => async (dispatch) => {
  const endPoint = 'astrid-slotbooking-api/api/businessarea/getall';
  const getBusinessAreasResponse = await get(
    `${endPoint}`,
    'addOrEditSlotBookingConfiguration'
  );
  const businessAreasItems = getBusinessAreasResponse.response.result.map(
    (item) => ({ value: item.id, text: item.name })
  );
  if (getBusinessAreasResponse.isSuccess) {
    dispatch({
      type: actionTypes.BUSINESS_AREAS_RECEIVED,
      payload: businessAreasItems,
    });
  }
};
