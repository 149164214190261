// import CreateOrUpdateTask from "../RoutedComponents/CreateOrUpdateTask";

export const GeneralPolicyList = {
  Policies: {
    Allowance: ['Booking']
  }
};

// TODO: IDEA: looks like the Action property is not used yet, PolicyGroupAccessHoc renders children or an empty fragment, effectively not rendering. We can style the component as well like disabling it
export const LayoutPolicyList = {
  Policies: {
    Header: {
      components: [
        {
          ComponentName: 'UserAdminSection',
          AllowedPolicies: ['UserAdminPolicy'],
          Action: 'Hide'
        },
        {
          ComponentName: 'VoyagesSection',
          AllowedPolicies: ['VoyageInternalPolicy', 'VoyageAdminPolicy', 'VoyageReadonlyPolicy'],
          Action: 'Hide'
        },
        {
          ComponentName: 'ContainerBookingsSection',
          AllowedPolicies: ['ContainerBookingInternalPolicy', 'ContainerBookingAdminPolicy', 'ContainerBookingReadonlyPolicy'],
          Action: 'Hide'
        },
        {
          ComponentName: 'SuffixSection',
          AllowedPolicies: ['SuffixOfficePolicy', 'SuffixAdminPolicy'],
          Action: 'Hide'
        },
        {
          ComponentName: 'CompanyAdminSection',
          AllowedPolicies: ['CompanyAdminPolicy'],
          Action: 'Hide'
        },
        {
          ComponentName: 'BusinessAreaAdminSection',
          AllowedPolicies: ['BookingAdminPolicy'],
          Action: 'Hide'
        },
        {
          ComponentName: 'BookingAdminSection',
          AllowedPolicies: ['BookingAdminPolicy'],
          Action: 'Hide'
        },
        {
          ComponentName: 'RoleAdminSection',
          AllowedPolicies: ['RoleAdminPolicy'],
          Action: 'Hide'
        },
        {
          ComponentName: 'DividerSection',
          AllowedPolicies: [
            'RoleAdminPolicy',
            'UserAdminPolicy',
            'SuffixForkliftPolicy',
            'SuffixOfficePolicy',
            'SuffixAdminPolicy',
            'CompanyAdminPolicy',
            'BookingAdminPolicy'
          ],
          Action: 'Hide'
        },
        {
          ComponentName: 'BookingInternalLinkSection',
          AllowedPolicies: ['BookingInternalPolicy'],
          Action: 'Hide'
        },
        {
          ComponentName: 'BookingExternalLinkSection',
          AllowedPolicies: ['BookingExternalPolicy'],
          Action: 'Hide'
        },
        {
          ComponentName: 'ShipmentDomainLink',
          AllowedPolicies: [
            'DocumentFullAccessPolicy',
            'ShipmentFullAccessPolicy',
            'ShipmentForkliftPolicy',
            'ShipmentReadonlyPolicy'
          ],
          Action: 'Hide'
        },
        {
          ComponentName: 'ArticlesDomainLink',
          AllowedPolicies: [
            'DocumentFullAccessPolicy',
            'ShipmentFullAccessPolicy',
            'ShipmentForkliftPolicy',
            'ShipmentReadonlyPolicy'
          ],
          Action: 'Hide'
        },
        {
          ComponentName: 'TaskLinkSection',
          AllowedPolicies: ['TaskBasePolicy'],
          Action: 'Hide'
        },
        {
          ComponentName: 'HistorySection',
          AllowedPolicies: ['HistoryPolicy'],
          Action: 'Hide'
        },
        {
          ComponentName: 'ReceivingPageLink',
          AllowedPolicies: ['ShipmentForkliftPolicy', 'ShipmentFullAccessPolicy'],
          Action: 'Hide'
        },
        {
          ComponentName: 'MessagesPageLink',
          AllowedPolicies: ['ShipmentFullAccessPolicy'],
          Action: 'Hide'
        },
        {
          ComponentName: 'BackOffice',
          AllowedPolicies: ['UserAdminPolicy', 'BookingAdminPolicy'],
          Action: 'Hide'
        }
      ]
    }
  }
};


export const ComponentPolicyList = {
  Policies: {
    HomePage: {},
    Operation: {
      routePolicy: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy', 'ShipmentForkliftPolicy', 'ShipmentReadonlyPolicy'],
      components: [
        {
          ComponentName: 'ShipmentLinkButtonHOC',
          AllowedPolicies: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy']
        },
        {
          ComponentName: 'NewShipmentsToOperationHOC',
          AllowedPolicies: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy']
        },
        {
          ComponentName: 'PickShipmentsToOperationHOC',
          AllowedPolicies: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy', 'ShipmentForkliftPolicy']
        },
        {
          ComponentName: 'TransportLinkButtonHOC',
          AllowedPolicies: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy']
        },
        {
          ComponentName: 'FileControllerOperationButtonsHOC',
          AllowedPolicies: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy', 'ShipmentForkliftPolicy']
        },
        {
          ComponentName: 'UnitLeftButtonHOC',
          AllowedPolicies: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy', 'ShipmentForkliftPolicy']
        },
        {
          ComponentName: 'UnitSealNumberButtonHOC',
          AllowedPolicies: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy', 'ShipmentForkliftPolicy']
        }
      ]
    },
    OperationSplitContainer: {
      routePolicy: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy']
    },
    ShipmentOutboundOperation: {
      routePolicy: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy', 'ShipmentForkliftPolicy']
    },
    Transport: {
      routePolicy: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy']
    },
    Shipments: {
      routePolicy: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy', 'ShipmentForkliftPolicy', 'ShipmentReadonlyPolicy'],
      components: [
        {
          ComponentName: 'AddNewShipmentButtonHOC',
          AllowedPolicies: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy']
        }
      ]
    },
    ShipmentDetail: {
      routePolicy: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy', 'ShipmentForkliftPolicy', 'ShipmentReadonlyPolicy'],
      components: [
        {
          ComponentName: 'MarkAsDeletedHOC',
          AllowedPolicies: ['ShipmentFullAccessPolicy']
        },
        {
          ComponentName: 'UpdateShipmentControlsHOC',
          AllowedPolicies: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy']
        },
        {
          ComponentName: 'THUListControlsHOC',
          AllowedPolicies: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy', 'ShipmentForkliftPolicy']
        },
        {
          ComponentName: 'THURemeasurementNotificationHOC',
          AllowedPolicies: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy', 'ShipmentForkliftPolicy']
        },
        {
          ComponentName: 'THUCreateHOC',
          AllowedPolicies: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy', 'ShipmentForkliftPolicy']
        },
        {
          ComponentName: 'THUConfirmHOC',
          AllowedPolicies: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy', 'ShipmentForkliftPolicy']
        },
        {
          ComponentName: 'THUPrintHOC',
          AllowedPolicies: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy', 'ShipmentForkliftPolicy']
        },
        {
          ComponentName: 'THURetryHOC',
          AllowedPolicies: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy', 'ShipmentForkliftPolicy']
        },
        {
          ComponentName: 'THUReverseStateHOC',
          AllowedPolicies: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy', 'ShipmentForkliftPolicy']
        },
        {
          ComponentName: 'THUDeleteHOC',
          AllowedPolicies: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy']
        },
        {
          ComponentName: 'FileControllerOperationButtonsHOC',
          AllowedPolicies: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy', 'ShipmentForkliftPolicy']
        },
        {
          ComponentName: 'HazardousFullAccessHOC',
          AllowedPolicies: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy', 'ShipmentForkliftPolicy']
        },
        {
          ComponentName: 'RemoveShipmentFromOperationHOC',
          AllowedPolicies: ['ShipmentFullAccessPolicy', 'ShipmentForkliftPolicy']
        }
      ]
    },
    Inventory: {
      routePolicy: ['ArticlesDomainLink']
    },
    Thu: {
      routePolicy: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy', 'ShipmentForkliftPolicy', 'ShipmentReadonlyPolicy'],
      components: [
        {
          ComponentName: 'ThuUpdateHOC',
          AllowedPolicies: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy', 'ShipmentForkliftPolicy']
        }
      ]
    },
    CreateThu: {
      routePolicy: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy', 'ShipmentForkliftPolicy']
    },
    PrintThu: {
      routePolicy: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy', 'ShipmentForkliftPolicy']
    },
    HoldTerminalItem: {
      routePolicy: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy', 'ShipmentForkliftPolicy']
    },
    ShipmentInboundOperation: {
      routePolicy: ['ShipmentFullAccessPolicy', 'ShipmentInternalPolicy']
    },
    ActionUrl: {
      routePolicy: [
        'DownloadDocumentPolicy',
        'DocumentFullAccessPolicy',
        'ShipmentFullAccessPolicy',
      ]
    },
    CalendarRuleList: {
      routePolicy: ['BookingAdminPolicy']
    },
    OpenAdditionalGate: {
      routePolicy: ['BookingAdminPolicy']
    },
    ReservedSlotRuleList: {
      routePolicy: ['BookingAdminPolicy']
    },
    AddOrEditReservedSlotRule: {
      routePolicy: ['BookingAdminPolicy']
    },
    AddOrEditSlotBookingConfiguration: {
      routePolicy: ['BookingAdminPolicy']
    },
    AddOrEditCalendarRule: {
      routePolicy: ['BookingAdminPolicy']
    },
    BusinessAreaList: {
      routePolicy: ['BookingAdminPolicy']
    },
    Booking: {
      routePolicy: ['BookingBasePolicy'],
      components: [
        {
          ComponentName: 'AllBookingHoc',
          AllowedPolicies: ['BookingInternalPolicy']
        },
        {
          ComponentName: 'MyBookingHoc',
          AllowedPolicies: ['BookingExternalPolicy']
        }
      ]
    },
    Bookings: {
      routePolicy: ['BookingInternalPolicy']
    },
    CarrierBookings: {
      routePolicy: ['BookingExternalPolicy']
    },
    CreateBookingContainer: {
      routePolicy: ['BookingBasePolicy'],
      components: [
        {
          ComponentName: 'AdministratorHoc',
          AllowedPolicies: ['BookingInternalPolicy']
        },
        {
          ComponentName: 'ExternalHoc',
          AllowedPolicies: ['BookingExternalPolicy']
        }
      ]
    },
    SelectTimeSlot: {
      routePolicy: ['BookingBasePolicy']
    },
    ConfirmBooking: {
      routePolicy: ['BookingBasePolicy'],
      components: [
        {
          ComponentName: 'AdministratorHoc',
          AllowedPolicies: ['BookingInternalPolicy']
        },
        {
          ComponentName: 'ExternalHoc',
          AllowedPolicies: ['BookingExternalPolicy']
        }
      ]
    },
    UpdateBookingContainer: {
      routePolicy: ['BookingBasePolicy'],
      components: [
        {
          ComponentName: 'AdministratorHoc',
          AllowedPolicies: ['BookingInternalPolicy']
        },
        {
          ComponentName: 'ExternalHoc',
          AllowedPolicies: ['BookingExternalPolicy']
        }
      ]
    },
    Voyages: {
      routePolicy: ['VoyageInternalPolicy', 'VoyageAdminPolicy', 'VoyageReadonlyPolicy'],
      components: [
        {
          ComponentName: 'AddVoyageButtonHOC',
          AllowedPolicies: ['VoyageInternalPolicy', 'VoyageAdminPolicy']
        }
      ]
    },
    Voyage: {
      routePolicy: ['VoyageInternalPolicy', 'VoyageAdminPolicy', 'VoyageReadonlyPolicy'],
      components: [
        {
          ComponentName: 'VoyageUpdateButtonHOC',
          AllowedPolicies: ['VoyageInternalPolicy', 'VoyageAdminPolicy']
        }
      ]
    },
    ContainerBookings: {
      routePolicy: ['ContainerBookingInternalPolicy', 'ContainerBookingAdminPolicy', 'ContainerBookingReadonlyPolicy'],
      components: [
        {
          ComponentName: 'AddNewContainerBookingButtonHOC',
          AllowedPolicies: ['ContainerBookingInternalPolicy', 'ContainerBookingAdminPolicy']
        },
        {
          ComponentName: 'SaveContainerBookingButtonHOC',
          AllowedPolicies: ['ContainerBookingInternalPolicy', 'ContainerBookingAdminPolicy']
        },
        {
          ComponentName: 'OperationContainerBookingButtonHOC',
          AllowedPolicies: ['ContainerBookingInternalPolicy', 'ContainerBookingAdminPolicy']
        },
        {
          ComponentName: 'AddOperationContainerBookingButtonHOC',
          AllowedPolicies: ['ContainerBookingInternalPolicy', 'ContainerBookingAdminPolicy']
        }
      ]
    },
    ContainerBooking: {
      routePolicy: ['ContainerBookingInternalPolicy', 'ContainerBookingAdminPolicy', 'ContainerBookingReadonlyPolicy'],
      components: [
        {
          ComponentName: 'SaveContainerBookingButtonHOC',
          AllowedPolicies: ['ContainerBookingInternalPolicy', 'ContainerBookingAdminPolicy']
        },
        {
          ComponentName: 'OperationContainerBookingButtonHOC',
          AllowedPolicies: ['ContainerBookingInternalPolicy', 'ContainerBookingAdminPolicy']
        },
        {
          ComponentName: 'AddOperationContainerBookingButtonHOC',
          AllowedPolicies: ['ContainerBookingInternalPolicy', 'ContainerBookingAdminPolicy']
        }
      ]
    },
    ConnectionPoints: {
      routePolicy: ['SuffixOfficePolicy', 'SuffixAdminPolicy']
    },
    ConnectionPoint: {
      routePolicy: ['SuffixOfficePolicy', 'SuffixAdminPolicy']
    },
    Suffixes: {
      routePolicy: ['SuffixOfficePolicy', 'SuffixAdminPolicy'],
      components: [
        {
          ComponentName: 'SuffixSuppliersAddingHOC',
          AllowedPolicies: ['SuffixOfficePolicy', 'SuffixAdminPolicy']
        }
      ]
    },
    Notification: {
      routePolicy: ['SuffixAdminPolicy']
    },
    AddOrEditSuffix: {
      routePolicy: ['SuffixOfficePolicy', 'SuffixAdminPolicy']
    },
    NotificationDetail: {
      routePolicy: ['SuffixOfficePolicy', 'SuffixAdminPolicy']
    },
    CompanyList: {
      routePolicy: ['CompanyAdminPolicy']
    },
    AddOrEditCompany: {
      routePolicy: ['CompanyAdminPolicy']
    },
    AddOrEditContract: {
      routePolicy: ['CompanyAdminPolicy']
    },
    UserList: {
      routePolicy: ['UserAdminPolicy']
    },
    ChangePassword: {
      routePolicy: ['UserAdminPolicy']
    },
    AddOrEditUser: {
      routePolicy: ['UserAdminPolicy']
    },
    NoAuth: {},
    Logout: {},
    RoleList: {
      routePolicy: ['RoleAdminPolicy']
    },
    CreateRole: {
      routePolicy: ['RoleAdminPolicy']
    },
    EditRole: {
      routePolicy: ['RoleAdminPolicy']
    },
    Tasks: {
      routePolicy: ['TaskBasePolicy'],
      components: [
        {
          ComponentName: 'MarkAsArrivedOnTask',
          AllowedPolicies: ['TaskInternalPolicy']
        },
        {
          ComponentName: 'EnterGateNumberOnTask',
          AllowedPolicies: ['TaskInternalPolicy']
        },
        {
          ComponentName: 'EditTask',
          AllowedPolicies: ['TaskInternalPolicy']
        },
        {
          ComponentName: 'KPIReport',
          AllowedPolicies: ['KPIReportAccessPolicy']
        }
      ]
    },
    CreateOrUpdateTask: {
      routePolicy: ['TaskBasePolicy'],
      components: [
        {
          ComponentName: 'TaskCreateInternalHoc',
          AllowedPolicies: ['TaskInternalPolicy']
        }
      ]
    },
    History: {
      routePolicy: ['HistoryPolicy']
    }
  }
};
