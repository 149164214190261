export const formInputTypes = Object.freeze({
    STRING_INPUT: Symbol('STRING_INPUT'),
    COMBO_INPUT: Symbol('COMBO_INPUT'),
    DATE_INPUT: Symbol('DATE_INPUT'),
    TIME_INPUT: Symbol('TIME_INPUT'),
    NUMBER_INPUT: Symbol('NUMBER_INPUT'),
    RADIO_INPUT: Symbol('RADIO_INPUT'),
    TEXTAREA_INPUT: Symbol('TEXTAREA_INPUT'),
    HIDDEN_INPUT: Symbol('HIDDEN_INPUT'),
    CHECKBOX_INPUT: Symbol('CHECKBOX_INPUT')
});


export const formButtonBehaviour = Object.freeze({
   BUTTON_DISABLE: Symbol('BUTTON_DISABLE'),
   BUTTON_DISAPPEAR: Symbol('BUTTON_DISAPPEAR'),
   BUTTON_NONE: Symbol('BUTTON_NONE')
});