import actionTypes from "./actionTypes";
import { getValue } from "../../../core/utility";

// If any filter has Value property empty string, "", that means it is not defined, that is better than passing base values null or undefined when using Controlled React Components. We filter wrt those in related action: readShipmentsOverview(filters)
const initialFilters = [
    { "Name": "status", "Value": "", "Operator": "=" },
    { "Name": "articleList.status", "Value": "", "Operator": "=" },
    { "Name": "shipmentNo", "Value": "", "Operator": "=" },
    { "Name": "destination.group.id", "Value": "", "Operator": "=" },
    { "Name": "destinationId", "Value": "", "Operator": "=" },
    { "Name": "actualMeasurement.itemQuantity.value", "Value": "", "Operator": ">=" },// may need a indeterminate state inside the component used
    { "Name": "actualMeasurement.itemQuantity.value", "Value": "", "Operator": "<=" },
    { "Name": "firstConsignorId", "Value": "", "Operator": "=" },
    { "Name": "endCustomerId", "Value": "", "Operator": "=" },
    { "Name": "supplierId", "Value": "", "Operator": "=" },
    { "Name": "temperatureControlled", "Value": "", "Operator": "=" },
    { "Name": "customsControlled", "Value": "", "Operator": "=" },
    { "Name": "hasHazardousItem", "Value": "", "Operator": "=" },
    { "Name": "customerPickupDate", "Value": "", "Operator": ">=" },
    { "Name": "customerPickupDate", "Value": "", "Operator": "<=" },
    { "Name": "customerDeliveryDate", "Value": "", "Operator": ">=" },
    { "Name": "customerDeliveryDate", "Value": "", "Operator": "<=" },
    { "Name": "createdDate", "Value": "", "Operator": ">=" },
    { "Name": "createdDate", "Value": "", "Operator": "<=" },
    { "Name": "actualMeasurement.volume.value", "Value": "", "Operator": ">=" },
    { "Name": "actualMeasurement.volume.value", "Value": "", "Operator": "<=" },
    { "Name": "actualMeasurement.grossWeight.value", "Value": "", "Operator": ">=" },
    { "Name": "actualMeasurement.grossWeight.value", "Value": "", "Operator": "<=" },
    { "Name": "unit.group.type", "Value": "", "Operator": "=" }, // Transport. type can be "Arrival" or "Delivery"
    { "Name": "unit.group.code", "Value": "", "Operator": "=" }, //  Transport. Transport Direction: Inbound/Outbound Transport, Arrival/Delivery Transport, respectively
    { "Name": "operationList.type", "Value": "", "Operator": "=" }, // Unit. type can be "Arrival" or "Delivery"
    { "Name": "operationList.id", "Value": "", "Operator": "=" }, // Unit. Inbound/Outbound Unit(s), Arrival/Delivery Unit(s), respectively
    { "Name": "operationList.unitCode", "Value": "", "Operator": "=" }, // Unit.
    { "Name": "destination.address.country.code", "Value": "", "Operator": "=" },
    { "Name": "arrivedDate", "Value": "", "Operator": ">=" },
    { "Name": "arrivedDate", "Value": "", "Operator": "<=" },
    { "Name": "receivedDate", "Value": "", "Operator": ">=" },
    { "Name": "receivedDate", "Value": "", "Operator": "<=" },
    { "Name": "shortReceivedDate", "Value": "", "Operator": ">=" },
    { "Name": "shortReceivedDate", "Value": "", "Operator": "<=" },
    { "Name": "shippedDate", "Value": "", "Operator": ">=" },
    { "Name": "shippedDate", "Value": "", "Operator": "<=" },
];

const initialState = {
    isRequesting: false,
    items: [],
    errors: [],
    filters: initialFilters,
    filtersAux: {},
};

/** SHIPMENTS */
function reduceShipments(state = initialState, action) {
    switch (action.type) {
        // READ
        case actionTypes.READ_SHIPMENTS_REQUESTING:
            return { ...state, isRequesting: true };
        case actionTypes.READ_SHIPMENTS_SUCCESS:
            return { ...state, isRequesting: false, items: action.payload, errors: [] };
        case actionTypes.READ_SHIPMENTS_FAILURE:
            return { ...state, isRequesting: false, errors: action.payload };
        // end READ
        // FILTER
        case actionTypes.SET_FILTER:
            const { Name, Value, Operator } = action.payload;
            return {
                ...state, filters: state.filters.map(filter => (filter.Name === Name && filter.Operator === Operator) === true
                    ? { ...filter, Value: Value }
                    : filter
                )
            };
        case actionTypes.SET_FILTERS:
            return {
                ...state, filters: action.payload
            };
        case actionTypes.SET_FILTERS_AUX:
            return {
                ...state, filtersAux: action.payload
            };
        case actionTypes.RESET_ALL_FILTERS:
            return { ...state, filters: initialFilters, filtersAux: {} };
        // end FILTER
        // SORT
        case actionTypes.SORT_SHIPMENTS:
            return {
                ...state,
                items: [...state.items].sort((a, b) => {
                    if (action.payload.dataType === 'string') {
                        const comparison = getValue(a, action.payload.sortBy) > getValue(b, action.payload.sortBy);
                        const flag = comparison ? 1 : -1;
                        return action.payload.sortDirection === 'ASC' ? (-flag) : flag;
                    } else if (action.payload.dataType === 'number') {
                        const difference = getValue(a, action.payload.sortBy) - getValue(b, action.payload.sortBy);
                        const flag = difference > 0 ? 1 : -1;
                        return action.payload.sortDirection === 'ASC' ? (-flag) : flag;
                    } else if (action.payload.dataType === 'boolean') {
                        const comparison = getValue(a, action.payload.sortBy) > getValue(b, action.payload.sortBy);
                        const flag = comparison ? 1 : -1;
                        return action.payload.sortDirection === 'ASC' ? (-flag) : flag;
                    }
                })
            };
        // end SORT
        default: return state;
    }
}
/** end SHIPMENTS */


export default reduceShipments;