/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { ButtonStack, Button } from '@dfds-ui/react-components';
import history from '../core/history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Filters from '../component/Tasks/Filters';
import Task from '../component/Tasks/Task/Task';
import { connect } from 'react-redux';
import {
  clearTasks,
  getTasks,
  filterTasks,
  clearState,
  getDefaultBusinessAreas,
  getBusinessAreas,
  setDefaultBusinessAreas,
  downloadKpiReport
} from '../store/action/taskActions';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import QuickFilters from '../component/Tasks/QuickFilters';
import LoadingTaskBadge from '../component/Tasks/Task/BadgeAreas/LoadingTaskBadge';
import UnloadingTaskBadge from '../component/Tasks/Task/BadgeAreas/UnloadingTaskBadge';
import LoadingCustomActions from '../component/Tasks/Task/CustomActions/LoadingCustomActions';
import UnloadingCustomActions from '../component/Tasks/Task/CustomActions/UnloadingCustomActions';
import Page from '../component/Page/Page';
import PolicyGroupAccessHoc from './Routes/PolicyGroupAccessHoc';

const Tasks = (props) => {
  //Redux actions
  const {
    getTasks,
    filterTasks,
    clearState,
    getBusinessAreas,
    clearTasks,
    downloadKpiReport
  } = props;

  //Redux store
  let { tasks, filteredTasks, authenticatedUser, businessAreas } = props;

  // //Others
  // let {} = props;

  const defaultTypeValue = { value: '', label: 'Select Type' };
  const defaultStatusValue = { value: '', label: 'Select Status' };
  const defaultDateValue = new Date();

  const [selectedQuickFilter, setSelectedQuickFilter] = useState('all');
  const [taskId, setTaskId] = useState('');
  const [selectedType, setSelectedType] = useState(defaultTypeValue);
  const [selectedDate, setSelectedDate] = useState(defaultDateValue); //FORMAT: 'YYYY-MM-DD';
  const [selectedStatus, setSelectedStatus] = useState(defaultStatusValue);
  const [selectedBusinessAreas, setSelectedBusinessAreas] = useState([]);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      clearTasks();
    }, 300000);
    return () => {
      clearInterval(refreshInterval);
      clearState();
    };
  }, []);

  useEffect(() => {
    if (!businessAreas) {
      getBusinessAreas();
    } else {
      const list = getDefaultBusinessAreas(businessAreas);
      setSelectedBusinessAreas(list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessAreas]);

  useEffect(() => {
    if (tasks) {
      filterTasks(tasks, selectedType.value);
    }
  }, [tasks, selectedType]);

  useEffect(() => {
    if (tasks) {
      clearTasks();
    }
  }, [selectedQuickFilter, taskId, selectedDate, selectedStatus, selectedBusinessAreas]);

  useEffect(() => {
    if (!tasks && businessAreas && selectedBusinessAreas && selectedBusinessAreas.length > 0) {
      getTasks(
        businessAreas,
        selectedQuickFilter,
        taskId,
        selectedDate,
        selectedStatus.value,
        authenticatedUser.id,
        selectedBusinessAreas
      );
    }
  }, [tasks, selectedBusinessAreas]);

  const onTaskIdChangedHandler = (e) => {
    setTaskId(e.target.value);
  };
  const onDateChangedHandler = (value) => {
    setSelectedDate(value);
  };

  const onTypeChangedHandler = (selected) => {
    setSelectedType(selected);
  };

  const onStatusChangedHandler = (selected) => {
    setSelectedStatus(selected);
  };

  const onSelectedQuickFilterChangeHandler = (e) => {
    setSelectedQuickFilter(e.target.value);
    clearSelectedFilters();
  };

  const onSelectedBusinessAreasChangedHandler = (selectedItems) => {
    setSelectedBusinessAreas(selectedItems);
    setDefaultBusinessAreas(selectedItems);
  };

  const clearSelectedFilters = () => {
    setTaskId('');
    setSelectedDate(defaultDateValue);
    setSelectedType(defaultTypeValue);
    setSelectedStatus(defaultStatusValue);
  };

  const renderNoTaskCondition = () => {
    return (
      (!filteredTasks || filteredTasks.length === 0) && (
        <Card className="mb-2">
          <Card.Body>
            <h4 className="mb-0 text-center">There are no tasks to show</h4>
          </Card.Body>
        </Card>
      )
    );
  };

  const badges = {
    Loading: LoadingTaskBadge,
    Unloading: UnloadingTaskBadge
  };

  const customActions = {
    Loading: LoadingCustomActions,
    Unloading: UnloadingCustomActions
  };

  return (
    <Page>
      <Row>
        <Col xs={12} sm={7} md={6}>
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <span style={{ fontSize: '1.75rem', paddingRight: '20px' }}>Tasks</span>
              <QuickFilters
                selectedQuickFilter={selectedQuickFilter}
                onSelectedQuickFilterChange={onSelectedQuickFilterChangeHandler}
              />
            </div>
          </div>
        </Col>
        <Col xs={12} sm={5} md={6}>
          <ButtonStack align="right">
            <PolicyGroupAccessHoc componentName="KPIReport">
              <Button
                variation="secondary"
                title="KPI Report"
                onClick={downloadKpiReport}
                icon={<FontAwesomeIcon icon="file-excel" />}
                className="d-none d-block d-sm-block d-md-block"
                style={{ marginTop: '5px' }}
              >
                {' '}
                KPI Report
              </Button>
            </PolicyGroupAccessHoc>
            <Button
              variation="secondary"
              title="Refresh"
              onClick={clearTasks}
              icon={<FontAwesomeIcon icon="sync" />}
              className="d-block"
            >
              Refresh
            </Button>
            <Button
              variation="primary"
              title="Create Task"
              onClick={() => {
                history.push('/task/new');
              }}
              icon={<FontAwesomeIcon icon="plus" />}
              className="d-block"
            >
              Create
            </Button>
          </ButtonStack>
        </Col>
      </Row>

      <hr />

      <Row>
        <Col>
          <Filters
            taskId={taskId}
            selectedType={selectedType}
            selectedDate={selectedDate}
            selectedStatus={selectedStatus}
            businessAreas={businessAreas}
            selectedBusinessAreas={selectedBusinessAreas}
            onSelectedBusinessAreasChanged={onSelectedBusinessAreasChangedHandler}
            onTypeChanged={onTypeChangedHandler}
            onStatusChanged={onStatusChangedHandler}
            onTaskIdChanged={onTaskIdChangedHandler}
            onDateChanged={onDateChangedHandler}
          />
        </Col>
      </Row>

      <hr />

      {filteredTasks &&
        filteredTasks.map((task) => (
          <Task
            key={task.id}
            task={task}
            badgeArea={badges[task.type]}
            customActions={customActions[task.type]}
          />
        ))}
      {renderNoTaskCondition()}
      <LoadingIndicator
        id="tasks"
        show={!selectedBusinessAreas || selectedBusinessAreas.length === 0}
      />
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    tasks: state.task.tasks,
    filteredTasks: state.task.filteredTasks,
    authenticatedUser: state.auth.authenticatedUser,
    businessAreas: state.task.businessAreas
  };
};
export default connect(mapStateToProps, {
  clearTasks,
  getTasks,
  clearState,
  filterTasks,
  getBusinessAreas,
  downloadKpiReport
})(Tasks);
