import React from 'react';
import { Spinner } from '@simply007org/react-spinners';
import './LoadingIndicator.css';

const LoadingIndicator = ({ id, show }) => {
    return (
        <Spinner name={id} show={show}>
            <div className="spinnerContainer">
                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
        </Spinner>
    );
};

export default LoadingIndicator;