/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getConfiguration,
  saveConfiguration,
  getBusinessAreas
} from '../store/action/slotBookingConfigurationActions';
import 'rc-time-picker/assets/index.css';
import './AddOrEditCalendarRule.css';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import Page from '../component/Page/Page';
import 'react-datepicker/dist/react-datepicker.css';
import SchemaInterpreter from '../component/FormComponents/Schema/SchemaInterpreter';
import moment from 'moment';
import { Row, Col, Card } from 'react-bootstrap';
import { Button, ButtonStack, Container, Column, SelectField } from '@dfds-ui/react-components';
import TimePicker from 'rc-time-picker';
import styled from 'styled-components';
import styles from './AddOrEditSlotBookingConfiguration.module.css';

const AddOrEditSlotBookingConfiguration = (props) => {
  //Redux actions
  const { getConfiguration, saveConfiguration, getBusinessAreas } = props;

  //Redux store
  let { configuration, businessAreas } = props;

  //Other props
  const { match } = props;

  const configurationId = match.params.id;

  const [currentConfig, setCurrentConfig] = useState({});
  const [error, setError] = useState({});

  useEffect(() => {
    if (!businessAreas) {
      getBusinessAreas();
    }
  }, [businessAreas]);
  useEffect(() => {
    if (configurationId != 'new') {
      getConfiguration(configurationId);
    }
  }, [configurationId, getConfiguration]);

  useEffect(() => {
    if (configuration && configurationId != 'new') {
      const newConfiguration = {
        ...configuration,
        cutoffTimeRaw: moment
          .utc()
          .startOf('day')
          .add({ minutes: configuration.cutoffTimeMinutesSinceMidnight }),
        customsTime: moment
          .utc()
          .startOf('day')
          .add({ minutes: configuration.customsClearanceTimeMinutesSinceMidnight })
      };

      setCurrentConfig(newConfiguration);
    }
  }, [configuration]);

  const validation = () => {
    const errorValidation = {};

    if (!currentConfig.businessAreaId) {
      errorValidation.businessAreaId = 'Business Area is mandatory';
    }

    if (!currentConfig.loadingEfficiency) {
      errorValidation.loadingEfficiency = 'Loading Efficiency is mandatory';
    } else if (Number(currentConfig.loadingEfficiency) <= 0) {
      errorValidation.loadingEfficiency = 'Loading Efficiency must be greater than 0';
    }

    if (!currentConfig.unloadingEfficiency) {
      errorValidation.unloadingEfficiency = 'Unloading Efficiency is mandatory';
    } else if (Number(currentConfig.unloadingEfficiency) <= 0) {
      errorValidation.unloadingEfficiency = 'Unloading Efficiency must be greater than 0';
    }

    if (currentConfig.extraTime !== '' && Number(currentConfig.extraTime) <= 0)
      errorValidation.extraTime = 'Extra Time must be greater than 0';

    if (currentConfig.customsHandlingTime !== '' && Number(currentConfig.customsHandlingTime) <= 0)
      errorValidation.customsHandlingTime = 'Customs Handling must be greater than 0';

    if (
      currentConfig.maxTimeIntervalBetweenNextBooking !== '' &&
      Number(currentConfig.maxTimeIntervalBetweenNextBooking) <= 0
    )
      errorValidation.maxTimeIntervalBetweenNextBooking =
        'Time Interval Between Next Booking must be greater than 0';

    if (currentConfig.hasCutoffTime && !currentConfig.cutoffTimeRaw)
      errorValidation.cutoffTime = 'Cutoff Time is mandatory if business area has cutofftime';

    setError(errorValidation);

    return errorValidation;
  };

  const submit = (e) => {
    e.preventDefault();
    if (Object.entries(validation()).length === 0) {
      saveConfiguration(currentConfig);
    }
  };

  const onFieldChangeHandler = (event, newValue, fieldId) => {
    if (fieldId === 'cutoffTimeRaw') {
      setCurrentConfig((prevState) => {
        return {
          ...prevState,
          [fieldId]: newValue,
          cutoffTimeMinutesSinceMidnight: newValue ? newValue.hours() * 60 + newValue.minutes() : 0
        };
      });
    } else if (fieldId === 'hasCutoffTime') {
      setCurrentConfig((prevState) => {
        return {
          ...prevState,
          [fieldId]: newValue,
          cutoffTimeRaw: null,
          cutoffTimeMinutesSinceMidnight: 0
        };
      });
    } else if (fieldId === 'customsTime') {
      setCurrentConfig((prevState) => {
        return {
          ...prevState,
          [fieldId]: newValue,
          customsClearanceTimeMinutesSinceMidnight: newValue
            ? newValue.hours() * 60 + newValue.minutes()
            : 0
        };
      });
    } else {
      setCurrentConfig((prevState) => {
        return {
          ...prevState,
          [fieldId]: newValue
        };
      });
    }
  };

  const renderButtons = () => {
    return (
      <Col>
        <ButtonStack align="right">
          <Button variation="primary" onClick={(e) => submit(e)}>
            Save
          </Button>
        </ButtonStack>
      </Col>
    );
  };

  const StyledTimePicker = styled(TimePicker)`
    height: calc(1.5em + 0.75rem + 2px);
  `;

  return (
    <Page backLink="/booking/configuration" backToWhere="Configurations">
      <Card>
        <Card.Body>
          <Row>
            <Column
              style={{
                padding: '5px'
              }}
            >
              <Row>
                <Col md={6}>
                  <div>
                    <label className={styles.label}>Business Area *</label>
                    {/*  <select selected={currentConfig.businessAreaId} onChange={(e) => onFieldChangeHandler(e, e.target.value, 'businessAreaId')} className={styles.selectfield}> */}

                    <select
                      id="country"
                      value={currentConfig.businessAreaId}
                      onChange={(e) => onFieldChangeHandler(e, e.target.value, 'businessAreaId')}
                      className={styles.selectfield}
                    >
                      <option value="">Select</option>
                      {businessAreas &&
                        businessAreas.map((item) => (
                          <option value={item.value} key={item.value}>
                            {item.text}
                          </option>
                        ))}
                    </select>
                    <small className="form-text text-danger">
                      {error.businessAreaId ? error.businessAreaId : ''}
                    </small>
                  </div>
                </Col>
                <Col md={6}>
                  <div>
                    <label className={styles.label}>Loading Efficiency (min/thu) *</label>
                    <div>
                      <input
                        className={'form-control'}
                        type="number"
                        onKeyDown={(evt) => evt.keyCode === 69 && evt.preventDefault()}
                        value={currentConfig.loadingEfficiency}
                        onChange={(e) =>
                          onFieldChangeHandler(e, e.target.value, 'loadingEfficiency')
                        }
                      ></input>
                    </div>
                    <small className="form-text text-danger">
                      {error.loadingEfficiency ? error.loadingEfficiency : ''}
                    </small>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div>
                    <label className={styles.label}>Unloading Efficiency (min/thu) *</label>
                    <div>
                      <input
                        className={'form-control'}
                        type="number"
                        onKeyDown={(evt) => evt.keyCode === 69 && evt.preventDefault()}
                        value={currentConfig.unloadingEfficiency}
                        onChange={(e) =>
                          onFieldChangeHandler(e, e.target.value, 'unloadingEfficiency')
                        }
                      ></input>
                    </div>
                    <small className="form-text text-danger">
                      {error.unloadingEfficiency ? error.unloadingEfficiency : ''}
                    </small>
                  </div>
                </Col>
                <Col md={6}>
                  <div>
                    <label className={styles.label}>Extra Time-Non Bookable (min)</label>
                    <div>
                      <input
                        className={'form-control'}
                        type="number"
                        onKeyDown={(evt) => evt.keyCode === 69 && evt.preventDefault()}
                        value={currentConfig.extraTime}
                        onChange={(e) => onFieldChangeHandler(e, e.target.value, 'extraTime')}
                      ></input>
                    </div>
                    <small className="form-text text-danger">
                      {error.extraTime ? error.extraTime : ''}
                    </small>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div>
                    <label className={styles.label}>Customs Handling Time (min)</label>
                    <div>
                      <input
                        className={'form-control'}
                        type="number"
                        onKeyDown={(evt) => evt.keyCode === 69 && evt.preventDefault()}
                        value={currentConfig.customsHandlingTime}
                        onChange={(e) =>
                          onFieldChangeHandler(e, e.target.value, 'customsHandlingTime')
                        }
                      ></input>
                    </div>
                    <small className="form-text text-danger">
                      {error.customsHandlingTime ? error.customsHandlingTime : ''}
                    </small>
                  </div>
                </Col>
                <Col md={6}>
                  <div>
                    <label className={styles.label}>Time Interval Between Next Booking (Max)</label>
                    <div>
                      <input
                        className={'form-control'}
                        type="number"
                        onKeyDown={(evt) => evt.keyCode === 69 && evt.preventDefault()}
                        value={currentConfig.maxTimeIntervalBetweenNextBooking}
                        onChange={(e) =>
                          onFieldChangeHandler(
                            e,
                            e.target.value,
                            'maxTimeIntervalBetweenNextBooking'
                          )
                        }
                      ></input>
                    </div>
                    <small className="form-text text-danger">
                      {error.maxTimeIntervalBetweenNextBooking
                        ? error.maxTimeIntervalBetweenNextBooking
                        : ''}
                    </small>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <label className={styles.label}>Has Cutoff Time</label>
                  <Row>
                    <Col
                      md={1}
                      style={{
                        marginTop: '5px'
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={currentConfig.hasCutoffTime}
                        onChange={(e) => onFieldChangeHandler(e, e.target.checked, 'hasCutoffTime')}
                      ></input>
                    </Col>
                    <Col md={11}>
                      <StyledTimePicker
                        disabled={!currentConfig.hasCutoffTime}
                        inputClassName={'form-control'}
                        showSecond={false}
                        format={'HH:mm'}
                        minuteStep={15}
                        value={currentConfig.cutoffTimeRaw}
                        onChange={(e) => onFieldChangeHandler(e, e, 'cutoffTimeRaw')}
                      />
                      <small className="form-text text-danger">
                        {error.cutoffTime ? error.cutoffTime : ''}
                      </small>
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <label className={styles.label}>Customs Clearance Time</label>
                  <StyledTimePicker
                    inputClassName={'form-control'}
                    showSecond={false}
                    format={'HH:mm'}
                    minuteStep={15}
                    value={currentConfig.customsTime}
                    onChange={(e) => onFieldChangeHandler(e, e, 'customsTime')}
                  />
                  <small className="form-text text-danger">
                    {error.customsTime ? error.customsTime : ''}
                  </small>
                </Col>
              </Row>
            </Column>
          </Row>
          <Row>{renderButtons()}</Row>
          <Container fluid></Container>
        </Card.Body>
      </Card>
      <LoadingIndicator id="addOrEditSlotBookingConfiguration" />
    </Page>
  );
};
const mapStateToProps = (state) => {
  return {
    configuration: state.slotBookingConfiguration.configuration,
    configurations: state.slotBookingConfiguration.configurations,
    businessAreas: state.slotBookingConfiguration.businessAreas
  };
};

const mapDispatchToProps = {
  getConfiguration,
  saveConfiguration,
  getBusinessAreas
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOrEditSlotBookingConfiguration);
