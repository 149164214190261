import post, { get } from '../../core/httpClientSync';
import { toast } from 'react-toastify';
import modalTypes from '../type/modal';
import thuDetailTypes from '../type/thuDetailTypes';
import { showThulabel } from './reportActions'

const apiUrl = 'xms-shipment-api';
// const apiUrl = 'http://localhost:5000';

export const clearThuData = () => async dispatch => {

    dispatch({
        type: thuDetailTypes.THU_STATE_CLEARED
    });
}

export const createThu = (thu, spinnerId = null) => async dispatch => {

    const url = `${apiUrl}/api/shipments/add-article`;

    const request = {
        shipmentId: thu.shipmentId,
        externalId: thu.externalId,
        externalCode: thu.externalCode,
        supplierCode: thu.supplierCode,
        deliveryNote: thu.deliveryNote,
        weight: thu.weight,
        volume: thu.volume,
        packageType: thu.packageType,
        actualMeasurement: {
            itemQuantity: {
                value: thu.quantity,
                unit: 'Pallet'
            },
            weight: {
                value: thu.weight,
                unit: 'Kgm'
            },
            volume: {
                unit: 'Cbm',
                value: thu.volume
            },
        }
    }

    const response = await post(url, request, spinnerId, true);

    if (response.isSuccess) {
        toast.success('Thu Created');

        return dispatch({
            type: thuDetailTypes.THU_CREATED,
            payload: response.response.article
        });

    } else {
        if (response.error && response.error.response.data.errors.length > 0) {
            toast.error(response.error.response.data.errors[0].description);
            return response.error.response.data.errors;
        }
        else {
            toast.error('Something went wrong');
            return;
        }
    }
}

export const createAndPrintThuList = (shipmentId, shipmentNo, thuList, spinnerId = null) => async dispatch => {

    const request = {
        shipmentId: shipmentId,
        articleList: thuList.map(thu => (
            {
                shipmentId: thu.shipmentId,
                externalId: thu.externalId,
                externalCode: thu.externalCode,
                supplierCode: thu.supplierCode,
                deliveryNote: thu.deliveryNote,
                packageType: thu.packageType,
                actualMeasurement: {
                    itemQuantity: {
                        value: thu.quantity,
                        unit: 'Pallet'
                    },
                    weight: {
                        value: thu.weight,
                        unit: 'Kgm'
                    },
                    volume: {
                        unit: 'Cbm',
                        value: thu.volume
                    },
                }
            }
        ))
    }

    const url = `${apiUrl}/api/shipments/add-article-list`;
    const response = await post(url, request, spinnerId, true);

    if (response.isSuccess) {
        dispatch({
            type: thuDetailTypes.THU_PRINTED,
            payload: response.response.articleList
        });

        dispatch(showThulabel({ shipmentNo, ArticleList: response.response.articleList }));
    } else {
        if (response.error && response.error.response.data.errors.length > 0) {
            toast.error(response.error.response.data.errors[0].description);
        }
        else {
            toast.error('Something went wrong');
        }
    }
}

export const setPrintThuList = (thuList) => async dispatch => {
    dispatch({
        type: thuDetailTypes.THU_PRINTED,
        payload: thuList
    });
}

export const printThuList = (shipmentNo, thuList) => async dispatch => {

    dispatch({
        type: modalTypes.SHOW_MODAL,
        payload: {
            modalType: 'print-label',
            modalData: { shipmentNo, thuList }
        }
    })
}

export const deleteThu = (id, spinnerId = null) => async dispatch => {

    const url = `${apiUrl}/api/articles/delete/${id}`;
    const response = await post(url, null, spinnerId);
    if (response.isSuccess) {
        toast.success('Thu Deleted');
        dispatch({
            type: thuDetailTypes.THU_DELETED,
            payload: id
        });
    } else {
        toast.error('Something went wrong');
    }
}

export const confirmThu = (id, spinnerId = null) => async dispatch => {

    const url = `${apiUrl}/api/articles/confirm/${id}`;

    const response = await post(url, null, spinnerId, true);
    if (response.isSuccess) {
        dispatch({
            type: thuDetailTypes.THU_CONFIRMED,
            payload: response.response
        });
    }
}

export const getPackageTypeList = () => async dispatch => {

    const packageTypeUrl = `${apiUrl}/api/definitions/packagetypes`;
    const packageTypeResponse = await get(packageTypeUrl);

    if (packageTypeResponse.isSuccess && packageTypeResponse.response) {
        dispatch({
            type: thuDetailTypes.PACKAGETYPE_LISTED,
            payload: packageTypeResponse.response.result
        });
    }
}

export const reverseThuState = (id, spinnerId = null) => async dispatch => {

    const url = `${apiUrl}/api/articles/reverse-article-state/${id}`;
    const response = await post(url, null, spinnerId, true);
    if (response.isSuccess) {
        toast.success('Thu State Changed');

        dispatch({
            type: thuDetailTypes.THU_STATE_REVERSED,
            payload: response.response
        });

    } else {
        toast.error('Something went wrong');
    }

}

export const getThu = (id, spinnerId = null) => async dispatch => {

    const url = `${apiUrl}/api/articles/${id}`;
    const response = await get(url, spinnerId);

    if (response.isSuccess) {

        dispatch({
            type: thuDetailTypes.THU_FOUND,
            payload: response.response.result.article
        });

    }
}

export const update = (request, spinnerId = null) => async dispatch => {

    const url = `${apiUrl}/api/articles/update`;

    const response = await post(url, request, spinnerId);

    if (response.isSuccess) {

        toast.success('Thu Updated');

        dispatch({
            type: thuDetailTypes.THU_UPDATED,
            payload: response.response
        });

    }
    else {
        dispatch({
            type: thuDetailTypes.THU_NOT_EDITED,
            payload: true
        });
    }

}

export const setThuList = (payload) => async dispatch => {

    dispatch({
        type: thuDetailTypes.THU_LISTED,
        payload: payload
    })
}

/** UPDATE THUS */
export function updateThus(payload, processIndicatorId) {
    return async function (dispatch) {
        dispatch(updateThusRequesting());

        const result = await post(`${apiUrl}/api/articles/update-articles`, payload, processIndicatorId);
        if (result.isSuccess) {
            toast.success('Thus Edited');
            dispatch(updateThusSuccess(result.response));
        } else {
            dispatch(updateThusFailure(result.error));
        }
    };
}

function updateThusRequesting() {
    return {
        type: thuDetailTypes.UPDATE_THUS_REQUESTING,
    };
}

function updateThusSuccess(payload) {
    return {
        type: thuDetailTypes.UPDATE_THUS_SUCCESS,
        payload,
    };
}

function updateThusFailure(payload) {
    return {
        type: thuDetailTypes.UPDATE_THUS_FAILURE,
        payload,
    };
}
/** end UPDATE THUS */

export const getShipment = (id) => async dispatch => {

    const url = `${apiUrl}/api/shipments/${id}`;
    const response = await get(url);

    if (response.isSuccess && response.response.result && response.response.result.shipment) {
        dispatch({
            type: thuDetailTypes.SHIPMENT_RECEIVED,
            payload: response.response.result.shipment
        })
    }
}

export const convertToActual = (id, spinnerId = null) => async dispatch => {

    const url = `${apiUrl}/api/articles/convert/${id}`;

    const response = await post(url, null, spinnerId);

    if (response.isSuccess) {

        toast.success('Thu Created');

        return dispatch({
            type: thuDetailTypes.THU_CONVERTED,
            payload: response.response.article
        });
    }
}