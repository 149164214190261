import actionTypes from "./actionTypes";
import { get } from '../../../core/httpClientSync';


const url = 'xms-definition-api/api/settings';


/** READ SHIPMENT SETTINGS */
function readShipmentSettings() {
    return async function (dispatch) {
        dispatch(readShipmentSettingsRequesting());

        const result = await get(`${url}/shipments.column`);

        if (result.isSuccess) {
            dispatch(readShipmentSettingsSuccess(result.response.result));
        } else {
            dispatch(readShipmentSettingsFailure(result.error));
        }
        return result;
    }
};

function readShipmentSettingsRequesting() {
    return {
        type: actionTypes.READ_SHIPMENT_SETTINGS_REQUESTING
    }
}

function readShipmentSettingsSuccess(payload) {
    return {
        type: actionTypes.READ_SHIPMENT_SETTINGS_SUCCESS,
        payload
    }
}

function readShipmentSettingsFailure(payload) {
    return {
        type: actionTypes.READ_SHIPMENT_SETTINGS_FAILURE,
        payload
    }
}
/** end READ SHIPMENT_SETTINGS */

/** READ ARTICLE SETTINGS */
function readArticleSettings() {
    return async function (dispatch) {
        dispatch(readArticleSettingsRequesting());

        const result = await get(`${url}/articles.column`);

        if (result.isSuccess) {
            dispatch(readArticleSettingsSuccess(result.response.result));
        } else {
            dispatch(readArticleSettingsFailure(result.error));
        }
        return result;
    }
};

function readArticleSettingsRequesting() {
    return {
        type: actionTypes.READ_ARTICLE_SETTINGS_REQUESTING
    }
}

function readArticleSettingsSuccess(payload) {
    return {
        type: actionTypes.READ_ARTICLE_SETTINGS_SUCCESS,
        payload
    }
}

function readArticleSettingsFailure(payload) {
    return {
        type: actionTypes.READ_ARTICLE_SETTINGS_FAILURE,
        payload
    }
}
/** end READ ARTICLE_SETTINGS */

/** READ CONTAINER BOOKING SETTINGS */
function readContainerBookingSettings() {
    return async function (dispatch) {
        dispatch(readContainerBookingSettingsRequesting());

        const result = await get(`${url}/containerbookings.column`);

        if (result.isSuccess) {
            dispatch(readContainerBookingSettingsSuccess(result.response.result));
        } else {
            dispatch(readContainerBookingSettingsFailure(result.error));
        }
        return result;
    }
};

function readContainerBookingSettingsRequesting() {
    return {
        type: actionTypes.READ_CONTAINER_BOOKING_SETTINGS_REQUESTING
    }
}

function readContainerBookingSettingsSuccess(payload) {
    return {
        type: actionTypes.READ_CONTAINER_BOOKING_SETTINGS_SUCCESS,
        payload
    }
}

function readContainerBookingSettingsFailure(payload) {
    return {
        type: actionTypes.READ_CONTAINER_BOOKING_SETTINGS_FAILURE,
        payload
    }
}
/** end READ CONTAINER BOOKING SETTINGS */

/** READ SETTINGS */
function readSettings(key) {
    return async function (dispatch) {
        dispatch(readSettingsRequesting());

        const result = await get(`${url}/${key}`);

        if (result.isSuccess) {
            dispatch(readSettingsSuccess(result.response.result));
        } else {
            dispatch(readSettingsFailure(result.error));
        }
        return result;
    }
};

function readSettingsRequesting() {
    return {
        type: actionTypes.READ_SETTINGS_REQUESTING
    }
}

function readSettingsSuccess(payload) {
    return {
        type: actionTypes.READ_SETTINGS_SUCCESS,
        payload
    }
}

function readSettingsFailure(payload) {
    return {
        type: actionTypes.READ_SETTINGS_FAILURE,
        payload
    }
}
/** end READ */


export {
    readShipmentSettings,
    readArticleSettings,
    readContainerBookingSettings,
    readSettings
};