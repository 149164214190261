import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Modal } from 'react-bootstrap';
import { Button } from '@dfds-ui/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { renderToString } from 'react-dom/server';
import ConsignmentNoteReport from '../Reports/ConsignmentNoteReport';
import { styles } from '../Reports/ConsignmentNoteReportStyles.js';

const ModalReport = ({ modalData, onHide }) => {
    const iframeRef = useRef();
    const contRef = useRef();
    const height = window.innerHeight * 3 / 4;

    const toIFrame = (html) => {
        const iframeDoc = iframeRef.current.contentDocument;
        iframeDoc.open();
        iframeDoc.write(`<style>${styles}</style>` + html); // appending nodes instead of writing strings. or assigning the contentDoc to element from ref. document.implementation.createDocument createHTMLDocument
        iframeDoc.close();
    }

    const onClickPrint = () => {
        iframeRef.current.contentWindow.print();
    }

    const hide = () => {
        onHide();
    }

    return (
        <Modal
            show={true}
            onHide={hide}
            centered
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ overflow: "hidden", height: height }}>
                <iframe
                    ref={iframeRef}
                    title="modal-report-iframe"
                    style={{ width: '100%', height: '100%', border: 'none' }}
                >
                </iframe>

                <div ref={contRef} style={{ display: 'none' }}>
                    <ConsignmentNoteReport modalData={modalData} toIFrame={toIFrame}></ConsignmentNoteReport>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={onClickPrint} size="small" variation="primary" >Print <FontAwesomeIcon icon="print" /></Button>
            </Modal.Footer>
        </Modal >
    )
}

export default ModalReport;