import types from '../type/messages';

const initialState = {
    operationList: [],
    isMessageTriggered: false,
    isMessagesTriggered: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.STATE_CLEARED:
            return {
                ...initialState
            }
        case types.OPERATION_RECEIVED:
            return {
                ...state,
                operationList: action.payload.operations
            }
        case types.MESSAGE_TRIGGERING:
            return {
                ...state,
                isMessageTriggered: false
            }
        case types.MESSAGE_TRIGGERED:
            return {
                ...state,
                isMessageTriggered: true
            }
        case types.MESSAGES_TRIGGERING:
            return {
                ...state,
                isMessagesTriggered: false
            }
        case types.MESSAGES_TRIGGERED:
            return {
                ...state,
                isMessagesTriggered: true
            }
        default:
            return state;
    }
}