import { Field } from "redux-form";
import * as PropTypes from "prop-types";
import React from "react";
import { addValidationToArray } from "./Validation/FormValidation";

export const RadioButtonGroupFormComponent = ({ name, options, isRequired = false, validationArray, isDisabled, onChange }) => {
    const validation = addValidationToArray(isRequired, validationArray);
    return (<div className="field-radio-group">
        <Field
            onChange={onChange}
            component={({ input, options, meta: { touched, error, warning }, ...props }) => {
                return (<>
                    {options.map(option => <div className="form-check form-check-inline" key={`${name}-${option.value}`}>
                        <input
                            className='form-check-input'
                            id={`${name}-${option.value}`}
                            disabled={props.disabled}
                            type='radio'
                            {...input}
                            value={option.value}
                            checked={option.value === input.value}
                            style={{width: '30px', height: '30px'}}
                        />
                        <label className='form-check-label' htmlFor={`${name}-${option.value}`}>{option.text}</label>
                    </div>)}
                    {touched && ((error && <span className="text-danger" style={{ display: 'block', marginTop: '-1rem' }}>{error}</span>) || (warning &&
                        <span className="text-warning">{warning}</span>))}
                </>);
            }}
            name={name}
            options={options}
            disabled={isDisabled}
            validate={validation}
        />
    </div>);
};

RadioButtonGroupFormComponent.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
    })),
    name: PropTypes.string.isRequired
};
