import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonStack } from '@dfds-ui/react-components';
import {
    readShipments,
    setFilters,
    setFiltersAux,
    resetAllFilters,
    sortShipments,
} from '../store/ducks/shipments/actionCreators';
import { fetchTypes, searchAssociates, searchConnectionPoints, searchSuffixes } from '../store/ducks/definitions/actionCreators';
import { readShipmentSettings } from '../store/ducks/settings/actionCreators';
import { showReportAction } from '../store/action/reportActions';
import { showModal } from '../store/action/modalActions';
import styles from './Shipments.module.css';
import { replaceWithIndex, getValue } from '../core/utility';
import { epochSecondToLocalDateTime } from '../core/utcToEpochConverter.js';
import { Row, Col, Card } from 'react-bootstrap';
import ModalShipmentsMoreFilters from '../component/Modal/ModalShipmentsMoreFilters';
import LoadingIndicator from "../component/LoadingIndicator/LoadingIndicator";
import PolicyGroupAccessHoc from "./Routes/PolicyGroupAccessHoc";
import { useIfNotNavigatedTo } from '../core/hooks';

function Shipments({
    shipments,
    readShipments,
    setFilters,
    setFiltersAux,
    resetAllFilters,
    sortShipments,
    shipmentSettings,
    readShipmentSettings,
    shipmentStatusTypes,
    articleStatusTypes,
    types,
    fetchTypes,
    connectionPoints,
    searchConnectionPoints,
    suffixes,
    searchSuffixes,
    showModal,
    associates,
    showReportAction,
}
) {
    /** HISTORY, LOCATION, SEARCH PARAMS, URL PARAMS */
    let history = useHistory();
    useIfNotNavigatedTo(["/shipment/"], resetAllFilters);
    /** end HISTORY, LOCATION, SEARCH PARAMS, URL PARAMS */

    /** CREATE */
    const onClickCreate = () => {
        showModal({ modalType: 'add-shipment' });
    }
    /** end CREATE */


    /** READ, read state after component renders */
    useEffect(() => {
        readShipments(shipments.filters.filter(({ Value }) => Value !== ''), 'readShipments');
        fetchTypes();
    }, [shipments.filters, readShipments, fetchTypes]);
    /** end READ */


    /** UPDATE */
    const onClickEdit = () => {
        const checkedItemId = checkedItemIds[0];
        history.push(`/shipments/${checkedItemId}`);
    }
    /** end UPDATE */


    /** DELETE */
    /** end DELETE */


    /** FILTER */
    const [queryLocal, setQueryLocal] = useState([]);
    const [queryLocalAux, setQueryLocalAux] = useState({});
    const [destinationGroupName, setDestinationGroupName] = useState('');
    const [destinationCode, setDestinationCode] = useState('');

    // SET FILTERS STATE AFTER MOUNT, from store
    useEffect(() => {
        setQueryLocal(shipments.filters); // array of objects
        setQueryLocalAux(shipments.filtersAux); // object of objects
    }, [shipments.filters]);

    useEffect(() => {
        if (queryLocal.length > 0) {
            const destinationGroupQuery = queryLocal.find(item => item.Name === 'destination.group.id');
            if (destinationGroupQuery && destinationGroupQuery.Value) {
                const destinationGroupId = destinationGroupQuery.Value;
                const matchedDestinationGroup = connectionPoints.items.find(item => item.id === destinationGroupId);
                if (matchedDestinationGroup) {
                    setDestinationGroupName(matchedDestinationGroup.name);
                }
            }
        }
    }, [queryLocal, connectionPoints]);

    useEffect(() => {
        if (queryLocal.length > 0) {
            const destinationIdQuery = queryLocal.find(item => item.Name === 'destinationId');
            const destinationId = destinationIdQuery.Value;
            const matchedDestination = suffixes.items.find(item => item.id === destinationId);
            if (matchedDestination) {
                setDestinationCode(matchedDestination.code);
            }
        }
    }, [queryLocal, suffixes]);
    // end SET FILTERS STATE AFTER MOUNT, from store

    // VALUE SELECTORS
    const valueSelectorForFilters = (name, operator = '=', arrayOfValues = queryLocal) => {
        const valueFound = arrayOfValues.find(({ Name, Operator }) => Name === name && Operator === operator);
        return (valueFound && valueFound.Value) ? valueFound.Value : ''; // TODO: include 0, '' too
    }

    const valueSelectorForFiltersAux = (name, objectOfValues = queryLocalAux) => objectOfValues[name] || ''; // returns object or empty string
    // end VALUE SELECTORS

    // ON CHANGES
    const onChangeQuery = e => {
        const { id, value, valueAsNumber, checked, type } = e.target;
        const { operator = '=' } = e.target.dataset; // retrieve data-* attribute values
        setQueryLocal(state => {
            return state.map(query => {
                return (query.Name === id && query.Operator === operator) ?
                    {
                        ...query,
                        Value: type && type === 'number' ? valueAsNumber
                            : type && type === 'checkbox' ? checked
                                : value
                    }
                    : query
            });
        }); // similar to the corresponding store reducer
    }

    // onchange destinationId
    // all on change transformer handling goes inside this apart the side effects listed below
    const onChangeTransformer = e => {
        // need to take suffix code or name as input. set helper state of destinationId; destinationCode
        // need to perform search on suffixes with remote call
        // find the suffix id by suffix code from suffixes and set local query state.
        const { id, value } = e.target;
        if (id === 'destination.group.id') {
            setDestinationGroupName(value)
        } else if (id === 'destinationId') {
            setDestinationCode(value);
        }
    }

    useEffect(() => {
        if (destinationGroupName.length > 2) {
            searchConnectionPoints({ query: destinationGroupName });
        }
    }, [destinationGroupName, searchConnectionPoints]);

    useEffect(() => {
        const matchedDestinationGroup = connectionPoints.items.find(item => item.name === destinationGroupName);
        if (matchedDestinationGroup) {
            const destinationGroupId = matchedDestinationGroup.id;
            setQueryLocal(state => state.map(query => query.Name === 'destination.group.id' ? { ...query, Value: destinationGroupId } : query));
        } else {
            setQueryLocal(state => state.map(query => query.Name === 'destination.group.id' ? { ...query, Value: '' } : query)); // CONSIDER: in that case we should not show the input fields with a non existent value and then perform search with no value actually, it is misleading user making them believe the results are the consequence of that field value
        }
    }, [connectionPoints, destinationGroupName]);

    useEffect(() => {
        if (destinationCode.length > 1) {
            searchSuffixes({ query: destinationCode });
        }
    }, [destinationCode, searchSuffixes]);

    // need to take suffix code or name as input. find the suffix id by suffix code from suffixes and set local query state.
    useEffect(() => {
        const matchedDestination = suffixes.items.find(item => item.code === destinationCode);
        if (matchedDestination) {
            const destinationId = matchedDestination.id;
            setQueryLocal(state => state.map(query => query.Name === 'destinationId' ? { ...query, Value: destinationId } : query));
        } else {
            setQueryLocal(state => state.map(query => query.Name === 'destinationId' ? { ...query, Value: '' } : query));
        }
    }, [suffixes, destinationCode]);
    // END onchange destinationId

    const onChangeDateQuery = (key, operator, epochSeconds) => {
        setQueryLocal(state => {
            return state.map(query => (query.Name === key && query.Operator === operator) === true ?
                { ...query, Value: epochSeconds } :
                query
            )
        }); // similar to the corresponding store reducer
    }

    const delimiter = (data) => data && data.code && data.name && data.id ? ', ' : '';

    const onChangeInputDatalist = (event) => {
        event.persist();

        // if the typed/selected text include matches any existing assoc set shipment state fields with those values from found assoc
        const matchedAssoc = associates.items.find(item => {
            return (
                [item.code, item.name, item.id].some((element) => element === event.target.value)
                ||
                `(${item['code']})${delimiter(item)}${item['name']}` === event.target.value
            );
        });

        var id = event.target.name.split(",")[0];
        var obj = event.target.name.split(",")[1];

        if (matchedAssoc) {
            setQueryLocal(state => state.map(query => query.Name === id ? { ...query, Value: matchedAssoc.id } : query));
            setQueryLocalAux({ ...queryLocalAux, [obj]: matchedAssoc });
        } else {
            setQueryLocal(state => state.map(query => query.Name === id ? { ...query, Value: '' } : query));
            setQueryLocalAux({ ...queryLocalAux, [obj]: null });
        }
    }
    // end ON CHANGES

    // More filter
    const [isModalMoreFiltersModalVisible, setIsMoreFiltersModalVisible] = useState(false);

    const onClickMoreFilters = (e) => {
        setIsMoreFiltersModalVisible(true);
    }
    // END More filter

    // Badge for Filters & More filters button
    const [numberOfFilters, setNumberOfFilters] = useState(0);
    const [numberOfMoreFilters, setNumberOfMoreFilters] = useState(0);
    const primaryFilters = ['shipmentNo', 'status', 'articleList.status', 'destination.group.id', 'destinationId'];
    const BASE_VALUES = ['', 0, false, NaN, undefined, null];

    useEffect(() => {
        if (shipments.filters) {
            const allFilters = shipments.filters
                .filter(item => BASE_VALUES.includes(item.Value) === false);
            const number = allFilters.length;
            setNumberOfFilters(number > 0 ? number : '');
            const moreFilters = shipments.filters
                .filter(item => !primaryFilters.includes(item.Name))
                .filter(item => BASE_VALUES.includes(item.Value) === false);
            const numberOfMoreFilters = moreFilters.length;
            setNumberOfMoreFilters(numberOfMoreFilters > 0 ? numberOfMoreFilters : '');
        }
    }, [shipments.filters]);
    // END Badge for Filters & More filters button

    const onSubmitQuery = (e) => {
        e.preventDefault();
        // just commit filters to redux store, and pass it as dependency array in read state from props effect; it will get state itself
        setFilters(queryLocal);
        setFiltersAux(queryLocalAux);
    }

    const onResetQuery = (e) => {
        // resets the form fields by default. if you want to reset the local state directly use e.preventDefault(); and call setQueryLocal() and setQueryLocalAux(), not recommended since it will make the values you use in fields elements TypeError since they will be all undefined you will be trying to reach properties of undefined if you have not handled that in a value selector like function.
        resetAllFilters(); // also reset filters at store then it will reset the filters at local state in turn
    }
    /** end FILTER */


    /** SORT */
    // Client side sort
    const [sortDirection, setSortDirection] = useState('ASC');
    const [sortBy, setSortBy] = useState();

    const onClickSort = (e) => {
        const { sortBy, sortDirection, dataType } = e.target.dataset;
        sortShipments({ sortBy, sortDirection, dataType });
        setSortDirection(state => state === 'ASC' ? 'DESC' : 'ASC');
        setSortBy(sortBy);
    }
    // END Client side sort

    // Server side sort
    // END Server side sort
    /** end SORT */


    /** FUNCTION CONTROLS SECTION */
    const [visibleFunctionControls, setVisibleFunctionControls] = useState('filters');

    const onClickFunctionButton = (e) => {
        if (visibleFunctionControls === e.currentTarget.id) {
            setVisibleFunctionControls(undefined);
        } else {
            setVisibleFunctionControls(e.currentTarget.id);
        }
    }
    /** end FUNCTION CONTROLS SECTION */


    /** VIEW SETTINGS */
    const revision = 2;
    const [defaultViewSettings, setDefaultViewSettings] = useState({ columnOptions: { items: [] }, fontSize: 16, wrap: 'nowrap', persistSettings: true, revision: revision });

    const [viewSettings, setViewSettings] = useState(defaultViewSettings);

    // Force Update View Settings: force update the localStorage by flushing it. Good for making sure your data in users' localStorage is in good shape, can crash app if you made changes to the data structure and your code operating on it. 
    useEffect(() => {
        if (defaultViewSettings.revision !== revision) {
            window.localStorage.removeItem('shipmentViewSettings');
        }
    }, [defaultViewSettings]);

    // Set view settings state column options from persistence or props
    useEffect(() => {
        // if persistSettings true start with the persisted state, else start with default state, the initial state to useState()
        const viewSettingsFromLocalStorage = JSON.parse(window.localStorage.getItem('shipmentViewSettings'));

        if (viewSettingsFromLocalStorage && viewSettingsFromLocalStorage.columnOptions.items.length) {
            // if persisted and persistSettings is true read settings from persistence
            setViewSettings(state => ({ ...state, ...viewSettingsFromLocalStorage, persistSettings: viewSettingsFromLocalStorage.persistSettings }));

        } else if (shipmentSettings.columns) {
            const columnOptionsItems = shipmentSettings.columns.All
                .map(columnOption => ({
                    ...columnOption,
                    isVisible: shipmentSettings.columns.Default.find(defaultColumnOption => defaultColumnOption.Key === columnOption.Key) ? true : false
                }));

            setViewSettings(state => ({ ...state, columnOptions: { ...state.columnOptions, items: columnOptionsItems } }));
        }
    }, [shipmentSettings.columns]);

    // Set default view settings state column options from props
    useEffect(() => {
        if (shipmentSettings.columns) {
            const columnOptionsItems = shipmentSettings.columns.All
                .map(columnOption => ({
                    ...columnOption,
                    isVisible: shipmentSettings.columns.Default.find(defaultColumnOption => defaultColumnOption.Key === columnOption.Key) ? true : false
                }));

            setDefaultViewSettings(state => ({ ...state, columnOptions: { ...state.columnOptions, items: columnOptionsItems } }));
        }
    }, [shipmentSettings.columns]);

    const onChangeViewSetting = (e) => {
        const { id, value, checked } = e.target;
        const { operator, index, increment } = e.target.dataset; // retrieve data-* attribute values

        switch (id) {
            case 'columnVisibility':
                setViewSettings({
                    ...viewSettings,
                    columnOptions: {
                        items: viewSettings.columnOptions.items.map((item, i) => {
                            return i === parseInt(index) ? ({ ...item, isVisible: checked }) : item;
                        })
                    },
                });
                break;
            case 'fontSize':
                setViewSettings({ ...viewSettings, fontSize: viewSettings.fontSize + parseInt(operator) });
                break;
            case 'wrap':
                setViewSettings({ ...viewSettings, [id]: value });
                break;
            case 'resize':
                // setViewSettings({ ...viewSettings, [id]: viewSettings.resize + (parseInt(increment) % 100) });
                break;
            case 'persistSettings':
                setViewSettings({ ...viewSettings, [id]: checked });
                break;
            default: return viewSettings;
        }
    }

    // Persist View Settings onchange
    useEffect(() => {
        if (viewSettings.persistSettings === true) {
            window.localStorage.setItem('shipmentViewSettings', JSON.stringify(viewSettings));
        }
    }, [viewSettings]);

    // always persist the persistSettings setting to persistence
    useEffect(() => {
        window.localStorage.setItem('shipmentViewSettings', JSON.stringify(viewSettings));
    }, [viewSettings.persistSettings]);
    // END Persist View Settings on change

    // Column Options
    const [isColumnOptionsPopoverOpen, setIsColumnOptionsPopoverOpen] = useState(false);

    const toggleColumnOptionsPopover = () => {
        setIsColumnOptionsPopoverOpen(!isColumnOptionsPopoverOpen);
    }

    // Read default shipment column settings from server
    useEffect(() => {
        readShipmentSettings();
    }, [readShipmentSettings]);
    // END Column Options

    const onClickViewSettingsResetToDefaults = (e) => {
        setViewSettings(defaultViewSettings);
    }
    /** end VIEW SETTINGS */


    /** ONCLICK LIST ITEM && CHECK LIST ITEM/S, navigate to item view, checkboxes */
    // delegated event for performance
    const [checkedItemIds, setCheckedItemIds] = useState([]);

    const onClickTableBody = (e) => {
        e.persist();
        const { id } = e.target.dataset;
        if (e.target.type === 'checkbox') {
            onChangeCheckbox(e);
        } else if (id) {
            history.push(`/shipment/${id}`);
        }
    }

    const onChangeCheckbox = (e) => {
        const { id } = e.target.dataset;
        if (e.target.checked) {
            setCheckedItemIds(state => state.includes(id) ? state : state.concat(id)); // check one
        } else {
            setCheckedItemIds(state => state.filter(item => item !== id)); // uncheck one
        }
    }

    const onChangeMasterCheckbox = (e) => {
        e.persist();
        if (e.target.checked) {
            setCheckedItemIds(shipments.items.map(item => item.id)); // check all
        } else {
            setCheckedItemIds([]); // uncheck all
        }
    }
    /** end ONCLICK LIST ITEM && CHECK LIST ITEM/S, navigate to item view, checkboxes */


    /** REPORTS */
    const [reportType, setReportType] = useState('');
    const onChangeReportType = e => {
        setReportType(e.target.value);
    }
    const onSubmitReportType = async e => {
        e.preventDefault();
        showReportAction({ ReportType: reportType, Parameters: { ShipmentIdList: checkedItemIds, Reset: false }, RenderAsPdf: true }, `${reportType} Report`, 'getReports');
    }
    useEffect(() => {
        if (reportType === 'Deviation' && checkedItemIds.length > 1) {
            setReportType('');
        }
    }, [checkedItemIds]);
    /** end REPORTS */


    /** VALIDATION */
    /** end VALIDATION */


    /** PAGINATION */
    /** end PAGINATION */


    /** SUMMARY SECTION */
    const [checkedItemsSummary, setCheckedItemsSummary] = useState({
        actualMeasurement: {
            itemQuantity: 0,
            volume: 0,
            grossWeight: 0,
        },
        expectedMeasurement: {
            itemQuantity: 0,
            volume: 0,
            grossWeight: 0,
        }
    });

    // Calculate accumulated values on checked Items for The Summary Section
    useEffect(() => {
        const accumulator = {
            actualMeasurement: {
                itemQuantity: 0,
                volume: 0,
                grossWeight: 0,
            },
            expectedMeasurement: {
                itemQuantity: 0,
                volume: 0,
                grossWeight: 0,
            }
        };

        const checkedShipmentsSummary = shipments.items
            .filter(item => checkedItemIds.includes(item.id) === true)
            .reduce((acc, item) => {
                return {
                    ...acc,
                    actualMeasurement: {
                        itemQuantity: acc.actualMeasurement.itemQuantity + item.actualMeasurement.itemQuantity.value,
                        volume: acc.actualMeasurement.volume + item.actualMeasurement.volume.value,
                        grossWeight: acc.actualMeasurement.grossWeight + item.actualMeasurement.grossWeight.value,
                    },
                    expectedMeasurement: {
                        itemQuantity: acc.expectedMeasurement.itemQuantity + item.expectedMeasurement.itemQuantity.value,
                        volume: acc.expectedMeasurement.volume + item.expectedMeasurement.volume.value,
                        grossWeight: acc.expectedMeasurement.grossWeight + item.expectedMeasurement.grossWeight.value,
                    },
                };
            }, accumulator);
        setCheckedItemsSummary(checkedShipmentsSummary);
    }, [checkedItemIds, shipments.items]);
    /** end SUMMARY SECTION */


    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col>
                        <div>
                            <header className={styles.header}>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <span style={{ fontSize: '1.75rem', paddingLeft: '10px' }}>Shipments</span>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                        <ButtonStack align='right'>
                                            <PolicyGroupAccessHoc componentName="AddNewShipmentButtonHOC">
                                                <Button
                                                    variation='primary'
                                                    key='create'
                                                    style={{ marginTop: '5px' }}
                                                    onClick={onClickCreate}>
                                                    Create <FontAwesomeIcon icon="plus" />
                                                </Button>
                                            </PolicyGroupAccessHoc>
                                            <Button
                                                variation='secondary'
                                                key='reports'
                                                id='reports'
                                                onClick={onClickFunctionButton}>
                                                Reports <FontAwesomeIcon icon="scroll" />
                                            </Button>
                                            <Button
                                                variation='secondary'
                                                key='view-settings'
                                                id='view-settings'
                                                onClick={onClickFunctionButton}>
                                                Settings <FontAwesomeIcon icon="cog" />
                                            </Button>
                                            {
                                                checkedItemIds.length === 0 &&
                                                <Button
                                                    variation='secondary'
                                                    key='filters'
                                                    id='filters'
                                                    onClick={onClickFunctionButton}>
                                                    {numberOfFilters} Filters <FontAwesomeIcon icon="filter" />
                                                </Button>
                                            }
                                        </ButtonStack>
                                    </div>
                                </div>
                                <hr />
                                {
                                    visibleFunctionControls === 'reports' &&
                                    <Card className={styles.functionControls}>
                                        <Row className={styles.functionSection}>
                                            <Col style={{ width: '100%', padding: '0px' }}>
                                                <form onSubmit={onSubmitReportType} style={{ margin: '0px' }}>
                                                    <Row>
                                                        <Col xs="12" sm="6" md="3" lg="2">
                                                            <label htmlFor="report-select" className={styles.label}>Report</label><br></br>
                                                            <select id="report-select" value={reportType} onChange={onChangeReportType} style={{ padding: 6 }}>
                                                                <option key={""} value={""}>Select Report Type</option>
                                                                <option key={'deviation-report'} value={'Deviation'} disabled={checkedItemIds.length > 1}>Deviation Report</option>
                                                                <option key={'inventory-report'} value={'Inventory'}>Inventory Report</option>
                                                            </select>
                                                        </Col>
                                                        <Col xs="6" sm="3" md="3" lg="2" style={{ display: 'flex', alignItems: 'flex-end' }}>
                                                            <input id="generate-report" type="button" className={!reportType || !checkedItemIds.length ? styles.disabled : styles.secondary} value="Generate" onClick={onSubmitReportType} disabled={!reportType || !checkedItemIds.length} title={reportType && checkedItemIds.length ? `Generate report for selected ${checkedItemIds.length}` : 'Select item(s) and a Report to generate a report'} />
                                                        </Col>
                                                    </Row>
                                                </form>
                                                <small className="form-text text-info">Select item(s) and a Report to generate a report</small>

                                            </Col>
                                        </Row>
                                        <LoadingIndicator id='getReports' />
                                    </Card>
                                }
                                {
                                    visibleFunctionControls === 'filters' &&
                                    <Card className={styles.functionControls}>
                                        <Row className={styles.functionSection}>
                                            <Col style={{ width: '100%', padding: '0px' }}>
                                                <form onSubmit={onSubmitQuery} onReset={onResetQuery} style={{ margin: '0px' }}>
                                                    <Row>
                                                        <Col xs="12" sm="6" md="3" lg="2">
                                                            <label htmlFor="shipmentNo" className={styles.label}>Shipment No</label><br></br>
                                                            <input id="shipmentNo" data-operator="=" value={valueSelectorForFilters('shipmentNo')} onChange={onChangeQuery} autoFocus />
                                                        </Col>

                                                        <Col xs="12" sm="6" md="3" lg="2">
                                                            <label htmlFor="status" className={styles.label}>Status</label><br></br>
                                                            <select id="status" data-operator="=" value={valueSelectorForFilters('status')} onChange={onChangeQuery} style={{ padding: 6 }}>
                                                                <option key={""} value={""}>Select Status</option>
                                                                {
                                                                    shipmentStatusTypes.map((item, i) => <option key={i} value={item.value}>{item.displayName}</option>)
                                                                }
                                                            </select>
                                                        </Col>


                                                        <Col xs="12" sm="6" md="3" lg="2">
                                                            <label htmlFor="articleList.status" className={styles.label}>Artice Status</label><br></br>
                                                            <select id="articleList.status" data-operator="=" value={valueSelectorForFilters('articleList.status')} onChange={onChangeQuery} style={{ padding: 6 }}>
                                                                <option key={""} value={""}>Select Status</option>
                                                                {
                                                                    articleStatusTypes.map((item, i) => <option key={i} value={item.value}>{item.displayName}</option>)
                                                                }
                                                            </select>
                                                        </Col>

                                                        <Col xs="12" sm="6" md="3" lg="2">
                                                            <label htmlFor="destination.group.id" className={styles.label}>Connection Point</label><br></br>
                                                            <input id="destination.group.id" list="destination.group.ids" data-operator="=" value={destinationGroupName} onChange={onChangeTransformer} autoComplete="off" placeholder="Min 3 characters" />
                                                            <datalist id="destination.group.ids">
                                                                {
                                                                    connectionPoints && connectionPoints.items && connectionPoints.items.map((item, i) => <option key={i} value={item.name} />)
                                                                }
                                                            </datalist>
                                                            <br></br>
                                                        </Col>

                                                        <Col xs="12" sm="6" md="3" lg="2">
                                                            <label htmlFor="destinationId" className={styles.label}>Suffix</label><br></br>
                                                            <input id="destinationId" list="destinationIds" data-operator="=" value={destinationCode} onChange={onChangeTransformer} autoComplete="off" placeholder="Min 2 characters" />
                                                            <datalist id="destinationIds">
                                                                {
                                                                    suffixes && suffixes.items && suffixes.items.map((item, i) => <option key={i} value={item.code} />)
                                                                }
                                                            </datalist>
                                                        </Col>

                                                        <Col xs="12" sm="6" md="3" lg="2" style={{ display: 'flex', alignItems: 'flex-end', minWidth: '90px', paddingTop: '10px' }}>
                                                            <input type="button" className={styles.outlined} value={`${numberOfMoreFilters} More`} onClick={onClickMoreFilters} title="More filters" style={{ flexGrow: 1 }} />
                                                        </Col>
                                                    </Row>

                                                    <Row style={{ justifyContent: 'flex-end' }}>
                                                        <Col xs="12" sm="6" md="3" lg="2" style={{ display: 'flex', alignItems: 'flex-end', minWidth: '90px', paddingTop: '10px' }}>
                                                            <input type="reset" className={styles.outlined} value='Reset All' title="Reset all filters" style={{ flexGrow: 1 }} />
                                                        </Col>

                                                        <Col xs="12" sm="6" md="3" lg="2" style={{ display: 'flex', alignItems: 'flex-end', minWidth: '90px', paddingTop: '10px' }}>
                                                            <input type="submit" className={styles.secondary} value='Search' title="Search" style={{ flexGrow: 1 }} />
                                                        </Col>
                                                    </Row>

                                                </form>
                                            </Col>
                                        </Row>
                                    </Card>
                                }
                                {
                                    visibleFunctionControls === 'view-settings' &&
                                    <Card className={styles.functionControls}>
                                        <Row className={styles.functionSection}>
                                            <Col style={{ width: '100%', padding: '0px' }}>
                                                <form style={{ margin: '0px' }}>
                                                    <Row>
                                                        <Col xs="12" sm="6" md="3" lg="3">
                                                            <label htmlFor="columnOptions" className={styles.label}>Column Options</label><br></br>
                                                            <input id="columnOptions" type="button" className={styles.outlined} value={"Column Options"} onClick={toggleColumnOptionsPopover} />
                                                        </Col>

                                                        <Col xs="12" sm="6" md="3" lg="3">
                                                            <label className={styles.label}>Font Size</label><br></br>
                                                            <Col xs="3" sm="4" md="6" lg="6" style={{ display: 'inline-block', paddingLeft: '0px' }}>
                                                                <input type="button" id="fontSize" className={styles.textual} data-operator={-1} value={"A -"} onClick={onChangeViewSetting} title="Decrease font size" />
                                                            </Col>
                                                            <Col xs="3" sm="4" md="6" lg="6" style={{ display: 'inline-block', paddingLeft: '0px' }}>
                                                                <input type="button" id="fontSize" className={styles.textual} data-operator={+1} value={"A +"} onClick={onChangeViewSetting} title="Increase font size" />
                                                            </Col>
                                                        </Col>

                                                        <Col xs="12" sm="6" md="3" lg="3">
                                                            <label htmlFor="persistSettings" className={styles.label}>Autosave Settings</label><br></br>
                                                            <input id="persistSettings" type="checkbox" checked={viewSettings.persistSettings} onChange={onChangeViewSetting} />
                                                        </Col>

                                                        <Col xs="12" sm="6" md="3" lg="3" style={{ display: 'flex', alignItems: 'flex-end' }} >
                                                            <input id="resetToDefaults" type="button" className={styles.primary} value="Reset" onClick={onClickViewSettingsResetToDefaults} title="Reset to defaults" />
                                                        </Col>
                                                    </Row>
                                                </form>
                                            </Col>
                                        </Row>
                                    </Card>
                                }
                                {
                                    isColumnOptionsPopoverOpen && visibleFunctionControls === 'view-settings' &&
                                    <Card className={styles.popover}>
                                        <div
                                            onDragOver={e => {
                                                e.preventDefault();
                                                const dt = e.dataTransfer;
                                                dt.dropEffect = "move";
                                                // TODO: add a feedback in place dragged over
                                            }}
                                            onDrop={(e) => {
                                                const dt = e.dataTransfer;
                                                const oldIndex = dt.getData('text/plain');
                                                const newIndex = e.target.dataset.index;
                                                // change the array that has the column names ordered, that will cause a re-render, you do not have to and better not use DOM methods to appendChild to target or remove from source element
                                                setViewSettings({ ...viewSettings, columnOptions: { items: replaceWithIndex(parseInt(oldIndex), parseInt(newIndex), viewSettings.columnOptions.items) } });
                                                e.preventDefault();
                                            }}
                                        >
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <FontAwesomeIcon icon="times" onClick={toggleColumnOptionsPopover} style={{ cursor: 'pointer' }} />
                                            </div>
                                            {
                                                viewSettings.columnOptions.items.map((item, i) => (
                                                    <div
                                                        key={i}
                                                        data-index={i}
                                                        draggable={true}
                                                        onDragStart={e => {
                                                            const dt = e.dataTransfer;
                                                            dt.setData('text/plain', i);
                                                            dt.dropEffect = "move";
                                                            // dt.effectAllowed = "copy";
                                                            // dt.setDragImage(image, xOffset, yOffset);
                                                        }}
                                                        onDragEnd={e => {
                                                        }}
                                                    >
                                                        <input id="columnVisibility" data-index={i} type="checkbox" checked={item.isVisible} onChange={onChangeViewSetting} />
                                                        <p>{item.Value}</p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </Card>
                                }
                                {
                                    checkedItemIds.length > 0 &&
                                    <Card className={styles.summarySection}>
                                        <div>
                                            <b>{checkedItemIds.length}</b> selected with
                                            <div>
                                                <b>total Expected Measurements</b> of <em>Item Quantity:</em> <b>{checkedItemsSummary.expectedMeasurement.itemQuantity}</b>, <em>Volume:</em> <b>{checkedItemsSummary.expectedMeasurement.volume}</b>, <em>GrossWeight:</em> <b>{checkedItemsSummary.expectedMeasurement.grossWeight}</b>
                                            </div>
                                            <div>
                                                <b>total Actual Measurements</b> of <em>Item Quantity:</em> <b>{checkedItemsSummary.actualMeasurement.itemQuantity}</b>, <em>Volume:</em> <b>{checkedItemsSummary.actualMeasurement.volume}</b>, <em>GrossWeight:</em> <b>{checkedItemsSummary.actualMeasurement.grossWeight}</b>
                                            </div>
                                        </div>
                                    </Card>
                                }
                            </header>





                            <main>
                                {
                                    !shipments.isRequesting && viewSettings.columnOptions.items.length &&
                                    <Table className="xms-table" bordered responsive>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <input
                                                        type="checkbox"
                                                        checked={shipments.items.length && checkedItemIds.length === shipments.items.length}
                                                        onChange={onChangeMasterCheckbox}
                                                    />
                                                </th>
                                                {
                                                    viewSettings.columnOptions.items
                                                        .filter(columnOption => columnOption.isVisible === true)
                                                        .map((columnOption, i) => (
                                                            <th
                                                                key={i}
                                                                data-sort-by={columnOption.Key}
                                                                data-sort-direction={sortDirection}
                                                                data-data-type={shipments.items[0] && typeof getValue(shipments.items[0], columnOption.Key)}
                                                                onClick={onClickSort}
                                                            >
                                                                {columnOption.Value}
                                                                <span style={{ display: (columnOption.Key === sortBy && sortDirection) ? 'initial' : 'none' }}>
                                                                    {sortDirection === 'ASC' ? " 🔼" : " 🔽"}
                                                                </span>
                                                            </th>
                                                        ))
                                                }
                                            </tr>
                                        </thead>
                                        <tbody onClick={onClickTableBody}>
                                            {
                                                shipments.items.map((shipment) => {
                                                    return (
                                                        <tr
                                                            key={shipment.id}
                                                            data-id={shipment.id}
                                                        >
                                                            <td
                                                                data-id={shipment.id}
                                                                data-shipment-no={shipment.shipmentNo}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    data-id={shipment.id}
                                                                    checked={checkedItemIds.includes(shipment.id)}
                                                                    onChange={onChangeCheckbox}
                                                                />
                                                            </td>
                                                            {
                                                                viewSettings.columnOptions.items
                                                                    .filter(columnOption => columnOption.isVisible === true)
                                                                    .map((columnOption, i) => (
                                                                        <td
                                                                            key={i}
                                                                            data-id={shipment.id}
                                                                            data-shipment-no={shipment.shipmentNo}
                                                                            style={{ fontSize: viewSettings.fontSize, color: shipment.logicalDeleteKey ? "#f2dede" : null }}
                                                                        >
                                                                            {
                                                                                columnOption.Key.search('Date') !== -1 ?
                                                                                    (getValue(shipment, columnOption.Key) > 0 ?
                                                                                        epochSecondToLocalDateTime(getValue(shipment, columnOption.Key)).toLocaleString() : '') :
                                                                                    getValue(shipment, columnOption.Key).toString()
                                                                            }
                                                                        </td>
                                                                    ))
                                                            }
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr className={styles.tr}>
                                                <td colSpan={viewSettings.columnOptions.items.filter(item => item.isVisible === true).length + 1}>{shipments.isRequesting ? 'Requesting' : `Showing ${shipments.items.length} items`}</td>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                }
                            </main>

                            <ModalShipmentsMoreFilters
                                showModal={isModalMoreFiltersModalVisible}
                                onHide={() => setIsMoreFiltersModalVisible(false)}
                                modalData={{ valueSelectorForFilters, valueSelectorForFiltersAux, onChangeQuery, onChangeDateQuery, onChangeInputDatalist, types, associates, onSubmitQuery }}
                            >
                            </ModalShipmentsMoreFilters>
                        </div>

                    </Col>
                </Row>
            </Card.Body>
            <LoadingIndicator id="readShipments" show={shipments.isRequesting} />
        </Card >
    );
}

const mapStateToProps = ({ shipmentsOverview, definitions, settings }) => {
    return {
        shipments: shipmentsOverview,
        shipmentSettings: settings.shipmentSettings,
        // remove this one since there is types with everything needed
        shipmentStatusTypes: (() => {
            const x = definitions.types.items.find(item => item.type === "ShipmentStatusType");
            return x ? x.values : [];
        })(), // NEED: We need proper redux style selectors
        articleStatusTypes: (() => {
            const x = definitions.types.items.find(item => item.type === "ArticleStatusType");
            return x ? x.values : [];
        })(), // NEED: We need proper redux style selectors
        types: definitions.types,
        associates: definitions.associates,
        connectionPoints: definitions.connectionPoints,
        suffixes: definitions.suffixes,
    };
}

const mapDispatchToProps = {
    readShipments,
    setFilters,
    setFiltersAux,
    resetAllFilters,
    showModal,
    sortShipments,
    readShipmentSettings,
    fetchTypes,
    searchAssociates,
    searchConnectionPoints,
    searchSuffixes,
    showReportAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(Shipments);