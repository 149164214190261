import React from 'react';
import { connect } from 'react-redux';
import { resetAllFilters } from '../../store/ducks/shipments/actionCreators';
import styles from '../../RoutedComponents/ContainerBookings.module.css';
import { Modal, Row, Col } from 'react-bootstrap';
import { localDateTimeToEpochSecond, epochSecondToLocalDateTime } from '../../core/utcToEpochConverter.js';
import DatePicker from "react-datepicker";
import { searchAssociates } from '../../store/ducks/definitions/actionCreators';

function ModalContainerBookingsMoreFilters({
    showModal = true,
    onHide,
    modalData,
    resetAllFilters
}) {
    const onSubmit = (e) => {
        modalData.onSubmitQuery(e);
        onHide();
    }

    const onReset = (e) => {
        // more filters are inside a form, so use reset event handler. reset more filters in redux store
    }

    const onResetAll = (e) => {
        // reset both more filters form and the primary filters form and redux store
        resetAllFilters();
    }

    const onClickHide = (e) => {
        onHide();
    }

    /* FORMAT */
    /* end FORMAT */

    return (
        <Modal show={showModal} onHide={onClickHide} size="xl" centered>
            <form onSubmit={onSubmit} onReset={onReset}>
                <Modal.Header closeButton>
                    <Col>
                        <Row>
                            <Col>
                                <Modal.Title>More filters</Modal.Title>
                            </Col>
                        </Row>
                    </Col>
                </Modal.Header>

                <Modal.Body>
                    <Col>
                        <Row>
                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="bookingReference" className={styles.label}>Booking Reference</label><br></br>
                                <input id='bookingReference' value={modalData.valueSelectorForFilters('bookingReference')} onChange={modalData.onChangeQuery} autoComplete="off" /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="customerReference" className={styles.label}>Customer Reference</label><br></br>
                                <input id='customerReference' value={modalData.valueSelectorForFilters('customerReference')} onChange={modalData.onChangeQuery} autoComplete="off" /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="loadingType" className={styles.label}>Loading Type</label><br></br>
                                <select id="loadingType" value={modalData.valueSelectorForFilters('loadingType')} onChange={modalData.onChangeQuery} style={{ padding: 6 }}>
                                    <option key={""} value={""}>Please select one</option>
                                    <option key={"FCL"} value={"FCL"}>FCL</option>
                                    <option key={"LCL"} value={"LCL"}>LCL</option>
                                </select>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="vgmCutoffDateStart" className={styles.label}>VGM Cutoff Date Start</label><br></br>
                                <DatePicker
                                    id="vgmCutoffDateStart"
                                    selected={modalData.valueSelectorForFilters('vgmCutoffDate', '>=') ? epochSecondToLocalDateTime(modalData.valueSelectorForFilters('vgmCutoffDate', '>=')) : ''}
                                    onChange={(dateTime) => modalData.onChangeDateQuery('vgmCutoffDate', ">=", dateTime ? localDateTimeToEpochSecond(dateTime) : '')}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    todayButton="Today"
                                    type='text'
                                    dropdownMode="select"
                                />
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="vgmCutoffDateEnd" className={styles.label}>VGM Cutoff Date End</label><br></br>
                                <DatePicker
                                    id="vgmCutoffDateEnd"
                                    selected={modalData.valueSelectorForFilters('vgmCutoffDate', '<=') ? epochSecondToLocalDateTime(modalData.valueSelectorForFilters('vgmCutoffDate', '<=')) : ''}
                                    onChange={(dateTime) => modalData.onChangeDateQuery('vgmCutoffDate', "<=", dateTime ? localDateTimeToEpochSecond(dateTime) : '')}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    todayButton="Today"
                                    type='text'
                                    dropdownMode="select"
                                />
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="emptyReleaseDateStart" className={styles.label}>Empty Release Date Start</label><br></br>
                                <DatePicker
                                    id="emptyReleaseDateStart"
                                    selected={modalData.valueSelectorForFilters('emptyReleaseDate', '>=') ? epochSecondToLocalDateTime(modalData.valueSelectorForFilters('emptyReleaseDate', '>=')) : ''}
                                    onChange={(dateTime) => modalData.onChangeDateQuery('emptyReleaseDate', ">=", dateTime ? localDateTimeToEpochSecond(dateTime) : '')}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    todayButton="Today"
                                    type='text'
                                    dropdownMode="select"
                                />
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="emptyReleaseDateEnd" className={styles.label}>Empty Release Date End</label><br></br>
                                <DatePicker
                                    id="emptyReleaseDateEnd"
                                    selected={modalData.valueSelectorForFilters('emptyReleaseDate', '<=') ? epochSecondToLocalDateTime(modalData.valueSelectorForFilters('emptyReleaseDate', '<=')) : ''}
                                    onChange={(dateTime) => modalData.onChangeDateQuery('emptyReleaseDate', "<=", dateTime ? localDateTimeToEpochSecond(dateTime) : '')}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    todayButton="Today"
                                    type='text'
                                    dropdownMode="select"
                                />
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="loadedReturnDateStart" className={styles.label}>Loaded Return Date Start</label><br></br>
                                <DatePicker
                                    id="loadedReturnDateStart"
                                    selected={modalData.valueSelectorForFilters('loadedReturnDate', '>=') ? epochSecondToLocalDateTime(modalData.valueSelectorForFilters('loadedReturnDate', '>=')) : ''}
                                    onChange={(dateTime) => modalData.onChangeDateQuery('loadedReturnDate', ">=", dateTime ? localDateTimeToEpochSecond(dateTime) : '')}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    todayButton="Today"
                                    type='text'
                                    dropdownMode="select"
                                />
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="loadedReturnDateEnd" className={styles.label}>Loaded Return Date End</label><br></br>
                                <DatePicker
                                    id="loadedReturnDateEnd"
                                    selected={modalData.valueSelectorForFilters('loadedReturnDate', '<=') ? epochSecondToLocalDateTime(modalData.valueSelectorForFilters('loadedReturnDate', '<=')) : ''}
                                    onChange={(dateTime) => modalData.onChangeDateQuery('loadedReturnDate', "<=", dateTime ? localDateTimeToEpochSecond(dateTime) : '')}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    todayButton="Today"
                                    type='text'
                                    dropdownMode="select"
                                />
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="voyage.vesselName" className={styles.label}>Vessel Name</label><br></br>
                                <input id='voyage.vesselName' value={modalData.valueSelectorForFilters('voyage.vesselName')} onChange={modalData.onChangeQuery} autoComplete="off" /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="voyage.voyageName" className={styles.label}>Voyage Name</label><br></br>
                                <input id='voyage.voyageName' value={modalData.valueSelectorForFilters('voyage.voyageName')} onChange={modalData.onChangeQuery} autoComplete="off" /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="voyage.departureLocation" className={styles.label}>Departure Location</label><br></br>
                                <input id='voyage.departureLocation' value={modalData.valueSelectorForFilters('voyage.departureLocation')} onChange={modalData.onChangeQuery} autoComplete="off" /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="voyage.arrivalLocation" className={styles.label}>Arrival Location</label><br></br>
                                <input id='voyage.arrivalLocation' value={modalData.valueSelectorForFilters('voyage.arrivalLocation')} onChange={modalData.onChangeQuery} autoComplete="off" /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="voyage.departureDateStart" className={styles.label}>Departure Date Start</label><br></br>
                                <DatePicker
                                    id="voyage.departureDateStart"
                                    selected={modalData.valueSelectorForFilters('voyage.departureDate', '>=') ? epochSecondToLocalDateTime(modalData.valueSelectorForFilters('voyage.departureDate', '>=')) : ''}
                                    onChange={(dateTime) => modalData.onChangeDateQuery('voyage.departureDate', ">=", dateTime ? localDateTimeToEpochSecond(dateTime) : '')}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    todayButton="Today"
                                    type='text'
                                    dropdownMode="select"
                                />
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="voyage.departureDateEnd" className={styles.label}>Departure Date End</label><br></br>
                                <DatePicker
                                    id="voyage.departureDateEnd"
                                    selected={modalData.valueSelectorForFilters('voyage.departureDate', '<=') ? epochSecondToLocalDateTime(modalData.valueSelectorForFilters('voyage.departureDate', '<=')) : ''}
                                    onChange={(dateTime) => modalData.onChangeDateQuery('voyage.departureDate', "<=", dateTime ? localDateTimeToEpochSecond(dateTime) : '')}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    todayButton="Today"
                                    type='text'
                                    dropdownMode="select"
                                />
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="voyage.arrivalDateStart" className={styles.label}>Arrival Date Start</label><br></br>
                                <DatePicker
                                    id="voyage.arrivalDateStart"
                                    selected={modalData.valueSelectorForFilters('voyage.arrivalDate', '>=') ? epochSecondToLocalDateTime(modalData.valueSelectorForFilters('voyage.arrivalDate', '=>')) : ''}
                                    onChange={(dateTime) => modalData.onChangeDateQuery('voyage.arrivalDate', ">=", dateTime ? localDateTimeToEpochSecond(dateTime) : '')}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    todayButton="Today"
                                    type='text'
                                    dropdownMode="select"
                                />
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="voyage.arrivalDateEnd" className={styles.label}>Arrival Date End</label><br></br>
                                <DatePicker
                                    id="voyage.arrivalDateEnd"
                                    selected={modalData.valueSelectorForFilters('voyage.arrivalDate', '<=') ? epochSecondToLocalDateTime(modalData.valueSelectorForFilters('voyage.arrivalDate', '<=')) : ''}
                                    onChange={(dateTime) => modalData.onChangeDateQuery('voyage.arrivalDate', "<=", dateTime ? localDateTimeToEpochSecond(dateTime) : '')}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    todayButton="Today"
                                    type='text'
                                    dropdownMode="select"
                                />
                            </Col>

                            <Col></Col>

                        </Row>
                    </Col>
                </Modal.Body>

                <Modal.Footer>
                    <Col>
                        <Row>

                            <Col></Col>
                            <Col></Col>

                            <Col xs="12" sm="6" md="6" lg="3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <input type="submit" value='Filter' />
                            </Col>

                        </Row>
                    </Col>
                </Modal.Footer>
            </form>
        </Modal >
    );
}


const mapDispatchToProps = {
    resetAllFilters,
    searchAssociates,
};

export default connect(undefined, mapDispatchToProps)(ModalContainerBookingsMoreFilters);
