/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import PageHeader from '../component/PageHeader/PageHeader';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import { getStatusList, getShipmentList, saveShipmentsToUnit, clearShipmentsState, getGroupList, getOperation } from '../store/action/shipmentsAction';
import List from '../component/Shipments/list/List.js';
import { toast } from 'react-toastify';
import StatusFilter from '../component/Shipments/filters/StatusFilter';
import ThuFilter from '../component/Shipments/filters/ThuFilter';
import OperationSummary from '../component/Operation/Summary';
import { InputDatalist } from '../component/InputDatalist/InputDatalist';
import { searchSuffixes, searchConnectionPoints } from '../store/ducks/definitions/actionCreators';
import { Button, Radio } from '@dfds-ui/react-components';
import { TYPES as SHIPMENTTYPE } from '../constants/shipment/shipment.js'
const Shipments = (props) => {

  //parent
  const { type, operationId } = useParams();

  //redux props
  const {
    suffixes,
    connectionPoints,
    statusList,
    shipmentList,
    groupList,
    operation
  } = props;

  //redux actions
  const {
    searchSuffixes,
    searchConnectionPoints,
    getStatusList,
    getShipmentList,
    saveShipmentsToUnit,
    clearShipmentsState,
    getGroupList,
    getOperation,
  } = props;

  const WaitingPickConstant = "WaitingPick";
  const PickedConstant = "Picked";

  const [selectedSuffixName, setSelectedSuffixName] = useState("");
  const [serialNumberTimeout, setSerialNumberTimeout] = useState();
  const [partNumberTimeout, setPartNumberTimeout] = useState();
  const [selectedSuffixList, setSelectedSuffixList] = useState([]);
  const [selectedConnectionPoint, setSelectedConnectionPoint] = useState("");
  const [selectedConnectionPointObject, setSelectedConnectionPointObject] = useState({});
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(WaitingPickConstant);
  const [selectedFilteredThuNumbers, setSelectedFilteredThuNumbers] = useState([]);
  const [criteria, setCriteria] = useState();
  const [inputValue, setInputValue] = useState('');
  const [partNumberValue, setPartNumberValue] = useState('');
  const [showPartNumberInput, setShowPartNumberInput] = useState(true);
  const [currentSuffixes, setCurrentSuffixes] = useState([]);
  const [selectedShipmentType, setSelectedShipmentType] = useState(SHIPMENTTYPE.ARRIVAL);
  const [filteredData, setFilteredData] = useState();

  const pageTypeEnum = {
    inStock: type == 1,
    waitingPick: type == 2 && selectedStatus == WaitingPickConstant,
    picked: type == 2 && selectedStatus == PickedConstant
  };

  const CONSTANTS = {
    SUMMARYLOADER: 'loader-operation-summary',
    LISTLOADER: 'loader-operation-list',
  }

  const header = () => {
    let header = `${operationId} - `
    if (pageTypeEnum.inStock) {
      header += 'In Stock'
    }
    if (pageTypeEnum.waitingPick) {
      header += 'Unpicked'
    }
    if (pageTypeEnum.picked) {
      header += 'Picked'
    }
    return header;
  }

  const pageOptions = {
    header: header(),
    buttonText: pageTypeEnum.inStock ? "Save" : pageTypeEnum.waitingPick ? "Set Picked" : "Set Unpicked",
    showConnectionPoint: pageTypeEnum.inStock,
    showSuffix: pageTypeEnum.inStock,
    showFilterButton: pageTypeEnum.inStock,
    showShipmentType: pageTypeEnum.inStock,
    showGroup: pageTypeEnum.inStock,
    showStatus: pageTypeEnum.waitingPick || pageTypeEnum.picked,
    showFilteredThuNumbers: pageTypeEnum.waitingPick || pageTypeEnum.picked,
    showSearch: pageTypeEnum.inStock,
    showSupplierField: pageTypeEnum.inStock,
    showWeightField: pageTypeEnum.inStock,
    showVolumeField: pageTypeEnum.inStock,
    showPartField: pageTypeEnum.inStock,
    showQuantityField: pageTypeEnum.inStock
  };

  useEffect(() => {
    clearShipmentsState();

    if (pageTypeEnum.waitingPick || pageTypeEnum.picked) {
      getStatusList();
    }
  }, []);

  useEffect(() => {
    if (operation) {
      getGroupList(operation.groupIdList)
    }
  }, [operation])

  useEffect(() => {
    if (suffixes) {
      setCurrentSuffixes(suffixes);
    }
  }, [suffixes])

  useEffect(() => {
    resetInputs();
    if (pageTypeEnum.inStock) {
      getOperation(operationId, CONSTANTS.SUMMARYLOADER);
    }
  }, [shipmentList]);

  useEffect(() => {
    if (groupList && groupList.length === 1) {
      setSelectedGroup(groupList[0].id);
    }
  }, [groupList]);

  useEffect(() => {
    if (!pageTypeEnum.inStock) return;
    setCriteria({
      pageTypeEnum: pageTypeEnum,
      suffixList: selectedSuffixList,
      shipmentType: selectedShipmentType,
    });
  }, [selectedSuffixList, selectedConnectionPointObject])

  useEffect(() => {
    if (pageTypeEnum.inStock) return;

    setCriteria({
      pageTypeEnum: pageTypeEnum,
      status: selectedStatus,
      operationId: operationId
    });

    getShipmentList({
      pageTypeEnum: pageTypeEnum,
      status: selectedStatus,
      operationId: operationId
    }, CONSTANTS.LISTLOADER);

  }, [selectedStatus]);

  const resetInputs = () => {
    setInputValue('');
    setPartNumberValue('');
  }

  const onSuffixChange = (event) => {
    if (event.target.value) {
      setSelectedSuffixName(event.target.value);
      console.log("selectedSuffixName: ", selectedSuffixName);
      const selectedSuffix = currentSuffixes.items.find(item => item.code === event.target.value);
      if (selectedSuffix)
        setSelectedSuffixList([selectedSuffix.id]);
    } else {
      setSelectedSuffixName("");
      setSelectedSuffixList([]);
    }
  }

  // set the suffix id when the suffix filter fields changes and the suffix.items redux store changes
  useEffect(() => {
    const selectedSuffix = suffixes.items.find(item => item.code === selectedSuffixName);
    if (selectedSuffix) {
      setSelectedSuffixList([selectedSuffix.id]);
    }
  }, [selectedSuffixName, suffixes.items]);

  const onConnectionPointChange = (event) => {
    setSelectedSuffixName("");
    setSelectedConnectionPoint(event.target.value);
    if (event.target.value == "") {
      setSelectedConnectionPointObject({});
    } else {
      const filteredConnectionPoint = connectionPoints.items.filter(x => String(x.name).startsWith(event.target.value.toUpperCase()));
      if (filteredConnectionPoint.length > 0) {
        var connectionPoint = filteredConnectionPoint[0];
        setSelectedConnectionPointObject(connectionPoint);
        var connectionPointSuffixList = connectionPoint.destinations.map(item => item.id);
        setSelectedSuffixList(connectionPointSuffixList);
      }
    }
  };

  useEffect(() => {
    if (selectedConnectionPointObject.destinations) {
      setCurrentSuffixes({ items: selectedConnectionPointObject.destinations });
    }
  }, [selectedConnectionPointObject]);

  const onGroupChange = (event) => {
    setSelectedGroup(event.target.value)
  }

  const onStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  }

  const onShipmentTypeChange = (event) => {
    setSelectedShipmentType(event.target.value);
    setCriteria({ ...criteria, shipmentType: event.target.value })
  }

  const onFilteredThuNumbersChange = (event) => {

    if (serialNumberTimeout) { clearTimeout(serialNumberTimeout) };

    var value = event.target.value.trim().replaceAll('"', '').replaceAll(',', '').replaceAll('”', '').replaceAll('“', '');
    setInputValue(value);

    const newTimeout = setTimeout(() => {

      var list = value == "" ? [] : value.split(/\r\n|\r|\n/g);

      if (selectedFilteredThuNumbers.indexOf(value) > -1) {
        resetInputs();
        toast.error(value + ' Scanned Twice');
        return false;
      }

      if (shipmentList.flatMap(s => s.articleList).map(s => s.externalId).indexOf(value) < 0) {
        resetInputs();
        toast.error(value + ' Not Found');
        return false;
      }

      if (shipmentList.flatMap(s => s.articleList).filter(x => x.externalId === value).length > 1) {
        console.log("duplicate record found please scan part number of current thu");
        setShowPartNumberInput(false);
        return false;
      }

      shipmentList.flatMap(s => s.articleList).filter(s => s.externalId == value)[0].isChecked = true;

      setSelectedFilteredThuNumbers(list);
      saveShipments();

    }, 150)

    setSerialNumberTimeout(newTimeout);
  }

  const onChangeThuPartNumber = (event) => {
    if (partNumberTimeout) { clearTimeout(partNumberTimeout) };

    var value = event.target.value.trim().replaceAll('"', '').replaceAll(',', '').replaceAll('”', '').replaceAll('“', '');
    setPartNumberValue(value);

    const newTimeout = setTimeout(() => {

      var thu = shipmentList.flatMap(s => s.articleList).filter(s => s.externalId === inputValue && s.externalCode === value);

      if (thu !== undefined && thu.length > 0) {

        thu[0].isChecked = true;

        setShowPartNumberInput(true);
        saveShipments();
      }
      else {
        resetInputs();
        toast.error(value + ' Not Found');
        return false;
      }
    }, 150)

    setPartNumberTimeout(newTimeout);
  }

  const handleFilter = () => {
    if (criteria) {
      getShipmentList(criteria, CONSTANTS.LISTLOADER);
      setFilteredData(criteria);
    }
  }

  const saveShipments = () => {

    if (shipmentList == null || shipmentList.length == 0) {
      toast.error('Please Select Any Item');
      return;
    }

    var selectedThusList = shipmentList.flatMap(s => s.articleList).filter(s => s.isChecked).map(getThuFields);

    if (selectedThusList.length === 0) {
      toast.error('Please Select Any Item');
      return;
    }

    const params = {
      operationId: operationId,
      groupId: selectedGroup,
      articleList: selectedThusList,
      pageTypeEnum: pageTypeEnum,
      shipmentType: filteredData && filteredData.shipmentType,
      status: selectedStatus
    }

    saveShipmentsToUnit(params, CONSTANTS.LISTLOADER);
  }

  const getThuFields = (thu) => {
    const fields = {
      externalId: thu.externalId,
      externalCode: thu.externalCode,
      id: thu.id
    };
    return fields;
  }

  const renderConnectionPointFilter = () => {
    if (!pageOptions.showConnectionPoint) {
      return null;
    }
    return (
      <Col lg={3}>
        <div className="from-group">
          <label style={{ padding: '15px', paddingBottom: '7px' }}>Connection Point</label>
          <div style={{ paddingLeft: '15px' }}>
            <InputDatalist
              value={selectedConnectionPoint}
              onChange={onConnectionPointChange}
              fetchDataFunction={searchConnectionPoints}
              isRequesting={connectionPoints.isRequesting}
              data={connectionPoints.items}
              renderDatalistOptions={(item, i) => <option value={item["name"]} key={i} />}
              visualSize={"small"}
            />
          </div>
        </div>
      </Col>
    )
  }

  const renderSuffixFilter = () => {
    if (!pageOptions.showSuffix) {
      return null;
    }

    return (
      <Col lg={3}>
        <div className="from-group">
          <label style={{ padding: '15px', paddingBottom: '7px' }}>Suffix</label>
          <div style={{ paddingLeft: '15px' }}>
            <InputDatalist
              value={selectedSuffixName}
              onChange={onSuffixChange}
              datalistId="dlist"
              fetchDataFunction={searchSuffixes}
              isRequesting={suffixes.isRequesting}
              data={currentSuffixes.items}
              renderDatalistOptions={(item, i) => <option value={item["name"]} key={i} />}
              constantFetchParam={selectedConnectionPointObject}
            />
          </div>
        </div>
      </Col>
    )
  }

  const renderShipmentTypeFilter = () => {
    if (!pageOptions.showShipmentType) {
      return null;
    }

    return (
      <Col lg={3}>
        <div className="from-group">
          <label style={{ padding: '15px', paddingBottom: '7px' }}>Type</label>
          <Row style={{ paddingLeft: '15px', marginTop: '10px' }}>
            <Col>
              <Radio name="shipmentType" value={SHIPMENTTYPE.ARRIVAL} checked={selectedShipmentType === SHIPMENTTYPE.ARRIVAL} onChange={onShipmentTypeChange}>{SHIPMENTTYPE.ARRIVAL}</Radio>
            </Col>
            <Col>
              <Radio name="shipmentType" value={SHIPMENTTYPE.DELIVERY} checked={selectedShipmentType === SHIPMENTTYPE.DELIVERY} onChange={onShipmentTypeChange}>{SHIPMENTTYPE.DELIVERY}</Radio>
            </Col>
          </Row>
        </div>
      </Col>
    )
  }

  const renderFilterButton = () => {
    if (!pageOptions.showFilterButton) {
      return null;
    }
    return (
      <Col lg={3} className="align-self-flex-end text-right">
        <div className="from-group">
          <Button
            // title="Filter"
            variation="primary"
            onClick={handleFilter}
            className='mr-2'

          >Filter</Button>
        </div>
      </Col>
    )
  }

  return (<>
    <Row>
      <Col>
        <PageHeader text={pageOptions.header} />
      </Col>
    </Row>
    <Row>
      <Col>
        <OperationSummary operation={operation} show={pageTypeEnum.inStock} loader={CONSTANTS.SUMMARYLOADER} />
      </Col>
    </Row>
    <Card>
      <Card.Body>
        <Row>
          {renderConnectionPointFilter()}
          {renderSuffixFilter()}
          {renderShipmentTypeFilter()}
          {renderFilterButton()}

          <Col lg={6} sm={12}>
            <div className="form-group">
              <StatusFilter list={statusList} value={selectedStatus} show={pageOptions.showStatus} onChange={onStatusChange} />
            </div>
          </Col>

        </Row>

        <Row>
          <Col>
            <div className="form-group">
              <ThuFilter
                value={inputValue}
                show={pageOptions.showFilteredThuNumbers}
                onChange={onFilteredThuNumbersChange}
                partNumberValue={partNumberValue}
                showPartNumber={showPartNumberInput}
                onChangePartNumber={onChangeThuPartNumber}>
              </ThuFilter>
            </div>
          </Col>

        </Row>
      </Card.Body>
    </Card>

    <Row className="mt-3">
      <Col>
        <LoadingIndicator id={CONSTANTS.LISTLOADER} hide />

        <List
          shipmentList={shipmentList}
          selectedGroup={selectedGroup}
          onClick={saveShipments}
          groupList={groupList || []}
          pageOptions={pageOptions}
          onGroupChange={onGroupChange}
        />

      </Col>
    </Row>
  </>)
}

const mapStateToProps = ({ shipments, definitions }) => {
  return {
    suffixes: definitions.suffixes,
    connectionPoints: definitions.connectionPoints,
    statusList: shipments.statusList,
    shipmentList: shipments.shipmentList,
    groupList: shipments.groupList,
    operation: shipments.operation
  }
}

// TODO: tidy up: mapDispatchToProps

export default connect(mapStateToProps, { searchSuffixes, searchConnectionPoints, getStatusList, getShipmentList, saveShipmentsToUnit, clearShipmentsState, getGroupList, getOperation })(Shipments);