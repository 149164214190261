import React from 'react';
import { connect } from 'react-redux';
import { resetAllFilters } from '../../store/ducks/shipments/actionCreators';
import styles from '../../RoutedComponents/Shipments.module.css';
import { Modal, Row, Col } from 'react-bootstrap';
import { localDateTimeToEpochSecond, epochSecondToLocalDateTime } from '../../core/utcToEpochConverter.js';
import DatePicker from "react-datepicker";
import { InputDatalist } from '../InputDatalist/InputDatalist';
import { searchAssociates } from '../../store/ducks/definitions/actionCreators';

function ModalShipmentsMoreFilters({
    showModal = true,
    onHide,
    modalData,
    resetAllFilters,
    searchAssociates
}) {
    const onSubmit = (e) => {
        modalData.onSubmitQuery(e);
        onHide();
    }

    const onReset = (e) => {
        // more filters are inside a form, so use reset event handler. reset more filters in redux store
    }

    const onResetAll = (e) => {
        // reset both more filters form and the primary filters form and redux store
        resetAllFilters();
    }

    const onClickHide = (e) => {
        onHide();
    }

    /* FORMAT */
    const delimiter = (data) => data && data.code && data.name && data.id ? ', ' : '';

    const formatInputDatalist = (name) => {
        const value = modalData.valueSelectorForFiltersAux(name);
        return value ? `(${value.code})${delimiter(value)}${value.name}` : '';
    }
    /* end FORMAT */

    const renderInputDatalist = ({
        value,
        onChange,
        fetchDataFunction,
        data,
        renderDatalistOptions,
        label,
        name,
        placeholder,
        autoComplete,
        error,
        queryParameterName = "query"
    }) => {
        return (
            <div>
                <InputDatalist
                    data={data}
                    value={value}
                    onChange={onChange}
                    fetchDataFunction={fetchDataFunction}
                    renderDatalistOptions={renderDatalistOptions}
                    labelText={label}
                    name={name}
                    placeholder={placeholder}
                    autoComplete={autoComplete}
                    queryParameterName={queryParameterName}
                    labelClassName={styles.label}
                />
                <small className="form-text text-danger">{error ? error : ''}</small>
            </div>
        );
    }

    const renderDatePicker = (id, label, selector, operator) => {
        return (
            <>
                <label htmlFor={id} className={styles.label}>{label}</label><br></br>
                <DatePicker
                    id={id}
                    selected={modalData.valueSelectorForFilters(selector, operator) ? epochSecondToLocalDateTime(modalData.valueSelectorForFilters(selector, operator)) : ''}
                    onChange={(dateTime) => modalData.onChangeDateQuery(selector, operator, dateTime ? localDateTimeToEpochSecond(dateTime) : '')}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    todayButton="Today"
                    type='text'
                    dropdownMode="select"
                />
            </>
        )
    }

    return (
        <Modal show={showModal} onHide={onClickHide} size="xl" centered>
            <form onSubmit={onSubmit} onReset={onReset}>
                <Modal.Header closeButton>
                    <Col>
                        <Row>
                            <Col>
                                <Modal.Title>More filters</Modal.Title>
                            </Col>
                        </Row>
                    </Col>
                </Modal.Header>

                <Modal.Body>
                    <Col>
                        <Row>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderInputDatalist({
                                    label: 'First Consignor',
                                    name: ['firstConsignorId', 'firstConsignor'],
                                    value: formatInputDatalist('firstConsignor'),
                                    onChange: modalData.onChangeInputDatalist,
                                    data: modalData.associates.items,
                                    fetchDataFunction: searchAssociates,
                                    queryParameterName: "queryStringValue",
                                    renderDatalistOptions: (item, i) => <option value={`(${item['code']})${delimiter(item)}${item['name']}`} key={i} />,
                                    placeholder: "Enter Code or Name"
                                })
                                }
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderInputDatalist({
                                    label: 'End Customer',
                                    name: ['endCustomerId', 'endCustomer'],
                                    value: formatInputDatalist('endCustomer'),
                                    onChange: modalData.onChangeInputDatalist,
                                    data: modalData.associates.items,
                                    fetchDataFunction: searchAssociates,
                                    queryParameterName: "queryStringValue",
                                    renderDatalistOptions: (item, i) => <option value={`(${item['code']})${delimiter(item)}${item['name']}`} key={i} />,
                                    placeholder: "Enter Code or Name"
                                })
                                }
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderInputDatalist({
                                    label: 'Supplier',
                                    name: ['supplierId', 'supplier'],
                                    value: formatInputDatalist('supplier'),
                                    onChange: modalData.onChangeInputDatalist,
                                    data: modalData.associates.items,
                                    fetchDataFunction: searchAssociates,
                                    queryParameterName: "queryStringValue",
                                    renderDatalistOptions: (item, i) => <option value={`(${item['code']})${delimiter(item)}${item['name']}`} key={i} />,
                                    placeholder: "Enter Code or Name"
                                })
                                }
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderDatePicker('customerPickupDateStart', 'Customer Pickup Date Start', 'customerPickupDate', '>=')}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderDatePicker('customerPickupDateEnd', 'Customer Pickup Date End', 'customerPickupDate', '<=')}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="destination.address.country.code" className={styles.label}>Country Code</label><br></br>
                                <input id='destination.address.country.code' value={modalData.valueSelectorForFilters('destination.address.country.code')} onChange={modalData.onChangeQuery} autoComplete="off" /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderDatePicker('customerDeliveryDateStart', 'Customer Delivery Date Start', 'customerDeliveryDate', '>=')}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderDatePicker('customerDeliveryDateEnd', 'Customer Delivery Date End', 'customerDeliveryDate', '<=')}
                            </Col>
                            
                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="temperatureControlled" className={styles.label}>Temperature Controlled?</label><br></br>
                                <input id="temperatureControlled" type="checkbox" checked={modalData.valueSelectorForFilters('temperatureControlled')} onChange={modalData.onChangeQuery} /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderDatePicker('createdDateStart', 'Created Date Start', 'createdDate', '>=')}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderDatePicker('createdDateEnd', 'Created Date End', 'createdDate', '<=')}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="hasHazardousItem" className={styles.label}>Has Hazardous Item?</label><br></br>
                                <input id="hasHazardousItem" type="checkbox" checked={modalData.valueSelectorForFilters('hasHazardousItem')} onChange={modalData.onChangeQuery} /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderDatePicker('arrivedDateStart', 'Arrived Date Start', 'arrivedDate', '>=')}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderDatePicker('arrivedDateEnd', 'Arrived Date End', 'arrivedDate', '<=')}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="customsControlled" className={styles.label}>Customs Controlled?</label><br></br>
                                <input id="customsControlled" type="checkbox" checked={modalData.valueSelectorForFilters('customsControlled')} onChange={modalData.onChangeQuery} /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderDatePicker('receivedDateStart', 'Received Date Start', 'receivedDate', '>=')}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderDatePicker('receivedDateEnd', 'Received Date End', 'receivedDate', '<=')}                                
                            </Col>                          

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="operationList.type" className={styles.label}>Operation Type</label><br></br>
                                <select id="operationList.type" value={modalData.valueSelectorForFilters('operationList.type')} onChange={modalData.onChangeQuery} style={{ padding: 6 }}>
                                    <option key={""} value={""}>Please select one</option>
                                    {
                                        modalData.types.items.length ?
                                            modalData.types.items
                                                .find(item => item.type === "OperationType").values
                                                .map((statusType, i) => <option value={statusType.value} key={i}>{statusType.displayName}</option>) :
                                            []
                                    }
                                </select>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderDatePicker('shortReceivedDateStart', 'Short Received Date Start', 'shortReceivedDate', '>=')}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderDatePicker('shortReceivedDateEnd', 'Short Received Date End', 'shortReceivedDate', '<=')}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="operationList.id" className={styles.label}>Operation ID</label><br></br>
                                <input id='operationList.id' value={modalData.valueSelectorForFilters('operationList.id')} onChange={modalData.onChangeQuery} /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderDatePicker('shippedDateStart', 'Shipped Date Start', 'shippedDate', '>=')}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderDatePicker('shippedDateEnd', 'Shipped Date End', 'shippedDate', '<=')}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="operationList.unitCode" className={styles.label}>Unit Number</label><br></br>
                                <input id='operationList.unitCode' value={modalData.valueSelectorForFilters('operationList.unitCode')} onChange={modalData.onChangeQuery} /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="actualMeasurement.itemQuantity.value" className={styles.label}>Item Quantity Min.</label><br></br>
                                <input type="number" id='actualMeasurement.itemQuantity.value' data-operator=">=" min={0} steps="any" value={modalData.valueSelectorForFilters('actualMeasurement.itemQuantity.value', '>=')} onChange={modalData.onChangeQuery} /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="actualMeasurement.itemQuantity.value" className={styles.label}>Item Quantity Max.</label><br></br>
                                <input type="number" id='actualMeasurement.itemQuantity.value' data-operator="<=" min={0} steps="any" value={modalData.valueSelectorForFilters('actualMeasurement.itemQuantity.value', '<=')} onChange={modalData.onChangeQuery} /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="unit.group.type" className={styles.label}>Transport Type</label><br></br>
                                <select id="unit.group.type" value={modalData.valueSelectorForFilters('unit.group.type')} onChange={modalData.onChangeQuery} style={{ padding: 6 }}>
                                    <option key={""} value={""}>Please select one</option>
                                    {
                                        modalData.types.items.length ?
                                            modalData.types.items
                                                .find(item => item.type === "OperationType").values
                                                .map((statusType, i) => <option value={statusType.value} key={i}>{statusType.displayName}</option>) :
                                            []
                                    }
                                </select>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="actualMeasurement.volume.value" className={styles.label}>Volume Min.</label><br></br>
                                <input type="number" id='actualMeasurement.volume.value' data-operator=">=" min={0} steps="any" value={modalData.valueSelectorForFilters('actualMeasurement.volume.value', '>=')} onChange={modalData.onChangeQuery} /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="actualMeasurement.volume.value" className={styles.label}>Volume Max.</label><br></br>
                                <input type="number" id='actualMeasurement.volume.value' data-operator="<=" min={0} steps="any" value={modalData.valueSelectorForFilters('actualMeasurement.volume.value', '<=')} onChange={modalData.onChangeQuery} /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="unit.group.code" className={styles.label}>Transport Code</label><br></br>
                                <input id='unit.group.code' value={modalData.valueSelectorForFilters('unit.group.code')} onChange={modalData.onChangeQuery} autoComplete="off" /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="actualMeasurement.grossWeight.value" className={styles.label}>Gross Weight Min.</label><br></br>
                                <input type="number" id='actualMeasurement.grossWeight.value' data-operator=">=" min={0} steps="any" value={modalData.valueSelectorForFilters('actualMeasurement.grossWeight.value', '>=')} onChange={modalData.onChangeQuery} /><br></br>
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                <label htmlFor="actualMeasurement.grossWeight.value" className={styles.label}>Gross Weight Max.</label><br></br>
                                <input type="number" id='actualMeasurement.grossWeight.value' data-operator="<=" min={0} steps="any" value={modalData.valueSelectorForFilters('actualMeasurement.grossWeight.value', '<=')} onChange={modalData.onChangeQuery} /><br></br>
                            </Col>

                        </Row>
                    </Col>
                </Modal.Body>

                <Modal.Footer>
                    <Col>
                        <Row>

                            <Col></Col>
                            <Col></Col>

                            <Col xs="12" sm="6" md="6" lg="3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <input type="submit" value='Filter' />
                            </Col>

                        </Row>
                    </Col>
                </Modal.Footer>
            </form>
        </Modal >
    );
}


const mapDispatchToProps = {
    resetAllFilters,
    searchAssociates,
};

export default connect(undefined, mapDispatchToProps)(ModalShipmentsMoreFilters);
