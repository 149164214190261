import React from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@dfds-ui/react-components';

const ModalConfirmation = ({ modalData, onHide }) => {

    const title = modalData.title;
    const message = modalData.message;
    const confirmButtonText = modalData.confirmButtonText ? modalData.confirmButtonText : 'Accept';
    const cancelButtonText = modalData.cancelButtonText ? modalData.cancelButtonText : 'Cancel'

    const onConfirmClick = () => {
        modalData.onConfirm();
        onHide();
    }

    return (
        <Modal
            show={true}
            onHide={onHide}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message}
            </Modal.Body>
            <Modal.Footer>
                <Button style={{ minWidth: '120px' }} variation="outlined" onClick={onHide}>{cancelButtonText}</Button>
                <Button style={{ minWidth: '120px' }} variation="primary" onClick={onConfirmClick}>{confirmButtonText}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalConfirmation;