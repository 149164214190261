import { memoize } from "lodash";
import { Field } from "redux-form";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import { DateComponent } from "./Pure/DateComponent";
import moment from "moment";
import { addValidationToArray } from "./Validation/FormValidation";


const isValidForToday = (value) => {
    let now = moment();
    const day = moment(value, "DD/MM/YYYY HH:mm");
    if (now.diff(day, 'seconds') > 0) {
        return 'Selected date must be higher than today';
    }
    return undefined;
};

const memoizedTodayValidation = (todayValidation, validationArray) => {
    let newValidation = validationArray ? validationArray : [];
    if (todayValidation) {
        newValidation.push(isValidForToday);
    }
    return newValidation;
};

const prepareValidation = (isRequired, isTodayCheck, validationArray) => {
    let validation = addValidationToArray(isRequired, validationArray);
    if (isTodayCheck) {
        validation = memoizedTodayValidation(isTodayCheck, validation);
    }
    return validation;
};

export const DateInputFormComponent = ({ name, isRequired, isTodayCheck, validationArray, isDisabled, onChange, showTimeSelect = true }) => {
    const [selectedDate, setSelectedDate] = useState(null);

    const dateChangedHandler = (event, newValue, previousValue, fieldId) => {
        setSelectedDate(newValue);
        const parsedNewValue = moment(newValue).format(showTimeSelect ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY');
        const parsedPreviousValue = moment(previousValue).format(showTimeSelect ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY');
        onChange(event, parsedNewValue, parsedPreviousValue, fieldId);
    };

    const validation = prepareValidation(isRequired, isTodayCheck, validationArray);
    return (<Field
        name={name}
        component={DateComponent}
        type="text"
        label="Select Task Date"
        selected={selectedDate}
        onChange={dateChangedHandler}
        showTimeSelect={showTimeSelect}
        className="form-control"
        validate={validation}
        disabled={isDisabled}
    />);
};

DateInputFormComponent.propTypes = {
    name: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    isTodayCheck: PropTypes.bool,
    validationArray: PropTypes.arrayOf(PropTypes.func)
};