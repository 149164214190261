import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import {
  getBookingsForCarrier,
  cancelBooking,
  clearBookingFormData,
} from "../store/action/bookingActions";
import BookingsTable from "../component/BookingTable/BookingsTable";
import history from "../core/history";
import { Button } from "@dfds-ui/react-components";
import Page from "../component/Page/Page";
import { TabCollection } from "../component/BaseComponents/TabCollection";
import { utcToEpoch } from "../core/utcToEpochConverter";
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingIndicator from "../component/LoadingIndicator/LoadingIndicator";
import { confirmationModal } from '../store/action/modalActions';

const dateTabs = [
  utcToEpoch(moment().toDate()),
  utcToEpoch(moment().add(1, 'day').toDate()),
  utcToEpoch(moment().add(2, 'day').toDate()),
  utcToEpoch(moment().add(3, 'day').toDate()),
  utcToEpoch(moment().add(4, 'day').toDate()),
  utcToEpoch(moment().add(5, 'day').toDate())
];

const CarrierBookings = (props) => {
  //Redux actions
  const { getBookingsForCarrier, cancelBooking, clearBookingFormData, confirmationModal } = props;

  //Redux store
  let { isRefreshData, carrierBookings } = props;


  const [selectedDate, setSelectedDate] = useState(utcToEpoch(moment().toDate()));

  useEffect(() => {
    getBookingsForCarrier(selectedDate);
  }, [selectedDate]);

  const dateChangedHandler = (newDate) => {
    setSelectedDate(newDate);
  };

  //TODO: Bu işlemin bu componentde olmaması gerekiyor. BookingForm state'ini Create ve Update componentleri kullanıyor. O componentlere erişim Bookings ve CarrierBookings üzerinden
  // sağlandığı için burada form state'in clear edilmesi sistemin kararlı çalışmasını sağlıyor. Ancak Create ve Update component'ler başka bir yerden çağrılacak şekilde bir ister gelirse
  // buradaki dependent useEffect işlemi olmadığı için form'lar back işlemi sırasında dolu gelme sorunu ortaya çıkacaktır.
  // Buradaki useEffect işleminin doğru kullanımı Create ve Update component'lerinde component unmount hook'unda çağrılması olurdu. Ancak Create ve Update component'leri tasarlanırken
  // booking akışı route değişicek şekilde tasarlandığı için state'in componentler arası redux üzerinden taşınması gerekiyor. Haliyle create ve update component'leri state üzerindeki formData
  // yı diğer componentlerin kullanabilmesi için unmount anında clear etmemektedir.
  // O yüzden burada teknik borç oluşmuştur.
  useEffect(() => {
    clearBookingFormData();
  }, []);

  useEffect(() => {
    if (isRefreshData) {
      getBookingsForCarrier(selectedDate);
    }
  }, [isRefreshData]);

  const editBookingClickedHandler = (bookingId) => {
    history.push(`/booking/${bookingId}`);
  };

  const cancelBookingClickedHandler = (bookingId) => {
    confirmationModal('Cancel booking', `Are you sure you want to cancel ${bookingId}?`, () => cancelBooking(bookingId));
  };

  return <Page>
    <Row>
      <Col>
        <div className='d-flex' style={{ alignItems: 'center' }}>
          <div style={{ flex: '1' }}>
            <h4 className='mb-0'>Bookings</h4>
          </div>
          <div>
            <Button title="Create"
              icon={<FontAwesomeIcon icon='plus' />}
              onClick={() => {
                history.push("/booking/new");
              }}>Create</Button>
          </div>
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div style={{ marginBottom: '40px' }}> </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <TabCollection
          tabItemStyle={{ 'height': '2rem', fontSize: 'medium' }}
          selectedTab={selectedDate}
          tabs={dateTabs}
          customTabRenderer={(tab) => moment.utc(tab * 1000).calendar(null, {
            lastDay: '[YESTERDAY] - MMM Do',
            sameDay: '[TODAY] - MMM Do',
            nextDay: '[TOMORROW] - MMM Do',
            nextWeek: 'dddd - MMM Do',
            sameElse: 'dddd - MMM Do'
          }).toLocaleUpperCase()}
          onTabClickedEvent={dateChangedHandler}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <BookingsTable
          data={carrierBookings}
          canEditBooking={true}
          canSeeAdminNotes={false}
          editBookingClickedEvent={editBookingClickedHandler}
          cancelBookingClickedHandler={cancelBookingClickedHandler}
        />
      </Col>
    </Row>
    <LoadingIndicator id="bookingGetAll" show />
  </Page>
};

const mapStateToProps = (state) => {
  return {
    carrierBookings: state.booking.carrierBookings,
    isRefreshData: state.booking.isRefreshData,
  };
};

const mapDispatchToProps = {
  getBookingsForCarrier,
  cancelBooking,
  clearBookingFormData,
  confirmationModal
}

export default connect(mapStateToProps, mapDispatchToProps)(CarrierBookings);
