import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Card } from 'react-bootstrap';
import { Select, Checkbox, Button, ButtonStack, TabPanel } from '@dfds-ui/react-components';
import classes from '../component/Shipments/shipments.module.css';
import { clearState, getHoldItemList, createHoldItem, confirmHoldItem, deleteHoldItem } from '../store/action/holdTerminalItemActions'
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import { BackgroundText } from '../component/BackgroundText/BackgroundText';
import PageHeader from '../component/PageHeader/PageHeader';
import { confirmationModal } from '../store/action/modalActions'
import './HoldterminalItem.css';

const HoldTerminalItem = (props) => {
    //parent
    const { shipmentId } = props.match.params;

    //redux
    const { getHoldItemList, createHoldItem, confirmHoldItem, deleteHoldItem, confirmationModal, itemList, holdTypeList, reasonTypeList } = props;

    const [allArticleChecked, setAllArticleChecked] = useState(false);
    const [allHoldItemChecked, setAllHoldItemChecked] = useState(false);

    const [holdModel, setHoldModel] = useState({ reasonType: '', holdType: '', description: '' });
    const [checkedArticleIdList, setCheckedArticleIdList] = useState([]);
    const [checkedHoldItemIdList, setCheckedHoldItemIdList] = useState([]);
    const [error, setError] = useState({});
    const [filter, setFilter] = useState({ reasonType: '', holdType: '', keyword: '' })
    const [filteredList, setFilteredList] = useState([])

    useEffect(() => {
        clearState();
        getHoldItemList(shipmentId, 'loader-hold');
    }, [])

    useEffect(() => {
        setHoldModel({ reasonType: '', holdType: '', description: '' })
        setCheckedArticleIdList([]);
        setCheckedHoldItemIdList([]);
        setAllArticleChecked(false);
        setAllHoldItemChecked(false);
        setFilteredList(itemList);
        setFilter({ reasonType: '', holdType: '', keyword: '' })
    }, [itemList]);

    useEffect(() => {

        var copyList = itemList.map(a => Object.assign({}, a));

        if (filter.reasonType) {
            for (let i = 0; i < copyList.length; i++) {
                copyList[i].holdDefinitionList = copyList[i].holdDefinitionList.filter(s => s.reasonType == filter.reasonType);
            }
        }

        if (filter.holdType) {
            for (let i = 0; i < copyList.length; i++) {
                copyList[i].holdDefinitionList = copyList[i].holdDefinitionList.filter(s => s.holdType == filter.holdType);
            }
        }

        if (filter.keyword) {
            copyList = copyList.filter(s =>
                (s.externalId && s.externalId.toUpperCase().indexOf(filter.keyword.toUpperCase()) > -1) ||
                (s.externalCode && s.externalCode.toUpperCase().indexOf(filter.keyword.toUpperCase()) > -1) ||
                (s.status && s.status.toUpperCase().indexOf(filter.keyword.toUpperCase()) > -1));
        }

        setFilteredList(copyList);

    }, [filter])

    const validation = (validateFields, validateList) => {
        const errorValidation = {};

        if (validateFields) {
            if (!holdModel.reasonType) {
                errorValidation.reasonType = 'Reason is mandatory';
            }

            if (!holdModel.holdType) {
                errorValidation.holdType = 'Hold Type is mandatory';
            }
        }

        if (validateList) {
            if (!checkedArticleIdList || checkedArticleIdList.length < 1) {
                errorValidation.checkedArticleIdList = 'THU(s) are mandatory';
            }
        }

        setError(errorValidation);
        return errorValidation;
    }

    const onModelChange = (event) => {
        setHoldModel({
            ...holdModel,
            [event.target.name]: event.target.value
        });
    }

    const onFilterChange = (event) => {
        setFilter({
            ...filter,
            [event.target.name]: event.target.value
        });

        setAllHoldItemChecked(false);
        setCheckedHoldItemIdList([]);
    }

    const onArticleCheckboxChange = (isChecked, id) => {
        setCheckedArticleIdList((prev) => {

            var list = [...prev];

            if (isChecked) {
                list.push(id);
            } else {
                list = list.filter(s => s !== id);
            }

            setAllArticleChecked(list.length === itemList.length);
            return list;
        })
    }

    const onArticleCheckboxAllChange = (isChecked) => {
        if (isChecked) {
            const list = itemList.map(item => item.id);
            setCheckedArticleIdList(list);
        } else {
            setCheckedArticleIdList([]);
        }
        setAllArticleChecked(isChecked);
    }

    const onHoldItemCheckboxChange = (isChecked, id) => {
        setCheckedHoldItemIdList((prev) => {

            var list = [...prev];

            if (isChecked) {
                list.push(id);
            } else {
                list = list.filter(s => s !== id);
            }

            setAllHoldItemChecked(list.length === itemList.length);
            return list;
        })
    }

    const onHoldItemCheckboxAllChange = (isChecked) => {
        if (isChecked) {
            setCheckedHoldItemIdList(filteredList.flatMap(s => s.holdDefinitionList).map(s => s.id));
        } else {
            setCheckedHoldItemIdList([]);
        }
        setAllHoldItemChecked(isChecked);
    }

    const isArticleChecked = (id) => {
        return checkedArticleIdList.some(item => item === id);
    }

    const isHoldItemChecked = (id) => {
        return checkedHoldItemIdList.some(item => item === id);
    }

    const isValid = (validateFields, validateList) => {
        return Object.entries(validation(validateFields, validateList)).length === 0;
    }

    const onSaveClick = () => {
        if (isValid(true, true)) {
            confirmationModal('Hold Items', 'Selected terminal item will be holded. Do you approve?', () => createHoldItem(shipmentId, holdModel.reasonType, holdModel.holdType, holdModel.description, checkedArticleIdList, 'loader-hold'));
        }
    }

    const onConfirmClick = () => {
        confirmationModal('Hold Items', 'Selected terminal item will be holded. Do you approve?', () => confirmHoldItem(shipmentId, checkedHoldItemIdList, itemList.map(s => s.id), 'loader-hold'));
    }

    const onDeleteClick = () => {
        confirmationModal('Hold Items', 'This hold will be removed. Do you approve?', () => deleteHoldItem(shipmentId, checkedHoldItemIdList, itemList.map(s => s.id), 'loader-hold'));
    }

    const renderSelectBox = (label, name, value, list, onChange, error) => {
        return (
            <Col xs={6} className="mb-2">
                <Row>
                    <Col>
                        <span>{label}</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Select name={name} size="small" value={value} onChange={onChange}>
                            <option value="">{'Select'}</option>
                            {
                                list.map(item => {
                                    return (<option key={item.value} value={item.value}>{item.name}</option>)
                                })
                            }
                        </Select>
                        <small className="form-text text-danger">{error ? error : ''}</small>
                    </Col>
                </Row>
            </Col>
        )
    }

    const renderTextarea = (label, name, value, onChange, error) => {
        return (
            <Col xs={12} className="mb-2">
                <Row>
                    <Col>
                        <span>{label}</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <textarea name={name} className='form-control input-block' rows={3} value={value} onChange={onChange}></textarea>
                        <small className="form-text text-danger">{error ? error : ''}</small>
                    </Col>
                </Row>
            </Col>
        )
    }

    const renderText = (label, name, value, onChange, error) => {
        return (
            <Col xs={12} className="mb-2">
                <Row>
                    <Col>
                        <span>{label}</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <input type='text' name={name} className='form-control input-block' value={value} onChange={onChange} />
                        <small className="form-text text-danger">{error ? error : ''}</small>
                    </Col>
                </Row>
            </Col>
        )
    }

    const renderCol = (text, value, xs) => {
        return (
            <Col xs={xs} className="mb-1">
                <Row>
                    <Col>
                        <span className="text-title">{text}</span>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-truncate">
                        <span className="color-text-grey" title={value}>{value}</span>
                    </Col>
                </Row>
            </Col>
        )
    }

    const renderArticleList = () => {

        if (!itemList || itemList.length == 0) {
            return (
                <h6>
                    No Thu registered for this shipment
                </h6>
            )
        }

        return (
            <>
                {
                    itemList.map((item, i) => {
                        return (
                            <Row style={{ marginBottom: '5px' }} key={i}>
                                <Col>
                                    <Card key={i}>
                                        <Card.Body style={{ padding: '0.1rem 1rem 0.1rem 1rem' }}>
                                            <Row className={`align-items-center`}>
                                                <BackgroundText text={'HOLD'} show={item.isHeld} type={'danger'} />
                                                <BackgroundText text={'CONFIRMED'} show={item.holdDefinitionList.length > 0 && item.holdDefinitionList.every(holdItem => holdItem.isConfirmed === true)} type={'light'} />
                                                <Col xs={1}>
                                                    <Checkbox
                                                        checked={isArticleChecked(item.id)}
                                                        onChange={(e) => { onArticleCheckboxChange(e.target.checked, item.id); }}
                                                    >
                                                    </Checkbox>
                                                </Col>
                                                <Col xs={11}>
                                                    <Row>
                                                        {renderCol('THU Number', item.externalId, 3)}
                                                        {renderCol('Part No', item.externalCode, 3)}
                                                        {renderCol('Status', item.status, 3)}
                                                        {renderCol('Hold Count', item.holdDefinitionList.length, 3)}
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        )
                    })
                }
            </>
        )
    }

    const renderHoldItemList = () => {

        var holdList = filteredList && filteredList.flatMap(s => s.holdDefinitionList);
        if (!holdList || holdList.length == 0) {
            return (
                <h6>
                    There is no hold(s)
                </h6>
            )
        }

        return filteredList.map((item, i) => {

            return (<>
                {
                    item.holdDefinitionList.map((holdItem, i) => {
                        return (
                            <Card key={i}>
                                <Card.Body>
                                    <Row className={`align-items-center`}>
                                        <BackgroundText text={'CONFIRMED'} show={holdItem.isConfirmed} type={'light'} />
                                        <Col xs={1}>
                                            <Checkbox
                                                checked={isHoldItemChecked(holdItem.id)}
                                                onChange={(e) => { onHoldItemCheckboxChange(e.target.checked, holdItem.id); }}
                                            >
                                            </Checkbox>
                                        </Col>
                                        <Col xs={11}>
                                            <Row>
                                                {renderCol('THU Number', item.externalId, 4)}
                                                {renderCol('Part No', item.externalCode, 4)}
                                                {renderCol('Status', item.status, 4)}
                                            </Row>
                                            <Row>
                                                {renderCol('Hold Reason', holdItem.reasonTypeName, 4)}
                                                {renderCol('Hold Type', holdItem.holdTypeName, 4)}
                                                {renderCol('Description', holdItem.description, 4)}
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                        )
                    })
                }
            </>
            )
        })
    }

    const renderListContent = () => {
        return (
            <Card>
                <Card.Body>
                    <Row>
                        {renderText('Search', 'keyword', filter.keyword, onFilterChange)}
                        {renderSelectBox('Hold Reason', 'reasonType', filter.reasonType, reasonTypeList, onFilterChange)}
                        {renderSelectBox('Hold Type', 'holdType', filter.holdType, holdTypeList, onFilterChange)}
                    </Row>
                    <Row className="align-items-center" style={{ marginBottom: '5px' }}>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col xs={4}>
                                            <Checkbox className={`${classes["shipments-checkbox"]}`} onChange={(e) => onHoldItemCheckboxAllChange(e.target.checked)} checked={allHoldItemChecked}></Checkbox>
                                        </Col>
                                        <Col xs={4} className="text-center">
                                            <h5 className="font-weight-bold">Hold Item List ({filteredList.flatMap(s => s.holdDefinitionList).length})</h5>
                                        </Col>
                                        <Col xs={4}>
                                            <ButtonStack align="right">
                                                <Button variation="secondary" size="small" onClick={(e) => onConfirmClick()} disabled={checkedHoldItemIdList.length === 0}>Confirm ({checkedHoldItemIdList.length})</Button>
                                                <Button variation="outlined" size="small" onClick={(e) => onDeleteClick()} disabled={checkedHoldItemIdList.length === 0}>Delete ({checkedHoldItemIdList.length})</Button>
                                            </ButtonStack>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    {renderHoldItemList()}
                </Card.Body>
            </Card>
        )
    }

    const renderCreateContent = () => {
        return (
            <>
                <Card>
                    <Card.Body>
                        <Row>
                            {renderSelectBox('Hold Reason', 'reasonType', holdModel.reasonType, reasonTypeList, onModelChange, error.reasonType)}
                            {renderSelectBox('Hold Type', 'holdType', holdModel.holdType, holdTypeList, onModelChange, error.holdType)}
                        </Row>
                        <Row>
                            {renderTextarea('Description', 'description', holdModel.description, onModelChange, error.description)}
                        </Row>
                        <Row className="align-items-center" style={{ marginBottom: '5px' }}>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Row>
                                            <Col xs={4}>
                                                <Checkbox className={`${classes["shipments-checkbox"]}`} onChange={(e) => onArticleCheckboxAllChange(e.target.checked)} checked={allArticleChecked}></Checkbox>
                                            </Col>
                                            <Col xs={4} className="text-center">
                                                <h5 className="font-weight-bold">Thu List ({itemList.length})</h5>
                                            </Col>
                                            <Col xs={4}>
                                                <ButtonStack align="right">
                                                    <Button variation="primary" size='small' onClick={(e) => onSaveClick()} disabled={checkedArticleIdList.length === 0}>Hold ({checkedArticleIdList.length})</Button>
                                                </ButtonStack>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        {renderArticleList()}
                        <small className="form-text text-danger text-right">{error.checkedArticleIdList ? error.checkedArticleIdList : ''}</small>
                    </Card.Body>
                </Card>
            </>
        )
    }

    return (
        <>
            <PageHeader text={`Hold THU`} />
            <Card>
                <LoadingIndicator id='loader-hold' />
                <Card.Body>

                    <ul className="nav nav-tabs" id="tabs-hold-items" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="hold-list" data-toggle="tab" href="#hold-list-content" role="tab">Hold Item List</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="hold-create" data-toggle="tab" href="#hold-create-content" role="tab">Create Hold Item</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="tabs-content">
                        <div className="tab-pane fade show active" id="hold-list-content" role="tabpanel">{renderListContent()}</div>
                        <div className="tab-pane fade" id="hold-create-content" role="tabpanel">{renderCreateContent()}</div>
                    </div>

                </Card.Body>
            </Card>
        </>
    )

}

const mapStateToProps = ({ holdTerminalItem }) => {
    return {
        itemList: holdTerminalItem.itemList,
        holdTypeList: holdTerminalItem.holdTypeList,
        reasonTypeList: holdTerminalItem.reasonTypeList
    }
}

const mapActionToProps = {
    getHoldItemList,
    createHoldItem,
    confirmHoldItem,
    deleteHoldItem,
    confirmationModal
}

export default connect(mapStateToProps, mapActionToProps)(HoldTerminalItem);