import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { ButtonStack } from '@dfds-ui/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@dfds-ui/react-components/button';
import {
    readVoyages,
    deleteVoyages,
    filterVoyages,
} from '../store/ducks/voyages/actionCreators';

import styles from './Voyages.module.css';
import { Card, Row, Col } from 'react-bootstrap';
import { epochSecondToDateTime } from '../core/utcToEpochConverter';
import { isEmptyObject } from '../core/utility.js';
import LoadingIndicator from "../component/LoadingIndicator/LoadingIndicator";
import PolicyGroupAccessHoc from "../RoutedComponents/Routes/PolicyGroupAccessHoc";

function Voyages({
    readVoyages,
    deleteVoyages,
    filterVoyages,
    voyages,
}) {
    // HISTORY, LOCATION, SEARCH PARAMS, URL PARAMS
    let history = useHistory();
    // end HISTORY, LOCATION, SEARCH PARAMS, URL PARAMS

    // GET STATE AFTER COMPONENT RENDERED
    useEffect(() => {
        readVoyages('processVoyages');
    }, []);
    // end GET STATE AFTER COMPONENT RENDERED

    // CREATE
    const onClickCreate = () => {
        history.push(`/voyages/voyage`);
    }
    // end CREATE

    // UPDATE
    const onClickEdit = () => {
        history.push(`/voyages/voyage?ids=${checkedItemIds}`);
    }
    // end UPDATE

    // DELETE
    const onClickDelete = async () => {
        if (window.confirm(`Are you sure you want to delete ${checkedItemIds.length > 1 ? `these ${checkedItemIds.length} items` : 'this item'}?`)) {
            await deleteVoyages({ voyageIds: checkedItemIds }, 'processVoyages');
            setCheckedItemIds([]);// uncheck all.
            // TODO: sync checked Ids with the state with an effect and map so do not need this line and the one below. better return promise from the action creators, the current old way of returning nothing and barring the promise with an await is bad practice.
        }
    }
    // end DELETE

    // FILTER
    const [areFiltersDisplayed, setAreFiltersDisplayed] = useState(true);
    const [filterQuery, setFilterQuery] = useState({});

    const onClickFilters = () => {
        toggleFiltersDisplayed();
    }

    const toggleFiltersDisplayed = () => {
        // if currently true, it wil be false, filters will bot be displayed, so load data back without filtering: read
        if (areFiltersDisplayed) {
            setFilterQuery({});
            readVoyages('processVoyages');
        }
        setAreFiltersDisplayed(state => !state);
    }

    const onChangeFilterQuery = (e) => {
        e.persist();
        setFilterQuery(state => ({ ...state, [e.target.id]: e.target.value }));
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (isEmptyObject(filterQuery) === false) {
            filterVoyages(filterQuery, 'processVoyages');
        }
    }

    // TODO: will it be necessary?
    const onClickHideFilters = () => {
        toggleFiltersDisplayed();
    }

    /*
    // TODO:
    // will use later for more filters cases to have conveniently reset filter fields at once
    const onReset = (e) => {
        // e.preventDefault();
        setQuery('');
        // readVoyages('processVoyages');
        // filterVoyages({ query }, 'readVoyages);
    }
    */
    // end FILTER

    // SORT
    // Client side sort, when clicked on table heads

    // Server side sort, when clicked on sort icon in functions section (next to plus and filter icons at top left)
    // end SORT

    // ON CLICK LIST ITEM, navigate to item view
    // delegated event for performance and less code, less js code inline html
    const onClickTableBody = (e) => {
        e.persist()
        if (e.target.type === 'checkbox') {
            // TODO: delegate checkbox event handling as well
        } else {
            history.push(`/voyages/voyage?ids=${[e.target.id]}`);
        }
    }
    // end ON CLICK LIST ITEM

    // CHECK LIST ITEM/S, with checkboxes
    const [checkedItemIds, setCheckedItemIds] = useState([]);

    const onChangeCheckbox = (e) => {
        e.persist();
        if (e.target.checked) {
            setCheckedItemIds(state => state.includes(e.target.id) ? state : state.concat(e.target.id)); // check one
        } else {
            setCheckedItemIds(state => state.filter(item => item !== e.target.id)); // uncheck one
        }
    }

    const onChangeMasterCheckbox = (e) => {
        e.persist();
        if (e.target.checked) {
            setCheckedItemIds(voyages.items.map(item => item.id)); // check all
        } else {
            setCheckedItemIds([]); // uncheck all
        }
    }

    const generateGUID = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r && 0x3 | 0x8);
            return v.toString(16);
        });
    }

    const onClickResetChecks = () => setCheckedItemIds([]);
    // end CHECK LIST ITEM/S

    // VALIDATION
    // end VALIDATION

    // PAGINATION
    // end PAGINATION

    // TODO: consider showing the selected N items note and its reset selection cross icon in the info section where spinner or showing n items stays
    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                <span style={{ fontSize: '1.75rem', paddingLeft: '10px' }}>Voyages</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                <ButtonStack align='right'>
                                    <PolicyGroupAccessHoc componentName="AddVoyageButtonHOC">
                                        <Button variation='primary'
                                            key={generateGUID()}
                                            onClick={onClickCreate}>
                                            Create <FontAwesomeIcon icon="plus" />
                                        </Button>
                                    </PolicyGroupAccessHoc>
                                    {checkedItemIds.length == 1 ?
                                        <Button variation='secondary'
                                            key={generateGUID()}
                                            onClick={onClickEdit}>
                                            Edit <FontAwesomeIcon icon="edit" />
                                        </Button> : ""
                                    }
                                    {checkedItemIds.length > 0 ?
                                        <Button variation='secondary'
                                            key={generateGUID()}
                                            onClick={onClickDelete}>
                                            Delete <FontAwesomeIcon icon="trash" />
                                        </Button> : ""}
                                    {checkedItemIds.length == 0 ?
                                        <Button variation='secondary'
                                            key={generateGUID()}
                                            onClick={onClickFilters}>
                                            Filter <FontAwesomeIcon icon="filter" />
                                        </Button> : ""
                                    }
                                </ButtonStack>
                            </div>
                        </div>
                        <hr />
                    </Col>
                </Row>

                {
                    areFiltersDisplayed &&
                    <>
                        <Row className={styles.functionSection}>
                            <Col style={{ width: '100%', padding: '0px' }}>
                                <form onSubmit={onSubmit} style={{ margin: '0px' }}>
                                    <Row>

                                        <Col xs="12" sm="12" md="9">
                                            <input
                                                id="externalNumber"
                                                value={filterQuery.externalNumber}
                                                onChange={onChangeFilterQuery}
                                                autoFocus
                                                placeholder="External Number"
                                            />
                                        </Col>
                                        <Col xs="12" sm="12" md="3" style={{ display: 'flex', alignItems: 'center' }}>
                                            <input type="submit" value="Filter" style={{ flexGrow: 1 }} />
                                        </Col>
                                    </Row>
                                </form>
                            </Col>
                        </Row>
                    </>
                }
                <Row>
                    <Col>
                        <main>

                            <Table className="xms-table" bordered responsive>
                                <thead>
                                    <tr>
                                        <th>
                                            <input
                                                type="checkbox"
                                                checked={voyages.items.length && checkedItemIds.length === voyages.items.length}
                                                onChange={onChangeMasterCheckbox}
                                            />
                                        </th>
                                        {/* <th className={styles.th}>Id</th> */}
                                        <th>Voyage Name</th>
                                        <th>External Number</th>
                                        <th>Internal Number</th>
                                        <th>Vessel Name</th>
                                        <th>Departure Location</th>
                                        <th>Arrival Location</th>
                                        <th>Departure Date & Time</th>
                                        <th>Arrival Date & Time</th>
                                    </tr>
                                </thead>
                                <tbody onClick={onClickTableBody}>
                                    {
                                        voyages.items.map((item) => {
                                            return (
                                                <tr key={item.id} id={item.id}>
                                                    <td
                                                        id={item.id}
                                                        onClick={(e) => e.stopPropagation()}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            id={item.id}
                                                            checked={checkedItemIds.includes(item.id)}
                                                            onChange={onChangeCheckbox}
                                                        />
                                                    </td>
                                                    <td id={item.id}>{item.voyageName}</td>
                                                    <td id={item.id}>{item.externalNumber}</td>
                                                    <td id={item.id}>{item.internalNumber}</td>
                                                    <td id={item.id}>{item.vesselName}</td>
                                                    <td id={item.id}>{item.departureLocation}</td>
                                                    <td id={item.id}>{item.arrivalLocation}</td>
                                                    <td id={item.id}>{item.departureDate > 0 ? epochSecondToDateTime(item.departureDate).toLocaleString() : ''}</td>
                                                    <td id={item.id}>{item.arrivalDate > 0 ? epochSecondToDateTime(item.arrivalDate).toLocaleString() : ''}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={9}>{voyages.isRequesting ? 'Requesting' : `Showing ${voyages.items.length} items`}</td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </main>
                    </Col>
                </Row>
            </Card.Body>
            <LoadingIndicator id="processVoyages" show={voyages.isRequesting} />
        </Card>
    );
}

const mapStateToProps = ({ voyages }) => {
    return {
        voyages
    };
}

const mapDispatchToProps = {
    readVoyages,
    deleteVoyages,
    filterVoyages,
}

export default connect(mapStateToProps, mapDispatchToProps)(Voyages);

// TODO: 

// open close the filters with animation

// if a button is selected indicate that, e.g. filters. also for requests made by buttons should indicate that, spinner and cancel

// color for function icons like blue color we used to use. or just blue/orange color the selection buttons. or blue list functions, orange selection functions. also the selected rows can be made text and other colors same color and tones

// micro animation when selection buttons show/hide

// sticky header for the table

// decide how to clear filters or when more filters come keep last used ones. also what to do when the filters are closed: keep results or show/fetch default data. you can show a back button and the filter results and operations on them jst like selection functions. that way closing filters is done by back button

// show checkboxes/delete for suffix-suppliers or conn point suffixes list rows only on hover on pc, select by checking or long press. on mobile long press selects

// consider tables for the suffix-suppliers or conn point suffixes inside the view they stay now

// should fetch logic after component rendered

// fix scrollbar makes page jump sideways

// when filtered or not show the total number of items at top as well.

// resize table horizontally: for shipments overview that is needed.
// TODO: event delegation for onChangeCheckbox as well

// protoptypes

// use fontawesome icons for system fonts instead of dfds icons, but dfds logistic specific icons can be of use later maybe.