/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PageHeader from '../component/PageHeader/PageHeader';
import { getRole, clearRoleState, updateRole } from '../store/action/roleActions';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import RoleName from '../component/Role/RoleName';
import PolicyList from '../component/Role/PolicyList';
import { toast } from 'react-toastify';
import { Button } from '@dfds-ui/react-components'
import Page from '../component/Page/Page';

const EditRole = (props) => {

    const [selectedPolicies, setSelectedPolicies] = useState([]);

    //redux elements
    const {
        role,
        getRole,
        updateRole,
        clearRoleState
    } = props;

    useEffect(() => {
        clearRoleState();
    }, [])

    useEffect(() => {

        if (!role) {
            const roleName = props.match.params.roleName;

            if (!roleName) { return; }
            getRole(roleName, 'editRole');
        } else {
            const predefinedPolicies = [...role.policies];
            setSelectedPolicies(predefinedPolicies);
        }

    }, [role])

    const onSaveRole = () => {
        const roleName = props.match.params.roleName;
        const errorList = controlInputs(roleName, selectedPolicies);

        if (errorList && errorList.length) { return; }

        updateRole(roleName, selectedPolicies);
    }

    const onPolicyToggle = (policyName, newValue) => {
        let selectedPoliciesArray = selectedPolicies;
        if (newValue) {
            selectedPoliciesArray.push(policyName);
        } else {
            selectedPoliciesArray = selectedPoliciesArray.filter(p => p !== policyName);
        }
        setSelectedPolicies(selectedPoliciesArray);
    }

    const renderPolicies = () => {

        if (!role) { return; }

        return (
            <div>
                <PolicyList onPolicyToggle={onPolicyToggle} predefinedPolicies={selectedPolicies} />
            </div>
        )
    }

    return <Page backLink='/role' backToWhere='Roles'>
        <h4>
            {role && role.role}
        </h4><hr className='mt-0' />

        {renderPolicies(onPolicyToggle, role, selectedPolicies)}
        {renderActionButton(onSaveRole)}
        <LoadingIndicator id='editRole' />
    </Page>
}

const renderActionButton = (onSaveRole) => {
    return (
        <div className='col'>
            <div className='row float-right'>
                <Button onClick={onSaveRole}>Save</Button>
            </div>
        </div>
    )
}

const controlInputs = (roleName, policyList) => {
    const errors = [];

    if (!roleName) { errors.push('Role Name is mandatory') };

    if (!policyList || !policyList.length) { errors.push('At least 1 policy must be selected') };

    for (const error of errors) {
        toast.error(error);
    }

    return errors;
}

const mapStateToProps = ({ role }) => {
    return {
        role: role.role
    }
}

export default connect(mapStateToProps, { getRole, updateRole, clearRoleState })(EditRole);