import postSync, { get as getSync } from '../../core/httpClientSync';
import shipmentInboundOperationTypes from '../type/shipmentInboundOperationTypes';
import { addQueryString } from '../../core/utility'

const path = "xms-shipment-api";
// const path = "http://localhost:5000";

export const clearShipmentsState = () => async dispatch => {
  dispatch({
    type: shipmentInboundOperationTypes.STATE_CLEARED
  })
}

export const getOperation = (operationId, spinnerElementId = null) => async dispatch => {

  const url = `${path}/api/operations/${operationId}`;
  const response = await getSync(url, spinnerElementId);

  if (response.isSuccess) {
    dispatch({
      type: shipmentInboundOperationTypes.OPERATION_RECEIVED,
      payload: response.response.result.operation
    })
  }
}

export const getLinkedShipmentList = (shipmentIdList, spinnerElementId = null) => async dispatch => {

  let url = `${path}/api/arrivals/linked-shipments?`;
  url += addQueryString('shipmentIdList', shipmentIdList)

  const response = await getSync(url, spinnerElementId);

  if (response.isSuccess) {
    dispatch({
      type: shipmentInboundOperationTypes.LINKED_SHIPMENT_RECEIVED,
      payload: response.response.result.shipmentList
    });
  }
}

export const getUnlinkedShipmentList = (filter, spinnerElementId = null) => async dispatch => {

  let url = `${path}/api/arrivals/unlinked-shipments?`;
  if (filter.shipmentNo) {
    url += `shipmentNo=${filter.shipmentNo}`
  }

  if (filter.groupCode) {
    url += `groupCode=${filter.groupCode}`
  }

  const response = await getSync(url, spinnerElementId)

  if (response.isSuccess) {
    dispatch({
      type: shipmentInboundOperationTypes.AVAILABLE_SHIPMENTS_LISTED,
      payload: response.response.result.shipmentList
    });
  }
}

export const linkShipmentsToOperation = (shipmentIdList, operationId, spinnerElementId = null) => async dispatch => {

  const url = `${path}/api/arrivals/link-shipments`;
  const request = { shipmentIdList, operationId };

  const response = await postSync(url, request, spinnerElementId)
  if (response.isSuccess) {
    dispatch({
      type: shipmentInboundOperationTypes.SHIPMENTS_LINKED,
      payload: shipmentIdList
    });
  }
}

export const unlinkShipmentsFromOperation = (shipmentIdList, operationId, spinnerElementId = null) => async dispatch => {

  const url = `${path}/api/arrivals/unlink-shipments`;
  const request = { shipmentIdList, operationId };

  const response = await postSync(url, request, spinnerElementId)
  if (response.isSuccess) {
    dispatch({
      type: shipmentInboundOperationTypes.SHIPMENTS_UNLINKED,
      payload: shipmentIdList
    });
  }

}