import { get , post} from "../../core/httpClientSync";
import { spinnerService } from "@simply007org/react-spinners";
import contractActions from "../type/contract";

const apiUrl = 'xms-slm-api/api/contractdefinition';

export const getContractList = () => async dispatch => {
    const endPoint = `${apiUrl}/getactivebycompany`;
    //spinnerService.show('getCompanyList')
    var response = await post(endPoint)
    if(response.isSuccess){
        dispatch({
            type: contractActions.CONTRACT_GETPASSIVECONTRACTS_SUCCESS,
            payload: response.data.result
        });
    }
}

export const insertOrUpdateContract = (contract) => async dispatch => {
    const endPoint = `${apiUrl}/save`;
    spinnerService.show('contractDetail')

    var response = await post(endPoint, contract);
    if(response.isSuccess){
        dispatch({
            type: contractActions.CONTRACT_SAVECONTRACT_SUCCESS
        });
    }

    spinnerService.hide('contractDetail');
}

export const getActiveContractByCompanyCode = (code) => async dispatch => {
    const endPoint = `${apiUrl}/getactivebycompany/${code}`;
    spinnerService.show('contractDetail');
    var response = await get(endPoint);
    
    if(response.isSuccess)
    {
        dispatch({
            type: contractActions.CONTRACT_GETCONTRACT_SUCCESS,
            payload: response.response.result
        });
    }
    
    spinnerService.hide('contractDetail');
}

export const getContract = (id) => async dispatch => {
    const endPoint = `${apiUrl}/get/${id}`;
    spinnerService.show('contractDetail');
    const response = await get(endPoint, 'contractDetail');

    if (response.isSuccess) {
        dispatch({
            type: contractActions.CONTRACT_RETRIEVED,
            payload: response.response.result
        });
    }
}
export const endContract = (id) => async dispatch => {
    const endPoint = `${apiUrl}/endcontract/${id}`;
    spinnerService.show('contractDetail');
    const response = await get(endPoint, 'contractDetail');

    if (response.isSuccess) {
        dispatch({
            type: contractActions.CONTRACT_ENDED,
            payload: response.response.result
        });
    }
}
export const getDraftContract = (code) => async dispatch => {
    const endPoint = `${apiUrl}/getdraftbycompany/${code}`;
    spinnerService.show('contractDetail');
    var response = await get(endPoint)
    if(response.isSuccess)
    {
        dispatch({
            type: contractActions.CONTRACT_GETDRAFTCONTRACT_SUCCESS,
            payload: response.response.result
        });
    }
    
    spinnerService.hide('contractDetail');
}

export const clearContractState = () => async dispatch => {
    dispatch({
        type: contractActions.CONTRACT_CLEARCONTRACTSTATE_SUCCESS
    });
    dispatch({
        type: contractActions.CONTRACT_CLEARCONTRACTSTATE_SUCCESS
    });
}