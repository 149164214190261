import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { ButtonStack, Button } from '@dfds-ui/react-components';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';

const Form = ({ transport, isEdit, loader, onItemChange, onSaveClick, onClearClick }) => {

    const renderField = (label, name, value, readonly) => {
        return (
            <Col md={4}>
                <div className='form-group'>
                    <label>{label}</label>
                    <input
                        name={name}
                        type='text'
                        className='form-control'
                        onKeyUp={onItemChange}
                        onChange={onItemChange}
                        autoComplete="off"
                        value={value ? value : ''}
                        readOnly={readonly ? true : false}
                    ></input>
                </div>
            </Col>
        )
    }

    return (<>
        <Card>
            <Card.Body>
                <Row>
                    {renderField('Code', 'code', transport.code, isEdit)}
                    {renderField('Carrier', 'carrierId', transport.carrierId)}
                    {/* {renderField('Type', 'type', transport.type, true)} */}
                </Row>
                <Row>
                    {renderField('Unit Type', 'unitType', transport.unitType)}
                    {renderField('Loading Type', 'loadingType', transport.loadingType)}
                    {renderField('Leg Type', 'legType', transport.legType)}
                </Row>
                <Row>
                    <Col>
                        <ButtonStack align="right">
                            <Button onClick={() => onClearClick()} variation="secondary">Clear</Button>
                            <Button onClick={() => onSaveClick(transport)}>Save</Button>
                        </ButtonStack>
                    </Col>
                </Row>
                <LoadingIndicator id={loader} />
            </Card.Body>
        </Card>
    </>)
}

export default Form;