import React from 'react';
import { connect } from 'react-redux';
import styles from './ImageController.module.css';
import {
    deleteImage,
    updateImageComment,
    downloadSingleImage,
    showImageOnRealSize
} from '../../store/action/imageControllerActions';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { Button, ButtonStack } from '@dfds-ui/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PolicyGroupAccessHoc from "../../RoutedComponents/Routes/PolicyGroupAccessHoc";

const ImageListItem = (props) => {

    // from parent component
    const {
        image,
        imageControllerOptions
    } = props;

    //from store
    const {
        deleteImage,
        updateImageComment,
        downloadSingleImage,
        showImageOnRealSize
    } = props;

    const onDeleteImage = () => {
        if (image.status !== 'uploaded') {
            deleteImage(image, imageControllerOptions.relativePathForFetch, `imageItemList_${image.uid}`);
        } else {
            if (window.confirm('Image will be deleted. Are you sure?')) {
                deleteImage(image, imageControllerOptions.relativePathForFetch, `imageItemList_${image.uid}`);
            }
        }
    }

    const onCommentChange = (event) => {
        const newValue = event.target.value;
        updateImageComment(image.uid, newValue);
    }

    const onDownloadImage = () => {
        downloadSingleImage(image, imageControllerOptions.relativePathForFetch, `imageItemList_${image.uid}`);
    }

    const onShowImage = () => {
        showImageOnRealSize(image.original, image);
    }

    return (
        <div className='card mb-2'>
            <div className='card-body p-0'>
                <div className='row'>
                    {renderImageThumbnail(image)}
                    <div className='col d-flex flex-column'>
                        {renderInformationField(image, onCommentChange)}
                        {renderItemActionButtons(image, onDeleteImage, onDownloadImage, onShowImage)}
                    </div>
                </div>
            </div>
            <LoadingIndicator id={`imageItemList_${image.uid}`} />
        </div>
    )

}

const renderImageThumbnail = (image) => {

    if (image.thumbnail) {
        return (
            <div>
                <img
                    src={image.thumbnail}
                    className={`${styles.imagePreview}`}
                    alt={image.name}
                />
            </div>
        )
    }

    return (
        <div>
            <img
                src={URL.createObjectURL(image)}
                className={`${styles.imagePreview}`}
                alt={image.name}
            />
        </div>
    )
}

const renderInformationField = (image, onCommentChange) => {

    if (image.status === 'not-uploaded') {
        return (
            <div className='form-group mr-3 mt-1'>
                <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Comment...'
                    onChange={onCommentChange}
                ></input>
            </div>
        )
    }

    let imageComment = image.comment ? image.comment : '';
    if (!imageComment) {
        if (image.data) {
            const imageData = JSON.parse(image.data);
            if (imageData) {
                imageComment = imageData.comment;
            }
        }
    }

    if (imageComment) {
        return (
            <span className='text-muted'>{imageComment}</span>
        )
    }
}

const renderItemActionButtons = (image, onDeleteImage, onDownloadImage, onShowImage) => {

    const isDownloadButtonDisabled = image.status === 'not-uploaded';

    return (
        <div className='mt-auto'>
            <div className='col'>
                <div className='row'>
                    <div className='col-md-4'>
                        {renderStatusIcon(image.status)}
                    </div>
                    <div className='col-md-8'>
                        <ButtonStack align='right'>
                            <Button size="small" variation="outlined" onClick={onShowImage}>
                                <FontAwesomeIcon icon="expand-arrows-alt" />
                            </Button>
                            <Button size="small" variation="secondary" disabled={isDownloadButtonDisabled} onClick={onDownloadImage}>
                                <FontAwesomeIcon icon="download" />
                            </Button>
                            <PolicyGroupAccessHoc componentName="FileControllerOperationButtonsHOC">
                                <Button size="small" variation="primary" onClick={onDeleteImage}>
                                    <FontAwesomeIcon icon="trash" />
                                </Button>
                            </PolicyGroupAccessHoc>
                        </ButtonStack>
                    </div>
                </div>
            </div>
        </div >
    )
}

const renderStatusIcon = (status) => {

    if (!status) { return; }

    if (status === 'not-uploaded') {
        return (
            <span title='Ready to Upload'>
                <FontAwesomeIcon icon="clock" size="2x" />
            </span>
        )
    }

    return (
        <span className='text-success' title='Uploaded'>
            <FontAwesomeIcon icon="check-circle" size="2x" />
        </span>
    )
}

const mapActionToProps = {
    deleteImage,
    updateImageComment,
    downloadSingleImage,
    showImageOnRealSize
}

export default connect(null, mapActionToProps)(ImageListItem);