import moment from 'moment';

export const epochToShortDate = (epochTime, format = 'L') => {
    return moment.utc(epochTime * 1000).format(format);
}

export const epochToShortDateTime = (epochTime, format = 'MMM D YYYY, HH:mm', isMiliseconds = false) => {
    return moment.utc(epochTime * (isMiliseconds ? 1 : 1000)).format(format)
}

export const epochToDatetime = (epochMs) => {
    return new Date(epochMs);
}