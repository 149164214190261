import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Form, Col, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import XmsTable from '../XmsTable/XmsTable';



const ReduxFormControl = ({ input, meta: { touched, error, warning }, ...props }) => {
    return <><Form.Control {...props} {...input} />
        {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span className="text-warning">{warning}</span>))}
    </>
};

// const checkName = value => value && value === 'new' ? `Code cannot be "new".` : undefined;
// const required = value => value ? undefined : 'Required';



let BusinessAreaForm = (props) => {
    const { isNew, pristine, submitting, businessArea, companies, companyValue, change } = props;
    const [businessAreaCompanies, setBusinessAreaCompanies] = useState(businessArea ? businessArea.companies : []);

    console.log('companies', companies);

    useEffect(() => {
        if (businessArea) {
            setBusinessAreaCompanies(businessArea.companies);
        }
    }, [businessArea]);

    const addCompanyClickedHandler = (e) => {
        e.preventDefault();
        console.log('companyValue', companyValue);
        if (!companyValue.id) {
            toast.error('Company is required.');
            return;
        }

        setBusinessAreaCompanies((prevState, props) => {
            if (!prevState) {
                prevState = [];
            }
            console.log('prevState', prevState);
            const businessAreaCompaniesClone = [...prevState];
            if (!businessAreaCompaniesClone.some(x => x.id === companyValue.id)) {
                businessAreaCompaniesClone.push(companyValue);
            }
            
            return businessAreaCompaniesClone;
        });

        change('company', '');
    }

    const deleteCompanyClickedHandler = (company, e) => {
        e.preventDefault();

        setBusinessAreaCompanies((prevState, props) => {
            const businessAreaCompaniesClone = [...prevState];
            const indexToRemove = businessAreaCompaniesClone.findIndex(x => x.id === company.id);
            businessAreaCompaniesClone.splice(indexToRemove, 1);
            return businessAreaCompaniesClone;
        });
    }

    const tableConfigurations = {
        noRowText: '',
        hasFilter: false,
        entities: businessAreaCompanies,
        columns: [
            { alias: 'Name', name: 'name', type: 'string' },
        ],
        rowActions: [
            { title: 'Delete business area company', clickEvent: deleteCompanyClickedHandler, icon: 'trash' },
        ],
    }

    const handleBeforeSubmit = (form) => {
        console.log('FORM:', form);
        const formWithCompanies = {
            ...form,
            companies: businessAreaCompanies
        }
        props.onSubmit(formWithCompanies);
    }

    // <Form onSubmit={handleSubmit}>
    //     <Form.Row>
    //         {/* <Form.Group as={Col} md="6">
    //             <Form.Label>BusinessAreaId</Form.Label>
    //             <Field
    //                 name="code"
    //                 component={ReduxFormControl}
    //                 type="text"
    //                 placeholder="Service code"
    //                 disabled={!isNew}
    //                 validate={[required, checkName]}
    //                 required
    //             />
    //         </Form.Group> */}
    //         <Form.Group as={Col} md="6">
    //             <Form.Label>Name</Form.Label>
    //             <Field
    //                 name="name"
    //                 component={ReduxFormControl}
    //                 type="text"
    //                 placeholder="Name"
    //                 required
    //             />
    //         </Form.Group>
    //     </Form.Row>
    //     <div>
    //         <Button type="submit" disabled={pristine || submitting}>Save</Button>
    //     </div>
    // </Form>


    return (
        <Form onSubmit={props.handleSubmit(handleBeforeSubmit.bind(this))} autoComplete="off">
            <fieldset /*disabled={!isNewOrDraft}*/>
                <Form.Row>
                    <Form.Group as={Col} md="12">
                        <Form.Label>Business Area Name</Form.Label>
                        <Field
                            name="name"
                            component={ReduxFormControl}
                            type="text"
                            placeholder="Business Area Name"
                            required
                        />
                    </Form.Group>
                    <Form.Row>
                        <Form.Group as={Col} md="12">
                            <br />
                            <Form.Label>Companies</Form.Label>
                        </Form.Group>
                    </Form.Row>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="12">
                        <XmsTable config={tableConfigurations} />
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                        <Field as="select" name="company" placeholder='Select company' component={ReduxFormControl}>
                            <option value=''>Select company</option>
                            {companies && companies.map((company) => (
                                <option value={JSON.stringify(company)} key={company.value}>
                                    {company.text}
                                </option>
                            ))}
                        </Field>
                    </Form.Group>
                    <Form.Group as={Col} md="1">
                        <Button size='medium' style={{ height: '38px', width: '100%' }} onClick={addCompanyClickedHandler}>Add</Button>
                    </Form.Group>
                </Form.Row>

                <Button type="submit" disabled={submitting} className='float-right'>Save</Button>
            </fieldset>
        </Form >
    );
};


BusinessAreaForm = reduxForm({
    form: 'insertOrUpdateBusinessAreaForm', // a unique identifier for this form
})(BusinessAreaForm);

const selector = formValueSelector('insertOrUpdateBusinessAreaForm')


BusinessAreaForm = connect(
    state => {
        const companyObjectStr = selector(state, 'company');
        let companyObject = {};
        if (companyObjectStr) {
            companyObject = JSON.parse(companyObjectStr);
        }

        return {
            initialValues: state.businessArea.businessArea, // pull initial values from service reducer
            companyValue: {
                id: companyObject.value,
                name: companyObject.text
            }
        }
    }
)(BusinessAreaForm);

export default BusinessAreaForm;
