import { createStore, compose, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
// import { createLogger } from 'redux-logger';
import reducers from './reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const loggerMiddleware = createLogger();

const store = createStore(
    reducers,
    composeEnhancers(
        applyMiddleware(
            reduxThunk,
            // loggerMiddleware
        )
    )
);

export default store;