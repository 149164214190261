import React from 'react';
import './Footer.css';
import { Logo } from '@dfds-ui/react-components/logo'
import '../../core/utility';
import { buildNumber } from "../../core/utility";
import { Row, Col } from 'react-bootstrap';

const Footer = () => {
    return (<footer className="main-footer d-none d-md-inline-block">
        <Col md="12">
            <Row>
                <Col md="8" className="text-left">
                    <Row>
                        <Col md="12 d-none d-md-inline-block" >
                            <Logo height="23px" width="100px" />
                            <span className="ml-2"><b> Copyright © {new Date().getFullYear()} DFDS </b>  All rights reserved.</span>
                        </Col>
                    </Row>
                </Col>
                <Col md="4" className="text-right  d-none d-md-inline-block">
                    <b>Build:</b> {buildNumber}
                </Col>
            </Row>
        </Col>
    </footer>);
};

export default Footer; 