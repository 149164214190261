import modalTypes from '../type/modal';

export const showModal = (payload) => {
    return {
        type: modalTypes.SHOW_MODAL,
        payload
    }
}

export const closeModals = () => async dispatch => {
    dispatch({
        type: modalTypes.HIDE_MODAL
    })
}

export const confirmationModal = (title, message, onConfirm) => async dispatch => {
    dispatch({
        type: modalTypes.SHOW_MODAL,
        payload: {
            modalType: 'confirmation',
            modalData: { title: title, message: message, onConfirm: onConfirm }
        }
    })
}

export const confirmToDelete = (onConfirm) => async dispatch => {
    dispatch({
        type: modalTypes.SHOW_MODAL,
        payload: {
            modalType: 'confirmation',
            modalData: { title: 'Confirm to delete', message: `Are you sure you want to delete this?`, confirmButtonText: 'Yes', cancelButtonText: 'No', onConfirm: onConfirm }
        }
    })
}