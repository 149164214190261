import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import history from '../core/history';
import { Button, ButtonStack, SelectField } from '@dfds-ui/react-components';
import { Row, Col, } from 'react-bootstrap';
import { clearThuData, createThu, getPackageTypeList, getShipment } from '../store/action/thuDetailAction';
import PageHeader from '../component/PageHeader/PageHeader';
import ThuList from '../component/Thu/ThuList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import { getBarcodeResult } from '../core/utility';
import { getSettings, searchSuffixes } from '../store/ducks/definitions/actionCreators';

const CreateThu = (props) => {

    //redux elements
    const { clearThuData, thuList, createThu, getPackageTypeList, packageTypeList, getSettings, barcodeRules, getShipment, shipment, searchSuffixes, suffixes } = props;

    //parent inputs
    const {
        shipmentId,
        shipmentNo
    } = props.match.params;

    const [myTimeOut, setMyTimeOut] = useState();
    const [weightTimeout, setWeightTimeout] = useState();
    const [inputValue, setInputValue] = useState('');
    const [inputValueElement, setInputValueElement] = useState('');
    const [externalId, setExternalId] = useState('');
    const [supplierCode, setSupplierCode] = useState('');
    const [deliveryNote, setDeliveryNote] = useState('');
    const [externalCode, setExternalCode] = useState('');
    const [quantity, setQuantity] = useState('');
    const [packageType, setPackageType] = useState('');
    const [weight, setWeight] = useState('');
    const [tempWeight, setTempWeight] = useState('');
    const [volume, setVolume] = useState('');
    const [isGLabel, setIsGLabel] = useState(false);
    const [message, setMessage] = useState('');
    const [articleFields, setArticleFields] = useState([]);

    useEffect(() => {
        clearThuData();
        reset(true);
        getShipment(shipmentId)
        getSettings(CONSTANTS.BARCODERULES);
        if (inputValueElement) {
            inputValueElement.focus();
        }
    }, [])

    useEffect(() => {
        if (shipment) {
            searchSuffixes({ query: shipment.destinationId });
            getPackageTypeList();
        }
    }, [shipment])

    useEffect(() => {
        var list = [];

        var suffix = suffixes.items[0];
        if (suffix && suffix.articleFields && suffix.articleFields.length > 0) {
            list = suffix.articleFields;
        }

        setArticleFields(list);

    }, [suffixes])

    useEffect(() => {
        if (myTimeOut) { clearTimeout(myTimeOut) };

        const newTimeOut = setTimeout(() => {
            evaluateNewValue(
                inputValue,
                setInputValue,
                setExternalId,
                setSupplierCode,
                setDeliveryNote,
                setExternalCode,
                setQuantity
            );
        }, 150);

        setMyTimeOut(newTimeOut);
    }, [inputValue])

    useEffect(() => {
        if (weightTimeout) { clearTimeout(weightTimeout) };
        const newTimeOut = setTimeout(() => {
            if (tempWeight) {
                setWeight(tempWeight);
            }
        }, 1000);
        setWeightTimeout(newTimeOut);
    }, [tempWeight])

    useEffect(() => {
        checkThuIsReady();
    }, [externalId, supplierCode, deliveryNote, externalCode, quantity, packageType, weight]);

    useEffect(() => {
        if (inputValueElement) {
            inputValueElement.focus();
        }
    }, [externalId, supplierCode, deliveryNote, externalCode, quantity]);

    const reset = (force) => {
        if (force || !isGLabel) {
            setExternalId('');
            setDeliveryNote('');
            setIsGLabel(false);
        }
        setInputValue('');
        setSupplierCode('');
        setExternalCode('');
        setQuantity('');
        setPackageType('');
        setVolume('');
        setWeight('');
        setTempWeight('');
        setMessage('');
    }

    const unlock = () => {
        setExternalId('');
        setDeliveryNote('');
        setIsGLabel(false);
        setMessage('');
    }

    const checkThuIsReady = () => {

        let isOk = externalId != '' &&
            (articleFields.includes('supplierCode') ? supplierCode != '' : true) &&
            (articleFields.includes('deliveryNote') ? deliveryNote != '' : true) &&
            (articleFields.includes('externalCode') ? externalCode != '' : true) &&
            (articleFields.includes('quantity') ? quantity != '' : true);

        if (articleFields.includes('packageType')) {
            isOk = isOk && packageType != '' && weight != '';
        }

        if (isOk) {
            createThu({ shipmentId, externalId, externalCode, quantity, supplierCode, deliveryNote, packageType, weight, volume }, `${CONSTANTS.FORMLOADER}`);
            reset(false);
        }
    }

    const onPackageTypeChange = (type) => {
        var packageType = packageTypeList.find(s => s.type === type);
        setVolume(packageType.volume);
        setPackageType(type);
    }

    const renderValueFields = () => {
        return (
            <>
                {renderInputField(inputValue, setInputValue, setInputValueElement)}
                {renderMessage()}
                <Row className={isGLabel ? "background-light" : ""}>
                    {renderSelectedField('(S),(M),(G) Serial Number (*)', externalId)}
                    {articleFields.includes('deliveryNote') ? renderSelectedField('(N) Delivery Note (*)', deliveryNote) : null}
                </Row>
                <Row>
                    {articleFields.includes('supplierCode') ? renderSelectedField('(V) Supplier No (*)', supplierCode) : null}
                    {articleFields.includes('externalCode') ? renderSelectedField('(P) Part Number (*)', externalCode) : null}
                    {articleFields.includes('quantity') ? renderSelectedField('(Q) Quantity (*)', quantity) : null}
                    {articleFields.includes('packageType') ? renderSelectBox('Package Type (*)', packageType, packageTypeList, onPackageTypeChange) : null}
                    {articleFields.includes('packageType') ? renderField('Gross Weight (*)', tempWeight, setTempWeight) : null}
                </Row>
            </>
        )
    }

    const renderInputField = (inputValue, setInputValue, setInputValueElement) => {
        return (
            <div className='row'>
                <div className="col col-md-6 mx-auto">
                    <div className='form-group'>
                        <label>Input Value</label>
                        <input
                            ref={input => setInputValueElement(input)}
                            type='text'
                            className='form-control'
                            onKeyUp={e => setInputValue(e.target.value)}
                            onChange={e => setInputValue(e.target.value)}
                            value={inputValue}
                            autoFocus
                        ></input>
                        <small>Scan Always <b>Serial Number</b> First!</small>
                    </div>
                </div>
            </div>
        )
    }

    const renderSelectedField = (label, value) => {
        return (
            <Col md={6}>
                <div className='form-group'>
                    <label>{label}</label>
                    <div className="input-group">
                        <input type='text' className='form-control' disabled value={value}></input>
                        <div className="input-group-append">
                            <span className="input-group-text">
                                <FontAwesomeIcon icon={value !== '' ? 'check' : 'clock'} className={value !== '' ? 'color-yes-green' : ''} />
                            </span>
                        </div>
                    </div>
                </div>
            </Col>
        )
    }

    const renderField = (label, value, setValue) => {
        return (
            <Col md={6}>
                <div className='form-group'>
                    <label>{label}</label>
                    <div className="input-group">
                        <input type='number' className='form-control' value={value} onChange={e => setValue(e.target.value)} onKeyUp={e => setValue(e.target.value)}></input>
                        <div className="input-group-append">
                            <span className="input-group-text">
                                <FontAwesomeIcon icon={value !== '' ? 'check' : 'weight-hanging'} className={value !== '' ? 'color-yes-green' : ''} />
                            </span>
                        </div>
                    </div>
                </div>
            </Col>
        )
    }

    const renderSelectBox = (label, value, list, onChange) => {
        return (
            <Col md={6}>
                <div className='form-group'>
                    <label>{label}</label>
                    <SelectField selected={value} onChange={(e) => onChange(e.target.value)} size='small' className='selectbox-labelless'>
                        <option value="">Select</option>
                        {list.map((item) => (
                            <option value={item.type} key={item.type}>
                                {item.type}
                            </option>
                        ))}
                    </SelectField>
                </div>
            </Col>
        )
    }

    const renderMessage = () => {
        if (!message) {
            return;
        }

        return (
            <Row>
                <Col>
                    <div className="alert alert-secondary" role="alert">
                        {message}
                        <Button style={{ float: 'right', marginTop: '-3px' }} onClick={() => unlock()} title="Unlock" size="small" variation="secondary"><FontAwesomeIcon icon={'unlock-alt'} className="mr-2" />Unlock</Button>
                    </div>
                </Col>
            </Row>
        )
    }

    const evaluateNewValue = (
        newValue,
        setInputValue,
        setExternalId,
        setSupplierCode,
        setDeliveryNote,
        setExternalCode,
        setQuantity
    ) => {
        if (!newValue) { return; }

        var barcodeValues = getBarcodeResult(newValue, barcodeRules);

        for (let i = 0; i < barcodeValues.length; i++) {

            const upperCaseValue = barcodeValues[i].toUpperCase();

            const startString = upperCaseValue.slice(0, 1);

            const value = upperCaseValue.slice(1, upperCaseValue.length);

            switch (startString) {
                case 'S':
                case 'M':
                case 'G':
                    if (!isGLabel) {
                        if (externalId !== upperCaseValue) {
                            reset(barcodeValues.length == 1);
                        }
                        setExternalId(upperCaseValue);
                        setIsGLabel(startString === 'G');
                    } else {
                        setMessage('G Label scanned before so you do NOT need to scan Serial Number. If you want to create NEW THU, please click UNLOCK!');
                    }
                    setInputValue('');
                    break;
                case 'N':
                    if (!isGLabel || !deliveryNote) {
                        setDeliveryNote(value);
                    } else {
                        setMessage('G Label scanned before so you do NOT need to scan Delivery Note. If you want to create NEW THU, please click UNLOCK!');
                    }
                    setInputValue('');
                    break;
                case 'V':
                    setSupplierCode(value);
                    setInputValue('');
                    break;
                case 'P':
                    setExternalCode(value);
                    setInputValue('');
                    break;
                case 'Q':
                    setQuantity(value);
                    setInputValue('');
                    break;
                default:
                    return;
            }
        }
    }

    const renderActionButtons = () => {
        return (
            <Row>
                <Col>
                    <ButtonStack align="right">
                        <Button variation="outlined" onClick={() => reset(true)}>Reset</Button>
                        <Button variation="primary" onClick={() => history.goBack()}>Done</Button>
                    </ButtonStack>
                </Col>
            </Row>
        )
    }

    const renderCreateThuDetail = () => {
        return (
            <div className='card card-body'>
                {renderValueFields()}
                {renderActionButtons()}
                <LoadingIndicator id={CONSTANTS.FORMLOADER} />
            </div>
        )
    }

    const renderThuList = () => {
        return (
            <ThuList
                shipmentId={shipmentId}
                shipmentNo={shipmentNo}
                thuList={thuList}
                headerText='Added THU List'
                accordionId='collapseThuDetail' />
        )
    }

    return (
        <div>
            <PageHeader text={`Create THU - ${shipmentNo}`} />
            {renderCreateThuDetail()}
            {renderThuList()}
        </div>
    )
}

const CONSTANTS = {
    FORMLOADER: 'loader-article-form',
    BARCODERULES: 'barcode.rules'
}

const mapStateToProps = ({ thuDetailReducer, definitions, shi }) => {
    return {
        thuList: thuDetailReducer.thuList,
        packageTypeList: thuDetailReducer.packageTypeList,
        barcodeRules: definitions.settings.items.filter(s => s.key === CONSTANTS.BARCODERULES).flatMap(s => s.value),
        shipment: thuDetailReducer.shipment,
        suffixes: definitions.suffixes,
    }
}

const mapActionToProps = {
    clearThuData,
    createThu,
    getPackageTypeList,
    getSettings,
    getShipment,
    searchSuffixes
}

export default connect(mapStateToProps, mapActionToProps)(CreateThu);