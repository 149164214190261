import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';

import { Button, ButtonStack, Switch, Counter, SelectField } from '@dfds-ui/react-components';
import { Row, Col } from 'react-bootstrap';
import { getShipment } from '../store/action/shipmentDetailAction';
import { clearThuData, createAndPrintThuList, getPackageTypeList } from '../store/action/thuDetailAction';
import PageHeader from '../component/PageHeader/PageHeader';
import ThuList from '../component/Thu/ThuList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { searchSuffixes } from '../store/ducks/definitions/actionCreators';

const PrintThu = (props) => {

    //route
    const { shipmentId, shipmentNo } = (props.match && props.match.params) || (props);

    //redux elements
    const { shipment, clearThuData, createAndPrintThuList, thuList, getPackageTypeList, packageTypeList, hideHeader, hideList, getShipment, searchSuffixes, suffixes } = props;

    //parent inputs
    const [readableLabel, setReadableLabel] = useState(false);
    const [notReadableLabel, setNotReadableLabel] = useState(true);
    const [showCounter, setShowCounter] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [counter, setCounter] = useState(1);

    const [externalId, setExternalId] = useState('');
    const [supplierCode, setSupplierCode] = useState('');
    const [deliveryNote, setDeliveryNote] = useState('');
    const [externalCode, setExternalCode] = useState('');
    const [quantity, setQuantity] = useState('');
    const [packageType, setPackageType] = useState('');
    const [volume, setVolume] = useState('');
    const [weight, setWeight] = useState('');
    const [articleFields, setArticleFields] = useState([]);

    useEffect(() => {
        clearThuData();
        reset();
    }, [])

    useEffect(() => {
        if (shipmentId) {
            getShipment(shipmentId)
        }
    }, [shipmentId])

    useEffect(() => {
        if (shipment) {
            searchSuffixes({ query: shipment.destinationId });
            getPackageTypeList();
        }
    }, [shipment])

    useEffect(() => {
        var list = [];

        var suffix = suffixes.items[0];
        if (suffix && suffix.articleFields && suffix.articleFields.length > 0) {
            list = suffix.articleFields;
        }

        setArticleFields(list);

    }, [suffixes])

    const reset = () => {
        setExternalId('');
        setDeliveryNote('');
        setSupplierCode('');
        setExternalCode('');
        setQuantity('');
        setVolume('');
        setPackageType('');
        setWeight('')
    }

    const onGenerateClicked = () => {

        let thuList = [];

        if (readableLabel) {
            if (externalId !== '') {
                thuList.push({
                    externalId: externalId,
                    supplierCode: supplierCode ? supplierCode : null,
                    deliveryNote: deliveryNote ? deliveryNote : null,
                    externalCode: externalCode,
                    quantity: quantity ? quantity : 0,
                    volume: volume,
                    weight: weight
                });
            }
        }

        if (notReadableLabel) {

            for (var i = 0; i < counter; i++) {
                thuList.push({
                    externalId: randomThuNumber(),
                    quantity: 0,
                    volume: 0,
                    weight: 0,
                })
            }
        }

        if (thuList.length > 0) {
            createAndPrintThuList(shipmentId, shipmentNo, thuList, 'print-thu');
            reset();
        }
    }

    const randomThuNumber = () => {
        const min = 1000;
        const max = 9999;

        const random = min + (Math.random() * (max - min))
        const number = `S${new Date().getTime()}${parseInt(random)}`;
        return number;
    }

    const onReadableChange = (selected) => {
        setReadableLabel(selected);
        setShowForm(selected);
        setNotReadableLabel(!selected);
        setShowCounter(!selected);
    }

    const onNotReadableChange = (selected) => {
        setNotReadableLabel(selected);
        setShowCounter(selected);
        setReadableLabel(!selected);
        setShowForm(!selected);
    }

    const onPackageTypeChange = (type) => {
        var packageType = packageTypeList.find(s => s.type === type);
        setVolume(packageType.volume);
        setPackageType(type);
        setVolume(packageType.volume);
    }

    const renderValueFields = () => {
        return (
            <>
                <Row>
                    <Col md={6}>
                        {renderSwitch('printType', 'Label Can Read', readableLabel, onReadableChange)}
                        {renderSwitch('printType', 'Label Cannot Read', notReadableLabel, onNotReadableChange)}
                    </Col>
                    <Col md={6}>
                        {renderCounter(counter, setCounter)}
                    </Col>
                </Row>
                <Row>
                    {renderSelectedField('Serial Number (*)', externalId, setExternalId)}
                    {articleFields.includes('deliveryNote') ? renderSelectedField('Delivery Note', deliveryNote, setDeliveryNote) : null}
                </Row>
                <Row>
                    {articleFields.includes('supplierCode') ? renderSelectedField('Supplier No', supplierCode, setSupplierCode) : null}
                    {articleFields.includes('externalCode') ? renderSelectedField('Part Number (*)', externalCode, setExternalCode) : null}
                    {articleFields.includes('quantity') ? renderSelectedField('Quantity', quantity, setQuantity) : null}
                    {articleFields.includes('packageType') ? renderSelectBox('Package Type (*)', packageType, packageTypeList, onPackageTypeChange) : null}
                    {articleFields.includes('packageType') ? renderField('Gross Weight (*)', weight, setWeight) : null}
                </Row>
            </>
        )
    }

    const renderSwitch = (name, text, checked, onChange) => {
        return (
            <div className='form-group row'>
                <Col lg={6} xs={8}>
                    <label>{text}</label>
                </Col>
                <Col lg={6} xs={4}>
                    <Switch
                        name={name}
                        onChange={e => onChange(e.target.checked)}
                        checked={checked}>
                    </Switch>
                </Col>
            </div>
        )
    }

    const renderCounter = (counter, setCounter) => {
        if (!showCounter) {
            return null;
        }

        return (
            <div className='form-group'>
                <label className='mr-2'>THU Generate Count</label>
                <Counter
                    currentVal={counter}
                    minVal={1}
                    maxVal={100}
                    executeOnChange={e => setCounter(e)}
                />
            </div>
        )
    }


    const renderSelectedField = (label, value, setValue) => {
        if (!showForm) {
            return null;
        }

        return (
            <Col md={6}>
                <div className='form-group'>
                    <label>{label}</label>
                    <input
                        type='text'
                        className='form-control'
                        onKeyUp={e => setValue(e.target.value)}
                        onChange={e => setValue(e.target.value)}
                        value={value}></input>
                </div>
            </Col>
        )
    }

    const renderField = (label, value, setValue) => {

        if (!showForm) {
            return null;
        }

        return (
            <Col md={6}>
                <div className='form-group'>
                    <label>{label}</label>
                    <div className="input-group">
                        <input type='number' className='form-control' value={value} onChange={e => setValue(e.target.value)} onKeyUp={e => setValue(e.target.value)}></input>
                        <div className="input-group-append">
                            <span className="input-group-text">
                                <FontAwesomeIcon icon={value !== '' ? 'check' : 'weight-hanging'} className={value !== '' ? 'color-yes-green' : ''} />
                            </span>
                        </div>
                    </div>
                </div>
            </Col>
        )
    }

    const renderSelectBox = (label, value, list, onChange) => {

        if (!showForm) {
            return null;
        }

        return (
            <Col md={6}>
                <div className='form-group'>
                    <label>{label}</label>
                    <SelectField selected={value} onChange={(e) => onChange(e.target.value)} size='small' className='selectbox-labelless'>
                        <option value="">Select</option>
                        {list.map((item) => (
                            <option value={item.type} key={item.type}>
                                {item.type}
                            </option>
                        ))}
                    </SelectField>
                </div>
            </Col>
        )
    }

    const renderActionButtons = () => {
        return (
            <Row>
                <Col md={6}></Col>
                <Col md={6}>
                    <ButtonStack align="right">
                        <Button variation="outlined" onClick={reset}>Reset</Button>
                        <Button variation="primary" onClick={onGenerateClicked} disabled={!shipmentId}>Generate</Button>
                    </ButtonStack>
                </Col>
            </Row>
        )
    }

    const renderCreateThuDetail = () => {
        return (
            <div className='card card-body'>
                {renderValueFields()}
                {renderActionButtons()}
                <LoadingIndicator id={'print-thu'} />
            </div>
        )
    }

    const renderThuList = () => {
        if (hideList) {
            return <></>
        }

        return (
            <ThuList
                thuList={thuList}
                shipmentId={shipmentId}
                shipmentNo={shipmentNo}
                headerText='Printed THU List'
                accordionId='collapseThuDetail' />
        )
    }

    const renderHeader = () => {
        if (hideHeader) {
            return <></>
        }

        return (
            <PageHeader text={`Print THU - ${shipmentNo}`} />
        )
    }

    return (
        <div>
            {renderHeader()}
            {renderCreateThuDetail()}
            {renderThuList()}
        </div>
    )
}

const mapStateToProps = ({ thuDetailReducer, definitions, shipmentDetail }) => {
    return {
        shipment: shipmentDetail.shipment,
        thuList: thuDetailReducer.thuList,
        packageTypeList: thuDetailReducer.packageTypeList,
        suffixes: definitions.suffixes,
    }
}

const mapActionToProps = {
    clearThuData,
    getShipment,
    createAndPrintThuList,
    getPackageTypeList,
    searchSuffixes
}

export default connect(mapStateToProps, mapActionToProps)(PrintThu);