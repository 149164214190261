import { formButtonBehaviour, formInputTypes } from "../FormComponentTypes";

export const CreateBookingSchema = (props) =>
{
    var CreateSchema = {
        Id: 'CreateBookingForm',
        SubmitButton: {
            Name: 'Continue',
            Behaviour: formButtonBehaviour.BUTTON_NONE
        },
        ColumnCountSettings: {
            columnCount: 2,
        },
        FormDesign: [
            {
                groupName: 'Hidden',
                ordering: 1,
                isTagVisible: false
            },
            {
                groupName: 'BasicsTop',
                ordering: 2,
                isTagVisible: false
            },
            {
                groupName: 'BasicsMiddle',
                ordering: 3,
                isTagVisible: false
            },
            {
                groupName: 'BasicsBottom',
                ordering: 4,
                isTagVisible: false
            },
            {
                groupName: 'Details',
                ordering: 5,
                isTagVisible: true
            },
            {
                groupName: 'Details1',
                ordering: 6,
                isTagVisible: false
            },
            {
                groupName: 'Details2',
                ordering: 7,
                isTagVisible: false
            },
            {
                groupName: 'Details3',
                ordering: 8,
                isTagVisible: false
            },
            {
                groupName: 'Notes',
                ordering: 10,
                isTagVisible: true
            }
        ],
        Rules: [
            'IF @clientId IS #EMPTY THEN @businessAreaId is #DISABLED',
            'IF @clientId IS #EMPTY THEN @serviceId is #DISABLED',
            'IF @operationType IS #EMPTY THEN @serviceId is #DISABLED',
        ],
        Fields: [
            {
                name: 'Client',
                id: 'clientId',
                type: formInputTypes.COMBO_INPUT,
                listOfItems: 'clients',
                isRequired: true,
                ordering: 10,
                formDesign: 'BasicsTop',
            },
    
            {
                name: 'Business Area',
                id: 'businessAreaId',
                type: formInputTypes.COMBO_INPUT,
                listOfItems: 'businessAreas',
                firstItemObject: 'businessAreasFirstItem',
                isRequired: true,
                ordering: 20,
                formDesign: 'BasicsTop',
                validations: [],
            },
    
            {
                name: 'Operation Type',
                id: 'operationType',
                type: formInputTypes.COMBO_INPUT,
                listOfItems: 'operationTypes',
                firstItemObject: 'typeFirstItemObject',
                isRequired: true,
                ordering: 30,
                formDesign: 'BasicsTop',
                validations: []
            },
    
            {
                name: 'Service',
                id: 'serviceId',
                type: formInputTypes.COMBO_INPUT,
                listOfItems: 'services',
                isRequired: true,
                ordering: 40,
                formDesign: 'BasicsTop',
            },
    
            {
                name: 'Unit Type',
                id: 'unitType',
                type: formInputTypes.COMBO_INPUT,
                listOfItems: 'unitTypes',
                firstItemObject: 'unitTypeFirstItemObject',
                isRequired: true,
                ordering: 50,
                formDesign: 'BasicsTop',
                validations: []
            },
            {
                name: 'Unit No',
                id: 'unitNo',
                type: formInputTypes.STRING_INPUT,
                isRequired: true,
                ordering: 60,
                formDesign: 'BasicsTop',
                
            },
            {
                name: 'THU(s)',
                id: 'thuAmount',
                type: formInputTypes.NUMBER_INPUT,
                step: 1,
                minValue: 0,
                isRequired: true,
                ordering: 70,
                formDesign: 'BasicsTop',
       
            },
            {
                name: 'Ref/Order Numbers/Transports',
                id: 'transportNumbers',
                type: formInputTypes.STRING_INPUT,
                isRequired: true,
                ordering: 80,
                formDesign: 'BasicsTop' 
            },
            {
                name: 'Contains Dangerous Goods',
                id: 'hasDangerousGoods',
                type: formInputTypes.RADIO_INPUT,
                options: [
                    {
                        text: 'Yes',
                        value: 'true'
                    },
                    {
                        text: 'No',
                        value: 'false'
                    }
                ],
                isRequired: true,
                ordering: 90,
                formDesign: 'BasicsTop', 
            },
            {
                name: 'Customs Clearance Needed',
                id: 'hasCustoms',
                type: formInputTypes.RADIO_INPUT,
                options: [
                    {
                        text: 'Yes',
                        value: 'true'
                    },
                    {
                        text: 'No',
                        value: 'false'
                    }
                ],
                isRequired: true,
                ordering: 100,
                formDesign: 'BasicsTop', 
            },
    
            {
                name: 'Preferred Arrival Time',
                id: 'preferredBookingDate',
                type: formInputTypes.DATE_INPUT,
                isRequired:true,
                isTodayCheck: false,
                ordering: 110,
                formDesign: 'BasicsTop',
                validations: [value => value ? props.bookingValidation() : 'This field is required' ]
            },
     
            {
                name: 'Name',
                id: 'name',
                type: formInputTypes.STRING_INPUT,
                isRequired: false,
                ordering: 10,
                formDesign: 'Details',
                validations: []
            },
            {
                name: 'Surname',
                id: 'surname',
                type: formInputTypes.STRING_INPUT,
                isRequired: false,
                ordering: 20,
                formDesign: 'Details',
                validations: []
            },
    
            
            {
                name: 'Carrier',
                id: 'carrier',
                type: formInputTypes.STRING_INPUT,
                authorizationWrapper: 'AdministratorHoc',
                isRequired: false,
                ordering: 30,
                formDesign: 'Details',
                validations: []
            },
    
           
            {
                name: 'Email',
                id: 'email',
                type: formInputTypes.STRING_INPUT,
                isRequired: false,
                ordering: 40,
                formDesign: 'Details',
                validations: []
            },
            {
                name: 'Phone Number',
                id: 'phoneNumber',
                type: formInputTypes.STRING_INPUT,
                isRequired: false,
                ordering: 50,
                formDesign: 'Details',
                validations: []
            },
    
            
            {
                name: 'Admin Notes',
                id: 'administratorNote',
                type: formInputTypes.TEXTAREA_INPUT,
                authorizationWrapper: 'AdministratorHoc',
                rowCount: 5,
                ordering: 150,
                formDesign: 'Notes',
            },
            {
                name: 'Notes',
                id: 'userNote',
                type: formInputTypes.TEXTAREA_INPUT,
                rowCount: 5,
                ordering: 160,
                formDesign: 'Notes',
            }
        ]
    };

    return CreateSchema;
};

 
