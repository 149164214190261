import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Col, Row } from 'react-bootstrap';
import { Button, ButtonStack } from '@dfds-ui/react-components';
import { clearState, createShipment, createShipmentNo } from '../../store/action/shipmentActions'
import { searchAssociates, searchAssociatesSuccess, searchSuffixes } from '../../store/ducks/definitions/actionCreators';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { InputDatalist } from '../InputDatalist/InputDatalist';
import history from '../../core/history';
import { getValue } from '../../core/utility';
import styles from '../../RoutedComponents/Shipments.module.css';

const ModalAddShipment = (props) => {

    const {
        showModal = true,
        onHide,
        createShipment,
        createShipmentNo,
        associates,
        suffixes,
        searchAssociates,
        searchAssociatesSuccess,
        searchSuffixes,
        clearState,
        createdShipment,
        createdShipmentNo
    } = props;

    const [shipment, setShipment] = useState({ unLoadHere: true });
    const [error, setError] = useState({});
    const [selectedSuffix, setSelectedSuffix] = useState('');

    useEffect(() => {
        createShipmentNo();
        return () => {
            clearState();
        }
    }, [])

    useEffect(() => {
        if (createdShipment) {
            history.push(`/shipment/${createdShipment.id}`)
        }
    }, [createdShipment])

    useEffect(() => {
        if (createdShipmentNo) {
            setShipment({ ...shipment, shipmentNo: createdShipmentNo });
        }
    }, [createdShipmentNo])

    useEffect(() => {
        const errorValidation = {};

        if (shipment.destinationId) {
            if (suffixes.items.map(item => item.id).includes(shipment.destinationId) === false) {
                errorValidation.destinationId = 'Suffix must be an existing one';
            }
        }

        if (shipment.supplierId) {
            if (associates.items.map(item => item.id).includes(shipment.supplierId) === false) {
                errorValidation.supplierId = 'Supplier must be an existing one';
            }
        }

        if (shipment.endCustomerId && selectedSuffix.endCustomers && selectedSuffix.endCustomers.length > 0 && selectedSuffix.endCustomers[0].name !== shipment.endCustomer.name) {

            if (associates.items.map(item => item.id).includes(shipment.endCustomerId) === false) {
                errorValidation.endCustomerId = 'End Customer must be an existing one';
            }
        }

        if (shipment.carrierId) {
            if (associates.items.map(item => item.id).includes(shipment.carrierId) === false) {
                errorValidation.carrierId = 'Carrier must be an existing one';
            }
        }

        if (shipment.firstConsignorId) {
            if (associates.items.map(item => item.id).includes(shipment.firstConsignorId) === false) {
                errorValidation.firstConsignorId = 'First Consignor must be an existing one';
            }
        }

        setError(errorValidation);

    }, [associates.items, suffixes.items, shipment]);

    const validation = () => {
        const errorValidation = {};

        if (!shipment.shipmentNo) {
            errorValidation.shipmentNo = 'Shipment is mandatory';
        }

        if (!shipment.supplierId) {
            errorValidation.supplierId = 'Supplier is mandatory';
        }

        if (!shipment.destinationId) {
            errorValidation.destinationId = 'Suffix is mandatory';
        }

        if (!shipment.endCustomerId) {
            errorValidation.endCustomerId = 'End Customer is mandatory';
        }

        if (shipment.groupCode && !shipment.carrierId) {
            errorValidation.carrierId = 'If Transport exists Carrier is mandatory';
        }

        if (!shipment.groupCode && shipment.carrierId) {
            errorValidation.groupCode = 'If Carrier exists Transport is mandatory';
        }

        if (shipment.itemQuantity) {
            if (isNaN(Number(shipment.itemQuantity))) {
                errorValidation.itemQuantity = 'THU Count must be number';
            } else if (Number(shipment.itemQuantity) < 0) {
                errorValidation.volume = 'THU Count must be equal or greater than 0';
            }
        }

        if (shipment.volume) {
            if (isNaN(Number(shipment.volume))) {
                errorValidation.volume = 'Volume must be number';
            } else if (Number(shipment.volume) < 0) {
                errorValidation.volume = 'Volume must be equal or greater than 0';
            }
        }

        if (shipment.grossWeight) {
            if (isNaN(Number(shipment.grossWeight))) {
                errorValidation.grossWeight = 'Weight must be number';
            } else if (Number(shipment.grossWeight) < 0) {
                errorValidation.grossWeight = 'Weight must be equal or greater than 0';
            }
        }
        setError(errorValidation);

        return errorValidation;
    }
    /** end VALIDATION */

    const onChangeInputDatalist = (event) => {
        // if the typed/selected text include matches any existing assoc set shipment state fields with those values from found assoc
        const matchedAssoc = associates.items.find(item => {
            return (
                [item.code, item.name].some((element) => element === event.target.value)
                ||
                `(${item['code']})${delimiter(item)}${item['name']}` === event.target.value
            );
        });

        if (matchedAssoc || event.target.value === '') {
            // set id, and name fields: e.g. supplierId and supplier
            setShipment({ ...shipment, [event.target.name.split(",")[0]]: matchedAssoc && matchedAssoc.id, [event.target.name.split(",")[1]]: matchedAssoc });
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (Object.entries(validation()).length === 0) {
            createShipment(shipment, 'loader-addshipment', true);
        }
    }

    const onReset = (e) => {
        // more filters are inside a form, so use reset event handler. reset more filters in redux store
    }

    const onResetAll = (e) => {
        // reset both more filters form and the primary filters form and redux store
        // resetAllFilters();
    }

    const onChange = (event) => {
        setShipment({
            ...shipment,
            [event.target.name]: event.target.value
        });
    }

    // Suffix
    const [suffixAux, setSuffixAux] = useState('');

    const onSuffixChange = (event) => {
        setSuffixAux(event.target.value);
    }

    useEffect(() => {
        const suffix = suffixes.items.find(item => item.code == suffixAux);

        if (suffix) {
            setSelectedSuffix(suffix);
            var endCustomer = suffix.endCustomers ? (suffix.endCustomers.length > 0 ? suffix.endCustomers[0] : null) : null;
            setShipment({
                ...shipment
                , destinationId: suffix.id
                , destinationName: suffix.name
                , endCustomer: endCustomer
                , endCustomerId: endCustomer ? endCustomer.id : null
            });
        }
    }, [suffixAux, suffixes.items]);
    // END Suffix

    const delimiter = (data) => data && data.id && data.name ? ', ' : '';

    // RULES
    // Suffix suppliers rule: when suffix and supplierId set, autoset customsControlled to true
    useEffect(() => {
        if (shipment.destinationId) {
            const selectedSuffix = suffixes.items.find(item => item.id === shipment.destinationId);
            if (selectedSuffix) {
                if (shipment.supplierId) {
                    const matchedSupplier = selectedSuffix.suppliers && selectedSuffix.suppliers.find(item => item.id === shipment.supplierId);
                    if (matchedSupplier) {
                        setShipment({ ...shipment, customsControlled: true });
                    }
                }
                if (selectedSuffix.endCustomer) {
                    setShipment({ ...shipment, endCustomer: selectedSuffix.endCustomer, endCustomerId: selectedSuffix.endCustomer.id, selectedSuffix: selectedSuffix });
                }
            }
        }
    }, [shipment.destinationId, shipment.supplierId]);


    const renderInput = ({
        label,
        name,
        id,
        type = 'text',
        min = 0,
        max,
        step = "any",
        autoComplete,
        error,
        value
    }) => {
        return (
            <div>
                <label htmlFor={id} className={styles.label}>{label}</label>
                <input
                    name={name}
                    type={type}
                    onKeyUp={onChange}
                    onChange={onChange}
                    autoComplete={autoComplete}
                    id={id}
                    min={min}
                    max={max}
                    step={step}
                    value={value}
                />
                <small className="form-text text-danger">{error ? error : ''}</small>
            </div>
        );
    }

    const renderInputDatalist = ({
        value,
        onChange,
        fetchDataFunction,
        data,
        renderDatalistOptions,
        label,
        name,
        placeholder,
        autoComplete,
        queryParameterName = "query"
    }) => {
        return (
            <div>
                <InputDatalist
                    data={data}
                    value={value}
                    onChange={onChange}
                    fetchDataFunction={fetchDataFunction}
                    isRequesting={suffixes.isRequesting}
                    renderDatalistOptions={renderDatalistOptions}
                    labelText={label}
                    name={name}
                    placeholder={placeholder}
                    autoComplete={autoComplete}
                    queryParameterName={queryParameterName}
                    labelClassName={styles.label}
                />
                <small className="form-text text-danger">{error[name[0]]}</small>
            </div>
        );
    }

    const renderSelect = (label, name, error) => {
        return (
            <div>
                <label htmlFor={name} className={styles.label}>{label}</label>
                <select
                    value={shipment[name]}
                    onChange={onChange}
                    name={name}
                    id={name}
                >
                    <option value={''}>Select one</option>
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                </select>
                <small className="form-text text-danger">{error ? error : ''}</small>
            </div>
        );
    }

    return (
        <Modal show={showModal} onHide={onHide} size="xl" centered>
            <LoadingIndicator id={`loader-addshipment`} />
            <form onSubmit={onSubmit} onReset={onReset}>
                <Modal.Header closeButton>
                    <Col>
                        <Row>
                            <Col>
                                <Modal.Title>
                                    Add New Shipment
                            </Modal.Title>
                            </Col>
                        </Row>
                    </Col>
                </Modal.Header>

                <Modal.Body>
                    <Col>
                        <Row>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderInput({ label: 'Shipment No *', name: 'shipmentNo', id: 'shipmentNoInModal', error: error.shipmentNo, value: shipment.shipmentNo })}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderInputDatalist({
                                    label: 'Suffix *',
                                    name: ['destinationId'],
                                    value: shipment.destinationName,
                                    onChange: onSuffixChange,
                                    fetchDataFunction: searchSuffixes,
                                    data: suffixes.items,
                                    renderDatalistOptions: (item, i) => <option value={item["code"]} key={i} />
                                })}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderInputDatalist({
                                    label: 'Supplier ID or Name *',
                                    name: ['supplierId', 'supplier'],
                                    value: `${shipment.supplierId ? `(${getValue(shipment, 'supplier.code') || ''})` : ''}${delimiter(shipment.supplier)}${getValue(shipment, 'supplier.name') || ''}`,
                                    onChange: onChangeInputDatalist,
                                    data: associates.items,
                                    fetchDataFunction: searchAssociates,
                                    queryParameterName: "queryStringValue",
                                    renderDatalistOptions: (item, i) => <option value={`(${item['code']})${delimiter(item)}${item['name']}`} key={i} />
                                })
                                }
                            </Col>
                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderInputDatalist({
                                    label: 'End Customer ID or Name *',
                                    name: ['endCustomerId', 'endCustomer'],
                                    value: `${shipment.endCustomerId ? `(${getValue(shipment, 'endCustomer.code') || ''})` : ''}${delimiter(shipment.endCustomer)}${getValue(shipment, 'endCustomer.name') || ''}`,
                                    onChange: onChangeInputDatalist,
                                    data: associates.items,
                                    fetchDataFunction: searchAssociates,
                                    queryParameterName: "queryStringValue",
                                    renderDatalistOptions: (item, i) => <option value={`(${item['code']})${delimiter(item)}${item['name']}`} key={i}
                                    />
                                })
                                }
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderInputDatalist({
                                    label: 'Carrier ID or Name',
                                    name: ['carrierId', 'carrier'],
                                    value: `${shipment.carrierId ? `(${getValue(shipment, 'carrier.code') || ''})` : ''}${delimiter(shipment.carrier)}${getValue(shipment, 'carrier.name') || ''}`,
                                    onChange: onChangeInputDatalist,
                                    data: associates.items,
                                    fetchDataFunction: searchAssociates,
                                    queryParameterName: "queryStringValue",
                                    renderDatalistOptions: (item, i) => <option value={`(${item['code']})${delimiter(item)}${item['name']}`} key={i}
                                    />
                                })
                                }
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderInput({ label: 'Transport', name: 'groupCode', id: 'groupCode', error: error.groupCode })}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderInputDatalist({
                                    label: 'First Consignor ID or Name',
                                    name: ['firstConsignorId', 'firstConsignor'],
                                    value: `${shipment.firstConsignorId ? `(${getValue(shipment, 'firstConsignor.code') || ''})` : ''}${delimiter(shipment.firstConsignorId)}${getValue(shipment, 'firstConsignor.name') || ''}`,
                                    onChange: onChangeInputDatalist,
                                    data: associates.items,
                                    fetchDataFunction: searchAssociates,
                                    queryParameterName: "queryStringValue",
                                    renderDatalistOptions: (item, i) => <option value={`(${item['code']})${delimiter(item.id)}${item['name']}`} key={i} />
                                })
                                }
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderSelect('Unload Here?', 'unLoadHere', error.unLoadHere)}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderSelect('Temperature Controlled?', 'temperatureControlled', error.temperatureControlled)}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderSelect('Customs Controlled?', 'customsControlled', error.customsControlled)}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderSelect('Rush?', 'isRush', error.isRush)}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderInput({ label: 'Commodity Type', name: 'commodityType', id: 'commodityType', error: error.commodityType })}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderInput({ label: 'THU Count', name: 'itemQuantity', id: 'itemQuantity', error: error.itemQuantity, type: 'number' })}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderInput({ label: 'Volume', name: 'volume', id: 'volume', error: error.volume, type: 'number' })}
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="4">
                                {renderInput({ label: 'Gross Weight', name: 'grossWeight', id: 'grossWeight', error: error.grossWeight, type: 'number' })}
                            </Col>

                        </Row>
                    </Col>
                </Modal.Body>

                <Modal.Footer>
                    <Col>
                        <Row>

                            {/**
                            <Col xs="12" sm="6" md="6" lg="2" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <input type="reset" value='Reset All' className={styles.reset} onClick={onResetAll} />
                            </Col>

                            <Col></Col>

                            <Col xs="12" sm="6" md="6" lg="2" style={{ display: 'flex' }}>
                                <input type="reset" value='Reset' />
                            </Col>
                            */}

                            <Col></Col>
                            <Col></Col>

                            <Col xs="12" sm="6" md="6" lg="3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <input type="submit" value='Add' />
                            </Col>
                        </Row>
                    </Col>
                </Modal.Footer>
            </form>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        associates: state.definitions.associates,
        suffixes: state.definitions.suffixes,
        createdShipment: state.shipment.createdShipment,
        createdShipmentNo: state.shipment.createdShipmentNo
    }
}

const mapActionToProps = {
    createShipment,
    createShipmentNo,
    searchAssociates,
    searchAssociatesSuccess,
    searchSuffixes,
    clearState
}

export default connect(mapStateToProps, mapActionToProps)(ModalAddShipment);

// TODO: rename to create shipment. adding may refer refer to adding a shipment to a group like transport operation or whatevever.

// TODO: When filtered and a detail page navigated from filter results and then navigated back, navigate back to the filter results, not to the list view page with default/no filters.
// Also keep the last filters used