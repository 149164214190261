import React from 'react';
import styles from './FileControllerFileItem.module.css';
import moment from 'moment';
import { Button, ButtonStack } from '@dfds-ui/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PolicyGroupAccessHoc from "../../RoutedComponents/Routes/PolicyGroupAccessHoc";

const FileControllerFileItem = (props) => {

    const { fileItem } = props;
    const { onDeleteFile, onDownloadFile, onShowFile } = props;

    return (
        <>
            <tr>
                {renderFileName(fileItem)}
                {renderFileStatus(fileItem)}
                {renderFileSize(fileItem)}
                {renderCreationDate(fileItem)}
                {renderCreatedBy(fileItem)}
                {renderActionButtons(fileItem, onShowFile, onDownloadFile, onDeleteFile)}
            </tr>
        </>
    )
}

const renderFileName = (fileItem) => {
    return (
        <td><strong>{fileItem.name}</strong></td>
    )
}

const renderFileStatus = (fileItem) => {

    switch (fileItem.status) {
        case 'not-uploaded':
            return (<td><p className={styles[fileItem.status]}>Not Uploaded</p></td>)
        case 'uploading':
            return (
                <td>
                    <div className='progress'>
                        <div className='progress-bar bg-success' style={{ 'width': fileItem.uploadPercentage + '%' }} />
                    </div>
                </td>
            );
        case 'finishing':
            return (<td><p className={styles[fileItem.status]}>Finishing...</p></td>)
        case 'uploaded':
            return (<td><p className={styles[fileItem.status]}>Uploaded</p></td>)
        default:
            return;
    }
}

const renderFileSize = (fileItem) => {

    const fileSize = fileItem.size ? fileItem.size : fileItem.sizeInBytes;

    const fileSizeInKB = (fileSize / 1024).toFixed(2);

    const fileSizeInMB = (fileSizeInKB / 1024).toFixed(2);

    if (fileSize < 1000) {
        return <td>{fileSize} B</td>
    }

    if (fileSizeInKB < 1000) {
        return <td>{fileSizeInKB} KB</td>
    }

    return <td>{fileSizeInMB} MB</td>

}

const renderCreationDate = (fileItem) => {
    const dateString = moment(fileItem.audit.creationDate).format('MMM D YYYY, h:mm:ss A');
    return (
        <td className={`${styles.creationDate}`}>{dateString}</td>
    )
}

const renderCreatedBy = (fileItem) => {
    return <td>{fileItem.audit.createdBy}</td>
}

const renderActionButtons = (fileItem, onShowFile, onDownloadFile, onDeleteFile) => {

    return (
        <td className={`${styles.actionColumn}`}>
            <ButtonStack align="center">
                <Button size="small" variation="outlined" onClick={() => onShowFile(fileItem)}>
                    <FontAwesomeIcon icon="expand-arrows-alt" />
                </Button>
                <Button size="small" variation="secondary" disabled={fileItem.status !== 'uploaded'} onClick={() => onDownloadFile(fileItem)}>
                    <FontAwesomeIcon icon="download" />
                </Button>
                <PolicyGroupAccessHoc componentName="FileControllerOperationButtonsHOC">
                    <Button size="small" variation="primary" onClick={() => onDeleteFile(fileItem)}>
                        <FontAwesomeIcon icon="trash" />
                    </Button>
                </PolicyGroupAccessHoc>
            </ButtonStack>
        </td>
    )
}

export default FileControllerFileItem;