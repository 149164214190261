import React, { useRef } from 'react';
import { Modal, Col, Row } from 'react-bootstrap';
import { Button } from '@dfds-ui/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactToPrint from 'react-to-print';
import Barcode from 'react-barcode';
import { getValue } from '../../core/utility';
import QRCode from 'qrcode.react';

const ModalPrintLabel = ({ modalData, onHide }) => {

    const componentRef = useRef();

    const shipmentNo = modalData.shipmentNo;
    const articleList = modalData.thuList;
    const height = window.innerHeight * 2 / 3;

    const renderBarcode = (value, shortCode) => {
        if (value) {
            return (
                <Barcode value={`${shortCode}${value}`} fontSize={0} height={75} width={1} format="CODE39" />
            )
        }
        return null;
    }

    const renderQRCode = (value, shortCode) => {
        if (value) {
            return (                
                //Check https://github.com/zpao/qrcode.react for other props
                <QRCode value={value} />
            )
        }
        return null;
    }

    const renderCol = (label, value, shortCode) => {
        return (
            <>
                <Row className="text-left">
                    <Col xs={4}>{label}</Col>
                    <Col xs={8}>
                        <h3 className='text-truncate'><b>{value}</b></h3>
                    </Col>
                </Row>
                <Row>
                    <Col className='barcode'>
                        {/* {renderBarcode(value, shortCode)} */}
                        {renderQRCode(value, shortCode)}
                    </Col>
                </Row>
            </>
        )
    }

    const renderList = () => {

        return articleList.map(article => <table className='table table-bordered text-center page-break mt-5' key={article.id}>
            <tbody>
                <tr>
                    <td colSpan={2}><b>Shipment Number : {shipmentNo}</b></td>
                </tr>
                <tr className='height-175'>
                    <td width='50%'>
                        {renderCol('Delivery Note (N)', article.deliveryNote, 'N')}
                    </td>
                    <td width='50%'>
                        {renderCol('Part No (P)', article.externalCode, 'P')}
                    </td>
                </tr >
                <tr className='height-175'>
                    <td>
                        {renderCol('Quantity (Q)', getValue(article, 'actualMeasurement.itemQuantity.value'), 'Q')}
                    </td>
                    <td>
                        {renderCol('Supplier (V)', article.supplierCode, 'V')}
                    </td>
                </tr>
                <tr className='height-175'>
                    <td colSpan={2}>
                        {renderCol('Serial Number', article.externalId, '')}
                    </td>
                </tr>
            </tbody>
        </table>
        )
    }

    return (
        <Modal show={true} onHide={onHide} centered size="xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    Print THU Label
                </Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ overflow: 'auto', height: height }}  >
                <div className='print-content' ref={componentRef}>
                    <Row>
                        <Col xs={1}></Col>
                        <Col>
                            {renderList()}
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <ReactToPrint
                    trigger={() => <Button size="small" variation="primary" >Print <FontAwesomeIcon icon="print" /></Button>}
                    content={() => componentRef.current}
                />
            </Modal.Footer>
        </Modal>
    )
}

export default ModalPrintLabel;