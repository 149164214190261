import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import authReducer from './authReducer';
import unitReducer from './unitReducer';
import shipmentReducer from './shipmentReducer';
import shipmentDetail from './shipmentDetailReducer';
import splitReducer from './splitReducer';
import transportReducer from './transportReducer';
import fileControllerReducer from './fileControllerReducer';
import BookingReducer from './bookingReducer';
import NotificationReducer from './notificationReducer';
import UserReducer from './userReducer';
import modalReducer from './modalReducer';
import actionUrlReducer from './actionUrlReducer';
import companyReducer from './companyReducer';
import calendarReducer from './calendarReducer';
import gateQuotaReducer from './gateQuotaReducer';
import roleReducer from './roleReducer';
import image from './imageReducer';
import shipmentsReducer from './shipmentsReducer';
import imageController from './imageControllerReducer';
import additionalGateReducer from './additionalGateReducer';
import reservedSlotReducer from './reservedSlotReducer';
import thuDetailReducer from './thuDetailReducer';
import holdTerminalItem from './holdTerminalItemReducer';
import taskReducer from './taskReducer';
import shipmentInboundOperation from './shipmentInboundOperationReducer';
import shipments from '../ducks/shipments';
import definitions from '../ducks/definitions';
import historyReducer from './historyReducer';
import voyages from '../ducks/voyages';
import containerBookings from '../ducks/containerBookings';
import serviceReducer from './serviceReducer';
import currencyReducer from './currencyReducer';
import contractReducer from './contractReducer';
import settings from '../ducks/settings';
import operationReducer from './operationReducer';
import businessAreaReducer from './businessAreaReducer';
import slotBookingConfigurationReducer from './slotBookingConfigurationReducer';
import articles from '../ducks/articles';
import messages from './messagesReducer';
import bookingsQueryStringReducer from './bookingsQueryStringReducer';

export default combineReducers({
  form: formReducer,
  auth: authReducer,
  unit: unitReducer,
  shipment: shipmentReducer,
  split: splitReducer,
  transport: transportReducer,
  fileController: fileControllerReducer,
  booking: BookingReducer,
  notification: NotificationReducer,
  user: UserReducer,
  company: companyReducer,
  service: serviceReducer,
  modal: modalReducer,
  actionUrl: actionUrlReducer,
  calendar: calendarReducer,
  additionalGate: additionalGateReducer,
  gateQuota: gateQuotaReducer,
  role: roleReducer,
  shipments: shipmentsReducer,
  shipmentDetail,
  image,
  imageController,
  reservedSlot: reservedSlotReducer,
  slotBookingConfiguration: slotBookingConfigurationReducer,
  thuDetailReducer: thuDetailReducer,
  holdTerminalItem: holdTerminalItem,
  task: taskReducer,
  shipmentInboundOperation: shipmentInboundOperation,
  shipmentsOverview: shipments, // TODO: to be shipments
  definitions,
  history: historyReducer,
  operationReducer,
  voyages,
  containerBookings,
  currency: currencyReducer,
  contract: contractReducer,
  settings,
  businessArea: businessAreaReducer,
  articles: articles,
  messages: messages,
  bookingsQuery: bookingsQueryStringReducer,
});
