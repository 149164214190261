/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  fillBookingFormById,
  fillBookingFormData,
  selectTimeSlot,
  getUnitTypesForBooking,
  getClients,
  getBusinessAreasByClient,
  getServicesByClientAndType,
  clearBookingFormData,
  parseUrlForBookingList,
  getQuery
} from '../store/action/bookingActions';
import history from '../core/history';
import './BookingCommon.css';
import { Col } from 'react-bootstrap';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import { isOnlyExternalUser } from '../core/authentication';
import SchemaInterpreter from '../component/FormComponents/Schema/SchemaInterpreter';
import { UpdateBookingSchema } from '../component/FormComponents/Schema/updateBooking';
import Page from '../component/Page/Page';
import moment from 'moment';
import { useIfNotNavigatedTo } from "../core/hooks";
import { ResetFilters } from '../store/action/bookingsQueryStringActions';

const UpdateBookingContainer = (props) => {
  // Redux actions
  const {
    fillBookingFormData,
    fillBookingFormById,
    selectTimeSlot,
    getUnitTypesForBooking,
    getBusinessAreasByClient,
    getClients,
    getServicesByClientAndType,
    clearBookingFormData,
    ResetFilters 
  } = props;

  // Redux store
  let {
    formData,
    authenticatedUser,
    unitTypesForBooking,
    services,
    clients,
    clientBusinessAreas,
    isBookingRetrieving
  } = props;

  // Others
  const { match } = props;

  useIfNotNavigatedTo(['/booking/list', '/booking', '/booking/carrier-booking-list'], ResetFilters);  

  const bookingId = match.params.bookingid;
  const [booking, setBooking] = useState({});
  useEffect(() => {
    clearBookingFormData();
    fillBookingFormById(bookingId);
  }, [bookingId]);

  useEffect(() => {
    if (!formData && !isBookingRetrieving) {
      fillBookingFormById(bookingId);
    }
  }, [isBookingRetrieving, fillBookingFormById]);

  useEffect(() => {
    if (formData && !booking.clientId) {
      setBooking({
        ...formData,
        preferredBookingDate: moment.unix(formData.dateTime).utc().format('DD/MM/YYYY HH:mm')
      });
    }
  }, [formData, booking.clientId, setBooking]);

  useEffect(() => {
    if (!unitTypesForBooking) {
      getUnitTypesForBooking();
    }
  }, [unitTypesForBooking, getUnitTypesForBooking]);

  useEffect(() => {
    if (booking && booking.clientId) {
      getBusinessAreasByClient(booking.clientId);
    }
  }, [booking.clientId, getBusinessAreasByClient]);

  useEffect(() => {
    if (booking && booking.clientId && booking.operationType) {
      getServicesByClientAndType(booking.clientId, booking.operationType);
    }
  }, [booking, getServicesByClientAndType]);

  useEffect(() => {
    if (clientBusinessAreas && clientBusinessAreas.length === 1 && !booking.businessAreaId) {
      setBooking((prevState) => {
        return { ...prevState, businessAreaId: clientBusinessAreas[0].value };
      });
    }
  }, [clientBusinessAreas, booking, setBooking]);

  useEffect(() => {
    if (!formData) {
      return;
    }
    const bookingCarrier = formData.carrier;
    if (!isInternalUser() && bookingCarrier !== authenticatedUser.company) {
      history.push('/noAuthorization');
    }
  }, [formData]);

  useEffect(() => {
    if (!clients) {
      getClients();
    }
  }, [clients, getClients]);

  const isInternalUser = () => {
    return !isOnlyExternalUser();
  };

  const submitAttributesForBooking = (formData) => {
    formData.serviceName = services.find((x) => x.value === formData.serviceId).text;
    formData.clientName = clients.find((x) => x.value === formData.clientId).text;
    formData.dateTime =
      moment.utc(booking.preferredBookingDate, 'DD/MM/YYYY HH:mm').valueOf() / 1000;
    fillBookingFormData(formData);
    history.push(`/booking/${bookingId}/confirm`);
  };

  const getOperationTypeList = () => {
    return [
      { value: 'Loading', text: 'Loading' },
      { value: 'Unloading', text: 'Unloading' }
    ];
  };

  const onFieldChangeHandler = (event, newValue, previousValue, fieldId) => {
    if (fieldId === 'clientId') {
      setBooking((prevState) => {
        return {
          ...prevState,
          clientId: newValue,
          businessAreaId: null,
          operationType: null
        };
      });
    } else if (fieldId === 'businessAreaId') {
      setBooking((prevState) => {
        return {
          ...prevState,
          businessAreaId: newValue
        };
      });
    } else if (fieldId === 'operationType') {
      setBooking((prevState) => {
        return {
          ...prevState,
          serviceId: null,
          operationType: newValue
        };
      });
    } else {
      setBooking((prevState) => {
        return {
          ...prevState,
          [fieldId]: newValue
        };
      });
    }
  };

  const isExternalUser = () => {
    const isExternal = authenticatedUser.roles.indexOf('ExternalUser') >= 0;
    return isExternal;
  };

  const returnBackFromDetailPageClick = () => {

    const url = isExternalUser()
      ? '/booking/carrier-booking-list/'
      : `/booking/list`;
    history.push(url);
  };

  return (
    <Page onBackClick={returnBackFromDetailPageClick}>
      {formData && clients && (
        <Col lg={12} sm={8} xs={12}>
          <SchemaInterpreter
            title="Update Booking"
            onFieldChangeHandler={onFieldChangeHandler}
            onSubmit={submitAttributesForBooking}
            formItem={formData}
            formSchema={UpdateBookingSchema}
            operationTypes={getOperationTypeList()}
            businessAreas={clientBusinessAreas}
            clients={clients}
            services={services}
            unitTypes={unitTypesForBooking}
          />
        </Col>
      )}
      <LoadingIndicator id="createOrUpdateBooking" />
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    clients: state.booking.clients,
    services: state.booking.services,
    clientBusinessAreas: state.booking.clientBusinessAreas,
    formData: state.booking.formData,
    authenticatedUser: state.auth.authenticatedUser,
    unitTypesForBooking: state.booking.unitTypesForBooking,
    isBookingRetrieving: state.booking.isBookingRetrieving
  };
};

const mapDispatchToProps = {
  fillBookingFormData,
  fillBookingFormById,
  clearBookingFormData,
  selectTimeSlot,
  getUnitTypesForBooking,
  getBusinessAreasByClient,
  getServicesByClientAndType,
  getClients,
  ResetFilters 
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateBookingContainer);