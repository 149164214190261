import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Radio } from '@dfds-ui/react-components';

const StatusFilter = ({ list, value, show, onChange }) => {
    if (!list || !show) {
        return null;
    }

    return (
        <>

            <label className="col-sm-3 d-none d-sm-block col-form-label">Status</label>
            {
                list.map(s => {
                    return (
                        <Col xs={6} key={s.value}>
                            <Radio name="status-list" className="mt-2" key={s.value} value={s.value} checked={s.value === value} onChange={onChange}>
                                {s.text}
                            </Radio>
                        </Col>
                    )
                })
            }
        </>
    )
}

export default StatusFilter;