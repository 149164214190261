import types from '../type/shipment';

const initialState = {
    createdShipment: null,
    shipmentList: [],

    fileList: null,
    searchedShipment: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.CLEAR_STATE:
            return {
                ...state,
                createdShipment: null
            }
        case types.SHIPMENT_NO_CREATED:
            return {
                ...state,
                createdShipmentNo: action.payload
            }
        case types.SHIPMENT_CREATED:
            return {
                ...state,
                createdShipment: action.payload
            }
        case types.SHIPMENTLIST_RECEIVED:
            return {
                ...state,
                shipmentList: action.payload
            }
        case types.ADD_SHIPMENTS_TO_LIST:
            const newTemp = { ...state.shipmentList };
            for (const shipment of action.payload) {
                newTemp[shipment.externalId] = shipment;
            }
            return {
                ...state,
                shipmentList: newTemp,
                searchedShipment: null
            }
        case types.UPDATE_SEARCHED_SHIPMENT:
            return {
                ...state,
                searchedShipment: action.payload
            }
        case types.INITALIZE_SHIPMENT_DATA:
            return {
                ...state,
                ...initialState
            }
        case types.SAVE_FILE_LIST:

            const newFileList = { ...state.fileList };
            for (const shipment of action.payload) {
                newFileList[shipment.relativePath] = shipment.files
            }

            return {
                ...state,
                fileList: newFileList
            }
        default:
            return state;
    }

};