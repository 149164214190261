import React from 'react';
import { connect } from 'react-redux';
import { Modal, Row, Col } from 'react-bootstrap';
import { epochToShortDateTime } from '../core/epochToDateConverter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@dfds-ui/react-components';

function ModalSuggestBookingDateTime({ showModal = true, onHide, onSelected, timeSlots }) {
  const onDateSelected = (value) => {
    onSelected(value);
  };

  return (
    <Modal show={showModal} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Col>
          <Row>
            <Col>
              <Modal.Title>
                Not available! You can select from suggested booking time(s) listed below for
                preferred date
              </Modal.Title>
            </Col>
          </Row>
        </Col>
      </Modal.Header>

      <Modal.Body>
        <Col style={{ textAlign: 'center' }}>
          {timeSlots &&
            timeSlots.map((value, index) => {
              return (
                <Row>
                  <Col>
                    <Button
                      id={index}
                      variation="outlined"
                      style={{ fontSize: '30px', marginBottom: '10px' }}
                      onClick={() => onDateSelected(epochToShortDateTime(value, 'D/MM/YYYY HH:mm'))}
                    >
                      {epochToShortDateTime(value, 'HH:mm')}
                      <FontAwesomeIcon icon="clock"></FontAwesomeIcon>
                    </Button>
                  </Col>
                </Row>
              );
            })}
        </Col>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

// get the state of the shipment overview table pass it to value of filters, pass it to the table
const mapStateToProps = (state) => {
  return {
    timeSlots: state.booking.timeSlots
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalSuggestBookingDateTime);

// TODO: NICE TO HAVE: keep last used filters, only if they have been searched with
