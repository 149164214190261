const actionTypes = Object.freeze({
    ADD_FILE_TO_FILE_LIST: 'astrid/shipment/ADD_FILE_TO_FILE_LIST',
    REMOVE_FILE_FROM_FILE_LIST: 'astrid/shipment/REMOVE_FILE_FROM_FILE_LIST',
    SAVE_FILE_LIST: 'astrid/shipment/SAVE_FILE_LIST',
    ADD_SHIPMENTS_TO_LIST: 'astrid/shipment/ADD_SHIPMENTS_TO_LIST',
    SAVE_DOCS_MISSING_STATE: 'astrid/shipment/SAVE_DOCS_MISSING_STATE',
    UPDATE_SEARCHED_SHIPMENT: 'astrid/shipment/UPDATE_SEARCHED_SHIPMENT',
    INITALIZE_SHIPMENT_DATA: 'astrid/shipment/INITALIZE_SHIPMENT_DATA',

    CLEAR_STATE: 'astrid/shipment/CLEAR_STATE',
    SHIPMENT_CREATED: 'astrid/shipment/SHIPMENT_CREATED',
    SHIPMENT_NO_CREATED: 'astrid/shipment/SHIPMENT_NO_CREATED',
    SHIPMENTLIST_RECEIVED: 'astrid/shipment/SHIPMENTLIST_RECEIVED'
});

export default actionTypes;