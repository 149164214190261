const contractActions = Object.freeze({
  CONTRACT_SAVECONTRACT_SUCCESS: Symbol('CONTRACT_SAVECONTRACT_SUCCESS'),
  CONTRACT_GETPASSIVECONTRACTS_SUCCESS: Symbol(
    'CONTRACT_GETPASSIVECONTRACTS_SUCCESS'
  ),
  CONTRACT_GETCONTRACT_SUCCESS: Symbol('CONTRACT_GETCONTRACT_SUCCESS'),
  CONTRACT_GETDRAFTCONTRACT_SUCCESS: Symbol(
    'CONTRACT_GETDRAFTCONTRACT_SUCCESS'
  ),
  CONTRACT_CLEARCONTRACTSTATE_SUCCESS: Symbol(
    'CONTRACT_CLEARCONTRACTSTATE_SUCCESS'
  ),
  CONTRACT_RETRIEVED: Symbol('CONTRACT_RETRIEVED'),
  CONTRACT_ENDED: Symbol('CONTRACT_ENDED'),
  CONTRACT_REFRESH: Symbol('CONTRACT_REFRESH')
});

export default contractActions;
