import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Filters from '../component/History/Filters';
import EntityStateDiffList from '../component/History/EntityStateDiffList';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import { Card, Row, Col } from 'react-bootstrap';
import { ButtonStack, Button } from '@dfds-ui/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getHistoryList, getObjectTypes, clearHistoryList } from '../store/action/historyActions';
import useTranslation from '../core/useTranslation';
import Page from '../component/Page/Page';

//import { getActionListComponent, getActionTypeFromList } from '../component/History/HistoryActionTypes';
//import { utcToEpoch } from '../core/utcToEpochConverter';

// const breadcrumbItems = [
//     { type: 'shipment', id: 'S00013435445', text: 'Shipment-S00013435445', link: '/history/shipment/S00013435445' },
//     { type: 'thu', id: 'T3462211353', text: 'THU-T3462211353', link: '/history/thu/T3462211353' },
//     { type: 'document', id: '969be016-423d-4a11-8bc7-354f77178b80', text: 'Document-969be016-423d-4a11-8bc7-354f77178b80' },
// ];

const History = ({ match, getHistoryList, getObjectTypes, clearHistoryList, histories, objectTypes }) => {
    const [entityId, setEntityId] = useState(match.params.entityid);
    const [objectType, setObjectType] = useState(match.params.objecttype);
    const [showFilters, setShowFilters] = useState(!match.params.objecttype);
    // const [actionType, setType] = useState(getActionTypeFromList());
    // const [startDate, setStartDate] = useState(utcToEpoch(new Date()));
    // const [endDate, setEndDate] = useState(utcToEpoch(new Date()));

    const { t } = useTranslation();

    useEffect(() => {
        clearHistoryList();
        getObjectTypes();
    }, []);

    useEffect(() => {
        if (objectTypes) {
            setObjectType(match.params.objecttype ? objectTypes.find(t => t.value.toLowerCase() === match.params.objecttype) : objectTypes[0]);
        }

        if (entityId && objectType) {
            getHistoryList(entityId, objectType);
        }
    }, [objectTypes]);

    // useEffect(() => {
    //     if (historyFullList) {
    //         filterHistoryList(historyFullList, actionType.value);
    //     }
    // }, [historyFullList]);

    // useEffect(() => {
    //     console.log('Call action for state diffs with filters');
    // }, [entityId]);

    const onEntityIdFilterChangedHandler = (id) => {
        setEntityId(id);
    }

    const onObjectTypeFilterChangedHandler = (selected) => {
        setObjectType(selected);
    }

    const showFiltersClickedHandler = () => {
        setShowFilters((prevState) => { return !prevState });
    }

    // const onBreadcrumbItemClickedHandler = (breadcrumb) => {
    //     setEntityId(breadcrumb.id);
    //     setObjectType(objectTypes.find(t => t.value === breadcrumb.type));

    //     getHistoryList(breadcrumb.id, breadcrumb.type);
    // }

    const onFilterClickedHandler = () => {
        getHistoryList(entityId, objectType.value);
    }

    // const onActionTypeFilterChangedHandler = (selected) => {
    //     setType(selected);
    //     filterHistoryList(historyFullList, selected.value);
    // }

    // const onDateFilterChangedHandler = (start, end) => {
    //     setStartDate(start);
    //     setEndDate(end);
    // }

    return <Page>

        <Row>
            <Col md='8' className='text-left'>
                <div className='custom-control-inline'>
                    <h2 style={{ lineHeight: '1.7', marginBottom: '0' }}>History Tracking</h2>
                </div>
            </Col>
            <Col md='4'>
                <ButtonStack align='right'>
                    <Button variation='secondary'
                        onClick={showFiltersClickedHandler}
                        icon={<FontAwesomeIcon icon='filter' />}
                    >
                        Filter
                    </Button>
                </ButtonStack>
            </Col>
        </Row>


        {showFilters && objectTypes && <> <hr /><Filters
            entityId={entityId}
            selectedObjectType={objectType}
            objectTypes={objectTypes.map(type => { return { value: type.value, label: t(`History.${type.label}`) } })}
            onFilterClicked={onFilterClickedHandler}
            onEntityIdChanged={onEntityIdFilterChangedHandler}
            onObjectTypeChanged={onObjectTypeFilterChangedHandler}
        //actionTypes={getActionListComponent()}
        //onActionTypeChanged={onActionTypeFilterChangedHandler}
        //selectedActionType={actionType}
        // startDateEpoch={startDate}
        // endDateEpoch={endDate}
        // onDateChanged={onDateFilterChangedHandler} 
        /></>}

        {/* {breadcrumbItems && <><hr /><Breadcrumb items={breadcrumbItems} breadcrumbItemClicked={onBreadcrumbItemClickedHandler} /></>} */}

        {histories && <><hr /> <EntityStateDiffList diffList={histories} /></>}
        <LoadingIndicator id='histories' />
    </Page>
}

const mapStateToProps = state => {
    return {
        histories: state.history.histories,
        objectTypes: state.history.objectTypes
    }
};

export default connect(mapStateToProps, { getHistoryList, getObjectTypes, clearHistoryList })(History);
