import businessAreaActions from '../type/businessArea';

const initialState = {
    businessArea: null,
    businessAreaList: null
}


const processBusinessArea = businessArea => businessArea.companyCount = businessArea.companies ? businessArea.companies.length.toString() : '0';

export default (state = initialState, action) => {
    switch (action.type) {
        case businessAreaActions.BUSINESS_AREA_LIST_RECEIVED:
            if (action.payload) {
                action.payload.forEach((x) => processBusinessArea(x));
            }

            return {
                ...state,
                businessAreaList: action.payload
            }
        case businessAreaActions.BUSINESS_AREA_RECEIVED:
            return {
                ...state,
                businessArea: action.payload
            }
        case businessAreaActions.BUSINESS_AREA_SAVED:
            const businessAreas = [...state.businessAreaList] || [];
            const itemIdx = businessAreas.findIndex(x => x.id = action.payload.id);
            if (itemIdx >= 0) {
                businessAreas[itemIdx] = action.payload;
            }
            else {
                businessAreas.push(action.payload);
            }

            return {
                ...state,
                businessAreaList: businessAreas
            };

        case businessAreaActions.BUSINESS_AREA_DELETED:
            console.log('payload:', action.payload);
            return {
                ...state,
                businessAreaList: state.businessAreaList ? state.businessAreaList.filter(x => x.id !== action.payload) : null
            };
        case businessAreaActions.BUSINESS_AREA_CLEAR_STATE:
            return { ...initialState };
        case businessAreaActions.COMPANY_LIST_RECEIVED:
            return {
                ...state,
                companies: action.payload
            };
        default:
            return state;
    }

}