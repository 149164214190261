import httpClient from "../../core/httpclient";
import { spinnerService } from "@simply007org/react-spinners";
import userActions from "../type/user";

const apiUrl = 'xms-identity-api/api';
//const apiUrl = 'http://localhost:5000/api';

export const getUserList = (pageIndex) => async dispatch => {
    const endPoint = `${apiUrl}/user/users`;

    const getUserRequest = pageIndex ? {
        token: pageIndex,
        pageSize: 5
    } : { token: null, pageSize: 5 }
    spinnerService.show('getUserList')
    httpClient.post(endPoint, getUserRequest)
        .then(response => {
            dispatch({
                type: userActions.USER_GETUSERS_SUCCESS,
                payload: response.data.result
            });
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            spinnerService.hide('getUserList')
        });
}

export const getUser = (username) => async dispatch => {
    const endPoint = `${apiUrl}/user/getuser?userName=${username}`;
    spinnerService.show('addOrEditUser')
    httpClient.get(endPoint)
        .then(response => {
            dispatch({
                type: userActions.USER_GETUSER_SUCCESS,
                payload: response.data.result
            });
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            spinnerService.hide('addOrEditUser')
        });
}

export const saveUser = (userModel) => async dispatch => {
    if (!userModel.status) {
        userModel = {
            ...userModel,
            status: 1
        }
    };

    const endPoint = `${apiUrl}/user/save`;
    spinnerService.show('addOrEditUser')
    httpClient.post(endPoint, userModel)
        .then(response => {
            dispatch({
                type: userActions.USER_SAVEUSER_SUCCESS,
                payload: userModel
            });
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            spinnerService.hide('addOrEditUser')
        });
}

//TODO: Boş bir zamanda kendi action ve reducer'ına alınabilir.
export const getRoles = () => async dispatch => {
    const endPoint = `${apiUrl}/role/roles`;
    httpClient.post(endPoint)
        .then(response => {
            dispatch({
                type: userActions.USER_GETROLES_SUCCESS,
                payload: response.data.result
            });
        })
        .catch(error => {
            console.log(error);
        })
}

//TODO: Boş bir zamanda kendi action ve reducer'ına alınabilir.
export const getRegions = () => async dispatch => {
    const endPoint = `${apiUrl}/region/regions`;
    httpClient.post(endPoint)
        .then(response => {
            dispatch({
                type: userActions.USER_GETREGIONS_SUCCESS,
                payload: response.data.result
            });
        })
        .catch(error => {
            console.log(error);
        })
}

export const clearSelectedUser = () => async dispatch => {
    dispatch({
        type: userActions.USER_CLEARSELECTEDUSER_SUCCESS
    });
}

export const getAllUsers = () => async dispatch => {
    const endPoint = `${apiUrl}/user/all-users`;
    spinnerService.show('getUserList')
    httpClient.get(endPoint)
        .then(response => {
            dispatch({
                type: userActions.USER_GETUSERS_SUCCESS,
                payload: response.data.result || []
            });
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            spinnerService.hide('getUserList')
        });
}