import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import { SelectField, Button } from '@dfds-ui/react-components';
import { Radio } from '@dfds-ui/react-components';
import { searchAssociates } from '../../store/ducks/definitions/actionCreators';
import { saveUser } from '../../store/action/userActions';
import { toast } from 'react-toastify';
import XmsTable from '../XmsTable/XmsTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function UserForm2({
    regions, roles, user, searchAssociates, companies, clients, saveUser
}) {
    // HISTORY, LOCATION, PARAMS
    let history = useHistory();
    let { usernameInQueryString } = useParams();
    // end HISTORY, LOCATION, PARAMS
    const onUpdateMode = !!user;

    const [isCreateMode, setIsCreateMode] = useState();
    const [error, setError] = useState({});
    const [userName, setUserName] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [company, setCompany] = useState({});
    const [region, setRegion] = useState("");
    const [password, setPassword] = useState("");
    const [tyoe, setType] = useState("1");
    const [status, setStatus] = useState("1");
    const [userRoles, setUserRoles] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [companyQuery, setCompanyQuery] = useState("");
    const [validationErrors, setvalidationErrors] = useState({});
    const [submitValidations, setSubmitValidations] = useState(false);
    const [authorizedClients, setAuthorizedClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState();

    useEffect(() => {
        var isCreateMode = usernameInQueryString === 'new';
        setIsCreateMode(isCreateMode);
    }, [usernameInQueryString]);

    useEffect((() => {
        if (companyQuery && companyQuery.length > 2) {
            //todo: we can talk (kaan)
            searchAssociates({ queryStringValue: companyQuery });
        }

    }), [companyQuery]);

    useEffect(() => {
        if (!user) {
            return;
        }
        setName(user.name);
        setSurname(user.surname);
        setPassword(user.password);
        setEmailAddress(user.email);
        setRegion(user.region);
        setCompany({
            code: user.companyCode,
            id: user.company ? user.company.id : null,
            name: user.company ? user.company.name : null
        });
        setUserName(user.username);
        setUserRoles(user.roles);
        setStatus(user.status);

        setCompanyQuery(format({
            code: user.companyCode,
            id: user.company ? user.company.id : null,
            name: user.company ? user.company.name : null
        }));

        if (user.authorizedClients)
            setAuthorizedClients(user.authorizedClients);
    }, [user]);

    useEffect((() => {
        setCompanyList(companies);
    }), [companies]);

    const onNameChanged = (e) => {
        setName(e.target.value);
    }

    const onSurnameChanged = (e) => {
        setSurname(e.target.value);
    };

    const onPasswordChanged = (e) => {
        setPassword(e.target.value);
    };

    const onEmailAddressChanged = (e) => {
        setEmailAddress(e.target.value);
    };

    const onRegionChanged = (val) => {
        setRegion(val);
    };

    const onClientChanged = (val) => {
        if (clients) {
            var selectedClient = clients.find(x => x.id == val);
            setSelectedClient(selectedClient);
        }
    }

    const onCompanyChanged = (event) => {
        setCompanyQuery(event.target.value);
        const matchedCompany = companyList.find(item => {
            return (
                [item.id, item.name].some((element) => element === event.target.value) // when there is no custom formatting on field, or when the field is empty and started being fields (e.g. create)
                ||
                event.target.value === format(item) // when there is a custom formatting
            );
        });

        if (matchedCompany) {
            setCompany({ id: matchedCompany.id, code: matchedCompany.code, name: matchedCompany.name });
            setvalidationErrors(state => ({ ...state, company: '' }));

        } else {
            //setCompany(event.target.value);
            setCompany({ id: null, code: null, name: event.target.value });
            setvalidationErrors(state => ({ ...state, company: 'Please select a company for the user.' }));
        }
    };

    const onUserNameChanged = (e) => {
        setUserName(e.target.value);
    };

    const renderInput = ({
        label,
        name,
        type = 'text',
        min = 0,
        max = 100,
        autoComplete = 'off',
        error,
        value,
        changeEvent,
        disabled = false
    }) => {
        return (

            type == "hidden" ? <input type="hidden" name={name} value={value} /> :

                <div className='form-group'>
                    <label id={name}>{label}</label>
                    <input
                        disabled={disabled}
                        name={name}
                        type={type}
                        className='form-control'
                        id={name}
                        min={min}
                        max={max}
                        value={value}
                        onChange={changeEvent}
                        autoComplete={autoComplete}
                    />
                    <small className="form-text text-danger">{submitValidations ? error : ''}</small>
                </div>
        );
    }

    const renderSelectBox = (label, value, list, onChange, error = null) => {
        return (
            <div className='form-group'>
                <label>{label}</label>
                <SelectField selected={value} onChange={(e) => onChange(e.target.value)} size='small' className='selectbox-labelless'>
                    <option value="">Select {label}</option>
                    {list.map((item,i) => (
                        <option value={item.id} key={i}>
                            {item.name}
                        </option>
                    ))}
                </SelectField>
                <small className="form-text text-danger">{submitValidations ? error : ''}</small>
            </div>
        )
    }

    useEffect(
        () => {
            setvalidationErrors({});
            if (!company || !company.code || !company.id || !company.name) {
                setvalidationErrors(state => ({ ...state, company: 'Please select a company for the user.' }));
            }
            else {
                setvalidationErrors(state => ({ ...state, company: '' }));
            }

            if (!userName) {
                setvalidationErrors(state => ({ ...state, userName: 'Username type must be provided.' }));
            }
            else {
                setvalidationErrors(state => ({ ...state, userName: '' }));
            }

            if (!name) {
                setvalidationErrors(state => ({ ...state, name: 'Name code must be provided.' }));
            }
            else {
                setvalidationErrors(state => ({ ...state, name: '' }));
            }

            if (!surname) {
                setvalidationErrors(state => ({ ...state, surname: 'Surname code must be provided.' }));
            }
            else {
                setvalidationErrors(state => ({ ...state, surname: '' }));
            }

            if (!region) {
                setvalidationErrors(state => ({ ...state, region: 'Please a region for the user.' }));
            }
            else {
                setvalidationErrors(state => ({ ...state, region: '' }));
            }

            if (!emailAddress) {
                setvalidationErrors(state => ({ ...state, emailAddress: 'A valid email address mut be provided.' }));
            }
            else {
                setvalidationErrors(state => ({ ...state, emailAddress: '' }));
            }

            if (!userRoles || userRoles.length == 0) {
                setvalidationErrors(state => ({ ...state, userRoles: 'Please select at least one role.' }));
            }
            else {
                setvalidationErrors(state => ({ ...state, userRoles: '' }));
            }

            if (!onUpdateMode) // create mode
            {
                if (!password) {
                    setvalidationErrors(state => ({ ...state, password: 'Password must be provided.' }));
                }
                else {
                    setvalidationErrors(state => ({ ...state, password: '' }));
                }
            }
        },
        [userName, name, surname, region, company, emailAddress, userRoles]
    );

    const validate = () => {
        if (validationErrors) {
            var isValid = Object.values(validationErrors).every(item => item === '');
            return isValid;
        }

        return true;
    }

    const getValidationMessages = () => {
        if (validationErrors) {
            var message = Object.values(validationErrors).filter((each) => each && each.length > 0).join("\n");
            return message
        }

        return null;
    }

    const onSubmitForm = async (e) => {
        e.preventDefault();

        if (!validate()) {
            var message = getValidationMessages();
            setSubmitValidations(true);
            toast.error(message);
            return;
        }

        var userModel = {
            companyCode: company.code,
            department: null,
            email: emailAddress,
            name: name,
            region: region,
            roles: userRoles,
            surname: surname,
            type: 1,
            username: userName,
            company: {
                id: company.id,
                name: company.name,
                code: company.code
            },
            authorizedClients: authorizedClients
        };
        if (!onUpdateMode) {
            // create mode
            userModel.password = password;
            userModel.status = "1"
        }
        else {
            // update mode
            userModel.password = password;
            userModel.repassword = password;
            userModel.status = status;
        }
        await saveUser(userModel);
        history.goBack();
    };

    const renderRoles = (error) => {
        if (!roles || roles.length == 0) {
            return <></>;
        }

        var div = <div className="form-group col-md-12">
            {
                roles.map((role, index) => renderCheckBox(role, index, onRoleCheckBoxChanged))
            }
            <small className="form-text text-danger">{submitValidations ? error : ''}</small>
        </div>;
        return div;
    };

    const onRoleCheckBoxChanged = (event) => {
        event.persist();
        var _userRolesClone = userRoles.slice();
        if (event.target.checked) {
            if (_userRolesClone.indexOf(event.target.name) < 0) {
                _userRolesClone.push(event.target.name);
            }
        }
        else {
            if (_userRolesClone.indexOf(event.target.name) >= 0) {
                _userRolesClone.splice(userRoles.indexOf(event.target.name), 1);
            }
        }
        setUserRoles(_userRolesClone);
    }

    const renderCheckBox = (role, key, onChangedHandler) => {
        var isChecked = userRoles.indexOf(role.role) >= 0;
        return <div className="CheckboxGroup_checkboxOne__1_Lj4" key={key}>
            <div className="form-check">
                <input type="checkbox" name={role.role} checked={isChecked} value={role.role} className="form-check-input" key={role.role} onChange={onChangedHandler} />
                <label className="form-check-label" style={{ marginLeft: "20px", marginTop: "10px" }}>
                    {role.role}
                </label>
            </div>
        </div>;
    }

    const onActivePassiveChange = (e) => {
        setStatus(e.target.value);
    };

    const format = (data) => {
        if (data) {
            const codePart = data.code ? `(${data.code})` : '';
            const namePart = data.name ? `${data.name}` : '';
            const delimiter = ', ';
            return `${codePart}${delimiter}${namePart}`;
        } else
            return "";
    }

    const renderActivePassivevalues = () => {
        var list = [{ value: "1", text: "Active" }, { value: "0", text: "Passive" }];
        return (
            <>
                <label className="col-sm-3 d-none d-sm-block col-form-label">Status</label>
                {
                    list.map(s => {
                        return (
                            <Col xs={6} key={s.value}>
                                <Radio name="status-list" className="mt-2" key={s.value} checked={s.value === status} value={s.value} onChange={onActivePassiveChange}>
                                    {s.text}
                                </Radio>
                            </Col>
                        )
                    })
                }
            </>
        );
    };

    const deleteClientClickedHandler = (client, e) => {
        e.preventDefault();

        setAuthorizedClients((prevState, props) => {
            const authorizedClientsClone = [...prevState];
            const indexToRemove = authorizedClientsClone.findIndex(x => x.id === client.id);
            authorizedClientsClone.splice(indexToRemove, 1);
            return authorizedClientsClone;
        });
    }

    const tableConfigurations = {
        noRowText: '',
        hasFilter: false,
        entities: authorizedClients,
        columns: [
            { alias: 'Name', name: 'name', type: 'string' },
        ],
        rowActions: [
            { title: 'Delete User Client Authorization', clickEvent: deleteClientClickedHandler, icon: 'trash' },
        ],
    }

    const renderCompanies = (error) => {
        return (
            <>
                <label htmlFor="companyQuery">Company ID or Name</label><br></br>
                <input id="companyQuery" list="companies" value={companyQuery} onChange={e => onCompanyChanged(e)} autoComplete="off" placeholder="Min 2 characters" />
                <datalist id="companies">
                    {
                        companyList.map((item, i) => <option key={i} value={format(item)} />)
                    }
                </datalist>
                <small className="form-text text-danger">{submitValidations ? error : ''}</small>
            </>
        );
    }

    const addClientClickedHandler = (e) => {
        e.preventDefault();
        if (!selectedClient || !selectedClient.id) {
            toast.error('Client selection is required');
            return;
        }

        setAuthorizedClients((prevState, props) => {
            if (!prevState) {
                prevState = [];
            }
            const authorizedClientsClone = [...prevState];
            if (!authorizedClientsClone.some(x => x.id === selectedClient.id)) {
                authorizedClientsClone.push(selectedClient);
            }

            return authorizedClientsClone;
        });
    }


    return (
        <Card>
            <Card.Body>
                <form onSubmit={onSubmitForm}>
                    <Row>
                        <Col>
                            {renderInput({ label: 'User name', name: 'userName', error: error.userName, value: userName, changeEvent: onUserNameChanged, disabled: onUpdateMode, error: validationErrors.userName })}
                        </Col>
                        <Col>
                            {renderInput({ label: 'Name', name: 'name', error: error.name, value: name, changeEvent: onNameChanged, error: validationErrors.name })}
                        </Col>
                        <Col>
                            {renderInput({ label: 'Surname', name: 'surname', error: error.surname, value: surname, changeEvent: onSurnameChanged, error: validationErrors.surname })}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {renderInput({ label: 'Email Address', name: 'emailAddress', error: error.emailAddress, value: emailAddress, changeEvent: onEmailAddressChanged, error: validationErrors.emailAddress })}
                        </Col>
                        <Col>
                            {/* {renderInput({ label: 'Company', name: 'company', error: error.company, value: company, changeEvent: onCompanyChanged })} */}
                            {renderCompanies(validationErrors.company)}
                        </Col>
                        <Col>
                            {regions && regions.length > 0 && renderSelectBox("Region", region, regions, onRegionChanged, validationErrors.region)}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {renderInput({ type: onUpdateMode ? "hidden" : "password", label: 'Password', name: 'password', value: password, changeEvent: onPasswordChanged, error: validationErrors.password })}
                            {onUpdateMode && renderActivePassivevalues()}
                        </Col>
                        <Col>
                            Roles<br />
                            {
                                renderRoles(validationErrors.userRoles)
                            }
                        </Col>
                        <Col>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div style={{ border: '1px solid grey', padding: '5px', marginBottom: '10px', marginTop: '10px' }}>
                                <Row>
                                    <Col md="12">
                                        <div>
                                            <div>
                                                Authorized Clients
                                </div>
                                            <div>
                                                <XmsTable config={tableConfigurations} />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {clients && clients.length > 0 &&
                                            <>
                                                <SelectField onChange={(e) => onClientChanged(e.target.value)} size='small' className='selectbox-labelless'>
                                                    <option value="">Select Client</option>
                                                    {clients.map((item) => (
                                                        <option value={item.id} key={item.id}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </SelectField>
                                            </>}
                                    </Col>
                                    <Col>
                                        <Button variation="outlined" onClick={(e) => addClientClickedHandler(e)}>Add<FontAwesomeIcon icon='plus'></FontAwesomeIcon></Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>

                        </Col>
                        <Col>

                        </Col>
                        <Col>
                            <input type="submit" value="Save" style={{ float: "right" }} />
                        </Col>
                    </Row>
                </form>
            </Card.Body>
        </Card>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        userList: state.user.userListResponse ? state.user.userListResponse.items : null,
        regions: state.user.regions,
        roles: state.user.roles,
        companies: state.definitions.associates && state.definitions.associates.items ? state.definitions.associates.items : []
    };
}

const mapDispatchToProps = {
    searchAssociates,
    saveUser
}

export default connect(mapStateToProps, mapDispatchToProps)(UserForm2);