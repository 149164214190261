import httpClient from '../../core/httpclient';
import { spinnerService } from '@simply007org/react-spinners';
import companyActions from '../type/company';
import post, { get } from '../../core/httpClientSync';

const apiUrl = 'xms-identity-api/api/company';
const lookupURL = 'xms-definition-api/api';
const countryUrl = 'xms-definition-api/api';
const companyUrl = 'xms-company-api/api';

export const getCompanyList = () => async (dispatch) => {
  const endPoint = `${apiUrl}/companies`;
  spinnerService.show('getCompanyList');
  httpClient
    .post(endPoint)
    .then((response) => {
      dispatch({
        type: companyActions.COMPANY_GETCOMPANIES_SUCCESS,
        payload: response.data.result
      });
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      spinnerService.hide('getCompanyList');
    });
};
export const insertOrUpdateCompany = (name, code) => async (dispatch) => {
  const endPoint = `${apiUrl}/save`;
  spinnerService.show('companyDetail');
  const insertOrUpdateCompanyRequest = {
    name: name.trim(),
    code: code.trim(),
    active: true
  };
  httpClient
    .post(endPoint, insertOrUpdateCompanyRequest)
    .then((response) => {
      dispatch({
        type: companyActions.COMPANY_CLEARCOMPANYSTATE_SUCCESS
      });
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      spinnerService.hide('companyDetail');
    });
};

export const fetchTypes = () => async (dispatch) => {
  const result = await get(`${lookupURL}/lookups?lookupType=CompanyType`, null);
  if (result.isSuccess) {
    let lookup = result.response.result.lookup.values;
    var dataList = lookup.map((each) => {
      return Object.assign({
        key: each.name,
        value: each.value,
        type: each.name
      });
    });
    dispatch({
      type: companyActions.COMPANY_FETCHTYPES_SUCCESS,
      payload: dataList
    });

    return;
  }

  dispatch({
    type: companyActions.COMPANY_FETCHTYPES_ERROR,
    payload: []
  });
};

export const fetchCountries = () => async (dispatch) => {
  const response = await get(`${countryUrl}/country/list`, null);
  if (response.isSuccess) {
    let countries = response.response.result;
    var dataList = countries
      .map((each) => {
        return Object.assign({
          key: each.id,
          value: each.id,
          type: each.name
        });
      })
      .sort(compare);
    dispatch({
      type: companyActions.COMPANY_FETCHCOUNTRIES_SUCCESS,
      payload: dataList
    });
    return;
  }

  dispatch({
    type: companyActions.COMPANY_FETCHCOUNTRIES_ERROR,
    payload: []
  });
};

function compare(country1, country2) {
  const str1 = country1.type.toUpperCase();
  const str2 = country2.type.toUpperCase();

  let comparison = 0;
  if (str1 > str2) {
    comparison = 1;
  } else if (str1 < str2) {
    comparison = -1;
  }
  return comparison;
}

export const getCompany = (id) => async (dispatch) => {
  const response = await get(`${companyUrl}/company/get-by-id?id=${id}`, null);
  if (response.isSuccess) {
    dispatch({
      type: companyActions.COMPANY_GETCOMPANY_SUCCESS,
      payload: response.response.result
    });

    return;
  }

  dispatch({
    type: companyActions.COMPANY_GETCOMPANY_ERROR,
    payload: null
  });
};

export const getClients = () => async (dispatch) => {
  const response = await post(`${companyUrl}/company/search`, { isClient: true, pageSize: 1000 }, 'getClients');
  response.isSuccess && dispatch({
      type: companyActions.COMPANY_GETCLIENTS_SUCCESS,
      payload: response.response.map(item => ({ id: item.id, name: item.name }))
  })
}

export const clearCompanyState = () => async (dispatch) => {
  dispatch({
    type: companyActions.COMPANY_CLEARCOMPANYSTATE_SUCCESS
  });
};
