import DatePicker from "react-datepicker";
import React from "react";

export const DateComponent = ({ input, label, type, className, selected, showTimeSelect, meta: { touched, error, warning }, ...props }) => {
    return (
        <div>
            <div>
                <DatePicker {...input}
                    disabled={props.disabled}
                    selected={selected}
                    placeholder={label}
                    type={type} className={className}
                    //format="MM/DD/YYYY"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    showTimeSelect={showTimeSelect}
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy k:mm"
                />
                {touched && ((error && <span className="text-danger">{error}</span>) || (warning &&
                    <span className="text-warning">{warning}</span>))}
            </div>
        </div>
    );
};