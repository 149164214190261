import actionTypes from "./actionTypes";

const initialState = {
    isRequesting: false,
    items: [],
    errors: []
};

/** VOYAGES */
function reduceVoyages(state = initialState, action) {
    switch (action.type) {
        // CREATE
        case actionTypes.CREATE_VOYAGE_REQUESTING:
            return { ...state, isRequesting: true };
        case actionTypes.CREATE_VOYAGE_SUCCESS:
            return {
                ...state,
                isRequesting: false,
                items: [...state.items, action.payload],
                errors: []
            };
        case actionTypes.CREATE_VOYAGE_FAILURE:
            return { ...state, isRequesting: true, errors: action.payload };
        // end CREATE
        // READ, singular
        case actionTypes.READ_VOYAGE_REQUESTING:
            return { ...state, isRequesting: true };
        case actionTypes.READ_VOYAGE_SUCCESS:
            return {
                ...state,
                isRequesting: false,
                items: state.items.reduce((acc, item) => {
                    return acc.find(newItem => newItem.id === item.id) ?
                        [...acc] :
                        [...acc, item]
                }, [action.payload]), // insert if not exist
                errors: []
            };
        case actionTypes.READ_VOYAGE_FAILURE:
            return { ...state, isRequesting: false, errors: action.payload };
        // end READ, singular
        // READ, plural
        case actionTypes.READ_VOYAGES_REQUESTING:
            return { ...state, isRequesting: true };
        case actionTypes.READ_VOYAGES_SUCCESS:
            return {
                ...state,
                isRequesting: false,
                items: state.items.reduce((acc, item) => {
                    return acc.find(newItem => newItem.id === item.id) ?
                        [...acc] :
                        [...acc, item]
                }, action.payload.voyages), // insert if not exist
                errors: []
            };
        case actionTypes.READ_VOYAGES_FAILURE:
            return { ...state, isRequesting: false, errors: action.payload };
        // end READ, plural
        // UPDATE
        case actionTypes.UPDATE_VOYAGE_REQUESTING:
            return { ...state, isRequesting: true };
        case actionTypes.UPDATE_VOYAGE_SUCCESS:
            return {
                ...state,
                isRequesting: false,
                items: state.items.map(item => {
                    return item.id === action.payload.id ?
                        { ...item, ...action.payload } :
                        item
                }),
                errors: []
            };
        case actionTypes.UPDATE_VOYAGE_FAILURE:
            return { ...state, isRequesting: false, errors: action.payload };
        // end UPDATE
        // DELETE, plural
        case actionTypes.DELETE_VOYAGES_REQUESTING:
            return { ...state, isRequesting: true };
        case actionTypes.DELETE_VOYAGES_SUCCESS:
            return {
                ...state,
                isRequesting: false,
                items: state.items.filter(item => !action.payload.voyageIds.includes(item.id)),
                errors: []
            };
        case actionTypes.DELETE_VOYAGES_FAILURE:
            return { ...state, isRequesting: false, errors: action.payload };
        // end DELETE, plural
        // FILTER
        case actionTypes.FILTER_VOYAGES_REQUESTING:
            return { ...state, isRequesting: true };
        case actionTypes.FILTER_VOYAGES_SUCCESS:
            return {
                ...state,
                isRequesting: false,
                items: action.payload ? action.payload : [],
                errors: []
            };
        case actionTypes.FILTER_VOYAGES_FAILURE:
            return { ...state, isRequesting: false, errors: action.payload };
        // end FILTER
        default:
            return state;
    }
}
/* end VOYAGES */



const reducer = reduceVoyages;

export default reducer;
