import types from '../type/split';
import { spinnerService } from '@simply007org/react-spinners';
import post, { deleteSync } from '../../core/httpClientSync';
import history from '../../core/history';

const apiUrl = 'xms-document-api/api/document';
// const apiUrl = 'http://localhost:5000/api/document';

export const clearState = () => async dispatch => dispatch({ type: types.CLEAR_STATE });

export const getThumbnails = (splitFilePath, splitKey, startPage, endPage, loadingIndicatorId) => async dispatch => {
    const endPoint = `${apiUrl}/getThumbnails`;

    const getThumbnailsRequest = { Path: splitFilePath, StartPage: startPage, EndPage: endPage };
    const getThumbnailsResponse = await post(endPoint, getThumbnailsRequest, loadingIndicatorId);

    if (getThumbnailsResponse.isSuccess) {
        dispatch({
            type: types.SPLIT_GET_THUMBNAILS_SUCCESS,
            payload: {
                key: splitKey,
                thumbs: getThumbnailsResponse.response.thumbs
            }
        });
    }
}

export const approve = (pdfSplits, sourcePath, targetPath, uid) => async dispatch => {

    spinnerService.show('splitOverAll');

    const request = {
        sourcePath,
        uid,
        targetPath,
        pdfSplits
    }

    const response = await post( `${apiUrl}/approvesplits`, request);
    if (response.isSuccess) {

        pdfSplits.filter(x => x.existingDocumentUid !== null).forEach(x => {
            (async () => {
                await deleteSync(getDeleteFileUrl(`Shipment/${x.key}`, x.existingDocumentUid), null, true);
            })();
        });

        await deleteSync(getDeleteFileUrl(sourcePath, uid), null, true);

        dispatch({
            type: types.SPLIT_APPROVE_SUCCESS,
        });

        history.goBack();
        spinnerService.hide('splitOverAll');
    }
}

export const deleteSplitFile = (filePath, uid) => async dispatch => {
    spinnerService.show('splitOverAll');
    const deleteSplitFileResponse = await deleteSync(getDeleteFileUrl(filePath, uid), 'splitOverAll');
    if (deleteSplitFileResponse.isSuccess) {
        dispatch({
            type: types.SPLIT_DELETE_SPLIT_FILE_SUCCESS
        });
        history.goBack();
    }
}

export const checkAndProcessUnfinishedSplit = (relativePath, splitters) => async dispatch => {
    const getFileListEndPoint = `${apiUrl}/getFileList`;
    let splitFileUid = '';

    const getFileListResponse = await post(getFileListEndPoint, { RelativePaths: [relativePath] }, 'splitOverAll');

    if (!getFileListResponse.isSuccess
        || (getFileListResponse.response && getFileListResponse.response.length === 0)
        || (getFileListResponse.response[0].files && getFileListResponse.response[0].files.length === 0)) {
        return;
    }

    splitFileUid = getFileListResponse.response[0].files[0].uid;
    const splitPdfRequest = {
        Path: relativePath,
        Uid: splitFileUid,
        Splitters: splitters,
        GetThumbnails: true,
        ThumbnailSizePercentage: 50,
        version : 'v1'
    };
    const splitPdfEndPoint = `${apiUrl}/splitpdf`;
    const splitPdfResponse = await post(splitPdfEndPoint, splitPdfRequest, 'splitOverAll');

    if (!splitPdfResponse.isSuccess) {
        return;
    }

    //"{ key: 'S004862219040401-001', startPage: 1, endPage: 2, isFound: true, existingDocumentUid: null }
    const splitResult = splitPdfResponse.response.splits.map(split => {
        return { ...split, isFound: true, existingDocumentUid: null }
    });
    const unfoundKeys = splitters.filter(x => !splitResult.some(y => y.key === x));
    unfoundKeys.forEach(x => splitResult.push({
        key: x,
        startPage: -1,
        endPage: -1,
        isFound: false,
        existingDocumentUid: null
    }));

    const createdSplits = [...splitResult];
    const relativePathsForDocumentExistingCheck = [];

    createdSplits.filter(s => s.isFound).forEach(s => relativePathsForDocumentExistingCheck.push('Shipment/' + s.key));
    const getFileListForDocumentExistingCheckResponse = await post(getFileListEndPoint, { RelativePaths: relativePathsForDocumentExistingCheck }, 'splitOverAll');
    if (!getFileListForDocumentExistingCheckResponse.isSuccess) {
        return;
    }

    createdSplits.filter(s => s.isFound).forEach(x => {
        const filesOfCurrentRelativePath = getFileListForDocumentExistingCheckResponse.response.find(fileCountList => fileCountList.relativePath === 'Shipment/' + x.key);
        if (filesOfCurrentRelativePath) {
            const existingFile = filesOfCurrentRelativePath.files.find(specificFile => specificFile.fileName.startsWith(x.key));
            x.existingDocumentUid = existingFile ? existingFile.uid : null;
        }
    });

    dispatch({
        type: types.DO_SPLIT,
        payload: {
            splits: createdSplits,
            splitFileUid: splitFileUid
        }
    });
}

export const doSplit = (files, data, relativePath, splitters) => async dispatch => {
    const endPoint = `${apiUrl}/uploadAndMerge`;
    let createdSplits = null;
    let splitFileUid = null;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
        formData.append('File' + i, files[i]);
    }
    formData.append('Data', JSON.stringify(data));
    formData.append('Path', relativePath);

    const uploadAndMergeResponse = await post(endPoint, formData, 'splitOverAll');
    if (!uploadAndMergeResponse.isSuccess) {
        return;
    }

    splitFileUid = uploadAndMergeResponse.response.uid;
    const splitPdfRequest = {
        Path: relativePath,
        Uid: splitFileUid,
        Splitters: splitters,
        GetThumbnails: true,
        ThumbnailSizePercentage: 50,
        version : 'v1'
    };
    const splitPdfEndPoint = `${apiUrl}/splitpdf`;
    const splitPdfResponse = await post(splitPdfEndPoint, splitPdfRequest, 'splitOverAll');

    if (!splitPdfResponse.isSuccess) {
        return;
    }

    //"{ key: 'S004862219040401-001', startPage: 1, endPage: 2, isFound: true, existingDocumentUid: null }
    const splitResult = splitPdfResponse.response.splits.map(split => {
        return { ...split, isFound: true, existingDocumentUid: null }
    });
    const unfoundKeys = splitters.filter(x => !splitResult.some(y => y.key === x));
    unfoundKeys.forEach(x => splitResult.push({
        key: x,
        startPage: -1,
        endPage: -1,
        isFound: false,
        existingDocumentUid: null
    }));

    createdSplits = [...splitResult];

    const relativePathsForDocumentExistingCheck = [];

    createdSplits.filter(s => s.isFound).forEach(s => relativePathsForDocumentExistingCheck.push('Shipment/' + s.key));
    const getFileListEndPoint = `${apiUrl}/getFileList`;

    const getFileListRequest = { RelativePaths: relativePathsForDocumentExistingCheck };
    const getFileListResponse = await post(getFileListEndPoint, getFileListRequest, 'splitOverAll');
    if (!getFileListResponse.isSuccess) {
        return;
    }

    createdSplits.filter(s => s.isFound).forEach(x => {
        const filesOfCurrentRelativePath = getFileListResponse.response.find(fileCountList => fileCountList.relativePath === 'Shipment/' + x.key);
        if (filesOfCurrentRelativePath) {
            const existingFile = filesOfCurrentRelativePath.files.find(specificFile => specificFile.fileName.startsWith(x.key));

            x.existingDocumentUid = existingFile ? existingFile.uid : null;
        }
    });

    dispatch({
        type: types.DO_SPLIT,
        payload: {
            splits: createdSplits,
            splitFileUid: splitFileUid
        }
    });
}

export const removeUnSavedSplit = (splitKey) => async dispatch => {
    dispatch({
        type: types.SPLIT_REMOVE_UNSAVED_SPLIT,
        payload: splitKey
    });
}

const getDeleteFileUrl = (filePath, uid) => {
    return `${apiUrl}/deleteFile?relativePath=${filePath}&uid=${uid}`;
}