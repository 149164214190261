import post, { get } from '../../core/httpClientSync';
import holdTerminalItemType from '../type/holdTerminalItem';

const apiUrl = 'xms-shipment-api';
// const apiUrl = 'http://localhost:5000';

export const clearState = () => async dispatch => {
    dispatch({
        type: holdTerminalItemType.STATE_CLEARED
    })
}

export const getHoldItemList = (shipmentId, spinnerId = null) => async dispatch => {
    const url = `${apiUrl}/api/articles/getholditemlist/${shipmentId}`;
    var response = await get(url, spinnerId);

    if (response.isSuccess) {
        dispatch({
            type: holdTerminalItemType.DATA_RETRIEVED,
            payload: {
                itemList: response.response.result.articleList,
                holdTypeList: response.response.result.holdTypeList,
                reasonTypeList: response.response.result.reasonTypeList,
            }
        })
    }
}

export const createHoldItem = (shipmentId, reasonType, holdType, description, articleIdList, spinnerId = null) => async dispatch => {
    const url = `${apiUrl}/api/articles/createholditem`;

    var request = {
        reasonType: reasonType,
        holdType: holdType,
        description: description,
        articleIdList: articleIdList
    };

    var response = await post(url, request, spinnerId);

    if (response.isSuccess) {
        dispatch(getHoldItemList(shipmentId, spinnerId))
    }
}

export const confirmHoldItem = (shipmentId, holdItemIdList, articleIdList, spinnerId = null) => async dispatch => {
    const url = `${apiUrl}/api/articles/confirmholditem`;

    var request = {
        articleIdList: articleIdList,
        holdItemIdList: holdItemIdList
    };

    var response = await post(url, request, spinnerId);

    if (response.isSuccess) {
        dispatch(getHoldItemList(shipmentId, spinnerId))
    }
}

export const deleteHoldItem = (shipmentId, holdItemIdList, articleIdList, spinnerId = null) => async dispatch => {
    const url = `${apiUrl}/api/articles/deleteholditem`;

    var request = {
        articleIdList: articleIdList,
        holdItemIdList: holdItemIdList
    };

    var response = await post(url, request, spinnerId);

    if (response.isSuccess) {
        dispatch(getHoldItemList(shipmentId, spinnerId))
    }
}