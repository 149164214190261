import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Button, Checkbox } from '@dfds-ui/react-components';
import TransportRow from './TransportRow';
import classes from './Transport.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TransportList = ({ isShow, transportList, onCheckAll, isCheckAll, onCheckTransport, isCheckTransport, onEditClick, onDeleteClick, status, onSearch, headerOptions }) => {

    const [filteredData, setFilteredData] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [searchTimeout, setSearchTimeout] = useState();

    useEffect(() => {
        setFilteredData(transportList);
        if (!onSearch) {
            setKeyword('');
        }
    }, [transportList])

    if (!isShow) {
        return (<></>)
    }

    const search = (key) => {

        const searchTerm = key.trim();
        setKeyword(searchTerm);

        if (searchTimeout) { clearTimeout(searchTimeout) };
        const newTimeOut = setTimeout(() => {

            if (!onSearch) {
                if (!searchTerm) {
                    setFilteredData(transportList);
                    return;
                }

                const newFilteredData = [];
                for (const transport of transportList) {

                    var code = transport.code ? transport.code : transport.id;
                    var upperCode = code ? code.toUpperCase() : '';

                    if (upperCode.indexOf(searchTerm.toUpperCase()) > -1) {
                        newFilteredData.push(transport);
                    }
                }

                setFilteredData(newFilteredData);
            }
            else {
                onSearch(searchTerm, status)
            }

        }, 500);
        setSearchTimeout(newTimeOut);
    }

    const renderList = () => {
        if (filteredData && filteredData.length > 0) {
            return filteredData.map((transport, i) => {
                return (
                    <TransportRow key={i} transport={transport} onChange={onCheckTransport} isChecked={isCheckTransport} onEditClick={onEditClick} onDeleteClick={onDeleteClick} />
                )
            })
        }

        return (
            <Card>
                <Card.Body>
                    <p>{status === 0 ? 'Search Transport Id or Code' : 'No transport found'} </p>
                </Card.Body>
            </Card>
        );
    }

    return (<>
        <Card>
            <Card.Header>
                <Row className="align-items-center">
                    <Col xs={2}>
                        <Checkbox className={`${classes["transports-checkbox"]}`} onChange={e => onCheckAll(e.target.checked)} checked={isCheckAll} disabled={!transportList}></Checkbox>
                    </Col>
                    <Col xs={1}></Col>
                    <Col xs={6} className="text-center">
                        <h5 className="font-weight-bold">{headerOptions.text}</h5>
                    </Col>
                    <Col xs={3} className="text-right">
                        <Button size="small" variation="primary" onClick={headerOptions.onClick} disabled={!transportList}>
                            {headerOptions.buttonText}
                            <span className={`badge badge-light ${classes["transports-count"]}`}>{headerOptions.count}</span>
                        </Button>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text"><FontAwesomeIcon icon="search" /></span>
                            </div>
                            <input type="text" className="form-control" placeholder="Search..." onChange={(e) => search(e.target.value)} value={keyword} />
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
        {renderList()}
    </>)
}

export default TransportList;