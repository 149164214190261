import React from "react";
import { Redirect } from "react-router-dom";
import { getRedirectUrl } from "../../core/authentication";

// Dashboard ihtiyacı olduğunda kullanabiliriz.
const HomePage = () => {
  return <Redirect to={getRedirectUrl()} />;
};

export default HomePage;
