/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { getRoles } from '../store/action/roleActions';
import { connect } from 'react-redux';
import history from '../core/history';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import XmsTable from '../component/XmsTable/XmsTable';
import Page from '../component/Page/Page';

const RoleList = ({ roleList, getRoles }) => {
  useEffect(() => {
    if (!roleList) {
      getRoles();
    }
  }, [roleList]);

  const newRoleClickedHandler = () => {
    history.push('/role/new');
  };

  const editRoleClickedHandler = (role) => {
    history.push('/role/' + role.role);
  };

  const tableConfigurations = {
    title: 'Roles',
    noRowText: 'There are not any roles to show.',
    hasFilter: false,
    entities: roleList,
    columns: [{ alias: 'Role', name: 'role', type: 'string' }],
    tableActions: [{ clickEvent: newRoleClickedHandler, buttonText: 'Create', icon: 'plus' }],
    rowActions: [{ title: 'Edit role', clickEvent: editRoleClickedHandler, icon: 'edit' }]
  };

  return (
    <Page>
      <XmsTable config={tableConfigurations} />
      <LoadingIndicator id="getRoles" />
    </Page>
  );
};
const mapStateToProps = (state) => {
  return {
    roleList: state.role.roleListResponse ? state.role.roleListResponse : null
  };
};

export default connect(mapStateToProps, { getRoles })(RoleList);
