import React from 'react';
import history from '../../core/history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@dfds-ui/react-components';
import { STATUS, TYPES } from '../../constants/operation/operation';
import PolicyGroupAccessHoc from "../../RoutedComponents/Routes/PolicyGroupAccessHoc";

const TransportLinkButton = (operation) => {

    if (!operation || operation.type === TYPES.UNLOADING) {
        return null;
    }

    return (
        <PolicyGroupAccessHoc componentName="TransportLinkButtonHOC">
            <Button disabled={operation.status === STATUS.COMPLATED || operation.status === STATUS.CANCELLED} size="small" variation="secondary" onClick={() => onClick(operation.id)}>
                <FontAwesomeIcon icon={['fas', 'plus']} className="mr-2" /> Link Transports
        </Button>
        </PolicyGroupAccessHoc>
    );
}

const onClick = (id) => {
    var path = `/transport/${id}`;
    history.push(path);
}

export default TransportLinkButton;