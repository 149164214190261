const companyActions = Object.freeze({
    COMPANY_GETCOMPANIES_SUCCESS: Symbol('COMPANY_GETCOMPANIES_SUCCESS'),
    COMPANY_SAVECOMPANY_SUCCESS: Symbol('COMPANY_SAVECOMPANY_SUCCESS'),
    COMPANY_GETCOMPANY_SUCCESS: Symbol('COMPANY_GETCOMPANY_SUCCESS'),
    COMPANY_GETCLIENTS_SUCCESS: Symbol('COMPANY_GETCLIENTS_SUCCESS'),
    COMPANY_GETCOMPANY_ERROR: Symbol('COMPANY_GETCOMPANY_ERROR'),
    COMPANY_CLEARCOMPANYSTATE_SUCCESS: Symbol('COMPANY_CLEARCOMPANYSTATE_SUCCESS'),
    COMPANY_FETCHCOUNTRIES_SUCCESS: Symbol('COMPANY_FETCHCOUNTRIES_SUCCESS'),
    COMPANY_FETCHCOUNTRIES_ERROR: Symbol('COMPANY_FETCHCOUNTRIES_ERROR'),
    COMPANY_FETCHTYPES_SUCCESS: Symbol('COMPANY_FETCHTYPES_SUCCESS'),
    COMPANY_FETCHTYPES_ERROR: Symbol('COMPANY_FETCHTYPES_ERROR'),
});

export default companyActions;