/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import FileControllerOperationButtons from './FileControllerOperationButtons';
import FileControllerFileItem from './FileControllerFileItem';
import {
    getFileList,
    deleteFile,
    downloadSingleFile,
    addFileToFileList,
    downloadAllDocuments,
    showFile,
    uploadSingleFile,
    resetFileControllerStoreElements
} from '../../store/action/fileControllerAction';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import uuidV1 from 'uuid/v1';
import { createActionUrl } from '../../store/action/definitionActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FileController = (props) => {

    const [addFileError, setAddFileError] = useState();

    // Inputs from parent component
    const {
        operationButtonsToShow,
        uploadOptions,
        deleteOptions,
        splitOptions,
        shareOptions,
        missingDocumentOptions,
        onToggleDocsMissingState,
        docsMissingHoldOptions,
        loader
    } = props;

    // Inputs from redux
    const {
        fileList,
        authenticatedUser,
        getFileList,
        deleteFile,
        downloadSingleFile,
        downloadOptions,
        addFileToFileList,
        downloadAllDocuments,
        uploadSingleFile,
        createActionUrl,
        showFile,
        resetFileControllerStoreElements
    } = props

    useEffect(() => {
        resetFileControllerStoreElements();
    }, [])

    useEffect(() => {
        if (!fileList) {
            getFileList(uploadOptions.uploadFileRelativePathAsArray, 'getFileList');
        }
    }, [fileList])

    const onFilesSelected = selectedFiles => {
        const fileListAsArray = Object.values(fileList);
        for (const selectedFile of selectedFiles) {
            addFileToList(selectedFile, fileListAsArray);
        }
    }

    const addFileToList = (selectedFile, fileListAsArray) => {
        const fileInList = fileListAsArray.filter(
            a => a.name === selectedFile.name &&
                a.sizeInBytes === selectedFile.size
        )

        if (fileInList && fileInList.length) {
            showErrorMessage('File already in List', setAddFileError);
            return;
        }

        selectedFile.externalId = uploadOptions.externalId;
        selectedFile.externalType = uploadOptions.externalType;

        selectedFile.sourceType = 'document';
        if (selectedFile.type.indexOf('image') > -1) {
            selectedFile.sourceType = 'image';
        }

        selectedFile.relativePath = uploadOptions.uploadFileRelativePath;

        selectedFile.status = 'not-uploaded';
        selectedFile.audit = {
            creationDate: new Date(),
            createdBy: authenticatedUser.name
        };
        selectedFile.uid = uuidV1();

        if (selectedFile.type === 'application/pdf') {
            var reader = new FileReader();
            reader.readAsBinaryString(selectedFile);
            reader.onloadend = function () {
                selectedFile.pageCount = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
            }
        }

        addFileToFileList(selectedFile);
    }

    const onDeleteFile = fileToDelete => {
        if (fileToDelete.status === 'uploaded') {
            if (window.confirm('File will be deleted. Are you sure?')) {
                deleteFile(fileToDelete, deleteOptions.deleteFileRelativePath, 'getFileList');
            }
        } else {
            deleteFile(fileToDelete, deleteOptions.deleteFileRelativePath, 'getFileList');
        }
    }

    const onDownloadFile = fileToDownload => {
        downloadSingleFile(fileToDownload, downloadOptions.downloadFileRelativePath, 'getFileList')
    }

    const onDownloadAllDocuments = () => {
        downloadAllDocuments(downloadOptions.downloadAllFilePathList, uploadOptions.uploadFileRelativePath, 'getFileList');
    }

    const onShowFile = (file) => {
        showFile(file, 'getFileList');
    }

    const onUploadDocuments = () => {
        const tmpList = [...Object.values(fileList)];

        for (const fileItem of tmpList) {
            if (fileItem.status === 'not-uploaded') {
                uploadSingleFile(
                    fileItem,
                    uploadOptions.uploadData,
                    uploadOptions.uploadFileRelativePath
                );
            }
        }
    }

    const onShareDocuments = () => {
        createActionUrl(shareOptions.actionKey, shareOptions.actionType, 'getFileList');
    }

    const renderFileList = () => {
        return (
            <>
                <div className="row">
                    <div className="col">
                        <div className='table-responsive table-center mt-1'>
                            <table className='table table-bordered'>
                                {renderFileListHeader()}
                                <tbody>
                                    {renderFileItems()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const renderFileListHeader = () => {
        return (

            <thead>
                <tr>
                    <th scope='col' className="text-center">Name</th>
                    <th scope='col' className="text-center">Status</th>
                    <th scope='col' className="text-center">Size</th>
                    <th scope='col' className="text-center">Creation Date</th>
                    <th scope='col' className="text-center">Created By</th>
                    <th scope='col' className="text-center">Action</th>
                </tr>
            </thead>
        )
    }

    const renderFileItems = () => {

        if (!fileList) { return; }

        const fileListAsArray = Object.values(fileList);

        if (!fileListAsArray || !fileListAsArray.length) {
            return (
                <tr>
                    <td colSpan='6' className='text-center'>
                        There are no documents to show.
                    </td>
                </tr>
            )
        }

        return fileListAsArray.map(fileItem => {
            return (
                <FileControllerFileItem
                    key={fileItem.uid}
                    fileItem={fileItem}
                    onDeleteFile={onDeleteFile}
                    onDownloadFile={onDownloadFile}
                    onShowFile={onShowFile}
                />
            )
        })
    }

    const renderDocsMissingWarning = () => {
        if (missingDocumentOptions && (missingDocumentOptions.hasDocsMissingFlagSet || missingDocumentOptions.docsMissingBySystem)) {
            return (
                <>
                    <FontAwesomeIcon icon="exclamation-triangle" />  Docs. Missing
                </>
            )
        }
    }

    return (
        <div className='card'>
            <div className='card-header collapsed' href="#collapseDocuments" data-toggle="collapse" data-parent="#accordion">
                <div className="row">
                    <div className="col">
                        <h5 className='mb-0'>
                            <img src="/image/icon/down.svg" className="color-action-blue" width="20px" alt='' /> Documents ({fileList ? Object.values(fileList).length : 0})
                        </h5>
                    </div>
                    <div className="col text-right">
                        {renderDocsMissingWarning()}
                    </div>
                </div>
            </div>
            <div id="collapseDocuments" className="collapse">
                <div className='card card-body'>
                    <div className='row'>
                        <FileControllerOperationButtons
                            operationButtonsToShow={operationButtonsToShow}
                            onFilesSelected={onFilesSelected}
                            onDownloadAllDocuments={onDownloadAllDocuments}
                            onUploadDocuments={onUploadDocuments}
                            splitUrl={splitOptions ? splitOptions.splitUrl : null}
                            fileList={fileList}
                            onShareDocuments={onShareDocuments}
                            missingDocumentOptions={missingDocumentOptions}
                            onToggleDocsMissingState={onToggleDocsMissingState}
                            docsMissingHoldOptions={docsMissingHoldOptions}
                        />
                        <div>
                            <h6 className='text-danger'>{addFileError}</h6>
                        </div>
                    </div>
                    {renderFileList()}
                    <LoadingIndicator id={uploadOptions.loader ? uploadOptions.loader : 'getFileList'} />
                </div>
            </div>
        </div>
    )
}

const showErrorMessage = (errorMessage, setErrorMessage) => {
    setErrorMessage(errorMessage);
    setTimeout(() => {
        setErrorMessage('');
    }, 3000);
}

const mapStateToProps = ({ fileController, auth }) => {

    if (!fileController) { return {} }

    return {
        authenticatedUser: auth.authenticatedUser,
        fileList: fileController.fileList
    }
}

const mapActionToProps = {
    getFileList,
    deleteFile,
    downloadSingleFile,
    addFileToFileList,
    downloadAllDocuments,
    uploadSingleFile,
    createActionUrl,
    showFile,
    resetFileControllerStoreElements
}

export default connect(mapStateToProps, mapActionToProps)(FileController);