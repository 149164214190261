/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getRules, deleteRule, getClients, setRuleFilter, getReservationWarnings } from '../store/action/reservedSlotActions';
import history from '../core/history';
import XmsTable from '../component/XmsTable/XmsTable';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import moment from 'moment';
import Page from '../component/Page/Page';
import { confirmToDelete } from '../store/action/modalActions';
import { Row, Col, Form, Card, Button, Badge } from 'react-bootstrap';
import { getBusinessAreas } from '../store/action/calendarActions';
import DatePicker from "react-datepicker";
import { weekDaysWithEveryday } from '../core/utility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './ReservedSlotRuleList.module.css';
import { utcToEpoch } from '../core/utcToEpochConverter';
import ModalReservedSlotRuleWarnings from './ModalReservedSlotRuleWarnings';


const ReservedSlotRuleList = (props) => {
  //Redux actions
  const { getRules, deleteRule, confirmToDelete, getBusinessAreas, getClients, setRuleFilter, getReservationWarnings } = props;

  //Redux store
  let { rules, refreshRules, businessAreas, clients, ruleFilter, reservationWarnings } = props;

  const [isModalReservedSlotRuleWarnings, setIsModalReservedSlotRuleWarnings] = useState(false);

  useEffect(() => {
    if (ruleFilter)
      filterClicked();

      getReservationWarnings();
  }, []);

  useEffect(() => {
    if (!rules || refreshRules) {
      getRules();
    } else if (ruleFilter) {
      filterClicked();
    } else {
      setFilteredData(rules);
    }
  }, [rules, refreshRules, getRules]);

  useEffect(() => {
    if (!businessAreas) {
      getBusinessAreas();
    }
  }, [businessAreas]);

  useEffect(() => {
    if (!clients) {
      getClients();
    }
  }, [clients]);

  const [filteredData, setFilteredData] = useState();

  const newRuleClickedHandler = () => {
    history.push('/booking/reserved-slot-rule/new');
  };

  const deleteRuleClickedHandler = (rule) => {
    confirmToDelete(() => {
      deleteRule(rule.id);
    });
  };

  const editRuleClickedHandler = (rule) => {
    history.push(`/booking/reserved-slot-rule/${rule.id}`);
  };

  const businessAreaFilterChanged = (value) => {
    setRuleFilter({ ...ruleFilter, businessAreaId: value });
  };



  const carrierFilterChanged = (value) => {
    setRuleFilter({ ...ruleFilter, carrierId: value });
  };

  const startDateFilterChanged = (value) => {
    setRuleFilter({ ...ruleFilter, startDate: value });
  };

  const endDateFilterChanged = (value) => {
    setRuleFilter({ ...ruleFilter, endDate: value });
  };

  const weekdayFilterChanged = (value) => {
    setRuleFilter({ ...ruleFilter, dayOfWeek: value });
  };

  const filterClicked = () => {
    var foundRules = rules;
    if (ruleFilter.businessAreaId) {
      foundRules = foundRules && foundRules.filter(
        a => a.businessAreaId === ruleFilter.businessAreaId
      );
    }

    if (ruleFilter.carrierId) {
      foundRules = foundRules && foundRules.filter(
        a => a.clientId === ruleFilter.carrierId
      );
    }

    if (ruleFilter.dayOfWeek) {
      foundRules = foundRules && foundRules.filter(
        a => a.dayOfWeek === ruleFilter.dayOfWeek
      );
    }

    if (ruleFilter.startDate) {
      foundRules = foundRules && foundRules.filter(
        a => a.startDate >= utcToEpoch(formattedDateOf(ruleFilter.startDate).toDate())
      );
    }

    if (ruleFilter.endDate) {
      foundRules = foundRules && foundRules.filter(
        a => a.endDate <= utcToEpoch(formattedDateOf(ruleFilter.endDate).toDate())
      );
    }

    setFilteredData(foundRules);
  }

  const formattedDateOf = date => moment(date, "DD/MM/YYYY HH:mm")

  const renderBadge = () => {
    return (<>
      <Button variant="outline-danger" style={{ marginLeft: '10px' }} onClick={() => setIsModalReservedSlotRuleWarnings(true)}>
        Warnings <Badge pill variant="danger">{reservationWarnings.length}</Badge>
      </Button>
    </>);
  }

  const renderFilters = () => {
    return (<>
      <Card className={styles.functionControls}>
        <Row className={styles.functionSection}>
          <Col style={{ width: '100%', padding: '0px' }}>
            <Row>
              <Col xs="12" sm="6" md="3" lg="2">
                <Form.Group>
                  <Form.Label>Business Area</Form.Label>
                  <select id="businessAreaCombobox" onChange={(e) => businessAreaFilterChanged(e.target.value)} value={ruleFilter.businessAreaId} style={{ padding: 6 }}>
                    <option key={""} value={""}>Choose Business Area...</option>
                    {businessAreas && businessAreas.map((businessArea) => (
                      <option key={businessArea.id} value={businessArea.id}>
                        {businessArea.name}
                      </option>
                    ))}
                  </select>
                </Form.Group>
              </Col>
              <Col xs="12" sm="6" md="3" lg="2">
                <Form.Group>
                  <Form.Label>Carrier</Form.Label>
                  <select id="carrierCombobox" onChange={(e) => carrierFilterChanged(e.target.value)} value={ruleFilter.carrierId} style={{ padding: 6 }}>
                    <option key={""} value={""}>Choose Carrier...</option>
                    {clients && clients.map((client) => (
                      <option key={client.value} value={client.value}>
                        {client.text}
                      </option>
                    ))}
                  </select>
                </Form.Group>
              </Col>
              <Col xs="12" sm="6" md="3" lg="2">
                <Form.Group>
                  <Form.Label>Weekday</Form.Label>
                  <select id="weekdayCombobox" onChange={(e) => weekdayFilterChanged(e.target.value)} value={ruleFilter.dayOfWeek} style={{ padding: 6 }}>
                    <option key={""} value={""}>Choose Weekday...</option>
                    {weekDaysWithEveryday && weekDaysWithEveryday.map((day) => (
                      <option key={day.value} value={day.value}>
                        {day.text}
                      </option>
                    ))}
                  </select>
                </Form.Group>
              </Col>
              <Col xs="12" sm="6" md="3" lg="2">
                <Form.Group>
                  <Form.Label>Start Date</Form.Label>
                  <DatePicker selected={ruleFilter.startDate}
                    type="text"
                    className="form-control"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormat="MM/dd/yyyy"
                    onChange={startDateFilterChanged}
                    emptyLabel="Your text"
                  />
                </Form.Group>
              </Col>
              <Col xs="12" sm="6" md="3" lg="2">
                <Form.Group>
                  <Form.Label>End Date</Form.Label>
                  <DatePicker selected={ruleFilter.endDate}
                    type="text"
                    className="form-control"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormat="MM/dd/yyyy"
                    onChange={endDateFilterChanged}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>

              <Col xs="12" sm="6" md="9" lg="10" ></Col>

              <Col xs="12" sm="6" md="3" lg="2">
                <Button variation='secondary'
                  className="input-block"
                  key='search'
                  id='search'
                  onClick={(e) => filterClicked()}>
                  Search <FontAwesomeIcon icon="search" />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </>);
    //  return <Form.Control type="text" placeholder="Search for Business Area, Carrier, Unit No or Weekday" onChange={(e) => searchTermChangedHandler(e.target.value)} />
  }

  const renderReservationWarnings = () => {
    return (
      <>
        <ModalReservedSlotRuleWarnings
        showModal={reservationWarnings && reservationWarnings.length > 0 && isModalReservedSlotRuleWarnings}
        onHide={() => setIsModalReservedSlotRuleWarnings(false)}
        warnings={{reservationWarnings}}
      ></ModalReservedSlotRuleWarnings>
      </>
    )
  }

  const renderValidTimeColumn = (rule) => {

    return (
      moment.utc().startOf('day').add(rule.startMinutesSinceMidnight, 'minutes').format('HH:mm') +
      (rule.endMinutesSinceMidnight != 0 ? ' - ' : '') +
      (rule.endMinutesSinceMidnight != 0 ? moment.utc().startOf('day').add(rule.endMinutesSinceMidnight, 'minutes').format('HH:mm') : '')
    );
  };

  const tableConfiguration = {
    title: 'Reserved Slot Rules',
    noRowText: 'There are not any rules to show.',
    hasFilter: true,
    showFiltersOnInit: true,
    entities: filteredData,
    columns: [
      { alias: 'Business Area', name: 'businessAreaName', type: 'string' },
      { alias: 'Carrier', name: 'clientName', type: 'string' },
      { alias: 'Unit No', name: 'unitNo', type: 'string' },
      { alias: 'Weekday', name: 'dayOfWeek', type: 'string' },
      { alias: 'Start Date', name: 'startDate', type: 'epoch', format: 'shortDate' },
      { alias: 'End Date', name: 'endDate', type: 'epoch', format: 'shortDate' },
      { alias: 'Valid Time', name: null, type: 'custom', customRender: renderValidTimeColumn }
    ],
    tableActions: [{ clickEvent: newRuleClickedHandler, buttonText: 'Create', icon: 'plus' }],
    rowActions: [
      { title: 'Delete rule', clickEvent: deleteRuleClickedHandler, icon: 'trash' },
      { title: 'Edit rule', clickEvent: editRuleClickedHandler, icon: 'edit' }
    ],
    filterRenderer: renderFilters,
    badgeRenderer: renderBadge
  };

  return (
    <Page backLink="/booking/calendar-rule" backToWhere="Rules">     
      <XmsTable config={tableConfiguration} />      
      {renderReservationWarnings()}
      <LoadingIndicator id="addOrEditReservedSlotRule" />
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    rules: state.reservedSlot.rules,
    refreshRules: state.reservedSlot.refreshRules,
    businessAreas: state.calendar.businessAreas,
    clients: state.reservedSlot.clients,
    ruleFilter: state.reservedSlot.ruleFilter,
    reservationWarnings: state.reservedSlot.reservationWarnings
  };
};

const mapDispatchToProps = {
  getRules,
  deleteRule,
  confirmToDelete,
  getBusinessAreas,
  getClients,
  setRuleFilter,
  getReservationWarnings
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservedSlotRuleList);
