import type from '../type/transport';

const initialState = {
    transportItem: null,
    saveCount: 0,
    operation: null,
    list: [],
    availableList: [],
    operationList: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case type.STATE_CLEARED:
            return initialState;
        case type.OPERATION_RECEIVED:
            return {
                ...state,
                operation: action.payload.operation
            }
        case type.LIST_RECEIVED:
            return {
                ...state,
                list: action.payload.groups ? action.payload.groups : [],
            }
        case type.UNLINKED_LIST_RECEIVED:
            return {
                ...state,
                availableList: action.payload.groups ? action.payload.groups : [],
            }
        case type.LINKED_LIST_RECEIVED:
            return {
                ...state,
                operationList: action.payload.groups ? action.payload.groups : [],
            }
        case type.LINKED:

            var fromList = [...state.availableList];
            var toList = [...state.operationList];

            for (var i = 0; i < action.payload.length; i++) {
                var id = action.payload[i];

                var transport = fromList.find(s => s.id === id);

                fromList = fromList.filter(s => s.id !== id);
                toList.push(transport);
            }

            return {
                ...state,
                availableList: fromList,
                operationList: toList
            }
        case type.UNLINKED:

            var fromList = [...state.availableList];
            var toList = [...state.operationList];

            for (var i = 0; i < action.payload.length; i++) {
                var id = action.payload[i];

                var transport = toList.find(s => s.id === id);

                toList = toList.filter(s => s.id !== id);
                fromList.push(transport);
            }
            return {
                ...state,
                availableList: fromList,
                operationList: toList
            }
        case type.SAVED:

            var item = action.payload;

            var fromList = [...state.availableList];
            if (fromList.find(s => s.id === item.id) == undefined) {
                fromList.push(action.payload);
            } else {
                var index = fromList.findIndex(s => s.id === item.id);
                fromList[index] = item;
            }

            return {
                ...state,
                availableList: fromList,
                transportItem: state.operation ? null : state.transportItem,
                saveCount: state.saveCount + 1
            }
        case type.EDITED:
            return {
                ...state,
                transportItem: action.payload
            }
        case type.DELETED:

            var fromList = [...state.availableList];
            fromList = fromList.filter(s => s.id !== action.payload);

            return {
                ...state,
                availableList: fromList,
                transportItem: null
            }
        default:
            return state;
    }
}