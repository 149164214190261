import { Accordion } from '@dfds-ui/react-components';
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ToShortDateFormat, ToShortDateTimeFormat } from '../../core/utility';
import Barcode from 'react-barcode';
import { array } from 'prop-types';

var date = new Date();
const currentDate = ToShortDateFormat(date);
const currentTime = ToShortDateTimeFormat(date);

const CommonPart = ({ allData, customerRelatedData, shipmentReferenceNumber }) => {
    
    const totalItemCount = () => {
        if (customerRelatedData && customerRelatedData.shipmentList) {
            return customerRelatedData.shipmentList.flatMap(s => (s.actualMeasurement && 
                                                                   s.actualMeasurement.itemQuantity && 
                                                                   s.actualMeasurement.itemQuantity.value ? s.actualMeasurement.itemQuantity.value : 0)).reduce((a, b) => a + b)
        }
        return 0;
    }

    const totalWeight = () => {
        if (customerRelatedData && customerRelatedData.shipmentList) {
            return customerRelatedData.shipmentList.flatMap(s => (s.actualMeasurement && 
                                                                   s.actualMeasurement.grossWeight && 
                                                                   s.actualMeasurement.grossWeight.value ? s.actualMeasurement.grossWeight.value : 0)).reduce((a, b) => a + b)
        }
        return 0;
    }

    const totalVolume = () => {
        if (customerRelatedData && customerRelatedData.shipmentList) {
            return customerRelatedData.shipmentList.flatMap(s => (s.actualMeasurement && 
                                                                   s.actualMeasurement.volume && 
                                                                   s.actualMeasurement.volume.value ? s.actualMeasurement.volume.value : 0)).reduce((a, b) => a + b)
        }
        return 0;
    }

    return (
        (!customerRelatedData || !allData) ?
            <></> :
            <>
                <tr class="head-row">
                    <td></td>
                    <td colspan="3">Detta uppdrag utförs i enlighet med transportföretagets vid varje tidpunkt gällande ansvarsbestämmelser</td>
                    <td>
                        <h2>FRAKTSEDEL</h2>
                        Transportinstruktion
                </td>
                    <td>Transportföretag, -slag</td>
                    <td>Sida nr 1/1</td>
                    <td></td>
                </tr>

                <tr>
                    <td></td>
                    <td colspan="3" rowspan="3">
                        <div>
                            <h1>Godsavsändare, postadress</h1>
                            <p>DFDS Logistics Contracts AB</p>
                            <p>Terminalvägen 9</p>
                            <p>440044</p>
                        </div>
                        <div class="container">
                            <div class="item">
                                <h1>Avsändningsort/-station (inkl postnr)</h1>
                                <p>40508 Göteborg</p>
                            </div>
                            <div class="item">
                                <h1>Godsavsändarens Telefon/fax</h1>
                                <p></p>
                            </div>
                        </div>
                    </td>
                    <td>
                        <h1>Utskrivningsdatum</h1>
                        <p>{currentDate}</p>
                    </td>
                    <td colspan="2">
                        <h1>Fraktsedel nr</h1>
                        <p>FS002400920020701-002</p>
                    </td>
                    <td></td>
                </tr>

                <tr>
                    <td></td>
                    <td colspan="3">
                        <h1>Godsavsändarens referens</h1>
                        <span>{customerRelatedData.group && customerRelatedData.group.code}</span>
                    </td>
                    <td></td>
                </tr>

                <tr>
                    <td></td>
                    <td colspan="3">
                        <h1>Godsmottagarens referens</h1>
                        <span></span>
                        <span></span>
                    </td>
                    <td></td>
                </tr>

                <tr>
                    <td class="side-note">
                        <h2><strong>TILL</strong></h2>
                        <p>Leve-rans-adress</p>
                    </td>
                    <td colspan="3" rowspan="3">
                        <div>
                            <h1>Godsavsändare, lev.adress</h1>
                            <p>{customerRelatedData.endCustomer && customerRelatedData.endCustomer.name}</p>
                            <p>{customerRelatedData.endCustomer && customerRelatedData.endCustomer.address && customerRelatedData.endCustomer.address.addressLine}</p>
                            <p>{customerRelatedData.endCustomer && customerRelatedData.endCustomer.address && (customerRelatedData.endCustomer.address.postalCode + " " + customerRelatedData.endCustomer.address.city)}</p>
                        </div>
                    </td>
                    <td colspan="3">Särskilda Transportinstruktioner (värme, kyla e d )</td>
                    <td></td>
                </tr>

                <tr>
                    <td></td>
                    <td>Efterkrav, kr</td>
                    <td>Gironr</td>
                    <td>Efterkravsreferens</td>
                    <td></td>
                </tr>

                <tr>
                    <td></td>
                    <td>
                        <h1>Fraktbetalning</h1>
                        <div>
                            <input type="checkbox" disabled /> <label>1 Avsändaren betalar</label>
                        </div>
                    </td>
                    <td>
                        <h1>inkl (kod)</h1>
                        <div></div>
                    </td>
                    <td>
                        <h1>Transportavtal nr</h1>
                        <div></div>
                    </td>
                    <td></td>
                </tr>

                <tr>
                    <td></td>
                    <td colspan="3">
                        <h1>Bestämmelseort/-station (inkl postnr)</h1>
                        <p>90434 UMEÅ</p>
                    </td>
                    <td>
                        <div>
                            <input type="checkbox" disabled /><label>3 Mottagaren Betalar</label>
                        </div>
                    </td>
                    <td>
                        <div>
                            <input type="checkbox" disabled /><label>4 Annan Fraktbetalning</label>
                        </div>
                    </td>
                    <td>
                        <h1>GAN/Kundnummer</h1>
                        <div></div>
                    </td>
                    <td></td>
                </tr>

                <tr>
                    <td></td>
                    <td colspan="3">
                        <div class="container">
                            <div class="item">
                                <h1>Leveransanvisning (Efterkrav o d)</h1>
                                <p>Suffix: {customerRelatedData.suffix}</p>
                            </div>
                            <div class="item">
                                <h1>Aviseras</h1>
                                <div><input type="checkbox" disabled /><label>Tel</label></div>
                                <div><input type="checkbox" disabled /><label>Fax</label></div>
                            </div>
                        </div>
                    </td>
                    <td colspan="3">Kodfält</td>
                    <td></td>
                </tr>

                <tr>
                    <td></td>
                    <td>Ant godk EUR-pallar</td>
                    <td>Godsmottagarens pallreg-nr</td>
                    <td>Godsavsändarens pallreg-nr</td>
                    <td colspan="3" rowspan="2">
                        Avsändarens godsadress-nummer (Streckkod)
                        {shipmentReferenceNumber && <Barcode value={shipmentReferenceNumber} /*format="CODE39"*/ width={1.5} />}
                    </td>
                    <td></td>
                </tr>

                <tr>
                    <td></td>
                    <td colspan="3">Lasttillbehör, behållare, (ägare, typ, nr) / presseningar (ägare, typ, antal)</td>
                    <td></td>
                </tr>

                <tr>
                    <td></td>
                    <td colspan="6">

                        {/* THU table */}
                        <table class="thu-table">

                            <thead>
                                <tr>
                                    <th>Godsmärkning/vagnsnummer</th>
                                    <th>Kolliantal</th>
                                    <th>Kollislag</th>
                                    <th>Varuslag (om i container/flak även art, ägare,nr, längd)</th>
                                    <th>Bruttovikt, kg</th>
                                    <th>Volym m³ (om icontainer/flak:godsvikt)</th>
                                    <th>Tull</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    (customerRelatedData.shipmentList && customerRelatedData.shipmentList.length > 0) && customerRelatedData.shipmentList.map((eachShipment, i, arr) => {
                                        return (
                                            <>
                                                <tr key={i}>
                                                    <td>
                                                        {(eachShipment.destination && eachShipment.destination.name) + " " + (eachShipment.commodityType ? eachShipment.commodityType : '')}
                                                    </td>
                                                    <td>
                                                        {eachShipment.actualMeasurement && eachShipment.actualMeasurement.itemQuantity && eachShipment.actualMeasurement.itemQuantity.value}
                                                    </td>
                                                    <td>
                                                        THU
                                                </td>
                                                    <td>
                                                        {eachShipment.shipmentNo}
                                                    </td>
                                                    <td>
                                                        {eachShipment.actualMeasurement && eachShipment.actualMeasurement.grossVolume && eachShipment.actualMeasurement.grossVolume.value}
                                                    </td>
                                                    <td>
                                                        {eachShipment.actualMeasurement && eachShipment.actualMeasurement.grossWeight && eachShipment.actualMeasurement.grossWeight.value}
                                                    </td>
                                                    <td><input type="checkbox" disabled /></td>
                                                </tr>
                                                {
                                                    (eachShipment.isHazardous && eachShipment.hazardousList && eachShipment.hazardousList.length > 0) &&
                                                    <tr key={(i + array.length)}>
                                                        <td colspan="7">
                                                            {/* Hazardous items table */}
                                                            <table class="hazardous-items-table">
                                                                <tbody>
                                                                    <tr>
                                                                        <th>UN nr</th>
                                                                        <th>Benämning</th>
                                                                        <th>Klass</th>
                                                                        <th>PG</th>
                                                                        <th>Tunnelrestriktionskod</th>
                                                                        <th>Förpackningstyp och antal</th>
                                                                        <th>Nettovikt</th>
                                                                    </tr>
                                                                    {eachShipment.hazardousList.map((eachHazardous, i) =>
                                                                        <tr key={i}>
                                                                            <td>{eachHazardous.undg}</td>
                                                                            <td>{eachHazardous.name}</td>
                                                                            <td>{eachHazardous.class}</td>
                                                                            <td>{eachHazardous.packingGroup}</td>
                                                                            <td>{eachHazardous.mfag}</td>
                                                                            <td></td>
                                                                            <td>{eachHazardous.netWeight}</td>
                                                                        </tr>)
                                                                    }
                                                                </tbody>
                                                            </table>
                                                            {/* END Hazardous items table */}
                                                        </td>
                                                    </tr>
                                                }
                                            </>
                                        );
                                    })
                                }

                            </tbody>

                            <tfoot>
                                <tr>
                                    <td colspan="2">
                                        <p>Lastad {currentTime}</p>
                                    </td>
                                    <td colspan="4">
                                        <strong>Bifogade handlingar:</strong>
                                        <input type="checkbox" checked="checked" disabled /><label><strong>Last lista</strong></label>
                                        <input type="checkbox" disabled /><label><strong>T1</strong></label>
                                        <input type="checkbox" disabled /><label><strong>ADR</strong></label>
                                        <input type="checkbox" disabled /><label><strong>Följesedel</strong></label>
                                    </td>
                                </tr>

                                <tr>
                                    <th>Totalt antal kollin</th>
                                    <th>Totalt bruttovikt, kg</th>
                                    <th>Totalt Nettovikt, kg</th>
                                    <th>Kod</th>
                                    <th>Kubik, flakm, ant, kg</th>
                                    <th>Varunummer</th>
                                    <th>Kontrolltal</th>
                                </tr>

                                <tr class="table-foot">
                                    <td><strong>{totalItemCount()}</strong></td>
                                    <td><strong>{totalWeight()}</strong></td>
                                    <td><strong></strong></td>
                                    <td><strong>V</strong></td>
                                    <td><strong>{totalVolume()}</strong></td>
                                    <td><strong></strong></td>
                                    <td><strong></strong></td>
                                </tr>
                            </tfoot>

                        </table>
                        {/* END THU table*/}

                    </td>
                    <td></td>
                </tr>
            </>
    );
}

const TiPart = ({ customerRelatedData }) => {
    return (
        <>
            {/* Rows at the end of the first page*/}
            <tr class="foot-row">
                <td>
                    <h2><strong>Ti</strong></h2>
                    <p><strong>Trans-port instr-uction</strong></p>
                </td>
                <td colspan="2"></td>
                <td colspan="2">
                    <h3>Ange kod och värde</h3>
                    <p>V = volym, m³</p>
                    <p>F = Flakmeter</p>
                    <p>S = Styck</p>
                    <p>B = Beräknad vikt</p>
                    <p>N = Nettovikt</p>
                </td>
                <td colspan="2">Sändningen mottagen för befordran (ev stämpel)(datum, transportföretag)</td>
                <td class="page-no"><p><strong>1</strong></p></td>
            </tr>
            {/* END Row at the end of the first page*/}
        </>
    );
}

const TuPart = ({ customerRelatedData }) => {
    return (
        <>
            {/* Rows at the end of the second page*/}
            <tr>
                <td></td>
                <td colspan="2">
                    <strong>Viktigt! Synlig skada eller minskning anmäls omedelbart</strong> till transportföretagetoch noteras i blad TU. Godsutlämnaren bestyrker med sin signatur och ev bilnummer.Skada som inte är synlig vid mottagandet anmäls till transportföretaget snarastdock senast inom sju(7) dagar.
                </td>
                <td colspan="4">
                    <div class="container">
                        <div class="item4">
                            <p>Aviserat(namn)</p>
                            <p></p>
                        </div>
                        <div class="item4">
                            <p>Datum</p>
                            <p></p>
                        </div>
                        <div class="item4">
                            <p>Klockan</p>
                            <p></p>
                        </div>
                        <div class="item4">
                            <p>Sign</p>
                            <p></p>
                        </div>
                    </div>
                </td>
                <td></td>
            </tr>

            <tr class="foot-row">
                <td class="side-note">
                    <h2><strong>Tu</strong></h2>
                    <p>Trans-port föret-agets utläm-nings kvitto</p>
                </td>
                <td colspan="3">
                    <div class="container">

                        <div class="item3">
                            <p><strong>Sändningen Kvitteras</strong></p>
                            <p><strong>X</strong></p>
                        </div>

                        <div class="item3">
                            <p>Datum</p>
                            <p><strong>X</strong></p>
                        </div>

                        <div class="item3">
                            <p>Godsmottagarens (ombudets) namnteckning</p>
                            <p><strong>X</strong></p>
                        </div>

                    </div>

                    <p>Godsmottagerens firmanamn (helst stämpel) och namnteckning (med namnförtydligande om så behövs) för den som tagit emot godset.</p>
                </td>
                <td colspan="3">Sändningen mottagen för befordran (ev stämpel, datum, transportföretag)</td>
                <td class="page-no"><p><strong>2</strong></p></td>
            </tr>
            {/* END Row at the end of the second page*/}
        </>
    );
}

const MPart = ({ customerRelatedData }) => {
    return (
        <>
            {/* Rows at the end of the third page*/}
            <tr>
                <td></td>
                <td colspan="2">Transportföretagets kvitto på <strong>Kontant</strong> mottaget belopp inkl ev efterkrav</td>
                <td colspan="4">
                    <div class="container">
                        <div class="item4">
                            <p>Aviserat(namn)</p>
                            <p></p>
                        </div>
                        <div class="item4">
                            <p>Datum</p>
                            <p></p>
                        </div>
                        <div class="item4">
                            <p>Klockan</p>
                            <p></p>
                        </div>
                        <div class="item4">
                            <p>Sign</p>
                            <p></p>
                        </div>
                    </div>
                </td>
                <td></td>
            </tr>

            <tr class="foot-row">
                <td class="side-note">
                    <h2><strong>M</strong></h2>
                    <p><strong>Motta-gar Blad</strong></p>
                </td>
                <td colspan="3">
                    <div class="container">

                        <div class="item3">
                            <p><strong>Sändningen Kvitteras</strong></p>
                            <p><strong>X</strong></p>
                        </div>

                        <div class="item3">
                            <p>Datum</p>
                            <p><strong>X</strong></p>
                        </div>

                        <div class="item3">
                            <p>Godsmottagarens(ombudets) namnteckning</p>
                            <p><strong>X</strong></p>
                        </div>

                    </div>

                    <p><strong>Viktigt! Synlig skada eller minskning anmäls omedelbart</strong> till transportföretaget och noteras i blad TU. Godsutlämnaren bestyrker med sin signatur och ev bilnummer.Skada som inte är synlig vid mottagandet anmäls till transportföretaget snarastdock senast inom sju(7) dagar.
                    </p>
                </td>
                <td colspan="3">Sändningen mottagen för befordran (ev stämpel, datum, transportföretag)</td>
                <td class="page-no"><p><strong>3</strong></p></td>
            </tr>
            {/* END Row at the end of the third page*/}
        </>
    );
}

const APart = ({ data }) => {
    return (
        <>
            {/* Rows at the end of pages*/}
            <tr class="foot-row">
                <td class="side-note">
                    <h2><strong>A</strong></h2>
                    <p><strong>Avsän-darens blad</strong></p>
                </td>
                <td colspan="2">
                    <p>FÖRSÄKRAN OM RÄTT UTRUSTNING OCH UTBILDNING</p>
                    <div>
                        Härmed intygar jag att mitt fordon vid lastning hos DFDS Logistics
                        Contracts AB är korrekt utrustat i enlighet med samtliga regler för
                        transport av farligt goods, att jag har mottagit och kontrollerat
                        att godsdeklaration och transportkort finns med samt att jag innehar
                        giltig ADR utbildning för Chaufförer
            <div class="container">
                            <div class={"item under-dashed"}>
                                <p>Ort</p>
                                <p>Chaufför underskrift</p>
                            </div>
                            <div class={"item under-dashed"}>
                                <p>Datum</p>
                                <p>Bil</p>
                            </div>
                        </div>
                    </div>
                </td>
                <td colspan="2">
                    <h3>Ange kod och värde</h3>
                    <p>V = volym, m³</p>
                    <p>F = Flakmeter</p>
                    <p>S = Styck</p>
                    <p>B = Beräknad vikt</p>
                    <p>N = Nettovikt</p>
                </td>
                <td colspan="2">Sändningen mottagen för befordran (ev stämpel) (datum, transportföretag)</td>
                <td class="page-no"><p><strong>4</strong></p></td>
            </tr>
            {/* END Row at the end of pages*/}
        </>
    );
}

const getEndCustomerList = (shipmentList) => {
    /*
    var endCustomers = [];
    if (!shipmentList || shipmentList.length == 0) {
        return null;
    }

    for (var i = 0; i < shipmentList.length; i++) {
        var endCustomerId = shipmentList[i].endCustomerId;
        var alreadyAdded = endCustomers.some(_endCustomerId => _endCustomerId == endCustomerId);
        if (!alreadyAdded && endCustomerId) {
            endCustomers.push(endCustomerId);
        }
    }

    return endCustomers;
    */
    return shipmentList.reduce((acc, item) => acc.includes(item.endCustomerId) ? acc : acc.concat(item.endCustomerId), []);
}

const getShipmentListByCustomer = (shipmentList, customerId) => {

    var customerShipments = [];
    for (let i = 0; i < shipmentList.length; i++) {
        const eachShipment = shipmentList[i];
        if (eachShipment.endCustomerId == customerId) {
            customerShipments.push(eachShipment);
        }
    }

    return customerShipments;
}

const getGroup = (shipmentList) => {

    if (!shipmentList || shipmentList.length == 0) {
        return null;
    }

    var filteredShipments = shipmentList.filter((eachShipment) => eachShipment.unit && eachShipment.unit.group);
    if (!filteredShipments || filteredShipments.length == 0) {
        return null;
    }

    return filteredShipments[0].unit.group;
}

const getEndCustomer = (shipmentList) => {
    if (!shipmentList || shipmentList.length == 0) {
        return null;
    }

    for (let i = 0; i < shipmentList.length; i++) {
        const eachShipment = shipmentList[i];
        if (eachShipment.endCustomer) {
            return eachShipment.endCustomer;
        }
    }

    return null;
}

const getSuffixOnTop = (shipmentList) => {
    if (!shipmentList || shipmentList.length == 0) {
        return null;
    }

    var suffixOnTop = shipmentList[0].destination && shipmentList[0].destination.name;
    return suffixOnTop;
}

const ConsignmentNoteReport = ({ modalData, toIFrame }) => {

    const tableRef = useRef();

    const [customersData, setCustomersData] = useState([]);
    const [shipmentReferenceNumber, setShipmentReferenceNumber] = useState();

    // Make api call
    useEffect(() => {
        console.log("ConsignmentNoteReport modalData: ", modalData);
        if (modalData) {
            var dataByCustomer = [];
            var endCustomerIdList = getEndCustomerList(modalData.shipmentList);
            console.log("ConsignmentNoteReport endCustomerIdList: ", endCustomerIdList);
            endCustomerIdList.forEach(eachCustomerId => {
                var endCustomershipmentList = getShipmentListByCustomer(modalData.shipmentList, eachCustomerId);
                console.log("ConsignmentNoteReport endCustomershipmentList: ", endCustomershipmentList);

                var group = getGroup(endCustomershipmentList);
                console.log("ConsignmentNoteReport group: ", group);

                var endCustomer = getEndCustomer(endCustomershipmentList)
                console.log("ConsignmentNoteReport endCustomer: ", endCustomer);

                var suffixOnTop = getSuffixOnTop(endCustomershipmentList);
                console.log("ConsignmentNoteReport suffixOnTop: ", suffixOnTop);

                dataByCustomer.push({ endCustomerId: eachCustomerId, group: group, endCustomer: endCustomer, shipmentList: endCustomershipmentList, suffix: suffixOnTop });
            });
            console.log("ConsignmentNoteReport dataByCustomer: ", dataByCustomer)
            setCustomersData(dataByCustomer);
            setShipmentReferenceNumber(dataByCustomer[0] && dataByCustomer[0].shipmentList[0] && dataByCustomer[0].shipmentList[0].referenceNumber);
            // modalData.operation;
            // modalData.nonHazardousShipmentList;
            // modalData.hazardousShipmentList;
        }
    }, [modalData]);

    useEffect(() => {
        toIFrame(tableRef.current.outerHTML);
    }, [modalData, customersData, toIFrame])

    const pageNames = [
        { id: 'Ti', component: TiPart },
        { id: 'Tu', component: TuPart },
        { id: 'M', component: MPart },
        { id: 'A', component: APart },
    ];
    return (
        <div ref={tableRef}>
            {
                customersData && customersData.map((eachCustomer, i) => {
                    return pageNames.map((item, j) => {
                        return (
                            <table class="main" key={j}>
                                <tbody>
                                    <CommonPart customerRelatedData={eachCustomer} allData={modalData} shipmentReferenceNumber={shipmentReferenceNumber}></CommonPart>
                                    <item.component customerRelatedData={eachCustomer} allData={modalData}></item.component>
                                </tbody>
                            </table>
                        );
                    })
                })
            }
        </div>
    );
}

export default ConsignmentNoteReport;