import React from 'react';
import { Card, Button, Col } from 'react-bootstrap';

import classes from './PageHeader.module.css';
import history from '../../core/history';

const PageHeader = ({ text, backButtonEnabled = true, actions, path }) => {
    return (
        <Card px={0}>
            <Card.Header className={`text-center ${classes['xms-page-header']}`}>
                <div className="row mb-0" >
                    <Col xs={3}>
                        {
                            backButtonEnabled ?
                                <div className="float-left">
                                    <Button variant="transparent" onClick={() => path ? history.push(path) : history.goBack()}>
                                        <img src="/image/icon/back.svg" alt="" />
                                    </Button>
                                </div>
                                :
                                null
                        }
                    </Col>
                    <Col xs={6}>
                        <h5>{text}</h5>
                    </Col>
                    <Col xs={3} className="text-right">
                        {actions}
                    </Col>
                </div>

            </Card.Header>
        </Card>
    );
};

export default PageHeader;

