import React, { useContext } from 'react';
import AuthorizeContext from '../../context/AuthorizeContext';
import { Route, Redirect } from 'react-router-dom';
import { isTokenExist, checkForAuthorization } from '../../core/authentication';
import AppContext from "../../context/AppContext";
import { ComponentPolicyList } from '../../context/Policy';

const PolicyRoutingHoc = ({ component: Component, ...rest }) => {
    const appContext = useContext(AppContext);
    const componentName = rest.name;
    const policies = ComponentPolicyList.Policies[componentName];

    if (!isTokenExist()) {
        const afterLogin = rest.location.pathname ? `?afterLogin=${rest.location.pathname}` : '';
        return (
            <Route
                {...rest}
                render={props => <Redirect to={`/login${afterLogin}`} />}
            />
        );
    }

    if (!checkForAuthorization(componentName, appContext, policies, true)) {
        return (
            <Route
                {...rest}
                render={props => <Redirect to={{ pathname: '/noAuthorization', state: { from: props.location } }} />}
            />
        );
    }

    return (
        <Route
            {...rest}
            render={props => (
                <AuthorizeContext.Provider value={policies}>
                    <Component {...props} policies={policies} />
                </AuthorizeContext.Provider>
            )}
        />
    );
};

export default PolicyRoutingHoc;