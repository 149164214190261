import { Col, Form } from "react-bootstrap";
import { AuthorizationWrapper } from "./AuthorizationRenderer";
import * as PropTypes from "prop-types";
import React from "react";
import { DateInputFormComponent } from "../../DateInputFormComponent";

export const DateRenderer = (field, formColumnCount) => {
    const dateControl = (<Form.Group key={field.name} as={Col} md={formColumnCount}>
        <Form.Label>{field.name}{(field.isRequired || field.dynamicRules.isRequired) && ' *'}</Form.Label>
        <DateInputFormComponent
            name={field.id}
            isRequired={field.isRequired || field.dynamicRules.isRequired}
            isTodayCheck={field.isTodayCheck}
            showTimeSelect={field.showTimeSelect}
            validationArray={field.validations}
            isDisabled={field.isDisabled || field.dynamicRules.isDisabled}
            onChange={field.onChange}
        />
    </Form.Group>);

    if (field.authorizationWrapper) {
        return AuthorizationWrapper(dateControl, field.id, field.authorizationWrapper);
    }
    return dateControl;
};

DateRenderer.propTypes = {
    item: PropTypes.object.isRequired
};