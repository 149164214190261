import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    createConnectionPoint,
    readConnectionPoint,
    updateConnectionPoint,
    searchSuffixes
} from '../store/ducks/definitions/actionCreators';
import { InputDatalist } from '../component/InputDatalist/InputDatalist';
import styles from './ConnectionPoint.module.css';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { Minus } from '@dfds-ui/icons/system';
import LoadingIndicator from "../component/LoadingIndicator/LoadingIndicator";

function ConnectionPoint({
    createConnectionPoint,
    readConnectionPoint,
    updateConnectionPoint,
    connectionPoint,
    connectionPoints,
    searchSuffixes,
    suffixes,
}) {
    // HISTORY, LOCATION, PARAMS
    let history = useHistory();
    let { connectionPointId } = useParams();
    // end HISTORY, LOCATION, PARAMS

    // UI LOGIC HELPERS, on requesting initial data, show/hides
    const [isCreateMode, setIsCreateMode] = useState();

    useEffect(() => {
        connectionPointId === 'create' ?
            setIsCreateMode(true) :
            setIsCreateMode(false);
    }, [connectionPointId]);
    // end UI LOGIC HELPERS

    // GET STATE AFTER COMPONENT RENDERS
    useEffect(() => {
        if (isCreateMode === false) {
            readConnectionPoint({ id: connectionPointId }, "processConnectionPoints");
        }
        // TODO: 1
    }, [isCreateMode]);
    // end GET STATE AFTER COMPONENT RENDERS

    // STATE
    const [connectionPointName, setConnectionPointName] = useState('');
    const [connectionPointSuffixes, setConnectionPointSuffixes] = useState([]); // [object]
    const [newSuffixName, setNewSuffixName] = useState('');
    // end STATE

    // SET STATE FROM PROPS
    useEffect(() => {
        // may need a truthy check
        if (connectionPoint) {
            setConnectionPointName(connectionPoint.name);
            setConnectionPointSuffixes(connectionPoint.destinations);
        }
    }, [connectionPoint]); // connection point from selector
    // end SET STATE FROM PROPS

    // if connectionPoint with connectionPointId not even exist reroute to create
    useEffect(() => {
        // may need a truthy check
        // cause back button to need two clicking. instead can use a text to show no such connection point exist with this id at return of render. it can be a toast too and the navigation. or pop the history of this state or just use history.push instead og the history.goBack
        /*
        if (!isCreateMode && connectionPoint === undefined) {
            history.push(`/connection-points/create`);
            // TODO: tell user not exist and can create instead
        }
        */
    }, [isCreateMode, connectionPoint]); // connection point from selector
    // end

    // ADD DELETE SUFFIX
    const onClickAddSuffix = (e) => {
        e.preventDefault();
        const matchedSuffix = suffixes.items.find(item => item.name === newSuffixName);
        if (matchedSuffix) {
            const wasNewSuffixIncluded = connectionPointSuffixes.find(item => item.id === matchedSuffix.id);
            if (!wasNewSuffixIncluded) {
                setConnectionPointSuffixes([...connectionPointSuffixes, matchedSuffix]);
            }
            setNewSuffixName('');
        }

    }

    const onClickDeleteSuffix = (e, id) => {
        e.preventDefault();
        setConnectionPointSuffixes([...connectionPointSuffixes.filter(element => element.id !== id)]);
    }
    // end ADD DELETE SUFFIX

    // SUBMIT
    const onSubmit = async (e) => {
        e.preventDefault();
        if (isCreateMode) {
            const payload = {
                id: connectionPointName,
                name: connectionPointName,
                destinations: connectionPointSuffixes.map(item => item.id)
            };
            await createConnectionPoint(payload, "processConnectionPoints");
            history.replace(`/connection-points/${payload.id}`);
        } else {
            const payload = {
                id: connectionPointId,
                name: connectionPointName,
                destinations: connectionPointSuffixes.map(item => item)
            };
            updateConnectionPoint(payload, "processConnectionPoints");
        }
    }
    // end SUBMIT

    // BACK
    const onClickBack = () => history.push('/connection-points');
    // end BACK

    // VALIDATION
    // end VALIDATION

    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                <Button style={{ fontSize: '14px', marginBottom: '17px' }} className='color-action-blue' variant="transparent" onClick={onClickBack}>
                                    <FontAwesomeIcon icon="chevron-left" size='lg' />
                                    <span style={{ paddingLeft: '10px' }}>
                                        {`Back to Connection Points`}
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>

                <main>
                    <section>
                        <form onSubmit={onSubmit}>
                            <Row>
                                <Col xs="12" sm="6" md="6" lg="4">
                                    <label htmlFor="name" className={styles.label}>Connection Point Name</label><br></br>
                                    <input id="name" value={connectionPointName} onChange={e => setConnectionPointName(e.target.value)} readOnly={!isCreateMode} /><br></br>
                                    <small style={{ visibility: 'hidden' }} className="form-text text-danger">asdf</small>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="9" md="10">
                                    <label htmlFor="suffixes" className={styles.label}>Suffixes</label><br></br>
                                    <div style={{ display: 'flex' }}>
                                        <InputDatalist
                                            inputId="suffixes"
                                            data={suffixes.items}
                                            value={newSuffixName}
                                            onChange={e => setNewSuffixName(e.target.value)}
                                            fetchDataFunction={searchSuffixes}
                                            labelText={''}
                                            visualSize={"small"}
                                            hasIcon={false}
                                            name={'suffix'}
                                            renderDatalistOptions={(item, i) => <option value={item["code"]} key={i} />}
                                            style={{ alignSelf: 'flex-end' }}
                                        /><br></br>
                                        <small style={{ visibility: 'hidden' }} className="form-text text-danger"></small>
                                    </div>
                                </Col>

                                <Col xs="3" md="2" style={{ display: 'flex' }}>
                                    <input type="button" className={styles.secondary} value="Add" onClick={onClickAddSuffix} style={{ alignSelf: 'flex-end' }} />
                                </Col>
                            </Row>

                            <Row style={{ marginBottom: '24px' }}>
                                <Col>
                                    {
                                        connectionPointSuffixes ?
                                            connectionPointSuffixes.map((item, i) => {
                                                return (
                                                    <Row key={i} className={styles.associateRow}>
                                                        <Col xs="9" md="10" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <span>
                                                                {item.code} - {item.name}
                                                            </span>
                                                        </Col>

                                                        <Col xs="3" md="2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <div title="Remove">
                                                                <Minus
                                                                    className={styles.iconButton}
                                                                    onClick={e => onClickDeleteSuffix(e, item.id)}
                                                                ></Minus>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                );
                                            })
                                            :
                                            'This connection point has no suffixes'
                                    }
                                </Col>
                            </Row>

                            <Row>
                                <Col></Col>

                                <Col xs="12" sm="6" md="6" lg="4" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <input type="submit" value={isCreateMode ? 'Create' : 'Update'} />
                                </Col>
                            </Row>
                        </form>

                    </section>

                </main>
            </Card.Body>
            <LoadingIndicator id="processConnectionPoints" show={connectionPoints.isRequesting} />
        </Card>
    );
}

const mapStateToProps = ({ definitions }, ownProps) => {
    return {
        connectionPoints: definitions.connectionPoints,
        connectionPoint: definitions.connectionPoints.items.find(item => item.id === ownProps.match.params.connectionPointId.toLocaleUpperCase()),
        suffixes: definitions.suffixes
    };
}

const mapDispatchToProps = {
    createConnectionPoint,
    readConnectionPoint,
    updateConnectionPoint,
    searchSuffixes
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionPoint);