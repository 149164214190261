import TimePicker from "rc-time-picker";
import React from "react";
import styled from "styled-components";

const StyledTimePicker = styled(TimePicker)`
    height: calc(1.5em + .75rem + 2px)
`;

export const TimeComponent = ({ input, label, type, className, defaultValue, minuteStep, meta: { touched, error, warning }, ...props }) => {
    return <div><div>
        <StyledTimePicker
            disabled={props.disabled}
            inputClassName={className}
            showSecond={false}
            format={'HH:mm'}
            minuteStep={minuteStep}
            value={input.value}
            onChange={input.onChange}
        />

        {touched && ((error && <span className="text-danger">{error}</span>) || (warning &&
            <span className="text-warning">{warning}</span>))}
    </div>
    </div>

};