const reservedSlotActions = Object.freeze({
    RULES_RETRIEVED: Symbol('RULES_RETRIEVED'),
    RULE_RETRIEVED: Symbol('RULE_RETRIEVED'),
    RULE_SAVED: Symbol('RULE_SAVED'),
    RULE_DELETED: Symbol('RULE_DELETED'),
    RULE_FILTER_SET: Symbol('RULE_FILTER_SET'),
    FINALIZE_ADD_OR_UPDATE: Symbol('FINALIZE_ADD_OR_UPDATE'),
    UNIT_TYPES_RETRIEVED: Symbol('UNIT_TYPES_RETRIEVED'),
    CLIENT_BUSINESS_AREAS_RECEIVED: Symbol('CLIENT_BUSINESS_AREAS_RECEIVED'),
    CLIENTS_RETRIEVED: Symbol('CLIENTS_RETRIEVED'),
    SERVICES_RETRIEVED: Symbol('SERVICES_RETRIEVED'),
    BUSINESS_AREA_DETAILS_RECEIVED: Symbol('BUSINESS_AREA_DETAILS_RECEIVED'),
    WARNINGS_RECEIVED: Symbol('WARNINGS_RECEIVED')
});

export default reservedSlotActions;