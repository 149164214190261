import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Checkbox } from '@dfds-ui/react-components';
import classes from '../shipments.module.css';
import { BackgroundText } from '../../BackgroundText/BackgroundText'
import { getValue } from '../../../core/utility';

const ThuRow = ({ thu, onChange, showPart, showQuantity, showWeight, showVolume }) => {

    return (
        <>
            <div className="card">
                <div className={`card-body ${classes["shipments-card-body"]}`}>
                    <Row className="align-items-center">
                        <BackgroundText text={'HOLD'} show={thu.isHeld} type={'danger'} />
                        <Col lg={1} sm={2} xs={3}>
                            <Checkbox disabled={thu.isHeld} className={`${classes["shipments-checkbox"]}`} onChange={(e) => onChange(thu.id, e.target.checked)} checked={thu.isChecked}></Checkbox>
                        </Col>
                        <Col lg={11} sm={10} xs={9}>
                            <Row >
                                <Col xs={12}>
                                    <Row>
                                        <Col>
                                            <span> {`Thu Number:  ${thu.externalId}`}</span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={10} >
                                    <Row>
                                        <Col md={3} sm={6} xs={12} className={showPart ? '' : 'd-none'} title="Part No">{`P: ${thu.externalCode}`}</Col>
                                        <Col md={2} sm={6} xs={12}>
                                            <div className={`${classes["isRush"]} `}>{thu.isRush ? "RUSH" : ""}</div>
                                        </Col>
                                        <Col md={3} sm={6} xs={12} className={showQuantity ? '' : 'd-none'} title="Quantity">{`Q: ${getValue(thu, 'actualMeasurement.itemQuantity.value', 0)}`}</Col>
                                        <Col md={2} sm={6} xs={12} className={showWeight ? '' : 'd-none'} title="Gross Weight">{`W: ${getValue(thu, 'actualMeasurement.grossWeight.value', 0)}`}</Col>
                                        <Col md={2} sm={6} xs={12} className={showVolume ? '' : 'd-none'} title="Volume">{`V: ${getValue(thu, 'actualMeasurement.volume.value', 0)}`}</Col>
                                    </Row>
                                </Col>
                                <Col xs={2}>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </div>
            </div>
        </>
    )

}
export default React.memo(ThuRow);
