import unitTypes from '../type/unit';

const initialState = {
    isRequesting: false,
    fetchedUnit: null,
    fileList: null,
    errors: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case unitTypes.UNIT_FETCHED:
            return {
                ...state,
                fetchedUnit: action.payload
            }
        case unitTypes.SAVE_SHIPMENT_FILE_LIST:
            const newFileList = { ...state.fileList };
            for (const shipment of action.payload) {
                newFileList[shipment.relativePath] = shipment.files
            }
            return {
                ...state,
                fileList: newFileList
            }
        case unitTypes.RESET_UNIT_DATA:
            return {
                ...state,
                ...initialState
            }
        // FILTER
        case unitTypes.FILTER_OPERATIONS_REQUESTING:
            return { ...state, isRequesting: true };
        case unitTypes.FILTER_OPERATIONS_SUCCESS:
            return {
                ...state,
                isRequesting: false,
                fetchedUnit: action.payload,
                errors: []
            };
        case unitTypes.FILTER_OPERATIONS_FAILURE:
            return { ...state, isRequesting: false, errors: action.payload };
        // end FILTER
        case unitTypes.UNIT_LEFT:

            let unit = { ...state.fetchedUnit };
            unit.unitLeft = true;

            return {
                ...state,
                fetchedUnit: unit
            }
        default:
            return state;
    }
}