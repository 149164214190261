import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { PDFReader } from 'reactjs-pdf-reader';
import { downloadBlobData } from '../../store/action/fileControllerAction'
import { Button } from '@dfds-ui/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactToPrint from 'react-to-print';
import { Document, Page, pdfjs } from 'react-pdf';

const ModalShowFile = ({ modalData, onHide }) => {

    useEffect(() => console.log("modalData: ", modalData), [modalData])

    const reportComponentRef = useRef();

    const file = modalData.file;
    const height = window.innerHeight * 2 / 3;

    const hide = () => {
        URL.revokeObjectURL(file.url);
        onHide();
    }

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const renderPages = () => {
        let items = [];
        for (var i = 1; i <= numPages; i++) {
            items.push(<Page key={i} pageNumber={i} width={1080} />)
        }
        return (items)
    }

    return (
        <Modal
            show={true}
            onHide={hide}
            centered
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title>{file.name}</Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ overflow: "auto", height: height }}>

                <div className='print-content' ref={reportComponentRef}>
                    {/* <PDFReader url={file.url} showAllPage="true" width="1080" /> */}
                    <Document
                        file={file.url}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        {renderPages()}
                    </Document>
                </div>

            </Modal.Body>

            <Modal.Footer>

                <ReactToPrint
                    trigger={() => <Button size="small" variation="primary" >Print <FontAwesomeIcon icon="print" /></Button>}
                    content={() => reportComponentRef.current}
                />
                <Button size="small" variation="secondary" disabled={file.status === 'not-uploaded'} onClick={() => downloadBlobData(file, file.name)}>
                    Download <FontAwesomeIcon icon="download" />
                </Button>

            </Modal.Footer>
        </Modal>
    )
}
export default ModalShowFile;