import { object } from 'prop-types';
import { actions } from 'react-table';
import types from '../type/bookingsQueryStringTypes';

const initialState = {    
    query: {
        type: 'booking-list',
        booking:  {
            businessAreas: [],
            code: null,
            searchTerm: "",
            date: null
        }
    }
};

export default (state = initialState, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case types.READQUERYSTRING:
            return {
                ...state,
                query: action.payload
            };
        case types.SETQUERYSTRING:
            return {
                ...state,
                query: action.payload
            };
        case types.DATE_CHANGED:
            newState.query.booking.date = action.payload;
            return newState;
        case types.BUSINESSAREA_CHANGED:
            newState.query.booking.businessAreas = action.payload;
            return newState;
        case types.SEARCHTERM_CHANGED:
            newState.query.booking.searchTerm = action.payload;
            return newState;
        case types.BOOKINGCODE_CHANGED:
            newState.query.booking.code = action.payload;
            return newState;
        case types.RESET_ALL_QUERY:
            return {    
                query: {
                    type: 'booking-list',
                    booking:  {
                        businessAreas: [],
                        code: null,
                        searchTerm: "",
                        date: null
                    }
                }
            };
        default:
            return newState;
    }
};