import { te } from "date-fns/locale";
import _ from 'lodash';

export const isDevelopmentEnvironment = () => !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

export const buildNumber = '323341';

export const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const weekDaysWithEveryday = [
    { value: 'Everyday', text: 'Everyday' },
    { value: 'Monday', text: 'Monday' },
    { value: 'Tuesday', text: 'Tuesday' },
    { value: 'Wednesday', text: 'Wednesday' },
    { value: 'Thursday', text: 'Thursday' },
    { value: 'Friday', text: 'Friday' },
    { value: 'Saturday', text: 'Saturday' },
    { value: 'Sunday', text: 'Sunday' },
]
// FILTER OBJECT
// returns a filtered new object
// filters an object with a filteringFunction(key, value, indexInEntriesArray, entriesArray) that returns Boolean
// Capable of filtering the object with respect to any combination of any algorithms packed in filteringFunction of choice
export function filterObject(object, filteringFunction, initialAccumulator = {}) {
    return Object.entries(object)
        .filter(([key, value], indexInEntriesArray, entriesArray) => filteringFunction(key, value, indexInEntriesArray, entriesArray))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), initialAccumulator);
}
// filteringFunction(key, value, indexInEntriesArray, entriesArray)

// Examples:

// const object = {a: 5, b: 6};

// filter with respect to property key:
// filterObject(object, (key, value) => key !== a);
// returns { b: 6 }

// const desiredKeysArray = ['a'];
// filterObject(object, (key, value) => desiredKeysArray.includes(key) === true);
// returns {a: 5}

// filter wrt (with respect to) value:
// filterObject(object, (key, value) => value < 6);
// returns { a: 5 }

// filterObject({});
// returns { }

// filterObject({ a: 5 }, x => x)

// end FILTER OBJECT


// MAP OBJECT
// returns a new object
// maps an object with a mappingFunction(key, value, indexInEntriesArray, entriesArray) that returns a new object that you want to map to
export function mapObject(object, mappingFunction, initialAccumulator = {}) {
    return Object.entries(object)
        .map(([key, value], indexInEntriesArray, entriesArray) => mappingFunction(key, value, indexInEntriesArray, entriesArray))
        .reduce((acc, o) => ({ ...acc, ...o }), initialAccumulator);
}
// mappingFunction(key, value, indexInEntriesArray, entriesArray)

// Examples:

// mapping values:
// mapObject({a: 2}, (k, v) => ({[k]: v**2}));
// returns { a: 4 }

// mapping keys possible as well:
// mapObject({a: 2}, (k, v) => ({[k.toUpperCase()]: v**2}));
// returns { A: 4 }

// switching keys and values:
// mapObject({a: 2}, (k, v) => ({[v]: k}));
// returns { 2: "a" }

// make all null values empty string values
// mapObject({a: 12, b: null, c: 6}, (key, val) =>  ({[key]: val === null ? "" : val}))
// returns { a: 12, b: "", c: 6 }

// end MAP OBJECT


export function isEmptyObject(object) {
    return Object.entries(object).length === 0 && object.constructor === Object;
}

export function isBooleanString(string, spec = ['true', 'false']) {
    return spec.some(item => item === string);
}

export function isNumberString(string) {
    return Number.parseInt(string).toString() === string || Number.parseFloat(string).toString() === string;
}

export function typeOf(thing) {
    return Array.isArray(thing) ? "array" : typeof (thing);
}

// UTILITIES
const nullp = list => list.length === 0; // returns true if list is empty or false
const first = list => list[0]; // returns the first element of the array, list
const rest = list => list.slice(1); // returns the new list same as array, list, except the first element
const cons = (element, list) => [element, ...list]; // constructs a new list with element, element, added to array, list
// end UTILITIES

// LETTER CASE CONVERTOR
// lower case predicate. checks if the character is lower case
export function lowerCasep(char) {
    if (char.length !== 1) {
        throw new TypeError(`Argument must be a character, i.e. a length 1 String, but it, ${char}, is not a character.`); // TODO: think about that. should we have these meaty try catches at least in algorithmic functions. lowerCasep could be just: return char === char.toLowerCase();
    } else {
        return char === char.toLowerCase();
    }
}

// auxiliary function for capitalCase
function capitalCaseAux(stringArray, index) {
    return nullp(stringArray) ? []
        : index === 0 ? [first(stringArray).toUpperCase(), ...capitalCaseAux(rest(stringArray), index + 1)]
            : lowerCasep(first(stringArray)) ? [first(stringArray), ...capitalCaseAux(rest(stringArray), index + 1)]
                : [" ", first(stringArray), ...capitalCaseAux(rest(stringArray), index + 1)];
}

// make Capital Case string from a camelCase string
export function capitalCase(string) {
    return capitalCaseAux(string.split(""), 0).join("");
}
// end LETTER CASE CONVERTOR

//GET VALUE WITH DEFAULT VALUE
//trying to get value with default value
export function getValue(obj, path, defaultValue = '', separator = '.') {
    var properties = Array.isArray(path) ? path : path.split(separator)
    var value = properties.reduce((prev, curr) => prev && prev[curr], obj);
    var hasValue = value != undefined && value != null ? value.toString().length > 0 : false;
    return hasValue ? value : defaultValue;
}
//end GET VALUE WITH DEFAULT VALUE

//SET VALUE 
//trying to get value with default value
export function setValue(obj, path, value) {
    _.set(obj, path, value);
}
//end SET VALUE 

//QUERY STRING CREATOR
//concatting query strings
export function addQueryString(parameter, list) {
    if (!list || list.length < 1) {
        return '';
    }

    var queryString = '';
    for (let i = 0; i < list.length; i++) {
        queryString += `${parameter}=${list[i]}&`
    }
    return queryString;
}
//end QUERY STRING CREATOR

//BARCODE SCANNER RESULT RULE
//finding best result of barcore scanner
export function getBarcodeResult(text, rules) {
    if (!text)
        return [];

    var array = [text];
    for (let index = 0; index < rules.length; index++) {
        const item = rules[index];

        let value = text.match(new RegExp(item.pattern));
        if (value && value.length > 0) {
            if (value[1]) {
                array = value[1].split(item.splitter);
            } else {
                array = value[0].split(item.splitter);
            }

            array = array.filter(s => s !== '');

            for (let i = 0; i < item.replace.length; i++) {
                array = array.map(s => s.replace(new RegExp(item.replace[i], 'g'), '').trim())
            }

            var y = array[0].match(new RegExp("^[A-Z][a-z0-9]{3,19}$"));

            if (y != null) {
                return array;
            }

        }
    }

    return array;
}
//end BARCODE READER RESULT RULE


// REPLACE ARRAY ELEMENT
export function replaceWithIndex(oldIndex, newIndex, arr) {
    arr = arr.slice();
    let element = arr[oldIndex];
    arr.splice(oldIndex, 1);
    arr.splice(newIndex, 0, element)
    return arr;
}

export function justFirstLetterCapitalize(text) {
    if (!text) {
        return null;
    }

    var splitParts = text.split(" ");
    var resultString = "";
    for (let index = 0; index < splitParts.length; index++) {
        const element = splitParts[index];
        resultString += (element.charAt(0).toUpperCase() + element.slice(1) + " ")
    }
    return resultString;
}
// end REPLACE ARRAY ELEMENT
// TODO: IMPROVE: mapObject, filterObject, and reduceObject recursive with optional depth parameter


//Returns date as 2020-09-20
export function ToShortDateFormat(date) {
    if (!date) {
        date = new Date();
    }

    var datestring = ("0" + date.getDate()).slice(-2) + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + date.getFullYear(); //+ " " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
    return datestring;
}

export function ToShortDateTimeFormat(date) {
    if (!date) {
        date = new Date();
    }

    var datestring = ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
    return datestring;
}

export function generateGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r && 0x3 | 0x8);
        return v.toString(16);
    });
}

/** MEMBER */
function memberAux(item, arr, { key = x => x, test = (item, y) => item === y }) {
    return arr.some(arrElm => test(item, key(arrElm)));
}

export function member(item, arr, rest = {}) {
    return memberAux(item, arr, rest);
}
/*
Examples:
ex1.
member(4, [3, 5, 6]);
=> false

ex.2
member(4, [{a:5, b: 3}, {a: 2, b: 4}], {key: arrElm => arrElm.b})
=> true

ex.3
// does any element has any property with value 4
member(4, [{a:4, b: 3}, {a: 2, b: 4}], {test: (item, arrElm) => item === arrElm.a || item === arrElm.b})
=> true

ex.4
// find if array2 has array1 in it
const array1 = [{Key: 'id', Val: 2}, {Key: 'name', Val: 'something'}];
const array2 = [{Key: 'id', Val: 2}, {Key: 'code', Val: 2}, {Key: 'name', Val: 'something'}];
array1.every(item => {
    return member(item, array2, {test: (item, y) => item.Key === y.Key && item.Val === y.Val})
});
=> true
*/
/** end MEMBER */