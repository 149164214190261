import React from 'react';
import { Card } from 'react-bootstrap';
import { Button } from '@dfds-ui/react-components';
import history from '../../core/history';

let DraftContract = ({ draftContract, companyCode, hidden, company }) => {
    return (
        hidden === false ?
            <Card Card style={{ display: 'block', marginTop: '50px' }
            }>
                <Card.Header>Draft Contract</Card.Header>
                <Card.Body>
                    {draftContract == null ? <div><Card.Text>There is no draft contract.</Card.Text></div>
                        : <div><Card.Text>
                            Contract Reference : {draftContract ? draftContract.contractReference : ''}
                        </Card.Text>
                            <Card.Text>
                                Contact Person : {draftContract ? draftContract.clientContactPerson : ''}
                            </Card.Text> <Button title='Contract Details' variant="primary" onClick={() => { history.push(`/companies/${companyCode}/contract/${draftContract.id}`) }}>Edit</Button></div>}
                </Card.Body>
            </Card >
            : <div></div>
    );
};

export default DraftContract;
