import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ModalShareDocs = ({ modalData, onHide }) => {

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const copy = () => {
        setShowSuccessMessage(true);

        setTimeout(() => {
            onHide();
        }, 2000);
    };

    /*
    function copy() {
        setShowSuccessMessage(true);
    }

    useEffect(() => {
        const id = setTimeout(() => {
            onHide();
        }, 2000);
        return () => clearTimeout(id);
    }, [showSuccessMessage]);
    */

    const renderSuccessMessage = () => {
        if (!showSuccessMessage) { return; }

        return (
            <span className='text-success'>Url copied to clipboard</span>
        )
    };

    return (
        <Modal
            show={true}
            onHide={onHide}
            centered
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>{'Share Unit Documents'}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className='form-group'>
                    <textarea className='form-control' rows='3' disabled defaultValue={modalData}></textarea>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 text-right">

                            {renderSuccessMessage()}

                            <CopyToClipboard text={modalData} onCopy={() => copy()}>
                                <Button variant='primary' className='ml-3' >
                                    <FontAwesomeIcon icon="clipboard" /> Copy to Clipboard
                                    </Button>
                            </CopyToClipboard>

                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )

};

export default ModalShareDocs;