import React from 'react';
const BackOffice = () => {
  window.location.href = process.env.REACT_APP_BACK_OFFICE_URL;
  return (
    <div>
      <p>Redirecting to Back Office ...</p>
    </div>
  );
};

export default BackOffice;
