import React, { useState } from 'react'
import { Table, Form, Col, Row, Button as BootstrapButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@dfds-ui/react-components/button';
import { Search } from '@dfds-ui/icons';
import './XmsTable.css';
import { ButtonStack } from '@dfds-ui/react-components';
import { epochToShortDate } from '../../core/epochToDateConverter';
import InlineConfirm from '../InlineConfirm/InlineConfirm';
import history from '../../core/history';

const generateGUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r && 0x3 | 0x8);
        return v.toString(16);
    });
}

const XmsTable = ({ config }) => {
    const {
        title,
        noRowText = 'There is not any data to show',
        hasFilter,
        showFiltersOnInit = false,
        entities,
        columns,
        tableActions,
        rowActions,
        filterRenderer,
        badgeRenderer,
        renderRowClasses } = config;

    const [showFilters, setShowFilters] = useState(showFiltersOnInit);

    const renderTableActions = tableActions => {
        if (!tableActions) { return null; }
        return (<>
            <ButtonStack align='right'>
                {tableActions.map((tableAction) =>
                    <Button variation='primary'
                        key={generateGUID()}
                        title={tableAction.title}
                        onClick={tableAction.clickEvent}>
                        {tableAction.buttonText} <FontAwesomeIcon icon={tableAction.icon} />
                    </Button>)}
                {hasFilter ? <Button onClick={filterClickedHandler} variation='secondary'> Filter <FontAwesomeIcon icon='filter' /> </Button> : null}
            </ButtonStack>
        </>)
    }

    const renderRowActionButton = (rowAction, entity) => {
        if (rowAction.hasConfirm) {
            return <InlineConfirm key={generateGUID()} confirmText={rowAction.confirmText} icon={rowAction.icon} onConfirm={() => rowAction.clickEvent(entity)} onCancel={null}>
                <Button variation='outlined'
                    key={generateGUID()}
                    size='small'
                    title={rowAction.title}>
                    <FontAwesomeIcon icon={rowAction.icon} />
                </Button>
            </InlineConfirm>
        }
        else {
            return <Button variation='outlined'
                key={generateGUID()}
                size='small'
                title={rowAction.title}
                onClick={(e) => rowAction.clickEvent(entity, e)}>
                <FontAwesomeIcon icon={rowAction.icon} />
            </Button>
        }
    }

    const renderRowActions = (rowActions, entity) => {
        if (!rowActions) { return null; }
        return <td width={rowActions.length * 8 + '%'}>
            <ButtonStack align='center'>
                {rowActions.map((rowAction) => renderRowActionButton(rowAction, entity))}
            </ButtonStack>
        </td>
    }

    const renderPropertyValue = (entity, column) => {
        const value = entity[column.name] ? entity[column.name] : column.defaultValue;
        switch (column.type) {
            case 'string' || 'number':
                return value;
            case 'array':
                return value.join(', ');
            case 'boolean':
                {
                    if (value === true || value === 1 || value === '1') {
                        return <Form.Check type='checkbox' checked disabled />
                    } else {
                        return <Form.Check type='checkbox' disabled />
                    }
                }
            case 'epoch':
                switch (column.format) {
                    case 'shortDate':
                        return value ? epochToShortDate(value) : '';
                    default:
                        break;
                }
                return value;
            case 'custom':
                return column.customRender(entity);
            default:
                return value;
        }
    }

    const renderTitle = () => {
        return <>
            <Row>
                <Col>
                    <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                            <span style={{ fontSize: '1.75rem', paddingLeft: '10px' }}>{title}</span>
                            {badgeRenderer && badgeRenderer()}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                            {renderTableActions(tableActions)}
                        </div>
                    </div>
                </Col>
            </Row>
            {title && <hr />}
            {showFilters && <>
                <div className='d-flex'>
                    {filterRenderer()}
                </div> <hr />
            </>
            }
        </>
    }

    const filterClickedHandler = () => {
        setShowFilters((prevState, props) => (!prevState));
    }

    const getClassesOf = column => {
        if (!column.classNames) {
            if (column.type !== 'number') {
                return null;
            } else {
                column.classNames = ['text-right'];
            }
        }
        return column.classNames ? column.classNames.join(', ') : null;
    }

    const renderColumns = (entity) => {
        if (!columns) { return null; }
        return columns.map((column) =>
            <td key={generateGUID()}
                className={getClassesOf(column)}

                style={column.style}>{renderPropertyValue(entity, column)}
            </td>);
    }

    const renderNoDataCondition = () => {
        return <tr>
            <td colSpan={columns.length + 1}
                className='text-center'>{noRowText}
            </td>
        </tr>
    }

    const renderRows = () => {
        if (!entities || entities.length === 0) {
            return renderNoDataCondition();
        }
        return entities.map((entity) => <tr className={renderRowClasses ? renderRowClasses(entity) : null} key={generateGUID()}>
            {renderColumns(entity)}
            {renderRowActions(rowActions, entity)}
        </tr>)
    }

    return (<>
        {renderTitle()}
        <Row>
            <Col>
                <Table className='xms-table' bordered responsive>
                    <thead>
                        <tr>
                            {columns.map((column, i) => <th className='text-center' key={i}>{column.alias}</th>)}
                            {rowActions ? <th className='text-center background-color-action-blue'>Actions</th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {renderRows()}
                    </tbody>
                </Table>
            </Col>
        </Row>
    </>)
}

export default XmsTable;