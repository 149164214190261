import historyTypes from '../type/historyTypes';
import post, { get } from "../../core/httpClientSync";
import _ from "lodash";
import { toastSuccess } from '.';

// export const filterHistoryList = (historyList, type) => dispatch => {
//     let filtered = historyList;
//     if (type && type !== '') {
//         filtered = historyList.filter(x => x.action === type)
//     }

//     dispatch({
//         type: historyTypes.HISTORY_LIST_FILTERED,
//         payload: filtered
//     });
// }

const apiUrl = 'xms-historytracking-api/api/historytracking';
//const apiUrl = 'http://localhost:5000/api/historytracking';

export const getObjectTypes = () => async dispatch => {
    const endPoint = `${apiUrl}/get-object-types`;
    const response = await get(endPoint, 'histories');
    if (response.isSuccess) {
        const types = response.response.result.map(type => { return { value: type, label: type } })
        types.unshift({ value: '', label: 'SelectType' });

        dispatch({
            type: historyTypes.ENTITY_TYPES_RECEIVED,
            payload: types
        });
    }

}

export const getHistoryList = (id, objectType) => async dispatch => {
    const endPoint = `${apiUrl}/get-history-diffs?id=${id}&recordObjectType=${objectType}`;
    const response = await get(endPoint, 'histories');
    if (response.isSuccess) {
        dispatch({
            type: historyTypes.HISTORY_LIST_RECEIVED,
            payload: response.response.result
        });
    }
};

export const clearHistoryList = () => async dispatch => {
    
        dispatch({
            type: historyTypes.HISTORY_LIST_CLEARED
        });
};

export const getJsonModel = () => async dispatch => {
    const endPoint = `${apiUrl}/ui-definition/get`;
    const response = await get(endPoint, 'editHistoryDefinitions');
    if (response.isSuccess) {
        dispatch({
            type: historyTypes.UI_MODEL_RECEIVED,
            payload: response.response.result
        });
    }
}

export const saveHistoryJsonModel = (model) => async dispatch => {
    const endPoint = `${apiUrl}/ui-definition/save`;
    const response = await post(endPoint, { model: model }, 'editHistoryDefinitions');
    response.isSuccess && toastSuccess('Model saved.');
}