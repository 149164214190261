/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import './FileControllerOperationButtons.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonStack } from '@dfds-ui/react-components';
import { AlertWarning, YesTick } from '@dfds-ui/icons';
import { Col } from "react-bootstrap";
import PolicyGroupAccessHoc from "../../RoutedComponents/Routes/PolicyGroupAccessHoc";

const FileControllerOperationButtons = (props) => {

    let inputFileElement;

    // Input objects from parent component
    const {
        operationButtonsToShow,
        onFilesSelected,
        onDownloadAllDocuments,
        fileList,
        splitUrl,
        onUploadDocuments,
        onShareDocuments,
        missingDocumentOptions,
        onToggleDocsMissingState,
        docsMissingHoldOptions
    } = props;

    const filesSelected = (e) => {
        onFilesSelected(e.target.files)
        e.target.values = '';
        e.target.value = '';
    }

    const renderAddFileIcon = () => {
        if (!operationButtonsToShow.addFile) { return; }
        return (
            <>
                <input
                    ref={input => inputFileElement = input}
                    type='file'
                    className='d-none'
                    onChange={(e) => filesSelected(e)}
                    multiple
                />
                <a onClick={() => inputFileElement.click()}>
                    <FontAwesomeIcon title="Add Document" icon="plus" size="2x" className="mx-3" />
                </a>
            </>
        )
    }

    const renderSplitIcon = () => {
        if (!operationButtonsToShow.split) { return; }

        return (
            <Link to={splitUrl}>
                <img
                    className='fileControllerOperationButtonsIcon mx-3'
                    src='/image/file-controller/scanner.png'
                    alt=''
                    title="Add Multiple Shipment Documents"
                />
            </Link>
        )
    }

    const renderUploadIcon = () => {
        if (!operationButtonsToShow.upload) { return; }

        const notUploadedFileList = fileList ? Object.values(fileList).find(a => a.status === 'not-uploaded') : null;
        if (notUploadedFileList) {
            return (
                <a onClick={onUploadDocuments}>
                    <FontAwesomeIcon title="Upload Documents" icon="upload" size="2x" className="mx-3 color-yes-green" />
                </a>
            )
        }

        return (
            <a>
                <FontAwesomeIcon title="Upload Documents" icon="upload" size="2x" className="mx-3" />
            </a>
        )
    }

    const renderDownloadIcon = () => {
        if (!operationButtonsToShow.download) { return; }

        return (
            <a onClick={onDownloadAllDocuments}>
                <FontAwesomeIcon title="Download Documents" icon="download" size="2x" className="mx-3" />
            </a>
        )
    }

    const renderShareIcon = () => {
        if (!operationButtonsToShow.shareDocuments) { return; }

        return (
            <a onClick={onShareDocuments}>
                <FontAwesomeIcon title="Share Documents" icon="share-alt" size="2x" className="mx-3" />
            </a>
        )
    }

    const renderChangeDocsMissingState = () => {

        if (!operationButtonsToShow.missingDocuments) { return; }

        if (missingDocumentOptions.hasDocsMissingFlagSet) {
            return (
                <Button
                    onClick={() => onToggleDocsMissingState()}
                    className="mr-2"
                    size="small"
                    icon={<AlertWarning />}
                    style={{ backgroundColor: "#be1e2d" }}
                    iconAlign="left">
                    Docs. Missing
                </Button>
            )
        } else {
            return (
                <Button
                    onClick={() => onToggleDocsMissingState()}
                    className="mr-2"
                    size="small"
                    icon={<YesTick />}
                    iconAlign="left">
                    Set Docs. Missing
                </Button>
            )
        }

    }

    const renderDocsMissingHoldState = () => {
        if (!operationButtonsToShow.missingDocumentsHold) {
            return;
        }

        if (docsMissingHoldOptions.autoHoldForDocsMissing) {
            return (
                <Button
                    onClick={() => docsMissingHoldOptions.onToggleDocsMissingHoldState()}
                    className="mr-2"
                    size="small"
                    icon={<AlertWarning />}
                    style={{ backgroundColor: "#be1e2d" }}
                    iconAlign="left">
                    Article(s) Held
                </Button>
            );
        }

        return (
            <Button
                onClick={() => docsMissingHoldOptions.onToggleDocsMissingHoldState()}
                className="mr-2"
                variation="secondary"
                size="small"
                icon={<YesTick />}
                iconAlign="left">
                Set Article(s) Hold
            </Button>
        );
    }

    return (
        <>
            <Col xs={4}>
                <PolicyGroupAccessHoc componentName="FileControllerOperationButtonsHOC">
                    {renderAddFileIcon()}
                    {renderSplitIcon()}
                    {renderUploadIcon()}
                </PolicyGroupAccessHoc>
                {renderDownloadIcon()}
                {renderShareIcon()}
            </Col>
            <Col xs={8}>
                <PolicyGroupAccessHoc componentName="FileControllerOperationButtonsHOC">
                    <ButtonStack align="right">
                        {renderChangeDocsMissingState()}
                        {renderDocsMissingHoldState()}
                    </ButtonStack>
                </PolicyGroupAccessHoc>
            </Col>
        </>
    )
}

export default FileControllerOperationButtons;