import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    createContainerBooking,
    readContainerBooking,
    updateContainerBooking,
    deleteContainerBookings,
} from '../store/ducks/containerBookings/actionCreators';

import styles from './ContainerBooking.module.css';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { filterVoyages } from '../store/ducks/voyages/actionCreators';
import { getOperationById } from '../store/action/operationActions';
import { localDateTimeToEpochSecond, epochSecondToLocalDateTime } from '../core/utcToEpochConverter.js';
import { useURLSearchParams } from '../core/hooks';
import DatePicker from "react-datepicker";
import LoadingIndicator from "../component/LoadingIndicator/LoadingIndicator";
import { ButtonStack, Button as DfdsButton } from '@dfds-ui/react-components';
import PolicyGroupAccessHoc from "../RoutedComponents/Routes/PolicyGroupAccessHoc";

function ContainerBooking({
    createContainerBooking,
    readContainerBooking,
    updateContainerBooking,
    deleteContainerBookings,
    containerBookings,
    voyages,
    filterVoyages,
    operations,
    getOperationById
}) {
    // HISTORY, LOCATION, SEARCH PARAMS, URL PARAMS
    let history = useHistory();
    let { mode } = useParams();
    let searchParams = useURLSearchParams();
    const [ids, setIds] = useState([]);
    useEffect(() => {
        if (searchParams) {
            setIds(searchParams.getAll("ids"));
        }
    }, [searchParams]);
    // end HISTORY, LOCATION, SEARCH PARAMS, URL PARAMS

    // STATE
    const [containerBookingsLocal, setContainerBookingsLocal] = useState({ items: [{}] });
    // end STATE

    // GET STATE AFTER COMPONENT RENDERS
    useEffect(() => {
        // we do not use currently a batch mode for this view, so skip that case and read one item from endpoint
        if (ids.length === 1) {
            readContainerBooking({ id: ids[0] }, 'processContainerBooking');
        }
    }, [ids, readContainerBooking]);
    // end GET STATE AFTER COMPONENT RENDERS

    // SET STATE FROM PROPS
    useEffect(() => {
        if (containerBookings && mode === 'container-booking') {
            // mode: mode 'container-booking' means mode for read and update
            const containerBooking = containerBookings.items.find(item => item.id === ids[0]);
            containerBooking && setContainerBookingsLocal(state => ({ ...state, isRequesting: containerBookings.isRequesting, items: [containerBooking] }));
        } else if (containerBookings && mode === 'update-many') {
            const containerBookingsChecked = containerBookings.items.filter(item => ids.includes(item.id));
            setContainerBookingsLocal(state => ({ ...state, isRequesting: containerBookings.isRequesting, items: containerBookingsChecked }));
        }
    }, [containerBookings.items, ids, mode]);
    // end SET STATE FROM PROPS

    // ON CHANGE
    const onChange = (e) => {
        e.persist();
        if (e.target.type === "datetime-local") {
            setContainerBookingsLocal(state => ({
                ...state,
                items: state.items.map(item => ({ ...item, [e.target.id]: localDateTimeToEpochSecond(new Date(e.target.value)) })),
            }));
        } else {
            setContainerBookingsLocal(state => ({
                ...state,
                items: state.items.map(item => ({ ...item, [e.target.id]: e.target.value })),
            }));
        }
    }

    // Set voyageId
    // when voyage external number changes we need to set a voyageId in local state
    const [voyageExternalNumberAux, setVoyageExternalNumberAux] = useState();

    useEffect(() => {
        if (containerBookingsLocal) {
            setVoyageExternalNumberAux(containerBookingsLocal.items[0].voyageExternalNumber);
        }
    }, [containerBookingsLocal]);

    // find voyage for voyage external number to use in setting voyageId
    useEffect(() => {
        if (voyageExternalNumberAux && voyageExternalNumberAux.length > 2) {
            filterVoyages({ externalNumber: voyageExternalNumberAux });
        }
    }, [voyageExternalNumberAux, filterVoyages]);

    // set voyage id separately, in parallel and other than voyage external number
    useEffect(() => {
        const voyage = voyages.items.find(x => x.externalNumber === voyageExternalNumberAux);
        if (voyage) {
            setContainerBookingsLocal(state => ({
                ...state,
                items: state.items.map(item => ({ ...item, "voyageId": voyage.id })),
            }));
        }
    }, [voyageExternalNumberAux, voyages]);
    // END Set voyageId

    const onChangeDatepicker = (key, epochSeconds, event) => {
        setContainerBookingsLocal(state => ({ ...state, items: state.items.map(item => ({ ...item, [key]: epochSeconds })) }));
    }
    // end ON CHANGE

    // VALUE
    // returns value of the prop if all the items, containerBookings, has the same value for a given prop, else it returns another value
    const valueSelector = (propName, variousValuesDecorator = '', undefinedValueDecorator = '') => {
        return hasVariousValues(propName) ?
            variousValuesDecorator :
            (containerBookingsLocal && containerBookingsLocal.items && containerBookingsLocal.items.length > 0 && containerBookingsLocal.items[0][propName] || undefinedValueDecorator); // undefined value for react complains about undefined and null on controlled input element values
    }

    const hasVariousValues = (propName) => {
        return containerBookingsLocal && containerBookingsLocal.items && !containerBookingsLocal.items.every((item, i, arr) => item[propName] === arr[0][propName]);
    }
    // end VALUE

    // ADD & REMOVE OPERATION
    const [newOperationId, setNewOperationId] = useState('');

    const onChangeNewOperationId = (e) => {
        e.persist();
        setNewOperationId(e.target.value);
    }

    useEffect(() => {
        if (newOperationId && newOperationId.length > 2) {
            getOperationById(newOperationId);
        }
    }, [newOperationId, getOperationById]);

    const onClickAddOperation = (e) => {
        e.persist();
        e.preventDefault();
        const newOperation = operations.items.find(item => item.id === newOperationId);
        newOperation && setContainerBookingsLocal(state => ({ ...state, items: [{ ...state.items[0], operationInfos: (state.items[0] && state.items[0].operationInfos) ? state.items[0].operationInfos.concat(newOperation) : [newOperation] }] }));
    }
    useEffect(() => {
        return () => setNewOperationId('');
    }, [containerBookingsLocal])

    const onClickRemoveOperation = (e, id) => {
        e.preventDefault();
        const ops = containerBookingsLocal.items[0].operationInfos;
        const newOps = ops.filter(op => op.id !== id);
        setContainerBookingsLocal(state => ({ ...state, items: [{ ...state.items[0], operationInfos: newOps }] }));
    }
    // end ADD & REMOVE OPERATION

    // FORMAT
    // end FORMAT

    // SUBMIT
    const onSubmit = async (e) => {
        e.preventDefault();
        if (ids.length === 1) {
            // Update
            // since this view we do not apply batch update, our endpoint support single item
            updateContainerBooking({ booking: containerBookingsLocal.items[0] }, 'processContainerBooking');
        } else {
            // Create
            await createContainerBooking({ booking: containerBookingsLocal.items[0] }, 'processContainerBooking');
            history.replace('/container-bookings');
        }
    }
    // end SUBMIT

    // DELETE
    const onClickDelete = async () => {
        if (window.confirm(`Are you sure you want to delete ${ids.length > 1 ? `these ${ids.length} items` : 'this item'}?`)) {
            await deleteContainerBookings({ bookingIds: ids }, 'processContainerBooking'); // can navigate without waiting the response as well, in that case do not use "await"
            history.replace(`/container-bookings`); // since the item is deleted and will return not found we may replace the navigation from the history, otherwise history.push() or history.goBack() can be used as well
        }
    }
    // end DELETE

    // BACK
    const onClickBack = () => history.push(`/container-bookings`);
    // end BACK

    const mouseDownHandler = (event, id) => {
        if (event.button === 1) {
            window.open(`/operation/${id}`, '_blank');
        }
    }

    const redirectToOperation = (id) => {
        const path = `/operation/${id}`;
        history.push(path);
    }

    // VALIDATION
    // end VALIDATION
    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                <Button style={{ fontSize: '14px', marginBottom: '17px' }} className='color-action-blue' variant="transparent" onClick={() => history.push(`/container-bookings`)}>
                                    <FontAwesomeIcon icon="chevron-left" size='lg' />
                                    <span style={{ paddingLeft: '10px' }}>
                                        {`Back to Container Bookings`}
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <main>
                            <section>
                                <form onSubmit={onSubmit}>
                                    <Row>
                                        <Col xs="12" sm="6" md="6" lg="4">
                                            <label htmlFor="voyageExternalNumber" className={styles.label}>Voyage External Number</label><br></br>
                                            <input id="voyageExternalNumber" list="voyageIds" value={valueSelector('voyageExternalNumber')} onChange={onChange} autoComplete="off" placeholder="Min 3 characters" />
                                            <datalist id="voyageIds">
                                                {
                                                    voyages.items.map((item) => <option key={item.id} value={item.externalNumber} />)
                                                }
                                            </datalist>
                                            <br></br>
                                            <small style={{ visibility: hasVariousValues('voyageExternalNumber') ? 'visible' : 'hidden' }} className="form-text text-warning">Various values</small>
                                        </Col>

                                        <Col xs="12" sm="6" md="6" lg="4">
                                            <label htmlFor="bookingReference" className={styles.label}>Booking Reference *</label><br></br>
                                            <input id="bookingReference" required value={valueSelector('bookingReference')} onChange={onChange} /><br></br>
                                            <small style={{ visibility: hasVariousValues('bookingReference') ? 'visible' : 'hidden' }} className="form-text text-warning">Various values</small>
                                        </Col>

                                        <Col xs="12" sm="6" md="6" lg="4">
                                            <label htmlFor="carrierBookingReference" className={styles.label}>Carrier Booking Reference *</label><br></br>
                                            <input id="carrierBookingReference" required value={valueSelector("carrierBookingReference")} onChange={onChange} /><br></br>
                                            <small style={{ visibility: hasVariousValues('carrierBookingReference') ? 'visible' : 'hidden' }} className="form-text text-warning">Various values</small>
                                        </Col>

                                        <Col xs="12" sm="6" md="6" lg="4">
                                            <label htmlFor="customerReference" className={styles.label}>Customer Reference *</label><br></br>
                                            <input id="customerReference" required value={valueSelector('customerReference')} onChange={onChange} /><br></br>
                                            <small style={{ visibility: hasVariousValues('customerReference') ? 'visible' : 'hidden' }} className="form-text text-warning">Various values</small>
                                        </Col>


                                        <Col xs="12" sm="6" md="6" lg="4">
                                            <label htmlFor="containerClosingDate" className={styles.label}>Container Closing Date & Time *</label><br></br>
                                            <DatePicker
                                                id="containerClosingDate"
                                                selected={valueSelector('containerClosingDate') ? epochSecondToLocalDateTime(valueSelector('containerClosingDate')) : ''}
                                                onChange={(dateTime, event) => onChangeDatepicker('containerClosingDate', localDateTimeToEpochSecond(dateTime), event)}
                                                dateFormat="MM/dd/yyyy HH:mm"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeCaption="Time"
                                                timeIntervals={1}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                todayButton="Today"
                                                placeholderText='Select Date & Time'
                                                type='text'
                                                dropdownMode="select"
                                                required
                                            />
                                            <small style={{ visibility: hasVariousValues('containerClosingDate') ? 'visible' : 'hidden' }} className="form-text text-warning">Various values</small>
                                        </Col>

                                        <Col xs="12" sm="6" md="6" lg="4">
                                            <label htmlFor="vgmCutoffDate" className={styles.label}>VGM Cutoff Date & Time</label><br></br>
                                            <DatePicker
                                                id="vgmCutoffDate"
                                                selected={valueSelector('vgmCutoffDate') ? epochSecondToLocalDateTime(valueSelector('vgmCutoffDate')) : ''}
                                                onChange={(dateTime, event) => onChangeDatepicker('vgmCutoffDate', localDateTimeToEpochSecond(dateTime), event)}
                                                dateFormat="MM/dd/yyyy HH:mm"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeCaption="Time"
                                                timeIntervals={1}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                todayButton="Today"
                                                placeholderText='Select Date & Time'
                                                type='text'
                                                dropdownMode="select"
                                            />
                                            <small style={{ visibility: hasVariousValues('vgmCutoffDate') ? 'visible' : 'hidden' }} className="form-text text-warning">Various values</small>
                                        </Col>

                                        <Col xs="12" sm="6" md="6" lg="4">
                                            <label htmlFor="emptyReleaseDate" className={styles.label}>Empty Release Date & Time</label><br></br>
                                            <DatePicker
                                                id="emptyReleaseDate"
                                                selected={valueSelector('emptyReleaseDate') ? epochSecondToLocalDateTime(valueSelector('emptyReleaseDate')) : ''}
                                                onChange={(dateTime, event) => onChangeDatepicker('emptyReleaseDate', localDateTimeToEpochSecond(dateTime), event)}
                                                dateFormat="MM/dd/yyyy HH:mm"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeCaption="Time"
                                                timeIntervals={1}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                todayButton="Today"
                                                placeholderText='Select Date & Time'
                                                type='text'
                                                dropdownMode="select"
                                            />
                                            <small style={{ visibility: hasVariousValues('emptyReleaseDate') ? 'visible' : 'hidden' }} className="form-text text-warning">Various values</small>
                                        </Col>

                                        <Col xs="12" sm="6" md="6" lg="4">
                                            <label htmlFor="loadedReturnDate" className={styles.label}>Loaded Return Date & Time</label><br></br>
                                            <DatePicker
                                                id="loadedReturnDate"
                                                selected={valueSelector('loadedReturnDate') ? epochSecondToLocalDateTime(valueSelector('loadedReturnDate')) : ''}
                                                onChange={(dateTime, event) => onChangeDatepicker('loadedReturnDate', localDateTimeToEpochSecond(dateTime), event)}
                                                dateFormat="MM/dd/yyyy HH:mm"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeCaption="Time"
                                                timeIntervals={1}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                todayButton="Today"
                                                placeholderText='Select Date & Time'
                                                type='text'
                                                dropdownMode="select"
                                            />
                                            <small style={{ visibility: hasVariousValues('loadedReturnDate') ? 'visible' : 'hidden' }} className="form-text text-warning">Various values</small>
                                        </Col>

                                        <Col xs="12" sm="6" md="6" lg="4">
                                            <label htmlFor="loadingType" className={styles.label}>Loading Type</label><br></br>
                                            <select id="loadingType" value={valueSelector('loadingType')} onChange={onChange} style={{ padding: 6 }}>
                                                <option key={""} value={""}>Please select one</option>
                                                <option key={"FCL"} value={"FCL"}>FCL</option>
                                                <option key={"LCL"} value={"LCL"}>LCL</option>
                                            </select>
                                            <small style={{ visibility: hasVariousValues('loadingType') ? 'visible' : 'hidden' }} className="form-text text-warning">Various values</small>
                                        </Col>

                                        <Col xs="12" sm="6" md="6" lg="4">
                                            <label htmlFor="containerPickupAddress" className={styles.label}>Container Pickup Address</label><br></br>
                                            <input id="containerPickupAddress" value={valueSelector('containerPickupAddress')} onChange={onChange} /><br></br>
                                            <small style={{ visibility: hasVariousValues('containerPickupAddress') ? 'visible' : 'hidden' }} className="form-text text-warning">Various values</small>
                                        </Col>

                                        <Col xs="12" sm="6" md="6" lg="4">
                                            <label htmlFor="containerDeliveryAddress" className={styles.label}>Container Delivery Address</label><br></br>
                                            <input id="containerDeliveryAddress" value={valueSelector('containerDeliveryAddress')} onChange={onChange} /><br></br>
                                            <small style={{ visibility: hasVariousValues('containerDeliveryAddress') ? 'visible' : 'hidden' }} className="form-text text-warning">Various values</small>
                                        </Col>

                                    </Row>

                                    <Row>
                                        <Col xs="9" md="10">
                                            <label htmlFor="newOperationId" className={styles.label}>Operations</label><br></br>
                                            <div style={{ display: 'flex' }}>
                                                <input id="newOperationId" list="operationIds" value={newOperationId} onChange={onChangeNewOperationId} autoComplete="off" placeholder="Enter operation key to search" style={{ alignSelf: 'flex-end' }} />
                                                <datalist id="operationIds">
                                                    {
                                                        operations.items.map((item, i) => <option key={i} value={item.id} />)
                                                    }
                                                </datalist><br></br>
                                                <small style={{ visibility: 'hidden' }} className="form-text text-danger"></small>
                                            </div>
                                        </Col>

                                        <Col xs="3" md="2" style={{ display: 'flex' }}>
                                            <PolicyGroupAccessHoc componentName="AddOperationContainerBookingButtonHOC">
                                                <input type="button" className={styles.secondary} value="Add" onClick={onClickAddOperation} style={{ alignSelf: 'flex-end' }} />
                                            </PolicyGroupAccessHoc>
                                        </Col>
                                    </Row>

                                    <Row style={{ marginBottom: '24px', marginTop: '10px' }}>
                                        <Col>
                                            {
                                                containerBookingsLocal && containerBookingsLocal.items[0].operationInfos ?
                                                    containerBookingsLocal.items[0].operationInfos.map((item, i) => {
                                                        return (
                                                            <Row key={i} className={styles.associateRow}>
                                                                <Col xs="6" md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <span>
                                                                        {item.id} - {item.unitCode}
                                                                    </span>
                                                                </Col>
                                                                <Col xs="3" md="4">
                                                                    <ButtonStack align="right">
                                                                        <DfdsButton variation="outlined" size="small" onMouseDown={(e) => mouseDownHandler(e, item.id)} onClick={() => redirectToOperation(item.id)}>Go Operation</DfdsButton>
                                                                        <PolicyGroupAccessHoc componentName="OperationContainerBookingButtonHOC">
                                                                            <DfdsButton variation="primary" size="small" onClick={(e) => onClickRemoveOperation(e, item.id)}>Remove</DfdsButton>
                                                                        </PolicyGroupAccessHoc>
                                                                    </ButtonStack>
                                                                </Col>
                                                                <Col xs="3" md="2" >
                                                                </Col>
                                                            </Row>
                                                        );
                                                    })
                                                    :
                                                    'There are no items to show'
                                            }
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col></Col>

                                        <Col xs="12" sm="6" md="6" lg="4" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <PolicyGroupAccessHoc componentName="SaveContainerBookingButtonHOC">
                                                <input type="submit" value={ids.length > 0 ? 'Update' : 'Create'} />
                                            </PolicyGroupAccessHoc>
                                        </Col>
                                    </Row>
                                </form>

                            </section>

                        </main>
                    </Col>
                </Row>
            </Card.Body>
            <LoadingIndicator id='processContainerBooking' show={containerBookings.isRequesting} />
        </Card>
    );
}

const mapStateToProps = ({ containerBookings, voyages, operationReducer }) => {
    return {
        containerBookings,
        voyages,
        operations: { items: operationReducer.operation ? [operationReducer.operation] : [] },
    };
}

const mapDispatchToProps = {
    createContainerBooking,
    readContainerBooking,
    updateContainerBooking,
    deleteContainerBookings,
    filterVoyages,
    getOperationById,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContainerBooking);