import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card } from 'react-bootstrap';
import { Button, ButtonStack, Checkbox } from '@dfds-ui/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Messages.module.css';
import {
    clearState,
    getOperations,
    triggerMessage,
    triggerMessages,
    getMessage
} from '../store/action/messagesActions';
import { fetchTypes } from '../store/ducks/definitions/actionCreators';
import { toast } from 'react-toastify';

import PageHeader from '../component/PageHeader/PageHeader';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';

const Messages = (props) => {

    //Inputs from redux
    const {
        operationList,
        isMessagesTriggered,
        isMessageTriggered,
        messageTypes
    } = props;

    //actions
    const {
        clearState,
        getOperations,
        fetchTypes,
        triggerMessage,
        triggerMessages,
        getMessage
    } = props;

    const CONSTANTS = {
        FORMLOADER: 'loader-form',
        ITEMLOADER: 'loader-task',
        LISTLOADER: 'loader-list',
    }

    const [taskIds, setTaskIds] = useState([]);
    const [selectedTaskIds, setSelectedTaskIds] = useState([]);
    const [messageType, setMessageType] = useState();

    useEffect(() => {
        fetchTypes();
        return () => {
            clearState();
        }
    }, [])

    useEffect(() => {
        if (isMessagesTriggered) {
            toast.info('Messages Triggered')
        }
    }, [isMessagesTriggered])

    useEffect(() => {
        if (isMessageTriggered) {
            toast.info('Message Triggered')
        }
    }, [isMessageTriggered])

    const onTextChanged = (value) => {
        if (value && value.length > 1) {
            var list = value.replace('"', '').split(/,| |;|\n|\r\n/).filter(s => s !== '');
            setTaskIds(list);
        }
        else {
            setTaskIds([]);
        }
    }

    const onTypeChange = (value) => {
        setMessageType(value);
    }

    const onSubmit = () => {
        if (!taskIds || (taskIds && taskIds.length === 0)) {
            toast.error('Please Enter TaskId(s)');
            return;
        }
        setSelectedTaskIds([])
        getOperations(taskIds, CONSTANTS.FORMLOADER);
    }

    const onTriggerClick = (operation) => {
        if (!messageType) {
            toast.error('Please Select Message Type');
            return;
        }
        var type = messageType.split('-')[0];
        var direction = messageType.split('-')[1];
        var id = operation.id;

        triggerMessage(id, type, direction, `${CONSTANTS.ITEMLOADER}-${operation.id}`)
    }

    const onSelectedTriggerClick = () => {
        if (selectedTaskIds && selectedTaskIds.length == 0) {
            toast.error('Please Select Task(s)');
            return;
        }

        if (!messageType) {
            toast.error('Please Select Message Type');
            return;
        }

        var type = messageType.split('-')[0];
        var direction = messageType.split('-')[1];

        triggerMessages(selectedTaskIds, type, direction, CONSTANTS.LISTLOADER);
    }

    const onDetailClick = (operation) => {
        if (!messageType) {
            toast.error('Please Select Message Type');
            return;
        }
        var type = messageType.split('-')[0];

        getMessage(operation, type, `${CONSTANTS.ITEMLOADER}-${operation.id}`);
    }

    const onCheckboxChecked = (id, checked) => {
        var list = [...selectedTaskIds];
        if (checked) {
            list.push(id);
        }
        else {
            list = list.filter(s => s !== id);
        }
        setSelectedTaskIds(list);
    }

    const onAllCheckboxChanged = (checked) => {
        if (checked) {
            setSelectedTaskIds(operationList.map((item) => item.id));
        }
        else {
            setSelectedTaskIds([])
        }
    }

    const renderPageHeader = () => {
        return <PageHeader text={'Messages'} />
    }

    const renderCol = (text, shortText, value) => {
        return (
            <Col lg={3} md={4} sm={6} xs={12} className="mb-1">
                <Row className="d-none d-lg-block">
                    <Col>
                        <span className="text-title">{text}</span>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-truncate">
                        <span className="d-lg-none text-title">{shortText}: </span>
                        <span className="color-text-grey">{value}</span>
                    </Col>
                </Row>
            </Col>
        )
    }

    const renderFilter = () => {
        return (
            <Card>
                <Card.Body>
                    <Row>
                        <Col>
                            <Row>
                                <Col xs="12" sm="6">
                                    <label className={styles.label}>Task(s)</label><br></br>
                                    <textarea className={'input-block'} rows={5} onChange={(e) => onTextChanged(e.target.value)} autoFocus />
                                </Col>
                                <Col xs="12" sm="6" className={styles.fixEnd}>
                                    <ButtonStack>
                                        <Button variation='primary' className={'input-block'} onClick={onSubmit}>Search <FontAwesomeIcon icon="search" /></Button>
                                    </ButtonStack>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <LoadingIndicator id={CONSTANTS.FORMLOADER} />
                </Card.Body>
            </Card>
        )
    }

    const renderList = () => {
        if (operationList && operationList.length === 0) {
            return;
        }

        return (
            <>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col>
                                <label className={styles.label}>Message Type</label><br></br>
                                <select onChange={(e) => onTypeChange(e.target.value)}>
                                    <option key={''} value={''}>Select Message Type</option>
                                    {
                                        messageTypes.map((item, i) => <option key={i} value={item.name}>{item.displayName}</option>)
                                    }
                                </select>
                            </Col>
                            <Col className={styles.fixEnd}>
                                <ButtonStack>
                                    <Button variation="primary" className={'input-block'} size="small" onClick={() => onSelectedTriggerClick()}>Trigger ({selectedTaskIds.length})</Button>
                                </ButtonStack>
                            </Col>
                        </Row>

                    </Card.Header>
                    <Card.Body>
                        <Row className={`align-items-center`}>
                            <Col xs={12} md={3} lg={2}>
                                <label className={styles.label}>Select All</label><br></br>
                                <input style={{ marginLeft: '21px' }} type='checkbox' onChange={(e) => onAllCheckboxChanged(e.target.checked)} />
                            </Col>
                        </Row>
                        {
                            operationList.map((operation, i) => (
                                <Card key={i} className={'mb-1'}>
                                    <Card.Body>
                                        <Row className={`align-items-center`}>
                                            <Col xs={12} md={1} lg={1}>
                                                <input type='checkbox' onChange={(e) => onCheckboxChecked(operation.id, e.target.checked)} checked={selectedTaskIds.includes(operation.id)} />
                                            </Col>
                                            <Col xs={12} md={9} lg={9}>
                                                <Row>
                                                    {renderCol('Task Id', 'Id', operation.id)}
                                                    {renderCol('Subject', 'S', operation.unitCode)}
                                                    {renderCol('Task Type', 'T', operation.type)}
                                                    {renderCol('Task Status', 'S', operation.status)}
                                                </Row>
                                            </Col>

                                            <Col xs={12} md={2} lg={2}>
                                                <ButtonStack>
                                                    <Button variation="secondary" className={'input-block'} size="small" onClick={() => onDetailClick(operation)}>Detail</Button>
                                                    <Button variation="primary" className={'input-block'} size="small" onClick={() => onTriggerClick(operation)}>Trigger</Button>
                                                </ButtonStack>
                                            </Col>
                                            <LoadingIndicator id={`${CONSTANTS.ITEMLOADER}-${operation.id}`} />
                                        </Row>
                                    </Card.Body>
                                </Card>

                            ))
                        }

                    </Card.Body>
                    <LoadingIndicator id={CONSTANTS.LISTLOADER} />
                </Card>
            </>
        )
    }

    return (
        <div>
            {renderPageHeader()}
            {renderFilter()}
            <br />
            {renderList()}
        </div>
    )
}


const mapStateToProps = ({ messages, definitions }) => {
    return {
        operationList: messages.operationList,
        isMessageTriggered: messages.isMessageTriggered,
        isMessagesTriggered: messages.isMessagesTriggered,
        messageTypes: (() => {
            const x = definitions.types.items.find(item => item.type === "MessageTypes");
            return x ? x.values : [];
        })(),
    }
}

const mapActionsToProps = {
    clearState,
    getOperations,
    fetchTypes,
    triggerMessage,
    triggerMessages,
    getMessage
}

export default connect(mapStateToProps, mapActionsToProps)(Messages);