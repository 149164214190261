/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Split from '../component/Split/Split';
import PageHeader from '../component/PageHeader/PageHeader';
import { clearState, getOperationById, getShipmentList } from '../store/action/operationActions';
import { Card } from 'react-bootstrap';

const OperationSplitContainer = (props) => {

    //Inputs from url
    const {
        operationId
    } = props.match.params;

    //Inputs from redux
    const {
        operation,
        shipmentIdList,
        shipmentList,
        clearState,
        getOperationById,
        getShipmentList,
    } = props;

    const [shipmentNoList, setShipmentNoList] = useState([]);

    useEffect(() => {
        getOperationById(operationId);
        return () => {
            clearState();
        }
    }, [])

    useEffect(() => {
        if (shipmentIdList && shipmentIdList.length > 0) {
            getShipmentList(shipmentIdList);
        }
    }, [shipmentIdList])

    useEffect(() => {
        if (shipmentList) {
            setShipmentNoList(shipmentList.map(s => s.shipmentNo))
        }
    }, [shipmentList])

    return (
        <>
            <Card>
                <PageHeader text={`Upload documents to ${operationId}`} />
                <Card.Body>
                    <Split shipments={shipmentList} splitFilePath={`Unit/${operationId}/FileToSplit`} splitters={shipmentNoList} />
                </Card.Body>
            </Card>
        </>
    );
};
const mapStateToProp = ({ operationReducer }) => {
    return {
        operation: operationReducer.operation,
        shipmentIdList: operationReducer.shipmentIdList,
        shipmentList: operationReducer.shipmentList
    }
}
export default connect(mapStateToProp, { clearState, getOperationById, getShipmentList })(OperationSplitContainer);