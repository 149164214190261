import actionTypes from "./actionTypes";
import postSync from "../../../core/httpClientSync";

const terminalURL = "xms-terminal-api/api";
//const terminalURL = "http://localhost:5000/api";

/** CREATE VOYAGE */
function createVoyage(payload, processIndicatorId) {
    return async function (dispatch) {
        dispatch(createVoyageRequesting());

        const result = await postSync(`${terminalURL}/voyage/create`, payload, processIndicatorId);
        // const result = { isSuccess: true };
        if (result.isSuccess) {
            dispatch(createVoyageSuccess(result.response));
        } else {
            dispatch(createVoyageFailure(result.error));
        }
    }
};

function createVoyageRequesting() {
    return {
        type: actionTypes.CREATE_VOYAGE_REQUESTING
    }
}

function createVoyageSuccess(payload) {
    return {
        type: actionTypes.CREATE_VOYAGE_SUCCESS,
        payload
    }
}

function createVoyageFailure(payload) {
    return {
        type: actionTypes.CREATE_VOYAGE_FAILURE,
        payload
    }
}
/** end CREATE VOYAGE */


/** READ VOYAGE, singular*/
function readVoyage(payload, processIndicatorId) {
    return async function (dispatch) {
        dispatch(readVoyageRequesting());

        const result = await postSync(`${terminalURL}/voyage/get`, payload, processIndicatorId);
        if (result.isSuccess) {
            dispatch(readVoyageSuccess(result.response));
        } else {
            dispatch(readVoyageFailure(result.error));
        }
    }
};

function readVoyageRequesting() {
    return {
        type: actionTypes.READ_VOYAGE_REQUESTING
    }
}

function readVoyageSuccess(payload) {
    return {
        type: actionTypes.READ_VOYAGE_SUCCESS,
        payload
    }
}

function readVoyageFailure(payload) {
    return {
        type: actionTypes.READ_VOYAGE_FAILURE,
        payload
    }
}
/** end READ VOYAGE */


/** READ VOYAGES, plural */
function readVoyages(processIndicatorId) {
    return async function (dispatch) {
        dispatch(readVoyagesRequesting());

        const result = await postSync(`${terminalURL}/voyage/list`, undefined, processIndicatorId);
        if (result.isSuccess) {
            dispatch(readVoyagesSuccess(result.response));
        } else {
            dispatch(readVoyagesFailure(result.error));
        }
        return result;
    }
};

function readVoyagesRequesting() {
    return {
        type: actionTypes.READ_VOYAGES_REQUESTING
    }
}

function readVoyagesSuccess(payload) {
    return {
        type: actionTypes.READ_VOYAGES_SUCCESS,
        payload
    }
}

function readVoyagesFailure(payload) {
    return {
        type: actionTypes.READ_VOYAGES_FAILURE,
        payload
    }
}
/** end READ VOYAGES */


/** UPDATE VOYAGE */
function updateVoyage(payload, processIndicatorId) {
    return async function (dispatch) {
        dispatch(updateVoyageRequesting());

        const result = await postSync(`${terminalURL}/voyage/update`, payload, processIndicatorId);
        if (result.isSuccess) {
            dispatch(updateVoyageSuccess(result.response));
        } else {
            dispatch(updateVoyageFailure(result.error));
        }
    }
};

function updateVoyageRequesting() {
    return {
        type: actionTypes.UPDATE_VOYAGE_REQUESTING
    }
}

function updateVoyageSuccess(payload) {
    return {
        type: actionTypes.UPDATE_VOYAGE_SUCCESS,
        payload
    }
}

function updateVoyageFailure(payload) {
    return {
        type: actionTypes.UPDATE_VOYAGE_FAILURE,
        payload
    }
}
/** end UPDATE VOYAGE */


/** DELETE VOYAGES, plural */
function deleteVoyages(payload, processIndicatorId) {
    return async function (dispatch) {
        dispatch(deleteVoyagesRequesting());

        const result = await postSync(`${terminalURL}/voyage/delete`, payload, processIndicatorId);
        if (result.isSuccess) {
            dispatch(deleteVoyagesSuccess(payload));
        } else {
            dispatch(deleteVoyagesFailure(result.error));
        }
    }
};

function deleteVoyagesRequesting() {
    return {
        type: actionTypes.DELETE_VOYAGES_REQUESTING
    }
}

function deleteVoyagesSuccess(payload) {
    return {
        type: actionTypes.DELETE_VOYAGES_SUCCESS,
        payload
    }
}

function deleteVoyagesFailure(payload) {
    return {
        type: actionTypes.DELETE_VOYAGES_FAILURE,
        payload
    }
}
/** end DELETE VOYAGES, plural */


/** FILTER VOYAGES */
function filterVoyages(payload, processIndicatorId) {
    return async function (dispatch) {
        dispatch(filterVoyagesRequesting());

        const result = await postSync(`${terminalURL}/voyage/search`, payload, processIndicatorId);
        if (result.isSuccess) {
            dispatch(filterVoyagesSuccess(result.response));
        } else {
            dispatch(filterVoyagesFailure(result.error));
        }
    }
};

function filterVoyagesRequesting() {
    return {
        type: actionTypes.FILTER_VOYAGES_REQUESTING
    }
}

function filterVoyagesSuccess(payload) {
    return {
        type: actionTypes.FILTER_VOYAGES_SUCCESS,
        payload
    }
}

function filterVoyagesFailure(payload) {
    return {
        type: actionTypes.FILTER_VOYAGES_FAILURE,
        payload
    }
}
/** end FILTER VOYAGES */



export {
    createVoyage,
    readVoyage,
    readVoyages,
    updateVoyage,
    deleteVoyages,
    filterVoyages,
};