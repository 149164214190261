import { Field } from "redux-form";
import React from "react";
import { InputComponent } from "./Pure/InputComponent";
import * as PropTypes from "prop-types";
import { addValidationToArray } from "./Validation/FormValidation";

export const TextAreaInputFormComponent = ({ name, rowCount, isRequired, isDisabled, validationArray, onChange }) => {
    const validation = addValidationToArray(isRequired, validationArray);
    return (<Field
        onChange={onChange}
        name={name}
        component={InputComponent}
        as="textarea"
        disabled={isDisabled}
        rows={rowCount}
        validate={validation}
    />)
};

TextAreaInputFormComponent.propTypes = {
    name: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    isRequired: PropTypes.bool,
    validationArray: PropTypes.arrayOf(PropTypes.func)
};