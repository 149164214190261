import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import LoadingIndicator from '../../component/LoadingIndicator/LoadingIndicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { updateSealNumber, unitLeft } from '../../store/action/operationActions'
import { getValue } from '../../core/utility'
import { Button as DfdsButton } from '@dfds-ui/react-components';
import { confirmationModal } from '../../store/action/modalActions';
import { utcToEpochWithTime } from '../../core/utcToEpochConverter';
import PolicyGroupAccessHoc from "../../RoutedComponents/Routes/PolicyGroupAccessHoc";

const Summary = ({ operation, show, loader, showSealNumber, showUnitLeft, updateSealNumber, unitLeft, confirmationModal }) => {

    const [editMode, setEditmode] = useState(false);
    const [sealNumberVal, setSealNumberVal] = useState('')
    const [details, setDetails] = useState({});

    useEffect(() => {
        if (operation && operation.details) {
            setDetails(operation.details);
            setSealNumberVal(getValue(operation.details, 'sealNumber', ''));
        }
    }, [operation])

    if (!show) {
        return null;
    }

    const setOrUpdateSealNumber = (e) => {
        if (details.sealNumber != sealNumberVal) {
            updateSealNumber(operation.id, sealNumberVal, loader);
        }
        switchEditMode()
    }

    const undoEdit = () => {
        setSealNumberVal(details.sealNumber);
        switchEditMode();
    }

    const switchEditMode = () => {
        setEditmode(!editMode);
    }

    const field = (label, value) => {
        if (value) {
            return (
                <div key={`${label}-${value}`} className='col-md-4'>
                    <div className='form-group'>
                        <label className="text-muted">{label}</label>
                        <input type='text' className='form-control' disabled value={value}></input>
                    </div>
                </div>
            )
        }
        return null;
    }

    const editablefField = (label, type, value, setValue, switchDisable, undoEdit, updateAction) => {
        if (showSealNumber) {
            return (
                <div key={[{ label }, { value }].join('-')} className='col-md-4'>
                    <div className="input-group mb-3">
                        <label className="text-muted">{label}</label>
                        <div className="input-group" >
                            <input type={type} className='form-control' value={value} onChange={(e => setValue(e.target.value))} onKeyUp={(e => setValue(e.target.value))} aria-label={label} aria-describedby="basic-addon2" disabled={!editMode ? 'disabled' : ''}></input>

                            <PolicyGroupAccessHoc componentName='UnitSealNumberButtonHOC'>
                                {renderEditButtons(editMode, switchDisable, undoEdit, updateAction)}
                            </PolicyGroupAccessHoc>
                        </div>
                    </div>
                </div>
            )
        }
        return null;
    }

    const renderEditButtons = (mode, switcher, undoEdit, updateAction) => {
        if (!mode)
            return (
                < div className="input-group-append" id="basic-addon2">
                    <Button variant="light" onClick={switcher}><FontAwesomeIcon icon={"edit"} /></Button>
                </div >
            )
        else
            return (
                < div className="input-group-append" id="basic-addon2">
                    <Button variant="light" onClick={updateAction}><FontAwesomeIcon icon={"check"} className={'color-yes-green'} /></Button>
                    <Button variant="light" onClick={undoEdit}><FontAwesomeIcon icon={"times"} className={'color-no-red'} /> </Button>
                </div >
            )
    }

    const renderUnitLeftButton = (operationId, value) => {
        if (!showUnitLeft) {
            return null;
        }

        return (
            <PolicyGroupAccessHoc componentName='UnitLeftButtonHOC' key={`${operationId}-unitleft-hoc`}>
                <div key={`${operationId}-unitleft`} className='col-md-4'>
                    <DfdsButton className='input-block' style={{ marginTop: '20px' }} disabled={value} variation="secondary" onClick={() => unitLeftConfirmation(operationId)}>Unit Left</DfdsButton>
                </div>
            </PolicyGroupAccessHoc>
        )
    }

    const unitLeftConfirmation = (operationId) => {
        confirmationModal('Operation Details', 'Unit will be set as left. Do you approve?', () => {
            unitLeft(operationId, utcToEpochWithTime(new Date()), loader)
        });
    }

    return (
        <div className='card'>
            <div className='card-body' mt={0}>
                <div className='col'>
                    <div className='row'>
                        {
                            [
                                field('Unit', getValue(operation, 'unitCode')),
                                field('Task', getValue(operation, 'id')),
                                field('Status', getValue(operation, 'statusName')),
                                field('Type', getValue(operation, 'typeName')),
                                field('Total Expected / Actual Gross Weight', getValue(details, 'totalExpectedGrossWeight', '0') + ' / ' + getValue(details, 'totalActualGrossWeight', '0')),
                                field('Total Expected / Actual Volume', getValue(details, 'totalExpectedVolume', '0') + ' / ' + getValue(details, 'totalActualVolume', '0')),
                                field('Total Expected / Actual Item Quantity', getValue(details, 'totalExpectedItemQuantity', '0') + ' / ' + getValue(details, 'totalActualItemQuantity', '0')),
                                editablefField('Seal Number', 'text', sealNumberVal, setSealNumberVal, switchEditMode, undoEdit, setOrUpdateSealNumber),
                                renderUnitLeftButton(getValue(operation, 'id'), getValue(details, 'unitLeftDateTime', null))
                            ]
                        }
                    </div>
                </div>
                <LoadingIndicator id={loader} show />
            </div>
        </div>
    )
};


const mapStateToProps = ({ }) => {
    return {
    }
}

const mapStateToActions = {
    updateSealNumber,
    unitLeft,
    confirmationModal
}


export default connect(mapStateToProps, mapStateToActions)(Summary);