export default Object.freeze({
    READ_SHIPMENTS_REQUESTING: 'astrid/shipments/READ_SHIPMENTS_REQUESTING',
    READ_SHIPMENTS_SUCCESS: 'astrid/shipments/READ_SHIPMENTS_SUCCESS',
    READ_SHIPMENTS_FAILURE: 'astrid/shipments/READ_SHIPMENTS_FAILURE',

    SET_FILTER: 'astrid/shipments/SET_FILTER',
    SET_FILTERS: 'astrid/shipments/SET_FILTERS',
    SET_FILTERS_AUX: 'astrid/shipments/SET_FILTERS_AUX',
    RESET_ALL_FILTERS: 'astrid/shipments/RESET_ALL_FILTERS',

    SORT_SHIPMENTS: 'astrid/shipments/SORT_SHIPMENTS',
});