import bookingActions from '../type/booking';
import httpClient from '../../core/httpclient';
import { spinnerService } from '@simply007org/react-spinners';
import { toast } from 'react-toastify';
import post, { get } from '../../core/httpClientSync';
import modalTypes from '../type/modal';
import moment from 'moment';
import {
  HubConnectionBuilder,
  HubConnectionState,
  HubConnection,
  HttpTransportType
} from '@microsoft/signalr';

const apiUrl = 'astrid-slotbooking-api/api/booking';
//const apiUrl = 'https://localhost:5001/api/booking';
//const signalRUrl = 'https://localhost:5001';
//const signalRUrl = 'astrid-slotbooking-api';
const businessAreasUrl = 'astrid-slotbooking-api/api/businessarea';
const taskApiUrl = 'xms-workflow-api/api/operation';


export const getAvailableDates = (businessAreaId) => async (dispatch) => {
  let endPoint = `${apiUrl}/getnextworkingdays?businessAreaId=${businessAreaId}`;
  const response = await get(endPoint, 'bookingTimeSlot');
  if (response.isSuccess) {
    dispatch({
      type: bookingActions.AVAILABLE_DATES_RECEIVED,
      payload: response.response.result.availableDates
    });
  } else {
    dispatch({
      type: bookingActions.TIME_SLOT_NOT_AVAILABLE
    });
  }
};

export const selectTimeSlot = (date, time) => async (dispatch) => {
  dispatch({
    type: bookingActions.BOOKING_SELECTTIMESLOT_SUCCESS,
    payload: { date: date, time: time }
  });
};

export const createBooking = (formData) => async (dispatch) => {
  const endPoint = `${apiUrl}/save`;
  console.log('FORMDATA CREATEBOOKİNG TİME:', formData.dateTime);
  const createBookingRequest = {
    DateTime: formData.dateTime,
    BusinessAreaId: formData.businessAreaId,
    BusinessAreaName: formData.businessAreaName,
    ClientId: formData.clientId,
    ClientName: formData.clientName,
    ServiceName: formData.serviceName,
    ServiceId: formData.serviceId,
    UnitTypeId: formData.unitType,
    UnitTypeName: formData.unitTypeName,
    HasDangerousGoods: formData.hasDangerousGoods === 'true' ? 1 : 0,
    HasCustoms: formData.hasCustoms === 'true' ? 1 : 0,
    UnitNo: formData.unitNo,
    Name: formData.name,
    Surname: formData.surname,
    Email: formData.email,
    THUAmount: formData.thuAmount,
    Carrier: formData.carrier,
    OperationType: formData.operationType === 'Unloading' ? 1 : 0,
    PhoneNumber: formData.phoneNumber,
    TransportNumbers: [formData.transportNumbers],
    AdministratorNote: formData.administratorNote,
    UserNote: formData.userNote
  };

  const response = await post(endPoint, createBookingRequest, 'createOrUpdateBooking');
  if (response.isSuccess) {
    dispatch({
      type: bookingActions.BOOKING_CREATEBOOKING_SUCCESS,
      payload: response.response
    });
  }
};

export const updateBooking = (bookingCode, formData, isInternalUser) => async (dispatch) => {
  const endPoint = isInternalUser ? `${apiUrl}/Save` : `${apiUrl}/save`;
  const updateBookingRequest = {
    DateTime: formData.dateTime,
    BookingCode: bookingCode,
    BusinessAreaId: formData.businessAreaId,
    ClientId: formData.clientId,
    ClientName: formData.clientName,
    ServiceName: formData.serviceName,
    ServiceId: formData.serviceId,
    UnitTypeId: formData.unitType,
    HasDangerousGoods: formData.hasDangerousGoods === 'true' ? 1 : 0,
    HasCustoms: formData.hasCustoms === 'true' ? 1 : 0,
    UnitNo: formData.unitNo,
    Name: formData.name,
    Surname: formData.surname,
    Email: formData.email,
    THUAmount: formData.thuAmount,
    Carrier: formData.carrier,
    OperationType: formData.operationType === 'Unloading' ? 1 : 0,
    PhoneNumber: formData.phoneNumber,
    TransportNumbers: [formData.transportNumbers],
    AdministratorNote: formData.administratorNote,
    UserNote: formData.userNote
  };

  spinnerService.show('createOrUpdateBooking');
  httpClient
    .post(endPoint, updateBookingRequest)
    .then((response) => {
      dispatch({
        type: bookingActions.UPDATE_BOOKING,
        payload: bookingCode
      });
    })
    .catch((error) => {
      console.log('Error occured: ', error);
      const errorMessage =
        error.response &&
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.length
          ? error.response.data.errors[0].description
          : error.message;
      if (errorMessage) {
        toast.error(`${errorMessage}`);
      }
    })
    .finally(() => {
      spinnerService.hide('createOrUpdateBooking');
    });
};

export const fillBookingFormData = (bookingFormData) => async (dispatch) => {
  dispatch({
    type: bookingActions.BOOKING_FILL_FORM_DATA_SUCCESS,
    payload: bookingFormData
  });
};

export const fillBookingFormById = (bookingId) => async (dispatch) => {
  let endPoint = `${apiUrl}/getbyid?id=${bookingId}`;

  spinnerService.show('createOrUpdateBooking');
  dispatch({
    type: bookingActions.BOOKING_RETRIEVING,
    payload: true
  });

  let response = await get(endPoint, "createOrUpdateBooking");
  if(response && response.response && response.response.result){
    let innerResponse = response.response.result;
    let resultData = {
      ...innerResponse,
      businessAreaId: !innerResponse.businessAreaId
        ? ''
        : innerResponse.businessAreaId,
      unitType: !innerResponse.unitTypeId ? '' : innerResponse.unitTypeId,
      name: !innerResponse.name ? '' : innerResponse.name,
      surname: !innerResponse.surname ? '' : innerResponse.surname,
      userNote: !innerResponse.userNote ? '' : innerResponse.userNote,
      administratorNote: !innerResponse.administratorNote
        ? ''
        : innerResponse.administratorNote,
      transportNumbers:
        innerResponse.transportNumbers != undefined
          ? innerResponse.transportNumbers.join(', ')
          : '',
      operationType: innerResponse.operationType,
      hasDangerousGoods: innerResponse.hasDangerousGoods ? 'true' : 'false',
      hasCustoms: innerResponse.hasCustoms ? 'true' : 'false'
    };
    dispatch({
      type: bookingActions.FILL_FORM_DATA_BY_ID,
      payload: resultData
    });
  }

  // httpClient
  //   .get(endPoint)
  //   .then((response) => {
  //     response.data.result = {
  //       ...response.data.result,
  //       businessAreaId: !response.data.result.businessAreaId
  //         ? ''
  //         : response.data.result.businessAreaId,
  //       unitType: !response.data.result.unitTypeId ? '' : response.data.result.unitTypeId,
  //       name: !response.data.result.name ? '' : response.data.result.name,
  //       surname: !response.data.result.surname ? '' : response.data.result.surname,
  //       userNote: !response.data.result.userNote ? '' : response.data.result.userNote,
  //       administratorNote: !response.data.result.administratorNote
  //         ? ''
  //         : response.data.result.administratorNote,
  //       transportNumbers:
  //         response.data.result.transportNumbers != undefined
  //           ? response.data.result.transportNumbers.join(', ')
  //           : '',
  //       operationType: response.data.result.operationType,
  //       hasDangerousGoods: response.data.result.hasDangerousGoods ? 'true' : 'false',
  //       hasCustoms: response.data.result.hasCustoms ? 'true' : 'false'
  //     };
  //     dispatch({
  //       type: bookingActions.FILL_FORM_DATA_BY_ID,
  //       payload: response.data.result
  //     });
  //   })
  //   .catch((error) => {
  //     const errorMessage =
  //       error.response &&
  //         error.response.data &&
  //         error.response.data.errors &&
  //         error.response.data.errors.length
  //         ? error.response.data.errors[0].description
  //         : error.message;
  //     if (errorMessage) {
  //       toast.error(`${errorMessage}`);
  //     }
  //   })
  //   .finally(() => {
  //     dispatch({
  //       type: bookingActions.BOOKING_RETRIEVING,
  //       payload: false
  //     });
  //     spinnerService.hide('createOrUpdateBooking');
  //   });
};

export const getTimeSlots = (businessAreaId, dateEpoch) => async (dispatch) => {
  let endPoint = `${apiUrl}/getslotsfordate?businessAreaId=${businessAreaId}&date=${dateEpoch}`;

  const response = await get(endPoint, 'bookingTimeSlot');
  if (response.isSuccess) {
    dispatch({
      type: bookingActions.BOOKING_GETTIMESLOTS_SUCCESS,
      payload: response.response.result
    });
  } else {
    dispatch({
      type: bookingActions.TIME_SLOT_NOT_AVAILABLE
    });
  }
};

let endPoint = `${apiUrl}/suggesttime`;
export const getDynamicTimeSlots = (formData) => async (dispatch) => {

  const request = {
    ...formData,
    transportNumbers: formData.transportNumbers ? formData.transportNumbers.split(',') : [],
    dateTime: moment.utc(formData.preferredBookingDate, 'DD/MM/YYYY HH:mm').valueOf() / 1000
  };

  dispatch({
    type: bookingActions.TIME_SLOTS_CLEARED
  });

  const response = await post(endPoint, request, 'createOrUpdateBooking');
  if (response.isSuccess) {
    dispatch({
      type: bookingActions.BOOKING_GETTIMESLOTS_SUCCESS,
      payload: response.response
    });
  } else {
    dispatch({
      type: bookingActions.TIME_SLOT_NOT_AVAILABLE
    });
  }
};

export const getBookingsForCarrier = (date) => async (dispatch) => {
  let endPoint = `${apiUrl}/getbookingsforcarrier?date=${date}`;
  spinnerService.show('bookingGetAll');
  const response = await get(endPoint, 'bookingGetAll');
  if (response.isSuccess) {
    dispatch({
      type: bookingActions.BOOKING_GETBOOKINGSFORCARRIER_SUCCESS,
      payload: response.response.result
    });
  }
};

export const getBookings = (date) => async (dispatch) => {
  let endPoint = `${apiUrl}/getbookingsfordate?date=${date}`;
  const response = await get(endPoint, 'bookingGetAll');
  if (response.isSuccess) {
    dispatch({
      type: bookingActions.BOOKING_GETBOOKINGS_SUCCESS,
      payload: response.response.result
    });
  }
};

export const setBookings = (bookingList) => async (dispatch) => {
  dispatch({
    type: bookingActions.BOOKING_GETBOOKINGS_SUCCESS,
    payload: bookingList
  });
};

export const setUpSignalRConnectionForBookingsList = (datetime) => async (dispatch) => {
  var endPoint = `${process.env.REACT_APP_BASE_API_URL}/astrid-slotbooking-api/bookinglisthub`;
 // var endPoint = `https://localhost:5001/bookinglisthub`;
  const connection = new HubConnectionBuilder()
    .withUrl(endPoint, { skipNegotiation: true, transport: HttpTransportType.WebSockets })
    .withAutomaticReconnect()
    .build();

  connection.on('Message', (message) => {
    console.log('Message', message);

    connection.on('BookingsChanged', (bookingList, bookingDate) => {
      console.log(bookingList + " **** " + bookingDate);
      setBookings(bookingList);
    });
  });
  
  try {
    await connection.start(); 
    
    dispatch({
      type: bookingActions.BOOKING_SIGNALR_CONNECTION_SUCCESS,
      payload: connection
    });


  } catch (err) {
    console.log(err);
  }
};

export const markAsArrived = (bookingId) => async (dispatch) => {
  let endPoint = `${apiUrl}/markunitasarrived?bookingCode=${bookingId}`;

  const response = await post(endPoint, { bookingCode: bookingId }, "bookingGetAll");

  if (response.isSuccess) {
    dispatch({
      type: bookingActions.BOOKING_MARK_AS_ARRIVED_SUCCESS,
      payload: bookingId
    });
  }
};

export const markAsUnArrived = (bookingId) => async (dispatch) => {
  let endPoint = `${apiUrl}/markunitasunarrived?bookingCode=${bookingId}`;

  const response = await post(endPoint, null, "bookingGetAll");

  if (response.isSuccess) {
    dispatch({
      type: bookingActions.BOOKING_MARKED_AS_UNARRIVED,
      payload: bookingId
    });
  }
};

export const getArrivalReceiptDetails = (bookingId) => async dispatch => {
  const endPoint = `${taskApiUrl}/get-detail-external`;

  const response = await post(
    endPoint,
    { externalId: bookingId, channelType: 'Booking' },
    'bookingGetAll'
  );
  if (response.isSuccess) {
    dispatch({
      type: bookingActions.TASK_DETAIL_RECEIVED,
      payload: response.response
    });

    return response.response.id;
  }
  return null;
}

export const cancelBooking = (bookingId) => async (dispatch) => {
  let endPoint = `${apiUrl}/cancelbooking?bookingCode=${bookingId}`;

  const response = await post(endPoint, { code: bookingId }, 'bookingGetAll');
  if (response.isSuccess) {
    dispatch({ type: bookingActions.BOOKING_CANCEL_BOOKING, payload: bookingId });
  }
};

export const getUnitTypes = () => async (dispatch) => {
  const endPoint = `${apiUrl}/getunittypes`;
  const getUnitTypesResponse = await get(endPoint, '');
  if (getUnitTypesResponse.isSuccess) {
    dispatch({
      type: bookingActions.UNIT_TYPE_RECEIVED,
      payload: getUnitTypesResponse.response.result
    });
  }
};

export const showInlineInformationModal = (title, data, canSeeAdminNotes, handler) => async (
  dispatch
) => {
  dispatch({
    type: modalTypes.SHOW_MODAL,
    payload: {
      modalType: 'inline-information',
      modalData: { title: title, data: data, canSeeAdminNotes: canSeeAdminNotes, handler: handler }
    }
  });
};

export const clearTimeSlots = () => async (dispatch) => {
  dispatch({
    type: bookingActions.TIME_SLOTS_CLEARED
  });
};

export const getBusinessAreas = () => async (dispatch) => {
  const endPoint = `${businessAreasUrl}/getall`;
  const getBusinessAreasResponse = await get(endPoint, 'createOrUpdateBooking');
  const businessAreasItems = getBusinessAreasResponse.response.result.map((item) => ({
    value: item.id,
    text: item.name
  }));
  if (getBusinessAreasResponse.isSuccess) {
    dispatch({
      type: bookingActions.BUSINESS_AREA_RECEIVED,
      payload: businessAreasItems
    });
  }
};

export const getBusinessAreasByClient = (clientId) => async (dispatch) => {
  const endPoint = 'astrid-slotbooking-api/api/businessarea/getbycompany';
  const getBusinessAreasResponse = await get(`${endPoint}/${clientId}`, 'createOrUpdateBooking');
  const businessAreasItems = getBusinessAreasResponse.response.result.map((item) => ({
    value: item.id,
    text: item.name
  }));
  if (getBusinessAreasResponse.isSuccess) {
    dispatch({
      type: bookingActions.CLIENT_BUSINESS_AREAS_RECEIVED,
      payload: businessAreasItems
    });
  }
};

export const getUnitTypesForBooking = () => async (dispatch) => {
  const endPoint = `${apiUrl}/getunittypes`;
  const getUnitTypesResponse = await get(endPoint, 'createOrUpdateBooking');
  const unitTypesItems = getUnitTypesResponse.response.result.map((item) => ({
    value: item.id,
    text: item.name
  }));
  if (getUnitTypesResponse.isSuccess) {
    dispatch({
      type: bookingActions.UNIT_TYPES_FOR_BOOKING,
      payload: unitTypesItems
    });
  }
};

export const getClients = () => async (dispatch) => {
  const response = await post(
    `xms-company-api/api/company/search`,
    { isClient: true, pageSize: 1000, onlyAuthorizedClients: true },
    'createOrUpdateBooking'
  );
  response.isSuccess &&
    dispatch({
      type: bookingActions.CLIENTS_RETRIEVED,
      payload: response.response.map((item) => ({ value: item.id, text: item.name }))
    });
};

export const getServicesByClientAndType = (clientId, type) => async (dispatch) => {
  const response = await get(
    `xms-slm-api/api/contractdefinition/getservicesbycompanyandtype/${clientId}/${type}`,
    'createOrUpdateBooking'
  );
  response.isSuccess &&
    dispatch({
      type: bookingActions.SERVICES_RETRIEVED,
      payload: response.response.result.services.map((item) => ({
        value: item.serviceId,
        text: item.serviceName
      }))
    });
};

export const clearBookingState = () => async (dispatch) => {
  dispatch({
    type: bookingActions.BOOKING_CLEAR_STATE
  });
};

export const clearBookingFormData = () => async (dispatch) => {
  dispatch({
    type: bookingActions.FORM_DATA_CLEARED
  });
};

const bookingDataRetrieving = (bookingStatus) => async (dispatch) => {
  dispatch({
    type: bookingActions.BOOKING_RETRIEVING,
    payload: bookingStatus
  });
};

export const getBusinessAreaDetails = (businessAreaId) => async (dispatch) => {
  const response = await get(
    `astrid-slotbooking-api/api/calendar/getslottypebybusinessarea/${businessAreaId}`
  );
  if (response.isSuccess) {
    dispatch({
      type: bookingActions.BUSINESS_AREA_DETAILS_RECEIVED,
      payload: { isDynamicTimeSlot: response.response.result === 'Dynamic' }
    });
  }
};


export const showAdminNotesInModal = (bookingId) => async dispatch => {
  const endPoint = `${apiUrl}/getbyid?id=${bookingId}`;
  var response = await get(endPoint, "bookingGetAll");
  if (response && response.response && response.isSuccess && response.response.result) {
    let adminNote = response.response.result.administratorNote;
    dispatch({
      type: modalTypes.SHOW_MODAL,
      payload: {
        modalType: 'modal-update-admin-notes',
        modalData: { adminNote: adminNote, bookingCode: bookingId }
      }
    });
  }
}

export const saveAdminNote = (bookingCode, adminNote, successCallback) => async dispatch => {
  const endPoint = `${apiUrl}/save-admin-notes`;
  const request = {
    BookingCode: bookingCode,
    AdminNote: adminNote
  };
  var response = await post(endPoint, request, "adminNoteModal");
  var isSuccess = response && response.isSuccess;
  var result = response && response.response; //holds true or false from the server
  if (isSuccess) {
    if (successCallback) {
      successCallback();
    }
  }

  if (result) {
    dispatch({
      type: bookingActions.ADMIN_NOTE_UPDATED,
    });
  }
}

export const getQuery = (queryParameter) => {
  var urlParams = new URLSearchParams(window.location.search);
  if(urlParams){
    if(urlParams.has(queryParameter)){
      const value = urlParams.get(queryParameter)
      return value;
    }
  }

  return null;
}

export const parseUrlForBookingList = (queryParameter)=> {
  var urlParams = new URLSearchParams(window.location.search);
  if(urlParams){
    if(urlParams.has(queryParameter)){
      var bookingListQueryObject = JSON.parse(urlParams.get(queryParameter));
      return bookingListQueryObject;
    }
  }

  return null;
}

export const arrangeUrlForBookingList = (bookingCode, selectedBusinessAreas, searchTerm, date)=> {
    var queryStringObj = {
      bookingCode: bookingCode,
      businessAreas: selectedBusinessAreas,
      searchTerm: searchTerm,
      date: date
    };
    const queryString = JSON.stringify(queryStringObj);
    return queryString;
}