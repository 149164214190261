/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getCompany, clearCompanyState, getCompanyList, insertOrUpdateCompany, fetchTypes, fetchCountries } from '../store/action/companyActions';
import { getActiveContractByCompanyCode, getDraftContract, clearContractState } from '../store/action/contractActions';
import CompanyForm from '../component/CompanyForm/CompanyForm';
import history from '../core/history';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import { toast } from 'react-toastify';
import Page from '../component/Page/Page';
import ActiveContract from '../component/Company/ActiveContract';
import DraftContract from '../component/Company/DraftContract';

function AddOrEditCompany({ match, companyList, company, getCompany, clearCompanyState, getCompanyList, insertOrUpdateCompany, getActiveContractByCompanyCode, getDraftContract, activeContract, draftContract, clearContractState,
    countryList, companyTypeList, fetchTypes, fetchCountries }) {

    useEffect(() => {
        if (!companyList) {
            getCompanyList();
        }
        
        if(!countryList || countryList.length == 0){
            fetchCountries();
        }

        if(!companyTypeList || companyTypeList.length == 0){
            fetchTypes();
        }

        // return () => {
        //     clearCompanyState();
        //     clearContractState();
        // }
    }, []);
    useEffect(() => {
        if (match.params.companycode !== 'new') {
            if (!activeContract)
                getActiveContractByCompanyCode(match.params.companycode);
            if (!draftContract)
                getDraftContract(match.params.companycode);
        }
    }, [match.params.companycode, activeContract, draftContract]);

    useEffect(() => {
        if (match.params.companycode !== 'new' && !company) {
            getCompany(companyList, match.params.companycode);
        }
    }, [companyList, match.params.companycode])

    const handleSubmit = (form) => {
        insertOrUpdateCompany(form.name, form.code);
        if (match.params.companycode === 'new') {
            toast.success('Company added.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
            });
        } else {
            toast.success('Company updated.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
            });
        }

        history.push('/company');
    }

    return <Page backLink='/company' backToWhere='Companies' >
        <h4>
            {company ? company.name : 'New Company'}
        </h4><hr className='mt-0' />
        <CompanyForm onSubmit={handleSubmit} isNewCompany={match.params.companycode === 'new'} companyTypes = {companyTypeList} countries={countryList} />
        <ActiveContract hidden={(match.params.companycode === 'new' || (draftContract && draftContract.id))} activeContract={activeContract} companyCode={match.params.companycode}></ActiveContract>
        <DraftContract hidden={match.params.companycode === 'new'} draftContract={draftContract} companyCode={match.params.companycode}></DraftContract>

        <LoadingIndicator id="contractDetail" />
    </Page>
}

const mapStateToProps = (state) => {
    return {
        company: state.company.company,
        companyList: state.company.companyList,
        activeContract: state.contract.activeContract,
        draftContract: state.contract.draftContract,
        countryList: state.company.countryList,
        companyTypeList: state.company.companyTypeList
    }
}
export default connect(mapStateToProps, { getCompanyList, getCompany, clearCompanyState, clearContractState, insertOrUpdateCompany, getActiveContractByCompanyCode, getDraftContract, fetchCountries, fetchTypes })(AddOrEditCompany);