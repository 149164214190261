import React from 'react';
import { Button, Input } from '@dfds-ui/react-components'

const SearchFilter = ({ show, onChange, value }) => {

    if (!show) {
        return null;
    }

    return (
        <>
            <div className="form-group">
                <label className="col-form-label">Search</label>
                <Input label="Search" size="small" placeholder="Shipment Number & THU Number" value={value} onChange={event => onChange(event.target.value)} />
            </div>
        </>
    )
}

export default SearchFilter;