import { stubTrue } from 'lodash';
import types from '../type/booking';

const initialState = {
    formData: null,
    bookingResult: false,
    isRefreshData: false,
    selectedDate: null,
    selectedTime: null,
    bookings: null,
    carrierBookings: null,
    unitTypes: null,
    unitTypesForBooking: null,
    businessAreas: null,
    isRedirectionNeeded: null,
    taskDetail: null,
    availableDates: null,
    isBookingRetrieving: false,
    clientBusinessAreas: null,
    services: null,
    clients: null,
    businessAreaDetails: null,
    timeSlots: null,
    signalRConnection: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.BOOKING_FILL_FORM_DATA_SUCCESS:
            return {
                ...state,
                formData: action.payload
            }
        case types.UPDATE_BOOKING:
            return {
                ...state,
                bookingResult: action.payload
            }
        case types.FILL_FORM_DATA_BY_ID:
            return {
                ...state,
                formData: action.payload
            }
        case types.FORM_DATA_CLEARED:
            return {
                ...state,
                formData: null
            }
        case types.BOOKING_CREATEBOOKING_SUCCESS:
            return {
                ...state,
                bookingResult: action.payload
            }
        case types.BOOKING_GETTIMESLOTS_SUCCESS:
            return {
                ...state,
                timeSlots: action.payload
            }
        case types.BOOKING_SELECTTIMESLOT_SUCCESS:
            return {
                ...state,
                selectedDate: action.payload.date,
                selectedTime: action.payload.time
            }
        case types.BOOKING_GETBOOKINGS_SUCCESS:
            return {
                ...state,
                bookings: action.payload,
                isRefreshData: false
            }
        case types.BOOKING_SIGNALR_CONNECTION_SUCCESS:
            return {
                ...state,
                signalRConnection: action.payload
            }
        case types.BOOKING_GETBOOKINGSFORCARRIER_SUCCESS:
            return {
                ...state,
                carrierBookings: action.payload
            }
        case types.BOOKING_CANCEL_BOOKING:
            return {
                ...state,
                bookings: state.bookings ? state.bookings.filter(x => x.bookingCode !== action.payload) : null,
                carrierBookings: state.carrierBookings ? state.carrierBookings.filter(x => x.bookingCode !== action.payload) : null
            }
        case types.BOOKING_MARK_AS_ARRIVED_SUCCESS:
            const bookingsCloned = [...state.bookings];
            const arrivedBookingIndex = bookingsCloned.findIndex(x => x.bookingCode === action.payload);
            bookingsCloned[arrivedBookingIndex].isArrived = true;

            return {
                ...state,
                bookings: bookingsCloned
            }
        case types.UNIT_TYPE_RECEIVED: {
            return {
                ...state,
                unitTypes: action.payload
            }
        }
        case types.TIME_SLOT_NOT_AVAILABLE: {
            return {
                ...state,
                isRedirectionNeeded: true
            }
        }
        case types.BOOKING_MARKED_AS_UNARRIVED: {
            return {
                ...state,
                isRefreshData: true
            }
        }
        case types.BUSINESS_AREA_RECEIVED: {
            return {
                ...state,
                businessAreas: action.payload
            }
        }
        case types.CLIENT_BUSINESS_AREAS_RECEIVED: {
            return {
                ...state,
                clientBusinessAreas: action.payload
            }
        }
        case types.UNIT_TYPES_FOR_BOOKING: {
            return {
                ...state,
                unitTypesForBooking: action.payload
            }
        }
        case types.TASK_DETAIL_RECEIVED: {
            return {
                ...state,
                taskDetail: action.payload
            }
        }
        case types.AVAILABLE_DATES_RECEIVED: {
            return {
                ...state,
                availableDates: action.payload
            }
        }
        case types.TIME_SLOTS_CLEARED: {
            return {
                ...state,
                timeSlots: null
            }
        }
        case types.BOOKING_RETRIEVING: {
            return {
                ...state,
                isBookingRetrieving: action.payload
            }
        }
        case types.CLIENTS_RETRIEVED: {
            return {
                ...state,
                clients: action.payload
            }
        }
        case types.SERVICES_RETRIEVED: {
            return {
                ...state,
                services: action.payload
            }
        }
        case types.BUSINESS_AREA_DETAILS_RECEIVED: {
            return {
                ...state,
                businessAreaDetails: action.payload
            }
        }
        case types.BOOKING_CLEAR_STATE:
            return {
                ...initialState,
                signalRConnection: state.signalRConnection};
        case types.ADMIN_NOTE_UPDATED:
            return {
                ...state,
                isRefreshData: true
            }
        default:
            return state;
    }
};
