/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { getSuffixByCode, getEmailsForSuffix, addEmailForSuffix, removeEmailForSuffix } from '../store/action/notificationActions';
import { Table, Col, Row } from 'react-bootstrap';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmailValid } from '../core/emailValidator';
import ReadonlyTextField from '../component/ReadonlyTextField/ReadonlyTextField';
import PageHeader from '../component/PageHeader/PageHeader';
import { Button, TextField } from '@dfds-ui/react-components';

const NotificationDetail = ({ match, getSuffixByCode, getEmailsForSuffix, addEmailForSuffix, removeEmailForSuffix, suffixDetail, suffixEmails }) => {
    useEffect(() => {
        const suffixId = match.params.notificationId;
        if (!suffixDetail) {
            getSuffixByCode(suffixId);
        }
        getEmailsForSuffix(suffixId)
    }, []);

    const [newEmail, setNewEmail] = useState('');
    const [isValid, setisValid] = useState(true);

    const suffixEmailDeleteClickedHandler = (mail) => {
        removeEmailForSuffix(match.params.notificationId, mail);
    }

    const addEmailClickedHandler = () => {
        if (isEmailValid(newEmail)) {
            addEmailForSuffix(match.params.notificationId, newEmail);
            setNewEmail('');
            setisValid(true);
        } else {
            setisValid(false);
        }
    }

    const renderSuffixEmails = () => {
        if (suffixEmails) {
            return (
                suffixEmails.map((mail, i) =>
                    <tr key={i}>
                        <td>{mail}
                            <Button size="small" variation="outlined" variant="transparent" className="float-right" onClick={() => suffixEmailDeleteClickedHandler(mail)}>
                                <FontAwesomeIcon icon="trash" />
                            </Button>
                        </td>
                    </tr>)
            )
        }
    }

    return (<>
        <PageHeader text={suffixDetail ? suffixDetail.longDescription : 'Loading...'} />
        <div className='card card-body'>
            {suffixDetail ?
                <div className='row'>
                    {ReadonlyTextField('Name', suffixDetail.name)}
                    {ReadonlyTextField('Description', suffixDetail.shortDescription)}
                    {ReadonlyTextField('Country Code', suffixDetail.address.country.code)}
                    {ReadonlyTextField('City', suffixDetail.address.country.city)}
                    {ReadonlyTextField('Country Name', suffixDetail.address.country.code)}
                </div>
                : null}

            <hr />
            <h5 className="text-center">Email Info</h5>
            <Row>
                <h6 className="ml-3">Number of Definitions : {suffixEmails ? suffixEmails.length : 0}</h6>
            </Row> 
            <Table>
                <tbody>
                    {renderSuffixEmails()}
                    <tr>
                        <td>
                            <Row>
                                <Col md="10">
                                    <TextField
                                        hintText="Add new e-mail"
                                        value={newEmail}
                                        onChange={(e) => setNewEmail(e.target.value)}
                                        errorMessage={!isValid ? 'Please provide a valid e-mail' : ''}
                                        size="small"
                                    />
                                </Col>
                                <Col md="2">
                                    <Button className="input-block" variant="outline-secondary" variation="secondary" onClick={addEmailClickedHandler}> Add </Button>
                                </Col>
                            </Row>
                        </td>
                    </tr>
                </tbody>
            </Table>
            <LoadingIndicator id="notificationGetEmailsForSuffix" show={!suffixEmails} />
        </div>
    </>)
}
const mapStateToProps = (state, props) => {
    return {
        suffixDetail: state.notification.suffixList ? state.notification.suffixList.find(x => x.code === props.match.params.notificationId) : state.notification.suffixDetail,
        suffixEmails: state.notification.suffixEmails
    }
}

export default connect(mapStateToProps, { getSuffixByCode, getEmailsForSuffix, addEmailForSuffix, removeEmailForSuffix })(NotificationDetail)