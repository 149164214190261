export const TYPES = {
    DELIVERY: 'Delivery',
    ARRIVAL: 'Arrival'
}

export const STATUS = {
    INTRANSIT: 'Intransit',
    ARRIVED: 'Arrived',
    SHORTRECEIVED: 'ShortReceived',
    RECEIVED: 'Received',
    SHIPPED: 'Shipped'
}