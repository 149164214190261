import postSync, { get, post } from '../../core/httpClientSync';
import actionTypes from "../type/reservedSlot";
import { toast } from 'react-toastify';
import { epochToShortDate } from '../../core/epochToDateConverter';

const apiUrl = 'astrid-slotbooking-api/api/reservation';

export const getRules = () => async dispatch => {
    const endPoint = `${apiUrl}/getall`;
    const getRulesResponse = await get(endPoint, 'addOrEditReservedSlotRule');
    if (getRulesResponse.isSuccess) {
        const rules = getRulesResponse.response.result.map(x => {
            return {
                ...x,
                carrier: x.company
            }
        });

        dispatch({
            type: actionTypes.RULES_RETRIEVED,
            payload: rules
        })
    }
}

export const getRule = (rules, id) => async dispatch => {
    dispatch({
        type: actionTypes.RULE_RETRIEVED,
        payload: rules && rules.length > 0 ? rules.find(x => x.id === id) : null
    })
}

export const saveRule = ruleModel => async dispatch => {
    const endPoint = `${apiUrl}/save`;
    const createRuleResponse = await postSync(endPoint, ruleModel, 'addOrEditReservedSlotRule');

    if (createRuleResponse.isSuccess) {
        dispatch({
            type: actionTypes.RULE_SAVED
        });

        toast.success(`Rule has been ${!ruleModel.id ? 'created' : 'updated'} successfully.`);
        const resultObject = createRuleResponse.response;
        if (resultObject.item2 && resultObject.item2.length > 0) {
            toast.warn(`But there are not available slots in the following dates: ${resultObject.item2.map(x => epochToShortDate(x)).join(', ')}`);
        }
    }
}

export const setRuleFilter = filterModel => async dispatch => {
    dispatch({
        type: actionTypes.RULE_FILTER_SET,
        payload: filterModel
    });
}

export const deleteRule = ruleId => async dispatch => {
    const endPoint = `${apiUrl}/delete?id=${ruleId}`;
    const deleteRuleResponse = await postSync(endPoint);
    if (deleteRuleResponse.isSuccess) {
        dispatch({
            type: actionTypes.RULE_DELETED,
        })
    }
}

export const finalizeAddOrUpdate = () => async dispatch => {
    dispatch({
        type: actionTypes.FINALIZE_ADD_OR_UPDATE,
    })
}

export const getClients = () => async dispatch => {
    const response = await post(`xms-company-api/api/company/search`, { isClient: true, pageSize: 1000 }, 'addOrEditReservedSlotRule');
    response.isSuccess && dispatch({
        type: actionTypes.CLIENTS_RETRIEVED,
        payload: response.response.map(item => ({ value: item.id, text: item.name }))
    })
}

export const getServicesByClientAndType = (clientId, type) => async dispatch => {
    const response = await get(`xms-slm-api/api/contractdefinition/getservicesbycompanyandtype/${clientId}/${type}`, 'addOrEditReservedSlotRule');
    response.isSuccess && dispatch({
        type: actionTypes.SERVICES_RETRIEVED,
        payload: response.response.result.services.map(item => ({ value: item.serviceId, text: item.serviceName }))
    })
}

export const getBusinessAreasByClient = (clientId) => async dispatch => {
    const endPoint = 'astrid-slotbooking-api/api/businessarea/getbycompany';
    const getBusinessAreasResponse = await get(`${endPoint}/${clientId}`, 'addOrEditReservedSlotRule');
    const businessAreasItems = getBusinessAreasResponse.response.result.map(item => ({ value: item.id, text: item.name }));
    if (getBusinessAreasResponse.isSuccess) {
        dispatch({
            type: actionTypes.CLIENT_BUSINESS_AREAS_RECEIVED,
            payload: businessAreasItems
        });
    }
};

export const getUnitTypes = () => async dispatch => {
    const endPoint = 'astrid-slotbooking-api/api/booking/getunittypes';
    const getUnitTypesResponse = await get(endPoint, 'addOrEditReservedSlotRule');

    const unitTypeItems = getUnitTypesResponse.response.result.map(item => ({ value: item.id, text: item.name }));
    if (getUnitTypesResponse.isSuccess) {
        dispatch({
            type: actionTypes.UNIT_TYPES_RETRIEVED,
            payload: unitTypeItems
        });
    }
};

export const getBusinessAreaDetails = businessAreaId => async dispatch => {
    const response = await get(`astrid-slotbooking-api/api/calendar/getslottypebybusinessarea/${businessAreaId}`, 'addOrEditReservedSlotRule');
    if (response.isSuccess) {
        dispatch({
            type: actionTypes.BUSINESS_AREA_DETAILS_RECEIVED,
            payload: { isDynamicTimeSlot: response.response.result === 'Dynamic' }
        })
    }
}

export const getReservationWarnings = () => async dispatch => {
    const endpoint = 'astrid-slotbooking-api/api/reservation/checkreservationstatusfornextweek';
    
    const getReservationWarningsResponse = await get(endpoint, 'getReservationWarnings');

    if(getReservationWarningsResponse.isSuccess) {
        console.log(getReservationWarningsResponse.response.result);
        dispatch({
            type: actionTypes.WARNINGS_RECEIVED,
            payload: getReservationWarningsResponse.response.result
        })
    }
}