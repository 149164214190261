import React from 'react';
import { connect } from 'react-redux';
import { Modal, Col, Row, Card } from 'react-bootstrap';
import { Button } from '@dfds-ui/react-components';
import { epochSecondToLocalDateTime } from '../../core/utcToEpochConverter.js';

const ModalMessageDetail = (props) => {

    //parent
    const { modalData, onHide } = props;

    const operation = modalData.operation;
    const result = modalData.result;

    const renderCol = (text, shortText, value) => {
        return (
            <Col xs={6} className="mb-1">
                <Row className="d-none d-lg-block">
                    <Col>
                        <span className="text-title">{text}</span>
                    </Col>
                </Row>
                <Row>
                    <Col className="">
                        <span className="d-lg-none text-title">{shortText}: </span>
                        <span className="color-text-grey">{value}</span>
                    </Col>
                </Row>
            </Col>
        )
    }

    const renderInput = (text, shortText, value) => {
        var x = "";
        try {
            x = JSON.stringify(JSON.parse(value), null, 2);
        } catch (error) {
            x = value;
        }
        return (
            <Col xs={12} className="mb-1">
                <Row className="d-none d-lg-block">
                    <Col>
                        <span className="text-title">{text}</span>
                    </Col>
                </Row>
                <Row>
                    <Col className="">
                        <span className="d-lg-none text-title">{shortText}: </span>
                        <textarea className={'input-block'} rows={5} defaultValue={x} />
                    </Col>
                </Row>
            </Col>
        )
    }

    return (
        <Modal show={true} onHide={onHide} centered size="xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    {`${operation.id} Message Detail`}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Col>
                    <Row>
                        {renderCol('Task Id', '', operation.id)}
                        {renderCol('Subject', '', operation.unitCode)}
                    </Row>
                    <Row>
                        {renderCol('Type', '', operation.type)}
                        {renderCol('Status', '', operation.status)}
                    </Row>
                </Col>
                <Card style={{ marginTop: '15px' }}>
                    <Card.Header>
                        <Row className={'align-items-center'}>
                            <Col>
                                <h4 className={'text-center'}>Result</h4>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            {renderCol('Type', '', result.type)}
                            {renderCol('Direction', '', result.direction)}
                        </Row>
                        <Row>
                            {renderCol('Status', '', result.status)}
                        </Row>
                        <Row>
                            {renderCol('Created Date', '', epochSecondToLocalDateTime(result.createdAt).toLocaleDateString())}
                            {renderCol('Updated Date', '', result.updatedAt ? epochSecondToLocalDateTime(result.updatedAt).toLocaleDateString() : '')}
                        </Row>
                        <Row>
                            {renderInput('Log', '', result.log)}
                        </Row>
                        <Row>
                            {renderInput('Input', '', result.input)}
                        </Row>
                        <Row>
                            {renderInput('Output', '', result.output)}
                        </Row>
                    </Card.Body>
                </Card>
            </Modal.Body>

            <Modal.Footer>
                <Button variation="outlined" onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = ({ }) => {
    return {

    }
}

const mapActionToProps = {
}

export default connect(mapStateToProps, mapActionToProps)(ModalMessageDetail);