const transportActionTypes = Object.freeze({
    STATE_CLEARED: 'astrid/transport/STATE_CLEARED',
    OPERATION_RECEIVED: 'astrid/transport/OPERATION_RECEIVED',
    LIST_RECEIVED: 'astrid/transport/LIST_RECEIVED',
    UNLINKED_LIST_RECEIVED: 'astrid/transport/UNLINKED_LIST_RECEIVED',
    LINKED_LIST_RECEIVED: 'astrid/transport/LINKED_LIST_RECEIVED',
    LINKED: 'astrid/transport/LINKED',
    UNLINKED: 'astrid/transport/UNLINKED',
    SAVED: 'astrid/transport/SAVED',
    EDITED: 'astrid/transport/EDITED',
    DELETED: 'astrid/transport/DELETED'
});

export default transportActionTypes;