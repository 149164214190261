import React from 'react';
import { connect } from 'react-redux';
import { Modal, Row, Col } from 'react-bootstrap';
import moment from 'moment';

function ModalReservedSlotRuleWarnings({ showModal = false, onHide, warnings }) {
  return (
    <Modal show={showModal} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Col>
          <Row>
            <Col>
              <Modal.Title>
              Following Business Areas have more reservations than gate count for next week
              </Modal.Title>
            </Col>
          </Row>
        </Col>
      </Modal.Header>

      <Modal.Body>
        <Col style={{ textAlign: 'center' }}>
                {warnings.reservationWarnings && warnings.reservationWarnings.map((warning) => (
                    <div>
                      <h5 style={{textDecorationLine:'underline'}}>{moment.unix(warning.item1).format('LL')}</h5>
                      {warning.item2.map((warningItem) => (
                        <p>{warningItem.businessAreaName} - { moment.utc().startOf('day').add(warningItem.startMinutesSinceMidnight, 'minutes').format('HH:mm')}</p>
                      ))}
                    </div>
                  ))}
        </Col>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

// get the state of the shipment overview table pass it to value of filters, pass it to the table
const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalReservedSlotRuleWarnings);

// TODO: NICE TO HAVE: keep last used filters, only if they have been searched with
