import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    createVoyage,
    readVoyage,
    updateVoyage,
    deleteVoyages,
} from '../store/ducks/voyages/actionCreators';

import styles from './Voyage.module.css';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { localDateTimeToEpochSecond, epochSecondToLocalDateTime } from '../core/utcToEpochConverter.js';
import DatePicker from "react-datepicker";
import LoadingIndicator from "../component/LoadingIndicator/LoadingIndicator";
import PolicyGroupAccessHoc from "../RoutedComponents/Routes/PolicyGroupAccessHoc";

// TODO: extract to utilities
function useSearchParams() {
    return new URLSearchParams(useLocation().search);
}

function Voyage({
    createVoyage,
    readVoyage,
    updateVoyage,
    deleteVoyages,
    voyages,
}) {
    // HISTORY, LOCATION, SEARCH PARAMS, URL PARAMS
    let history = useHistory();
    let { mode } = useParams();
    let searchParams = useSearchParams();
    let [idsString] = [
        searchParams.get("ids"),
    ];
    let ids = idsString ? idsString.split(",") : []; // was thuIds
    // end HISTORY, LOCATION, SEARCH PARAMS, URL PARAMS

    // STATE
    const [voyages1, setVoyages1] = useState({ isRequesting: false, items: [{}], errors: [] });
    // end STATE

    // GET STATE AFTER COMPONENT RENDERS
    useEffect(() => {
        // we do not use currently a batch mode for this view, so skip that case and read one item from endpoint
        if (ids.length === 1) {
            readVoyage({ id: ids[0] }, 'processVoyage');
        }
        // TODO: 1
    }, [ids.length]);
    // end GET STATE AFTER COMPONENT RENDERS

    // SET STATE FROM PROPS
    useEffect(() => {
        if (voyages && mode === 'voyage') {
            // mode: mode 'voyage' means mode for read and update
            const voyage = voyages.items.find(item => item.id === ids[0]);
            voyage && setVoyages1(state => ({ ...state, items: [voyage] }));
        } else if (voyages && mode === 'update-many') {
            const voyagesChecked = voyages.items.filter(item => ids.includes(item.id));
            setVoyages1(state => ({ ...state, items: voyagesChecked }));
        }
    }, [voyages]);
    // end SET STATE FROM PROPS

    // ON CHANGE
    const onChange = (e) => {
        e.persist();
        if (e.target.type === "datetime-local") {
            setVoyages1(state => ({
                ...state,
                items: state.items.map(item => ({ ...item, [e.target.id]: localDateTimeToEpochSecond(new Date(e.target.value)) })),
            }));
        } else {
            setVoyages1(state => ({
                ...state,
                items: state.items.map(item => ({ ...item, [e.target.id]: e.target.value })),
            }));
        }
    }

    const onChangeDatepicker = (key, epochSeconds, event) => {
        setVoyages1(state => ({ ...state, items: state.items.map(item => ({ ...item, [key]: epochSeconds })) }));
    }
    // end ON CHANGE

    // VALUE
    // returns value of the prop if all the items, voyages, has the same value for a given prop, else it returns another value
    const valueSelector = (propName, variousValuesDecorator = '', undefinedValueDecorator = '') => {
        return hasVariousValues(propName) ?
            variousValuesDecorator :
            (voyages1.items && voyages1.items.length > 0 && voyages1.items[0][propName] || undefinedValueDecorator); // undefined value for react complains about undefined and null on controlled input element values
    }

    const hasVariousValues = (propName) => {
        return voyages1.items && !voyages1.items.every((item, i, arr) => item[propName] === arr[0][propName]);
    }
    // end VALUE

    // SUBMIT
    const onSubmit = async (e) => {
        e.preventDefault();
        if (ids.length === 1) {
            // Update
            // since this view we do not apply batch update, our endpoint support single item
            updateVoyage({ voyage: voyages1.items[0] }, 'processVoyage');
        } else {
            // Create
            await createVoyage({ voyage: voyages1.items[0] }, 'processVoyage');
            history.replace('/voyages');
            // history.replace(`/voyages/voyage?ids=${voyages1.items[0].id}`); // TODO better assign response on prev line then use the id from there. for this return from action creator to make it work. TODO: maybe using history.replace is a better idea.
        }
    }
    // end SUBMIT

    // DELETE
    const onClickDelete = async () => {
        if (window.confirm(`Are you sure you want to delete ${ids.length > 1 ? `these ${ids.length} items` : 'this item'}?`)) {
            await deleteVoyages({ voyageIds: ids }, 'processVoyage'); // can navigate without waiting the response as well, in that case do not use "await"
            history.replace(`/voyages`); // since the item is deleted and will return not found we may replace the navigation from the history, otherwise history.push() or history.goBack() can be used as well
        }
    }
    // end DELETE

    // BACK
    const onClickBack = () => history.push(`/voyages`);
    // end BACK

    // REROUTE if voyage with voyage id  not even exist reroute to create
    // TODO: or show a message and call to action like create action
    /*
    useEffect(() => {
        // may need a truthy check
        // cause back button to need two clicking. instead can use a text to show no such connection point exist with this id at return of render. it can be a toast too and the navigation. or pop the history of this state or just use history.push instead og the history.goBack

        if (!isCreateMode && connectionPoint === undefined) {
            history.push(`/voyages/create`);
            // TODO: tell user not exist and can create instead
        }

    }, [mode]); // connection point from selector
    */
    // end REROUTE

    // VALIDATION
    // end VALIDATION



    {/* <header>
                    <div className={styles.title}>
                        <div title="Back"><ArrowBack className={styles.iconButton} onClick={onClickBack}></ArrowBack></div>
                        <h4>Voyage</h4>
                        {
                            voyages.isRequesting ?
                                <Spinner instant /> :
                                ids.length === 0 ?
                                    <span style={{ margin: '4px', marginLeft: '6px' }}>Create item</span> :
                                    (voyages1.items && <span style={{ margin: '4px', marginLeft: '6px' }}>View or Edit {voyages1.items.length} {voyages1.items.length > 1 ? `items` : `item`}</span>)
                        }
                    </div>
                    <div className={styles.functions}>
                        <div title="Delete"><Delete className={styles.iconButton} onClick={onClickDelete}></Delete></div>
                    </div>
                </header>
 */}

    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                <Button style={{ fontSize: '14px', marginBottom: '17px' }} className='color-action-blue' variant="transparent" onClick={() => history.push(`/voyages`)}>
                                    <FontAwesomeIcon icon="chevron-left" size='lg' />
                                    <span style={{ paddingLeft: '10px' }}>
                                        {`Back to Voyages`}
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <main>
                    <section>
                        {
                            !voyages.isRequesting &&

                            <form onSubmit={onSubmit}>
                                <Row>
                                    <Col xs="12" sm="6" md="6" lg="4">
                                        <label htmlFor="voyageName" className={styles.label}>Voyage Name (*)</label><br></br>
                                        <input id="voyageName" required value={valueSelector('voyageName')} onChange={onChange} /><br></br>
                                        <small style={{ visibility: hasVariousValues('voyageName') ? 'visible' : 'hidden' }} className="form-text text-warning">Various values</small>
                                    </Col>

                                    <Col xs="12" sm="6" md="6" lg="4">
                                        <label htmlFor="vesselName" className={styles.label}>Vessel Name (*)</label><br></br>
                                        <input id="vesselName" required value={valueSelector('vesselName')} onChange={onChange} /><br></br>
                                        <small style={{ visibility: hasVariousValues('vesselName') ? 'visible' : 'hidden' }} className="form-text text-warning">Various values</small>
                                    </Col>

                                    <Col xs="12" sm="6" md="6" lg="4">
                                        <label htmlFor="departureLocation" className={styles.label}>Departure Location (*)</label><br></br>
                                        <input id="departureLocation" required value={valueSelector('departureLocation')} onChange={onChange} /><br></br>
                                        <small style={{ visibility: hasVariousValues('departureLocation') ? 'visible' : 'hidden' }} className="form-text text-warning">Various values</small>
                                    </Col>

                                    <Col xs="12" sm="6" md="6" lg="4">
                                        <label htmlFor="arrivalLocation" className={styles.label}>Arrival Location (*)</label><br></br>
                                        <input id="arrivalLocation" required value={valueSelector('arrivalLocation')} onChange={onChange} /><br></br>
                                        <small style={{ visibility: hasVariousValues('arrivalLocation') ? 'visible' : 'hidden' }} className="form-text text-warning">Various values</small>
                                    </Col>

                                    <Col xs="12" sm="6" md="6" lg="4">
                                        <label htmlFor="departureDate" className={styles.label}>Departure Date & Time</label><br></br>
                                        <DatePicker
                                            id="departureDate"
                                            selected={valueSelector('departureDate') ? epochSecondToLocalDateTime(valueSelector('departureDate')) : ''}
                                            onChange={(dateTime, event) => onChangeDatepicker('departureDate', localDateTimeToEpochSecond(dateTime), event)}
                                            dateFormat="MM/dd/yyyy HH:mm"
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeCaption="Time"
                                            timeIntervals={1}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            todayButton="Today"
                                            placeholderText='Select Date & Time'
                                            type='text'
                                            dropdownMode="select"
                                        />
                                        <small style={{ visibility: hasVariousValues('invoiceDate') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                    </Col>

                                    <Col xs="12" sm="6" md="6" lg="4">
                                        <label htmlFor="arrivalDate" className={styles.label}>Arrival Date & Time</label><br></br>
                                        <DatePicker
                                            id="arrivalDate"
                                            selected={valueSelector('arrivalDate') ? epochSecondToLocalDateTime(valueSelector('arrivalDate')) : ''}
                                            onChange={(dateTime, event) => onChangeDatepicker('arrivalDate', localDateTimeToEpochSecond(dateTime), event)}
                                            dateFormat="MM/dd/yyyy HH:mm"
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeCaption="Time"
                                            timeIntervals={1}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            todayButton="Today"
                                            placeholderText='Select Date & Time'
                                            type='text'
                                            dropdownMode="select"
                                        />
                                        <small style={{ visibility: hasVariousValues('invoiceDate') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                    </Col>

                                    <Col xs="12" sm="6" md="6" lg="4">
                                        <label htmlFor="externalNumber" className={styles.label}>External No (*)</label><br></br>
                                        <input id="externalNumber" required value={valueSelector("externalNumber")} onChange={onChange} /><br></br>
                                        <small style={{ visibility: hasVariousValues('externalNumber') ? 'visible' : 'hidden' }} className="form-text text-warning">Various values</small>
                                    </Col>

                                    <Col xs="12" sm="6" md="6" lg="4">
                                        <label htmlFor="internalNumber" className={styles.label}>Internal No (*)</label><br></br>
                                        <input id="internalNumber" required value={valueSelector('internalNumber')} onChange={onChange} /><br></br>
                                        <small style={{ visibility: hasVariousValues('internalNumber') ? 'visible' : 'hidden' }} className="form-text text-warning">Various values</small>
                                    </Col>

                                    <Col xs="12" sm="6" md="6" lg="4">
                                        <label htmlFor="createdDate" className={styles.label}>Created At</label><br></br>
                                        {/*
                                                <input id="createdDate" readOnly type="datetime-local" value={epochToShortDateTime(valueSelector('createdDate'), 'YYYY-MM-DDTHH:mm')} onChange={onChange} /><br></br>
                                                */}
                                        <DatePicker
                                            id="createdDate"
                                            selected={valueSelector('createdDate') ? epochSecondToLocalDateTime(valueSelector('createdDate')) : ''}
                                            onChange={(dateTime, event) => onChangeDatepicker('createdDate', localDateTimeToEpochSecond(dateTime), event)}
                                            dateFormat="MM/dd/yyyy HH:mm"
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeCaption="Time"
                                            timeIntervals={1}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            placeholderText=''
                                            type='text'
                                            dropdownMode="select"
                                            todayButton="Today"
                                            readOnly
                                        />
                                        <small style={{ visibility: hasVariousValues('createdDate') ? 'visible' : 'hidden' }} className="form-text text-warning">Various values</small>
                                    </Col>

                                    <Col xs="12" sm="6" md="6" lg="4">
                                        <label htmlFor="createdBy" className={styles.label}>Created By</label><br></br>
                                        <input id="createdBy" readOnly value={valueSelector('createdBy')} onChange={onChange} /><br></br>
                                        <small style={{ visibility: hasVariousValues('createdBy') ? 'visible' : 'hidden' }} className="form-text text-warning">Various values</small>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col></Col>

                                    <Col xs="12" sm="6" md="6" lg="4" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <PolicyGroupAccessHoc componentName="VoyageUpdateButtonHOC">
                                            <input type="submit" value={ids.length > 0 ? 'Update' : 'Create'} />
                                        </PolicyGroupAccessHoc>
                                    </Col >
                                </Row >
                            </form >
                        }

                    </section >

                </main >
            </Card.Body>
            <LoadingIndicator id="processVoyage" show={voyages.isRequesting} />
        </Card>
    );
}

const mapStateToProps = ({ voyages }) => {
    return {
        voyages,
        // connectionPoint: definitions.connectionPoints.items.find(item => item.id === ownProps.match.params.connectionPointId),
    };
};

const mapDispatchToProps = {
    createVoyage,
    readVoyage,
    updateVoyage,
    deleteVoyages,
};

export default connect(mapStateToProps, mapDispatchToProps)(Voyage);

// TODO:
// 1. // can implement shouldRequest or optimistic actuality here or in action creator. without passing connectionPoints here. passing url param to action creator.
// cannot just pass the connectionPoint ready from the parent and not request since routes are exact.

// when the connection Id not exist indicate that

// suggestion: place the badges at top so that whether they appear or not the margin of the first inputs are the same. they can be input fields of themselves

// group closely similar input fields closer

// make some rows with narrower inputs more than two columns to preserve vertical space

// The status section, next to the title, can show a status message like "Create <entity>" on create mode of the views