import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    searchAssociates,
    searchSuffixes,
    filterCurrencies,
    filterCountries
} from '../store/ducks/definitions/actionCreators';
import { readArticlesByIds } from '../store/ducks/articles/actionCreators';
import { getPackageTypeList, updateThus } from '../store/action/thuDetailAction';
import { InputDatalist } from '../component/InputDatalist/InputDatalist';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import { Row, Col, Card, Button as BootstrapButton } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { localDateTimeToEpochSecond, epochSecondToLocalDateTime } from '../core/utcToEpochConverter.js';
import { getValue, setValue } from '../core/utility';
import { useURLSearchParams } from '../core/hooks';
import styles from './Thu.module.css';
import { useIfNotNavigatedTo } from "../core/hooks";
import { resetAllFilters } from '../store/ducks/articles/actionCreators';
import { RightLink } from '@dfds-ui/icons'
import { Button } from '@dfds-ui/react-components'
import PolicyGroupAccessHoc from "../RoutedComponents/Routes/PolicyGroupAccessHoc";

function Thu({
    readArticlesByIds,
    articles,
    searchAssociates,
    associates,
    getPackageTypeList,
    packageTypeList,
    searchSuffixes,
    suffixes,
    filterCurrencies,
    currencies,
    filterCountries,
    countries,
    updateThus,
    resetAllFilters,
}) {
    // HISTORY, LOCATION, SEARCH PARAMS, URL PARAMS
    let history = useHistory();
    let searchParams = useURLSearchParams();

    const [searchParamsString, setSearchParamsString] = useState();
    const [articleIds, setArticleIds] = useState();
    useEffect(() => {
        if (searchParams) {
            setSearchParamsString(searchParams.toString());
            setArticleIds(searchParams.getAll("idList"));
        }
    }, [searchParams]);

    useIfNotNavigatedTo(['/articles'], resetAllFilters);
    // end HISTORY, LOCATION, SEARCH PARAMS, URL PARAMS

    // STATE
    const [thusLocal, setThusLocal] = useState([]);
    // end STATE

    // GET STATE AFTER COMPONENT RENDERS
    useEffect(() => {
        if (searchParamsString) {
            readArticlesByIds(searchParamsString, 'loader-thu-detail'); // add a process indicator
        }
    }, [searchParamsString, readArticlesByIds]);
    // end GET STATE AFTER COMPONENT RENDERS

    // SET STATE FROM PROPS
    useEffect(() => {
        // set thusLocal here accordingly depending on articles state form store, by filtering it with the search params articleIds
        if (articleIds) {
            const articlesFound = articles.items.filter(item => articleIds.includes(item.id));
            setThusLocal(articlesFound);
        }
    }, [articleIds, articles]);
    // end SET STATE FROM PROPS

    const [supplierQuery, setSupplierQuery] = useState('');
    useEffect(() => {
        if (supplierQuery && supplierQuery.length > 1) {
            searchAssociates({ queryStringValue: supplierQuery });
        }
    }, [supplierQuery, searchAssociates]);


    // ON CHANGE
    const onChange = (e) => {
        var list = [...thusLocal];
        for (let i = 0; i < list.length; i++) {
            setValue(list[i], e.target.id, e.target.value);
        }
        setThusLocal(list);
    }

    /* Destination, Suffix */
    const [destinationPropAux, setDestinationPropAux] = useState('');
    const onChangeDestinationProp = e => {
        setDestinationPropAux(e.target.value);
    }
    // validate
    useEffect(() => {
        const destination = suffixes.items.find(({ name, code }) => [name, code].includes(destinationPropAux));
        if (destination) {
            setThusLocal(state => state.map(item => ({ ...item, destination, destinationId: destination.id })));
        }
    }, [destinationPropAux, suffixes.items]);
    /* END Destination, Suffix */

    const onChangeDatepicker = (key, epochSeconds) => {
        setThusLocal(thusLocal.map(item => ({ ...item, [key]: epochSeconds })));
    }

    const onChangeSupplierInputDatalist = (event) => {
        // if the typed/selected text include matches any existing assoc set shipment state fields with those values from found assoc
        const matched = associates.items.find(item => {
            return (
                [item.code, item.name].some((element) => element === event.target.value)
                ||
                `(${item['code']})${delimiter(item)}${item['name']}` === event.target.value
            );
        });

        if (matched || event.target.value === '') {
            setThusLocal(thusLocal.map(item => ({ ...item, supplierId: matched && matched.id, supplier: matched })));
        }
    }

    const onChangeInputDatalist = (e) => {
        // if the typed/selected text include matches any existing assoc set shipment state fields with those values from found assoc
        // supplierId, packageType, suffix, currency
        let matched;
        if (e.target.id === 'countryOfOrigin') {
            matched = countries.items.find(item => {
                return (
                    [item.id, item.name].some((element) => element === e.target.value)
                    ||
                    e.target.value === `(${item['id']})${delimiter(item)}${item['name']}`
                );
            });
        } else if (e.target.id === 'currency') {
            matched = currencies.items.find(item => {
                return (
                    [item.id, item.name].some((element) => element === e.target.value)
                    ||
                    e.target.value === `(${item['id']})${delimiter(item)}${item['name']}`
                );
            });
        }

        if (matched) {
            // set id, and name fields: e.g. supplierId and supplier
            // deformat here
            setThusLocal(thusLocal.map(item => ({ ...item, [e.target.id]: matched.id })));
        } else {
            // deformat here
            setThusLocal(thusLocal.map(item => ({ ...item, [e.target.id]: '' })));
        }
    }
    // end ON CHANGE


    // VALUE
    // returns value of the prop if all the items, thus, has the same value for a given prop, else it returns another value
    const valueSelector = (
        propName,
        variousValuesDecorator = '',
        falsyValueDecorator = (propName) => (thusLocal.length > 0 && [0, '', false].includes(getValue(thusLocal[0], propName))) ? getValue(thusLocal[0], propName) : ''
    ) => {
        return hasVariousValues(propName) ?
            variousValuesDecorator :
            ((thusLocal.length > 0 && getValue(thusLocal[0], propName)) || falsyValueDecorator(propName));
    }

    const hasVariousValues = (propName, thuList = thusLocal) => {
        return !thuList.every((item, i, arr) => getValue(item, propName) === getValue(arr[0], propName));
    }
    // end VALUE


    // FORMAT
    const format = (data) => {
        if (data) {
            const codePart = data.code ? `(${data.code})` : '';
            const namePart = data.name ? `${data.name}` : '';
            const delimiter = ', ';
            return `${codePart}${delimiter}${namePart}`;
        } else
            return "";
    }

    const delimiter = (previousElement) => previousElement ? ',  ' : '';
    // end FORMAT

    // SUBMIT
    const onSubmit = (e) => {
        e.preventDefault();
        if (isValid) {
            updateThus(thusLocal, 'loader-thu-detail');
        }
    }
    // end SUBMIT

    // DELETE
    /*
    const onClickDelete = async () => {
    if (confirm('Are you sure you want to delete this item?')) {
        await deleteConnectionPoint({ connectionPointId });
        history.goBack();
    }
    }
    */
    // end DELETE

    // BACK
    const onClickBack = () => history.goBack(); // if goBack not desirable, history.push('/connection-points');
    // end BACK

    // REROUTE if connectionPoint with connectionPointId not even exist reroute to create
    // TODO: or show a message and call to action like create action
    /*
    useEffect(() => {
        // may need a truthy check
        // cause back button to need two clicking. instead can use a text to show no such connection point exist with this id at return of render. it can be a toast too and the navigation. or pop the history of this state or just use history.push instead og the history.goBack

        if (!isCreateMode && connectionPoint === undefined) {
            history.push(`/thus/create`);
            // TODO: tell user not exist and can create instead
        }

    }, [mode]); // connection point from selector
    */
    // end REROUTE

    // VALIDATION
    const [requiredFields, setRequiredFields] = useState(["externalId", "externalCode", "supplierId", "actualMeasurement.itemQuantity.value"]);
    const [isValid, setIsValid] = useState();
    useEffect(() => {
        const isInvalid = requiredFields.some(item => valueSelector(item) === '');
        setIsValid(!isInvalid);
    }, [thusLocal, supplierQuery, requiredFields, valueSelector]);
    // end VALIDATION

    // RENDER HELPERS
    const renderHoldStatusLabel = (isHeld) => {
        let variant = '';
        let textContent = '';
        switch (isHeld) {
            case true:
                variant = 'red';
                textContent = 'On Hold';
                break;
            case false:
                variant = 'green';
                textContent = 'Not On Hold';
                break;
            case '':
                variant = "variousValues";
                textContent = 'Various Hold Values';
                break;
            default:
                variant = "gray";
                textContent = 'Unknown';
        }
        return (
            <span className={`${styles.label} ${styles[variant]}`}>
                {
                    textContent
                }
            </span>
        );
    }

    const renderStatusLabel = (status) => {
        let variant = '';

        switch (status) {
            case 'Shipped':
                variant = 'blue'
                break;
            case 'Picked':
                variant = 'green'
                break;
            case 'WaitingPick':
                variant = "indigo"
                break;
            case 'Instock':
                variant = "purple"
                break;
            case 'WaitingConfirmation':
                variant = "orange"
                break;
            case '':
                variant = "variousValues"
                break;
            default:
                variant = "gray"
        }
        return <span className={`${styles.label} ${styles[variant]}`}>{status || 'Various Status values'}</span>;
    }

    const renderInputDatalist = ({
        value,
        onChange,
        fetchDataFunction,
        data,
        renderDatalistOptions,
        label,
        name,
        placeholder,
        autoComplete,
        queryParameterName = "query"
    }) => {
        return (
            <div>
                <InputDatalist
                    data={data}
                    value={value}
                    onChange={onChange}
                    fetchDataFunction={fetchDataFunction}
                    isRequesting={suffixes.isRequesting}
                    renderDatalistOptions={renderDatalistOptions}
                    labelText={label}
                    name={name}
                    placeholder={placeholder}
                    autoComplete={autoComplete}
                    queryParameterName={queryParameterName}
                    labelClassName={styles.label}
                />
                <small style={{ visibility: hasVariousValues('supplierId') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
            </div>
        );
    }
    // end RENDER HELPERS

    const renderRedirectButton = (id) => {
        if (id) {
            return (
                <Button className="input-block" variation="outlined" icon={<RightLink />} iconAlign="right" size="small" onMouseDown={(e) => mouseDownHandler(e, `/shipment/${id}`)} onClick={() => routeToShipmentDetail(id)}>Go Shipment</Button>
            )
        }

        return (
            <span>There is no binded shipment</span>
        )

    }

    const routeToShipmentDetail = (shipmentId) => {
        history.push(`/shipment/${shipmentId}`);
    }

    const mouseDownHandler = (event, path) => {
        if (event.button === 1) {
            window.open(path, '_blank');
        }
    }

    return (
        <Card>
            <Card.Body>

                <Row>
                    <Col>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                <BootstrapButton style={{ fontSize: '14px', marginBottom: '17px' }} className='color-action-blue' variant="transparent" onClick={onClickBack}>
                                    <FontAwesomeIcon icon="chevron-left" size='lg' />
                                    <span style={{ paddingLeft: '10px' }}>
                                        {`Back`}
                                    </span>
                                </BootstrapButton>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <main>
                            <section>
                                {
                                    <form onSubmit={onSubmit}>
                                        <Row>
                                            <Col>
                                                {renderHoldStatusLabel(valueSelector('isHeld'))}
                                                {renderStatusLabel(valueSelector('status'))}
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="externalId" className={styles.label}>(S), (M), (G) Serial No *</label><br></br>
                                                <input id="externalId" value={valueSelector('externalId')} readOnly /><br></br>
                                                <small style={{ visibility: hasVariousValues('externalId') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>

                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="externalCode" className={styles.label}>(P) Part No *</label><br></br>
                                                <input id="externalCode" value={valueSelector('externalCode')} readOnly /><br></br>
                                                <small style={{ visibility: hasVariousValues('externalCode') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>
                                            <Col xs="12" sm="6" md="6" lg="4">
                                                {renderInputDatalist({
                                                    label: 'Supplier Code or Name *',
                                                    name: ['supplierId', 'supplier'],
                                                    value: `${valueSelector('supplier').code ? `(${valueSelector('supplier').code})` : ''
                                                        }${delimiter(valueSelector('supplier').code)}${valueSelector('supplier') ? valueSelector('supplier').name : ''}`,
                                                    onChange: onChangeSupplierInputDatalist,
                                                    data: associates.items,
                                                    fetchDataFunction: searchAssociates,
                                                    queryParameterName: "queryStringValue",
                                                    renderDatalistOptions: (item, i) => <option value={`(${item['code']})${delimiter(item)}${item['name']}`} key={i} />
                                                })}
                                            </Col>

                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="expectedMeasurement.itemQuantity.value" className={styles.label}>(Q) Expected Quantity </label><br></br>
                                                <input id="expectedMeasurement.itemQuantity.value" type="number" min={0} value={valueSelector('expectedMeasurement.itemQuantity.value')} onChange={onChange} /><br></br>
                                                <small style={{ visibility: hasVariousValues('expectedMeasurement.itemQuantity.value') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>

                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="actualMeasurement.itemQuantity.value" className={styles.label}>(Q) Actual Quantity *</label><br></br>
                                                <input id="actualMeasurement.itemQuantity.value" type="number" min={0} value={valueSelector('actualMeasurement.itemQuantity.value')} onChange={onChange} /><br></br>
                                                <small style={{ visibility: hasVariousValues('actualMeasurement.itemQuantity.value') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>

                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="destinationId" className={styles.label}>Suffix</label><br></br>
                                                <InputDatalist
                                                    inputId="destinationId"
                                                    data={suffixes.items}
                                                    value={valueSelector('destination.name')}
                                                    onChange={onChangeDestinationProp}
                                                    fetchDataFunction={searchSuffixes}
                                                    visualSize={"small"}
                                                    hasIcon={false}
                                                    name={'destinationId'}
                                                    renderDatalistOptions={(item, i) => <option value={item["name"]} key={i} />}
                                                />
                                                <small style={{ visibility: hasVariousValues('destinationId') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>

                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="expectedMeasurement.volume.value" className={styles.label}>Expected Volume</label><br></br>
                                                <input id="expectedMeasurement.volume.value" type="number" min={0} step="any" value={valueSelector('expectedMeasurement.volume.value')} onChange={onChange} /><br></br>
                                                <small style={{ visibility: hasVariousValues('expectedMeasurement.volume.value') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>

                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="expectedMeasurement.grossWeight.value" className={styles.label}>Expected Gross Weight</label><br></br>
                                                <input id="expectedMeasurement.grossWeight.value" type="number" min={0} step="any" value={valueSelector('expectedMeasurement.grossWeight.value')} onChange={onChange} /><br></br>
                                                <small style={{ visibility: hasVariousValues('expectedMeasurement.grossWeight.value') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>

                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="expectedMeasurement.weight.value" className={styles.label}>Expected Net Weight</label><br></br>
                                                <input id="expectedMeasurement.weight.value" type="number" min={0} step="any" value={valueSelector('expectedMeasurement.weight.value')} onChange={onChange} /><br></br>
                                                <small style={{ visibility: hasVariousValues('expectedMeasurement.weight.value') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>


                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="actualMeasurement.volume.value" className={styles.label}>Actual Volume</label><br></br>
                                                <input id="actualMeasurement.volume.value" type="number" min={0} step="any" value={valueSelector('actualMeasurement.volume.value')} onChange={onChange} /><br></br>
                                                <small style={{ visibility: hasVariousValues('actualMeasurement.volume.value') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>

                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="actualMeasurement.grossWeight.value" className={styles.label}>Actual Gross Weight</label><br></br>
                                                <input id="actualMeasurement.grossWeight.value" type="number" min={0} step="any" value={valueSelector('actualMeasurement.grossWeight.value')} onChange={onChange} /><br></br>
                                                <small style={{ visibility: hasVariousValues('actualMeasurement.grossWeight.value') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>

                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="actualMeasurement.weight.value" className={styles.label}>Actual Net Weight</label><br></br>
                                                <input id="actualMeasurement.weight.value" type="number" min={0} step="any" value={valueSelector('actualMeasurement.weight.value')} onChange={onChange} /><br></br>
                                                <small style={{ visibility: hasVariousValues('actualMeasurement.weight.value') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>

                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="deliveryNote" className={styles.label}>Delivery Note</label><br></br>
                                                <input id="deliveryNote" value={valueSelector('deliveryNote')} onChange={onChange} /><br></br>
                                                <small style={{ visibility: hasVariousValues('deliveryNote') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>

                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="pricePerItem" className={styles.label}>Price per Item</label><br></br>
                                                <input id="pricePerItem" type="number" min={0} step="any" value={valueSelector('pricePerItem')} onChange={onChange} /><br></br>
                                                <small style={{ visibility: hasVariousValues('pricePerItem') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>

                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="totalValue" className={styles.label}>Total Value</label><br></br>
                                                <input id="totalValue" type="number" min={0} steps="any" value={(valueSelector('actualMeasurement.itemQuantity.value') * valueSelector('pricePerItem')) || valueSelector('totalValue')} readOnly /><br></br>
                                                <small style={{ visibility: hasVariousValues('totalValue') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>

                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="currency" className={styles.label}>Currency</label><br></br>
                                                <InputDatalist
                                                    inputId="currency"
                                                    data={currencies.items}
                                                    value={valueSelector('currency')}
                                                    onChange={onChangeInputDatalist}
                                                    fetchDataFunction={filterCurrencies}
                                                    visualSize={"small"}
                                                    hasIcon={false}
                                                    name={'currency'}
                                                    renderDatalistOptions={(item, i) => <option value={`(${item['id']})${delimiter(item.id)}${item['name']}`} key={i} />}
                                                />
                                                <small style={{ visibility: hasVariousValues('currency') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>

                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="packageType" className={styles.label}>Package Type</label><br></br>
                                                <InputDatalist
                                                    inputId="packageType"
                                                    data={packageTypeList}
                                                    value={valueSelector('packageType')}
                                                    onChange={onChange}
                                                    fetchDataFunction={getPackageTypeList}
                                                    visualSize={"small"}
                                                    hasIcon={false}
                                                    name={'packageType'}
                                                    renderDatalistOptions={(item, i) => <option value={item["type"]} key={i} />}
                                                />
                                                <small style={{ visibility: hasVariousValues('packageType') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>

                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="packageRefNo" className={styles.label}>Package Reference No</label><br></br>
                                                <input id="packageRefNo" value={valueSelector('packageRefNo')} onChange={onChange} /><br></br>
                                                <small style={{ visibility: hasVariousValues('packageRefNo') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>

                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="commodityCode" className={styles.label}>HS Code</label><br></br>
                                                <input id="commodityCode" value={valueSelector('commodityCode')} onChange={onChange} /><br></br>
                                                <small style={{ visibility: hasVariousValues('commodityCode') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>

                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="commodityType" className={styles.label}>Commodity Type</label><br></br>
                                                <input id="commodityType" value={valueSelector('commodityType')} onChange={onChange} /><br></br>
                                                <small style={{ visibility: hasVariousValues('commodityType') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>

                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="countryOfOrigin" className={styles.label}>Country of Origin</label><br></br>
                                                <InputDatalist
                                                    inputId="countryOfOrigin"
                                                    data={countries.items}
                                                    value={valueSelector('countryOfOrigin')}
                                                    onChange={onChangeInputDatalist}
                                                    fetchDataFunction={filterCountries}
                                                    visualSize={"small"}
                                                    hasIcon={false}
                                                    name={'countryOfOrigin'}
                                                    renderDatalistOptions={(item, i) => <option value={`(${item['id']})${delimiter(item.id)}${item['name']}`} key={i} />}
                                                />
                                                <small style={{ visibility: hasVariousValues('countryOfOrigin') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>

                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="invoiceNumber" className={styles.label}>Invoice Number</label><br></br>
                                                <input id="invoiceNumber" value={valueSelector('invoiceNumber')} onChange={onChange} /><br></br>
                                                <small style={{ visibility: hasVariousValues('invoiceNumber') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>
                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="invoiceDate" className={styles.label}>Invoice Date</label><br></br>
                                                <DatePicker
                                                    id="invoiceDate"
                                                    selected={valueSelector('invoiceDate') ? epochSecondToLocalDateTime(valueSelector('invoiceDate')) : ''}
                                                    onChange={(dateTime, e) => { onChangeDatepicker('invoiceDate', localDateTimeToEpochSecond(dateTime), e) }}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    todayButton="Today"
                                                    placeholderText='Select Date'
                                                    type='text'
                                                    dropdownMode="select"
                                                />
                                                <small style={{ visibility: hasVariousValues('invoiceDate') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>

                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="customerOrderNumber" className={styles.label}>Customer Order No</label><br></br>
                                                <input id="customerOrderNumber" value={valueSelector('customerOrderNumber')} onChange={onChange} /><br></br>
                                                <small style={{ visibility: hasVariousValues('customerOrderNumber') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>

                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="poNumber" className={styles.label}>PO No</label><br></br>
                                                <input id="poNumber" value={valueSelector('poNumber')} onChange={onChange} /><br></br>
                                                <small style={{ visibility: hasVariousValues('poNumber') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>

                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="batchNumber" className={styles.label}>Batch No</label><br></br>
                                                <input id="batchNumber" value={valueSelector('batchNumber')} onChange={onChange} /><br></br>
                                                <small style={{ visibility: hasVariousValues('batchNumber') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>
                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="isRush" className={styles.label}>Rush?</label><br></br>
                                                <select id="isRush" value={valueSelector('isRush')} onChange={onChange} style={{ padding: 6 }}>
                                                    <option key={""} value={""}>Select one</option>
                                                    {
                                                        [{ optionValue: true, optionKey: 'True' }, { optionValue: false, optionKey: 'False' }]
                                                            .map(({ optionValue, optionKey }) => <option value={optionValue} key={optionKey}>{optionKey}</option>)
                                                    }
                                                </select>
                                                <small style={{ visibility: hasVariousValues('isRush') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>

                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="isValidated" className={styles.label}>Is Validated</label><br></br>
                                                <input id="isValidated" value={valueSelector('isValidated')} readOnly /><br></br>
                                                <small style={{ visibility: hasVariousValues('isValidated') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>

                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="createdAt" className={styles.label}>Created At</label><br></br>
                                                <DatePicker
                                                    id="createdAt"
                                                    selected={valueSelector('createdDate') ? epochSecondToLocalDateTime(valueSelector('createdDate')) : ''}
                                                    onChange={(dateTime, event) => onChangeDatepicker('createdAt', localDateTimeToEpochSecond(dateTime), event)}
                                                    dateFormat="MM/dd/yyyy HH:mm"
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    todayButton="Today"
                                                    placeholderText='Select Date'
                                                    type='text'
                                                    dropdownMode="select"
                                                    readOnly
                                                />

                                                <small style={{ visibility: hasVariousValues('createdDate') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>

                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="createdBy" className={styles.label}>Created By</label><br></br>
                                                <input id="createdBy" value={valueSelector('createdBy')} readOnly /><br></br>
                                                <small style={{ visibility: hasVariousValues('createdBy') ? 'visible' : 'hidden' }} className={styles.variousValues}>Various values</small>
                                            </Col>

                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="arrivalShipmentId" className={styles.label}>Arrival Shipment</label><br></br>
                                                {renderRedirectButton(valueSelector('arrivalShipmentId'))}
                                            </Col>

                                            <Col xs="12" sm="6" md="6" lg="4">
                                                <label htmlFor="deliveryShipmentId" className={styles.label}>Delivery Shipment</label><br></br>
                                                {renderRedirectButton(valueSelector('deliveryShipmentId'))}
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col></Col>

                                            <Col xs="12" sm="6" md="6" lg="4" style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                                <PolicyGroupAccessHoc componentName="ThuUpdateHOC">
                                                    <input type="submit" value={'Update'} disabled={!isValid} />
                                                </PolicyGroupAccessHoc>
                                            </Col>
                                        </Row>
                                    </form>
                                }

                            </section>

                        </main>

                    </Col>
                </Row>

            </Card.Body>
            <LoadingIndicator id='loader-thu-detail' />
        </Card>
    );
}

const mapStateToProps = ({ articles, definitions, thuDetailReducer }, ownProps) => {
    return {
        articles,
        associates: definitions.associates,
        packageTypeList: thuDetailReducer.packageTypeList,
        suffixes: definitions.suffixes,
        currencies: definitions.currencies,
        countries: definitions.countries,
        thus: thuDetailReducer.thuList,
    };
}

const mapDispatchToProps = {
    readArticlesByIds,
    searchAssociates,
    searchSuffixes,
    filterCurrencies,
    filterCountries,
    getPackageTypeList,
    updateThus,
    resetAllFilters,
}

export default connect(mapStateToProps, mapDispatchToProps)(Thu);
