import React, { useEffect, useState } from 'react';
import PageHeader from '../component/PageHeader/PageHeader';
import { Row, Col, Card } from 'react-bootstrap';
import { Button, ButtonStack, Container, Column, SelectField } from '@dfds-ui/react-components';
import { InputDatalist } from '../component/InputDatalist/InputDatalist';
import history from '../core/history';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import { getThu, getPackageTypeList, update } from '../store/action/thuDetailAction';
import { connect } from 'react-redux'; 
import { toast } from 'react-toastify';
import { getValue, setValue } from '../core/utility';

const ThuRemeasure = (props) => {

    const { getThu, thuDetail, update, packageTypeList, getPackageTypeList } = props;

    const { id } = props.match.params

    const [volume, setVolume] = useState(0);
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    const [length, setLength] = useState(0);
    const [weight, setWeight] = useState(0);
    const [netWeight, setNetWeight] = useState(0);
    const [packageType, setPackageType] = useState('');

    useEffect(() => {
        getThu(id, 'loader-thu-detail');
        getPackageTypeList();
    }, [])

    useEffect(() => {
        if (thuDetail) {
            setPackageType(getValue(thuDetail, 'packageType'));
            setVolume(getValue(thuDetail, 'actualMeasurement.volume.value', 0));
            setHeight(getValue(thuDetail, 'actualMeasurement.size.height', 0));
            setWidth(getValue(thuDetail, 'actualMeasurement.size.width', 0));
            setLength(getValue(thuDetail, 'actualMeasurement.size.length', 0));
            setWeight(getValue(thuDetail, 'actualMeasurement.grossWeight.value', 0));
            setNetWeight(getValue(thuDetail, 'actualMeasurement.weight.value', 0));
        }
    }, [thuDetail])

    const onPackageTypeChange = (type) => {
        var packageType = packageTypeList.find(s => s.type === type);
        setVolume(packageType.volume);
        setPackageType(type);
    }

    const onThuRemeasureSave = () => {
        const size = height * width * length > 0 ?
            {
                height: height,
                width: width,
                length: length
            } : null;

        thuDetail.packageType = packageType;
        setValue(thuDetail, "actualMeasurement.volume.value", volume);
        setValue(thuDetail, "actualMeasurement.grossWeight.value", weight);
        setValue(thuDetail, "actualMeasurement.weight.value", netWeight);
        setValue(thuDetail, "actualMeasurement.size", size);

        update(thuDetail, 'loader-thu-detail');
    }

    const onCalculateValue = () => {
        var calcVolume = height * width * length;
        calcVolume > 0 ? setVolume(calcVolume) : toast.error('Please enter size information correctly');
    }

    const renderEditThu = () => {
        if (thuDetail === null) return

        return (
            <>
                <Card >
                    <Card.Body>
                        <Row>
                            <Column style={{
                                padding: '5px'
                            }}>
                                <Row>
                                    <Col md={12}>
                                        <div>
                                            <label>(S),(M),(G) Serial Number (*)</label>
                                            <div>
                                                <input type='text' className='form-control' readOnly value={thuDetail.externalId} ></input>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className='form-group'>
                                            <label>PackageType</label>
                                            <SelectField selected={thuDetail.packageType} onChange={(e) => onPackageTypeChange(e.target.value)} size='small' className='selectbox-labelless'>
                                                <option value="">Select</option>
                                                {packageTypeList.map((item) => (
                                                    <option value={item.type} key={item.type}>
                                                        {item.type}
                                                    </option>
                                                ))}
                                            </SelectField>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div>
                                            <label>Volume (M3)</label>
                                            <div>
                                                <input type='number' onKeyDown={(evt) => evt.keyCode === 69 && evt.preventDefault()} value={volume} onChange={(e => setVolume(e.target.value))} onKeyUp={(e => setVolume(e.target.value))}></input>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>
                                <Row style={{
                                    backgroundColor: '#BFDFF4',
                                    padding: '10px',
                                    margin: '1px'
                                }}>
                                    <Col md={6}>
                                        <div>
                                            <label>Height (M)</label>
                                            <div>
                                                <input type='number' onKeyDown={(evt) => evt.keyCode === 69 && evt.preventDefault()} className='form-control' value={height} onChange={(e => setHeight(e.target.value))} onKeyUp={(e => setHeight(e.target.value))} ></input>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div>
                                            <label>Length (M)</label>
                                            <div>
                                                <input type='number' onKeyDown={(evt) => evt.keyCode === 69 && evt.preventDefault()} className='form-control' value={length} onChange={(e => setLength(e.target.value))} onKeyUp={(e => setLength(e.target.value))} ></input>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div>
                                            <label>Width (M)</label>
                                            <div>
                                                <input type='number' onKeyDown={(evt) => evt.keyCode === 69 && evt.preventDefault()} className='form-control' value={width} onChange={(e => setWidth(e.target.value))} onKeyUp={(e => setWidth(e.target.value))} ></input>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <br></br>
                                        <Button variation="primary" onClick={() => onCalculateValue()} style={{
                                            width: '100%'
                                        }}>Calculate Volume</Button>
                                    </Col>
                                </Row>
                            </Column>
                            <Column style={{
                                padding: '5px'
                            }}>
                                <Row>
                                    <Col md={12}>
                                        <div>
                                            <label>(P) Part Number (*)</label>
                                            <div>
                                                <input type='text' className='form-control' readOnly value={thuDetail.externalCode} ></input>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div>
                                            <label>Gross Weight (KG)</label>
                                            <div>
                                                <input type='number' onKeyDown={(evt) => evt.keyCode === 69 && evt.preventDefault()} value={weight} onChange={(e => setWeight(e.target.value))} onKeyUp={(e => setWeight(e.target.value))} ></input>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div>
                                            <label>Net Weight (KG)</label>
                                            <div>
                                                <input type='number' onKeyDown={(evt) => evt.keyCode === 69 && evt.preventDefault()} value={netWeight} onChange={(e => setNetWeight(e.target.value))} onKeyUp={(e => setNetWeight(e.target.value))} ></input>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                            </Column>
                        </Row>
                        <Row>
                            <Column style={{
                                padding: '5px'
                            }}>
                                <Row>
                                    <Col md={12}>
                                        <div>
                                            <label>Inbound Shipment</label>
                                            <div>
                                                <input type='text' className='form-control' readOnly value={thuDetail.arrivalShipmentId} ></input>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>
                            </Column>
                            <Column style={{
                                padding: '5px'
                            }}>
                                <Row>
                                    <Col md={12}>
                                        <div>
                                            <label>Outbound Shipment</label>
                                            <div>
                                                <input type='text' className='form-control' readOnly value={thuDetail.deliveryShipmentId} ></input>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Column>
                        </Row>
                        <Row>
                            {renderButtons()}
                        </Row>
                        <Container fluid>

                        </Container>
                    </Card.Body>
                </Card>

            </>
        )

    }
    const renderButtons = () => {
        return (
            <Col>
                <ButtonStack align="right">
                    <Button variation="outlined" onClick={() => history.goBack()}>Cancel</Button>
                    <Button variation="primary" disabled={thuDetail.status == "Shipped"} onClick={() => onThuRemeasureSave()}>Done</Button>
                </ButtonStack>
            </Col>
        )
    }

    const renderInputDatalist = ({
        value,
        onChange,
        fetchDataFunction,
        data,
        renderDatalistOptions,
        label,
        name,
        placeholder,
        autoComplete,
        error,
        md = 6,
        defaultValue
    }) => {
        return (
            <div>
                <label>{label}</label>
                <div>
                    <InputDatalist
                        data={data}
                        value={value}
                        onChange={onChange}
                        fetchDataFunction={fetchDataFunction}
                        data={data}
                        renderDatalistOptions={renderDatalistOptions}
                        name={name}
                        placeholder={placeholder}
                        autoComplete={autoComplete}
                    />
                </div>
            </div>

        );
    }

    return (
        <div>
            <PageHeader text='Thu Remeasurement' />
            <LoadingIndicator id='loader-thu-detail' hide />
            {renderEditThu()}
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        thuDetail: state.thuDetailReducer.thuDetail,
        packageTypeList: state.thuDetailReducer.packageTypeList,
    }
}
const mapActionToProps = {
    getThu,
    getPackageTypeList,
    update
}
export default connect(mapStateToProps, mapActionToProps)(ThuRemeasure);