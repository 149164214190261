import actionTypes from "./actionTypes";
import { getValue } from "../../../core/utility";

// If any filter has Value property empty string, "", that means it is not defined, that is better than passing base values null or undefined when using Controlled React Components. We filter wrt those in related action: readShipmentsOverview(filters)
const initialFilters = [
    { "Name": "voyageExternalNumber", "Value": "", "Operator": "=" },
    { "Name": "operationInfos.id", "Value": "", "Operator": "=" },
    { "Name": "operationInfos.unitCode", "Value": "", "Operator": "=" },
    { "Name": "carrierBookingReference", "Value": "", "Operator": "=" },
    { "Name": "containerClosingDate", "Value": "", "Operator": ">=" },
    { "Name": "containerClosingDate", "Value": "", "Operator": "<=" },
    { "Name": "bookingReference", "Value": "", "Operator": "=" },
    { "Name": "customerReference", "Value": "", "Operator": "=" },
    { "Name": "loadingType", "Value": "", "Operator": "=" },
    { "Name": "vgmCutoffDate", "Value": "", "Operator": ">=" },
    { "Name": "vgmCutoffDate", "Value": "", "Operator": "<=" },
    { "Name": "emptyReleaseDate", "Value": "", "Operator": ">=" },
    { "Name": "emptyReleaseDate", "Value": "", "Operator": "<=" },
    { "Name": "loadedReturnDate", "Value": "", "Operator": ">=" },
    { "Name": "loadedReturnDate", "Value": "", "Operator": "<=" },
    { "Name": "voyage.vesselName", "Value": "", "Operator": "=" },
    { "Name": "voyage.voyageName", "Value": "", "Operator": "=" },
    { "Name": "voyage.departureLocation", "Value": "", "Operator": "=" },
    { "Name": "voyage.arrivalLocation", "Value": "", "Operator": "=" },
    { "Name": "voyage.departureDate", "Value": "", "Operator": ">=" },
    { "Name": "voyage.departureDate", "Value": "", "Operator": "<=" },
    { "Name": "voyage.arrivalDate", "Value": "", "Operator": ">=" },
    { "Name": "voyage.arrivalDate", "Value": "", "Operator": "<=" },
];

const initialState = {
    isRequesting: false,
    items: [],
    errors: [],
    filters: initialFilters,
    filtersAux: {},
};

/** CONTAINER_BOOKINGS */
function reduceContainerBookings(state = initialState, action) {
    switch (action.type) {
        // CREATE
        case actionTypes.CREATE_CONTAINER_BOOKING_REQUESTING:
            return { ...state, isRequesting: true };
        case actionTypes.CREATE_CONTAINER_BOOKING_SUCCESS:
            return {
                ...state,
                isRequesting: false,
                items: [...state.items, action.payload.booking],
                errors: []
            };
        case actionTypes.CREATE_CONTAINER_BOOKING_FAILURE:
            return { ...state, isRequesting: true, errors: action.payload };
        // end CREATE
        // READ, singular
        case actionTypes.READ_CONTAINER_BOOKING_REQUESTING:
            return { ...state, isRequesting: true };
        case actionTypes.READ_CONTAINER_BOOKING_SUCCESS:
            return {
                ...state,
                isRequesting: false,
                items: state.items.reduce((acc, item) => {
                    return acc.find(newItem => newItem.id === item.id) ?
                        [...acc] :
                        [...acc, item]
                }, [action.payload.booking]), // insert if not exist
                errors: []
            };
        case actionTypes.READ_CONTAINER_BOOKING_FAILURE:
            return { ...state, isRequesting: false, errors: action.payload };
        // end READ, singular
        // READ plural
        case actionTypes.READ_CONTAINER_BOOKINGS_REQUESTING:
            return { ...state, isRequesting: true };
        case actionTypes.READ_CONTAINER_BOOKINGS_SUCCESS:
            return {
                ...state,
                isRequesting: false,
                items: action.payload,
                errors: []
            };
        case actionTypes.READ_CONTAINER_BOOKINGS_FAILURE:
            return { ...state, isRequesting: false, errors: action.payload };
        // end READ plural
        // UPDATE
        case actionTypes.UPDATE_CONTAINER_BOOKING_REQUESTING:
            return { ...state, isRequesting: true };
        case actionTypes.UPDATE_CONTAINER_BOOKING_SUCCESS:
            return {
                ...state,
                isRequesting: false,
                items: state.items.map(item => {
                    return item.id === action.payload.booking.id ?
                        { ...item, ...action.payload.booking } :
                        item
                }),
                errors: []
            };
        case actionTypes.UPDATE_CONTAINER_BOOKING_FAILURE:
            return { ...state, isRequesting: false, errors: action.payload };
        // end UPDATE
        // DELETE plural
        case actionTypes.DELETE_CONTAINER_BOOKINGS_REQUESTING:
            return { ...state, isRequesting: true };
        case actionTypes.DELETE_CONTAINER_BOOKINGS_SUCCESS:
            return {
                ...state,
                isRequesting: false,
                items: state.items.filter(item => !action.payload.bookingIds.includes(item.id)),
                errors: []
            };
        case actionTypes.DELETE_CONTAINER_BOOKINGS_FAILURE:
            return { ...state, isRequesting: false, errors: action.payload };
        // end DELETE plural
        // FILTER
        case actionTypes.SET_FILTER:
            const { Name, Value, Operator } = action.payload;
            return {
                ...state, filters: state.filters.map(filter => (filter.Name === Name && filter.Operator === Operator) === true
                    ? { ...filter, Value: Value }
                    : filter
                )
            };
        case actionTypes.SET_FILTERS:
            return {
                ...state, filters: action.payload
            };
        case actionTypes.SET_FILTERS_AUX:
            return {
                ...state, filtersAux: action.payload
            };
        case actionTypes.RESET_ALL_FILTERS:
            return { ...state, filters: initialFilters, filtersAux: {} };
        // end FILTER
        // SORT
        case actionTypes.SORT_CONTAINER_BOOKINGS:
            return {
                ...state,
                items: [...state.items].sort((a, b) => {
                    if (action.payload.dataType === 'string') {
                        const comparison = getValue(a, action.payload.sortBy) > getValue(b, action.payload.sortBy);
                        const flag = comparison ? 1 : -1;
                        return action.payload.sortDirection === 'ASC' ? (-flag) : flag;
                    } else if (action.payload.dataType === 'number') {
                        const difference = getValue(a, action.payload.sortBy) - getValue(b, action.payload.sortBy);
                        const flag = difference > 0 ? 1 : -1;
                        return action.payload.sortDirection === 'ASC' ? (-flag) : flag;
                    } else if (action.payload.dataType === 'boolean') {
                        const comparison = getValue(a, action.payload.sortBy) > getValue(b, action.payload.sortBy);
                        const flag = comparison ? 1 : -1;
                        return action.payload.sortDirection === 'ASC' ? (-flag) : flag;
                    }
                })
            };
        // end SORT
        default:
            return state;
    }
}
/* end CONTAINER_BOOKINGS */


export default reduceContainerBookings;
