import post, { get } from '../../core/httpClientSync';
import { spinnerService } from '@simply007org/react-spinners';
import actions from '../type/additionalGate';
import moment from 'moment';
import { utcToEpoch } from '../../core/utcToEpochConverter';

const apiUrl = 'astrid-slotbooking-api/api/booking';
const timeSlotApiUrl = 'astrid-slotbooking-api/api/gatescheduler';
const businessAreaApiUrl = 'astrid-slotbooking-api/api/businessarea';

export const getTimeSlots = (businessAreaId, date) => async (dispatch) => {
  let currentDate = new Date();
  currentDate.setDate(currentDate.getTime() + 1);

  var momentUtc = date ? utcToEpoch(date) : Math.floor(moment().add(1, 'days').valueOf() / 1000);

  let endPoint = `${apiUrl}/getallslotsfordate?businessAreaId=${businessAreaId}&date=${momentUtc}`;

  spinnerService.show('bookingTimeSlot');
  const getSlotsForDateResult = await get(endPoint, 'openAdditionalGates');
  if (getSlotsForDateResult.isSuccess) {
    dispatch({
      type: actions.TIME_SLOTS_RETRIEVED,
      payload: getSlotsForDateResult.response.result
    });
  } else {
    dispatch({ type: actions.TIME_SLOTS_WERE_CLEARED });
  }
};

export const openAdditionalGates = (
  selectedDate,
  timeSlots,
  additionalGateNumber,
  businessAreaId
) => async (dispatch) => {
  let endPoint = `${timeSlotApiUrl}/increasequotasfortimeslot`;
  const openAdditionalGatesResponse = await post(
    endPoint,
    {
      date: selectedDate,
      timeSlots: timeSlots,
      quota: additionalGateNumber,
      businessAreaId: businessAreaId
    },
    'openAdditionalGates'
  );
  if (openAdditionalGatesResponse.isSuccess) {
    dispatch({ type: actions.ADDITIONAL_GATES_SUCCESSFULLY_OPENED });
  }
};

export const clearState = () => async (dispatch) => {
  dispatch({ type: actions.CLEAR_STATE });
};

export const getBusinessAreas = () => async (dispatch) => {
  const endPoint = `${businessAreaApiUrl}/getall`;
  const getBusinessAreasResponse = await get(endPoint, 'openAdditionalGates');
  if (getBusinessAreasResponse.isSuccess) {
    dispatch({
      type: actions.BUSINESS_AREAS_RETRIEVED,
      payload: getBusinessAreasResponse.response.result
    });
  }
};
