import actionTypes from "./actionTypes";
import { post, get } from '../../../core/httpClientSync';
import { closeModals } from '../../action/modalActions';

// TODO: add a should fetch check since when we go from a list to list item page and back to list it fetches again and puts the spinner, this is  little bit frustrating and loss of time: just show the component with the old data and fetch at the same time and merge or replace the state with newly arrived results. and this fetch should not block whole list, maybe can show still a spinner somewhere else, maybe in a toast.

const url = 'xms-shipment-api/api/articles';
//  const url = 'http://localhost:5000/api/articles';

/** READ ARTICLES */
function readArticles(payload, loadingIndicatorId) {
    return async function (dispatch) {
        dispatch(readArticlesRequesting());

        const messyPayload = {
            properties: payload,
            size: 50
        }
        const result = await post(`${url}/search`, messyPayload, loadingIndicatorId);

        if (result.isSuccess) {
            // TODO: there need to be a better convention to know if the response is empty and successful or failed
            dispatch(readArticlesSuccess(result.response.items));
        } else {
            dispatch(readArticlesFailure(result.error));
        }
        dispatch(closeModals());
        return result;
    }
};

function readArticlesRequesting() {
    return {
        type: actionTypes.READ_ARTICLES_REQUESTING
    }
}

function readArticlesSuccess(payload) {
    return {
        type: actionTypes.READ_ARTICLES_SUCCESS,
        payload
    }
}

function readArticlesFailure(payload) {
    return {
        type: actionTypes.READ_ARTICLES_FAILURE,
        payload
    }
}
/** end READ ARTICLES */


/** READ ARTICLES BY IDs*/
function readArticlesByIds(payload, loadingIndicatorId) {
    return async function (dispatch) {
        dispatch(readArticlesByIdsRequesting());

        const result = await get(`${url}?${payload}`, loadingIndicatorId);

        if (result.isSuccess) {
            // TODO: there need to be a better convention to know if the response is empty and successful or failed
            dispatch(readArticlesByIdsSuccess(result.response.result.articleList));
        } else {
            dispatch(readArticlesByIdsFailure(result.response.errors));
        }
        return result;
    }
};

function readArticlesByIdsRequesting() {
    return {
        type: actionTypes.READ_ARTICLES_BY_IDS_REQUESTING
    }
}

function readArticlesByIdsSuccess(payload) {
    return {
        type: actionTypes.READ_ARTICLES_BY_IDS_SUCCESS,
        payload
    }
}

function readArticlesByIdsFailure(payload) {
    return {
        type: actionTypes.READ_ARTICLES_BY_IDS_FAILURE,
        payload
    }
}
/** end READ ARTICLES BY IDs */


/** FILTER ARTICLES */
function setFilter(payload) {
    return {
        type: actionTypes.SET_FILTER,
        payload
    };
};

function setFilters(payload) {
    return {
        type: actionTypes.SET_FILTERS,
        payload
    };
};

function resetAllFilters() {
    return {
        type: actionTypes.RESET_ALL_FILTERS
    };
};
/** end FILTERS ARTICLES */


/** SORT ARTICLES */
function sortArticles(payload) {
    return {
        type: actionTypes.SORT_ARTICLES,
        payload
    }
};
/** end SORT ARTICLES */


export {
    readArticles,
    readArticlesByIds,
    setFilter,
    setFilters,
    resetAllFilters,
    sortArticles
};