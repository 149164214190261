import React from 'react';
import { connect } from 'react-redux';
import { closeModals } from '../../store/action/modalActions';
import ModalShareDocs from './ModalShareDocs';
import ModalShowImage from './ModalShowImage';
import ModalShowFile from './ModalShowFile';
import ModalConfirmation from './ModalConfirmation';
import ModalInlineInformation from "./ModalInlineInformation";
import ModalPrintLabel from './ModalPrintLabel';
import ModalShowUsersForAssignment from './ModalShowUsersForAssignment';
import ModalHazardousShipment from './ModalHazardousShipment';
import ModalAddShipment from './ModalAddShipment';
import ModalEnterGateForOperation from './ModalEnterGateForOperation';
import ModalReport from './ModalReport';
import ModalMessageDetail from './ModalMessageDetail';
import ModalUpdateAdminNotes from "./ModalUpdateAdminNotes";

const modals = {
    'share-document': ModalShareDocs,
    'show-image': ModalShowImage,
    'show-file': ModalShowFile,
    'inline-information': ModalInlineInformation,
    'show-users-for-assignment': ModalShowUsersForAssignment,
    'show-assign-gate-to-operation': ModalEnterGateForOperation,
    'confirmation': ModalConfirmation,
    'print-label': ModalPrintLabel,
    'addorupdate-hazardous': ModalHazardousShipment,
    'add-shipment': ModalAddShipment,
    'modal-report': ModalReport,
    'modal-message-detail': ModalMessageDetail,
    'modal-update-admin-notes': ModalUpdateAdminNotes,
}

const Modal = ({ modal, closeModals }) => {
    const renderModal = () => {
        if (!modal.showModal) { return; }

        const PopUpModal = modals[modal.modalType];
        return <PopUpModal modalData={modal.modalData} onHide={closeModals} />
    }

    return <>{renderModal()}</>
}

const mapStateToProps = ({ modal }) => { return { modal } }
const mapDispatchToProps = { closeModals };

export default connect(mapStateToProps, mapDispatchToProps)(Modal);