/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import history from '../core/history';
import XmsTable from '../component/XmsTable/XmsTable';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import Page from '../component/Page/Page';
import { getConfigurations } from '../store/action/slotBookingConfigurationActions';

const SlotBookingConfiguration = (props) => {
  //Redux actions
  const { getConfigurations } = props;

  //Redux store
  let { configurations, refresh } = props;

  useEffect(() => {
    if (!configurations || refresh) {
      getConfigurations();
    }
  }, [configurations, refresh, getConfigurations]);

  const newClickedHandler = () => {
    history.push('/booking/configuration/new');
  };

  const editClickedHandler = (configuration) => {
    history.push(`/booking/configuration/${configuration.id}`);
  };

  const tableConfiguration = {
    title: 'Slot Booking Configurations',
    noRowText: 'There are not any configuration to show.',
    hasFilter: false,
    entities: configurations,
    columns: [
      { alias: 'Business Area', name: 'businessAreaName', type: 'string' },
      {
        alias: 'Loading Efficiency (min/thu)',
        name: 'loadingEfficiency',
        type: 'string',
      },
      {
        alias: 'Unloading Efficiency (min/thu)',
        name: 'unloadingEfficiency',
        type: 'string',
      },
      {
        alias: 'Extra Time-Non Bookable (min)',
        name: 'extraTime',
        type: 'number',
      },
      {
        alias: 'Customs Handling Time (min)',
        name: 'customsHandlingTime',
        type: 'number',
      },
    ],
    tableActions: [
      {
        clickEvent: newClickedHandler,
        buttonText: 'Create',
        icon: 'plus',
      },
    ],
    rowActions: [
      {
        title: 'Edit Configuration',
        clickEvent: editClickedHandler,
        icon: 'edit',
      },
    ],
  };

  return (
    <Page backLink="/booking/calendar-rule" backToWhere="Rules">
      <XmsTable config={tableConfiguration} />
      <LoadingIndicator id="addOrEditSlotBookingConfiguration" />
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    configurations: state.slotBookingConfiguration.configurations,
    refresh: state.slotBookingConfiguration.refresh,
  };
};

const mapDispatchToProps = {
  getConfigurations,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SlotBookingConfiguration);
