import companyTypes from '../type/company';

const initialState = {
    company: null,
    companyList: null,
    companyTypeList: [],
    countryList: [],
    clients: []
}
export default (state = initialState, action) => {
    switch (action.type) {
        case companyTypes.COMPANY_GETCOMPANIES_SUCCESS:
            return {
                ...state,
                companyList: action.payload
            }
        case companyTypes.COMPANY_GETCOMPANY_SUCCESS:
            return {
                ...state,
                company: action.payload
            }
        case companyTypes.COMPANY_CLEARCOMPANYSTATE_SUCCESS:
            return { ...initialState };
        case companyTypes.COMPANY_FETCHCOUNTRIES_SUCCESS:
            return { ...state, countryList: action.payload };
        case companyTypes.COMPANY_FETCHCOUNTRIES_ERROR:
            return { ...state, countryList: [] };
        case companyTypes.COMPANY_GETCLIENTS_SUCCESS:
            return { ...state, clients: action.payload };
        case companyTypes.COMPANY_FETCHTYPES_ERROR:
            return { ...state, companyTypeList: [] };
        case companyTypes.COMPANY_FETCHTYPES_SUCCESS:
            return { ...state, companyTypeList: action.payload };
        default:
            return state;
    }

}