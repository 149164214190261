import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@dfds-ui/react-components/button';
import {
  readCompanies,
  deleteCompany,
  deleteCompanies,
  searchCompanies,
  setFilter,
  setFilters,
  resetAllFilters
} from '../store/ducks/definitions/actionCreators-company';

import styles from './Companies.module.css';
import { Card, Row, Col } from 'react-bootstrap';
import { SelectField, ButtonStack } from '@dfds-ui/react-components';
import { toast } from 'react-toastify';
import { capitalCase } from '../core/utility';
import { get } from '../core/httpClientSync';
import { Table } from 'react-bootstrap';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';

function Companies({
  readCompanies,
  deleteCompany,
  deleteCompanies,
  searchCompanies,
  companies,
  setFilter,
  setFilters,
  resetAllFilters,
  filters
}) {
  const lookupURL = 'xms-definition-api/api';

  // GET STATE AFTER COMPONENT RENDERED
  useEffect(() => {
    fetchTypes();
    if (filters) {
      for (let index = 0; index < filters.length; index++) {
        const element = filters[index];
        if (element) {
          const elementValue = element.Value;
          if (element.Name == 'isClient') {
            setIsClientFilter(elementValue || '');
          }

          if (element.Name == 'companyCode') {
            setCompanyCodeFilter(elementValue || '');
          }

          if (element.Name == 'companyName') {
            setCompanyNameFilter(elementValue || '');
          }

          if (element.Name == 'companyType') {
            setCompanyTypeFilter(elementValue || '');
          }
        }
      }

      // let _codeFilter = filters.filter(eachFilter => eachFilter.Name == "companyCode")[0].Value;
      // let _typeFilter = filters.filter(eachFilter => eachFilter.Name == "companyType")[0].Value;
      // let _nameFilter = filters.filter(eachFilter => eachFilter.Name == "companyName")[0].Value;
      // let _isClientFilter = filters.filter(eachFilter => eachFilter.Name == "isClient")[0].Value;

      // let readyToSearch = _codeFilter || _typeFilter || _nameFilter || _isClientFilter;
      // if (!readyToSearch) {
      //     return;
      // }

      const searchPayload = {
        QueryStringValue: null,
        IsClient: isClientFilter,
        Code: companyCodeFilter,
        Name: companyNameFilter,
        CompanyType: companyTypeFilter
      };
      searchCompanies(searchPayload, 'searchCompanies');
    }
  }, []);

  // end GET STATE AFTER COMPONENT RENDERED

  // CREATE
  let history = useHistory();

  const onClickCreate = () => {
    history.push(`/companies/create`);
  };
  // end CREATE

  // UPDATE
  const onClickEdit = () => {
    const checkedConnectionPointId = checkedCompanyIds[0];
    history.push(`/companies/${checkedConnectionPointId}`);
  };
  // end UPDATE

  // DELETE
  const onClickDelete = async () => {
    if (
      window.confirm(
        `Are you sure you want to delete ${
          checkedCompanyIds.length > 1 ? `these ${checkedCompanyIds.length} items` : 'this item'
        }?`
      )
    ) {
      if (checkedCompanyIds.length === 1) {
        const payload = checkedCompanyIds[0];
        await deleteCompany(payload);
        setCheckedCompanyds([]); // uncheck all. TODO: sycn checked Ids with the state with an effect and map so do not need this line and the one below
      } else if (checkedCompanyIds.length > 1) {
        const payload = {
          ThrowExceptionIfOneFails: false,
          Identifiers: checkedCompanyIds
        };
        await deleteCompanies(payload);
        setCheckedCompanyds([]); // uncheck all
      }
    }
  };
  // end DELETE

  // FILTER
  const [areFiltersDisplayed, setAreFiltersDisplayed] = useState(true);

  const toggleFiltersDisplayed = () => {
    // if currently true, it wil be false, so load data back: read connection points
    if (areFiltersDisplayed) {
      //readCompanies();
    }
    setAreFiltersDisplayed((state) => !state);
  };

  const onClickFilters = () => {
    toggleFiltersDisplayed();
  };

  const [companyNameFilter, setCompanyNameFilter] = useState('');
  const [companyCodeFilter, setCompanyCodeFilter] = useState('');
  const [companyTypeFilter, setCompanyTypeFilter] = useState('');
  const [companyTypeDataListFilter, setCompanyTypeDataListFilter] = useState([]);
  const [isClientFilter, setIsClientFilter] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();

    // let readyToSearch = companyNameFilter || companyTypeFilter || companyCodeFilter || isClientFilter;
    // if (!readyToSearch) {
    //     toast.error("Please fill some filters.", { autoClose: 3000 });
    //     return;
    // }

    const searchPayload = {
      QueryStringValue: null,
      IsClient: isClientFilter ? isClientFilter : null,
      Code: companyCodeFilter ? companyCodeFilter : null,
      Name: companyNameFilter ? companyNameFilter : null,
      CompanyType: companyTypeFilter ? companyTypeFilter : null
    };
    searchCompanies(searchPayload, 'searchCompanies');
  };
  // end FILTER

  // SORT
  // Client side sort

  // Server side sort
  // end SORT

  // ON CLICK LIST ITEM, navigate to item view
  // delegated event for performance
  const onClickTableBody = (e) => {
    e.persist();
    if (e.target.type === 'checkbox') {
    } else {
      var _companyId = e.target.id;
      //var clickedCompany = companies.items.filter(eachCompany => eachCompany.id == _companyId)[0];
      history.push(`/companies/${_companyId}`);
    }
  };
  // end ON CLICK LIST ITEM

  // SELECT LIST ITEM/S, with checkboxes
  const [checkedCompanyIds, setCheckedCompanyds] = useState([]);

  async function fetchTypes() {
    const result = await get(`${lookupURL}/lookups?type=CompanyType`, null);
    if (result.isSuccess) {
      let lookup = result.response.result.lookup.values;
      var items = lookup.map((each) => {
        return Object.assign({
          key: each.name,
          value: each.value,
          type: each.name
        });
      });
      setCompanyTypeDataListFilter(items);
    } else {
      setCompanyTypeDataListFilter([]);
    }
  }

  const onChangeCheckbox = (e) => {
    e.persist();
    if (e.target.checked) {
      setCheckedCompanyds((state) =>
        state.includes(e.target.id) ? state : state.concat(e.target.id)
      ); // check one
    } else {
      setCheckedCompanyds((state) => state.filter((item) => item !== e.target.id)); // uncheck one
    }
  };

  const onChangeMasterCheckbox = (e) => {
    e.persist();
    if (e.target.checked) {
      setCheckedCompanyds(companies.items.map((item) => item.id)); // check all
    } else {
      setCheckedCompanyds([]); // uncheck all
    }
  };

  const onClickResetChecks = () => setCheckedCompanyds([]);
  // end SELECT LIST ITEM/S

  const onChangeCompanyTypeFilterChanged = (val) => {
    setFilter({ Name: 'companyType', Value: val, Operator: '=' });
    setCompanyTypeFilter(val);
  };

  const onIsClientFilterChanged = (val) => {
    setIsClientFilter(val);
    setFilter({ Name: 'isClient', Value: val, Operator: '=' });
  };

  const onCompanyNameFilterChanged = (val) => {
    setCompanyNameFilter(val);
    setFilter({ Name: 'companyName', Value: val, Operator: '=' });
  };

  const onCompanyCodeFilterChanged = (val) => {
    setCompanyCodeFilter(val);
    setFilter({ Name: 'companyCode', Value: val, Operator: '=' });
  };

  const renderCompanyTypes = () => {
    return renderSelectBox(
      'Company Type',
      companyTypeFilter,
      companyTypeDataListFilter,
      onChangeCompanyTypeFilterChanged
    );
  };

  const getTextOfCompanyType = (typeAsInt) => {
    if (!companyTypeDataListFilter) {
      return typeAsInt + '';
    }

    var items = companyTypeDataListFilter.filter((eachItem) => eachItem.value == typeAsInt);
    if (items && items.length > 0) {
      return items[0].type;
    }
    return typeAsInt + '';
  };

  const renderIsClientField = () => {
    var isClientDataList = [];
    isClientDataList.push({
      key: '1',
      value: 'true',
      type: 'Yes'
    });
    isClientDataList.push({
      key: '0',
      value: 'false',
      type: 'No'
    });

    return renderSelectBox('Is Client', isClientFilter, isClientDataList, onIsClientFilterChanged);
  };

  const generateGUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : r && 0x3 | 0x8;
      return v.toString(16);
    });
  };

  const renderSelectBox = (label, value, list, onChange) => {
    return (
      <Col xs="4" sm="4" md="3">
        <div className="form-group">
          {/* <label>{label}</label> */}
          <SelectField
            onChange={(e) => onChange(e.target.value)}
            size="small"
            className="selectbox-labelless"
            selected={value}
          >
            <option value="">{label}</option>
            {list.map((item) => (
              <option value={item.value} key={item.key}>
                {capitalCase(item.type)}
              </option>
            ))}
          </SelectField>
        </div>
      </Col>
    );
  };

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <span style={{ fontSize: '1.75rem', paddingLeft: '10px' }}>Companies</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <ButtonStack align="right">
                  <Button variation="primary" key={generateGUID()} onClick={onClickCreate}>
                    Create <FontAwesomeIcon icon="plus" />
                  </Button>
                  {checkedCompanyIds.length == 1 ? (
                    <Button variation="secondary" key={generateGUID()} onClick={onClickEdit}>
                      Edit <FontAwesomeIcon icon="edit" />
                    </Button>
                  ) : (
                    ''
                  )}
                  {checkedCompanyIds.length > 0 ? (
                    <Button variation="secondary" key={generateGUID()} onClick={onClickDelete}>
                      Delete <FontAwesomeIcon icon="trash" />
                    </Button>
                  ) : (
                    ''
                  )}
                  {checkedCompanyIds.length == 0 ? (
                    <Button variation="secondary" key={generateGUID()} onClick={onClickFilters}>
                      Filter <FontAwesomeIcon icon="filter" />
                    </Button>
                  ) : (
                    ''
                  )}
                </ButtonStack>
              </div>
            </div>
            <hr />
          </Col>
        </Row>
        {areFiltersDisplayed && (
          <>
            <Row className={styles.functionSection}>
              <Col style={{ width: '100%', padding: '0px' }}>
                <form onSubmit={onSubmit} style={{ margin: '0px' }}>
                  <Row>
                    <Col xs="4" sm="4" md="3">
                      <input
                        value={companyNameFilter}
                        onChange={(e) => onCompanyNameFilterChanged(e.target.value)}
                        autoFocus
                        placeholder="Type company name"
                      />
                    </Col>
                    <Col xs="4" sm="4" md="3">
                      <input
                        value={companyCodeFilter}
                        onChange={(e) => onCompanyCodeFilterChanged(e.target.value)}
                        placeholder="Type company code"
                      />
                    </Col>
                    {renderCompanyTypes()}
                    {renderIsClientField()}
                    <Col
                      xs="12"
                      sm="12"
                      md="3"
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <input type="submit" value="Filter" style={{ flexGrow: 1 }} />
                    </Col>
                  </Row>
                </form>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col>
            <main>
              <Table className="xms-table" bordered responsive>
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        checked={
                          companies.items.length &&
                          checkedCompanyIds.length === companies.items.length
                        }
                        onChange={onChangeMasterCheckbox}
                      />
                    </th>
                    <th>Id</th>
                    <th>Code</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>IsClient</th>
                  </tr>
                </thead>
                <tbody onClick={onClickTableBody}>
                  {companies.items.map((item) => {
                    return (
                      <tr key={item.id} id={item.id}>
                        <td id={item.id} onClick={(e) => e.stopPropagation()}>
                          <input
                            type="checkbox"
                            id={item.id}
                            checked={checkedCompanyIds.includes(item.id)}
                            onChange={onChangeCheckbox}
                          />
                        </td>
                        <td id={item.id}>{item.id}</td>
                        <td id={item.id}>{item.code}</td>
                        <td id={item.id}>{item.name}</td>
                        <td id={item.id}>{capitalCase(getTextOfCompanyType(item.type))}</td>
                        <td id={item.id}>{item.isClient ? 'Yes' : 'No'}</td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="5">
                      {companies.isRequesting
                        ? 'Requesting'
                        : `Showing ${companies.items.length} items`}
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </main>
          </Col>
        </Row>
      </Card.Body>
      <LoadingIndicator id="searchCompanies" show={companies.isRequesting} />
    </Card>
  );
}

const mapStateToProps = ({ definitions }) => {
  return {
    filters: definitions.companies.filters,
    companies: definitions.companies
  };
};

const mapDispatchToProps = {
  readCompanies,
  deleteCompany,
  deleteCompanies,
  searchCompanies,
  setFilter,
  setFilters,
  resetAllFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
