import React, { useState, useEffect, useRef } from 'react';
import './Scanner.css';
import { scanImageData } from 'zbar.wasm';

const polyfillMediaDevices = (constraints) => {
    // Older browsers might not implement mediaDevices at all, so we set an empty object first
    if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
    }

    // Some browsers partially implement mediaDevices. We can't just assign an object
    // with getUserMedia as it would overwrite existing properties.
    // Here, we will just add the getUserMedia property if it's missing.
    if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {

            // First get ahold of the legacy getUserMedia, if present
            var getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

            // Some browsers just don't implement it - return a rejected promise with an error
            // to keep a consistent interface
            if (!getUserMedia) {
                return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
            }

            // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
            return new Promise(function (resolve, reject) {
                getUserMedia.call(navigator, constraints, resolve, reject);
            });

        }
    }
}

export default function Scanner({ isTurnedOn, isScanning, setRfs }) {
    const [isTurnedOnLocal, setIsTurnedOnLocal] = useState();
    // sync isTurnedOnLocal with external
    useEffect(() => {
        if (isTurnedOn !== undefined) {
            setIsTurnedOnLocal(isTurnedOn);
        }
    }, [isTurnedOn]);

    const [isScanningLocal, setIsScanningLocal] = useState(true);
    // sync isScanningLocal with external
    useEffect(() => {
        if (isScanning !== undefined) {
            setIsScanningLocal(isScanning);
        }
    }, [isScanning]);

    const [ID, setID] = useState();

    const videoRef = useRef();

    const playOrPause = e => {
        // e.preventDefault();
        e.target.paused ? e.target.play() : e.target.pause();
    }

    const [constraints, setConstraints] = useState({
        video: {
            facingMode: 'environment',
        },
        audio: false
    });
    useEffect(() => {
        // here call apply constraints
    }, [constraints]);

    useEffect(() => {
        var id;
        async function init() {
            try {
                if (isTurnedOnLocal && isScanningLocal) {
                    polyfillMediaDevices(constraints);
                    var stream = await navigator.mediaDevices.getUserMedia(constraints);
                    videoRef.current.srcObject = stream;
                    await videoRef.current.play();
                    var sw, sh;
                    sw = videoRef.current.videoWidth;
                    sh = videoRef.current.videoHeight;

                    // get video dimensions to use for drawing video on canvas
                    // set up a canvas for drawing video on
                    const canvas = document.createElement('canvas');
                    canvas.width = videoRef.current.videoWidth;
                    canvas.height = videoRef.current.videoHeight;
                    var ctx = canvas.getContext('2d');
                }

                const getImageData = () => {
                    // TODO: use requestAnimationFrame. it stops when tab is not open, so better
                    ctx.drawImage(videoRef.current, 0, 0);
                    return ctx.getImageData(0, 0, sw, sh);
                };

                const main = async () => {
                    const imageData = getImageData();

                    try {
                        var res = await scanImageData(imageData);
                    } catch (err) {
                        console.error("scanImageData(): scanning error: ", err);
                    }
                    return res.map(item => ({ ...item, rawValue: item.decode() }));
                };

                if (isTurnedOnLocal && isScanningLocal) {
                    id = setInterval(() => {
                        main()
                            .then(results => {
                                setRfs(results)
                            })
                            .catch(err => console.error("main.catch(err): err: ", err))
                    }, 500);
                    setID(id);
                }
            } catch (err) {
                console.error("init catch: err: ", err)
                if (err.name === "NotAllowedError") {
                    // setInfo(err.message) or something similar
                }
            }
        }

        init();
        const videoElement = videoRef.current;
        return () => {
            clearInterval(id);
            videoElement && videoElement.srcObject && videoElement.srcObject.getTracks().forEach(track => track.stop());
        }
    }, [isTurnedOnLocal, isScanningLocal]);

    useEffect(() => {
        if (isTurnedOnLocal === false) {
            clearInterval(ID);
            const videoElement = videoRef.current;
            videoElement && videoElement.srcObject && videoElement.srcObject.getTracks().forEach(track => track.stop());
        }
    }, [isTurnedOnLocal]);

    useEffect(() => {
        if (isScanningLocal === false) {
            clearInterval(ID);
        }
    }, [isScanningLocal]);

    return (
        isTurnedOnLocal ? <video ref={videoRef} /*onTouchStart={playOrPause}*/ onClick={playOrPause} autoPlay muted playsInline></video> : null
    );
}