import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Checkbox } from '@dfds-ui/react-components';
import classes from '../shipments.module.css';
import ThuRow from './ThuRow.js';
import { getValue } from '../../../core/utility';

const ShipmentRow = ({ shipment, counter, showSupplier, showPart, showQuantity, showWeight, showVolume }) => {

    const parentId = `accordion_shipment_${shipment.shipmentNo}`;

    //TODO:do not remove this. we have to find better way
    const [x, xx] = useState([]);

    const onChange = (isChecked) => {
        shipment.isChecked = isChecked;
        shipment.articleList.filter(s => !s.isHeld).map(s => s.isChecked = shipment.isChecked);

        xx([]);

        counter(isChecked);
    }

    const onThuChange = (id, isChecked) => {
        const thu = shipment.articleList.find(s => s.id === id);
        if (thu && thu.status === "Instock") {
            if (shipment.type === "Arrival") {
                shipment.articleList.filter(s => s.id === id && !s.isHeld).map(s => s.isChecked = isChecked);
            }
            else {
                shipment.articleList.filter(s => !s.isHeld).map(s => s.isChecked = isChecked);
            }
        } else {
            shipment.articleList.filter(s => s.id === id && !s.isHeld).map(s => s.isChecked = isChecked);
        }

        if (isChecked) {
            shipment.isChecked = shipment.articleList.filter(s => s.isChecked && !s.isHeld).length === shipment.articleList.length;
        } else {
            shipment.isChecked = false;
        }

        xx([]);
        counter(isChecked);
    }

    return (
        <>
            <div id="accordion" className="text-left">
                <div className='card'>
                    <div className={`card-header ${classes["shipments-card-header"]}`}>
                        <Row className="align-items-center">
                            <Col lg={1} sm={2} xs={3}>
                                <Checkbox className={`${classes["shipments-checkbox"]} `} onChange={(e) => onChange(e.target.checked)} checked={shipment.isChecked}></Checkbox>
                            </Col>
                            <Col lg={11} sm={10} xs={9} className="collapsed" href={`#${parentId}`} data-toggle="collapse" data-parent="#accordion">
                                <Row>
                                    <Col xs={12}>
                                        <span>{shipment.shipmentNo}</span>
                                    </Col>

                                    <Col xs={12}>
                                        <Row>
                                            <Col xs={10}>
                                                <Row>
                                                    <Col md={3} sm={6} xs={12}>
                                                        {`THUs : ${shipment.articleList.filter(s => s.isChecked).length} / ${shipment.articleList.length}`}
                                                    </Col>
                                                    <Col md={2} sm={6} xs={12}>
                                                        <div className={`${classes["isRush"]} `}>{shipment.isRush ? "RUSH" : ""}</div>
                                                    </Col>
                                                    <Col md={3} sm={6} xs={12} className={showSupplier ? 'text-truncate' : 'd-none'} title={shipment.supplier}>
                                                        {getValue(shipment, 'supplier.name')}
                                                    </Col>

                                                    <Col md={2} sm={6} xs={12} className={showWeight ? '' : 'd-none'}>
                                                        {`GW: ${getValue(shipment, 'actualMeasurement.grossWeight.value', 0)}`}
                                                    </Col>

                                                    <Col md={2} sm={6} xs={12} className={showVolume ? '' : 'd-none'}>
                                                        {`V: ${getValue(shipment, 'actualMeasurement.volume.value', 0)}`}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={2} className="text-right">
                                                <img src='/image/icon/down.svg' className="img-accordion" alt="down" />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <div id={parentId} className="collapse">
                        {
                            shipment.articleList.map((thu, i) => {

                                return (
                                    <ThuRow
                                        key={i}
                                        thu={thu}
                                        onChange={onThuChange}
                                        showPart={showPart}
                                        showVolume={showVolume}
                                        showQuantity={showQuantity}
                                        showWeight={showWeight} />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default React.memo(ShipmentRow);
