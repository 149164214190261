import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PolicyGroupAccessHoc from "../../RoutedComponents/Routes/PolicyGroupAccessHoc";
import { InputDatalist } from '../InputDatalist/InputDatalist';
import { ButtonStack, Button } from '@dfds-ui/react-components';
import { Row, Col } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { toggleEditingGranted, toggleEditingGrant, changeFieldValue, changeExpectedVolumeFieldValue, changeExpectedItemQuantityFieldValue, changeExpectedGrossWeightFieldValue, updateShipment } from '../../store/action/shipmentDetailAction';
import { searchSuffixes, fetchSuffixes, fetchTypes, searchAssociates, searchAssociatesSuccess } from '../../store/ducks/definitions/actionCreators';
import { isEmptyObject, isBooleanString, isNumberString, getValue } from '../../core/utility';
import { localDateTimeToEpochSecond, epochSecondToLocalDateTime } from '../../core/utcToEpochConverter.js';
import styles from './ShipmentSummary.module.css';
import LoadingIndicator from "../../component/LoadingIndicator/LoadingIndicator";

const ShipmentSummary = ({
    shipment,
    isEditingGranted,
    toggleEditingGranted,
    toggleEditingGrant,
    isRequesting,
    isRequestingEditingGrant,
    changeFieldValue,
    changeExpectedVolumeFieldValue,
    changeExpectedItemQuantityFieldValue,
    changeExpectedGrossWeightFieldValue,
    updateShipment,
    suffixes,
    types,
    searchSuffixes,
    associates,
    searchAssociates,
    searchAssociatesSuccess
}) => {
    let history = useHistory();

    // ON COMPONENT MOUNT: Clean up the side effect: on component unmount unlock edit lock if it is locked
    useEffect(() => {
        return () => {
            return isEditingGranted === true ?
                toggleEditingGranted({ id: shipment.id, edit: false }) :
                false;
        }
    }, [isEditingGranted, toggleEditingGranted, shipment.id]);
    // end ON COMPONENT MOUNT

    // HELPERS: for ui logic
    // Fields and Shipment State
    const isShipmentFieldEditable = (shipmentFieldName) => {
        const editableShipmentFields = ['shipmentNo', 'expectedMeasurement.itemQuantity.value', 'temperatureControlled', 'customsControlled', 'expectedMeasurement.volume.value', 'expectedMeasurement.grossWeight.value', 'commodityType', 'unLoadHere', 'internalRemark', 'destinationId', 'supplierId', 'firstConsignorId', 'endCustomerId', 'unit.group.carrierId', 'unit.group.code', 'isRush', 'customReference'];
        return editableShipmentFields.includes(shipmentFieldName);
    }
    // Shipment will be able to be updated until it will be shipped.
    const isShipmentUpdatable = (shipment, updatableShipmentStatusTypes = [0, 1, 2, 3, "Intransit", "Arrived", "ShortReceived", "Received"]) => {
        return shipment && updatableShipmentStatusTypes.includes(shipment.status) && !shipment.logicalDeleteKey;
    }

    // Control Buttons State: Editing Grant and Update
    const isRequestEditingDisabled = (shipment) => {
        return !isShipmentUpdatable(shipment) || isEditingGranted;
    }
    const isUpdatingDisabled = (shipment) => {
        return !isShipmentUpdatable(shipment) || !isEditingGranted;
    }
    // end HELPERS

    const [shipmentValue, setShipmentValue] = useState();

    const delimiter = (previousElement) => previousElement ? ', ' : '';

    const changeMeasurementFieldValue = (value, callbackType) => {
        switch (callbackType) {
            case 'grossWeight':
                return changeExpectedGrossWeightFieldValue(value);
            case 'itemQuantity':
                return changeExpectedItemQuantityFieldValue(value);
            case 'volume':
                return changeExpectedVolumeFieldValue(value);
        }
    }

    const onChangeInputDatalist = (event) => {
        // if the typed/selected text include matches any existing assoc set shipment state fields with those values from found assoc
        const matchedAssoc = associates.items.find(item => {
            return (
                [item.code, item.name].some((element) => element === event.target.value)
                ||
                `(${item['code']})${delimiter(item.code)}${item['name']}` === event.target.value
            );
        });
        if (matchedAssoc) {
            // set id, and name fields: e.g. supplierId and supplier
            changeFieldValue({ [event.target.name.split(",")[0]]: matchedAssoc.id });
            // find the name and set so
            changeFieldValue({ [event.target.name.split(",")[1]]: matchedAssoc });
        } else {
            changeFieldValue({ [event.target.name.split(",")[0]]: null });
            changeFieldValue({ [event.target.name.split(",")[1]]: null });
        }
    }

    /** VALIDATION */
    const [validationErrors, setValidationErrors] = useState({});

    useEffect(() => {
        if (getValue(shipment, 'unit.group.code') && !getValue(shipment, 'unit.group.carrierId')) {
            setValidationErrors(state => ({ ...state, carrierId: 'If Transport exists Carrier info is mandatory' }));
        } else if (!getValue(shipment, 'unit.group.code') && getValue(shipment, 'unit.group.carrierId')) {
            setValidationErrors(state => ({ ...state, groupCode: 'If Carrier info exists Transport is mandatory' }));
        } else {
            setValidationErrors(state => ({ ...state, groupCode: '', carrierId: '' }));
        }

        setShipmentValue(shipment);
    }, [shipment]);
    /** end VALIDATION */


    useEffect(() => {
        if (shipment.destinationId && shipment.supplierId) {
            const selectedSuffix = suffixes.items.find(item => item.id === shipment.destinationId);
            if (selectedSuffix) {
                const matchedSupplier = selectedSuffix.suppliers && selectedSuffix.suppliers.find(item => item.id === shipment.supplierId);
                if (matchedSupplier) {
                    changeFieldValue({ customsControlled: true });
                }
            }
        }
    }, [shipment.destinationId, shipment.supplierId, suffixes]);

    // suffix endCustomer rule: When suffix set, autoset suffix.endCustomer to selectedSuffix.endCustomer
    useEffect(() => {
        if (shipment.destinationId) {
            const selectedSuffix = suffixes.items.find(item => item.id === shipment.destinationId);
            if (selectedSuffix && selectedSuffix.endCustomer) {
                searchAssociatesSuccess({ associateList: [selectedSuffix.endCustomer] }); // if we normalized our data from network w smth like normalizr that would not be necessary here 
                // setShipment({ ...shipment, endCustomer: selectedSuffix.endCustomer, endCustomerId: selectedSuffix.endCustomer.id });
                changeFieldValue({ 'endCustomer': selectedSuffix.endCustomer });
                changeFieldValue({ 'endCustomerId': selectedSuffix.endCustomer.id });
            } else {
                // setShipment({ ...shipment, endCustomer: '', endCustomerId: '' });
                // changeFieldValue({ endCustomer: '' });
                // changeFieldValue({ endCustomerId: '' });
            }
        }
    }, [shipment.destinationId, suffixes]);

    const onSuffixChange = (event) => {
        //TODO : should fix here
        const selectedSuffix = suffixes.items.find(item => item.name === event.target.value);
        if (selectedSuffix) {
            changeFieldValue({ 'destinationId': selectedSuffix.id });
        } else {
            changeFieldValue({ 'destinationId': null });
        }
    }

    // RENDER HELPERS
    const renderInput = ({ label, key, type = 'text' }) => {

        // step="any" for making any value valid, note: only whole multiples of the step value are valid.
        return (
            type === "number" ?
                <>
                    <label htmlFor={key} className={styles.label}>{label}</label>
                    <input
                        type={type}
                        id={key}
                        value={getValue(shipment, key)}
                        onChange={e => changeFieldValue({ [key]: (type === 'number' && isNumberString(e.target.value) ? JSON.parse(e.target.value) : e.target.value) })}
                        readOnly={!isShipmentFieldEditable(key) || !isShipmentUpdatable(shipment) || !isEditingGranted}
                        min={0}
                        step={"any"}
                    />
                </>
                :
                <>
                    <label htmlFor={key} className={styles.label}>{label}</label>
                    <input
                        type={type}
                        id={key}
                        value={getValue(shipment, key)}
                        onChange={e => changeFieldValue({ [key]: e.target.value })}
                        readOnly={!isShipmentFieldEditable(key) || !isShipmentUpdatable(shipment) || !isEditingGranted}
                    />
                </>
        );
    }

    const renderMeasurementInput = ({ label, key, callbackType }) => {

        // step="any" for making any value valid, note: only whole multiples of the step value are valid.
        return (
            <>
                <label htmlFor={key} className={styles.label}>{label}</label>
                <input
                    type='number'
                    id={key}
                    value={getValue(shipment, key)}
                    onChange={e => changeMeasurementFieldValue(isNumberString(e.target.value) ? JSON.parse(e.target.value) : e.target.value, callbackType)}
                    readOnly={!isShipmentFieldEditable(key) || !isShipmentUpdatable(shipment) || !isEditingGranted}
                    min={0}
                    step={"any"}
                />
            </>
        );
    }


    const renderTextarea = ({ label, key }) => {

        const isReadOnly = !isShipmentFieldEditable(key) || !isShipmentUpdatable(shipment) || !isEditingGranted;

        return (
            <>
                <label htmlFor={key} className={styles.label}>{label}</label>
                <textarea
                    id={key}
                    value={getValue(shipment, key)}
                    onChange={e => changeFieldValue({ [key]: e.target.value })}
                    readOnly={isReadOnly}
                    style={{ width: '100%' }}
                >
                </textarea>
            </>
        );
    }

    const renderSelect = ({
        label,
        key,
        optionsList = [],
        renderOptionElement = ({ optionData, optionKey }) => <option value={optionData} key={optionKey}>{optionData}</option>,
    }) => {
        return (
            <>
                <label htmlFor={key} className={styles.label}>{label}</label>
                <select
                    id={key}
                    value={getValue(shipment, key)}
                    onChange={e => changeFieldValue({ [key]: (isBooleanString(e.target.value) || isNumberString(e.target.value) ? JSON.parse(e.target.value) : e.target.value) })}
                    disabled={!isShipmentFieldEditable(key) || !isShipmentUpdatable(shipment) || !isEditingGranted}
                >
                    <option value={""}>Select one</option>
                    {optionsList.map((item, i) => renderOptionElement({ optionData: item, optionKey: i }))}
                </select>
            </>
        );
    }

    const renderInputDatalist = ({
        label,
        name,
        value,
        onChange,
        data,
        fetchDataFunction,
        minCharsToFetchData = 2,
        renderDatalistOptions,
        placeholder,
        queryParameterName = "query",
        labelClassName = styles.label,
    }) => {
        return (
            <>
                <InputDatalist
                    value={value}
                    onChange={onChange}
                    data={data}
                    fetchDataFunction={fetchDataFunction}
                    minCharsToFetchData={minCharsToFetchData}
                    renderDatalistOptions={renderDatalistOptions}
                    readOnly={!isShipmentFieldEditable(name[0]) || !isShipmentUpdatable(shipment) || !isEditingGranted}
                    labelText={label}
                    placeholder={placeholder}
                    name={name}
                    hasIcon={false}
                    queryParameterName={queryParameterName}
                    labelClassName={labelClassName}
                />
                <small className="form-text text-danger">{validationErrors[name]}</small>
            </>
        );
    }

    const renderDateTimePicker = ({ label, key }) => {
        return (
            <>
                <label className={styles.label}>{label}</label>
                <DatePicker
                    id={key}
                    selected={getValue(shipment, key) ? epochSecondToLocalDateTime(getValue(shipment, key)) : ''}
                    onChange={(dateTime) => changeFieldValue({ [key]: localDateTimeToEpochSecond(dateTime) })}
                    readOnly={!isShipmentFieldEditable(key) || !isShipmentUpdatable(shipment) || !isEditingGranted}
                    dateFormat="MM/dd/yyyy HH:mm"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeCaption="Time"
                    timeIntervals={1}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    todayButton="Today"
                    placeholderText='Select Date & Time'
                    type='text'
                    dropdownMode="select"
                />
            </>
        );
    }
    // end RENDER HELPERS

    return (
        <div className='card'>
            <div className='card-header collapsed' href={`#shipmentSummary`} data-toggle='collapse' data-parent="#accordion">
                <h5 className='mb-0'>
                    <div>
                        <img src="/image/icon/down.svg" className="color-action-blue" width="20px" alt='' />
                        Shipment Summary
                    </div>
                </h5>
            </div>

            <div id='shipmentSummary' className='collapse'>
                <div className='card-body' mt={0}>
                    <Col>

                        <Row>
                            {
                                !isEmptyObject(shipment) && [
                                    renderInput({ label: 'Shipment No *', key: 'shipmentNo' }),
                                    renderInputDatalist({
                                        label: 'Suffix *',
                                        name: ['destinationId'],
                                        value: getValue(shipment, 'destination.code'),
                                        onChange: e => onSuffixChange(e),
                                        data: suffixes.items,
                                        fetchDataFunction: searchSuffixes,
                                        //TODO : should write value ant text to option tag
                                        renderDatalistOptions: (item, i) => <option value={item['code']} key={i} />,
                                    }),
                                    renderInputDatalist({
                                        label: 'Supplier ID or Name *',
                                        name: ['supplierId', 'supplier'],
                                        value: `${getValue(shipment, 'supplier.code') ? `(${getValue(shipment, 'supplier.code') || ''})` : ''}${delimiter(getValue(shipment, 'supplier.code'))}${getValue(shipment, 'supplier.name') || ''}`,
                                        onChange: onChangeInputDatalist,
                                        data: associates.items,
                                        fetchDataFunction: searchAssociates,
                                        queryParameterName: "queryStringValue",
                                        renderDatalistOptions: (item, i) => <option value={`(${item['code']})${delimiter(item.id)}${item['name']}`} key={i} />,
                                    }),
                                    renderInputDatalist({
                                        label: 'End Customer ID or Name *',
                                        name: ['endCustomerId', 'endCustomer'],
                                        value: `${getValue(shipment, 'endCustomer.code') ? `(${getValue(shipment, 'endCustomer.code') || ''})` : ''}${delimiter(getValue(shipment, 'endCustomer.code'))}${getValue(shipment, 'endCustomer.name') || ''}`,
                                        onChange: onChangeInputDatalist,
                                        data: associates.items,
                                        fetchDataFunction: searchAssociates,
                                        queryParameterName: "queryStringValue",
                                        renderDatalistOptions: (item, i) => <option value={`(${item['code']})${delimiter(item.id)}${item['name']}`} key={i} />,
                                    }),
                                    renderInputDatalist({
                                        label: 'Carrier ID or Name',
                                        name: ['unit.group.carrierId', 'unit.group.carrier'],
                                        value: `${getValue(shipment, 'unit.group.carrier.code') ? `(${getValue(shipment, 'unit.group.carrier.code') || ''})` : ''}${delimiter(getValue(shipment, 'unit.group.carrier.code'))}${getValue(shipment, 'unit.group.carrier.name') || ''}`,
                                        onChange: onChangeInputDatalist,
                                        data: associates.items,
                                        fetchDataFunction: searchAssociates,
                                        queryParameterName: "queryStringValue",
                                        renderDatalistOptions: (item, i) => <option value={`(${item['code']})${delimiter(item.id)}${item['name']}`} key={i} />,
                                    }),
                                    renderInput({ label: 'Transport', key: 'unit.group.code' }),
                                    renderInput({ label: 'Transport Reference', key: 'referenceNumber' }),
                                    renderInputDatalist({
                                        label: 'First Consignor ID or Name',
                                        name: ['firstConsignorId', 'firstConsignor'],
                                        value: `${getValue(shipment, 'firstConsignor.code') ? `(${getValue(shipment, 'firstConsignor.code') || ''})` : ''}${delimiter(getValue(shipment, 'firstConsignor.code'))}${getValue(shipment, 'firstConsignor.name') || ''}`,
                                        onChange: onChangeInputDatalist,
                                        data: associates.items,
                                        fetchDataFunction: searchAssociates,
                                        queryParameterName: "queryStringValue",
                                        renderDatalistOptions: (item, i) => <option value={`(${item['code']})${delimiter(item.id)}${item['name']}`} key={i} />,
                                    }),
                                    renderInput({ label: 'Custom Reference', key: 'customReference' }),

                                    renderMeasurementInput({ label: 'Exp. Item Quantity', key: 'expectedMeasurement.itemQuantity.value', callbackType: 'itemQuantity' }),
                                    renderMeasurementInput({ label: 'Exp. Gross Weight', key: 'expectedMeasurement.grossWeight.value', callbackType: 'grossWeight' }),
                                    renderMeasurementInput({ label: 'Exp. Volume', key: 'expectedMeasurement.volume.value', callbackType: 'volume' }),

                                    renderInput({ label: 'Act. Item Quantity', key: 'actualMeasurement.itemQuantity.value', type: 'number' }),
                                    renderInput({ label: 'Act. Gross Weight', key: 'actualMeasurement.grossWeight.value', type: 'number' }),
                                    renderInput({ label: 'Act. Volume', key: 'actualMeasurement.volume.value', type: 'number' }),

                                    renderSelect({
                                        label: 'Unload Here?',
                                        key: 'unLoadHere',
                                        optionsList: [true, false],
                                        renderOptionElement: ({ optionData, optionKey }) => <option value={optionData} key={optionKey}>{optionData ? 'True' : 'False'}</option>
                                    }),
                                    renderSelect({
                                        label: 'Temperature Controlled?',
                                        key: 'temperatureControlled',
                                        optionsList: [true, false],
                                        renderOptionElement: ({ optionData, optionKey }) => <option value={optionData} key={optionKey}>{optionData ? 'True' : 'False'}</option>
                                    }),
                                    renderSelect({
                                        label: 'Customs Controlled?',
                                        key: 'customsControlled',
                                        optionsList: [true, false],
                                        renderOptionElement: ({ optionData, optionKey }) => <option value={optionData} key={optionKey}>{optionData ? 'True' : 'False'}</option>
                                    }),
                                    renderSelect({
                                        label: 'Rush?',
                                        key: 'isRush',
                                        optionsList: [true, false],
                                        renderOptionElement: ({ optionData, optionKey }) =>
                                            <option
                                                value={optionData}
                                                key={optionKey}>
                                                {optionData ? 'True' : 'False'}
                                            </option>
                                    }),
                                    renderInput({ label: 'Commodity Type', key: 'commodityType' }),
                                    renderInput({ label: 'Internal Remark', key: 'internalRemark' }),

                                    renderSelect({
                                        label: 'Content TB Received?',
                                        key: 'contentTBReceived',
                                        optionsList: [true, false],
                                        renderOptionElement: ({ optionData, optionKey }) => <option value={optionData} key={optionKey}>{optionData ? 'True' : 'False'}</option>
                                    }),                                 


                                    renderSelect({
                                        label: 'Type',
                                        key: 'type',
                                        optionsList: types.items.length ? types.items.find(item => item.type === "ShipmentType").values : [],
                                        renderOptionElement: ({ optionData, optionKey }) => <option value={optionData.name} key={optionKey}>{optionData.displayName}</option>
                                    }),
                                    renderSelect({
                                        label: 'Status',
                                        key: 'status',
                                        optionsList: types.items.length ? types.items.find(item => item.type === "ShipmentStatusType").values : [],
                                        renderOptionElement: ({ optionData, optionKey }) => <option value={optionData.name} key={optionKey}>{optionData.displayName}</option>
                                    }),
                                    renderDateTimePicker({ label: 'Customer Pickup Date & Time', key: 'customerPickupDate' }),
                                    renderDateTimePicker({ label: 'Customer Delivery Date & Time', key: 'customerDeliveryDate' }),
                                    renderDateTimePicker({ label: 'Created Date', key: 'createdDate' }),
                                    renderInput({ label: 'Created By', key: 'createdBy' }),
                                    renderTextarea({ label: 'Advice Notes', key: 'adviceNotes' }),
                                ].map((component, i) => <Col xs="12" sm="6" md="6" lg="4" key={i}>{component}</Col>)
                            }
                        </Row>

                        <PolicyGroupAccessHoc componentName="UpdateShipmentControlsHOC">
                            <ButtonStack align="right">
                                <Button
                                    disabled={isRequestEditingDisabled(shipment) || isRequestingEditingGrant}
                                    onClick={() => toggleEditingGrant({ id: shipment.id, edit: true })}
                                    title={
                                        !isShipmentUpdatable(shipment) ? 'Intransit shipments are not editable'
                                            : isRequestingEditingGrant ? 'Requesting editing grant'
                                                : isEditingGranted ? "Editing granted"
                                                    : "Request editing"
                                    }
                                >
                                    {
                                        !isShipmentUpdatable(shipment) ? 'Shipment Not Updatable'
                                            : isRequestingEditingGrant ? 'Requesting editing grant'
                                                : isEditingGranted ? "Editing Granted"
                                                    : 'Request Editing'
                                    }
                                </Button>
                                <Button
                                    disabled={isUpdatingDisabled(shipment) || isRequesting}
                                    onClick={async () => {
                                        await updateShipment(shipment, 'processShipmentUpdate');
                                        history.push(`/shipment/${shipment.id}`);
                                    }}
                                    title={
                                        !isShipmentUpdatable(shipment) ? 'Intransit shipments are not editable'
                                            : isEditingGranted ? "Editing granted"
                                                : "Request editing"
                                    }
                                >
                                    {isRequesting ? 'Updating' : 'Update'}
                                </Button>
                            </ButtonStack>
                        </PolicyGroupAccessHoc>
                    </Col>
                </div>
                <LoadingIndicator id='processShipmentUpdate' show={isRequesting} />

            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        isEditingGranted: state.shipmentDetail.isEditingGranted,
        isRequestingEditingGrant: state.shipmentDetail.isRequestingEditingGrant,
        isRequesting: state.shipmentDetail.isRequesting,
        suffixes: state.definitions.suffixes,
        types: state.definitions.types,
        associates: state.definitions.associates
    }
};

const mapDispatchToProps = {
    toggleEditingGranted,
    toggleEditingGrant,
    changeFieldValue,
    changeExpectedVolumeFieldValue,
    changeExpectedItemQuantityFieldValue,
    changeExpectedGrossWeightFieldValue,
    updateShipment,
    fetchSuffixes,
    searchSuffixes,
    searchAssociates,
    searchAssociatesSuccess,
    fetchTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentSummary);
