/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Table, Row, Col } from 'react-bootstrap';
import { convertEpochToUtcTime } from '../../core/convertEpochToUtcTime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@dfds-ui/react-components/button';
import { Search } from '@dfds-ui/icons';
import classes from './BookingsTable.module.css';
import { getAuthorizedBusinessAreas } from '../../store/action/calendarActions';
import { showInlineInformationModal, getUnitTypes, getQuery, parseUrlForBookingList } from '../../store/action/bookingActions';
import { connect } from 'react-redux';
import { TextField } from '@dfds-ui/react-components';
import { MultiSelect } from '../BaseComponents/MultiSelect';
import moment from 'moment';
import 'moment-timezone';
import { bookingIdChanged, textSearchChanged,businessAreasChanged } from '../../store/action/bookingsQueryStringActions';


//Bu canMarkAsArrived ve canEditBooking sonradan permission yapısı ile bağlanacağı için üzerinde çok düşünmekdim.
const BookingsTable = (props) => {
  // Redux actions
  const { getBusinessAreas, getUnitTypes, showInlineInformationModal,  bookingIdChanged, textSearchChanged,businessAreasChanged } = props;

  // Redux store
  let { businessAreas, unitTypes, bookingsQuery } = props;
  const { getAuthorizedBusinessAreas } = props;

  // Redux store
  let { authorizedBusinessAreas } = props;

  // Others
  const {
    data,
    canMarkAsArrived = false,
    canEditBooking = false,
    canSeeAdminNotes = false,
    markAsArrivedClickedEvent = null,
    editBookingClickedEvent = null,
    cancelBookingClickedHandler = null,
    markAsUnArrivedClickedEvent = null,
    updateAdminNotesClickHandler = null,
    arrivalReceiptClickedEvent = null,
    authenticatedUser = null    
  } = props;

  const [selectedBusinessAreas, setSelectedBusinessAreas] = useState([]);
  const [allBusinessAreas, setAllBusinessAreas] = useState([]);
  const [textFilter, setTextFilter] = useState('' || bookingsQuery.booking.searchTerm);
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    if (!authorizedBusinessAreas) {
      getAuthorizedBusinessAreas(authenticatedUser.id);
    }
  }, [authorizedBusinessAreas, getAuthorizedBusinessAreas]);

  useEffect(() => {
    if (!unitTypes) {
      getUnitTypes();
    }
  }, [unitTypes, getUnitTypes]);

  useEffect(() => {
    if (authorizedBusinessAreas !== null) {
      setAllBusinessAreas(
        authorizedBusinessAreas.map((item) => {
          return {
            label: item.name,
            value: item.name
          };
        })
      ); 
    }
  }, [authorizedBusinessAreas, setAllBusinessAreas]);

  useEffect(() => {
    if (data) {
      var newState = bookingsQuery && bookingsQuery.booking && bookingsQuery.booking.businessAreas && bookingsQuery.booking.businessAreas.length > 0 ? bookingsQuery.booking.businessAreas : [];
      setSelectedBusinessAreas(newState);
      setFilteredData(data);
      if(bookingsQuery && bookingsQuery.booking){
        bookingFilterResultHandler(bookingsQuery.booking.searchTerm, bookingsQuery.booking.businessAreas);
      }
    } 
  }, [data, setSelectedBusinessAreas, setFilteredData]);

  const showActions = () => canMarkAsArrived || canEditBooking;

  const editClickHandler = (bookingCode)=>{
    bookingIdChanged(bookingCode);
    editBookingClickedEvent(bookingCode, selectedBusinessAreas, textFilter);
  }

  const renderIsArrivedButton = (row) => {
    if (canMarkAsArrived && !row.isArrived) {
      return (
        <Button
          variation="outlined"
          size="small"
          className="mr-2"
          title="Mark as arrived"
          onClick={() => markAsArrivedClickedEvent(row.bookingCode)}
          variant="transparent"
        >
          <FontAwesomeIcon icon="calendar-check" />
        </Button>
      );
    }
    return null;
  };

  const renderHasCustomsNotification = (row) => {
    if (row.hasCustoms) {
      return (
          <FontAwesomeIcon icon="exclamation-triangle" style={{color:'#be1e2d'}} title="Custom clearance is needed!" />
      );
    }
    return null;
  }

  const renderMarkAsUnArrivedButton = (row) => {
    if (canMarkAsArrived && row.isArrived && markAsUnArrivedClickedEvent) {
      return (
        <Button
          variation="outlined"
          size="small"
          className="mr-2"
          title="Mark as unarrived"
          onClick={() => markAsUnArrivedClickedEvent(row.bookingCode)}
          variant="transparent"
        >
          <FontAwesomeIcon icon="undo-alt" />
        </Button>
      );
    }
    return null;
  };

 /*  const renderReceiptButton = (row) => {
    return canMarkAsArrived && row.isArrived && arrivalReceiptClickedEvent ?
        <Button variation="outlined" size="small" className="mr-2" title="Arrival Receipt"
                onClick={() => arrivalReceiptClickedEvent(row.bookingCode)} variant="transparent">
            <FontAwesomeIcon icon="file-invoice"/>
        </Button> : null;
};
 */

const renderReceiptButton = (row)=>{
    
  const isExternal = isExternalUser();
  if(isExternal || !row.isArrived){
    return <></>;
  }
  return (
    <Button
      variation="outlined"
      size="small"
      className="mr-2"
      title="Arrival Receipt"
      onClick={() => arrivalReceiptClickedEvent(row.bookingCode)}
      variant="transparent"
    >
      <FontAwesomeIcon icon="file-invoice" />
    </Button>
  );
}

  const renderEditBookingButton = (row) => {
    if (canEditBooking && !row.isArrived) {
      return (
        <Button
          variation="outlined"
          size="small"
          className="mr-2"
          title="Edit Booking"
          onClick={() => editClickHandler(row.bookingCode)}
          variant="transparent"
        >
          <FontAwesomeIcon icon="edit" />
        </Button>
      );
    }
    return null;
  };

  const renderCancelBookingButton = (row) => {
    if (canEditBooking && !row.isArrived && row.isCancellable) {
      return (
        <Button
          variation="outlined"
          size="small"
          title="Cancel Booking"
          onClick={() => cancelBookingClickedHandler(row.bookingCode)}
          variant="transparent"
        >
          <FontAwesomeIcon icon="calendar-times" />
        </Button>
      );
    }
    return null;
  };

  const renderUpdateAdminNotes = (row)=>{
    
    const isExternal = isExternalUser();
    if(isExternal){
      return <></>;
    }
    return (
      <Button
        variation="outlined"
        size="small"
        className="mr-2"
        title="Edit Admin Notes"
        onClick={() => updateAdminNotesClickHandler(row.bookingCode)}
        variant="transparent"
      >
        <FontAwesomeIcon icon="clipboard" />
      </Button>
    );
  }

  const isExternalUser = () => {
    const isExternal = authenticatedUser.roles.indexOf("ExternalUser") >= 0;
    return isExternal;
  }

  const multiSelectResultHandler = (selectedItems) => {
    bookingFilterResultHandler(textFilter, selectedItems);
    setSelectedBusinessAreas(selectedItems);

    businessAreasChanged(selectedItems);
  };

  const textResultHandler = (searchTerm) => {
    bookingFilterResultHandler(searchTerm, selectedBusinessAreas);
    setTextFilter(searchTerm);

    textSearchChanged(searchTerm);
  };

  const isTextFilterApplied = (loweredSearchTerm, loweredBookingRow) => {
    if (
      !loweredSearchTerm ||
      loweredBookingRow.DateTimeEpoch.indexOf(loweredSearchTerm) > -1 ||
      loweredBookingRow.BookingCode.indexOf(loweredSearchTerm) > -1 ||
      loweredBookingRow.BusinessArea.indexOf(loweredSearchTerm) > -1 ||
      loweredBookingRow.UnitNo.indexOf(loweredSearchTerm) > -1 ||
      loweredBookingRow.Name.indexOf(loweredSearchTerm) > -1 ||
      loweredBookingRow.Surname.indexOf(loweredSearchTerm) > -1 ||
      loweredBookingRow.Carrier.indexOf(loweredSearchTerm) > -1 ||
      loweredBookingRow.OperationType.indexOf(loweredSearchTerm) > -1 ||
      loweredBookingRow.ThuAmount.indexOf(loweredSearchTerm) > -1 ||
      loweredBookingRow.PhoneNumber.indexOf(loweredSearchTerm) > -1 ||
      loweredBookingRow.TransportNumbers.indexOf(loweredSearchTerm) > -1
    ) {
      return true;
    }

    return false;
  };

  const isBusinessAreaExistIn = (selectedBusinessAreas, rowBusinessArea) =>
    !selectedBusinessAreas ||
    selectedBusinessAreas.length === 0 ||
    selectedBusinessAreas.find((e) => e.toLowerCase() === rowBusinessArea);

  const checkIfExistThe = (bookingItemProp) => {
    if (Array.isArray(bookingItemProp)) {
      return !!(bookingItemProp && bookingItemProp[0]);
    }
    return !!bookingItemProp;
  };

  const getLoweredValueOrEmptyStringOf = (bookingItemProperty) => {
    const loweredItem = '';

    if (!checkIfExistThe(bookingItemProperty)) {
      return loweredItem;
    }

    if (Array.isArray(bookingItemProperty)) {
      return bookingItemProperty[0].toLowerCase();
    }

    if (typeof bookingItemProperty !== 'string') {
      return bookingItemProperty.toString().toLowerCase();
    }

    return bookingItemProperty.toLowerCase();
  };

  const lowerBookingItem = (bookingItem) => {
    const loweredBookingItem = {};

    loweredBookingItem.DateTimeEpoch = getLoweredValueOrEmptyStringOf(
      convertEpochToUtcTime(bookingItem.dateTime)
    );
    loweredBookingItem.BookingCode = getLoweredValueOrEmptyStringOf(bookingItem.bookingCode);
    loweredBookingItem.UnitNo = getLoweredValueOrEmptyStringOf(bookingItem.unitNo);
    loweredBookingItem.Name = getLoweredValueOrEmptyStringOf(bookingItem.name);
    loweredBookingItem.Surname = getLoweredValueOrEmptyStringOf(bookingItem.surname);
    loweredBookingItem.Carrier = getLoweredValueOrEmptyStringOf(bookingItem.carrier);
    loweredBookingItem.OperationType = getLoweredValueOrEmptyStringOf(bookingItem.operationType);
    loweredBookingItem.ThuAmount = getLoweredValueOrEmptyStringOf(bookingItem.thuAmount);
    loweredBookingItem.PhoneNumber = getLoweredValueOrEmptyStringOf(bookingItem.phoneNumber);
    loweredBookingItem.TransportNumbers = getLoweredValueOrEmptyStringOf(
      bookingItem.transportNumbers
    );
    loweredBookingItem.BusinessArea = getLoweredValueOrEmptyStringOf(bookingItem.businessAreaName);
    return loweredBookingItem;
  };

  const bookingFilterResultHandler = (searchTerm, selectedBusinessAreas) => {
    const loweredSearchTerm = searchTerm.trim().toLowerCase();

    if (!loweredSearchTerm && selectedBusinessAreas.length === 0) {
      setFilteredData(data);
      return;
    }

    if (!data || data.length === 0) {
      return;
    }

    const newFilteredBookings = [];
    for (const d of data) {
      const loweredBookingRow = lowerBookingItem(d);
      var a = isBusinessAreaExistIn(selectedBusinessAreas, loweredBookingRow.BusinessArea);
      if (
        isTextFilterApplied(loweredSearchTerm, loweredBookingRow) &&
        isBusinessAreaExistIn(selectedBusinessAreas, loweredBookingRow.BusinessArea)
      ) {
        newFilteredBookings.push(d);
      }
    }

    setFilteredData(newFilteredBookings);
  };

  const getUnitTypeName = (unitTypeId) => {
    if (!unitTypes || unitTypes.length === 0) return '';
    const unitType = unitTypes.find((e) => e.id === unitTypeId);
    if (unitType) return unitType.name;

    return '';
  };

  const detail = (row) => {
    return (
      <div className={classes.divTable}>
        <div className={classes.divTableBody}>
          <div className={classes.divTableRow}>
            <div className={classes.divTableCellLeft}>Business Area:</div>
            <div className={classes.divTableCellRight}>{row.businessAreaName}</div>
          </div>
          <div className={classes.divTableRow}>
            <div className={classes.divTableCellLeft}>Client:</div>
            <div className={classes.divTableCellRight}>{row.clientName}</div>
          </div>
          <div className={classes.divTableRow}>
            <div className={classes.divTableCellLeft}>Service:</div>
            <div className={classes.divTableCellRight}>{row.serviceName}</div>
          </div>
          <div className={classes.divTableRow}>
            <div className={classes.divTableCellLeft}>Unit Type:</div>
            <div className={classes.divTableCellRight}>{getUnitTypeName(row.unitTypeId)}</div>
          </div>
          <div className={classes.divTableRow}>
            <div className={classes.divTableCellLeft}>Customs Needed:</div>
            <div className={classes.divTableCellRight}>{row.hasCustoms ? 'Yes' : 'No'}</div>
          </div>
          <div className={classes.divTableRow}>
            <div className={classes.divTableCellLeft}>Dangerous Goods:</div>
            <div className={classes.divTableCellRight}>{row.hasDangerousGoods ? 'Yes' : 'No'}</div>
          </div>
          <div className={classes.divTableRow}>
            <div className={classes.divTableCellLeft}>Thu Amount:</div>
            <div className={classes.divTableCellRight}>{row.thuAmount}</div>
          </div>
          <div className={classes.divTableRow}>
            <div className={classes.divTableCellLeft}>Name Surname:</div>
            <div className={classes.divTableCellRight}>{`${row.name ? row.name : ''} ${
              row.surname ? row.surname : ''
            }`}</div>
          </div>
          <div className={classes.divTableRow}>
            <div className={classes.divTableCellLeft}>Email:</div>
            <div className={classes.divTableCellRight}>{row.email}</div>
          </div>
          <div className={classes.divTableRow}>
            <div className={classes.divTableCellLeft}>Notes:</div>
            <div className={classes.divTableCellRight}>{row.userNote}</div>
          </div>
          {canSeeAdminNotes ? (
            <div className={classes.divTableRow}>
              <div className={classes.divTableCellLeft}>Admin Notes:</div>
              <div className={classes.divTableCellRight}>{row.administratorNote}</div>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const showRowInformation = (row) => {
    showInlineInformationModal(row.bookingCode, row, canSeeAdminNotes, detail);
  };

  const calculateNoRowSpan = () => {
    let baseColumnCount = 9;

    if (showActions()) {
      baseColumnCount++;
    }

    if (canSeeAdminNotes) {
      baseColumnCount++;
    }

    return baseColumnCount;
  };

  return (
    <>
      <Row className="mt-3">
        <Col lg={4} md={5} sm={6}>
          <div className={classes.bookIt}>
            <MultiSelect
              placeholder="Select Business Area"
              disableSearch={true}
              options={allBusinessAreas}
              selected={selectedBusinessAreas}
              onSelectedChanged={(selected) => multiSelectResultHandler(selected)}
            />
          </div>
        </Col>
        <Col lg={4} md={5} sm={6}>
          <div className={classes.searchText} style={{ width: '100%' }}>
            <TextField
              icon={<Search />}
              type="text"
              value={textFilter}
              placeholder="Enter Ref No / Carrier / Unit Number / ..."
              onChange={(e) => textResultHandler(e.target.value)}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col style={{ overflowY: 'hidden', paddingRight: '0px', minHeight: '300px' }}>
          <Table bordered>
            <thead>
              <tr>
                <th style={{ whiteSpace: 'nowrap' }}>Business Area</th>
                <th>Time</th>
                <th>Booking No</th>
                <th style={{ whiteSpace: 'nowrap' }}>Unit Number</th>
                <th>Carrier</th>
                <th style={{ whiteSpace: 'nowrap' }}>Operation Type</th>
                <th>THU'S</th>
                <th>Notes</th>
                {canSeeAdminNotes ? <th style={{ whiteSpace: 'nowrap' }}>Admin Notes</th> : null}
                <th>Transports</th>
                {showActions() ? <th>Actions</th> : null}
              </tr>
            </thead>
            <tbody>
              {filteredData && filteredData.length > 0 ? (
                filteredData.map((row, i) => (
                  <tr key={i} className={row.isArrived ? 'arrived-booking' : ''}>
                    <td style={{ whiteSpace: 'nowrap' }}>{row.businessAreaName} {renderHasCustomsNotification(row)}</td>
                    <td>{convertEpochToUtcTime(row.dateTime)}</td>
                    <td style={{ whiteSpace: 'nowrap', textDecoration: 'underline' }}>
                      <a hef="#" onClick={() => showRowInformation(row)}>
                        {row.bookingCode}
                      </a>
                    </td>
                    <td>{row.unitNo}</td>
                    <td>{row.carrier}</td>
                    <td>{row.operationType}</td>
                    <td>{row.thuAmount}</td>
                    <td>{row.userNote}</td>
                    {canSeeAdminNotes ? <td>{row.administratorNote}</td> : null}
                    <td>{row.transportNumbers ? row.transportNumbers[0] : null}</td>
                    {showActions() ? (
                      <td style={{ whiteSpace: 'nowrap' }}>
                        {renderIsArrivedButton(row)}
                        {renderMarkAsUnArrivedButton(row)}
                        {renderEditBookingButton(row)}
                        {renderUpdateAdminNotes(row)}
                        {renderCancelBookingButton(row)}
                        {renderReceiptButton(row)}                      
                      </td>
                    ) : null}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={calculateNoRowSpan()} className="text-center">
                    {' '}
                    There are not any bookings for selected date
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authorizedBusinessAreas: state.calendar.authorizedBusinessAreas,
    unitTypes: state.booking.unitTypes,
    authenticatedUser: state.auth.authenticatedUser,
    bookingsQuery: state.bookingsQuery.query
  };
};

const mapDispatchToProps = {
  getAuthorizedBusinessAreas,
  getUnitTypes,
  showInlineInformationModal,

  bookingIdChanged, 
  textSearchChanged,
  businessAreasChanged
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingsTable);
