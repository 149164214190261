export const parseRules = rules => !rules ? [] : rules.map(rule => {
    var fieldPattern = /@\w*/g;
    const fieldIds = rule.match(fieldPattern);

    var behaviourPattern = /#\w*/g;
    const behaviours = rule.match(behaviourPattern);

    return {
        if: {
            fieldId: fieldIds[0].replace('@', ''),
            condition: behaviours[0].replace('#', '')
        },
        then: {
            fieldId: fieldIds[1].replace('@', ''),
            action: behaviours[1].replace('#', '')
        }
    }
});
