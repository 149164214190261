/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getUser, clearSelectedUser, getRegions, getRoles, saveUser } from '../store/action/userActions';
import { getCompanyList } from '../store/action/companyActions';
import history from '../core/history';
import UserForm2 from '../component/UserForm/UserForm2';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import Page from '../component/Page/Page';
import { getClients } from '../store/action/companyActions';

const AddOrEditUser = ({ match, user, userList, getUser, getRegions, getRoles, getCompanyList, getClients, clearSelectedUser, saveUser, regions, roles, companies, clients }) => {

    const [isClientRequested, setIsClientRequested] = useState(false);
    const userNameQueryString = match.params.username;


    useEffect(() => {
        if (!userNameQueryString || userNameQueryString === 'new') {
            return;
        }
        
        getUser(userNameQueryString);

        return () => {
            clearSelectedUser();
        }
    }, userNameQueryString);

    useEffect(() => {
        if (!isClientRequested) {
            //todo: we can talk (kaan)
            getClients();
            setIsClientRequested(true);
        }
    }, [clients]);

    useEffect(() => {
        if (!regions) {
            getRegions();
        }
    }, [regions, getRegions]);

    useEffect(() => {
        if (!roles) {
            getRoles();
        }
    }, [roles, getRoles]);

    useEffect(() => {
        if (!companies) {
            getCompanyList();
        }
    }, [companies, getCompanyList]);

    const submit = (userModel) => {
        saveUser(userModel);
        history.goBack();
    }
    return <Page backLink='/user/' backToWhere='Users'>
        <h4>{user ? [user.surname, user.name].join(' ') : 'New User'}</h4><hr className='mt-0' />
        {/* <UserForm onSubmit={submit} user={user} regions={regions} roles={roles} companies={companies} /> */}
        <UserForm2 onSubmit={submit} user={user} regions={regions} roles={roles} clients={clients} />
        <LoadingIndicator id="addOrEditUser" />
    </Page>
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        userList: state.user.userListResponse && state.user.userListResponse.length > 0 ? state.user.userListResponse : [],
        regions: state.user.regions,
        roles: state.user.roles,
        companies: state.company.companyList,
        clients: state.company.clients
    }
}
export default connect(mapStateToProps, { getUser, clearSelectedUser, getRegions, saveUser, getRoles, getCompanyList, getClients })(AddOrEditUser);
