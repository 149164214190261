import axios from 'axios'
import { toast } from 'react-toastify';
import { spinnerService } from "@simply007org/react-spinners";
import MockAdapter from 'axios-mock-adapter';

let myAxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL
});

const tokenHandler = config => {
    const token = localStorage.getItem('access_token');
    if (!token) {
        return;
    }
    myAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return config;
}

myAxios.defaults.headers.common['ApplicationName'] = 'XMS';
myAxios.defaults.headers.common['Region'] = 'ARN';
myAxios.interceptors.request.use(tokenHandler());

export const refreshAxios = () => {
    myAxios = axios.create({
        baseURL: process.env.REACT_APP_BASE_API_URL
    });
    myAxios.interceptors.request.use(tokenHandler());
}

export const postHandler = async (url, data, config) => {
    try {
        const response = await myAxios.post(url, data, config);
        console.log("myAxios response: ", response);
        return response;
    } catch (error) {
        console.error("myAxios error: ", error)
    } finally {
        console.log("myAxios finally");
    }
}

export const post = async (endpoint, data, spinnerElementId = null, doNotShowToastr = false, config) => {

    const postResult = { isSuccess: false, response: null, error: null };

    if (spinnerElementId) {
        spinnerService.show(spinnerElementId);
    }

    try {
        const response = await myAxios.post(endpoint, data, config);
        postResult.isSuccess = true;
        postResult.response = response.data.result;
        return postResult;
    } catch (error) {
        postResult.isSuccess = false;
        postResult.error = error;
        if (!doNotShowToastr) {
            const errorMessage = error.response && error.response.data && error.response.data.errors && error.response.data.errors.length ? error.response.data.errors[0].description : error.message;
            if (errorMessage) {
                toast.error(`${errorMessage}`);
            }
        }
    } finally {
        if (spinnerElementId) {
            spinnerService.hide(spinnerElementId);
        }
    }
    return postResult;
}

export const get = async (endpoint, spinnerElementId = null, doNotShowToastr = false) => {

    const getResult = { isSuccess: false, response: null, error: null };

    if (spinnerElementId) {
        spinnerService.show(spinnerElementId);
    }

    try {
        const response = await myAxios.get(endpoint);
        getResult.isSuccess = true;
        getResult.response = response.data;
        return getResult;
    } catch (error) {
        getResult.isSuccess = false;
        getResult.error = error;
        if (!doNotShowToastr) {
            const errorMessage = error.response && error.response.data && error.response.data.errors && error.response.data.errors.length ? error.response.data.errors[0].description : error.message;
            if (errorMessage) {
                toast.error(`${errorMessage}`);
            }
        }
    } finally {
        if (spinnerElementId) {
            spinnerService.hide(spinnerElementId);
        }
    }
    return getResult;
}

export const deleteSync = async (endpoint, spinnerElementId = null, doNotShowToastr = false) => {

    const getResult = { isSuccess: false, response: null, error: null };

    if (spinnerElementId) {
        spinnerService.show(spinnerElementId);
    }

    try {
        await myAxios.delete(endpoint);
        getResult.isSuccess = true;
        return getResult;
    } catch (error) {
        getResult.isSuccess = false;
        getResult.error = error;
        if (!doNotShowToastr) {
            const errorMessage = error.response && error.response.data && error.response.data.errors && error.response.data.errors.length ? error.response.data.errors[0].description : error.message;
            if (errorMessage) {
                toast.error(`${errorMessage}`);
            }
        }
    } finally {
        if (spinnerElementId) {
            spinnerService.hide(spinnerElementId);
        }
    }
    return getResult;
}

//Lazım olunca kullanabilmek adına bu satırları burada bırakıyorum.
var mock = new MockAdapter(myAxios, { delayResponse: 450 });
mock.onGet('xms-history-api/api/history/ui-definition/get').reply(200, {
    "result": {
        "id": "ID",
        "history": {
            "status": "Status",
            "volume": "Volume",
            "quantity": "Quantity",
            "shipment": {
                "itemQuantity": "Item Quantity",
                "hasTHU": "Has any THU",
                "volume": "S. Volume"
            }
        }
    }, "success": true, "errors": []
})
    .onAny().passThrough();

export default post;