import { formInputTypes } from "../../FormComponentTypes";
import * as Renderers from "./renders";
import { parseRules } from "../constants/RuleParser";

const processRulesOnField = (field, formRules, formValues) => {
    const parsedRules = parseRules(formRules);
    const disablingRules = parsedRules.filter(r => r.then.fieldId === field.id && r.then.action === 'DISABLED');

    if (!field.isDisabled) { // Kurallar işletilmeden direk disable seçilmişse, kuralları işletmeye gerek yok. Disable çizilecek demektir.
        let isDisabled = false;
        for (let i = 0; i < disablingRules.length; i++) {
            const rule = disablingRules[i];
            switch (rule.if.condition) {
                case 'EMPTY':
                    isDisabled = !formValues || !formValues[rule.if.fieldId];
                    break;
                default:
                    isDisabled = formValues && formValues[rule.if.fieldId] !== rule.if.condition;
                    break;
            }

            if (isDisabled) { // Herhangi bir kuraldan ötürü disable oldu ise, diğer kuralları işletmeye gerek yok. Disable çizilecek demektir.
                break;
            }
        }
        field = {
            ...field,
            isDisabled: isDisabled
        }

    }
    return field;
}

const bindOnChangeToField = (field, onChangeEvent) => { return { ...field, onChange: onChangeEvent } }

export const FieldRenderer = (fields, props, formColumnCount) => {
    const orderedSchema = fields.slice(0).sort((first, second) => (first.ordering > second.ordering) ? 1 : -1);
    return orderedSchema.map((field) => {
        field = processRulesOnField(field, props.formSchema.Rules, props.formValues);
        field = bindOnChangeToField(field, props.onFieldChange);
         
        field = {
            ...field,
            dynamicRules: (props.fieldRules && props.fieldRules[field.id]) || {}
        } 
      
        if (field.type === formInputTypes.HIDDEN_INPUT) {
            return Renderers.HiddenRenderer(field);
        }
        if (field.type === formInputTypes.STRING_INPUT) {
            return Renderers.StringRenderer(field, formColumnCount);
        }
        if (field.type === formInputTypes.DATE_INPUT) {
            return Renderers.DateRenderer(field, formColumnCount);
        }
        
        if (field.type === formInputTypes.TIME_INPUT) {
            return Renderers.TimeRenderer(field, formColumnCount);
        }

        if (field.type === formInputTypes.NUMBER_INPUT) {
            return Renderers.NumberRenderer(field, formColumnCount);
        }
        if (field.type === formInputTypes.TEXTAREA_INPUT) {
            return Renderers.TextAreaRenderer(field, formColumnCount);
        }
        if (field.type === formInputTypes.RADIO_INPUT) {
            return Renderers.RadioButtonListRenderer(field, formColumnCount);
        }
        
        if (field.type === formInputTypes.CHECKBOX_INPUT) {
            return Renderers.CheckboxRenderer(field, formColumnCount);
        }
        

        if (field.type === formInputTypes.COMBO_INPUT) {
            const listItem = props[field.listOfItems] ? [...props[field.listOfItems]] : [];
            const firstItemObject = props[field.firstItemObject];
            const doNotCreateEmptyOption = props[field.doNotCreateEmptyOption];
            return Renderers.ComboboxRenderer(listItem, firstItemObject, doNotCreateEmptyOption, field, formColumnCount);
        }
    });
};