/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Button, ButtonStack } from '@dfds-ui/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import classes from '../Thu/ThuList.module.css';
import { showHazardousModal, deleteHazardousItem } from '../../store/action/shipmentDetailAction'
import { confirmationModal } from '../../store/action/modalActions'
import PolicyGroupAccessHoc from "../../RoutedComponents/Routes/PolicyGroupAccessHoc";

const ShipmentHazardous = (props) => {

    //parent inputs
    const {
        shipmentId,
        shipmentStatus,
        shipmentDeleted,
        hazardousItemList
    } = props;

    //redux
    const {
        showHazardousModal,
        confirmationModal,
        deleteHazardousItem
    } = props;

    const renderCol = (text, shortText, value, lg = 3, md = 4, sm = 6, xs = 12) => {
        if (value) {
            return (
                <Col lg={lg} md={md} sm={sm} xs={xs} className="mb-1">
                    <Row className="d-none d-lg-block">
                        <Col>
                            <span className="text-title">{text}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <span className="d-lg-none text-title">{shortText}: </span>
                            <span className="color-text-grey">{value}</span>
                        </Col>
                    </Row>
                </Col>
            )
        }
        return null;
    }

    const renderAddButton = () => {
        return (
            <PolicyGroupAccessHoc componentName="HazardousFullAccessHOC">
                <Button disabled={shipmentStatus === "Shipped" || shipmentDeleted} size="small" variation="primary" onClick={() => showHazardousModal(shipmentId)}>
                    <FontAwesomeIcon icon={['fas', 'plus']} className="mr-2" />Add New
            </Button>
            </PolicyGroupAccessHoc>
        )
    }

    const renderEdit = (item) => {
        return (
            <PolicyGroupAccessHoc componentName="HazardousFullAccessHOC">
                <Button disabled={shipmentStatus === "Shipped" || shipmentDeleted} className="input-block" variation="primary" size="small" onClick={() => showHazardousModal(shipmentId, item)}>
                    <FontAwesomeIcon icon="pen" className="mr-2" /> Edit
            </Button>
            </PolicyGroupAccessHoc>
        )
    }

    const renderDelete = (id) => {
        return (
            <PolicyGroupAccessHoc componentName="HazardousFullAccessHOC">
                <Button disabled={shipmentStatus === "Shipped" || shipmentDeleted} className="input-block" variation="outlined" size="small" onClick={() => onDeleteHazardousItem(shipmentId, id, `loader-hazardousitem-${id}`)}>
                    <FontAwesomeIcon icon="trash" className="mr-2" /> Delete
            </Button>
            </PolicyGroupAccessHoc>
        )
    }


    const onDeleteHazardousItem = (shipmentId, id, loader) => {
        confirmationModal('Hazardous Item', 'Hazardous item will be deleted. Do you approve?', () => deleteHazardousItem(shipmentId, id, loader));
    }

    const renderList = () => {

        if (!hazardousItemList || hazardousItemList.length == 0) {
            return (
                <h6>
                    No hazardous registered for this shipment
                </h6>
            )
        }

        return hazardousItemList.map((item, i) => {

            return (
                <div className="card mb-1" key={i}>
                    <LoadingIndicator id={`loader-hazardousitem-${item.id}`} />
                    <div className={`card-body ${classes["thuList-card-body"]}`}>
                        <Row className={`align-items-center`}>
                            <Col lg={10} md={9} sm={8} xs={6}>
                                <Row>
                                    {renderCol('Name', 'Name', item.name, 12, 12, 12, 12)}
                                    {renderCol('Class', 'Class.', item.class)}
                                    {renderCol('UNDG', 'UNDG', item.undg)}
                                    {renderCol('EMS', 'EMS', item.ems)}
                                    {renderCol('MFAG', 'MFAG', item.mfag)}
                                    {renderCol('Sub Class', 'Sub C.', item.subClass)}
                                    {renderCol('Net Weight', 'Net W.', item.netWeight)}
                                    {renderCol('Gross Weight', 'Gross W.', item.grossWeight)}
                                    {renderCol('Flash Point', 'Flash P.', item.flashPoint)}
                                    {renderCol('Packing Group', 'Packing G.', item.packingGroup)}
                                    {renderCol('Marine Pollutant', 'Marine P.', item.packingGroup)}
                                </Row>
                            </Col>
                            <Col lg={2} md={3} sm={4} xs={6} >
                                <ButtonStack align="right">
                                    {renderEdit(item)}
                                    {renderDelete(item.id)}
                                </ButtonStack>
                            </Col>
                        </Row>
                    </div>
                </div>
            )
        })
    }

    return (
        <div className='card'>
            <div className='card-header collapsed' href={`#collapseHazardousItemList`} data-toggle='collapse'>
                <Row>
                    <Col xs={8}>
                        <h5 className='mb-0'>
                            <img src='/image/icon/down.svg' className='color-action-blue' width='20px' alt='' /> Hazardous Items ({hazardousItemList.length})
                        </h5>
                    </Col>
                    <Col xs={4} className="text-right">
                        {hazardousItemList.length > 0 ? <FontAwesomeIcon icon="exclamation-triangle" /> : null}
                    </Col>
                </Row>
            </div>
            <div id={'collapseHazardousItemList'} className='collapse'>
                <div className='card card-body'>
                    <LoadingIndicator id={`loader-hazardousitem`} />
                    <Row className="mb-3">
                        <Col>
                            <ButtonStack>
                                {renderAddButton()}
                            </ButtonStack>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            {renderList()}
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )

}

const mapStateToProps = ({ }) => {
    return {

    }
}

const mapActionToProps = {
    showHazardousModal,
    confirmationModal,
    deleteHazardousItem
}

export default connect(mapStateToProps, mapActionToProps)(ShipmentHazardous);