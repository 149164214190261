/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ImageControllerOperationButtons = (props) => {

    let inputFileElement;

    //from parent component
    const {
        imageSelected,
        uploadImages
    } = props;

    //from store
    const {
        imageList
    } = props;

    
    const filesSelected = (e) => {
        imageSelected(e.target.files)
        e.target.values = '';
        e.target.value = '';
    }

    const renderNewImageButton = () => {
        return (
            <>
                <input
                    ref={input => inputFileElement = input}
                    type='file'
                    className='d-none'
                    onChange={(e) => filesSelected(e)}
                    multiple
                />
                <a onClick={() => inputFileElement.click()}>
                    <FontAwesomeIcon title="Add Image" icon="camera" size="2x" className="mx-3" />
                </a>
            </>
        )
    }

    return (
        <>
            <div className="col">
                <div className='d-flex'>
                    {renderNewImageButton()}
                    {renderUploadButton(imageList, uploadImages)}
                </div>
            </div>
        </>
    )
}

const renderUploadButton = (imageList, uploadImages) => {

    const imageListAsArray = imageList ? Object.values(imageList) : [];

    const haveNotUploadedImage = imageListAsArray.some(im => im.status === 'not-uploaded');

    if (haveNotUploadedImage) {
        return (
            <a onClick={uploadImages}>
                <FontAwesomeIcon title="Upload Images" icon="upload" size="2x" className="mx-3 color-yes-green" />
            </a>
        )
    }

    return (
        <a>
            <FontAwesomeIcon title="Upload Images" icon="upload" size="2x" className="mx-3" />
        </a>
    )

}

const mapStateToProps = ({ imageController }) => {
    return {
        imageList: imageController.imageList
    }
}

export default connect(mapStateToProps)(ImageControllerOperationButtons);