/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { getSuffixList } from '../store/action/notificationActions';
import { deleteSuffix } from '../store/ducks/definitions/actionCreators';
import { Form } from 'react-bootstrap';
import history from '../core/history';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import XmsTable from '../component/XmsTable/XmsTable';
import Page from '../component/Page/Page';

const Suffixes = ({ suffixList, getSuffixList, deleteSuffix }) => {

    useEffect(() => {
        if (!suffixList) {
            getSuffixList();
        }
        setFilteredData(suffixList);
    }, [suffixList]);

    useEffect(() => {
        getSuffixList();
        setFilteredData(suffixList);
    }, []);

    const [filteredData, setFilteredData] = useState(suffixList);
    const searchTermChangedHandler = (key) => {
        const searchTerm = key.toLowerCase();
        if (!searchTerm) {
            setFilteredData(suffixList);
            return;
        }

        const foundSuffixes = suffixList.filter(suffix => {
            return Object
                .values(suffix)
                .some(suffixPropValue => {
                    return typeof suffixPropValue === "string" ?
                        suffixPropValue.toLowerCase().indexOf(searchTerm) > -1 : false;
                });
        });
        setFilteredData(foundSuffixes);
    }

    const notificationEmailClickedHandler = (suffix) => {
        history.push('suffixes/email/' + suffix.code);
    }

    const notificationEditClickedHandler = (suffix) => {
        history.push('suffixes/' + suffix.id);
    }

    const notificationDeleteClickedHandler = (suffix) => {
        if (confirm('Do you want to delete this suffix? ')) {//eslint-disable-line

            
            deleteSuffix(suffix.id).then((x) => {
                getSuffixList();
            });
           
           
        }
    }
    const newSuffixClickedHandler = () => {
        history.push('suffixes/new');
    }

    const renderCountryColumn = row => {
        return row.address && row.address.country ? [row.address.country.code, row.address.country.name].join(' - ') : "";
    }

    const renderCityColumn = row => {
        return row.address ? row.address.city : "";
    }

    const renderFilters = () => {
        return <Form.Control type="text" placeholder="Search" onChange={(e) => searchTermChangedHandler(e.target.value)} />
    }

    const tableConfigurations = {
        title: 'Suffixes',
        noRowText: 'There are not any suffixes to show.',
        hasFilter: true,
        showFiltersOnInit: true,
        entities: filteredData,
        columns: [
            { alias: 'Name', name: 'name', type: 'string' },
            { alias: 'Desc', name: 'shortDescription', type: 'string' },
            { alias: 'Company', name: 'longDescription', type: 'string' },
            { alias: 'City', name: '', type: 'custom', customRender: renderCityColumn },
            { alias: 'Country', name: '', type: 'custom', customRender: renderCountryColumn, style: { whiteSpace: 'nowrap' } }
        ],
        tableActions: [
            { clickEvent: newSuffixClickedHandler, buttonText: 'Create', icon: 'plus' }
        ],
        rowActions: [
            { title: 'Edit suffix e-mails', clickEvent: notificationEmailClickedHandler, icon: 'envelope' },
            { title: 'Edit suffix', clickEvent: notificationEditClickedHandler, icon: 'edit' },
            { title: 'Delete suffix', clickEvent: notificationDeleteClickedHandler, icon: 'trash' }
        ],
        filterRenderer: renderFilters,
    }
    return <Page>
        <XmsTable config={tableConfigurations} />
        <LoadingIndicator id="notificationGetSuffixList" />
    </Page >
}
const mapStateToProps = (state) => {
    return {
        suffixList: state.notification.suffixList
    }
}
export default connect(mapStateToProps, { getSuffixList, deleteSuffix })(Suffixes);