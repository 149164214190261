import { Col, Form } from "react-bootstrap";
import { AuthorizationWrapper } from "./AuthorizationRenderer";
import * as PropTypes from "prop-types";
import React from "react";
import { StringInputFormComponent } from "../../StringInputFormComponent";

export const StringRenderer = (field, formColumnCount) => {
    const stringControl = (<Form.Group key={field.id} as={Col} md={formColumnCount}>
        <Form.Label>{field.name}{field.isRequired && ' *'}</Form.Label>
        <StringInputFormComponent name={field.id} isRequired={field.isRequired} validationArray={field.validations} isDisabled={field.isDisabled} onChange={field.onChange} />
    </Form.Group>);

    if (field.authorizationWrapper) {
        return AuthorizationWrapper(stringControl, field.id, field.authorizationWrapper);
    }
    return stringControl;
};

StringRenderer.propTypes = {
    item: PropTypes.object.isRequired,
    formColumnCount: PropTypes.number.isRequired
};