export const styles = `
@page {
  /* Page-Margin Box rules */
  @top-left-corner {
    visibility: hidden;
  }
  @top-left {
    visibility: hidden;
  }
  @top-center {
    visibility: hidden;
  }
  @top-right {
    visibility: hidden;
  }
  @top-right-corner {
    visibility: hidden;
  }

  @bottom-right-corner {
    visibility: hidden;
  }
  @bottom-right {
    visibility: hidden;
  }
  @bottom-center {
    visibility: hidden;
  }
  @bottom-left {
    visibility: hidden;
  }
  @bottom-left-corner {
    visibility: hidden;
  }
  /* END Page-Margin Box rules */
}

* {
  box-sizing: border-box;
}

html {
  font-family: Arial, sans-serif;
}

h1 {
  font-size: 0.8rem /*11pt*/;
  font-weight: normal;
}

/************* Table common *************/
table.main {
  page-break-before: always;
  break-before: page;
  page-break-inside: avoid;
  break-inside: avoid;
  page-break-after: always;
  break-after: page;
}

tr,
td {
  page-break-inside: avoid;
  break-inside: avoid;
}

tr.table-foot {
  font-size: 1rem; /*18pt;*/
}

td,
th {
  border: 2px solid rgb(0, 0, 0);
  padding: 2px 4px;
}

.container {
  width: 100%;
}

.item {
  display: inline-block;
  width: 40%;
}

.item3 {
  display: inline-block;
  width: 30%;
  border-bottom: 2px dashed black;
}

.item4 {
  display: inline-block;
  width: 24%;
}

.under-dashed > p {
  border-bottom: 2px dashed black;
  margin: 2rem;
}

.side-note {
  vertical-align: bottom;
  word-break: normal;
  -webkit-hyphens: manual;
  -ms-hyphens: manual;
  hyphens: manual;
}

.page-no {
  vertical-align: bottom;
  font-size: 3rem;
}

@supports (appearance: none) or (-webkit-appearance: none) or (-moz-appearance: none) {
  @media screen, print {
    input[type='checkbox'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 1.25em;
      height: 1.25em;
      border: 2px solid black;
      outline: none;
      vertical-align: bottom;
    }
    
    input[type='checkbox']:checked {
      background: black;
    }

    input[type='checkbox']:disabled {
      cursor: not-allowed;
    }
  }
}
/************* ENd Table common *************/

/************* Main table, outermost *************/
.main {
  margin: 5px 0; /* important to fit the width of the table to container */
  border-collapse: collapse;
}

.main > tbody > tr > td:first-child {
  border: none;
}

.main > tbody > tr > td:last-child {
  border: none;
}
/************* END Main table, outermost *************/

/************* THU table *************/
.thu-table {
  width: 100%;
  border: none;
  text-align: center;
}

.thu-table td,
.thu-table th {
}

.thu-table th {
  border-style: none solid none none;
}

.thu-table td {
  border: none;
}

.thu-table tfoot th,
.thu-table tfoot td {
  border: none;
}
/************* END THU table *************/

/************* Hazardous items table *************/
.hazardous-items-table {
  width: 100%;
  border: none;
  text-align: center;
}

.hazardous-items-table th {
  border-style: none none solid none;
}

.hazardous-items-table td {
  border-style: none none dashed none;
  border-width: 2px;
}
/************* END Hazardous items table *************/
`;
