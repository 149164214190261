import currencyActions from '../type/currency';

const initialState = {
    currencies: null,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case currencyActions.CURRENCIES_RETRIEVED:
            return {
                ...state,
                currencies: action.payload
            }
        default:
            return state;
    }

}