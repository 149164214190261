/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import LoginForm from "../component/Login/LoginForm";
import { login } from "../store/action/authActions";
import UserContext from "../context/UserContext";
import history from "../core/history";
import { getRedirectUrl } from "../core/authentication";

const Login = ({ login }) => {
  const authenticatedUser = useContext(UserContext);
  const [afterLogin, setAfterLogin] = useState();

  useEffect(() => {
    if (authenticatedUser) {
      redirectToNextPage();
    } else {
      if (window.location.search) {
        setAfterLogin(window.location.search.replace("?afterLogin=", ""));
      }
    }
  }, [authenticatedUser]);

  const redirectToNextPage = () => {
    if (afterLogin && afterLogin !== '/') {
      history.push(afterLogin);
    } else {
      history.push(getRedirectUrl());
    }
  };

  const onFormSubmit = (email, password) => {
    login(email, password);
  };

  return (
    <>
      <LoginForm onFormSubmit={onFormSubmit} />
    </>
  );
};

export default connect(null, { login })(Login);
