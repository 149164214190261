import { combineReducers } from "redux";
import actionTypes from "./actionTypes";

const initialState = {
  associates: {
    isRequesting: false,
    items: [],
    errors: []
  },
  connectionPoints: {
    isRequesting: false,
    items: [],
    errors: []
  },
  suffixes: {
    isRequesting: false,
    items: [],
    errors: []
  },
  /**shipment status types */
  types: {
    isRequesting: false,
    items: [],
    errors: []
  },
  currencies: {
    isRequesting: false,
    items: [],
    errors: []
  },
  countries: {
    isRequesting: false,
    items: [],
    errors: []
  },
  settings: {
    isRequesting: false,
    items: [],
    errors: []
  },
  companies: {
    isRequesting: false,
    items: [],
    errors: [],
    filters: [
      {Name: "isClient", Value: null, Operator: "="},
      {Name: "companyCode", Value: null, Operator: "="},
      {Name: "companyName", Value: null, Operator: "="},
      {Name: "companyType", Value: null, Operator: "="}
    ],
  }
};

/** ASSOCIATES */
function reduceAssociates(state = initialState.associates, action) {
  switch (action.type) {
    // FILTER
    case actionTypes.SEARCH_ASSOCIATES_REQUESTING:
      return { ...state, isRequesting: true };
    case actionTypes.SEARCH_ASSOCIATES_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: state.items.reduce((acc, item) => {
          return acc.find(newItem => newItem.id === item.id) ?
            [...acc] :
            [...acc, item];
        }, action.payload), // insert if not exist
        errors: []
      };
    case actionTypes.SEARCH_ASSOCIATES_FAILURE:
      return { ...state, isRequesting: false, errors: action.payload };
    // end FILTER
    default:
      return state;
  }
}
/** end ASSOCIATES */



/** CONNECTION POINTS */
function reduceConnectionPoints(state = initialState.connectionPoints, action) {
  switch (action.type) {
    // CREATE
    case actionTypes.CREATE_CONNECTION_POINT_REQUESTING:
      return { ...state, isRequesting: true };
    case actionTypes.CREATE_CONNECTION_POINT_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: [...state.items, action.payload],
        errors: []
      };
    case actionTypes.CREATE_CONNECTION_POINT_FAILURE:
      return { ...state, isRequesting: true, errors: action.payload };
    // end CREATE
    // READ singular
    case actionTypes.READ_CONNECTION_POINT_REQUESTING:
      return { ...state, isRequesting: true };
    case actionTypes.READ_CONNECTION_POINT_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: state.items.reduce((acc, item) => {
          return acc.find(newItem => newItem.id === item.id) ?
            [...acc] :
            [...acc, item]
        }, action.payload.result ? [action.payload.result] : []), // insert if not exist
        errors: []
      };
    case actionTypes.READ_CONNECTION_POINT_FAILURE:
      return { ...state, isRequesting: false, errors: action.payload };
    // end READ singular
    // READ plural
    case actionTypes.READ_CONNECTION_POINTS_REQUESTING:
      return { ...state, isRequesting: true };
    case actionTypes.READ_CONNECTION_POINTS_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: state.items.reduce((acc, item) => {
          return acc.find(newItem => newItem.id === item.id) ?
            [...acc] :
            [...acc, item]
        }, action.payload.result), // insert if not exist
        errors: []
      };
    case actionTypes.READ_CONNECTION_POINTS_FAILURE:
      return { ...state, isRequesting: false, errors: action.payload };
    // end READ plural
    // UPDATE
    case actionTypes.UPDATE_CONNECTION_POINT_REQUESTING:
      return { ...state, isRequesting: true };
    case actionTypes.UPDATE_CONNECTION_POINT_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: state.items.map(item => {
          return item.id === action.payload.id ?
            { ...item, ...action.payload } :
            item
        }),
        errors: []
      };
    case actionTypes.UPDATE_CONNECTION_POINT_FAILURE:
      return { ...state, isRequesting: false, errors: action.payload };
    // end UPDATE
    // DELETE singular
    case actionTypes.DELETE_CONNECTION_POINT_REQUESTING:
      return { ...state, isRequesting: true };
    case actionTypes.DELETE_CONNECTION_POINT_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: state.items.filter(item => item.id !== action.payload.connectionPointId),
        errors: []
      };
    case actionTypes.DELETE_CONNECTION_POINT_FAILURE:
      return { ...state, isRequesting: false, errors: action.payload };
    // end DELETE singular
    // DELETE plural
    case actionTypes.DELETE_CONNECTION_POINTS_REQUESTING:
      return { ...state, isRequesting: true };
    case actionTypes.DELETE_CONNECTION_POINTS_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: state.items.filter(item => !action.payload.includes(item.id)),
        errors: []
      };
    case actionTypes.DELETE_CONNECTION_POINTS_FAILURE:
      return { ...state, isRequesting: false, errors: action.payload };
    // end DELETE plural
    // FILTER
    case actionTypes.SEARCH_CONNECTION_POINTS_REQUESTING:
      return { ...state, isRequesting: true };
    case actionTypes.SEARCH_CONNECTION_POINTS_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: action.payload,
        errors: []
      };
    case actionTypes.SEARCH_CONNECTION_POINTS_FAILURE:
      return { ...state, isRequesting: false, errors: action.payload };
    // end FILTER
    default:
      return state;
  }
}
/* end CONNECTION POINTS */



/* SUFFIXES */
function reduceSuffixes(state = initialState.suffixes, action) {
  switch (action.type) {
    // CREATE
    case actionTypes.CREATE_SUFFIX_REQUESTING:
      return { ...state, isRequesting: true };
    case actionTypes.CREATE_SUFFIX_SUCCESS:
      return {
        ...state,
        isRequesting: true,
        items: [...state.items, action.payload.suffix],
        errors: [],
      };
    case actionTypes.CREATE_SUFFIX_FAILURE:
      return { ...state, isRequesting: true, errors: action.payload };
    // end CREATE
    // READ
    case actionTypes.FETCH_SUFFIXES_REQUESTING:
      return { ...state, isRequesting: true };
    case actionTypes.FETCH_SUFFIXES_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: state.items.reduce(
          (acc, item) =>
            acc.find((newItem) => newItem.id === item.id)
              ? [...acc]
              : [...acc, item],
          action.payload
        ),
        errors: [],
      };
    case actionTypes.FETCH_SUFFIXES_OF_CONNECTION_POINT_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: action.payload,
        errors: [],
      };
    case actionTypes.FETCH_SUFFIXES_FAILURE:
      return { ...state, isRequesting: false, errors: action.payload };
    // end READ
    // UPDATE
    case actionTypes.UPDATE_SUFFIXES_REQUESTING:
      return { ...state, isRequesting: true };
    case actionTypes.UPDATE_SUFFIXES_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: state.items.map((item) => {
          return item.id === action.payload.suffix.id
            ? { ...item, ...action.payload.suffix }
            : item;
        }),
        errors: [],
      };
    case actionTypes.UPDATE_SUFFIXES_FAILURE:
      return { ...state, isRequesting: false, errors: action.payload };
    // end UPDATE
    // DELETE
    case actionTypes.DELETE_SUFFIXES_REQUESTING:
      return { ...state, isRequesting: true };
    case actionTypes.DELETE_SUFFIXES_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: action.payload.filter((item) => item.id !== action.payload.id),
        errors: [],
      };
    case actionTypes.DELETE_SUFFIXES_FAILURE:
      return { ...state, isRequesting: false, errors: action.payload };
    // end DELETE
    default:
      return state;
  }
}
/* end SUFFIXES */



/** TYPES shipment status types */
function reduceTypes(state = initialState.types, action) {
  switch (action.type) {
    // READ
    case actionTypes.FETCH_TYPES_REQUESTING:
      return { ...state, isRequesting: true };
    case actionTypes.FETCH_TYPES_SUCCESS:
      return { ...state, isRequesting: false, items: action.payload, errors: [] };
    case actionTypes.FETCH_TYPES_FAILURE:
      return { ...state, isRequesting: false, errors: action.payload };
    // end READ
    default:
      return state;
  }
}
/** end TYPES shipment status types*/



/** CURRENCIES */
function reduceCurrencies(state = initialState.currencies, action) {
  switch (action.type) {
    // FILTER
    case actionTypes.FILTER_CURRENCIES_REQUESTING:
      return { ...state, isRequesting: true };
    case actionTypes.FILTER_CURRENCIES_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: state.items.reduce(
          (acc, item) =>
            acc.find((newItem) => newItem.id === item.id)
              ? [...acc]
              : [...acc, item],
          action.payload
        ), // insert if not exist
        errors: []
      };
    case actionTypes.FILTER_CURRENCIES_FAILURE:
      return { ...state, isRequesting: false, errors: action.payload };
    // end FILTER
    default:
      return state;
  }
}
/** end CURRENCIES */



/** COUNTRIES */
function reduceCountries(state = initialState.countries, action) {
  switch (action.type) {
    // FILTER
    case actionTypes.FILTER_COUNTRIES_REQUESTING:
      return { ...state, isRequesting: true };
    case actionTypes.FILTER_COUNTRIES_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: state.items.reduce(
          (acc, item) =>
            acc.find((newItem) => newItem.id === item.id)
              ? [...acc]
              : [...acc, item],
          action.payload
        ), // insert if not exist
        errors: []
      };
    case actionTypes.FILTER_COUNTRIES_FAILURE:
      return { ...state, isRequesting: false, errors: action.payload };
    // end FILTER
    default:
      return state;
  }
}
/** end COUNTRIES */

/** SETTINGS */
function reduceSettings(state = initialState.settings, action) {
  switch (action.type) {
    // FILTER
    case actionTypes.FILTER_SETTINGS_REQUESTING:
      return { ...state, isRequesting: true };
    case actionTypes.FILTER_SETTINGS_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: state.items.reduce(
          (acc, item) =>
            acc.find((newItem) => newItem.key === item.key)
              ? [...acc]
              : [...acc, item],
          [action.payload]
        ),
        errors: []
      };
    case actionTypes.FILTER_SETTINGS_FAILURE:
      return { ...state, isRequesting: false, errors: action.payload };
    // end FILTER
    default:
      return state;
  }
}
/** end SETTINGS */

/** COMPANIES */
function reduceCompanies(state = initialState.companies, action) {
  switch (action.type) {
    // CREATE
    case actionTypes.CREATE_COMPANY_REQUESTING:
      return { ...state, isRequesting: true };
    case actionTypes.CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: state.items.reduce(
          (acc, item) =>
            acc.find((newItem) => newItem.id === item.id)
              ? [...acc]
              : [...acc, item],
          [action.payload]),
        createdCompany: action.payload,
        errors: [],
      };
    case actionTypes.CREATE_COMPANY_FAILURE:
      return { ...state, isRequesting: false, errors: action.payload };
    // end CREATE
    // READ singular
    case actionTypes.READ_COMPANY_REQUESTING:
      return { ...state, isRequesting: true };
    case actionTypes.READ_COMPANY_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: state.items.reduce(
          (acc, item) => {
            return acc.find((newItem) => newItem.id === item.id)
              ? [...acc]
              : [...acc, item];
          },
          action.payload.result ? [action.payload.result] : null
        ), // insert if not exist
        createdCompany: null,
        // updatedCompany: null,
        // updatedCompanyTracker: null,
        // deletedCompany: null,
        // deletedCompanyTracker: null,
        errors: []
      };
    case actionTypes.READ_COMPANY_FAILURE:
      return { ...state, isRequesting: false, errors: action.payload };
    // end READ singular
    // READ plural
    case actionTypes.READ_COMPANIES_REQUESTING:
      return { ...state, isRequesting: true };
    case actionTypes.READ_COMPANIES_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: state.items.reduce((acc, item) => {
          return acc.find((newItem) => newItem.id === item.id)
            ? [...acc]
            : [...acc, item];
        }, action.payload.connectionPoints), // insert if not exist
        errors: [],
      };
    case actionTypes.READ_COMPANIES_FAILURE:
      return { ...state, isRequesting: false, errors: action.payload };
    // end READ plural
    // UPDATE
    case actionTypes.UPDATE_COMPANY_REQUESTING:
      return { ...state, isRequesting: true };
    case actionTypes.UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: state.items.map((item) => {
          return item.id === action.payload.id
            ? { ...item, ...action.payload }
            : item;
        }),
        // updatedCompany: action.payload,
        // updatedCompanyTracker: new Date().getTime() * 10000, //when use effect is used for updatedCompany, the field never changes, so a tracking field is used
        errors: [],
      };
    case actionTypes.UPDATE_COMPANY_FAILURE:
      return { ...state, isRequesting: false, errors: action.payload };
    // end UPDATE
    // DELETE singular
    case actionTypes.DELETE_COMPANY_REQUESTING:
      return { ...state, isRequesting: true };
    case actionTypes.DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: state.items.filter(
          (item) => item.id !== action.payload
        ),
        // deletedCompanyTracker: new Date().getTime() * 10000, //when use effect is used for deletedCompany, the field never changes, so a tracking field is used,
        // deletedCompany: action.payload,
        errors: [],
      };
    case actionTypes.DELETE_COMPANY_FAILURE:
      return { ...state, isRequesting: false, errors: action.payload };
    // end DELETE singular
    // DELETE plural
    case actionTypes.DELETE_COMPANIES_REQUESTING:
      return { ...state, isRequesting: true };
    case actionTypes.DELETE_COMPANIES_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: state.items.filter(
          (item) => !action.payload.Identifiers.includes(item.id)
        ),
        errors: [],
      };
    case actionTypes.DELETE_COMPANIES_FAILURE:
      return { ...state, isRequesting: false, errors: action.payload };
    case actionTypes.SEARCH_COMPANIES_REQUESTING:
      return { ...state, isRequesting: true };
    case actionTypes.SEARCH_COMPANIES_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: action.payload,
        errors: [],
      };
    case actionTypes.SEARCH_COMPANIES_FAILURE:
      return { ...state, isRequesting: false, errors: action.payload };
    case actionTypes.SET_FILTER:
      const { Name, Value, Operator } = action.payload;
      return {
        ...state, filters: state.filters.map(filter => (filter.Name === Name && filter.Operator === Operator) === true
          ? { ...filter, Value: Value }
          : filter
        )
      };
    case actionTypes.SET_FILTERS:
      return {
        ...state, filters: action.payload
      };
    case actionTypes.RESET_ALL_FILTERS:
      return { ...state, filters: state.filters.map(filter => ({ ...filter, Value: "" })) };
    default:
      return state;
  }
}
/** end COMPANIES */


const reducer = combineReducers({
  associates: reduceAssociates,
  connectionPoints: reduceConnectionPoints,
  suffixes: reduceSuffixes,
  types: reduceTypes,
  currencies: reduceCurrencies,
  countries: reduceCountries,
  settings: reduceSettings,
  companies: reduceCompanies
});

export default reducer;