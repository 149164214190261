import React, { useState, useEffect } from 'react';
import { createSuffix, updateSuffix, deleteSuffix, searchAssociates, searchAssociatesSuccess, fetchTypes } from '../../store/ducks/definitions/actionCreators';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './Suffix.module.css';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { Minus } from '@dfds-ui/icons/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Suffix({
    suffix,
    editingMode,
    createSuffix,
    updateSuffix,
    deleteSuffix,
    associates,
    searchAssociates,
    searchAssociatesSuccess,
    articleFields,
    fetchTypes,
}) {
    let history = useHistory();

    // STATE
    // put props in state to work on them, dont use these props but state then on
    // backup state for reset function to return to
    const [id, setId] = useState('');
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [address, setAddress] = useState(null);
    const [city, setCity] = useState(null);
    const [shortDescription, setShortDescription] = useState('');
    const [longDescription, setLongDescription] = useState('');
    const [country, setCountry] = useState({});
    const [countryCode, setCountryCode] = useState('');
    const [selectedArticleFields, setSelectedArticleFields] = useState([]);
    const [compareWithExpectedCargo, setCompareWithExpectedCargo] = useState(false);
    const [remeasurement, setRemeasurement] = useState(false);

    const [suppliers, setSuppliers] = useState([]); // [object]
    const [newSupplierName, setNewSupplierName] = useState('');

    const [endCustomer, setEndCustomer] = useState({}); // object
    // end STATE

    useEffect(() => {
        fetchTypes()
    }, [])

    // GET STATE FROM PROPS
    // if suffixes ready at first render use useState(prop), if not useEffect(..., [prop])
    useEffect(() => {
        if (suffix) {
            setId(suffix.id);
            setName(suffix.name);
            setCode(suffix.name);
            suffix.address && setCity(suffix.address.city);
            setShortDescription(suffix.shortDescription);
            setLongDescription(suffix.longDescription);
            suffix.address && setCountry(suffix.address.country);
            suffix.address && suffix.address.country && setCountryCode(suffix.address.country.code);
            setSelectedArticleFields(suffix.articleFields ? suffix.articleFields : []);
            setRemeasurement(suffix.remeasurement);
            setCompareWithExpectedCargo(suffix.compareWithExpectedCargo);
            suffix.suppliers && setSuppliers(suffix.suppliers); // suffix.suppliers can be null down from server initially, if not set state with it
            if (suffix.endCustomers && suffix.endCustomers.length > 0) {
                setEndCustomer(suffix.endCustomers[0]); // prop can be null down from server initially, if not set state with it
                setEndCustomerQuery(format(suffix.endCustomers[0]));
                searchAssociatesSuccess([suffix.endCustomers[0]]); // if we normalized our data from network w smth like normalizr that would not be necessary here 
            }
        }
    }, [suffix]);
    // end GET STATE FROM PROPS

    // VALUE, FORMAT/DEFORMAT
    // format
    const format = (data) => {
        const codePart = data.code ? `(${data.code})` : '';
        const namePart = data.name ? `${data.name}` : '';
        const delimiter = ', ';
        return `${codePart}${delimiter}${namePart}`;
    }
    // end VALUE, FORMAT/DEFORMAT


    // ON CHANGE
    const onChangeNewSupplierName = (event) => {
        setNewSupplierName(event.target.value);
    };

    const [endCustomerQuery, setEndCustomerQuery] = useState('');

    const onChangeNewEndCustomerName = (event) => {
        setEndCustomerQuery(event.target.value);

        // Validate
        // if the typed/selected text include matches any existing assoc set state fields with those values from found assoc
        const matchedAssoc = associates.items.find(item => {
            return (
                [item.id, item.name].some((element) => element === event.target.value) // when there is no custom formatting on field, or when the field is empty and started being fields (e.g. create)
                ||
                event.target.value === format(item) // when there is a custom formatting
            );
        });
        // end Validate

        if (matchedAssoc) {
            setEndCustomer(matchedAssoc); // you need to set endCustomer only if it exists in associations
        }
    };


    useEffect(() => {
        if (city || countryCode) {
            setAddress({ city: city, country: country });
        }
    }, [city, country]);

    // Filter associates
    useEffect(() => {
        // TODO: abort request if a new request made. use abort controller in return of this effect
        if (endCustomerQuery && endCustomerQuery.length > 1) {
            searchAssociates({ QueryStringValue: endCustomerQuery });
        }
    }, [endCustomerQuery, searchAssociates]);

    useEffect(() => {
        // TODO: abort request if a new request made. use abort controller in return of this effect
        if (newSupplierName && newSupplierName.length > 1) {
            searchAssociates({ QueryStringValue: newSupplierName });
        }
    }, [newSupplierName]);
    // end Filter associates

    // end ON CHANGE

    // VALIDATION
    const [validityState, setValidityState] = useState({});
    // may carry validity error settings here in an effect
    useEffect(() => {
        if (newSupplierName) {
            const matchedAssociate = associates.items.find(item => item.name === newSupplierName);
            if (!matchedAssociate) {
                setValidityState(state => ({ ...state, supplier: 'Supplier must be an existing one' }));
            } else {
                setValidityState(state => ({ ...state, supplier: '' }));
            }
        } else {
            setValidityState(state => ({ ...state, supplier: '' }));
        }

        if (endCustomer.name) {
            const matchedAssociate = associates.items.find(item => item.name === endCustomer.name);
            if (!matchedAssociate) {
                setValidityState(state => ({ ...state, endCustomer: 'End Customer must be an existing one' }));
            } else {
                setValidityState(state => ({ ...state, endCustomer: '' }));
            }
        } else {
            setValidityState(state => ({ ...state, endCustomer: '' }));
        }
    }, [newSupplierName, endCustomer.name]);
    // end VALIDATION

    const onSubmit = (event) => {
        event.preventDefault();
        const isValid = Object.values(validityState).every(item => item === '');
        if (isValid) {

            const payload = { id, code, name, address, shortDescription, longDescription, compareWithExpectedCargo, remeasurement, suppliers, endCustomers: Object.keys(endCustomer).length > 0 ? [endCustomer] : null, articleFields: selectedArticleFields };
            suffix ?
                updateSuffix(payload).then(() => history.push('/suffixes'))
                :
                createSuffix(payload).then(() => history.push('/suffixes'));
        }
    };

    const onReset = (e) => e;

    // DELETE
    const onClickDelete = async () => {

        if (suffix.id) {
            if (confirm('Are you sure you want to delete this item?')) {//eslint-disable-line

                await deleteSuffix(suffix.id);
                history.goBack();
            }
        }
    }
    // end DELETE

    // BACK
    const onClickBack = () => history.push('/suffixes'); //history.goBack(); // if goBack not desirable, since back may not be the /connection-points, history.push('/connection-points');
    // end BACK

    const onArticleFieldChanged = (field, checked) => {
        var list = [...selectedArticleFields];
        if (checked) {
            list.push(field);
        } else {
            list = list.filter(s => s != field)
        }
        setSelectedArticleFields(list);
    }

    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                <Button style={{ fontSize: '14px', marginBottom: '17px' }} className='color-action-blue' variant="transparent" onClick={onClickBack}>
                                    <FontAwesomeIcon icon="chevron-left" size='lg' />
                                    <span style={{ paddingLeft: '10px' }}>
                                        {`Back to Suffixes`}
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>

                        <main>
                            <section>
                                <form onSubmit={onSubmit} onReset={onReset}>
                                    <Row>
                                        <Col xs="12" sm="6" md="6" lg="4">
                                            <label htmlFor="suffixName" className={styles.label}>Suffix Name</label> <br></br>
                                            <input id="suffixName" value={name} onChange={e => {
                                                setName(e.target.value);
                                                setCode(e.target.value);
                                            }}
                                                readOnly={editingMode !== 'create'}
                                            /><br></br>
                                            <small style={{ visibility: validityState.suffixName ? 'visible' : 'hidden' }} className="form-text text-danger">{validityState.suffixName || ''}</small>
                                        </Col>

                                        <Col xs="12" sm="6" md="6" lg="4">
                                            <label htmlFor="city" className={styles.label}>City</label><br></br>
                                            <input id="city" value={city || ''} onChange={e => setCity(e.target.value)} /><br></br>
                                            <small style={{ visibility: validityState.city ? 'visible' : 'hidden' }} className="form-text text-danger">{validityState.city || ''}</small>
                                        </Col>

                                        <Col xs="12" sm="6" md="6" lg="4">
                                            <label htmlFor="shortDescription" className={styles.label}>Short Description</label><br></br>
                                            <input id="shortDescription" value={shortDescription || ''} onChange={e => setShortDescription(e.target.value)} /><br></br>
                                            <small style={{ visibility: validityState.shortDescription ? 'visible' : 'hidden' }} className="form-text text-danger">{validityState.shortDescription || ''}</small>
                                        </Col>

                                        <Col xs="12" sm="6" md="6" lg="4">
                                            <label htmlFor="longDescription" className={styles.label}>Long Description</label><br></br>
                                            <input id="longDescription" value={longDescription || ''} onChange={e => setLongDescription(e.target.value)} /><br></br>
                                            <small style={{ visibility: validityState.longDescription ? 'visible' : 'hidden' }} className="form-text text-danger">{validityState.longDescription || ''}</small>
                                        </Col>

                                        <Col xs="12" sm="6" md="6" lg="4">
                                            <label htmlFor="country" className={styles.label}>Country</label><br></br>
                                            <select
                                                id="country"
                                                value={countryCode}
                                                onChange={e => {
                                                    setCountry(countries.find(item => item.code === e.target.value));
                                                    setCountryCode(e.target.value);
                                                }}
                                                style={{ padding: 5.5 }}
                                            >
                                                <option value={''}>Select one</option>
                                                {
                                                    countries.map((item, i) => {
                                                        return (
                                                            <option
                                                                key={i}
                                                                value={item.code}
                                                            >
                                                                {item.code} - {item.name}
                                                            </option>
                                                        );
                                                    })
                                                }
                                            </select><br></br>
                                            <small style={{ visibility: validityState.countryCode ? 'visible' : 'hidden' }} className="form-text text-danger">{validityState.countryCode || ''}</small>
                                        </Col>

                                        <Col xs="12" sm="6" md="6" lg="4">
                                            <label htmlFor="endCustomer" className={styles.label}>End Customer</label><br></br>
                                            <input id="endCustomer" list="endCustomers" value={endCustomerQuery} onChange={e => onChangeNewEndCustomerName(e)} autoComplete="off" placeholder="Min 2 characters" />
                                            <datalist id="endCustomers">
                                                {
                                                    associates.items.map((item, i) => <option key={i} value={format(item)} />)
                                                }
                                            </datalist><br></br>
                                            <small style={{ visibility: validityState.endCustomer ? 'visible' : 'hidden' }} className="form-text text-danger">{validityState.endCustomer || ''}</small>
                                        </Col>

                                        <Col xs="12" sm="6" md="6" lg="4">
                                            <label htmlFor="remeasurement" className={styles.label}>Remeasurement</label><br></br>
                                            <input id="remeasurement" type="checkbox" checked={remeasurement} onChange={e => setRemeasurement(e.target.checked)} /><br></br>
                                            <small style={{ visibility: validityState.remeasurement ? 'visible' : 'hidden' }} className="form-text text-danger">{validityState.remeasurement || ''}</small>
                                        </Col>


                                        <Col xs="12" sm="6" md="6" lg="4">
                                            <label htmlFor="compareWithExpectedCargo" className={styles.label}>Compare With Expected Cargo</label><br></br>
                                            <input id="compareWithExpectedCargo" type="checkbox" checked={compareWithExpectedCargo} onChange={e => setCompareWithExpectedCargo(e.target.checked)} /><br></br>
                                            <small style={{ visibility: validityState.compareWithExpectedCargo ? 'visible' : 'hidden' }} className="form-text text-danger">{validityState.compareWithExpectedCargo || ''}</small>
                                        </Col>

                                        <Col xs="12" sm="6" md="6" lg="4">
                                        </Col>

                                        {
                                            articleFields && articleFields.map((field, i) => {
                                                return (
                                                    <Col xs="12" sm="6" md="6" lg="4" key={i}>
                                                        <label className={styles.label}>{field.displayName}</label><br></br>
                                                        <input id={field} type="checkbox" onChange={e => onArticleFieldChanged(field.name, e.target.checked)} checked={selectedArticleFields.includes(field.name)} /><br></br>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>

                                    <Row>
                                        <Col xs="9" md="10">
                                            <label htmlFor="supplier" className={styles.label}>Suppliers</label><br></br>
                                            <input id="supplier" list="suppliers" value={newSupplierName} onChange={e => onChangeNewSupplierName(e)} autoComplete="off" placeholder="Min 2 characters" />
                                            <datalist id="suppliers">
                                                {
                                                    associates.items.map((item, i) => <option key={i} value={format(item)} />)
                                                }
                                            </datalist><br></br>
                                        </Col>

                                        <Col xs="3" md="2" style={{ display: 'flex' }}>
                                            <input
                                                type="button"
                                                className={styles.secondary}
                                                value="Add"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    const matchedAssociate = associates.items.find(item => format(item) === newSupplierName);
                                                    if (matchedAssociate) {
                                                        const wasNewSupplierIncluded = suppliers.find(item => item.id === matchedAssociate.id);
                                                        if (!wasNewSupplierIncluded) {
                                                            setSuppliers([...suppliers, matchedAssociate]);
                                                        }
                                                        setNewSupplierName('');
                                                    }
                                                }}
                                                style={{ alignSelf: 'flex-end' }}
                                            />
                                        </Col>
                                    </Row>

                                    <Row style={{ marginBottom: '24px' }}>
                                        <Col>
                                            {
                                                suppliers.length > 0 ?
                                                    suppliers.map((item, i) => {
                                                        return (
                                                            <Row key={i} className={styles.associateRow}>


                                                                <Col xs="9" md="10" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <span>
                                                                        {item.code} - {item.name}
                                                                    </span>
                                                                </Col>

                                                                <Col xs="3" md="2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                                                    <div title="Remove">
                                                                        <Minus
                                                                            className={styles.iconButton}
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                setSuppliers([...suppliers.filter(element => element.id !== item.id)]);
                                                                            }}
                                                                        ></Minus>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        );
                                                    })
                                                    :
                                                    'No customs needed suppliers to show'
                                            }
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col></Col>

                                        <Col xs="12" sm="6" md="6" lg="4" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            {/*<input type="reset" value="Reset" />*/}
                                            <input type="submit" value={suffix ? "Update" : "Create"} />
                                        </Col>
                                    </Row>
                                </form>
                            </section>
                        </main>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}

const mapStateToProps = state => {
    return {
        associates: state.definitions.associates,
        types: state.definitions.types,
        articleFields: (() => {
            const x = state.definitions.types.items.find(item => item.type === "ArticleFieldsType");
            return x ? x.values : [];
        })()
    };
};

const mapDispatchToProps = {
    createSuffix,
    updateSuffix,
    deleteSuffix,
    searchAssociates,
    searchAssociatesSuccess,
    fetchTypes
};

const countries = [
    { 'code': 'AF', 'name': 'Afghanistan' },
    { 'code': 'AX', 'name': 'Aland Islands' },
    { 'code': 'AL', 'name': 'Albania' },
    { 'code': 'DZ', 'name': 'Algeria' },
    { 'code': 'AS', 'name': 'American Samoa' },
    { 'code': 'AD', 'name': 'Andorra' },
    { 'code': 'AO', 'name': 'Angola' },
    { 'code': 'AI', 'name': 'Anguilla' },
    { 'code': 'AQ', 'name': 'Antarctica' },
    { 'code': 'AG', 'name': 'Antigua and Barbuda' },
    { 'code': 'AR', 'name': 'Argentina' },
    { 'code': 'AM', 'name': 'Armenia' },
    { 'code': 'AW', 'name': 'Aruba' },
    { 'code': 'AU', 'name': 'Australia' },
    { 'code': 'AT', 'name': 'Austria' },
    { 'code': 'AZ', 'name': 'Azerbaijan' },
    { 'code': 'BS', 'name': 'Bahamas' },
    { 'code': 'BH', 'name': 'Bahrain' },
    { 'code': 'BD', 'name': 'Bangladesh' },
    { 'code': 'BB', 'name': 'Barbados' },
    { 'code': 'BY', 'name': 'Belarus' },
    { 'code': 'BE', 'name': 'Belgium' },
    { 'code': 'BZ', 'name': 'Belize' },
    { 'code': 'BJ', 'name': 'Benin' },
    { 'code': 'BM', 'name': 'Bermuda' },
    { 'code': 'BT', 'name': 'Bhutan' },
    { 'code': 'BO', 'name': 'Bolivia' },
    { 'code': 'BA', 'name': 'Bosnia and Herzegovina' },
    { 'code': 'BW', 'name': 'Botswana' },
    { 'code': 'BV', 'name': 'Bouvet Island' },
    { 'code': 'BR', 'name': 'Brazil' },
    { 'code': 'VG', 'name': 'British Virgin Islands' },
    { 'code': 'IO', 'name': 'British Indian Ocean Territory' },
    { 'code': 'BN', 'name': 'Brunei Darussalam' },
    { 'code': 'BG', 'name': 'Bulgaria' },
    { 'code': 'BF', 'name': 'Burkina Faso' },
    { 'code': 'BI', 'name': 'Burundi' },
    { 'code': 'KH', 'name': 'Cambodia' },
    { 'code': 'CM', 'name': 'Cameroon' },
    { 'code': 'CA', 'name': 'Canada' },
    { 'code': 'CV', 'name': 'Cape Verde' },
    { 'code': 'KY', 'name': 'Cayman Islands' },
    { 'code': 'CF', 'name': 'Central African Republic' },
    { 'code': 'TD', 'name': 'Chad' },
    { 'code': 'CL', 'name': 'Chile' },
    { 'code': 'CN', 'name': 'China' },
    { 'code': 'HK', 'name': 'Hong Kong, SAR China' },
    { 'code': 'MO', 'name': 'Macao, SAR China' },
    { 'code': 'CX', 'name': 'Christmas Island' },
    { 'code': 'CC', 'name': 'Cocos (Keeling) Islands' },
    { 'code': 'CO', 'name': 'Colombia' },
    { 'code': 'KM', 'name': 'Comoros' },
    { 'code': 'CG', 'name': 'Congo (Brazzaville)' },
    { 'code': 'CD', 'name': 'Congo, (Kinshasa)' },
    { 'code': 'CK', 'name': 'Cook Islands' },
    { 'code': 'CR', 'name': 'Costa Rica' },
    { 'code': 'CI', 'name': 'Côte d\'Ivoire' },
    { 'code': 'HR', 'name': 'Croatia' },
    { 'code': 'CU', 'name': 'Cuba' },
    { 'code': 'CY', 'name': 'Cyprus' },
    { 'code': 'CZ', 'name': 'Czech Republic' },
    { 'code': 'DK', 'name': 'Denmark' },
    { 'code': 'DJ', 'name': 'Djibouti' },
    { 'code': 'DM', 'name': 'Dominica' },
    { 'code': 'DO', 'name': 'Dominican Republic' },
    { 'code': 'EC', 'name': 'Ecuador' },
    { 'code': 'EG', 'name': 'Egypt' },
    { 'code': 'SV', 'name': 'El Salvador' },
    { 'code': 'GQ', 'name': 'Equatorial Guinea' },
    { 'code': 'ER', 'name': 'Eritrea' },
    { 'code': 'EE', 'name': 'Estonia' },
    { 'code': 'ET', 'name': 'Ethiopia' },
    { 'code': 'FK', 'name': 'Falkland Islands (Malvinas)' },
    { 'code': 'FO', 'name': 'Faroe Islands' },
    { 'code': 'FJ', 'name': 'Fiji' },
    { 'code': 'FI', 'name': 'Finland' },
    { 'code': 'FR', 'name': 'France' },
    { 'code': 'GF', 'name': 'French Guiana' },
    { 'code': 'PF', 'name': 'French Polynesia' },
    { 'code': 'TF', 'name': 'French Southern Territories' },
    { 'code': 'GA', 'name': 'Gabon' },
    { 'code': 'GM', 'name': 'Gambia' },
    { 'code': 'GE', 'name': 'Georgia' },
    { 'code': 'DE', 'name': 'Germany' },
    { 'code': 'GH', 'name': 'Ghana' },
    { 'code': 'GI', 'name': 'Gibraltar' },
    { 'code': 'GR', 'name': 'Greece' },
    { 'code': 'GL', 'name': 'Greenland' },
    { 'code': 'GD', 'name': 'Grenada' },
    { 'code': 'GP', 'name': 'Guadeloupe' },
    { 'code': 'GU', 'name': 'Guam' },
    { 'code': 'GT', 'name': 'Guatemala' },
    { 'code': 'GG', 'name': 'Guernsey' },
    { 'code': 'GN', 'name': 'Guinea' },
    { 'code': 'GW', 'name': 'Guinea-Bissau' },
    { 'code': 'GY', 'name': 'Guyana' },
    { 'code': 'HT', 'name': 'Haiti' },
    { 'code': 'HM', 'name': 'Heard and Mcdonald Islands' },
    { 'code': 'VA', 'name': 'Holy See (Vatican City State)' },
    { 'code': 'HN', 'name': 'Honduras' },
    { 'code': 'HU', 'name': 'Hungary' },
    { 'code': 'IS', 'name': 'Iceland' },
    { 'code': 'IN', 'name': 'India' },
    { 'code': 'ID', 'name': 'Indonesia' },
    { 'code': 'IR', 'name': 'Iran, Islamic Republic of' },
    { 'code': 'IQ', 'name': 'Iraq' },
    { 'code': 'IE', 'name': 'Ireland' },
    { 'code': 'IM', 'name': 'Isle of Man' },
    { 'code': 'IL', 'name': 'Israel' },
    { 'code': 'IT', 'name': 'Italy' },
    { 'code': 'JM', 'name': 'Jamaica' },
    { 'code': 'JP', 'name': 'Japan' },
    { 'code': 'JE', 'name': 'Jersey' },
    { 'code': 'JO', 'name': 'Jordan' },
    { 'code': 'KZ', 'name': 'Kazakhstan' },
    { 'code': 'KE', 'name': 'Kenya' },
    { 'code': 'KI', 'name': 'Kiribati' },
    { 'code': 'KP', 'name': 'Korea (North)' },
    { 'code': 'KR', 'name': 'Korea (South)' },
    { 'code': 'KW', 'name': 'Kuwait' },
    { 'code': 'KG', 'name': 'Kyrgyzstan' },
    { 'code': 'LA', 'name': 'Lao PDR' },
    { 'code': 'LV', 'name': 'Latvia' },
    { 'code': 'LB', 'name': 'Lebanon' },
    { 'code': 'LS', 'name': 'Lesotho' },
    { 'code': 'LR', 'name': 'Liberia' },
    { 'code': 'LY', 'name': 'Libya' },
    { 'code': 'LI', 'name': 'Liechtenstein' },
    { 'code': 'LT', 'name': 'Lithuania' },
    { 'code': 'LU', 'name': 'Luxembourg' },
    { 'code': 'MK', 'name': 'Macedonia, Republic of' },
    { 'code': 'MG', 'name': 'Madagascar' },
    { 'code': 'MW', 'name': 'Malawi' },
    { 'code': 'MY', 'name': 'Malaysia' },
    { 'code': 'MV', 'name': 'Maldives' },
    { 'code': 'ML', 'name': 'Mali' },
    { 'code': 'MT', 'name': 'Malta' },
    { 'code': 'MH', 'name': 'Marshall Islands' },
    { 'code': 'MQ', 'name': 'Martinique' },
    { 'code': 'MR', 'name': 'Mauritania' },
    { 'code': 'MU', 'name': 'Mauritius' },
    { 'code': 'YT', 'name': 'Mayotte' },
    { 'code': 'MX', 'name': 'Mexico' },
    { 'code': 'FM', 'name': 'Micronesia, Federated States of' },
    { 'code': 'MD', 'name': 'Moldova' },
    { 'code': 'MC', 'name': 'Monaco' },
    { 'code': 'MN', 'name': 'Mongolia' },
    { 'code': 'ME', 'name': 'Montenegro' },
    { 'code': 'MS', 'name': 'Montserrat' },
    { 'code': 'MA', 'name': 'Morocco' },
    { 'code': 'MZ', 'name': 'Mozambique' },
    { 'code': 'MM', 'name': 'Myanmar' },
    { 'code': 'NA', 'name': 'Namibia' },
    { 'code': 'NR', 'name': 'Nauru' },
    { 'code': 'NP', 'name': 'Nepal' },
    { 'code': 'NL', 'name': 'Netherlands' },
    { 'code': 'AN', 'name': 'Netherlands Antilles' },
    { 'code': 'NC', 'name': 'New Caledonia' },
    { 'code': 'NZ', 'name': 'New Zealand' },
    { 'code': 'NI', 'name': 'Nicaragua' },
    { 'code': 'NE', 'name': 'Niger' },
    { 'code': 'NG', 'name': 'Nigeria' },
    { 'code': 'NU', 'name': 'Niue' },
    { 'code': 'NF', 'name': 'Norfolk Island' },
    { 'code': 'MP', 'name': 'Northern Mariana Islands' },
    { 'code': 'NO', 'name': 'Norway' },
    { 'code': 'OM', 'name': 'Oman' },
    { 'code': 'PK', 'name': 'Pakistan' },
    { 'code': 'PW', 'name': 'Palau' },
    { 'code': 'PS', 'name': 'Palestinian Territory' },
    { 'code': 'PA', 'name': 'Panama' },
    { 'code': 'PG', 'name': 'Papua New Guinea' },
    { 'code': 'PY', 'name': 'Paraguay' },
    { 'code': 'PE', 'name': 'Peru' },
    { 'code': 'PH', 'name': 'Philippines' },
    { 'code': 'PN', 'name': 'Pitcairn' },
    { 'code': 'PL', 'name': 'Poland' },
    { 'code': 'PT', 'name': 'Portugal' },
    { 'code': 'PR', 'name': 'Puerto Rico' },
    { 'code': 'QA', 'name': 'Qatar' },
    { 'code': 'RE', 'name': 'Réunion' },
    { 'code': 'RO', 'name': 'Romania' },
    { 'code': 'RU', 'name': 'Russian Federation' },
    { 'code': 'RW', 'name': 'Rwanda' },
    { 'code': 'BL', 'name': 'Saint-Barthélemy' },
    { 'code': 'SH', 'name': 'Saint Helena' },
    { 'code': 'KN', 'name': 'Saint Kitts and Nevis' },
    { 'code': 'LC', 'name': 'Saint Lucia' },
    { 'code': 'MF', 'name': 'Saint-Martin (French part)' },
    { 'code': 'PM', 'name': 'Saint Pierre and Miquelon' },
    { 'code': 'VC', 'name': 'Saint Vincent and Grenadines' },
    { 'code': 'WS', 'name': 'Samoa' },
    { 'code': 'SM', 'name': 'San Marino' },
    { 'code': 'ST', 'name': 'Sao Tome and Principe' },
    { 'code': 'SA', 'name': 'Saudi Arabia' },
    { 'code': 'SN', 'name': 'Senegal' },
    { 'code': 'RS', 'name': 'Serbia' },
    { 'code': 'SC', 'name': 'Seychelles' },
    { 'code': 'SL', 'name': 'Sierra Leone' },
    { 'code': 'SG', 'name': 'Singapore' },
    { 'code': 'SK', 'name': 'Slovakia' },
    { 'code': 'SI', 'name': 'Slovenia' },
    { 'code': 'SB', 'name': 'Solomon Islands' },
    { 'code': 'SO', 'name': 'Somalia' },
    { 'code': 'ZA', 'name': 'South Africa' },
    { 'code': 'GS', 'name': 'South Georgia and the South Sandwich Islands' },
    { 'code': 'SS', 'name': 'South Sudan' },
    { 'code': 'ES', 'name': 'Spain' },
    { 'code': 'LK', 'name': 'Sri Lanka' },
    { 'code': 'SD', 'name': 'Sudan' },
    { 'code': 'SR', 'name': 'Suriname' },
    { 'code': 'SJ', 'name': 'Svalbard and Jan Mayen Islands' },
    { 'code': 'SZ', 'name': 'Swaziland' },
    { 'code': 'SE', 'name': 'Sweden' },
    { 'code': 'CH', 'name': 'Switzerland' },
    { 'code': 'SY', 'name': 'Syrian Arab Republic (Syria)' },
    { 'code': 'TW', 'name': 'Taiwan, Republic of China' },
    { 'code': 'TJ', 'name': 'Tajikistan' },
    { 'code': 'TZ', 'name': 'Tanzania, United Republic of' },
    { 'code': 'TH', 'name': 'Thailand' },
    { 'code': 'TL', 'name': 'Timor-Leste' },
    { 'code': 'TG', 'name': 'Togo' },
    { 'code': 'TK', 'name': 'Tokelau' },
    { 'code': 'TO', 'name': 'Tonga' },
    { 'code': 'TT', 'name': 'Trinidad and Tobago' },
    { 'code': 'TN', 'name': 'Tunisia' },
    { 'code': 'TR', 'name': 'Turkey' },
    { 'code': 'TM', 'name': 'Turkmenistan' },
    { 'code': 'TC', 'name': 'Turks and Caicos Islands' },
    { 'code': 'TV', 'name': 'Tuvalu' },
    { 'code': 'UG', 'name': 'Uganda' },
    { 'code': 'UA', 'name': 'Ukraine' },
    { 'code': 'AE', 'name': 'United Arab Emirates' },
    { 'code': 'GB', 'name': 'United Kingdom' },
    { 'code': 'US', 'name': 'United States of America' },
    { 'code': 'UM', 'name': 'US Minor Outlying Islands' },
    { 'code': 'UY', 'name': 'Uruguay' },
    { 'code': 'UZ', 'name': 'Uzbekistan' },
    { 'code': 'VU', 'name': 'Vanuatu' },
    { 'code': 'VE', 'name': 'Venezuela (Bolivarian Republic)' },
    { 'code': 'VN', 'name': 'Viet Nam' },
    { 'code': 'VI', 'name': 'Virgin Islands, US' },
    { 'code': 'WF', 'name': 'Wallis and Futuna Islands' },
    { 'code': 'EH', 'name': 'Western Sahara' },
    { 'code': 'YE', 'name': 'Yemen' },
    { 'code': 'ZM', 'name': 'Zambia' },
    { 'code': 'ZW', 'name': 'Zimbabwe' }
];

export default connect(mapStateToProps, mapDispatchToProps)(Suffix);