const notificationActions = Object.freeze({
    NOTIFICATION_GETSUFFIXLIST_SUCCESS: 'astrid/suffixes/NOTIFICATION_GETSUFFIXLIST_SUCCESS',
    NOTIFICATION_GETSUFFIX_SUCCESS: 'astrid/suffixes/NOTIFICATION_GETSUFFIX_SUCCESS',
    NOTIFICATION_GETEMAILSFORSUFFIX_SUCCESS: 'astrid/suffixes/NOTIFICATION_GETEMAILSFORSUFFIX_SUCCESS',
    NOTIFICATION_ADDEMAILFORSUFFIX_SUCCESS: 'astrid/suffixes/NOTIFICATION_ADDEMAILFORSUFFIX_SUCCESS',
    NOTIFICATION_REMOVEEMAILFORSUFFIX_SUCCESS: 'astrid/suffixes/NOTIFICATION_REMOVEEMAILFORSUFFIX_SUCCESS',
    GET_COUNTRIES: 'astrid/suffixes/GET_COUNTRIES',
    SAVE_SUFFIX: 'astrid/suffixes/SAVE_SUFFIX',
    CLEAR_STATE: 'astrid/suffixes/CLEAR_STATE',
});

export default notificationActions;