import { get, post } from '../../core/httpClientSync';
import httpClient from "../../core/httpclient";
import { spinnerService } from "@simply007org/react-spinners";
import calendarActions from "../type/calendar";
import { utcToEpoch } from '../../core/utcToEpochConverter';


const apiUrl = 'astrid-slotbooking-api/api/calendar';
const timeSlotApiUrl = 'astrid-slotbooking-api/api/timeslot';
const businessAreaApiUrl = 'astrid-slotbooking-api/api/businessarea';


const getMinutes = value => (value.get('hours') * 60) + value.get('minutes')

export const setFilteredBusinessAreaId = (filteredBusinessAreaId) => async dispatch => {
    dispatch({
        type: calendarActions.CALENDAR_SET_FILTERED_BUSINESSAREA,
        payload: filteredBusinessAreaId
    })
}

export const getRules = () => async dispatch => {
    const endPoint = `${apiUrl}/getallactivecalendarrules`;
    spinnerService.show('getAllRules');
    httpClient.get(endPoint)
        .then(response => {
            dispatch({
                type: calendarActions.CALENDAR_GETALLRULES_SUCCESS,
                payload: response.data.result
            })
        })
        .catch(error => console.log(error))
        .finally(() => { spinnerService.hide('getAllRules'); });
}

export const getRule = (rules, id) => async dispatch => {
    dispatch({
        type: calendarActions.CALENDAR_GETRULE_SUCCESS,
        payload: rules && rules.length > 0 ? rules.find(x => x.id === id) : null
    })
}

export function saveRule(ruleModel) {
    return async dispatch => {
        const endPoint = `${apiUrl}/save`;

        ruleModel.gateQuota = ruleModel.gateQuota !== -1 ? ruleModel.gateQuota : null;
    
        let createRuleRequest = {
            StartMinutesSinceMidnight: getMinutes(ruleModel.startMinutesSinceMidnight),
            EndMinutesSinceMidnight: getMinutes(ruleModel.endMinutesSinceMidnight),
            RuleType: ruleModel.ruleType,
            RuleState: ruleModel.ruleState,
            GateQuota: ruleModel.gateQuota,
            BusinessAreaId: ruleModel.businessArea,
            SlotSize: ruleModel.slotSize,
            SlotType: ruleModel.slotSize === '15' ? 'Dynamic' : 'Static'
        }
    
        switch (ruleModel.ruleType) {
            case 'Weekday':
                createRuleRequest.Weekday = ruleModel.weekday;
                break;
            case 'SpecificDate':
                createRuleRequest.DateEpoch = utcToEpoch(ruleModel.dateEpoch);
                break;
            default:
                break;
        }
        spinnerService.show('createRule');

        const response = await post(endPoint, createRuleRequest);
        if (response.isSuccess) {

            ruleModel.id = response.response;
                ruleModel.startMinutesSinceMidnight = getMinutes(ruleModel.startMinutesSinceMidnight);
                ruleModel.endMinutesSinceMidnight = getMinutes(ruleModel.endMinutesSinceMidnight);
                ruleModel.businessAreaId = ruleModel.businessArea;

                dispatch({
                    type: calendarActions.CALENDAR_CREATERULE_SUCCESS,
                    payload: ruleModel
                })

        } else {
            console.log(response.errors)
        }

        spinnerService.hide('createRule');
    }
}

export const getBusinessAreas = () => async dispatch => {
    const endPoint = `${businessAreaApiUrl}/getall`;
    const getBusinessAreasResponse = await get(endPoint, 'createRule');
    if (getBusinessAreasResponse.isSuccess) {
        dispatch({
            type: calendarActions.BUSINESS_AREAS_RETRIEVED,
            payload: getBusinessAreasResponse.response.result
        });
    }
}

export const getAuthorizedBusinessAreas = (userid) => async dispatch => {
    const endPoint = `${businessAreaApiUrl}/getauthorizedareas?userid=${userid}`;
    const getBusinessAreasResponse = await get(endPoint, 'createRule');
    if (getBusinessAreasResponse.isSuccess) {
        dispatch({
            type: calendarActions.AUTHORIZED_BUSINESS_AREAS_RETRIEVED,
            payload: getBusinessAreasResponse.response.result
        });
    }
}

export const deleteRule = ruleId => async dispatch => {
    const endPoint = `${apiUrl}/delete?id=${ruleId}`;

    httpClient.post(endPoint)
        .then(response => {
            dispatch({
                type: calendarActions.CALENDAR_DELETERULE_SUCCESS,
                payload: ruleId
            })
        })
        .catch(error => console.log(error))
        .finally(() => { spinnerService.hide('createRule'); });
}

export const getTimeSlotSimulation = (date, businessAreaId) => async dispatch => {
    const dateEpoch = utcToEpoch(date);
    const endPoint = `${timeSlotApiUrl}/previewslotsfordate?date=${dateEpoch}&businessAreaId=${businessAreaId}`;
    const response = await get(endPoint, 'getTimeSlotSimulation');
    if (response.isSuccess) {
        dispatch({
            type: calendarActions.TIME_SLOT_SIMULATION_RETRIEVED,
            payload: response.response.result
        });
    }
}

export const getEffectingRulesFor = (date, businessAreaId) => async dispatch => {
    const dateEpoch = utcToEpoch(date);
    const endPoint = `${apiUrl}/getcalendarrulesforday?date=${dateEpoch}&businessAreaId=${businessAreaId}`;
    const response = await get(endPoint, 'getTimeSlotSimulation');
    if (response.isSuccess) {
        dispatch({
            type: calendarActions.TIME_SLOT_EFFECTING_RULES_RETRIEVED,
            payload: response.response.result
        });
    }
}

export const clearRules = () => async dispatch => {
    dispatch({ type: calendarActions.CLEAR_RULES });
}
