import { formButtonBehaviour, formInputTypes } from "../FormComponentTypes";

export const EditTaskSchema = {
    Id: 'EditTaskForm',
    SubmitButton: {
        Name: 'Update',
        Behaviour: formButtonBehaviour.BUTTON_NONE
    },
    Commands: [
        {
            name: 'Cancel Task',
            callback: 'cancelTask',
            isConfirmationNeeded: true,
            confirmationText: 'Are you sure you want to cancel this task?',
            params: ['operationId'],
            ordering: 1
        }
    ],
    Grouping: ['Details'],
    ColumnCountSettings: {
        columnCount: 2,
        isAdaptiveColumn: false
    },
    FormDesign: [
        {
            groupName: 'Hidden',
            ordering: 1,
            isTagVisible: false
        },
        {
            groupName: 'BasicsTop',
            ordering: 2,
            isTagVisible: false
        },
        {
            groupName: 'BasicsBottom',
            ordering: 3,
            isTagVisible: false
        },
        {
            groupName: 'Details',
            ordering: 5,
            isTagVisible: true
        },
        {
            groupName: 'Details1',
            ordering: 6,
            isTagVisible: false
        },
        {
            groupName: 'Notes',
            ordering: 10,
            isTagVisible: true
        }
    ],
    Rules: [
        'IF @clientId IS #EMPTY THEN @businessAreaId is #DISABLED',
        'IF @clientId IS #EMPTY THEN @serviceId is #DISABLED',
        'IF @operationType IS #EMPTY THEN @serviceId is #DISABLED',
    ],
    Fields: [
        {
            name: '',
            id: 'operationId',
            type: formInputTypes.HIDDEN_INPUT,
            ordering: 10,
            formDesign: 'Hidden'
        },
        {
            name: 'Client',
            id: 'clientId',
            type: formInputTypes.COMBO_INPUT,
            listOfItems: 'clients',
            firstItemObject: 'clientsFirstItem',
            isRequired: true,
            ordering: 20,
            formDesign: 'BasicsTop',
            validations: []
        },

        {
            name: 'Business Area',
            id: 'businessAreaId',
            type: formInputTypes.COMBO_INPUT,
            listOfItems: 'businessAreas',
            firstItemObject: 'businessAreasFirstItem',
            isRequired: true,
            ordering: 30,
            formDesign: 'BasicsTop',
            validations: []
        },

        {
            name: 'Type',
            id: 'operationType',
            type: formInputTypes.COMBO_INPUT,
            listOfItems: 'operationTypes',
            firstItemObject: 'typeFirstItemObject',
            isRequired: true,
            ordering: 40,
            formDesign: 'BasicsTop',
            validations: []
        },
      

        {
            name: 'Service',
            id: 'serviceId',
            type: formInputTypes.COMBO_INPUT,
            listOfItems: 'services',
            firstItemObject: 'servicesFirstItem',
            isRequired: true,
            ordering: 50,
            formDesign: 'BasicsTop',
            validations: []
        },
        {
            name: 'Subject',
            id: 'subjectId',
            type: formInputTypes.STRING_INPUT,
            isRequired: true,
            ordering: 60,
            formDesign: 'BasicsTop',
            validations: []
        },
      
        {
            name: 'Task Operation Date',
            id: 'operationDate',
            type: formInputTypes.DATE_INPUT,
            isTodayCheck: false,
            isRequired: true,
            ordering: 70,
            formDesign: 'BasicsTop',
            validations: []
        },
        {
            name: 'Unit Type',
            id: 'unitType',
            type: formInputTypes.COMBO_INPUT,
            listOfItems: 'unitTypes',
            firstItemObject: 'unitTypeFirstItemObject',
            isRequired: true,
            group: 'Details',
            ordering: 80,
            formDesign: 'Details',
            validations: []
        },
        {
            name: 'Carrier',
            id: 'carrier',
            type: formInputTypes.STRING_INPUT,
            isRequired: false,
            group: 'Details',
            ordering: 90,
            formDesign: 'Details',
            validations: []
        },
        {
            name: 'Rush',
            id: 'rushRequest',
            type: formInputTypes.RADIO_INPUT,
            options: [
                {
                    text: 'Yes',
                    value: 'true'
                },
                {
                    text: 'No',
                    value: 'false'
                }
            ],
            isRequired: true,
            group: 'Details',
            ordering: 100,
            formDesign: 'Details1',
            validations: []
        },
        {
            name: 'Non Advised',
            id: 'nonAdvised',
            type: formInputTypes.RADIO_INPUT,
            options: [
                {
                    text: 'Yes',
                    value: 'true'
                },
                {
                    text: 'No',
                    value: 'false'
                }
            ],
            isRequired: true,
            group: 'Details',
            ordering: 110,
            formDesign: 'Details1',
            validations: []
        },
        {
            name: 'Admin Notes',
            id: 'adminNotes',
            type: formInputTypes.TEXTAREA_INPUT,
            authorizationWrapper: 'TaskCreateInternalHoc',
            rowCount: 5,
            group: 'Details',
            ordering: 120,
            formDesign: 'Notes',
        },
        {
            name: 'External Notes',
            id: 'externalNotes',
            type: formInputTypes.TEXTAREA_INPUT,
            rowCount: 5,
            group: 'Details',
            ordering: 130,
            formDesign: 'Notes',
        }
    ]
};