import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { ButtonStack, Button, IconButton, Checkbox } from '@dfds-ui/react-components';
import { Edit, Delete } from '@dfds-ui/icons';
import classes from './Transport.module.css';

const TransportRow = ({ transport, onChange, isChecked, onEditClick, onDeleteClick }) => {

    return (<>
        <Card>
            <Card.Body>
                <Row className={`align-items-center`}>
                    <Col xs={2}>
                        <Checkbox className={`${classes["transports-checkbox"]} `} onChange={e => onChange(transport.id, e.target.checked)} checked={isChecked(transport.id)}></Checkbox>
                    </Col>
                    <Col xs={8}>
                        <Row>
                            <Col xs={12}>
                                {`${transport.code ? transport.code : transport.id}`}
                            </Col>
                            <Col xs={12}>
                                <Row>
                                    <Col xs={6} className={'text-truncate'}>
                                        {`Carrier: ${transport.carrierId ? transport.carrierId : ''}`}
                                    </Col>
                                    <Col xs={6}>
                                        {`Unit: ${transport.unitType ? transport.unitType : ''}`}
                                    </Col>
                                    <Col xs={6}>
                                        {`Loading: ${transport.loadingType ? transport.loadingType : ''}`}
                                    </Col>
                                    <Col xs={6}>
                                        {`Leg: ${transport.legType ? transport.legType : ''}`}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={2}>
                        <ButtonStack align="right">
                            {
                                onEditClick ? <Button className="button-icon-ml-0" size="small" variation="secondary" onClick={() => onEditClick(transport)} icon={<Edit />}></Button> : null
                            }
                            {
                                onDeleteClick ? <Button className="button-icon-ml-0" size="small" variation="outlined" onClick={() => onDeleteClick(transport)} icon={<Delete />}></Button> : null
                            }
                        </ButtonStack>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    </>)
}

export default TransportRow;