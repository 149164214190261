import { Col, Form } from "react-bootstrap";
import { AuthorizationWrapper } from "./AuthorizationRenderer";
import * as PropTypes from "prop-types";
import React from "react";
import { RadioButtonGroupFormComponent } from "../../RadioButtonGroupFormComponent";

export const RadioButtonListRenderer = (field, formColumnCount) => {
    const radioButtonListControl = (<Form.Group key={field.name} as={Col} md={formColumnCount}>
        <Form.Label>{field.name}{field.isRequired && ' *'}</Form.Label>
        <RadioButtonGroupFormComponent name={field.id} isRequired={field.isRequired} validationArray={field.validations} options={field.options} isDisabled={field.isDisabled} onChange={field.onChange} />
    </Form.Group>);

    if (field.authorizationWrapper) {
        return AuthorizationWrapper(radioButtonListControl, field.id, field.authorizationWrapper);
    }
    return radioButtonListControl;
};

RadioButtonListRenderer.propTypes = {
    item: PropTypes.object.isRequired
};
