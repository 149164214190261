import { Field } from "redux-form";
import * as PropTypes from "prop-types";
import React from "react";
import { addValidationToArray } from "./Validation/FormValidation";

export const CheckboxFormComponent = ({ name, isRequired = false, validationArray, isDisabled, onChange }) => {
    const validation = addValidationToArray(isRequired, validationArray);
    return (<div className="field-radio-group">
        <Field
            onChange={onChange}
            component={({ input, meta: { touched, error, warning }, ...props }) => {
                return (<>
                        <input
                            className='form-check-input'
                            id={name}
                            disabled={props.disabled}
                            type='checkbox'
                            {...input}
                            checked={input.value}
                            style={{width: '30px', height: '30px', position: 'relative', marginLeft: '0px'}}
                        />
                    {touched && ((error && <span className="text-danger" style={{ display: 'block', marginTop: '-1rem' }}>{error}</span>) || (warning &&
                        <span className="text-warning">{warning}</span>))}
                </>);
            }}
            name={name}
            disabled={isDisabled}
            validate={validation}
        />
    </div>);
};

CheckboxFormComponent.propTypes = {
    name: PropTypes.string.isRequired
};
