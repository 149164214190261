import postSync, { get as getSync } from '../../core/httpClientSync';
import operationTypes from '../type/operation';
import { toastSuccess } from '.';
import { addQueryString } from '../../core/utility'

const shipmentApiUrl = 'xms-shipment-api';
const workflowApiUrl = 'xms-workflow-api';
// const shipmentApiUrl = 'http://localhost:5000';
// const workflowApiUrl = 'http://localhost:5000';

export const clearState = () => async dispatch => {
    dispatch({
        type: operationTypes.STATE_CLEARED
    })
}

export const getOperationById = (id, spinnerElementId = null) => async dispatch => {

    const url = `${shipmentApiUrl}/api/operations/${id}`;
    const response = await getSync(url, spinnerElementId);

    if (response.isSuccess && response.response) {
        dispatch({
            type: operationTypes.OPERATION_RECEIVED,
            payload: response.response.result
        })
    }
}

export const updateSealNumber = (id, sealNumber = "", spinnerElementId = null) => async dispatch => {

    const request = {
        operationId: id,
        details: {
            sealNumber: sealNumber
        }
    };

    const url = `${workflowApiUrl}/api/operation/put-detail`;
    const response = await postSync(url, request, spinnerElementId);

    if (response.isSuccess) {
        toastSuccess('Seal number ' + sealNumber + ' updated successfully');
        dispatch({ type: operationTypes.SEALNUMBER_UPDATED, payload: sealNumber });
    }
}

export const unitLeft = (id, date, spinnerElementId = null) => async dispatch => {

    const request = {
        operationId: id,
        details: {
            unitLeftDateTime: date,
        }
    };

    const url = `${workflowApiUrl}/api/operation/put-detail`;
    const response = await postSync(url, request, spinnerElementId);

    if (response.isSuccess) {
        toastSuccess('Unit successfully left');
        dispatch({ type: operationTypes.UNIT_LEFT, payload: date });
    }
}

export const getShipmentList = (shipmentIdList, spinnerElementId) => async dispatch => {

    if (shipmentIdList && shipmentIdList.length > 0) {

        let url = `${shipmentApiUrl}/api/shipments?`;
        url += addQueryString('idList', shipmentIdList);

        const response = await getSync(url, spinnerElementId);

        if (response.isSuccess) {
            dispatch({
                type: operationTypes.SHIPMENTLIST_RECEIVED,
                payload: response.response.result
            })
        }
    }
}

export const findActionUrl = async (id, spinnerElementId = null) => {

    const url = `${shipmentApiUrl}/api/operations/${id}`;
    const response = await getSync(url, spinnerElementId);

    if (!response.isSuccess || !response.response || !response.response.result || !response.response.result.operation) {
        return;
    }

    const operation = response.response.result.operation;

    operation.shipmentRelativePathList = [];

    for (const shipmentId of operation.shipmentIdList) {
        operation.shipmentRelativePathList.push(`Shipment/${shipmentId}`);
    }

    operation.relativePath = `Unit/${operation.id}`;
    return operation;
}