/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAllUsers } from '../store/action/userActions';
import history from '../core/history';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import Page from '../component/Page/Page';
import { Table, Row, Col } from 'react-bootstrap';
import { Search } from '@dfds-ui/icons';
import { TextField, ButtonStack, Button } from '@dfds-ui/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { generateGUID } from '../core/utility.js';

const UserList = (props) => {

  const { userList, getAllUsers } = props;

  const [filteredUserList, setFilteredUserList] = useState([]);

  useEffect(() => {
    if (!userList || userList.length == 0) {
      getAllUsers();
    }

    setFilteredUserList(userList || []);
  }, [userList]);
  
  const userClickedHandler = (user) => {
    history.push('/user/' + user.username);
  };

  const changePasswordHandler = (user) => {
    history.push('/user/' + user.username + '/change-password');
  };
 
  const newUserClickedHandler = () => {
    history.push('/user/new');
  };

  const renderStatusColumn = (row) => {
    return row.status === 1 || row.status === '1' ? (
      <span className="text-success">Active</span>
    ) : (
      <span className="text-danger">Passive</span>
    );
  };

  const searchTermChanged = (text) => {
    if(!text)
    {
      setFilteredUserList(userList);
      return;
    }

    var _filteredUsers = [];
    for (let index = 0; index < userList.length; index++) {
      const user = userList[index];
      if(user)
      {
        const contains = containsValue(user.username, text) || containsValue(user.name, text) || containsValue(user.surname, text) || containsValue(user.email, text) || containsValue(user.region, text) || containsValue(user.companyCode, text);
        if(contains){
          _filteredUsers.push(user);
        }
      }
    }

    setFilteredUserList(_filteredUsers);
  };

  const containsValue = (obj, value) => {
    if(value && value.trim() == '')
    {
      return true;
    }

    if(!obj || !value)
    {
      return false;
    }

    const _obj = obj.trim().toLowerCase();
    const _value = value.trim().toLowerCase();

    const contains = _obj.indexOf(_value) >= 0;
    return contains; 
  }

  return (
    <Page>
      <Row>
        <Col>
          <div className="d-flex" style={{ alignItems: 'center' }}>
            <div style={{ flex: '1' }}>
              <h4 className="mb-0">Users</h4>
            </div>
            <div>
              <Button
                title="Create"
                icon={<FontAwesomeIcon icon="plus" />}
                onClick={() => {
                  history.push('/user/new');
                }}
              >
                Create
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
            <div style={{ width: '100%' }}>
              <TextField
                icon={<Search />}
                type="text"                
                placeholder="Enter Username / Name / Surname / Email / Region ..."
                onChange={(e) => searchTermChanged(e.target.value)}
              />
            </div>          
        </Col>
      </Row>
      <Row>
        <Col>
          <Table className="table xms-table table-bordered">
            <thead>
              <tr>
                <th>Username</th>
                <th>Email</th>
                <th style={{ whiteSpace: 'nowrap' }}>Name</th>
                <th>Surname</th>
                <th>Company</th>
                <th>Region</th>
                <th>Status</th>
                <th>Roles</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
             { 
               filteredUserList == null || filteredUserList.length == 0 ?  
                (<tr>
                  <td colSpan="9" className="text-center">
                    {' '}
                    There are not any users to show.
                  </td>
                </tr>)
                :
                ( filteredUserList && filteredUserList.map((tableRow, i)=>{
                  return <tr key={i}>
                    <td>
                      {
                        tableRow.username
                      }
                    </td>
                    <td>
                      {
                        tableRow.email
                      }
                    </td>
                    <td style={{ whiteSpace: 'nowrap' }}>
                      {
                        tableRow.name
                      }
                    </td>
                    <td style={{ whiteSpace: 'nowrap' }}>
                      {
                        tableRow.surname
                      }
                    </td>
                    <td>
                      {
                        tableRow.companyCode
                      }
                    </td>
                    <td>
                      {
                        tableRow.region
                      }
                    </td>
                    <td>
                      {
                        tableRow.status === 1 || tableRow.status === '1' ? <span className="text-success">Active</span> : <span className="text-danger">Passive</span>
                      }
                    </td>
                    <td>
                      {
                        tableRow.roles && tableRow.roles.join(', ')
                      }
                    </td>
                    <td style={{widt:"16%"}}>
                      <ButtonStack align="center">
                        <Button variation='outlined'
                            key={generateGUID()}
                            size='small'
                            title="Edit user"
                            onClick={(e)=>userClickedHandler(tableRow)}>
                            <FontAwesomeIcon icon="edit" />
                        </Button>
                        <Button variation='outlined'
                            key={generateGUID()}
                            size='small'
                            title="Change password"
                            onClick={(e)=>changePasswordHandler(tableRow)}>
                            <FontAwesomeIcon icon="key" />
                        </Button>
                      </ButtonStack>
                    </td>
                  </tr>  
                }))
             }
            </tbody>
          </Table>
          <LoadingIndicator id="getUserList" />
        </Col>
      </Row>
    </Page>
  );
};

const mapStateToProps = (state) => {
  var _userList = [];
  if(state.user && state.user.userListResponse && state.user.userListResponse.length > 0){
    _userList = state.user.userListResponse;
  }
  return {
    userList: _userList
  };
};

const mapDispatchToProps = {
  getAllUsers 
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
