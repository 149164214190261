import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Col, Row } from 'react-bootstrap';
import { Button, Checkbox, SelectField } from '@dfds-ui/react-components';
import { addOrUpdateHazardous } from '../../store/action/shipmentDetailAction'

const ModalHazardousShipment = (props) => {

    //parent
    const { modalData, onHide } = props;

    //redux
    const { addOrUpdateHazardous } = props;

    const shipmentId = modalData.shipmentId;
    const item = { ...modalData.item };

    const [name, setName] = useState(item.name ? item.name : '');
    const [nameError, setNameError] = useState('');
    const [itemClass, setItemClass] = useState(item.class ? item.class : '');
    const [undg, setUndg] = useState(item.undg ? item.undg : '');
    const [ems, setEms] = useState(item.ems ? item.ems : '');
    const [mfag, setMfag] = useState(item.mfag ? item.mfag : '');
    const [subClass, setSubClass] = useState(item.subClass ? item.subClass : '');
    const [netWeight, setNetWeight] = useState(item.netWeight ? item.netWeight : '');
    const [grossWeight, setGrossWeight] = useState(item.grossWeight ? item.grossWeight : '');
    const [flashPoint, setFlashPoint] = useState(item.flashPoint ? item.flashPoint : '');
    const [packingGroup, setPackingGroup] = useState(item.packingGroup ? item.packingGroup : '');
    const [marinePollutant, setMarinePollutant] = useState(item.marinePollutant ? item.marinePollutant : '');
    const [limitedQuantity, setLimitedQuantity] = useState(item.limitedQuantity ? item.limitedQuantity : false);

    const onClick = () => {

        item.name = name;
        item.class = itemClass;
        item.undg = undg;
        item.ems = ems;
        item.mfag = mfag;
        item.subClass = subClass;
        item.netWeight = netWeight;
        item.grossWeight = grossWeight;
        item.flashPoint = flashPoint;
        item.packingGroup = packingGroup;
        item.marinePollutant = marinePollutant;
        item.limitedQuantity = limitedQuantity;
        item.shipmentId = shipmentId;

        setNameError('');

        if (item.name) {
            addOrUpdateHazardous(shipmentId, { ...item }, loaderClass(item));
            onHide();
        }
        else {
            setNameError('Name is mandatory');
        }

    }

    const loaderClass = (item) => {
        if (item.id) {
            return `loader-hazardousitem-${item.id}`;
        }

        return `loader-hazardousitem`;
    }

    const renderField = (label, value, setValue, error, md = 3) => {
        return (
            <Col md={md}>
                <div className='form-group'>
                    <label>{label}</label>
                    <input
                        type='text'
                        className='form-control'
                        autoComplete="off"
                        onKeyUp={e => setValue(e.target.value)}
                        onChange={e => setValue(e.target.value)}
                        value={value}></input>
                    <small className="form-text text-danger">{error ? error : ''}</small>
                </div>
            </Col>
        )
    }

    const renderSelectBox = (label, value, setValue, error, md = 3) => {
        return (
            <Col md={md}>
                <div className='form-group'>
                    <label>{label}</label>
                    <SelectField selected={value} onChange={(e) => setValue(e.target.value)} size='small'>
                        <option value="">Select</option>
                        <option value="P">P</option>
                        <option value="PP">PP</option>
                    </SelectField>
                </div>
            </Col>
        )
    }

    const renderCheckBox = (label, value, setValue, md = 3) => {
        return (
            <Col md={md}>
                <div className='form-group'>
                    <label>{label}</label>
                    <Checkbox className='form-control' onChange={(e) => setValue(e.target.checked)} checked={value}></Checkbox>
                </div>
            </Col>
        )
    }

    return (
        <Modal show={true} onHide={onHide} centered size="xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    {item.id ? "Edit Hazarous Item" : "Add Hazardous Item"}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Col>
                    <Row>
                        {renderField('Name (*)', name, setName, nameError, 12)}
                    </Row>
                    <Row>
                        {renderField('Class', itemClass, setItemClass)}
                        {renderField('UNDG', undg, setUndg)}
                        {renderField('EMS', ems, setEms)}
                        {renderField('MFAG', mfag, setMfag)}
                    </Row>
                    <Row>
                        {renderField('Sub Class', subClass, setSubClass)}
                        {renderField('Net Weight', netWeight, setNetWeight)}
                        {renderField('Gross Weight', grossWeight, setGrossWeight)}
                        {renderField('Flash Point', flashPoint, setFlashPoint)}
                    </Row>
                    <Row>
                        {renderField('Packing Group', packingGroup, setPackingGroup)}
                        {renderSelectBox('Marine Pollutant', marinePollutant, setMarinePollutant)}
                        {renderCheckBox('Limited Quantity', limitedQuantity, setLimitedQuantity)}
                    </Row>
                </Col>
            </Modal.Body>

            <Modal.Footer>
                <Button variation="outlined" onClick={onHide}>Cancel</Button>
                <Button variation="primary" onClick={onClick}>Save</Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = ({ }) => {
    return {

    }
}

const mapActionToProps = {
    addOrUpdateHazardous
}

export default connect(mapStateToProps, mapActionToProps)(ModalHazardousShipment);