/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getCountries, getSuffix, createSuffix, updateSuffix, clearState, getSuffixList } from '../store/action/notificationActions';
import history from '../core/history';
import Suffix from '../component/Suffix/Suffix'
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';

const AddOrEditSuffix = ({ match, suffix, getSuffix, getSuffixList, createSuffix, updateSuffix, suffixList, countries, getCountries, clearState }) => {
    const [editingMode, setEditingMode] = useState();

    useEffect(() => {
        if (!match.params.suffix || match.params.suffix === 'new') {
            setEditingMode('create');
        }
    }, [match.params.suffix]);

    useEffect(() => {
        if (!suffixList) {
            getSuffixList();
        }

        if (!match.params.suffix || match.params.suffix === 'new') {
            return;
        }
        getSuffix(match.params.suffix);

        return () => {
            clearState();
        }
    }, []);

    useEffect(() => {
        if (!countries) {
            getCountries();
        }
    }, [countries]);

    const submit = (suffixModel) => {
        if (!match.params.suffix || match.params.suffix === 'new') {
            createSuffix(suffixModel);
        } else {
            updateSuffix(suffixModel);
        }
        history.goBack();
    }


    return <><LoadingIndicator id="notificationGetSuffix" />
        <Suffix onSubmit={submit} suffix={suffix} suffixList={suffixList} countries={countries} editingMode={editingMode} />
    </>
}

const mapStateToProps = (state) => {
    return {
        suffixList: state.notification.suffixList,
        suffix: state.notification.suffixDetail,
        countries: state.notification.countries
    }
}
export default connect(mapStateToProps, { getCountries, getSuffix, getSuffixList, createSuffix, updateSuffix, clearState })(AddOrEditSuffix);
