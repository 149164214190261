import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@dfds-ui/react-components/button';
import { ButtonStack } from '@dfds-ui/react-components';
import {
    readConnectionPoints,
    searchConnectionPoints,
} from '../store/ducks/definitions/actionCreators';
import styles from './ConnectionPoints.module.css';
import { Row, Col, Card } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import LoadingIndicator from "../component/LoadingIndicator/LoadingIndicator";

function ConnectionPoints({
    readConnectionPoints,
    searchConnectionPoints,
    connectionPoints
}) {
    // GET STATE AFTER COMPONENT RENDERED
    useEffect(() => {
        readConnectionPoints('processConnectionPoints');
    }, []);
    // end GET STATE AFTER COMPONENT RENDERED

    // CREATE
    let history = useHistory();

    const onClickCreate = () => {
        history.push(`/connection-points/create`);
    }
    // end CREATE

    // UPDATE
    const onClickEdit = () => {
        const checkedConnectionPointId = checkedConnectionPointIds[0];
        history.push(`/connection-points/${checkedConnectionPointId}`);
    }
    // end UPDATE

    // FILTER
    const [areFiltersDisplayed, setAreFiltersDisplayed] = useState(true);

    const toggleFiltersDisplayed = () => {
        // if currently true, it wil be false, so load data back: read connection points
        if (areFiltersDisplayed) {
            setQuery('');
            readConnectionPoints('processConnectionPoints');
        }
        setAreFiltersDisplayed(state => !state);
    }

    const onClickFilters = () => {
        toggleFiltersDisplayed();
    }

    const [query, setQuery] = useState('');

    const onSubmit = (e) => {
        e.preventDefault();
        if (query !== '') {
            searchConnectionPoints({ query }, 'processConnectionPoints'); // TODO: rename to filter..., since more generic semantic
        }
    }

    const onClickHideFilters = () => {
        toggleFiltersDisplayed();
    }
    // end FILTER

    // SORT
    // Client side sort

    // Server side sort
    // end SORT

    // ON CLICK LIST ITEM, navigate to item view
    // delegated event for performance
    const onClickTableBody = (e) => {
        e.persist()
        if (e.target.type === 'checkbox') {
        } else {
            history.push(`/connection-points/${e.target.id}`);
        }
    }
    // end ON CLICK LIST ITEM

    // SELECT LIST ITEM/S, with checkboxes
    const [checkedConnectionPointIds, setCheckedConnectionPointIds] = useState([]);

    const onChangeCheckbox = (e) => {
        e.persist();
        if (e.target.checked) {
            setCheckedConnectionPointIds(state => state.includes(e.target.id) ? state : state.concat(e.target.id)); // check one
        } else {
            setCheckedConnectionPointIds(state => state.filter(item => item !== e.target.id)); // uncheck one
        }
    }

    const onChangeMasterCheckbox = (e) => {
        e.persist();
        if (e.target.checked) {
            setCheckedConnectionPointIds(connectionPoints.items.map(item => item.id));// check all
        } else {
            setCheckedConnectionPointIds([]);// uncheck all
        }
    }

    const generateGUID = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r && 0x3 | 0x8);
            return v.toString(16);
        });
    }
    // end SELECT LIST ITEM/S

    // PAGINATION
    // end PAGINATION
    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                <span style={{ fontSize: '1.75rem', paddingLeft: '10px' }}>Connection Points</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                <ButtonStack align='right'>
                                    <Button variation='primary'
                                        key={generateGUID()}
                                        onClick={onClickCreate}>
                                        Create <FontAwesomeIcon icon="plus" />
                                    </Button>
                                    {checkedConnectionPointIds.length == 1 ?
                                        <Button variation='secondary'
                                            key={generateGUID()}
                                            onClick={onClickEdit}>
                                            Edit <FontAwesomeIcon icon="edit" />
                                        </Button> : ""
                                    }
                                    {checkedConnectionPointIds.length == 0 ?
                                        <Button variation='secondary'
                                            key={generateGUID()}
                                            onClick={onClickFilters}>
                                            Filter <FontAwesomeIcon icon="filter" />
                                        </Button> : ""
                                    }
                                </ButtonStack>
                            </div>
                        </div>
                        <hr />
                    </Col>
                </Row>

                {
                    areFiltersDisplayed &&
                    <>
                        <Row className={styles.functionSection}>
                            <Col style={{ width: '100%', padding: '0px' }}>
                                <form onSubmit={onSubmit} style={{ margin: '0px' }}>
                                    <Row>

                                        <Col xs="12" sm="12" md="9">
                                            <input
                                                value={query}
                                                onChange={e => setQuery(e.target.value)}
                                                autoFocus
                                                placeholder="Type query"
                                            />
                                        </Col>
                                        <Col xs="12" sm="12" md="3" style={{ display: 'flex', alignItems: 'center' }}>
                                            <input type="submit" value="Search" style={{ flexGrow: 1 }} />
                                        </Col>
                                    </Row>
                                </form>
                            </Col>
                        </Row>
                    </>
                }

                <Row>
                    <Col>
                        <main>
                            <Table className="xms-table" bordered responsive>
                                <thead>
                                    <tr>
                                        <th>
                                            <input
                                                type="checkbox"
                                                checked={connectionPoints.items.length && checkedConnectionPointIds.length === connectionPoints.items.length}
                                                onChange={onChangeMasterCheckbox}
                                            />
                                        </th>
                                        <th>Id</th>
                                        <th>Name</th>
                                    </tr>
                                </thead>
                                <tbody onClick={onClickTableBody}>
                                    {
                                        connectionPoints.items.map((item) => {
                                            return (
                                                <tr key={item.id} id={item.id}>
                                                    <td
                                                        id={item.id}
                                                        onClick={(e) => e.stopPropagation()}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            id={item.id}
                                                            checked={checkedConnectionPointIds.includes(item.id)}
                                                            onChange={onChangeCheckbox}
                                                        />
                                                    </td>
                                                    <td id={item.id}>{item.id}</td>
                                                    <td id={item.id}>{item.name}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="3">{connectionPoints.isRequesting ? 'Requesting' : `Showing ${connectionPoints.items.length} items`}</td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </main>
                    </Col>
                </Row>
            </Card.Body>
            <LoadingIndicator id="processConnectionPoints" show={connectionPoints.isRequesting} />
        </Card>
    );
}

const mapStateToProps = ({ definitions }) => {
    return {
        connectionPoints: definitions.connectionPoints
    };
}

const mapDispatchToProps = {
    readConnectionPoints,
    searchConnectionPoints,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionPoints);
