export default Object.freeze({
    READ_ARTICLES_REQUESTING: 'astrid/articles/READ_ARTICLES_REQUESTING',
    READ_ARTICLES_SUCCESS: 'astrid/articles/READ_ARTICLES_SUCCESS',
    READ_ARTICLES_FAILURE: 'astrid/articles/READ_ARTICLES_FAILURE',

    READ_ARTICLES_BY_IDS_REQUESTING: 'astrid/articles/READ_ARTICLES_BY_IDS_REQUESTING',
    READ_ARTICLES_BY_IDS_SUCCESS: 'astrid/articles/READ_ARTICLES_BY_IDS_SUCCESS',
    READ_ARTICLES_BY_IDS_FAILURE: 'astrid/articles/READ_ARTICLES_BY_IDS_FAILURE',

    SET_FILTER: 'astrid/articles/SET_FILTER',
    SET_FILTERS: 'astrid/articles/SET_FILTERS',
    RESET_ALL_FILTERS: 'astrid/articles/RESET_ALL_FILTERS',

    SORT_ARTICLES: 'astrid/articles/SORT_ARTICLES',
});