import { Field } from "redux-form";
import React from "react";
import { InputComponent } from "./Pure/InputComponent";
import * as PropTypes from "prop-types";
import { addValidationToArray } from "./Validation/FormValidation";

export const StringInputFormComponent = ({ name, isRequired, isDisabled, validationArray, onChange }) => {
    const validation = addValidationToArray(isRequired, validationArray);
    return (<Field
        name={name}
        onChange={onChange}
        type="text"
        disabled={isDisabled}
        component={InputComponent}
        validate={validation}
    />)
};

StringInputFormComponent.propTypes = {
    name: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    validationArray: PropTypes.arrayOf(PropTypes.func)
};