import React, { useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { ButtonStack, Button } from '@dfds-ui/react-components';

const Filter = ({ onClick }) => {

    const [filter, setFilter] = useState({});

    const onFilterChange = (event) => {
        setFilter({
            ...filter,
            [event.target.name]: event.target.value
        });
    }

    const renderField = (label, name) => {
        return (
            <Col md={6}>
                <div className='form-group'>
                    <label>{label}</label>
                    <input
                        name={name}
                        type='text'
                        className='form-control'
                        onKeyUp={onFilterChange}
                        onChange={onFilterChange}
                        autoComplete="off"
                    ></input>
                </div>
            </Col>
        )
    }

    return (<>
        <Card>
            <Card.Body>
                <Row>
                    {renderField('Shipment No', 'shipmentNo')}
                    {renderField('Transport', 'groupCode')}
                </Row>
                <Row>
                    <Col>
                        <ButtonStack align="right">
                            <Button onClick={() => onClick(filter)}>Search</Button>
                        </ButtonStack>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    </>)
}

export default Filter;