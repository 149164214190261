import React from 'react';
import classes from './BackgroundText.module.css';

export const BackgroundText = (props) => {

    const { text, show, type } = props;

    if (!show) {
        return null;
    }

    return (
        <div className={`background-${type} ${classes['background-text']}`}>
            <span className={`${classes['background-text-content']}`}>{text}</span>
        </div>
    )
}