import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Button, ButtonStack } from '@dfds-ui/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import history from '../../core/history';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import styles from './ThuList.module.css';
import { convertToActual, deleteThu, confirmThu, reverseThuState } from '../../store/action/thuDetailAction'
import { showThulabel } from '../../store/action/reportActions'
import { confirmationModal } from '../../store/action/modalActions'
import { BackgroundText } from '../BackgroundText/BackgroundText';
import PolicyGroupAccessHoc from "../../RoutedComponents/Routes/PolicyGroupAccessHoc";
import { getValue } from '../../core/utility'
import Badge from '../../component/BaseComponents/Badge';
const ThuList = (props) => {

    //parent inputs
    const {
        thuList,
        shipmentId,
        shipmentNo,
        accordionId,
        headerText,
        showAddButton,
        showPrintButton,
        showThulabel,
        showHoldButton,
        shipmentStatus,
        showRemeasurement,
    } = props;

    //redux
    const {
        convertToActual,
        deleteThu,
        confirmThu,
        confirmationModal,
        reverseThuState
    } = props;

    const CONSTANTS = {
        ITEMLOADER: 'loader-article',
    }

    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        if (thuList) {
            setFilteredData(thuList);
        }
    }, [thuList])

    const onAddClick = () => {
        var path = `/thu/new/${shipmentId}/${shipmentNo}`;
        history.push(path);
    }

    const onPrintClick = () => {
        var path = `/thu/print/${shipmentId}/${shipmentNo}`;
        history.push(path);
    }

    const onHoldClick = () => {
        var path = `/thu/hold/${shipmentId}`;
        history.push(path);
    }

    const showCreate = (article) => {
        return article.type === 'Expected' && article.status === 'WaitingConfirmation';
    }

    const showDelete = (article) => {
        return article.type === 'Actual' && article.status === 'WaitingConfirmation' && !article.isHeld;
    }

    const showConfirmation = (article) => {
        return article.type === 'Actual' && article.status === 'WaitingConfirmation';
    }

    const showPrint = (article) => {
        return article.type === 'Actual' && !article.retry;
    }

    const showReverseState = (article) => {
        return article.type === 'Actual' && article.status === "Instock"
    }

    const showViewDetail = (article) => {
        return article.type === 'Actual'
    }

    const renderCol = (text, shortText, value) => {
        return (
            <Col lg={3} md={4} sm={6} xs={12} className="mb-1">
                <Row className="d-none d-lg-block">
                    <Col>
                        <span className="text-title">{text}</span>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-truncate">
                        <span className="d-lg-none text-title">{shortText}: </span>
                        <span className="color-text-grey">{value}</span>
                    </Col>
                </Row>
            </Col>
        )
    }

    const renderActions = () => {
        return (
            <Row className="mb-3">
                <Col>
                    <ButtonStack>
                        {renderAddButton()}
                        {renderPrintButton()}
                        {renderHoldButton()}
                    </ButtonStack>
                </Col>
            </Row>
        )
    }

    const renderListActions = () => {
        if (filteredData && filteredData.length > 0) {
            return (
                <Row className="mb-3">
                    <Col>
                        <ButtonStack>
                            {
                                checkedThuIds.length > 0 ?
                                    [renderClearSelection(0), renderEditButton(1)]
                                    :
                                    [renderSelectAllButton(0), renderFiltersButton(1)]
                            }
                        </ButtonStack>
                    </Col>
                </Row>
            )
        }
    }

    const renderAddButton = () => {
        if (showAddButton) {
            return (
                <Button disabled={shipmentStatus === "Shipped"} size="small" variation="primary" onClick={() => onAddClick()}>
                    <FontAwesomeIcon icon={['fas', 'plus']} className="mr-2" />Add New
                </Button>
            )
        }
        return null;
    }

    const renderPrintButton = () => {
        if (showPrintButton) {
            return (
                <Button disabled={shipmentStatus === "Shipped"} size="small" variation="secondary" onClick={() => onPrintClick()}>
                    <FontAwesomeIcon icon={'print'} className="mr-2" />Print Label
                </Button>
            )
        }
        return null;
    }

    const renderHoldButton = () => {
        if (showHoldButton) {
            return (
                <Button disabled={shipmentStatus === "Shipped"} size="small" variation="outlined" onClick={() => onHoldClick()}>
                    <FontAwesomeIcon icon={'lock'} className="mr-2" />Hold THU(s)
                </Button>
            )
        }
        return null;
    }

    const renderSelectAllButton = (key) => {
        return (
            <Button key={key} size="small" variation="outlined" onClick={onChangeMasterCheckbox}>
                <FontAwesomeIcon icon={['far', 'square']} className="mr-2" />Select All
            </Button>
        );
    }

    const renderFiltersButton = (key) => {
        return (
            <Button key={key} size="small" variation="secondary" onClick={onClickFilters}>
                <FontAwesomeIcon icon={'filter'} className="mr-2" />Filters
            </Button>
        );
    }

    const renderClearSelection = (key) => {
        return (
            <Button key={key} size="small" variation="secondary" onClick={onClickResetChecks} title={`Clear selected ${checkedThuIds.length} item(s)`}>
                <FontAwesomeIcon icon={'check-square'} className="mr-2" />Clear selected {checkedThuIds.length}
            </Button>
        );
    }

    const renderEditButton = (key) => {
        return (
            <PolicyGroupAccessHoc componentName="THUConfirmHOC" key={key}>
                <Button size="small" variation="primary" onClick={onClickEdit} title={`Edit selected ${checkedThuIds.length} item(s)`} style={{ margin: '5px 10px' }}>
                    <FontAwesomeIcon icon={['fas', 'edit']} className="mr-2" />Edit {checkedThuIds.length}
                </Button>
            </PolicyGroupAccessHoc>
        );
    }

    const renderRemeasurementNotification = (article) => {
        if (showRemeasurement) {
            return (
                <Button className="input-block mb-1" size="small" variation="outlined" color="red" style={{
                    background: article.isRemeasured ? '#4CAF50' : '#BE1E2D',
                    color: 'white'
                }} onClick={() => onRemeasureClick(article.id)} title={`Remeasure. ${article.isRemeasured ? 'Remeasured at least once' : 'Never remeasured'}`}>
                    <FontAwesomeIcon icon={'exclamation-triangle'} className="mr-2" />Remeasure
                </Button>
            )
        }
        return null;
    }

    const onRemeasureClick = (id) => {
        var path = `/thu/remeasure/${id}`;
        history.push(path);
    }

    const renderConfirm = (article) => {
        if (showConfirmation(article)) {
            return (
                <Button disabled={shipmentStatus === "Shipped"} className="input-block mb-1" variation="primary" size="small" onClick={() => onConfirm(article.id)}>
                    Confirm THU
                </Button>
            );
        }
        return null;
    }

    const renderDelete = (article) => {
        if (showDelete(article)) {
            return (
                <Button
                    onClick={() => onDelete(article.id)}
                    disabled={shipmentStatus === "Shipped"}
                    className="input-block mb-1"
                    variation="outlined"
                    size="small"
                >
                    <FontAwesomeIcon icon="trash" className="mr-2" />
                    Delete
                </Button>
            )
        }
        return null;
    }

    const renderCreate = (article) => {
        if (showCreate(article)) {
            return (
                <Button className="input-block mb-1" variation="outlined" size="small" onClick={() => onCreate(article)}>
                    Create THU
                </Button>
            )
        }
        return null;
    }


    const renderPrint = (article) => {
        if (showPrint(article)) {
            return (
                <Button className="input-block mb-1" variation="outlined" size="small" onClick={() => showThulabel({ shipmentNo, ArticleList: [article] }, `${CONSTANTS.ITEMLOADER}-${article.id}`)}>
                    <FontAwesomeIcon icon="print" className="mr-2" /> Print
                </Button>
            )
        }
        return null;
    }

    const renderReverseState = (article) => {
        if (showReverseState(article)) {
            return (
                <Button className="input-block mb-1" variation="outlined" size="small" onClick={() => onReverseState(article.id)}>
                    <FontAwesomeIcon icon="history" className="mr-2" /> Reverse
                </Button>
            );
        }
        return null;
    }
    const renderViewDetail = (article) => {
        if (showViewDetail(article)) {
            return (
                <Button className="input-block mb-1" variation="outlined" size="small" onMouseDown={(e) => mouseDownHandler(e, shipmentId, article.id)} onClick={() => onViewDetail(article.id)}>
                    <FontAwesomeIcon icon="arrow-right" className="mr-2" /> Detail
                </Button>
            )
        }
        return null;
    }

    const onCreate = (article) => {
        confirmationModal('Thu Details', 'THU will be created. Do you approve?', () => convertToActual(article.id, `${CONSTANTS.ITEMLOADER}-${article.id}`));
    }

    const onConfirm = (id) => {
        confirmationModal('Thu Details', 'THU will be confirmed. Do you approve?', () => confirmThu(id, `${CONSTANTS.ITEMLOADER}-${id}`));
    }

    const onDelete = (id) => {
        confirmationModal('Thu Details', 'THU will be deleted. Do you approve?', () => {
            deleteThu(id, `${CONSTANTS.ITEMLOADER}-${id}`);
        });
    }

    const onReverseState = (id) => {
        confirmationModal('Thu Details', 'THU will be reversed to Waiting Confirmation State. Do you approve?', () => reverseThuState(id, `${CONSTANTS.ITEMLOADER}-${id}`));
    }

    const mouseDownHandler = (event, shipmentId, id) => {
        if (event.button === 1) {
            window.open(`/thu?idList=${id}`, '_blank');
        }
    }

    const onViewDetail = (id) => {
        history.push(`/thu?idList=${id}`); // /article?id=
    }

    const renderRush = (isRush) => {
        if (!isRush)
            return;
        return (
            // <div className={`${classes["isRush"]} `}>{isRush ? "RUSH" : ""}</div>
            <Badge className='p-2' variant='danger'>RUSH</Badge>
        )
    }

    const renderList = (articleList) => {

        if (!articleList || articleList.length == 0) {
            return (
                <h6>
                    No entries to show
                </h6>
            )
        }

        return articleList.map((article, i) => {
            article.shipmentId = shipmentId;

            return (
                <div className={`mb-1 card`} key={i}>
                    <div className={`card-body ${styles["thuList-card-body"]}`} >
                        {renderRush(article.isRush)}
                        <Row className={`align-items-center`}>
                            <BackgroundText text={'HOLD'} show={article.isHeld} type={'danger'} />
                            <Col lg={1} md={1} sm={1} xs={2}>
                                <input
                                    type="checkbox"
                                    id={`${article.id}`}
                                    checked={checkedThuIds.includes(article.id)}
                                    onChange={onChangeCheckbox}
                                />
                            </Col>
                            <Col lg={9} md={9} sm={11} xs={10}>
                                <Row>
                                    {renderCol('THU Number', 'THU', article.externalId)}
                                    {renderCol('Supplier', 'Sup.', getValue(article, "supplier.name"))}
                                    {renderCol('Part No', 'Part', article.externalCode)}
                                    {renderCol('Status', 'Stat.', article.status)}
                                    {renderCol('Quantity', 'Quan.', getValue(article, "actualMeasurement.itemQuantity.value"))}
                                    {renderCol('Note', 'Note', article.deliveryNote)}
                                    {renderCol('Gross Weight', 'W', `${getValue(article, "actualMeasurement.grossWeight.value")} ${getValue(article, "actualMeasurement.grossWeight.unit")}`)}
                                    {renderCol('Volume', 'V', `${getValue(article, "actualMeasurement.volume.value")} ${getValue(article, "actualMeasurement.volume.unit")}`)}
                                </Row>
                            </Col>

                            <Col lg={2} md={2} sm={12} xs={12} className={styles.thuFunctions}>
                                <ButtonStack align="right">
                                    <PolicyGroupAccessHoc componentName="THURemeasurementNotificationHOC">
                                        {renderRemeasurementNotification(article)}
                                    </PolicyGroupAccessHoc>
                                    <PolicyGroupAccessHoc componentName="THUCreateHOC">
                                        {renderCreate(article)}
                                    </PolicyGroupAccessHoc>
                                    <PolicyGroupAccessHoc componentName="THUConfirmHOC">
                                        {renderConfirm(article)}
                                    </PolicyGroupAccessHoc>
                                    {renderViewDetail(article)}
                                    <PolicyGroupAccessHoc componentName="THUPrintHOC">
                                        {renderPrint(article)}
                                    </PolicyGroupAccessHoc>
                                    <PolicyGroupAccessHoc componentName="THUReverseStateHOC">
                                        {renderReverseState(article)}
                                    </PolicyGroupAccessHoc>
                                    <PolicyGroupAccessHoc componentName="THUDeleteHOC">
                                        {renderDelete(article)}
                                    </PolicyGroupAccessHoc>
                                </ButtonStack>
                            </Col>
                        </Row>
                    </div>
                    <LoadingIndicator id={`${CONSTANTS.ITEMLOADER}-${article.id}`} />
                </div>
            )
        })
    }

    // UPDATE
    const onClickEdit = () => {
        if (checkedThuIds.length === 1) {
            history.push(`/thu?idList=${checkedThuIds[0]}`);
        } else if (checkedThuIds.length > 1) {
            const urlSearchParams = checkedThuIds.reduce((acc, item) => {
                acc.append('idList', item);
                return acc;
            }, new URLSearchParams());
            console.log("urlSearchParams: ", urlSearchParams.toString())
            history.push(`/thu?${urlSearchParams.toString()}`);
        }
    }
    // end UPDATE

    // FILTER
    const [areFiltersDisplayed, setAreFiltersDisplayed] = useState(false);

    const toggleFiltersDisplayed = () => {
        // if currently true, it wil be false
        if (areFiltersDisplayed) {
            setQueryDeliveryNote('');
            setQueryExternalCode('');
            setFilteredData(thuList);
        }
        setAreFiltersDisplayed(state => !state);
    }

    const onClickFilters = () => {
        toggleFiltersDisplayed();
    }

    const [queryDeliveryNote, setQueryDeliveryNote] = useState('');
    const [queryExternalCode, setQueryExternalCode] = useState('');

    const onSubmit = (e) => {
        e.preventDefault();
        if ([queryDeliveryNote, queryExternalCode].some(item => item !== '')) {
            const filteredData = filterThus(thuList, { queryDeliveryNote: queryDeliveryNote.toLowerCase(), queryExternalCode: queryExternalCode.toLowerCase() });
            setFilteredData(filteredData);
        }
    }

    const filterThus = (articles, { queryDeliveryNote, queryExternalCode }) => articles.filter(article => {
        if (queryDeliveryNote && queryExternalCode && article.deliveryNote && article.externalCode) {
            return article.deliveryNote.toLowerCase() === queryDeliveryNote && article.externalCode.toLowerCase() === queryExternalCode;
        } else if (queryDeliveryNote && article.deliveryNote) {
            return article.deliveryNote.toLowerCase() === queryDeliveryNote;
        } else if (queryExternalCode && article.externalCode) {
            return article.externalCode.toLowerCase() === queryExternalCode;
        }
    });

    /*
    // will use later for more filters cases
    const onReset = (e) => {
        // e.preventDefault();
        setQuery('');
        // readConnectionPoints();
        // searchConnectionPoints({ query }); // TODO: rename to filter..., since more generic semantic
    }
    */

    const onClickHideFilters = () => {
        toggleFiltersDisplayed();
    }
    // end FILTER

    // SELECT LIST ITEM/S, with checkboxes
    const [checkedThuIds, setCheckedThuIds] = useState([]);

    const onChangeCheckbox = (e) => {
        e.persist();
        if (e.target.checked) {
            setCheckedThuIds(state => state.includes(e.target.id) ? state : state.concat(e.target.id)); // check one
        } else {
            setCheckedThuIds(state => state.filter(item => item !== e.target.id)); // uncheck one
        }
    }

    const onChangeMasterCheckbox = (e) => {
        e.persist();
        if (e.target.checked || checkedThuIds.length !== thuList.length) {
            setCheckedThuIds(thuList.map(item => item.id));// check all
        } else {
            setCheckedThuIds([]);// uncheck all
        }
    }

    const onClickResetChecks = () => setCheckedThuIds([]);
    // end SELECT LIST ITEM/S

    return (
        <div>
            <div className='card'>
                <div className='card-header' href={`#${accordionId}`} data-toggle='collapse' data-parent='#accordion'>
                    <h5 className='mb-0'>
                        <div>
                            <img src='/image/icon/down.svg' className='color-action-blue' width='20px' alt='' /> {headerText}
                        </div>
                    </h5>
                </div>

                <div id={accordionId} className='collapse show'>
                    <div className='card card-body'>
                        <LoadingIndicator id='loader-thu-actions' />

                        <header>

                            <div /*className={styles.title}*/>
                                <PolicyGroupAccessHoc componentName="THUListControlsHOC">
                                    {renderActions()}
                                    {renderListActions()}
                                </PolicyGroupAccessHoc>
                            </div>

                            {
                                areFiltersDisplayed &&
                                <>
                                    <Row style={{ margin: '8px 0px', width: '100%' }}>
                                        <Col style={{ width: '100%', padding: '0px' }}>
                                            <form onSubmit={onSubmit} style={{ margin: '0px' }}>
                                                <Row>

                                                    <Col xs="12" sm="12" md="4">
                                                        <input
                                                            value={queryDeliveryNote}
                                                            onChange={e => setQueryDeliveryNote(e.target.value)}
                                                            autoFocus
                                                            placeholder="Advice Note"
                                                        />
                                                    </Col>

                                                    <Col xs="12" sm="12" md="4">
                                                        <input
                                                            value={queryExternalCode}
                                                            onChange={e => setQueryExternalCode(e.target.value)}
                                                            placeholder="Part No"
                                                        />
                                                    </Col>

                                                    <Col xs="12" sm="12" md="4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                        <input type="submit" value="Filter" />
                                                    </Col>
                                                </Row>
                                            </form>
                                        </Col>
                                    </Row>
                                </>
                            }
                        </header>

                        <Row className="mb-3">
                            <Col>
                                {renderList(filteredData)}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col className="text-right">
                                <label>Showing {filteredData.length} of {thuList.length} entries</label>
                            </Col>
                        </Row>
                    </div>
                </div>

            </div>
        </div>
    )

}

const mapStateToProps = ({ }) => {
    return {

    }
}

const mapActionToProps = {
    convertToActual,
    deleteThu,
    confirmThu,
    confirmationModal,
    showThulabel,
    reverseThuState
}

export default connect(mapStateToProps, mapActionToProps)(ThuList);