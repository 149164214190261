const businessAreaActions = Object.freeze({
    BUSINESS_AREA_LIST_RECEIVED: Symbol('BUSINESS_AREA_LIST_RECEIVED'),
    BUSINESS_AREA_RECEIVED: Symbol('BUSINESS_AREA_RECEIVED'),
    BUSINESS_AREA_SAVED: Symbol('BUSINESS_AREA_SAVED'),
    BUSINESS_AREA_DELETED: Symbol('BUSINESS_AREA_DELETED'),
    BUSINESS_AREA_CLEAR_STATE: Symbol('BUSINESS_AREA_CLEAR_STATE'),
    COMPANY_LIST_RECEIVED: Symbol('COMPANY_LIST_RECEIVED')
});

export default businessAreaActions;
