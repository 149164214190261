import imageControllerTypes from '../type/imageControllerTypes';
import modalTypes from '../type/modal';
import postSync from '../../core/httpClientSync';
import _ from 'lodash';
import { spinnerService } from "@simply007org/react-spinners";
import httpClient from '../../core/httpclient';

export const uploadSingleImage = (imageToUpload, uploadData, imageControllerOptions, spinnerElementId) => async dispatch => {

    const url = 'xms-document-api/api/document/uploadFiles'
    const formData = new FormData();

    formData.append('File', imageToUpload, imageToUpload.name);
    formData.append('Data', JSON.stringify(uploadData));
    formData.append('FileName', imageToUpload.name);
    formData.append('Path', imageControllerOptions.relativePath);
    formData.append('sourceType', 'image');
    formData.append('externalId', imageControllerOptions.externalId);
    formData.append('externalType', imageControllerOptions.externalType);

    const uploadImageResponse = await postSync(url, formData, spinnerElementId);
    if (uploadImageResponse.isSuccess) {
        const updateImageInfo = {
            oldUid: imageToUpload.uid,
            newUid: uploadImageResponse.response.uid,
            status: 'uploaded'
        }
        dispatch({
            type: imageControllerTypes.UPDATE_IMAGE_INFO,
            payload: updateImageInfo
        })
    }
}

export const addImageToImageList = (image) => async dispatch => {

    dispatch({
        type: imageControllerTypes.ADD_IMAGE_TO_IMAGE_LIST,
        payload: image
    })

}

export const retrieveImages = (relativePath, spinnerElementId) => async dispatch => {

    const url = 'xms-document-api/api/document/fileList'
    const filePathRequest = { relativePath };

    const filePathResponse = await postSync(url, filePathRequest, spinnerElementId);
    if (filePathResponse.isSuccess) {
        for (const image of filePathResponse.response.fileList) {
            const { createdBy, data, fileName, original, relativePath, thumbnail, type, uid } = image;

            dispatch({
                type: imageControllerTypes.ADD_IMAGE_TO_IMAGE_LIST,
                payload: { createdBy, data, fileName, original, relativePath, thumbnail, type, uid, status: 'uploaded' }
            })

        }
    }
}

export const deleteImage = (imageToDelete, relativePath, spinnerId) => async dispatch => {

    if (imageToDelete.status === 'uploaded') {
        if (spinnerId) { spinnerService.show(spinnerId) }
        const deleteUrl = `xms-document-api/api/document/deleteFile/?relativePath=${relativePath}&uid=${imageToDelete.uid}`
        httpClient.delete(deleteUrl)
            .then(res => {
                dispatch({
                    type: imageControllerTypes.REMOVE_IMAGE_FROM_IMAGE_LIST,
                    payload: imageToDelete
                })
                if (spinnerId) { spinnerService.hide(spinnerId) }
            })
            .catch(function (err) {
                if (spinnerId) { spinnerService.hide(spinnerId) }
            });
    } else {
        dispatch({
            type: imageControllerTypes.REMOVE_IMAGE_FROM_IMAGE_LIST,
            payload: imageToDelete
        })
    }
}

export const downloadSingleImage = (imageToDownload, relativePath, spinnerId) => async dispatch => {
    const endPoint = `xms-document-api/api/document/getFile/?relativePath=${relativePath}&uid=${imageToDownload.uid}`;

    const fileName = imageToDownload.fileName ? imageToDownload.fileName : imageToDownload.name;

    if (spinnerId) { spinnerService.show(spinnerId) }
    httpClient.get(endPoint, { responseType: 'blob' })
        .then(blob => {
            downloadBlobData(blob, fileName);
            if (spinnerId) { spinnerService.hide(spinnerId) }
        })
        .catch(function (err) {
            console.log(err);
            if (spinnerId) { spinnerService.hide(spinnerId) }
        });
}


const downloadBlobData = async (blobData, downloadFileName) => {
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blobData.data, downloadFileName);
    } else {
        var data = new Blob([blobData.data], { type: 'image/jpeg' });
        var csvURL = window.URL.createObjectURL(data);
        const tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', downloadFileName);
        tempLink.click();
    }
}

export const updateImageComment = (imageUid, newComment) => async dispatch => {

    const payload = { uid: imageUid, comment: newComment };

    dispatch({
        type: imageControllerTypes.UPDATE_IMAGE_COMMENT,
        payload
    })

}

export const showImageOnRealSize = (url, image) => async dispatch => {
    dispatch({
        type: modalTypes.SHOW_MODAL,
        payload: {
            modalType: 'show-image',
            modalData: { url, image }
        }
    })
}

export const clearImageControllerValues = () => async dispatch => {
    dispatch({
        type: imageControllerTypes.CLEAR_IMAGE_VALUES
    })
}