import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// this hook runs func when user navigates to routes given in urlBag.
// the function test takes 1 argument, urlBag, that can be an array of anything, and returns a boolean.
// if boolean true test passed and function in func wll be called, otherwise not.
// By default test tests it with a startsWith algorithm in test parameter but you can pass it your own test function
export function useIfNotNavigatedTo(
    urlBag,
    func,
    test = (urlBag) => urlBag.every(item => !history.location.pathname.startsWith(item))) {

    let history = useHistory();

    useEffect(() => {
        return () => test(urlBag) ? func() : false;
    }, []);
}