/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import SchemaInterpreter from '../component/FormComponents/Schema/SchemaInterpreter';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import { CreateBookingSchema } from '../component/FormComponents/Schema/createBooking';
import Page from '../component/Page/Page';
import history from '../core/history';
import './BookingCommon.css';
import { isOnlyExternalUser } from '../core/authentication';
import {
    fillBookingFormData,
    getBusinessAreas,
    getUnitTypesForBooking,
    getClients,
    getServicesByClientAndType,
    getBusinessAreasByClient,
    clearBookingFormData,
    getBusinessAreaDetails,
    getDynamicTimeSlots
} from '../store/action/bookingActions';
import { toast } from 'react-toastify';
import { epochToShortDateTime } from '../core/epochToDateConverter';
import moment from 'moment';
import ModalSuggestBookingDateTime from './ModalSuggestBookingDateTime';
import { useIfNotNavigatedTo } from "../core/hooks";
import { ResetFilters } from '../store/action/bookingsQueryStringActions';

const CreateBookingContainer = (props) => {
    const ref = useRef(null);

    // Redux actions
    const {
        fillBookingFormData,
        getUnitTypesForBooking,
        getClients,
        getServicesByClientAndType,
        getBusinessAreasByClient,
        clearBookingFormData,
        getBusinessAreaDetails,
        getDynamicTimeSlots,
        ResetFilters
    } = props;

    // Redux store
    let {
        timeSlots,
        businessAreas,
        formData,
        unitTypesForBooking,
        clients,
        clientBusinessAreas,
        services,
        businessAreaDetails
    } = props;

    const [booking, setBooking] = useState({});
    const [isModalSuggestBookingDateTime, setIsModalSuggestBookingDateTime] = useState(false);
    const [timeSlotValidationMessage, setTimeSlotValidationMessage] = useState("");

    useIfNotNavigatedTo(['/booking/list', '/booking/carrier-booking-list', '/booking/new/confirm'], ResetFilters);
    
    useEffect(() => {
        if (ref && ref.current) {
            ref.current.scrollIntoView();
        }
    }, [ref]);

    useEffect(() => {
        if (!unitTypesForBooking) {
            getUnitTypesForBooking();
        }
    }, [unitTypesForBooking, getUnitTypesForBooking]);

    useEffect(() => {
        if (booking && booking.clientId) {
            getBusinessAreasByClient(booking.clientId);
        }
    }, [booking.clientId, getBusinessAreasByClient]);

    useEffect(() => {
        if (clientBusinessAreas && clientBusinessAreas.length == 1) {
            setBooking((prevState) => {
                return { ...prevState, businessAreaId: clientBusinessAreas[0].value };
            });
            return;
        }

        setBooking((prevState) => {
            return { ...prevState, businessAreaId: null };
        });
    }, [clientBusinessAreas]);

    useEffect(() => {
        if (formData && !booking.clientId) {
            setBooking(formData);
        }
    }, [formData, booking, setBooking]);

    useEffect(() => {
        if (booking && booking.clientId && booking.operationType && !booking.serviceId) {
            getServicesByClientAndType(booking.clientId, booking.operationType);
        }
    }, [booking.operationType, booking.clientId, getServicesByClientAndType]);

    useEffect(() => {
        if (businessAreas && businessAreas.length === 1 && !booking.businessAreaId) {
            setBooking((prevState) => {
                return { ...prevState, businessAreaId: businessAreas[0].value };
            });
        }
    }, [businessAreas, booking, setBooking]);

    useEffect(() => {
        if (!clients) {
            getClients();
        } else if (clients.length == 1) {
            setBooking((prevState) => {
                return { ...prevState, clientId: clients[0].value, clientName: clients[0].text };
            });
            return;
        }

    }, [clients, getClients]);

    useEffect(() => {        
        if (
            booking &&
            booking.preferredBookingDate &&
            !booking.preferredBookingDate.toString().endsWith('00:00')
        ) {
            getDynamicTimeSlots(booking);
        }
    }, [booking.preferredBookingDate]);

    useEffect(() => {
        setTimeSlotValidationMessage("");
        if (
            timeSlots && booking.preferredBookingDate &&
            ((timeSlots.length == 1 &&
                timeSlots[0] !=
                moment.utc(booking.preferredBookingDate, 'DD/MM/YYYY HH:mm').valueOf() / 1000) ||
                timeSlots.length > 1)
        ) {
            setIsModalSuggestBookingDateTime(true);
        }else if(timeSlots == null && booking.preferredBookingDate)
        {
            setTimeSlotValidationMessage("No time slot reservations are available for the given date");
        }
    }, [timeSlots]);

    const getOperationTypeList = () => {
        return [
            { value: 'Loading', text: 'Loading' },
            { value: 'Unloading', text: 'Unloading' }
        ];
    };

    const submit = (bookingModel) => {
        if (
            timeSlots &&
            ((timeSlots.length == 1 &&
                timeSlots[0] !=
                moment.utc(bookingModel.preferredBookingDate, 'DD/MM/YYYY HH:mm').valueOf() / 1000) ||
                timeSlots.length > 1)
        ) {
            setIsModalSuggestBookingDateTime(true);
        }
        else {
            bookingModel.dateTime =
                moment.utc(bookingModel.preferredBookingDate, 'DD/MM/YYYY HH:mm').valueOf() / 1000;

            fillBookingFormData(bookingModel);
            history.push('/booking/new/confirm');
        }
    };

    const updateBookingPreferedDate = (date) => {
        setBooking((prevState) => {
            return {
                ...prevState,
                preferredBookingDate: date
            };
        });
        setIsModalSuggestBookingDateTime(false);
    };
    const onFieldChangeHandler = (event, newValue, previousValue, fieldId) => {
        if (fieldId === 'clientId') {
            setBooking((prevState) => {
                return {
                    ...prevState,
                    clientId: newValue,
                    clientName: clients && clients.length > 0 && newValue != "" ? clients.find((x) => x.value === newValue).text : null,
                    businessAreaId: businessAreas && businessAreas.length == 1 ? businessAreas[0] : null,
                    operationType: null
                };
            });
        } else if (fieldId === 'businessAreaId') {
            setBooking((prevState) => {
                return {
                    ...prevState,
                    businessAreaId: newValue,
                    businessAreaName: clientBusinessAreas && clientBusinessAreas.length && newValue != "" > 0 ? clientBusinessAreas.find((x) => x.value === newValue).text : null
                };
            });
        } else if (fieldId === 'serviceId') {
            setBooking((prevState) => {
                return {
                    ...prevState,
                    serviceId: newValue,
                    serviceName: services && services.length > 0 && newValue != "" ? services.find((x) => x.value === newValue).text : null
                };
            });
        } else if (fieldId === 'operationType') {
            setBooking((prevState) => {
                return {
                    ...prevState,
                    serviceId: null,
                    operationType: newValue
                };
            });
        } else if (fieldId === 'unitType') {
            setBooking((prevState) => {
                return {
                    ...prevState,
                    unitType: newValue,
                    unitTypeName: unitTypesForBooking && unitTypesForBooking.length > 0 && newValue != "" ?unitTypesForBooking.find((x) => x.value === newValue).text : null
                };
            });
        } else {
            setBooking((prevState) => {
                return {
                    ...prevState,
                    [fieldId]: newValue
                };
            });
        }
    };

    // const fieldRules = {
    //     'preferredBookingDate': {
    //         isDisabled: !businessAreaDetails || !businessAreaDetails.isDynamicTimeSlot,
    //         isRequired: businessAreaDetails && businessAreaDetails.isDynamicTimeSlot
    //     }
    // }
    const bookingValidation = () => {
        return timeSlotValidationMessage;
    }

    return (
        <Page
            backLink={!isOnlyExternalUser() ? '/booking/list' : '/booking/carrier-booking-list'}
            backToWhere="Bookings"
        >
            <div style={{ display: 'hidden' }} ref={ref}></div>

            {clients && booking && (
                <SchemaInterpreter
                    title="Booking information"
                    onSubmit={submit}
                    formItem={booking}
                    onFieldChange={onFieldChangeHandler}
                    formSchema={CreateBookingSchema({bookingValidation})}
                    operationTypes={getOperationTypeList()}
                    businessAreas={clientBusinessAreas}
                    clients={clients}
                    services={services}
                    unitTypes={unitTypesForBooking}
                //fieldRules={fieldRules}
                />
            )}
            <ModalSuggestBookingDateTime
                showModal={isModalSuggestBookingDateTime}
                onHide={() => setIsModalSuggestBookingDateTime(false)}
                modalData={{ timeSlots }}
                onSelected={(param) => updateBookingPreferedDate(param)}
            ></ModalSuggestBookingDateTime>
            <LoadingIndicator id="createOrUpdateBooking" />
        </Page>
    );
};

const mapStateToProps = (state) => {
    return {
        clients: state.booking.clients,
        clientBusinessAreas: state.booking.clientBusinessAreas,
        services: state.booking.services,
        formData: state.booking.formData,
        businessAreas: state.booking.businessAreas,
        businessAreaDetails: state.booking.businessAreaDetails,
        unitTypesForBooking: state.booking.unitTypesForBooking,
        timeSlots: state.booking.timeSlots
    };
};

const mapDispatchToProps = {
    fillBookingFormData,
    clearBookingFormData,
    getBusinessAreas,
    getUnitTypesForBooking,
    getClients,
    getServicesByClientAndType,
    getBusinessAreasByClient,
    getBusinessAreaDetails,
    getDynamicTimeSlots,
    ResetFilters
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateBookingContainer);
