import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Checkbox } from '@dfds-ui/react-components';
import classes from './ShipmentInboundOperation.module.css';
import { getValue } from '../../core/utility'

const ShipmentRow = ({ shipment, onChange, isChecked }) => {

    return (<>
        <Card>
            <Card.Body>
                <Row className={`align-items-center`}>
                    <Col xs={2}>
                        <Checkbox className={`${classes["shipments-checkbox"]} `} onChange={e => onChange(shipment.id, e.target.checked)} checked={isChecked(shipment.id)}></Checkbox>
                    </Col>
                    <Col xs={10}>
                        <Row>
                            <Col xs={8}>
                                {`${shipment.shipmentNo}`}
                            </Col>
                            <Col xs={4}>
                                {`${getValue(shipment, 'unit.group.code')}`}
                            </Col>
                            <Col xs={12}>
                                <Row>
                                    <Col xs={12} className={'text-truncate'}>
                                        {`Supplier: ${getValue(shipment, 'supplier.name')}`}
                                    </Col>
                                    <Col xs={4}>
                                        {`Thu(s): ${getValue(shipment, 'expectedMeasurement.itemQuantity.value')}`}
                                    </Col>
                                    <Col xs={4}>
                                        {`GW: ${getValue(shipment, 'expectedMeasurement.grossWeight.value')} KG`}
                                    </Col>
                                    <Col xs={4}>
                                        {`V: ${getValue(shipment, 'expectedMeasurement.volume.value')} M3`}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    </>)
}

export default ShipmentRow;