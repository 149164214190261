import { Col, Form } from "react-bootstrap";
import { AuthorizationWrapper } from "./AuthorizationRenderer";
import * as PropTypes from "prop-types";
import React from "react";
import { NumberInputFormComponent } from "../../NumberInputFormComponent";

export const NumberRenderer = (field, formColumnCount) => {
    const numberControl = (<Form.Group key={field.name} as={Col} md={formColumnCount}>
        <Form.Label>{field.name}{field.isRequired && ' *'}</Form.Label>
        <NumberInputFormComponent name={field.id} isRequired={field.isRequired} step={field.step} minValue={field.minValue} validationArray={field.validations} isDisabled={field.isDisabled} onChange={field.onChange} />
    </Form.Group>);

    if (field.authorizationWrapper) {
        return AuthorizationWrapper(numberControl, field.id, field.authorizationWrapper);
    }
    return numberControl;
};

NumberRenderer.propTypes = {
    item: PropTypes.object.isRequired
};