import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Button, Chip } from '@dfds-ui/react-components';
import classes from '../shipments.module.css';
import ShipmentRow from './ShipmentRow.js';
import GroupFilter from '../filters/GroupFilter';
import SearchFilter from '../filters/SearchFilter';

const List = ({ shipmentList, groupList, onClick, pageOptions, selectedGroup, onGroupChange }) => {

    const [filteredData, setFilteredData] = useState([]);
    const [keyword, setKeyword] = useState('');

    useEffect(() => {
        counter();
        if (shipmentList) {
            setKeyword('');
            setFilteredData(handleIsRush(shipmentList));
        }
    }, [shipmentList]);

    const counter = () => {
        const count = shipmentList.flatMap(s => s.articleList.filter(t => t.isChecked).length).reduce((a, b) => a + b, 0);
        document.getElementsByClassName('count-top')[0].innerText = count;
    }

    const handleIsRush = (shipmentList) => {
        
        const list = shipmentList.map(shipment => {
            if(shipment.articleList && shipment.articleList.filter(t => t.isRush).length > 0) 
                return { ...shipment, isRush: true }
            return shipment
          })

          list.sort(function(x, y) { 
            return (x.isRush === y.isRush) ? 0 : x.isRush ? -1 : 1; 
        });
        
        return list;

    }

    const onSearchChange = (key) => {
        setKeyword(key);

        const searchTerm = key.trim().toUpperCase();
        if (!searchTerm) {
            setFilteredData(shipmentList);
            return;
        }

        const newFilteredData = [];
        for (const shipment of shipmentList) {

            if (shipment.shipmentNo.toUpperCase().indexOf(searchTerm) > -1) {
                newFilteredData.push(shipment);
            }
            else if (shipment.articleList.filter(s => s.externalId.toUpperCase().indexOf(searchTerm) > -1).length > 0) {

                var filteredShipment = { ...shipment };
                filteredShipment.articleList = shipment.articleList.filter(s => s.externalId.toUpperCase().indexOf(searchTerm) > -1);
                newFilteredData.push(filteredShipment);
            }
        }
        setFilteredData(newFilteredData);
    }

    return (
        <>
            <Card className={`bg-light`}>
                <Card.Header className={`${classes["shipments-card-header"]}`}>
                    <Row>
                        <Col className="text-center">
                            <h5 className="font-weight-bold">Shipments</h5>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col md={4}>
                            <SearchFilter show={pageOptions.showSearch} onChange={onSearchChange} value={keyword} />
                        </Col>
                        <Col md={2}></Col>
                        <Col md={6}>
                            <Row>
                                <Col xs={6}>
                                    <GroupFilter
                                        list={groupList}
                                        show={pageOptions.showGroup}
                                        selectedGroup={selectedGroup}
                                        onChange={onGroupChange}
                                    />
                                </Col>
                                <Col xs={pageOptions.showGroup ? 6 : 12} className="align-self-flex-end text-right">
                                    <div className="form-group">
                                        <Button variation="primary" onClick={onClick}>
                                            {pageOptions.buttonText}
                                            <span className={`badge badge-light count-top ${classes["shipments-count"]}`}>{0}</span>
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card.Body>
                <div>
                    {
                        filteredData.map(shipment => {
                            return (
                                <ShipmentRow
                                    key={shipment.id}
                                    shipment={shipment}
                                    counter={counter}
                                    showSupplier={pageOptions.showSupplierField}
                                    showPart={pageOptions.showPartField}
                                    showVolume={pageOptions.showVolumeField}
                                    showQuantity={pageOptions.showQuantityField}
                                    showWeight={pageOptions.showWeightField} />
                            )
                        })
                    }
                </div>
            </Card>
        </>
    )
}
export default List;
