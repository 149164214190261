import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ListItem, Input } from '@dfds-ui/react-components';
import { YesTick } from '@dfds-ui/icons'
import { saveAdminNote } from '../../store/action/bookingActions';
import { connect } from 'react-redux';
import LoadingIndicator from "../../component/LoadingIndicator/LoadingIndicator";

const ModalUpdateAdminNotes = ({ modalData, onHide, saveAdminNote }) => {
    
    const [adminNote, setAdminNote] = useState(modalData.adminNote ? modalData.adminNote : "");

    const onSaveClickHandler = () => {
        saveAdminNote(modalData.bookingCode, adminNote, ()=> {
            onHide()
        });
    }
    
    return (
        <Modal
            show={true}
            onHide={onHide}
            centered
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>{'Admin Notes - ' + modalData.bookingCode }</Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ overflow: 'auto', height: "300px" }}>
                {/* <Input name="adminNotes" placeholder="Admin notes..." aria-rowcount="6" value={adminNote} onChange={e => setAdminNote(e.target.value)} /> */}
                <textarea placeholder="Admin notes..." rows="10" value={adminNote} onChange={e => setAdminNote(e.target.value)} style={{width:"100%"}}/>
                <LoadingIndicator id="adminNoteModal" />
            </Modal.Body>

            <Modal.Footer>
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 text-right">
                            <Button variant='primary' className='ml-3' onClick={onSaveClickHandler}>Save</Button>
                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal >
    )
}


const mapStateToProps = (state) => {
    return null;
};
  
const mapDispatchToProps = {
    saveAdminNote
};
  
export default connect(mapStateToProps, mapDispatchToProps)(ModalUpdateAdminNotes);