import modalTypes from '../type/modal';
import { spinnerService } from "@simply007org/react-spinners";
import httpClient from '../../core/httpclient';
import { toast } from 'react-toastify';

const apiUrl = 'xms-report-api/api/shipments';
// const apiUrl = 'http://localhost:5000/api/shipments';
const reportApiUrl = "xms-report-api/api";

export const showReport = (request, downloadReportName, spinnerId, renderAsPdf = true) => async dispatch => {
    const url = `${apiUrl}?type=${request.type}&identifier=${request.identifier}&renderAsPdf=${renderAsPdf}`;
    if (spinnerId) { spinnerService.show(spinnerId) };

    if (renderAsPdf) {
        try {
            const response = await httpClient.get(url, { responseType: 'blob' });
            dispatch(showFileModal(response.data, downloadReportName, 'uploaded'));
        } catch (error1) {
            var reader = new FileReader();
            reader.onload = function () {
                var data = JSON.parse(this.result);
                var message = data.errors && data.errors[0] && data.errors[0].description ? data.errors[0].description : "System Error";
                toast.error(message);
            };

            reader.readAsText(error1.response.data);
        }
    } else {
        try {
            const data = await httpClient.get(url);
            dispatch(showReportModal(data.data.result));
        } catch (error2) {
            var data = error2.response.data;
            var message = data.errors && data.errors[0] && data.errors[0].description ? data.errors[0].description : "System Error";
            toast.error(message);
        }
    }

    if (spinnerId) { spinnerService.hide(spinnerId) }
}

export const showReportAction = (request, downloadReportName, spinnerId, renderAsPdf = true) => async dispatch => {
    if (spinnerId) { spinnerService.show(spinnerId) };

    if (renderAsPdf) {
        try {
            const response = await httpClient.post(`${reportApiUrl}/report/generate`, request, { responseType: 'blob' });
            console.log("response: ", response)
            return dispatch(showFileModal(new Blob([response.data], { type: 'application/pdf' }), downloadReportName, 'uploaded'));
        } catch (error1) {
            console.log("catch error1: ", error1)
            var reader = new FileReader();
            reader.onload = function () {
                var data = JSON.parse(this.result);
                var message = data.errors && data.errors[0] && data.errors[0].description ? data.errors[0].description : "System Error";
                toast.error(message);
            };

            reader.readAsText(error1.response.data);
        } finally {
            if (spinnerId) { spinnerService.hide(spinnerId) }
        }
    } else {
        try {
            const data = await httpClient.get(reportApiUrl);
            dispatch(showReportModal(data.data.result));
        } catch (error2) {
            var data = error2.response.data;
            var message = data.errors && data.errors[0] && data.errors[0].description ? data.errors[0].description : "System Error";
            toast.error(message);
        } finally {
            if (spinnerId) { spinnerService.hide(spinnerId) }
        }
    }

}

export const showThulabel = (request, spinnerId = null) => async dispatch => {
    if (spinnerId) { spinnerService.show(spinnerId) };

    const url = `${apiUrl}/getThuLabel`;
    httpClient.post(url, request, { responseType: 'blob' })
        .then(response => {
            dispatch(showFileModal(response.data, 'THU', 'uploaded'));
            if (spinnerId) { spinnerService.hide(spinnerId) }
        })
        .catch(err => {
            console.error("err: ", err)

            var reader = new FileReader();
            reader.onload = function () {
                console.log("this.result: ", this.result)
                var data = JSON.parse(this.result);
                var message = data.errors && data.errors[0] && data.errors[0].description ? data.errors[0].description : "System Error";
                toast.error(message);
                console.log(data);
            };

            reader.readAsText(err.response.data);
            if (spinnerId) { spinnerService.hide(spinnerId) }
        });
}

const showFileModal = (data, fileName, status) => dispatch => {
    let file = {
        data: data,
        url: window.URL.createObjectURL(data),
        name: fileName,
        status: status,
    }

    dispatch({
        type: modalTypes.SHOW_MODAL,
        payload: {
            modalType: 'show-file',
            modalData: { file: file }
        }
    })
}

export const showReportModal = (data) => dispatch => {
    console.log("show report modal action")
    dispatch({
        type: modalTypes.SHOW_MODAL,
        payload: {
            modalType: 'modal-report',
            modalData: data
        }
    })
}