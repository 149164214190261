export const TYPES = {
    LOADING: 'Loading',
    UNLOADING: 'Unloading'
}

export const STATUS = {
    INITIATED: 'Initiated',
    ASSIGNED: 'Assigned',
    INPROGRESS: 'InProgress',
    COMPLATED: 'Completed',
    CANCELLED: 'Cancelled'
}