import actionTypes from "./actionTypes";
import postSync from "../../../core/httpClientSync";

const terminalURL = "xms-terminal-api/api";
//const terminalURL = "http://localhost:5000/api";

/** CREATE CONTAINER_BOOKING */
function createContainerBooking(payload, processIndicatorId) {
    return async function (dispatch) {
        dispatch(createContainerBookingRequesting());

        const result = await postSync(`${terminalURL}/booking/create`, payload, processIndicatorId);
        if (result.isSuccess) {
            dispatch(createContainerBookingSuccess(result.response));
        } else {
            dispatch(createContainerBookingFailure(result.error));
        }
        return result;
    }
};

function createContainerBookingRequesting() {
    return {
        type: actionTypes.CREATE_CONTAINER_BOOKING_REQUESTING
    }
}

function createContainerBookingSuccess(payload) {
    return {
        type: actionTypes.CREATE_CONTAINER_BOOKING_SUCCESS,
        payload
    }
}

function createContainerBookingFailure(payload) {
    return {
        type: actionTypes.CREATE_CONTAINER_BOOKING_FAILURE,
        payload
    }
}
/** end CREATE CONTAINER_BOOKING */


/** READ CONTAINER_BOOKING, singular */
function readContainerBooking(payload, processIndicatorId) {
    return async function (dispatch) {
        dispatch(readContainerBookingRequesting());

        const result = await postSync(`${terminalURL}/booking/get`, payload, processIndicatorId);
        if (result.isSuccess) {
            dispatch(readContainerBookingSuccess(result.response));
        } else {
            dispatch(readContainerBookingFailure(result.error));
        }
        return result;
    }
};

function readContainerBookingRequesting() {
    return {
        type: actionTypes.READ_CONTAINER_BOOKING_REQUESTING
    }
}

function readContainerBookingSuccess(payload) {
    return {
        type: actionTypes.READ_CONTAINER_BOOKING_SUCCESS,
        payload
    }
}

function readContainerBookingFailure(payload) {
    return {
        type: actionTypes.READ_CONTAINER_BOOKING_FAILURE,
        payload
    }
}
/** end READ CONTAINER BOOKING */


/** READ CONTAINER_BOOKINGS, plural */
function readContainerBookings(payload, processIndicatorId) {
    return async function (dispatch) {
        dispatch(readContainerBookingsRequesting());

        const payloadAugmented = {
            properties: payload,
            size: 50
        }

        const result = await postSync(`${terminalURL}/booking/search`, payloadAugmented, processIndicatorId);
        if (result.isSuccess) {
            dispatch(readContainerBookingsSuccess(result.response.items));
        } else {
            dispatch(readContainerBookingsFailure(result.error));
        }
        return result;
    }
};

function readContainerBookingsRequesting() {
    return {
        type: actionTypes.READ_CONTAINER_BOOKINGS_REQUESTING
    }
}

function readContainerBookingsSuccess(payload) {
    return {
        type: actionTypes.READ_CONTAINER_BOOKINGS_SUCCESS,
        payload
    }
}

function readContainerBookingsFailure(payload) {
    return {
        type: actionTypes.READ_CONTAINER_BOOKINGS_FAILURE,
        payload
    }
}
/** end READ CONTAINER BOOKINGS */


/** UPDATE CONTAINER BOOKING */
function updateContainerBooking(payload, processIndicatorId) {
    return async function (dispatch) {
        dispatch(updateContainerBookingRequesting());

        const result = await postSync(`${terminalURL}/booking/update`, payload, processIndicatorId);
        if (result.isSuccess) {
            dispatch(updateContainerBookingSuccess(result.response));
        } else {
            dispatch(updateContainerBookingFailure(result.error));
        }
        return result;
    }
};

function updateContainerBookingRequesting() {
    return {
        type: actionTypes.UPDATE_CONTAINER_BOOKING_REQUESTING
    }
}

function updateContainerBookingSuccess(payload) {
    return {
        type: actionTypes.UPDATE_CONTAINER_BOOKING_SUCCESS,
        payload
    }
}

function updateContainerBookingFailure(payload) {
    return {
        type: actionTypes.UPDATE_CONTAINER_BOOKING_FAILURE,
        payload
    }
}
/** end UPDATE CONTAINER_BOOKING */


/** DELETE CONTAINER_BOOKINGS, plural */
function deleteContainerBookings(payload, processIndicatorId) {
    return async function (dispatch) {
        dispatch(deleteContainerBookingsRequesting());

        const result = await postSync(`${terminalURL}/booking/delete`, payload, processIndicatorId);
        if (result.isSuccess) {
            dispatch(deleteContainerBookingsSuccess(payload));
        } else {
            dispatch(deleteContainerBookingsFailure(result.error));
        }
        return result;
    }
};

function deleteContainerBookingsRequesting() {
    return {
        type: actionTypes.DELETE_CONTAINER_BOOKINGS_REQUESTING
    }
}

function deleteContainerBookingsSuccess(payload) {
    return {
        type: actionTypes.DELETE_CONTAINER_BOOKINGS_SUCCESS,
        payload
    }
}

function deleteContainerBookingsFailure(payload) {
    return {
        type: actionTypes.DELETE_CONTAINER_BOOKINGS_FAILURE,
        payload
    }
}
/** end DELETE CONTAINER_BOOKINGS, plural */


/** FILTER CONTAINER_BOOKINGS */
function setFilter(payload) {
    return {
        type: actionTypes.SET_FILTER,
        payload
    };
};

function setFilters(payload) {
    return {
        type: actionTypes.SET_FILTERS,
        payload
    };
};

function setFiltersAux(payload) {
    return {
        type: actionTypes.SET_FILTERS_AUX,
        payload
    };
}

function resetAllFilters() {
    return {
        type: actionTypes.RESET_ALL_FILTERS
    };
};
/** end FILTER CONTAINER_BOOKINGS */

/** SORT SHIPMENTS */
function sortContainerBookings(payload) {
    return {
        type: actionTypes.SORT_CONTAINER_BOOKINGS,
        payload
    }
};
/** end SORT SHIPMENTS */

export {
    createContainerBooking,
    readContainerBooking,
    readContainerBookings,
    updateContainerBooking,
    deleteContainerBookings,
    setFilter,
    setFilters,
    setFiltersAux,
    resetAllFilters,
    sortContainerBookings,
};