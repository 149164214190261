const bookingsQueryStringTypes = Object.freeze({
    SETQUERYSTRING: 'SETQUERYSTRING',
    READQUERYSTRING: 'READQUERYSTRING',
    DATE_CHANGED: 'DATE_CHANGED',
    BUSINESSAREA_CHANGED: 'BUSINESSAREA_CHANGED',
    SEARCHTERM_CHANGED: 'SEARCHTERM_CHANGED',
    BOOKINGCODE_CHANGED: 'BOOKINGCODE_CHANGED',
    RESET_ALL_QUERY: 'RESET_ALL_QUERY'
});

export default bookingsQueryStringTypes;