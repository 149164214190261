import actionTypes from "./actionTypes";
import { post, get } from '../../../core/httpClientSync';
import { closeModals } from '../../action/modalActions';

// TODO: add a should fetch check since when we go from a list to list item page and back to list it fetches again and puts the spinner, this is  little bit frustrating and loss of time: just show the component with the old data and fetch at the same time and merge or replace the state with newly arrived results. and this fetch should not block whole list, maybe can show still a spinner somewhere else, maybe in a toast.

const url = 'xms-shipment-api/api/shipments';
// const url = 'http://localhost:5000/api/shipments';

/** READ SHIPMENTS */
function readShipments(payload, loadingIndicatorId) {
    return async function (dispatch) {
        dispatch(readShipmentsRequesting());

        const payloadAugmented = {
            properties: payload,
            size: 50
        }
        const result = await post(`${url}/search`, payloadAugmented, loadingIndicatorId);

        if (result.isSuccess) {
            // TODO: there need to be a better convention to know if the response is empty and successful or failed
            dispatch(readShipmentsSuccess(result.response.items));
        } else {
            dispatch(readShipmentsFailure(result.error));
        }
        dispatch(closeModals());
        return result;
    }
};

const getShipmentsByShipmentNo = (shipmentNo, spinnerElementId = null) => async dispatch => {
    const response = await get(`${url}?shipmentNo=${shipmentNo}`, spinnerElementId);
    dispatch(closeModals());
    if (response.isSuccess) {
        return dispatch(readShipmentsSuccess(response.response.result.shipments));
    } else {
        return dispatch(readShipmentsFailure(response.error));
    }
}

function readShipmentsRequesting() {
    return {
        type: actionTypes.READ_SHIPMENTS_REQUESTING
    }
}

function readShipmentsSuccess(payload) {
    return {
        type: actionTypes.READ_SHIPMENTS_SUCCESS,
        payload
    }
}

function readShipmentsFailure(payload) {
    return {
        type: actionTypes.READ_SHIPMENTS_FAILURE,
        payload
    }
}
/** end READ SHIPMENTS */


/** FILTER SHIPMENTS */
function setFilter(payload) {
    return {
        type: actionTypes.SET_FILTER,
        payload
    };
};

function setFilters(payload) {
    return {
        type: actionTypes.SET_FILTERS,
        payload
    };
};

function setFiltersAux(payload) {
    return {
        type: actionTypes.SET_FILTERS_AUX,
        payload
    };
}

function resetAllFilters() {
    return {
        type: actionTypes.RESET_ALL_FILTERS
    };
};
/** end FILTERS SHIPMENTS */


/** SORT SHIPMENTS */
function sortShipments(payload) {
    return {
        type: actionTypes.SORT_SHIPMENTS,
        payload
    }
};
/** end SORT SHIPMENTS */


export {
    readShipments,
    setFilter,
    setFilters,
    setFiltersAux,
    resetAllFilters,
    sortShipments,
    getShipmentsByShipmentNo,
};