import post, { get } from '../../core/httpClientSync';
import transportTypes from '../type/transport';
import { addQueryString } from '../../core/utility';

const path = "xms-shipment-api";
// const path = "http://localhost:5000";

export const clearState = () => async dispatch => {
    dispatch({
        type: transportTypes.STATE_CLEARED
    })
}

export const getOperation = (operationId, spinnerElementId = null) => async dispatch => {

    const url = `${path}/api/operations/${operationId}`;
    const response = await get(url, spinnerElementId);

    if (response.isSuccess) {
        dispatch({
            type: transportTypes.OPERATION_RECEIVED,
            payload: response.response.result
        })
    }
}

export const getList = (type, ids, code, status, spinnerElementId = null) => async dispatch => {

    let url = `${path}/api/groups?type=${type}`;

    if (ids && ids.length > 0)
        url = `${url}&${addQueryString('idList', ids)}`;

    if (code)
        url = `${url}&code=${code.toUpperCase()}&idList=${code}`;

    if (status === 0 || status === 1)
        url = `${url}&status=${status}`;

    const response = await get(url, spinnerElementId);

    if (response.isSuccess) {

        var dispatchType = transportTypes.LIST_RECEIVED;

        if (status === 0) {
            dispatchType = transportTypes.UNLINKED_LIST_RECEIVED
        } else if (status === 1) {
            dispatchType = transportTypes.LINKED_LIST_RECEIVED
        }

        dispatch({
            type: dispatchType,
            payload: response.response.result
        });
    }
}

export const linkOperation = (groupIdList, operationId, spinnerElementId = null) => async dispatch => {

    const url = `${path}/api/groups/link`;
    const request = { groupIdList, operationId };

    const response = await post(url, request, spinnerElementId)
    if (response.isSuccess) {
        dispatch({
            type: transportTypes.LINKED,
            payload: groupIdList
        });
    }
}

export const unlinkOperation = (groupIdList, operationId, spinnerElementId = null) => async dispatch => {

    const url = `${path}/api/groups/unlink`;
    const request = { groupIdList, operationId };

    const response = await post(url, request, spinnerElementId)
    if (response.isSuccess) {
        dispatch({
            type: transportTypes.UNLINKED,
            payload: groupIdList
        });
    }
}

export const saveItem = (transport, spinnerElementId = null) => async dispatch => {
    const url = `${path}/api/groups`;
    const request = { group: { ...transport } };

    const response = await post(url, request, spinnerElementId)
    if (response.isSuccess) {
        dispatch({
            type: transportTypes.SAVED,
            payload: response.response.group
        });
    }
}

export const editItem = (transport, spinnerElementId = null) => async dispatch => {

    const url = `${path}/api/groups/${transport.id}`;

    const response = await get(url, spinnerElementId)
    if (response.isSuccess) {
        dispatch({
            type: transportTypes.EDITED,
            payload: response.response.result.group
        });
    }
}

export const deleteItem = (transport, spinnerElementId = null) => async dispatch => {
    const url = `${path}/api/groups/delete/${transport.id}`;
    const response = await post(url, null, spinnerElementId)
    if (response.isSuccess) {
        dispatch({
            type: transportTypes.DELETED,
            payload: transport.id
        });
    }
}