import contractTypes from '../type/contract';

const initialState = {
  activeContract: null,
  passiveContracts: null,
  draftContract: null,
  contract: null,
  refresh: true
};
export default (state = initialState, action) => {
  switch (action.type) {
    case contractTypes.CONTRACT_GETCONTRACT_SUCCESS:
      return {
        ...state,
        activeContract: action.payload,
        refresh: false
      };
    case contractTypes.CONTRACT_RETRIEVED:
      return {
        ...state,
        contract: action.payload
      };
    case contractTypes.CONTRACT_GETDRAFTCONTRACT_SUCCESS:
      return {
        ...state,
        draftContract: action.payload,
        refresh: false
      };
    case contractTypes.CONTRACT_GETPASSIVECONTRACTS_SUCCESS:
      return {
        ...state,
        passiveContracts: action.payload,
        refresh: false
      };
    case contractTypes.CONTRACT_CLEARCONTRACTSTATE_SUCCESS:
      return { ...initialState };
    case contractTypes.CONTRACT_ENDED:
      return { ...initialState };
    case contractTypes.CONTRACT_SAVECONTRACT_SUCCESS:
      return { ...initialState };
    default:
      return state;
  }
};
