import { formButtonBehaviour, formInputTypes } from "../FormComponentTypes";

export const CreateOrUpdateReservedSlotRuleSchema = {
    Id: 'CreateOrUpdateReservedSlotRuleForm',
    SubmitButton: {
        Name: 'Save',
        Behaviour: formButtonBehaviour.BUTTON_NONE
    },
    ColumnCountSettings: {
        columnCount: 2,
    },
    FormDesign: [
        {
            groupName: 'Hidden',
            ordering: 1,
            isTagVisible: false
        },
        {
            groupName: 'BasicsTop',
            ordering: 2,
            isTagVisible: false
        },
        {
            groupName: 'BasicsMiddle',
            ordering: 3,
            isTagVisible: false
        },
        {
            groupName: 'BasicsBottom',
            ordering: 4,
            isTagVisible: false
        },
        {
            groupName: 'Details',
            ordering: 5,
            isTagVisible: true
        },
        {
            groupName: 'Details1',
            ordering: 6,
            isTagVisible: false
        },
        {
            groupName: 'Details2',
            ordering: 7,
            isTagVisible: false
        },
        {
            groupName: 'Details3',
            ordering: 8,
            isTagVisible: false
        },
    ],
    Rules: [
        'IF @clientId IS #EMPTY THEN @businessAreaId is #DISABLED',
        'IF @clientId IS #EMPTY THEN @serviceId is #DISABLED',
        'IF @operationType IS #EMPTY THEN @serviceId is #DISABLED',
    ],
    Fields: [
        {
            name: '',
            id: 'id',
            type: formInputTypes.HIDDEN_INPUT,
            ordering: 1,
            formDesign: 'Hidden'
        },
        {
            name: 'Client',
            id: 'clientId',
            type: formInputTypes.COMBO_INPUT,
            listOfItems: 'clients',
            isRequired: true,
            ordering: 10,
            formDesign: 'BasicsTop',
        },

        {
            name: 'Business Area',
            id: 'businessAreaId',
            type: formInputTypes.COMBO_INPUT,
            listOfItems: 'businessAreas',
            isRequired: true,
            ordering: 20,
            formDesign: 'BasicsTop',
            validations: [],
        },

        {
            name: 'Operation Type',
            id: 'operationType',
            type: formInputTypes.COMBO_INPUT,
            listOfItems: 'operationTypes',
            isRequired: true,
            ordering: 30,
            formDesign: 'BasicsTop',
            validations: []
        },

        {
            name: 'Service',
            id: 'serviceId',
            type: formInputTypes.COMBO_INPUT,
            listOfItems: 'services',
            isRequired: true,
            ordering: 40,
            formDesign: 'BasicsTop',
        },

        {
            name: 'Start Date',
            id: 'startDate',
            type: formInputTypes.DATE_INPUT,
            isTodayCheck: false,
            showTimeSelect: false,
            isRequired: true,
            ordering: 41,
            formDesign: 'BasicsTop',
            validations: []
        },
        
        {
            name: 'Start Time',
            id: 'startTime',
            type: formInputTypes.TIME_INPUT,
            isRequired: true,
            minuteStep: 15,
            ordering: 42,
            formDesign: 'BasicsTop',
            validations: []
        },

        {
            name: 'End Date',
            id: 'endDate',
            type: formInputTypes.DATE_INPUT,
            isRequired: true,
            isTodayCheck: false,
            showTimeSelect: false,
            ordering: 42,
            formDesign: 'BasicsTop',
            validations: []
        },
      
        {
            name: 'End Time',
            id: 'endTime',
            type: formInputTypes.TIME_INPUT,
            minuteStep: 15,
            ordering: 43,
            formDesign: 'BasicsTop',
            validations: []
        },

        {
            name: 'Day Of Week',
            id: 'dayOfWeek',
            type: formInputTypes.COMBO_INPUT,
            listOfItems: 'daysOfWeek',
            isRequired: true,
            ordering: 44,
            formDesign: 'BasicsTop',
            validations: []
        },
        
        {
            name: 'Unit Type',
            id: 'unitTypeId',
            type: formInputTypes.COMBO_INPUT,
            listOfItems: 'unitTypes',
            isRequired: true,
            ordering: 50,
            formDesign: 'BasicsTop',
            validations: []
        },
        {
            name: 'Unit No',
            id: 'unitNo',
            type: formInputTypes.STRING_INPUT,
            isRequired: true,
            ordering: 60,
            formDesign: 'BasicsTop',
            
        },
 
        {
            name: 'Name',
            id: 'name',
            type: formInputTypes.STRING_INPUT,
            isRequired: false,
            ordering: 10,
            formDesign: 'Details',
            validations: []
        },
        {
            name: 'Surname',
            id: 'surname',
            type: formInputTypes.STRING_INPUT,
            isRequired: false,
            ordering: 20,
            formDesign: 'Details',
            validations: []
        },

        
        {
            name: 'Carrier',
            id: 'carrier',
            type: formInputTypes.STRING_INPUT,
            authorizationWrapper: 'AdministratorHoc',
            isRequired: false,
            ordering: 30,
            formDesign: 'Details',
            validations: []
        },

       
        {
            name: 'Email',
            id: 'email',
            type: formInputTypes.STRING_INPUT,
            isRequired: false,
            ordering: 40,
            formDesign: 'Details',
            validations: []
        },
        {
            name: 'Phone Number',
            id: 'phoneNumber',
            type: formInputTypes.STRING_INPUT,
            isRequired: false,
            ordering: 50,
            formDesign: 'Details',
            validations: []
        }
    ]
};
