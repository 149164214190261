import { Form } from "react-bootstrap";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import React, { useState } from "react";
import * as PropTypes from "prop-types";
import * as Renderers from "./Render/renders";
import { CommandListRenderer } from "./Render/CommandRenderer";
import { formButtonBehaviour } from "../FormComponentTypes";
import { Button } from "@dfds-ui/react-components";

let SchemaInterpreter = (props) => {

    const [isVisible, setIsVisible] = useState(true);

    const handleBeforeSubmit = (model) => {
        renderButton();
        const groupingList = props.formSchema.Grouping ? props.formSchema.Grouping : undefined;

        let newModel = Object.assign({}, model);

        if (groupingList && groupingList.length > 0) {
            groupingList.forEach(group => {
                newModel[group] = {};

                const groupProperty = props.formSchema.Fields.filter(item => item.group === group);

                if (groupProperty && groupProperty.length > 0) {
                    groupProperty.map(item => {
                        newModel[group][item.id] = model[item.id];
                        if (newModel.hasOwnProperty(item.id))
                            delete newModel[item.id];
                    });
                }
            });
        }
        props.onSubmit(newModel);
    };

    const renderButton = () => {
        if (props.formSchema.SubmitButton.Behaviour === formButtonBehaviour.BUTTON_DISAPPEAR) {
            setIsVisible(false);
        }
    };

    const renderForm = (schema) => Renderers.MainRenderer(schema, props);

    return <Form onSubmit={props.handleSubmit(handleBeforeSubmit.bind(this))} autoComplete="off">
        {props.title && <><h4>{props.title}</h4><hr className='mt-0' /></>}
        {renderForm(props.formSchema)}
        {isVisible && <Button style={{ marginLeft: '10px' }} className="float-right">{props.formSchema.SubmitButton.Name}</Button>}
        {CommandListRenderer(props.formSchema, props)}
    </Form >
};
const mapStateToProps = (state, ownProps) => {
    return {
        form: ownProps.formSchema.Id,
        formValues: state.form[ownProps.formSchema.Id] ? state.form[ownProps.formSchema.Id].values : null,
        initialValues: ownProps.formItem
    }
};

SchemaInterpreter = reduxForm({ enableReinitialize: true })(SchemaInterpreter);
SchemaInterpreter = connect(mapStateToProps)(SchemaInterpreter);

SchemaInterpreter.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    formSchema: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    title: PropTypes.string,
};

export default SchemaInterpreter;