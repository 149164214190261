export const utcToEpoch = (date) => {
    const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
    return utcDate.getTime() / 1000;
}

export const utcToEpochWithTime = (dateTime) => {
    const utcDate = new Date(Date.UTC(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate(), dateTime.getHours(), dateTime.getMinutes(), dateTime.getSeconds(), dateTime.getMilliseconds()));
    return parseInt(utcDate.getTime() / 1000);
}

export const datetimeStringToUTCEpochMs = (datetimeString) => {
    const datetimeObject = new Date(datetimeString);
    return Date.UTC(datetimeObject.getUTCFullYear(), datetimeObject.getUTCMonth(), datetimeObject.getUTCDate(), datetimeObject.getUTCHours(), datetimeObject.getUTCMinutes(), datetimeObject.getUTCSeconds());
};

/*************** DATE & TIME ***************/
// Even though the local versions of those utilities just calls the global versions, initially we had thought of designing them as cross timezone convertors with timezone offset parameter defaulting to current timezone offset of the machine that the code runs on. However, it have been left unused for now since we do not need it currently (as you can see commented out in the function parameters definitions and not used in the function block) so that if the need arises any developer can take it from where it has been left y uncommenting it and using it inside the function definition by adding/substracting it from the related terms of the algebraic expressions.
export const epochSecondToDateTime = (epochSecond, /*timezoneSecond = -(new Date().getTimezoneOffset() * 60)*/) => {
    return new Date(epochSecond * 1000);
}

export const dateTimeToEpochSecond = (dateTime, /*timezoneSecond = -(new Date().getTimezoneOffset() * 60)*/) => {
    return Math.floor((dateTime.getTime() / 1000));
}

export const epochSecondToLocalDateTime = (epochSecond) => {
    return epochSecondToDateTime(epochSecond);
}

export const localDateTimeToEpochSecond = (dateTime) => {
    return dateTimeToEpochSecond(dateTime);
}
//*************** end DATE & TIME ***************/