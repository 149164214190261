/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getContract, clearContractState, insertOrUpdateContract, endContract } from '../store/action/contractActions';
import { getServiceList } from '../store/action/serviceActions';
import { getCompany, getCompanyList } from '../store/action/companyActions';
import history from '../core/history';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import Page from '../component/Page/Page';
import ContractForm from '../component/ContractForm/ContractForm';
import { getCurrencies } from '../store/action/currencyActions';
import { toastSuccess } from '../store/action';
import { toast } from 'react-toastify';
import { confirmationModal } from '../store/action/modalActions';
import { useParams } from 'react-router-dom';

function AddOrEditContract({ match, company, contract, clearContractState, insertOrUpdateContract, getCurrencies, services, getServiceList, getCompany, companyList, getCompanyList, currencies, getContract, endContract, confirmationModal }) {

    const {companycode} = useParams();
    
    useEffect(() => {
        return () => {
            clearContractState();
        }
    }, [])

    useEffect(() => {
        if (!companyList) {
            getCompanyList();
        }
    }, [companyList]);

    useEffect(() => {
        if (companyList && companycode) {
            getCompany(companycode);
        }
    }, [companyList, companycode]);

    useEffect(() => {
        if (match.params.contractId !== 'new' && !contract) {
            getContract(match.params.contractId);
        }
    }, [contract, match.params.contractId]);

    useEffect(() => {
        if (!services) {
            getServiceList();
        }
    }, [services]);

    useEffect(() => {
        if (!currencies) {
            getCurrencies();
        }
    }, [currencies]);


    const handleSubmit = (model) => {
        if (!model.currency) {
            toast.error('Currency is required.');
            return;
        }

        let contract = {
            contractReference: model.contractReference,
            clientContactPerson: model.clientContactPerson,
            currency: model.currency.trim(),
            services: model.services,
            isDraft: model.isDraft,
            isActive: !model.isDraft,
            companyName: company.name.trim(),
            companyId: company.id.trim(),
        }

        if (match.params.contractId !== 'new') {
            contract = {
                ...contract,
                id: match.params.contractId
            }
        }

        insertOrUpdateContract(contract);

        toastSuccess(match.params.contractId === 'new' ? 'Contract added.' : 'Contract updated.');

        history.push('/companies/' + match.params.companycode);
    }
    function endContractHandler(id) {
        confirmationModal('Warning', 'Are you sure you want to end contract ?', () => {
            endContract(id);
            history.push('/companies/' + match.params.companycode);
        });

    }
    return <Page backLink={'/companies/' + match.params.companycode} backToWhere='Company' >
        <h4>
            {company ? company.name : 'New Contract'}
        </h4><hr className='mt-0' />
        <ContractForm currencies={currencies}
            services={services}
            contract={contract}
            onSubmit={handleSubmit}
            onEndContract={endContractHandler}
        />
        <LoadingIndicator id="companyDetail" />
        <LoadingIndicator id="getServiceList" />
        <LoadingIndicator id="contractDetail" />
    </Page>
}

const mapStateToProps = (state, ownProps) => {
    return {
        company: state.company.company,
        contract: state.contract.contract,
        companyList: state.company.companyList,
        currencies: state.currency.currencies,
        services: state.service.serviceList,
    }
}
export default connect(mapStateToProps, { getContract, clearContractState, insertOrUpdateContract, getCurrencies, getServiceList, getCompany, getCompanyList, endContract, confirmationModal })(AddOrEditContract);
