import React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';

import { getUserFromLocalStorage } from '../store/action/authActions';

import Footer from './Footer/Footer';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import {
    faCopy,
    faCheck,
    faTimes,
    faPlus,
    faEdit,
    faMinusSquare,
    faCross,
    faTrash,
    faUserPlus,
    faKey,
    faUndoAlt,
    faCalendarMinus,
    faEnvelope,
    faCalendarTimes,
    faPallet,
    faAngleDoubleUp,
    faSave,
    faSquare,
    faCheckSquare,
    faUpload,
    faDownload,
    faCamera,
    faExclamationTriangle,
    faShareAlt,
    faFileExcel,
    faClipboard,
    faExpandArrowsAlt,
    faClock,
    faCheckCircle,
    faChevronRight,
    faChevronLeft,
    faPlusSquare,
    faPrint,
    faRedo,
    faCalendarCheck,
    faLock,
    faLockOpen
} from '@fortawesome/free-solid-svg-icons'

import Modal from './Modal/Modal';
import XMSRoutes from '../RoutedComponents/Routes';
import UserContext from '../context/UserContext';
import AppContext from '../context/AppContext';
import { GeneralPolicyList } from "../context/Policy";

library.add(fab, far, fas, faCopy, faCheck, faTimes, faPlus, faEdit, faMinusSquare, faCross, faTrash, faUserPlus, faKey, faCalendarCheck, faEnvelope, faCalendarTimes, faPallet, faAngleDoubleUp, faSave, faSquare, faCheckSquare,
    faUpload, faDownload, faCamera, faExclamationTriangle, faShareAlt, faFileExcel, faClipboard, faExpandArrowsAlt, faClock, faCheckCircle, faChevronRight, faChevronLeft, faPlusSquare, faPrint, faUndoAlt, faRedo, faCalendarMinus,
    faLock, faLockOpen);

const App = ({
    getUserFromLocalStorage,
    isUserFetched,
    authenticatedUser
}) => {

    useEffect(() => {
        if (!isUserFetched) {
            getUserFromLocalStorage();
        }
    }, [getUserFromLocalStorage, isUserFetched]);

    const renderContent = () => {
        if (!isUserFetched) {
            return;
        }

        return (
            <AppContext.Provider value={GeneralPolicyList.Policies.Allowance}>
                <UserContext.Provider value={authenticatedUser}>
                    <XMSRoutes />
                    <Footer />
                    <Modal />
                </UserContext.Provider>
            </AppContext.Provider>
        )
    }

    return (
        <>
            {renderContent()}
        </>
    )
};

const mapStateToProps = ({ auth }) => {
    return {
        authenticatedUser: auth.authenticatedUser,
        isUserFetched: auth.isUserFetched
    }
}

const mapDispatchToProps = {
    getUserFromLocalStorage
};

export default connect(mapStateToProps, mapDispatchToProps)(App);