import { Col, Form } from "react-bootstrap";
import { AuthorizationWrapper } from "./AuthorizationRenderer";
import * as PropTypes from "prop-types";
import React from "react";
import { TimePickerFormComponent } from "../../TimePickerFormComponent";

export const TimeRenderer = (field, formColumnCount) => {
    const timeControl = (<Form.Group key={field.name} as={Col} md={formColumnCount}>
        <Form.Label>{field.name}{(field.isRequired || field.dynamicRules.isRequired) && ' *'}</Form.Label>
        <TimePickerFormComponent
            name={field.id}
            isRequired={field.isRequired || field.dynamicRules.isRequired}
            minuteStep={field.minuteStep}
            validationArray={field.validations}
            isDisabled={field.isDisabled || field.dynamicRules.isDisabled}
            onChange={field.onChange}
        />
    </Form.Group>);

    if (field.authorizationWrapper) {
        return AuthorizationWrapper(timeControl, field.id, field.authorizationWrapper);
    }
    return timeControl;
};

TimeRenderer.propTypes = {
    item: PropTypes.object.isRequired
};