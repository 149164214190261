import postSync, { get as getSync } from '../../core/httpClientSync';
import types from '../type/messages';
import modalTypes from '../type/modal';
import { addQueryString } from '../../core/utility'

const shipmentApiUrl = 'xms-shipment-api/api';
// const shipmentApiUrl = 'http://localhost:5000/api';

const ediSenderApiUrl = 'xms-edi-sender-api/api';
const ediReceiverApiUrl = 'xms-edi-receiver-api/api';

export const clearState = () => async dispatch => {
    dispatch({
        type: types.STATE_CLEARED
    })
}

export const getOperations = (ids, spinnerElementId = null) => async dispatch => {

    let url = `${shipmentApiUrl}/operations?`;
    url += addQueryString('idList', ids);

    const response = await getSync(url, spinnerElementId);

    if (response.isSuccess && response.response) {
        dispatch({
            type: types.OPERATION_RECEIVED,
            payload: response.response.result
        })
    }
}

export const triggerMessage = (taskId, type, direction, spinnerElementId = null) => async dispatch => {

    dispatch({
        type: types.MESSAGE_TRIGGERING
    })

    const request = {
        messageType: type,
        messageKey: taskId,
        direction: direction,
        message: {
            operationId: taskId
        }
    }

    const response = await postSync(`${ediSenderApiUrl}/edi-sender/send-edi`, request, spinnerElementId);

    if (response.isSuccess) {
        dispatch({
            type: types.MESSAGE_TRIGGERED
        })
    }
}

export const triggerMessages = (taskIds, type, direction, spinnerElementId = null) => async dispatch => {

    dispatch({
        type: types.MESSAGES_TRIGGERING
    })

    for (let i = 0; i < taskIds.length; i++) {

        const request = {
            messageType: type,
            messageKey: taskIds[i],
            direction: direction,
            message: {
                operationId: taskIds[i]
            }
        }

        await postSync(`${ediSenderApiUrl}/edi-sender/send-edi`, request, spinnerElementId);
    }

    dispatch({
        type: types.MESSAGES_TRIGGERED
    })

}

export const getMessage = (operation, type, spinnerElementId = null) => async dispatch => {
    const request = {
        type: type,
        id: operation.id,
    }

    const response = await postSync(`${ediReceiverApiUrl}/edi-receiver/get-edi`, request, spinnerElementId);

    if (response.isSuccess) {
        dispatch({
            type: modalTypes.SHOW_MODAL,
            payload: {
                modalType: 'modal-message-detail',
                modalData: { operation, result: response.response }
            }
        })
    }
}
