import types from '../type/slotBookingConfiguration';

const initialState = {
  configuration: null,
  configurations: null,
  businessAreas: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case types.CONFIGURATIONS_RETRIEVED:
      return {
        ...state,
        configurations: action.payload,
        refresh: false,
      };
    case types.CONFIGURATION_RETRIEVED:
      return {
        ...state,
        configuration: action.payload,
      };

    case types.CONFIGURATION_SAVED:
      return {
        ...state,
        configuration: null,
        refresh: true,
      };
    case types.BUSINESS_AREAS_RECEIVED: {
      return {
        ...state,
        businessAreas: action.payload,
      };
    }
    default:
      return state;
  }
};
