const userActions = Object.freeze({
    UNIT_FOUND: 'astrid/unit/UNIT_FOUND',
    SEARCH_UNIT_RESET: 'astrid/unit/SEARCH_UNIT_RESET',
    UNIT_FETCHED: 'astrid/unit/UNIT_FETCHED',
    RESET_UNIT_DATA: 'astrid/unit/RESET_UNIT_DATA',
    SAVE_SHIPMENT_FILE_LIST: 'astrid/unit/SAVE_SHIPMENT_FILE_LIST',

    FILTER_OPERATIONS_REQUESTING: 'astrid/unit/FILTER_OPERATIONS_REQUESTING',
    FILTER_OPERATIONS_SUCCESS: 'astrid/unit/FILTER_OPERATIONS_SUCCESS',
    FILTER_OPERATIONS_FAILURE: 'astrid/unit/FILTER_OPERATIONS_FAILURE',

    UNIT_LEFT: 'astrid/unit/UNIT_LEFT',
});

export default userActions;