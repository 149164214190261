import React, { useEffect, useState } from 'react';
import history from "../core/history";
import { Col, Row } from "react-bootstrap";
import LoadingIndicator from "../component/LoadingIndicator/LoadingIndicator";
import moment from 'moment';
import { connect } from "react-redux";
import { saveTask, clearState, getUnitTypes, getTaskItem, updateTask, cancelTask, getBusinessAreasByClient, getClients, getServicesByClientAndType } from '../store/action/taskActions';
import SchemaInterpreter from "../component/FormComponents/Schema/SchemaInterpreter";
import { CreateTaskSchema } from "../component/FormComponents/Schema/createTask";
import { EditTaskSchema } from "../component/FormComponents/Schema/editTask";
import Page from '../component/Page/Page';

const CreateOrUpdateTask = (props) => {

    // Redux actions
    const { clearState, saveTask, updateTask, cancelTask, getUnitTypes, unitTypes, getServicesByClientAndType, getBusinessAreasByClient, getTaskItem, getClients } = props;

    // Redux store
    let { isTaskCreated, isTaskUpdated, isTaskCancelled, businessAreas, clients, services, taskItem } = props;

    // Others
    let { match, authenticatedUser } = props;

    const isUpdate = () => match.params.taskid !== 'new';
    const [task, setTask] = useState({});

    useEffect(() => {
        if (isTaskCreated || isTaskUpdated || isTaskCancelled) {
            history.push('/task');
        }

    }, [isTaskCreated, isTaskUpdated, isTaskCancelled]);

    useEffect(() => {
        if (!clients) {
            getClients();
        }
    }, [clients, getClients]);

    useEffect(() => {
        if (!unitTypes) {
            getUnitTypes();
        }

    }, [getUnitTypes, unitTypes]);

    useEffect(() => {
        if (task && task.clientId) {
            getBusinessAreasByClient(task.clientId);
        }
    }, [task.clientId, getBusinessAreasByClient]);

    useEffect(() => {
        if (businessAreas && businessAreas.length === 1 && !task.businessAreaId) {
            setTask(prevState => { return { ...prevState, businessAreaId: businessAreas[0].value } })
        }
    }, [businessAreas, task, setTask]);


    useEffect(() => {
        if (task && task.clientId && task.operationType) {
            getServicesByClientAndType(task.clientId, task.operationType);
        }
    }, [task, getServicesByClientAndType]);

    useEffect(() => {
        if (unitTypes && !taskItem) {
            console.log(taskItem);
            getTaskItem(match.params.taskid);
        }

    }, [getTaskItem, match.params.taskid, taskItem, unitTypes]);

    useEffect(() => {
        if (taskItem) {
            setTask(taskItem);
        }
    }, [taskItem]);

    useEffect(() => {
        return (() => {
            clearState();
        });
    }, [clearState]);

    const submit = (taskModel) => {
        taskModel.valueEpoch = moment.utc(taskModel.operationDate, "DD/MM/YYYY HH:mm").valueOf() / 1000;
        taskModel.channelType = "Web";
        taskModel.serviceName = services.find(x => x.value === taskModel.serviceId).text;
        taskModel.clientName = clients.find(x => x.value === taskModel.clientId).text;
        taskModel.Details.unitTypeName = unitTypes.find(x => x.value === taskModel.Details.unitType).text;


        if (taskModel.hasOwnProperty("operationDate")) {
            delete taskModel.operationDate;
        }

        if (isUpdate()) {
            updateTask(taskModel);
        } else {
            saveTask(taskModel);
        }
    };

    const getOperationTypeList = () => {
        return [
            { value: "Loading", text: "Loading" },
            { value: "Unloading", text: "Unloading" }
        ];
    };

    const cancelOperation = (model) => {
        console.log('Cancel Operation', model);
        cancelTask(model.operationId, authenticatedUser.id);
    };

    const onFieldChangeHandler = (event, newValue, previousValue, fieldId) => {
        if (fieldId === 'clientId') {
            setTask(prevState => {
                return {
                    ...prevState,
                    clientId: newValue,
                    businessAreaId: null,
                    operationType: null
                }
            });
        }

        if (fieldId === 'businessAreaId') {
            setTask(prevState => {
                return {
                    ...prevState,
                    businessAreaId: newValue,
                }
            });
        }

        if (fieldId === 'operationType') {
            setTask(prevState => {
                return {
                    ...prevState,
                    serviceId: null,
                    operationType: newValue,
                }
            });
        }
        console.log(`Field changed \n fieldId: ${fieldId},\n previousValue: ${previousValue},\n newValue: ${newValue},\n event: `, event);
    }

    const getActualForm = () => {
        if (isUpdate()) {
            return (
                <SchemaInterpreter
                    title='Update Task'
                    onFieldChange={onFieldChangeHandler}
                    onSubmit={submit}
                    formItem={task}
                    cancelTask={cancelOperation}
                    formSchema={EditTaskSchema}
                    operationTypes={getOperationTypeList()}
                    clients={clients}
                    services={services}
                    businessAreas={businessAreas}
                    unitTypes={unitTypes}
                />
            );
        }

        return clients && <SchemaInterpreter
            title='Create Task'
            onFieldChange={onFieldChangeHandler}
            onSubmit={submit}
            formItem={task}
            formSchema={CreateTaskSchema}
            operationTypes={getOperationTypeList()}
            clients={clients}
            services={services}
            businessAreas={businessAreas}
            unitTypes={unitTypes}
        />

    };

    return <Page backLink='/task' backToWhere='Tasks'>
        <Row className="justify-content-md-center justify-content-sm-center">
            <Col lg={12} sm={8} xs={12}>
                {getActualForm()}
                <LoadingIndicator id="createOrUpdateTask" show={!clients} />
            </Col>
        </Row>
    </Page>

};
const mapStateToProps = state => {
    return {
        isTaskCreated: state.task.isTaskCreated,
        isTaskUpdated: state.task.isTaskUpdated,
        isTaskCancelled: state.task.isTaskCancelled,
        unitTypes: state.task.unitTypes,
        businessAreas: state.task.clientBusinessAreas,
        taskItem: state.task.taskItemModel,
        clients: state.task.clients,
        authenticatedUser: state.auth.authenticatedUser,
        services: state.task.services
    }
};

const mapDispatchToProps = {
    saveTask,
    updateTask,
    cancelTask,
    clearState,
    getUnitTypes,
    getBusinessAreasByClient,
    getTaskItem,
    getClients,
    getServicesByClientAndType
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrUpdateTask);
