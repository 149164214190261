import types from '../type/operation';

const initialState = {
    operation: null,
    shipmentIdList: [],
    shipmentList: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.STATE_CLEARED:
            return {
                ...initialState
            }
        case types.OPERATION_RECEIVED:
            return {
                ...state,
                operation: action.payload.operation,
                shipmentIdList: action.payload.operation && action.payload.operation.shipmentIdList ? action.payload.operation.shipmentIdList : []
            }
        case types.SEALNUMBER_UPDATED:
            return {
                ...state,
                operation: { ...state.operation, details: { ...state.operation.details, sealNumber: action.payload } }
            }
        case types.UNIT_LEFT:
            return {
                ...state,
                operation: { ...state.operation, details: { ...state.operation.details, unitLeftDateTime: action.payload } }
            }
        case types.SHIPMENTLIST_RECEIVED: {
            return {
                ...state,
                shipmentList: action.payload.shipments
            }
        }
        default:
            return state;
    }
}