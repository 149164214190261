import React from 'react';
import history from '../../../core/history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@dfds-ui/react-components';
import { STATUS, TYPES } from '../../../constants/operation/operation';
import PolicyGroupAccessHoc from "../../../RoutedComponents/Routes/PolicyGroupAccessHoc";

const NewShipmentsToOperation = (operation) => {

    if (!operation || operation.type === TYPES.UNLOADING) {
        return null;
    }

    return (
        <PolicyGroupAccessHoc componentName="NewShipmentsToOperationHOC">
            <Button
                onClick={() => newShipmentsClickedEvent(operation.id)}
                disabled={operation.status === STATUS.COMPLATED || operation.status === STATUS.CANCELLED}
                title="Add New Shipments"
                size="small"
                variation="primary"
            >
                <FontAwesomeIcon icon={['fas', 'plus']} className="mr-2" />
                Add Shipments
            </Button>
        </PolicyGroupAccessHoc>
    );
}

const newShipmentsClickedEvent = (id) => {
    var path = `/shipmentoutboundoperation/1/${id}`;
    // if (transport) path += `/${groupId}`;
    history.push(path);
}


export default NewShipmentsToOperation;