import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import history from '../core/history';
import { Button, Spinner } from '@dfds-ui/react-components';
import { Delete, Swap } from '@dfds-ui/icons';
import {
    clearShipmentDetailData,
    getShipment,
    getRelatedShipments,
    changeMissingDocsState,
    removeShipmentFromOperation,
    changeMissingDocsHoldState,
    getOperations,
    deleteShipment
} from '../store/action/shipmentDetailAction';
import PolicyGroupAccessHoc from "./Routes/PolicyGroupAccessHoc";
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import PageHeader from '../component/PageHeader/PageHeader';
import FileController from '../component/FileController/FileController';
import ShipmentSummary from '../component/ShipmentDetail/ShipmentSummary';
import ShipmentUnitsRows from '../component/ShipmentDetail/ShipmentUnitsRows';
import ShipmentHazardous from '../component/ShipmentDetail/ShipmentHazardous';
import ThuList from '../component/Thu/ThuList';
import ImageController from '../component/ImageController';
import { clearThuData } from '../store/action/thuDetailAction';
import { confirmationModal } from '../store/action/modalActions'
import { fetchTypes } from '../store/ducks/definitions/actionCreators';
import { TYPES } from '../constants/shipment/shipment';
import { useIfNotNavigatedTo } from "../core/hooks";
import { resetAllFilters } from '../store/ducks/shipments/actionCreators';

const ShipmentDetail = (props) => {

    //parent
    const {
        shipmentId
    } = props.match.params;

    //redux params
    const {
        shipment,
        relatedShipmentList,
        operations,
        isRequesting,
        hazardousItemList,
        docsMissingState,
        isEditingGranted,
        thuList,
        docsMissingHoldState,
        fetchTypes,
        resetAllFilters,
    } = props;

    //redux actions
    const {
        clearShipmentDetailData,
        getShipment,
        getRelatedShipments,
        changeMissingDocsState,
        clearThuData,
        confirmationModal,
        removeShipmentFromOperation,
        changeMissingDocsHoldState,
        getOperations,
        deleteShipment
    } = props;

    // HISTORY, LOCATION, SEARCH PARAMS, URL PARAMS
    useIfNotNavigatedTo(['/shipments'], resetAllFilters);
    // end HISTORY, LOCATION, SEARCH PARAMS, URL PARAMS

    const CONSTANTS = {
        SUMMARYLOADER: 'loader-shipment-summary',
        FILELOADER: 'loader-shipment-file'
    }

    const [shipmentIdList, setShipmentIdList] = useState([]);

    useEffect(() => {
        clearThuData();
        clearShipmentDetailData();
        fetchTypes();
        getShipment(shipmentId, CONSTANTS.SUMMARYLOADER);
        getRelatedShipments(shipmentId);
    }, [shipmentId]);

    useEffect(() => {
        if (relatedShipmentList) {
            var ids = relatedShipmentList.map(s => s.id);
            ids.push(shipmentId);
            setShipmentIdList(ids);
            getOperations(ids)
        }
    }, [relatedShipmentList]);

    const onToggleDocsMissingState = () => {
        changeMissingDocsState(shipmentId, docsMissingState ? false : true, CONSTANTS.FILELOADER);
    }

    const onToggleDocsMissingHoldState = () => {
        changeMissingDocsHoldState(shipmentId, docsMissingHoldState, CONSTANTS.FILELOADER);
    }

    const renderOperations = () => {

        const onRemoveFromOperationClick = (shipmentId, operationId, loader) => {
            confirmationModal('Shipment Detail', 'Shipment will be removed from loading operation. Do you approve?', () => removeShipmentFromOperation(shipmentId, operationId, shipment.type === TYPES.DELIVERY, loader));
        }

        var options = {
            operations: operations,
            shipmentId: shipmentId,
            shipmentIdList: shipmentIdList,
            name: `Operations (${operations.length})`,
            id: "collapseUnit",
            onRemoveFromOperationClick: onRemoveFromOperationClick
        };

        return (
            <ShipmentUnitsRows options={options} />
        )
    }

    const renderHazardous = () => {
        return (
            <ShipmentHazardous shipmentId={shipmentId} shipmentStatus={shipment && shipment.status} shipmentDeleted={shipment && shipment.logicalDeleteKey} hazardousItemList={hazardousItemList} />
        )
    }

    const renderImageController = () => {

        const options = {
            relativePath: [`image/shipment/${shipmentId}`],
            relativePathForFetch: `image/shipment/${shipmentId}`,
            redirectUrl: `/shipment/${shipmentId}`,
            externalId: shipmentId,
            externalType: 'Shipment',
            showButtons: (shipment && !shipment.logicalDeleteKey),
        }

        return (
            <ImageController imageControllerOptions={options} />
        )
    }

    // TODO: remove "Intransit" after BE shipmentDetail api is fixed
    const isShipmentUpdatable = (shipment, updatableShipmentStatusTypes = [0, "Intransit"]) => {
        // 'Intransit' type has value 0 (zero)
        return shipment && updatableShipmentStatusTypes.includes(shipment.status);
    }

    // TODO: CONSIDER: finding a better way to pass actions, maybe an array of objects for declarativeness [{displayName: 'Mark As Deleted', component: <div></div>, ...}, ...]
    const renderPageHeader = () => {
        return (
            <PageHeader
                text={shipment && `${shipment.typeName} - ${shipment.shipmentNo}`}
                actions={
                    [
                        renderDeleteButton(),
                        renderArrivalButton()
                    ]
                }
            />
        );
    }

    const renderDeleteButton = () => {
        if (!shipment || shipment.type === TYPES.DELIVERY) return;

        const isDisabled = shipment && (shipment.logicalDeleteKey || !isShipmentUpdatable(shipment) || !isEditingGranted);

        return (
            <PolicyGroupAccessHoc componentName="MarkAsDeletedHOC" key={0}>
                <Button
                    onClick={() => deleteShipment({ ...shipment, deleted: !shipment.logicalDeleteKey }, 'searchShipment')}
                    disabled={isDisabled || isRequesting}
                    size="small"
                    style={{ backgroundColor: isDisabled ? "rgba(77, 78, 76, 0.3)" : "#be1e2d" }}
                    icon={isRequesting ? <Spinner /> : <Delete />}
                    iconAlign="left"
                    title={
                        shipment &&
                            shipment.deleted ? 'Shipment is already marked as deleted'
                            :
                            !isShipmentUpdatable(shipment) ? 'Intransit shipments cannot be marked as deleted' : 'Mark as deleted'
                    }
                >
                    {shipment && shipment.logicalDeleteKey ? 'Deleted' : 'Mark As Deleted'}
                </Button>
            </PolicyGroupAccessHoc>
        )
    }

    const renderArrivalButton = () => {

        if (!shipment || shipment.type === TYPES.ARRIVAL || !shipment.relatedShipmentId) return;

        return (
            <Button key={1}
                onClick={() => history.push(`/shipment/${shipment.relatedShipmentId}`)}
                size="small"
                icon={<Swap />}
                iconAlign="left"
            >
                Go to Arrival
            </Button>
        )
    }

    const renderFileController = () => {

        const relativePath = `Shipment/${shipmentId}`

        const uploadOptions = {
            uploadFileRelativePath: relativePath,
            uploadFileRelativePathAsArray: [relativePath],
            uploadData: shipment,
            externalId: shipmentId,
            externalType: 'Shipment',
            loader: CONSTANTS.FILELOADER
        };

        const downloadOptions = {
            downloadAllFilePathList: [relativePath],
            downloadFileRelativePath: [relativePath]
        };

        const deleteOptions = { deleteFileRelativePath: [relativePath] };

        const missingDocumentOptions = {
            docsMissingStateKey: shipmentId,
            hasDocsMissingFlagSet: docsMissingState,
            docsMissingBySystem: shipment && shipment.docsMissingBySystem,
            onToggleDocsMissingState
        }

        const shareOptions = {
            actionKey: shipmentId,
            actionType: 'download-shipment-documents'
        }

        const docsMissingHoldOptions = {
            shipmentId: shipmentId,
            onToggleDocsMissingHoldState,
            autoHoldForDocsMissing: docsMissingHoldState
        }

        const buttons = { addFile: shipment && !shipment.logicalDeleteKey, download: true, upload: shipment && !shipment.logicalDeleteKey, missingDocuments: shipment && !shipment.logicalDeleteKey, shareDocuments: shipment && !shipment.logicalDeleteKey, missingDocumentsHold: shipment && !shipment.logicalDeleteKey };

        return (
            <FileController
                operationButtonsToShow={buttons}
                uploadOptions={uploadOptions}
                missingDocumentOptions={missingDocumentOptions}
                deleteOptions={deleteOptions}
                downloadOptions={downloadOptions}
                onToggleDocsMissingState={onToggleDocsMissingState}
                shareOptions={shareOptions}
                docsMissingHoldOptions={docsMissingHoldOptions}
            />
        );
    }

    const renderArticleList = () => {

        return (
            <ThuList
                thuList={thuList}
                shipmentId={shipmentId}
                shipmentNo={shipment && shipment.shipmentNo}
                shipmentStatus={shipment && shipment.status}
                headerText='THU Details'
                accordionId='collapseThuDetail'
                showAddButton={shipment && shipment.type === TYPES.ARRIVAL && !shipment.logicalDeleteKey}
                showPrintButton={shipment && shipment.type === TYPES.ARRIVAL && !shipment.logicalDeleteKey}
                showHoldButton={shipment && shipment.type === TYPES.ARRIVAL && !shipment.logicalDeleteKey}
                showRemeasurement={shipment && shipment.rules && shipment.rules.Measurement} />
        )
    }

    return (
        <div>
            {renderPageHeader()}
            <div id="accordion">
                <ShipmentSummary shipment={shipment || {}} />
                {renderFileController()}
                {renderImageController()}
                {renderOperations()}
                {renderHazardous()}
                {renderArticleList()}
            </div>
            <LoadingIndicator id={CONSTANTS.SUMMARYLOADER} />
        </div>
    )
}

const mapStateToProps = ({ shipmentDetail, thuDetailReducer }) => {

    return {
        shipment: shipmentDetail.shipment,
        relatedShipmentList: shipmentDetail.relatedShipmentList,
        operations: shipmentDetail.operations,
        isRequesting: shipmentDetail.isRequesting,
        hazardousItemList: shipmentDetail.hazardousItemList,
        docsMissingState: shipmentDetail.docsMissingState,
        isEditingGranted: shipmentDetail.isEditingGranted,
        thuList: thuDetailReducer.thuList,
        docsMissingHoldState: shipmentDetail.docsMissingHoldState
    }
}

const mapDispatchToProps = {
    clearShipmentDetailData,
    getShipment,
    getRelatedShipments,
    changeMissingDocsState,
    clearThuData,
    confirmationModal,
    removeShipmentFromOperation,
    changeMissingDocsHoldState,
    fetchTypes,
    getOperations,
    deleteShipment,
    resetAllFilters,
}

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentDetail);