/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { getCompanyList } from '../store/action/companyActions';
import history from '../core/history';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import XmsTable from '../component/XmsTable/XmsTable';
import Page from '../component/Page/Page';

const CompanyList = ({ pageIndex, companyList, getCompanyList }) => {
    useEffect(() => {
        if (!companyList) {
            getCompanyData(null);
        }
    }, [companyList])
    const getCompanyData = () => {
        getCompanyList(pageIndex);
    }
    const companyClickedHandler = company => {
        history.push('/company/' + company.code);
    }
    const newCompanyClickedHandler = () => {
        history.push('/company/new');
    }

    const tableConfigurations = {
        title: 'Companies',
        noRowText: 'There are not any companies to show.',
        hasFilter: false,
        entities: companyList,
        columns: [
            { alias: 'Code', name: 'code', type: 'string' },
            { alias: 'Name', name: 'name', type: 'string' },
        ],
        tableActions: [
            { clickEvent: newCompanyClickedHandler, buttonText: 'Create company', icon: 'plus' }
        ],
        rowActions: [
            { title: 'Edit company', clickEvent: companyClickedHandler, icon: 'edit' },
        ],
    }

    return <Page>
        <XmsTable config={tableConfigurations} />
        <LoadingIndicator id="getCompanyList" />
    </Page>
}

const mapStateToProps = (state) => {
    return {
        companyList: state.company.companyList,
    }
}
export default connect(mapStateToProps, { getCompanyList })(CompanyList);  
