const actionTypes = Object.freeze({
    THU_STATE_CLEARED: 'astrid/thuDetail/THU_STATE_CLEARED',
    THU_LISTED: 'astrid/thuDetail/THU_LISTED',
    THU_PRINTED: 'astrid/thuDetail/THU_PRINTED',
    THU_CREATED: 'astrid/thuDetail/THU_CREATED',
    THU_CONVERTED: 'astrid/thuDetail/THU_CONVERTED',
    THU_DELETED: 'astrid/thuDetail/THU_DELETED',
    THU_CONFIRMED: 'astrid/thuDetail/THU_CONFIRMED',
    PACKAGETYPE_LISTED: 'astrid/thuDetail/PACKAGETYPE_LISTED',
    THU_STATE_REVERSED: 'astrid/thuDetail/THU_STATE_REVERSED',
    THU_FOUND: 'astrid/thuDetail/THU_FOUND',
    THU_UPDATED: 'astrid/thuDetail/THU_UPDATED',
    THU_NOT_EDITED: 'astrid/thuDetail/THU_NOT_EDITED',
    UPDATE_THUS_REQUESTING: 'astrid/thuDetail/UPDATE_THUS_REQUESTING',
    UPDATE_THUS_SUCCESS: 'astrid/thuDetail/UPDATE_THUS_SUCCESS',
    UPDATE_THUS_FAILURE: 'astrid/thuDetail/UPDATE_THUS_FAILURE',
    SHIPMENT_RECEIVED: 'astrid/thuDetail/SHIPMENT_RECEIVED'
});

export default actionTypes;