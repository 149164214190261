const taskActions = Object.freeze({
    TASKS_RECEIVED: Symbol('TASKS_RECEIVED'),
    TASKS_CLEARED: Symbol('TASKS_REFRESHED'),
    USERS_RECEIVED: Symbol('USERS_RECEIVED'),
    TASK_ACCEPTED: Symbol('TASK_ACCEPTED'),
    TASK_COMPLETED: Symbol('TASK_COMPLETED'),
    TASK_REJECTED: Symbol('TASK_REJECTED'),
    TASK_ASSIGNED: Symbol('TASK_ASSIGNED'),
    TASK_UPDATED: Symbol('TASK_UPDATED'),
    TASK_RECEIVED_DETAILS: Symbol('TASK_RECEIVED_DETAILS'),
    TASK_CLAIMED: Symbol('TASK_CLAIMED'),
    TASK_CREATED: Symbol('TASK_CREATED'),
    TASK_CANCELLED: Symbol('TASK_CANCELLED'),
    TASKS_FILTERED: Symbol('TASKS_FILTERED'),
    TASK_ITEM_MODEL: Symbol('TASK_ITEM_MODEL'),
    UNIT_TYPE_RECEIVED: Symbol('UNIT_TYPE_RECEIVED'),
    BUSINESS_AREAS_RECEIVED: Symbol('BUSINESS_AREAS_RECEIVED'),
    DETAIL_PUT: Symbol('DETAIL_PUT'),
    CLEAR_STATE: Symbol('CLEAR_STATE'),
    BUSINESS_AREAS_RETRIEVED: Symbol('BUSINESS_AREAS_RETRIEVED'),
    CLIENT_BUSINESS_AREAS_RECEIVED: Symbol('CLIENT_BUSINESS_AREAS_RECEIVED'),
    CLIENTS_RETRIEVED: Symbol('CLIENTS_RETRIEVED'),
    SERVICES_RETRIEVED: Symbol('SERVICES_RETRIEVED')
});

export default taskActions;