import type from '../type/shipmentInboundOperationTypes';

const initialState = {
    operation: {},
    shipmentIdList: [],
    availableShipmentList: [],
    linkedShipmentList: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case type.STATE_CLEARED:
            return {
                ...initialState
            }
        case type.OPERATION_RECEIVED:
            return {
                ...state,
                operation: action.payload,
                shipmentIdList: action.payload.shipmentIdList
            }
        case type.LINKED_SHIPMENT_RECEIVED:
            return {
                ...state,
                linkedShipmentList: action.payload,
                availableShipmentList: []
            }
        case type.AVAILABLE_SHIPMENTS_LISTED:
            return {
                ...state,
                availableShipmentList: action.payload
            }
        case type.SHIPMENTS_LINKED:

            var toList = [...state.availableShipmentList];
            var fromList = [...state.linkedShipmentList];

            for (var i = 0; i < action.payload.length; i++) {
                var id = action.payload[i];

                var shipment = toList.find(s => s.id === id);

                toList = toList.filter(s => s.id !== id);
                fromList.push(shipment);
            }

            return {
                ...state,
                availableShipmentList: toList,
                linkedShipmentList: fromList
            }
        case type.SHIPMENTS_UNLINKED:

            var toList = [...state.availableShipmentList];
            var fromList = [...state.linkedShipmentList];

            for (var i = 0; i < action.payload.length; i++) {
                var id = action.payload[i];

                var shipment = fromList.find(s => s.id === id);

                fromList = fromList.filter(s => s.id !== id);
                toList.push(shipment);
            }

            return {
                ...state,
                availableShipmentList: toList,
                linkedShipmentList: fromList
            }
        default:
            return state
    }
}