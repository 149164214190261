/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import history from '../../core/history';
import { Row, Col, Card } from 'react-bootstrap';
import { ButtonStack, Button } from '@dfds-ui/react-components';
import { RightLink } from '@dfds-ui/icons'
import NewShipmentsToOperation from '../Shipments/buttons/NewShipmentsToOperation';
import PickShipmentsToOperation from '../Shipments/buttons/PickShipmentsToOperation';
import ShipmentLinkButton from '../ShipmentInbound/ShipmentLinkButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BackgroundText } from '../BackgroundText/BackgroundText';
import TransportLinkButton from '../Transport/TransportLinkButton';
import styles from './OperationShipmentList.module.css';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { TYPES } from '../../constants/shipment/shipment';
import { getValue } from '../../core/utility'

const OperationShipmentList = ({ operation, shipmentList, loader }) => {

    const groupIdList = () => {
        var groupIds = [];

        for (var i = 0; i < shipmentList.length; i++) {
            var groupId = shipmentList[i].groupId;
            if (groupIds.indexOf(groupId) < 0) {
                groupIds.push(groupId);
            }
        }

        return groupIds;
    }

    const groupedShipmentList = () => {

        var groupIds = groupIdList();

        var groups = [];

        for (var i = 0; i < groupIds.length; i++) {
            var groupId = groupIds[i];
            var group = {
                id: groupId,
                shipments: []
            };

            for (var j = 0; j < shipmentList.length; j++) {
                var shipment = shipmentList[j];

                if (shipment.groupId === groupId) {
                    group.carrierName = shipment.type === TYPES.ARRIVAL ? getValue(shipment, 'firstConsignor.name') : getValue(shipment, 'endCustomer.name');
                    group.shipments.push(shipment)
                }
            }
            groups.push(group);
        }

        return groups;
    }

    const mouseDownHandler = (event, shipment) => {
        if (event.button === 1) {
            window.open(`/shipment/${shipment.id}`,'_blank');
        }
    }

    const redirectToShipment = (shipment) => {
        const path = `/shipment/${shipment.id}`;
        history.push(path);
    }

    const renderActions = () => {
        return (
            <Row className="mb-3">
                <Col className={styles.controlButtons}>
                    {ShipmentLinkButton(operation)}
                    {NewShipmentsToOperation(operation)}
                    {PickShipmentsToOperation(operation)}
                    {TransportLinkButton(operation)}
                </Col>
            </Row>
        )
    }

    const renderShipmentList = (shipmentList) => {

        if (!shipmentList) {
            return;
        }

        return shipmentList.map((shipment, i) => {
            return (
                <Card key={i}>
                    <Card.Body>
                        <Row className={`align-items-center`}>
                            {renderBackGroundText(shipment)}
                            <Col xs={12} md={8} lg={8} >
                                <Row>
                                    <Col md={8}>
                                        {`${shipment.shipmentNo}`}
                                    </Col>
                                    <Col md={4}>
                                        {getValue(shipment, "unit.group.code")}
                                    </Col>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={8} className={'text-truncate'}>
                                                {`Supplier: ${getValue(shipment, 'supplier.name')}`}
                                            </Col>
                                            <Col md={4}>
                                                {`Suffix: ${getValue(shipment, 'destination.name')}`}
                                            </Col>
                                            <Col md={4}>
                                                {`Thu(s): ${renderActualAndExpected(shipment.type, getValue(shipment, 'actualMeasurement.itemQuantity.value'), getValue(shipment, 'expectedMeasurement.itemQuantity.value'))}`}
                                            </Col>
                                            <Col md={4}>
                                                {`GW: ${renderActualAndExpected(shipment.type, getValue(shipment, 'actualMeasurement.grossWeight.value'), getValue(shipment, 'expectedMeasurement.grossWeight.value'), "KG")}`}
                                            </Col>
                                            <Col md={4}>
                                                {`V: ${renderActualAndExpected(shipment.type, getValue(shipment, 'actualMeasurement.volume.value'), getValue(shipment, 'expectedMeasurement.volume.value'), "M3")}`}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={2} lg={2} >
                                <Row>
                                    <Col>
                                        {shipment.type === TYPES.ARRIVAL && shipment.actionNeeded ? <span ><FontAwesomeIcon icon="exclamation-triangle" /> Action Need</span> : null}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {shipment.type === TYPES.ARRIVAL && (shipment.docsMissingBySystem || shipment.docsMissingByUser) ? <span ><FontAwesomeIcon icon="exclamation-triangle" /> Doc. Missing</span> : null}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {shipment.type === TYPES.ARRIVAL && shipment.customsControlled ? <span ><FontAwesomeIcon icon="user-tie" /> Customs Contr.</span> : null}
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={2} lg={2} >
                                <ButtonStack align="right">
                                    <Button variation="text" icon={<RightLink />} iconAlign="right" size="small" onMouseDown={(e) => mouseDownHandler(e, shipment)} onClick={() => redirectToShipment(shipment)}>Go Shipment</Button>
                                </ButtonStack>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            );
        });
    }

    const renderActualAndExpected = (shipmentType, actual, expected, unit = '') => {
        if (shipmentType === TYPES.ARRIVAL) {
            return `${expected} / ${actual} ${unit}`;
        }
        return `${expected} ${unit}`;
    }

    const renderGroupedShipmentList = () => {

        var groupedShipments = groupedShipmentList();

        return (
            <Card>
                <Card.Body>
                    <Row>
                        <Col xs={2}>
                            <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                {
                                    groupedShipments.map((group, i) => {

                                        return (

                                            <a key={i} className={`nav-link ${i === 0 ? 'active' : ''}`} data-toggle="pill" href={`#operation-shipments-${group.id}-content`} role="tab">{group.id ? group.id : 'Other(s)'}</a>

                                        )
                                    })
                                }
                            </div>
                        </Col>
                        <Col xs={10}>
                            <div className="tab-content" id="v-pills-tabContent">
                                {
                                    groupedShipments.map((group, i) => {
                                        return (
                                            <div key={i} className={`tab-pane fade show ${i === 0 ? 'active' : ''}`} id={`operation-shipments-${group.id}-content`} role="tabpanel">
                                                {renderShipmentList(group.shipments)}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        );
    }

    const renderList = () => {

        if (!shipmentList || shipmentList.length === 0) {
            return (
                <Card>
                    <Card.Body>
                        <p>No Record(s) found</p>
                    </Card.Body>
                </Card>
            )
        }

        return (
            <Row className="mb-3">
                <Col>
                    <ul className="nav nav-tabs" id="tabs-operation-shipments" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="operation-shipments-all" data-toggle="tab" href="#operation-shipments-all-content" role="tab">All</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="operation-shipments-grouped" data-toggle="tab" href="#operation-shipments-grouped-content" role="tab">Grouped</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="tabs-operation-shipments-content">
                        <div className="tab-pane fade show active" id="operation-shipments-all-content" role="tabpanel">{renderShipmentList(shipmentList)}</div>
                        <div className="tab-pane fade" id="operation-shipments-grouped-content" role="tabpanel">{renderGroupedShipmentList()}</div>
                    </div>
                </Col>
            </Row>
        )
    }

    const renderBackGroundText = (shipment) => {
        if (!shipment) {
            return;
        }
        if (!shipment.unLoadHere) {
            return (<><BackgroundText text={'DO NOT UNLOAD'} show={true} type={'danger'} /></>)
        }
        else if (shipment.isHeld) {
            return (<><BackgroundText text={'HOLD'} show={true} type={'danger'} /></>)
        }
    }

    return (
        <div className='card'>
            <div className='card-header' href={`#operation-shipments`} data-toggle='collapse' data-parent='#accordion'>
                <h5 className='mb-0'>
                    <div>
                        <img src='/image/icon/down.svg' className='color-action-blue' width='20px' alt='' /> Shipments
                    </div>
                </h5>
            </div>
            <div id='operation-shipments' className='collapse show'>
                <div className='card card-body'>
                    {renderActions()}

                    {renderList()}
                    <LoadingIndicator id={loader} />
                </div>
            </div>
        </div>
    )
}

export default OperationShipmentList;