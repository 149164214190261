/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { FormLabel } from 'react-bootstrap';
import './OpenAdditionalGate.css';
import PageHeader from '../component/PageHeader/PageHeader';
import {
  openAdditionalGates,
  getTimeSlots,
  clearState,
  getBusinessAreas
} from '../store/action/openAdditionalGateActions';
import TimeSlotTable from '../component/TimeSlotTable/TimeSlotTable';
import { Row, Col } from 'react-bootstrap';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import { Button, ButtonStack, Label, NumberField, SelectField } from '@dfds-ui/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import history from '../core/history';
import Page from '../component/Page/Page';
import XmsTable from '../component/XmsTable/XmsTable';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import { utcToEpoch } from '../core/utcToEpochConverter';
const OpenAdditionalGate = (props) => {
  //Redux actions
  const { getTimeSlots, openAdditionalGates, clearState, getBusinessAreas } = props;

  //Redux store
  let { timeSlots, businessAreas, result } = props;
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const defaultDateValue = tomorrow;
  const defaultSlotSizeList = [15, 30, 60];
  const [additionalGateNumber, setAdditionalGateNumber] = useState(1);
  const [selectedBusinessArea, setSelectedBusinessArea] = useState(null);
  const [additionalGateNumberError, setAdditionalGateNumberError] = useState('');
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [isAllTimeSlotsSlected, setIsAllTimeSlotsSelected] = useState(false);
  const [selectedDate, setSelectedDate] = useState(defaultDateValue);
  const [slotSizeList, setSlotSizeList] = useState(defaultSlotSizeList);
  const [state, setState] = useState({
    startMinutesSinceMidnight: null,
    endMinutesSinceMidnight: null
  });

  useEffect(() => {
    return () => {
      clearState();
    };
  }, []);

  useEffect(() => {
    if (!businessAreas) {
      getBusinessAreas();
    }
  }, [businessAreas]);

  useEffect(() => {
    if (result) {
      toast.success('Additional gates were added.');
      clearState();
      history.push('/booking/calendar-rule');
    }
  }, [result]);
  const isAllTimeSlotsSlectedChanged = () => {
    setIsAllTimeSlotsSelected((prevState, props) => !prevState);
  };

  const additionalGateNumberChangedHander = (value) => {
    if (typeof value == 'number' || value <= 0) {
      setAdditionalGateNumberError('Please enter a positive number.');
    } else {
      setAdditionalGateNumberError('');
    }
    setAdditionalGateNumber(value);
  };
  const openAdditionalGatesClickedHandler = () => {
    openAdditionalGates(
      utcToEpoch(selectedDate),
      selectedTimeSlots,
      additionalGateNumber,
      selectedBusinessArea
    );
  };

  const businessAreaChangedHandler = (value) => {
    setSelectedBusinessArea(value);
  };
  const onDatePickerChangedHandler = (value) => {
    setSelectedDate(value);
  };
  const removeTimeSlot = (item, e) => {
    e.preventDefault();

    setSelectedTimeSlots((prevState, props) => {
      let slots = [...prevState];
      const ind = slots.findIndex((x) => x === item);
      console.log(ind);
      slots.splice(ind, 1);
      return slots;
    });
  };
  const tableConfigurations = {
    noRowText: '',
    hasFilter: false,
    entities: selectedTimeSlots,
    columns: [
      { alias: 'Start Time', name: 'startTime', type: 'string' },
      { alias: 'End Time', name: 'endTime', type: 'string' }
    ],
    rowActions: [{ title: 'Remove Time Slot', clickEvent: removeTimeSlot, icon: 'trash' }]
  };
  const startTimeChangedHandler = (value) => {
    setState((previousState, props) => ({
      ...previousState,
      startMinutesSinceMidnight: value
    }));
  };
  const endTimeChangedHandler = (value) => {
    setState((previousState, props) => ({
      ...previousState,
      endMinutesSinceMidnight: value
    }));
  };
  const addTimeSlot = (e) => {
    e.preventDefault();
    if (state.startMinutesSinceMidnight && state.endMinutesSinceMidnight) {
      if (state.startMinutesSinceMidnight >= state.endMinutesSinceMidnight) {
        toast.error('Time range is invalid. End time should be greater than start time.');
        return;
      }
      const slots = [
        ...selectedTimeSlots,
        {
          startTime: moment(state.startMinutesSinceMidnight).format('H:mm'),
          endTime: moment(state.endMinutesSinceMidnight).format('H:mm'),
          startMinutesSinceMidnight:
            moment(state.startMinutesSinceMidnight).hour() * 60 +
            moment(state.startMinutesSinceMidnight).minute(),
          endMinutesSinceMidnight:
            moment(state.endMinutesSinceMidnight).hour() * 60 +
            moment(state.endMinutesSinceMidnight).minute(),
          slotSize: 30
        }
      ];
      setSelectedTimeSlots(slots);
      state.startMinutesSinceMidnight = null;
      state.endMinutesSinceMidnight = null;
    }
  };
  const renderTimeRange = () => {
    return (
      <div className="p-2">
        <Row>
          <Col>
            <h6 className="text-center">Selected Time Ranges</h6>
          </Col>
        </Row>
        <XmsTable config={tableConfigurations} />
        <br />
        <br />
        <hr />
        <Row>
          <Col md="1">
            <FormLabel>Start Time </FormLabel>
          </Col>
          <Col md="1">
            <TimePicker
              value={state.startMinutesSinceMidnight}
              onChange={(e) => startTimeChangedHandler(e)}
              showSecond={false}
              minuteStep={15}
            />
          </Col>
          <Col md="1"></Col>
          <Col md="1">
            <FormLabel>End Time </FormLabel>
          </Col>
          <Col md="1">
            <TimePicker
              value={state.endMinutesSinceMidnight}
              onChange={(e) => endTimeChangedHandler(e)}
              showSecond={false}
              minuteStep={15}
            />
          </Col>
          <Col>
            <Button variation="outlined" onClick={(e) => addTimeSlot(e)}>
              Add<FontAwesomeIcon icon="plus"></FontAwesomeIcon>
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Page backLink="/booking/calendar-rule" backToWhere="Rules">
      <h4>Enter additional gate number and select the time slots.</h4>
      <hr className="mt-0" />

      <Row className="mt-3">
        <Col>
          <SelectField
            name="businessArea"
            label="Business Area"
            onChange={(e) => businessAreaChangedHandler(e.target.value)}
          >
            <option>Choose..</option>
            {businessAreas
              ? businessAreas.map((businessArea) => (
                  <option key={businessArea.id} value={businessArea.id}>
                    {businessArea.name}
                  </option>
                ))
              : null}
          </SelectField>
        </Col>
        {
          <Col>
            <Label className="date-picker-label" label="Select Date">
              Date
            </Label>
            <DatePicker
              placeholderText="Select Date"
              selected={selectedDate}
              type="text"
              className="additional-gate-date-picker"
              format="MM/DD/YYYY"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              onChange={onDatePickerChangedHandler}
            />
          </Col>
        }
        <Col>
          <NumberField
            name="additionalGateNumber"
            label="Additional Gate Number"
            value={additionalGateNumber}
            errorMessage={additionalGateNumberError}
            onChange={(e) => additionalGateNumberChangedHander(e.target.value)}
          />
        </Col>
      </Row>
      <div
        style={{
          border: '1px solid grey',
          padding: '5px',
          marginBottom: '10px',
          marginTop: '10px'
        }}
      >
        {renderTimeRange()}
      </div>
      <Row className="mt-3">
        <Col md="12">
          <ButtonStack align="right">
            <Button
              onClick={openAdditionalGatesClickedHandler}
              disabled={
                !selectedTimeSlots ||
                selectedTimeSlots.length === 0 ||
                additionalGateNumber <= 0 ||
                !selectedBusinessArea
              }
            >
              Open gates
              <FontAwesomeIcon className="ml-2" icon="save" />
            </Button>
          </ButtonStack>
        </Col>
      </Row>
      <LoadingIndicator id="openAdditionalGates" />
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    timeSlots: state.additionalGate.timeSlots,
    result: state.additionalGate.openAdditionalGateResult,
    businessAreas: state.additionalGate.businessAreas
  };
};

const mapDispatchToProps = {
  getTimeSlots,
  openAdditionalGates,
  getBusinessAreas,
  clearState
};
export default connect(mapStateToProps, mapDispatchToProps)(OpenAdditionalGate);
