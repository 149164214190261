import type from '../type/thuDetailTypes';

const initialState = {
    thuList: [],
    packageTypeList: [],
    suffix: null,
    thuDetail: null,
    thuNotEdited: null,
    shipment: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case type.THU_STATE_CLEARED:
            return {
                ...initialState,
                thuList: [],
            }
        case type.THU_LISTED:
            return {
                ...state,
                thuList: action.payload || [] // FIXED: added this fix since the action.payload is not and array where it must be
            }
        case type.THU_PRINTED:
            return {
                ...state,
                thuList: [...state.thuList, ...(action.payload || [])],
            }
        case type.THU_CREATED:
            return {
                ...state,
                thuList: [...state.thuList, action.payload],
            }
        case type.THU_CONVERTED:
            var list = [...state.thuList];
            for (var i = 0; i < list.length; i++) {
                if (list[i].id == action.payload.id) {
                    list[i] = action.payload;
                }
            }
            return {
                ...state,
                thuList: list,
            }
        case type.THU_DELETED:
            var list = [...state.thuList];
            list = list.filter(s => s.id !== action.payload);
            return {
                ...state,
                thuList: list,
            }
        case type.THU_CONFIRMED:
            var list = [...state.thuList];
            list.filter(s => s.id === action.payload.article.id).map(s => s.status = action.payload.article.status);

            return {
                ...state,
                thuList: list
            }
        case type.PACKAGETYPE_LISTED:
            return {
                ...state,
                packageTypeList: action.payload
            }
        case type.THU_STATE_REVERSED:
            var list = [...state.thuList];
            list.filter(s => s.id === action.payload.article.id).map(s => s.status = action.payload.article.status);
            return {
                ...state,
                thuList: list
            }
        case type.THU_FOUND:
            return {
                ...state,
                thuDetail: action.payload
            }
        case type.THU_FOUND:
        case type.THU_UPDATED:
            return {
                ...state,
                thuNotEdited: false
            }
        case type.THU_NOT_EDITED:
            return {
                ...state,
                thuNotEdited: true
            }
        case type.SHIPMENT_RECEIVED:
            return {
                ...state,
                shipment: action.payload
            }
        default:
            return state
    }
}