import type from '../type/shipments';

const initialState = {
    suffixList: null,
    statusList: null,
    shipmentList: [],
    groupList: null,
    operationMass: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case type.GET_SUFFIX_LIST:
            return {
                ...state,
                suffixList: action.payload
            }
        case type.GET_CONNECTIONPOINT_LIST:
            return {
                ...state,
                connectionPointsList: action.payload
            }
        case type.GET_STATUS_LIST:
            return {
                ...state,
                statusList: action.payload
            }
        case type.OPERATION_RECEIVED:
            return {
                ...state,
                operation: action.payload
            }
        case type.GET_SHIPMENT_LIST:
            return {
                ...state,
                shipmentList: action.payload
            }
        case type.CLEAR_ADD_SHIPMENTS_STATE:
            return {
                ...initialState
            }
        case type.SHIPMENTS_LINKED:
            var list = [...state.shipmentList];

            for (var i = 0; i < action.payload.length; i++) {
                list = list.filter(s => s.shipmentNo != action.payload[i]);
            }

            return {
                ...state,
                shipmentList: list
            }
        case type.GET_OPERATIONTRANSPORT_LIST:
            return {
                ...state,
                groupList: action.payload
            }
        default:
            return state
    }
}