/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getBusinessAreaList, insertOrUpdateBusinessArea, getBusinessArea, clearBusinessAreaState, getClients } from '../store/action/businessAreaActions';
import BusinessAreaForm from '../component/BusinessAreaForm/BusinessAreaForm';
import history from '../core/history';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import { toast } from 'react-toastify';
import Page from '../component/Page/Page';

function AddOrEditBusinessArea({ match, businessArea, businessAreaList, clearBusinessAreaState, getBusinessArea, getBusinessAreaList, insertOrUpdateBusinessArea, companyList, getClients }) {
    useEffect(() => {
        if (!businessAreaList) {
            getBusinessAreaList();
        }
        return () => {
            clearBusinessAreaState();
        }
    }, []);

    useEffect(() => {
        if (!companyList) {
            getClients();
        }
    }, [companyList]);

    useEffect(() => {
        if (match.params.businessAreaId !== 'new' && !businessArea) {
            getBusinessArea(businessAreaList, match.params.businessAreaId);
        }
    }, [businessAreaList, match.params.businessAreaId])

    const handleSubmit = (form) => {
        insertOrUpdateBusinessArea(form.id, form.name, form.companies);
        if (match.params.businessAreaId === 'new') {
            toast.success('Busines area added.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
            });
        } else {
            toast.success('Business area updated.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
            });
        }

        history.push('/businessarea');
    }


    return <Page backLink='/businessarea/' backToWhere='Business Areas'>
        <h4>
            {businessArea ? businessArea.name : 'New Business Area'}
        </h4><hr className='mt-0' />
        <BusinessAreaForm companies={companyList}
            businessArea={businessArea}
            onSubmit={handleSubmit}
        />
        <LoadingIndicator id="businessAreaDetail" />
        <LoadingIndicator id="getBusinessAreaList" />
    </Page>
}

const mapStateToProps = (state) => {
    return {
        businessArea: state.businessArea.businessArea,
        businessAreaList: state.businessArea.businessAreaList,
        companyList: state.businessArea.companies
    }
}

const mapDispatchToProps = {
    clearBusinessAreaState,
    getBusinessArea,
    getBusinessAreaList,
    insertOrUpdateBusinessArea,
    getClients
}

export default connect(mapStateToProps, mapDispatchToProps)(AddOrEditBusinessArea);
